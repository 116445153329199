import React, {useEffect, useState} from 'react';
import {APPNAME, changeCurrency, numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {
    LINK_CONDITIONS,
    LINK_FINISHED_STORE,
} from "../../../../routes/type";

import Cookies from "js-cookie";
import {loadRecapContent} from "../../../../store/Register/action";
import {toast} from "react-toastify";
import axios from "axios";
import { FedaPay, MiddleLoading, PayPal, RegLoading } from '../../../../components'

const Recap = ({t,loadRecapContent,register : {transaction,error,isLoading},setStep}) => {

    document.title = APPNAME+" :: "+t('Recap')


    const [GLoading,setGLoading] = useState(false)
    const [checked,setChecked] = useState(false)
    const [demoLoading,setDemoLoading] = useState(false)
    const [transactionAmount,setTransactionAmount] = useState(0)
    const [promo,setPromo] = useState("")
    const [recalcul,setRecalcul] = useState(false)
    const [haveDiscount,setHaveDiscount] = useState(false)

    useEffect(()=>{
        loadRecapContent()
    },[])

    useEffect(()=>{
        if (transaction && transaction?.amount){
           setTransactionAmount(transaction?.amount)
        }
    },[transaction ])


    let history = useHistory()


    const succeedPayment = async (details,method) =>{
        setGLoading(true)
        const data = {
            payment_id : details.id,
            payment_method : method,
            type : "REGISTRATION",
            shop_key : Cookies.get("shop_key"),
            sponsorship :  Cookies.get("sponsor_key")??null,
            code_promo : promo
        }

        await axios.post(SERVER_URL+"shop/create",data).then((response)=>{
            if (response.data.succeed){
                setGLoading(true)
                Cookies.remove("shop_key")
                Cookies.remove("total_price")
                Cookies.remove("step")
                Cookies.set('finished',true)
                toast.success(t('SucceedPayment'))
                window.scrollTo(0,0)
                setTimeout(()=>{
                    setGLoading(false)
                     history.push(LINK_FINISHED_STORE)
                },2000)

            }else{
                toast.error(t(response.data.message))
            }
        })


    }

    const StartTrial = async () => {
        setGLoading(true)
        const data = {
            shop_key : Cookies.get("shop_key"),
            sponsorship :  Cookies.get("sponsor_key")??null
        }

        await axios.post(SERVER_URL+"shop/trial",data).then((response)=>{
            if (response.data.succeed){
                setGLoading(true)
                Cookies.remove("shop_key")
                Cookies.remove("total_price")
                Cookies.remove("step")
                Cookies.set('finished',true)
                window.scrollTo(0,0)
                setTimeout(()=>{
                    setGLoading(false)
                    history.push(LINK_FINISHED_STORE)
                },2000)

            }else{
                toast.error(t(response.data.message))
            }
        })


    }

    const submitDemo = async () => {
        setDemoLoading(true)
        const data = {
            shop_key : Cookies.get("shop_key"),
            demo : true
        }

        await axios.post(SERVER_URL+"shop/start/demo",data).then((response)=>{
            if (response.data.succeed){
                setDemoLoading(true)
                Cookies.remove("shop_key")
                Cookies.remove("total_price")
                Cookies.remove("step")
                Cookies.set('finished',true)
                window.scrollTo(0,0)
                setTimeout(()=>{
                    setGLoading(false)
                    history.push(LINK_FINISHED_STORE)
                },2000)

            }else{
                toast.error(t(response.data.message))
            }
        })

    }


    const handleCouponSubmit = (event) => {
      event.preventDefault()
        if (event.target[0].value){
            const data = {
                coupon : event.target[0].value
            }

            axios.post(SERVER_URL+"apply/coupon",data).then((response)=>{
                if (response.data.succeed){
                    toast.success(t('CouponApplied'))
                    setPromo(data.coupon)
                     const discount = transactionAmount - ((transactionAmount * 10) / 100)
                    setTransactionAmount(discount)

                     setRecalcul(true)
                    setTimeout(()=>{
                       setRecalcul(false)
                        setHaveDiscount(true)
                    },2000)

                }else{
                    toast.error(t(response.data.message))
                }
            })
        }
    }


   return (
        <>
            <div className="row wow fadeInUp"  data-wow-delay="0.1s">
                <div className="col-md-10 pt-10">
                    <h2>{t('Recap')}  </h2>
                </div>
                <div className="col-md-2 pt-10" style={{textAlign : "right"}}>
                    <h4 style={{textAlign : "right",justifySelf : "right"}}>{numberWithCommas(transaction?.amount ?? 0)} Frcs</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <hr/>
                </div>
            </div>
            {
                isLoading  || GLoading

                    ?
                    <RegLoading />
                    :

                    <>
                        {
                            transaction

                            &&

                            <>

                                {
                                    checked ?

                                    <div className="row">
                                        { parseInt(transaction.price?.price) === 0 ?
                                           <>
                                               <div className="offset-md-2 col-md-8 p-t-180">
                                                   <h2 className="text-center">{t('FreeTrial')}</h2>
                                                   <p className="text-center">{t('FreeTrialText')}</p>
                                                   <p className="text-center">{t('FreeTrialTextContent')}</p>
                                                   <hr/>
                                               </div>

                                               <div className="offset-md-4 col-md-4 p-b-100">
                                                   <button className='btn btn-success btn-block' onClick={event => StartTrial()}>{t('Finished')}</button>
                                               </div>
                                           </>

                                            :
                                            <div className="row p-t-100 p-b-100">

                                                <div className="offset-lg-3 col-lg-6">
                                                   <div className="card" style={{border : "1px solid #ccc"}}>
                                                       <div className="card-body">
                                                           <div className={haveDiscount ? "d-flex justify-content-between" : ""}>
                                                               <h1  style={{
                                                                   textAlign : haveDiscount ? 'left' : 'center',
                                                                   fontSize: haveDiscount ? '20px' : '50px',
                                                                   textDecoration: haveDiscount ? 'line-through' : 'none',
                                                                   color: haveDiscount ? 'red' : 'black'
                                                               }}>{numberWithCommas(transaction?.amount)} <small><small>XOF</small></small> </h1>

                                                               {
                                                                   haveDiscount ?
                                                                       <div>
                                                                           <br />
                                                                           <b className="btn btn-rounded btn-success btn-sm">{promo}</b>
                                                                       </div>
                                                                       :
                                                                       ''
                                                               }
                                                           </div>


                                                           {
                                                               haveDiscount ? <h1 className="text-center"
                                                                                  style={{ fontSize: '50px' }}>{numberWithCommas(transactionAmount)}
                                                                   <sub>XOF</sub></h1> : ""
                                                           }


                                                           {
                                                               !haveDiscount ?
                                                                   <>
                                                                       <p className="mt-10">
                                                                       {t('HaveCodeCoupon')}
                                                                       </p>
                                                                       <form
                                                                           className="hstack gap-3 px-3 mx-n3 mt-10 mb-20"
                                                                           action="" method="get"
                                                                           onSubmit={event => handleCouponSubmit(event)}>
                                                                           <input className="front" type="text"
                                                                                  placeholder="code"
                                                                                  aria-label="Add Promo Code here..."
                                                                                  required />
                                                                           <button type="submit"
                                                                                   className="btn btn-success">
                                                                               {t('Apply')}
                                                                           </button>
                                                                       </form>

                                                                       <hr />
                                                                       <br />
                                                                       <p className="text-center">
                                                                           <b>{t('PayIfDontHaveCoupon')}.</b>
                                                                       </p>
                                                                   </>
                                                                   : ""
                                                           }

                                                           {
                                                               recalcul ?

                                                                  <MiddleLoading />

                                                                  :

                                                                  <>
                                                                      <FedaPay amount={transactionAmount}
                                                                               text="Payer avec Mobile Money"
                                                                               succeedPayment={succeedPayment}
                                                                               user={transaction?.shopInfo} />
                                                                      <PayPal succeedPayment={succeedPayment}
                                                                              total={changeCurrency(transactionAmount, 'USD')} />
                                                                  </>
                                                           }
                                                       </div>
                                                   </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                        :

                                        <>
                                            <div className="row">
                                                <div className="col-lg-6 fs-13 mt-30">
                                                <div className="card" style={{ border: '1px solid #eee' }}>
                                                        <div className="card-header">
                                                            <h5>{t('Info')} </h5>
                                                        </div>
                                                        <div className="card-body">
                                                        <p>
                                                                <b>{t('Fullname')} : </b>
                                                                <br /> {transaction.shopInfo?.firstname} {transaction.shopInfo?.lastname}
                                                            </p>

                                                            <p>
                                                                <b>Email </b> : <br /> {transaction.shopInfo?.email}
                                                            </p>

                                                            <p>
                                                                <b>{t('Phone')} : </b>
                                                                <br /> {transaction.shopInfo?.phone}
                                                            </p>

                                                            <p>
                                                                <b>{t('shopName')} </b> : <br /> {transaction.shopInfo?.shopName}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card" style={{ border: '1px solid #eee' }}>
                                                        <div className="card-header">
                                                            <h5>{t('Plan')} </h5>
                                                        </div>

                                                        <div className="row fs-13 card-body">
                                                            <div className="offset-lg-3 col-lg-6 text-center pb-10">
                                                                <h1>{numberWithCommas(transaction.price?.price)} <br />
                                                                    <small className="fs-13">Frcs /{t('Month')}</small>
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mt-30">
                                                    <div className="card" style={{ border: '1px solid #eee' }}>
                                                        <div className="card-header">
                                                            <h5>{t('ConfigPage')} </h5>
                                                        </div>

                                                        <div className="row pt-3 fs-13 card-body">
                                                            {
                                                                transaction.page?.content && JSON.parse(transaction.page?.content)?.map((lt, key) =>

                                                                    <p key={key} className="col-lg-12 "
                                                                       data-wow-delay="0.3s">
                                                                        <span className="text-danger">*</span> {t(lt.name)}
                                                                    </p>,
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="card" style={{ border: '1px solid #eee' }}>
                                                        <div className="card-header">
                                                            <h5>{t('Template')} </h5>
                                                        </div>

                                                        <div className="row card-body">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <h4> {JSON.parse(transaction.template?.content)?.name} </h4>
                                                                        <p>
                                                                            <b className="btn btn-sm btn-light">{t('Free')}</b>
                                                                        </p>
                                                                        <b className="btn btn-sm btn-light">{t('Modern')}</b>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="template_showing_content_element"
                                                                            style={{ height: '150px' }}>
                                                                            <img
                                                                                src={SERVER_IMG_URL + 'templates/' + JSON.parse(transaction.template?.content)?.picture}
                                                                                alt="..." />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {transaction.demo ?
                                                <div className="sticky-side-div">
                                                    <div className="alert border-dashed alert-success" role="alert">
                                                        <div className="d-flex align-items-center">
                                                            <lord-icon src="https://cdn.lordicon.com/nkmsrxys.json" trigger="loop" colors="primary:#121331,secondary:#099885" style={{width:"100px",height:"100px"}}></lord-icon>
                                                            <div className="ms-2">
                                                                <h3 className="text-success fw-semibold"> {t('DemoOffer')}</h3>
                                                                <p className="text-body mb-1 fs-14">{t('DemoOfferText')}.</p>
                                                                <div className="d-flex justify-content-end">
                                                                    <button className="btn btn-success mt-20"
                                                                            onClick={event => submitDemo()}>
                                                                        {
                                                                            demoLoading ?
                                                                                <span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</span>
                                                                                :
                                                                                 <span>{t('ClickToBeneficy')}</span>
                                                                        }
                                                                        </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row" >
                                                    <div className="form-group">
                                                        <label htmlFor="confirmation" style={{display: "flex"}}>
                                                            <input type="checkbox"
                                                                   onClick={event => setChecked(event.target.checked)}
                                                                   style={{width: "20px"}} id='confirmation'/> <span
                                                            style={{
                                                                paddingTop: "10px",
                                                                paddingLeft: "20px"
                                                            }}>{t('MessageBeforeLastSubmitShop')} <Link
                                                            to={LINK_CONDITIONS}> {t('Termsndcondition')}</Link></span>
                                                        </label>
                                                    </div>

                                                </div>
                                            }


                                        </>
                                }


                            </>
                        }
                    </>


            }
        </>
   );
}

const mapStateToProps = (state) => ({
    register: state.registershop,
});


export default compose(connect(mapStateToProps, {loadRecapContent}))(withTranslation()(Recap));