import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";



const CreateTicket  = ({open,setOpen,created,t,auth}) => {

    const [loading,setLoading] = useState(false)


    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t('InputRequired')),
        customer_id: Yup.string().required(t('InputRequired')),
        priority: Yup.string().required(t('InputRequired')),
        description: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        shop_id : auth?.shop.id,
        customer_id: auth?.me.id,
        type : 2,
        title  :  '',
        priority :  2,
        description :  '',
    }


    const handleSubmit = async (values) => {
        setLoading(true)

        values.shop_id = auth?.shop?.id

            await axios.post(SERVER_URL+"ticket/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(auth?.me?.id)
                }else{
                    toast.error(t(response.data.message))
                }
            })



    }


    return (
      <CustomModal open={open} setOpen={setOpen} title={ <>{t('Add')} {t('Ticket')}</>} size="md">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ errors, touched }) =>
                  <Form>
                 <div className="row">
                     <div>
                         <label htmlFor="title" className="form-label">{t('Title')} <b className="text-danger">*</b></label>
                         <Field  className="form-control" id="title" name="title"/>
                         {errors.title && touched.title ? (
                             <div className='text-danger'>{errors.title}</div>
                         ) : null}
                     </div>
                 </div>

                      <div className="row">
                          <div>
                              <br/>
                              <label htmlFor="description" className="form-label">{t('Description')} <b className="text-danger">*</b></label>
                              <Field  as="textarea" className="form-control" rows="3" cols="30" id="description" name="description"/>
                              {errors.description && touched.description ? (
                                  <div className='text-danger'>{errors.description}</div>
                              ) : null}
                          </div>
                      </div>

                     <div className="row">
                         <div className="col-lg-8 pt-3">
                             <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                         </div>
                         <div className="col-lg-4 pt-3">
                             <button className="btn btn-success btn-block" disabled={loading}>
                                 {
                                     loading ?
                                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                         :
                                         <span>{t('Submit')}</span>
                                 }
                             </button>
                         </div>
                     </div>
                  </Form>
              }

          </Formik>
      </CustomModal>
    );
}

export default  withTranslation() (CreateTicket)
