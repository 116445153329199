import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,auth,customers}) => {

    const [loading,setLoading] = useState(false)


    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t('InputRequired')),
        customer_id: Yup.string().required(t('InputRequired')),
        priority: Yup.string().required(t('InputRequired')),
        description: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        title: isEdit ? updateData.title : '',
        customer_id: isEdit ? updateData.customer_id : '',
        priority: isEdit ? updateData.priority : '',
        description: isEdit ? updateData.description : '',
        supSide : 1
    }


    const handleSubmit = async (values) => {
        setLoading(true)

        const shop_id = auth.shop.id
        values.shop_id = shop_id

        if (isEdit){
            await axios.post(SERVER_URL+"ticket/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"ticket/add",values,attachTokenToHeaders(true)).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    return (
      <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Ticket')}</> : <>{t('Edit')} {t('Ticket')}</>} size="md">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ errors, touched }) =>
                  <Form>
                 <div className="row">
                     <div>
                         <label htmlFor="title" className="form-label">{t('Title')} <b className="text-danger">*</b></label>
                         <Field  className="form-control" id="title" name="title"/>
                         {errors.title && touched.title ? (
                             <div className='text-danger'>{errors.title}</div>
                         ) : null}
                     </div>
                 </div>
                      <div className="row ">
                          <div className="col-lg-6">
                              <br/>
                              <label htmlFor="customer_id" className="form-label">{t('Customer')} <b className="text-danger">*</b></label>
                              <Field as="select" className="form-select" id="customer_id" name="customer_id">
                                  <option value="">-</option>
                                  {
                                      customers?.length > 0 ?

                                          <>
                                              {
                                                  customers?.map((cust,key) => <option key={key} value={cust.id}>{cust.firstname} {cust.lastname}</option>)
                                              }
                                          </>
                                          : null
                                  }
                              </Field>
                              {errors.customer_id && touched.customer_id ? (
                                  <div className='text-danger'>{errors.customer_id}</div>
                              ) : null}
                          </div>
                          <div className="col-lg-6">
                              <br/>
                              <label htmlFor="priority" className="form-label">{t('Priority')} <b className="text-danger">*</b></label>
                              <Field as="select" className="form-select" id="priority" name="priority">
                                  <option value="">-</option>
                                  <option value="1">{t('High')}</option>
                                  <option value="2">{t('Medium')}</option>
                                  <option value="3">{t('Low')}</option>
                              </Field>
                              {errors.priority && touched.priority ? (
                                  <div className='text-danger'>{errors.priority}</div>
                              ) : null}
                          </div>
                      </div>

                      <div className="row">
                          <div>
                              <br/>
                              <label htmlFor="description" className="form-label">{t('Description')} <b className="text-danger">*</b></label>
                              <Field  as="textarea" className="form-control" rows="3" cols="30" id="description" name="description"/>
                              {errors.description && touched.description ? (
                                  <div className='text-danger'>{errors.description}</div>
                              ) : null}
                          </div>
                      </div>

                     <div className="row">
                         <div className="col-lg-8 pt-3">
                             <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                         </div>
                         <div className="col-lg-4 pt-3">
                             <button className="btn btn-success btn-block" disabled={loading}>
                                 {
                                     loading ?
                                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                         :
                                         <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                 }
                             </button>
                         </div>
                     </div>
                  </Form>
              }

          </Formik>
      </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)
