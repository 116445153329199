export const STORE_SHOP_LOADING = 'STORE_SHOP_LOADING';
export const STORE_SHOP_SUCCESS = 'STORE_SHOP_SUCCESS';
export const STORE_SHOP_FAIL = 'STORE_SHOP_FAIL';

export const LOAD_SHOP_LOADING = 'LOAD_SHOP_LOADING';
export const LOAD_SHOP_SUCCESS = 'LOAD_SHOP_SUCCESS';
export const LOAD_SHOP_FAIL = 'LOAD_SHOP_FAIL';

export const LOAD_RECAP_LOADING = 'LOAD_RECAP_LOADING';
export const LOAD_RECAP_SUCCESS = 'LOAD_RECAP_SUCCESS';
export const LOAD_RECAP_FAIL = 'LOAD_RECAP_FAIL';