import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import Authorization from "../../../../components/Authorization";
import ShopConfig from "./ShopConfig";
import CartChoosed from "./CartChoosed";

const Pages = ({t,auth}) => {

    document.title = APPNAME+" :: Pages"

    const [pages,setpages] = useState([])
    const [loading,setLoading] = useState(false)
    const [open,setOpen] = useState(false)
    const [description,setDescription] = useState(auth?.shop.description)
    const [color,setColor] = useState(auth?.shop.color)
    const [hashKey,setHashkey] = useState("")
    const [d_loading, setD_loading] = useState(false)
    const [c_loading, setC_loading] = useState(false)

    const [desc,setDesc] = useState(false)
    const [card,setCard] = useState(false)
    const [col,setCol] = useState(false)


    useEffect(()=>{
        getPagesList()
        setDescription(auth?.shop.description)
        setColor(auth?.shop.color)
    },[])

    useEffect(()=>{
        setDescription(auth?.shop.description)
        setColor(auth?.shop.color)
    },[auth])


    const getPagesList = async () =>{
        setLoading(true)

        await axios.get(SERVER_URL+"pages/list", attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
              setpages(response.data.pages)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })

    }


    const configureProducts = (hash_key) => {
          setOpen(true)
        setHashkey(hash_key)
    }


    const saveDescription = async () => {

        const data = {
            shop_id : auth?.shop.id,
            description : description
        }
        setD_loading(true)

        await axios.post(SERVER_URL+"store/description/save", data,attachTokenToHeaders()).then((response)=>{
            setD_loading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((e)=>{
            setD_loading(false)
            console.log(e.message)
        })
    }

    const saveColor = async () => {
        const data = {
            shop_id : auth?.shop.id,
            color : color
        }
        setC_loading(true)

        await axios.post(SERVER_URL+"store/color/save", data,attachTokenToHeaders()).then((response)=>{
            setC_loading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((e)=>{
            setC_loading(false)
            console.log(e.message)
        })
    }


    const changeTemplate = () => {

    }



    return (
        <AdminLayout>

            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Pages')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="#">{APPNAME}</a></li>
                                <li className="breadcrumb-item active">{t('Pages')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <Authorization single={true}  module="pages"  component={true} >
                <div className="row">
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0 flex-grow-1 justify-content-between">Configuration </h5>
                            </div>
                            <div className="card-body">
                                <h6 className="cursor-pointer underline text-primary"
                                    onClick={event => setDesc(!desc)}>{t('ShopDescription')}</h6>
                                <small>{t('ShopDescriptionExplain')}</small>
                                {
                                    desc ?

                                        <div>
                                            <br/>
                                            <br/>
                                            <textarea name="" id="" cols="30" value={description} rows="4"
                                                      className="form-control" placeholder=""
                                                      onChange={event => setDescription(event.target.value)}/>
                                            <br/>
                                            <button className="btn btn-block btn-success" onClick={saveDescription}
                                                    disabled={d_loading}>
                                                {
                                                    d_loading ?
                                                        <><span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"/> {t('Loading')}...</>
                                                        :
                                                        <span>{t('Submit')}</span>
                                                }
                                            </button>
                                        </div>
                                        :
                                        ""
                                }

                                <hr/>
                                <h6 className="cursor-pointer underline text-primary"
                                    onClick={event => setCard(!card)}>{t('ShopProductCardChoose')}</h6>
                                <small>{t('ShopProductCardChooseText')}</small>

                                <hr/>
                                <h6 className="cursor-pointer underline text-primary"
                                    onClick={event => setCol(!col)}>{t('ChangeShopDefaultColor')}</h6>
                                <small>{t('ChangeShopDefaultColorText')}</small>

                                {
                                    col ?
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div>


                                                    <div className="colorBlock">
                                                        <label htmlFor="color" className="color-custom"
                                                               style={{background: color}}>
                                                            <input type="color" pattern="#[a-f0-9]{6}"
                                                                   onChange={event => setColor(event.target.value)}
                                                                   id="color" style={{display: "none"}}/>
                                                            {color.toUpperCase()}
                                                        </label>
                                                        <div className="color-white">{t('White')}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-4">
                                                <button onClick={event => saveColor()} className="btn btn-success mt-30"
                                                        disabled={c_loading}>
                                                    {
                                                        c_loading ?
                                                            <><span className="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"/> {t('Loading')}...</>
                                                            :
                                                            <span>{t('Submit')}</span>
                                                    }</button>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }




                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="card" id="tasksList">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Pages')}</h5>
                                    {/*<div className="flex-shrink-0">*/}
                                    {/*    <button className="btn btn-primary add-btn btn-sm" onClick={event => changeTemplate()}>*/}
                                    {/*         {t('ChangeTemplate')}*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>


                            <div className="card-body">
                                <div className="table-card mb-20">
                                    <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                        <thead className="table-light text-muted">
                                        <tr>
                                            <th className="sort">{t('Page')}</th>
                                            <th className="sort" style={{width : "15%"}}>Status</th>
                                            <th className="sort" style={{width : "15%"}}>{t('Action')}</th>
                                        </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                        {
                                            loading ?
                                                <tr>
                                                    <td colSpan="12">
                                                        <p className="text-center">
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                        </p>
                                                    </td>
                                                </tr>

                                                :

                                                <>
                                                    {
                                                        pages?.length > 0
                                                            ?
                                                            <>
                                                                {
                                                                    pages?.map((page,key)=>
                                                                        <tr key={key}>
                                                                            <td><b>{t(page.name)}</b></td>
                                                                            <td>
                                                                                {(page.statut == 1)
                                                                                    ? <span className="badge badge-soft-success">{t('Active')}</span>
                                                                                    :  <span className="badge badge-soft-danger">{t('Inactive')}</span>
                                                                                }
                                                                            </td>
                                                                           <td>
                                                                               <ul className="list-inline  mb-0">
                                                                                   {
                                                                                       page.name === "Shop"  ?
                                                                                           <li className="list-inline-item">
                                                                                                         <span
                                                                                                             className="remove-item-btn cursor-pointer"
                                                                                                                 onClick={event => configureProducts(page.hash_key)}>
                                                                                                             <i className=" ri-survey-line   align-bottom me-2"></i> <small>configuration</small>
                                                                                                         </span>
                                                                                           </li>
                                                                                           :
                                                                                           <li className="list-inline-item">
                                                                                               <Link to={"/store/page/editor/"+page.hash_key}>
                                                                                                   <span className="remove-item-btn cursor-pointer">
                                                                                                             <i className="  ri-brush-line align-bottom me-2"></i> <small>k-builder</small>
                                                                                                         </span>
                                                                                               </Link>

                                                                                           </li>
                                                                                   }
                                                                               </ul>
                                                                           </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan="12">
                                                                    <p className="text-center">{t('EmptyList')}</p>
                                                                </td>
                                                            </tr>
                                                    }
                                                </>
                                        }

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </Authorization>

            <ShopConfig setOpen={setOpen} open={open} slug={hashKey} shop_key={auth?.shop.shop_key} loadData={getPagesList} />

            <CartChoosed open={card} setOpen={setCard}  shop_key={auth?.shop.shop_key} loadData={getPagesList} />

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default compose(withRouter, connect(mapStateToProps, {})) (withTranslation()(Pages))
