import React from 'react';
import label_dashboard from '../../../../../assets/label_dashboard.png'

const _Dashboard = ({setStep,t,label}) => {

    return (
        <div className="row">
            <div className="col-lg-3">
                <img src={label_dashboard} alt="" style={{width : "350px"}}/>
            </div>
            <div className="col-lg-5">
                <h3>{t('LabelGenerator')}</h3>
                <p>
                    {label?.[localStorage.getItem('I18N_LANGUAGE')]}
                </p>
                {
                    label ?

                        <div>
                            <ul>
                                {
                                    JSON.parse(label.features).map((feature,idx) =>
                                        <li key={idx}>* {feature?.[localStorage.getItem('I18N_LANGUAGE')]}</li>
                                    )

                                }
                            </ul>
                        </div>
                        :
                        ""
                }
                <br/>
                <button className="btn btn-primary btn-sm ml-30 mb-30" onClick={event => setStep(4)}>Configurer</button>


            </div>

            <div className="col-lg-4">
                <div className="row">
                    <div className="offset-lg-6 col-lg-6 pt-190">

                        <button className="btn btn-light btn-lg d-flex justify-content-between">
                            <i className="ri-google-play-line fs-24"></i> <span style={{paddingTop: "10px"}}>Disponible sur PlayStore</span>
                        </button>
                        <br/>
                        <button className="btn btn-light btn-lg d-flex justify-content-between">
                            <i className="ri-apple-line fs-24"></i> <span style={{paddingTop: "10px"}}>Disponible sur PlayStore</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default _Dashboard;
