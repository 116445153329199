import React from 'react';
import {withTranslation} from "react-i18next";

const Part1 = ({t}) => {
    return (
        <section className="service-section p-t-130 p-b-130 bg-secondary-color-5" >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="common-heading text-center heading-white m-b-30">
                            <h2 className="title">{t('ManageAll')}</h2>
                        </div>
                    </div>
                </div>
                <div
                    className="row no-gutters justify-content-lg-between justify-content-md-start justify-content-center icon-boxes-v3">
                    <div className="col-lg-3 col-md-6 col-sm-10 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="iconic-box box-version-white color-v1 m-t-50">
                            <div className="icon d-flex justify-content-center">
                                <i className="far fa-sign-in"></i>
                            </div>
                            <h4 className="title text-center"><a href="#">{t('RegisterYourShop')}</a></h4>
                            <p>
                                {t('CompletandSecureRegistration')}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-10 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="iconic-box box-version-white color-v2 m-t-50">
                            <div className="icon d-flex justify-content-center">
                                <i className="far fa-keyboard"></i>
                            </div>
                            <h4 className="title text-center"><a href="#">{t('ConfigYourShop')}</a></h4>
                            <p>
                                {t('SimpleConfigShop')}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-10 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="iconic-box box-version-white color-v3 m-t-50">
                            <div className="icon d-flex justify-content-center">
                                <i className="far fa-shopping-basket"></i>
                            </div>
                            <h4 className="title text-center"><a href="#">{t('Selled')}</a></h4>
                            <p>
                                {t('SellOnYourRythm')}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default withTranslation()(Part1)
