import React, {useEffect, useRef} from 'react';
import "./otpInput.css"

const OtpInput = ({length, onInputComplete}) => {

    const inputs = useRef([]);

    useEffect(() => {
        if (inputs.current.length > 0) {
            inputs.current[0].focus();
        }
    }, []);

    const handleInputChange = (index, e) => {
        const value = e.target.value;
        if (value) {
            if (index < length - 1) {
                inputs.current[index + 1].focus();
            } else {
                inputs.current[index].blur();
                onInputComplete(getOTPValue());
            }
        }
    }

    const handleInputPaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (paste.length === length) {
            for (let i = 0; i < length; i++) {
                inputs.current[i].value = paste[i];
                if (i < length - 1) {
                    inputs.current[i + 1].focus();
                }
            }
        }
    }

    const getOTPValue = () => {
        return inputs.current.map(input => input.value).join('');
    }


    const digit = (input) =>{
        input.value = input.value.replace(/[^0-9]/g,'');
    }

    return (
        <div className="otp-input">
            {[...Array(length)].map((_, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    onInput={e => digit(e.target)}
                    ref={el => inputs.current[index] = el}
                    onChange={e => handleInputChange(index, e)}
                    onPaste={handleInputPaste}
                />
            ))}
        </div>
    );
}

export default OtpInput;
