import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import avatarDefault from "../../../../assets/admin/images/avatar-blank.png"
import bg from "../../../../assets/ecom.jpg"
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {attachTokenToHeaders, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import {loadMe, logout} from "../../../../store/Auth/action";


const Profil = ({auth,t,logout,loadMe}) => {

    const [avatar, setAvatar] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ploading, setPloading] = useState(false);
    const [pwLoading, setPwloading] = useState(false);
    const [deletereqPassword, setDeletereqPassword] = useState("");

    const changeAvatar = async (e) => {
        setAvatar(URL.createObjectURL(e.target.files[0]))
        const data = new FormData()
        data.append('type',"users")
        data.append('avatar',e.target.files[0])

        await axios.post(SERVER_URL+"user/avatar/"+ auth?.me.id,data,attachTokenToHeaders(true)).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                loadMe()
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    useEffect(() => {
        auth?.me.avatar? setAvatar(SERVER_IMG_URL+auth?.shop.shop_key+"/users/"+auth?.me.avatar) : setAvatar(avatarDefault)
    }, [auth?.me]);



    const passwordValidationSchema = Yup.object().shape({
        oldpassword: Yup.string()
            .required(t('PasswordRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('PasswordContaint')
            )
        , password: Yup.string()
            .required(t('PasswordRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('PasswordContaint')
            )
        ,
        confpassword: Yup.string()
            .required(t('ConfPasswordRequired'))
            .oneOf([Yup.ref('password'), null], t('PasswordSame'))
        ,
    });



    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required(t('InputRequired')),
        lastname: Yup.string().required(t('InputRequired')),
        phone: Yup.string().required(t('InputRequired')),
        email: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        firstname :  auth?.me.firstname,
        lastname :  auth?.me.lastname,
        phone :  auth?.me.phone,
        email :  auth?.me.email,
    }

    const  passwordinitialValues= {
        oldpassword : "",
        password : "",
        confpassword: "",
    }


    const handleSubmitInfoPerso = async (values) => {
        setLoading(true)
        await axios.put(SERVER_URL+"user/edit/"+ auth?.me.id,values,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                loadMe()
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const handleSubmitPassword = async (values) => {
        setPwloading(true)
        await axios.put(SERVER_URL+"user/password/"+ auth?.me.id,values,attachTokenToHeaders()).then((response)=>{
            setPwloading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setTimeout(()=>{
                    window.location.reload(true)
                },2000)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const deleteMyAccount = async () => {
       if (deletereqPassword){
           await swal({
               title : t('Deleting'),
               text : t("DeleteMyAccount"),
               buttons: [t("Cancel"), t('Yes')],
               dangerMode:true,
               icon : "error"
           }).then(async (check) => {
               if (check) {
                   await axios.put(SERVER_URL + "user/delete/" + auth?.me.id, {password: deletereqPassword}, attachTokenToHeaders()).then(async (response) => {
                       setLoading(false)
                       if (response.data.succeed) {
                           toast.success(t(response.data.message))
                           logout()
                       } else {
                            if (response.data.error) {
                                toast.error(t(response.data.message))
                                setTimeout(()=>{
                                    window.location.reload(true)
                                },2000)
                            }else{
                                await swal({
                                    title: t('Deleting'),
                                    text: t(response.data.message),
                                    buttons: [t("Cancel"), t('Yes')],
                                    dangerMode:true,
                                    icon: "warning"
                                })
                                    .then(async (check) => {
                                        if (check) {
                                            setPloading(true)
                                            try {
                                                const response = await axios.get(`${SERVER_URL}user/delete/definitif/${auth?.me.id}`, attachTokenToHeaders())
                                                setPloading(false)
                                                setDeletereqPassword("")
                                                if (response.data.succeed === true) {
                                                    toast.success(t(response.data.message))
                                                }else{
                                                    toast.error(t(response.data.message))
                                                }
                                            } catch (error) {
                                                setPloading(false)
                                                console.log(error)
                                            }
                                        }
                                    });
                            }
                       }
                   })
               }
           })
       }else{
           document.getElementById('passwordInput').classList.add('is-invalid')
           toast.error("InputRequired")
       }
    }


    return (
        <AdminLayout>
            <div className="position-relative mx-n4 mt-n4">
                <div className="profile-wid-bg profile-setting-img">
                    <img src={bg} className="profile-wid-img" alt=""/>
                </div>
            </div>

            <div className="row">
                <div className="col-xxl-3">
                    <div className="card mt-n5">
                        <div className="card-body p-4">
                            <div className="text-center">
                                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                    <img src={auth?.me.avatar || avatar ? avatar : avatarDefault} className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image"/>
                                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                        <input id="profile-img-file-input" type="file" value={[]} className="profile-img-file-input" onChange={event => changeAvatar(event)}/>
                                        <label htmlFor="profile-img-file-input"
                                               className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                        </label>
                                    </div>
                                </div>
                                <h5 className="fs-16 mb-1">{auth?.me.firstname} {auth?.me.lastname}</h5>
                                <p className="text-muted mb-0">{auth?.shop.shopName}</p>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="col-xxl-9">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                        <i className="fas fa-home"></i> {t('PersonalDetails')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                        <i className="far fa-user"></i> {t('UpdatePassword')}
                                    </a>
                                </li>

                                {/*<li className="nav-item">*/}
                                {/*    <a className="nav-link" data-bs-toggle="tab" href="#privacy" role="tab">*/}
                                {/*        <i className="far fa-envelope"></i> {t('PrivacyPolicy')}*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                        <div className="card-body p-4">
                            <div className="tab-content">
                                <div className="tab-pane active" id="personalDetails" role="tabpanel">
                                    <Formik initialValues={initialValues} onSubmit={handleSubmitInfoPerso} validationSchema={validationSchema}>
                                    {({ errors, touched }) =>
                                        <Form>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="firstname" className="form-label">{t('Lastname')}</label>
                                                    <Field  className="form-control form-control-lg" id="lastname" name="lastname"/>
                                                    {errors.lastname && touched.lastname ? (
                                                        <div className='text-danger'>{errors.lastname}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="firstname" className="form-label">{t('Firstname')}</label>
                                                    <Field  className="form-control form-control-lg" id="firstname" name="firstname"/>
                                                    {errors.firstname && touched.firstname ? (
                                                        <div className='text-danger'>{errors.firstname}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="phonenumberInput" className="form-label">{t('Phone')}</label>
                                                    <Field  className="form-control form-control-lg" id="phone" name="phone"/>
                                                    {errors.phone && touched.phone ? (
                                                        <div className='text-danger'>{errors.phone}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="emailInput" className="form-label">Email</label>
                                                    <Field type="email"  className="form-control form-control-lg" id="email" name="email"/>
                                                    {errors.email && touched.email ? (
                                                        <div className='text-danger'>{errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>



                                            <div className="col-lg-12">
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                                        {
                                                            loading ?
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                                :
                                                                <span> {t('Edit')}</span>
                                                        }
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        </Form>
                                    }

                                </Formik>
                                </div>

                                <div className="tab-pane" id="changePassword" role="tabpanel">
                                    <Formik initialValues={passwordinitialValues} onSubmit={handleSubmitPassword} validationSchema={passwordValidationSchema}>
                                        {({ errors, touched }) =>
                                            <Form>
                                        <div className="row g-2">
                                            <div className="col-lg-12">
                                                <div>
                                                    <label htmlFor="oldpassword" className="form-label">{t('OldPassword')}</label>
                                                    <Field type="password"  className="form-control form-control-lg" id="oldpassword" name="oldpassword"/>
                                                    {errors.oldpassword && touched.oldpassword ? (
                                                        <div className='text-danger'>{errors.oldpassword}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div>
                                                    <label htmlFor="password" className="form-label">{t('NewPassword')}</label>
                                                    <Field type="password"  className="form-control form-control-lg" id="password" name="password"/>
                                                    {errors.password && touched.password ? (
                                                        <div className='text-danger'>{errors.password}</div>
                                                    ) : null}
                                            </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div>
                                                    <label htmlFor="confpassword" className="form-label">{t('ConfPassword')}</label>
                                                    <Field type="password"  className="form-control form-control-lg" id="confpassword" name="confpassword"/>
                                                    {errors.confpassword && touched.confpassword ? (
                                                        <div className='text-danger'>{errors.confpassword}</div>
                                                    ) : null}
                                                </div>
                                            </div>


                                            <div className="col-lg-12">
                                                <div className="text-end">
                                                    <button type="submit" className="btn btn-success">{t("Edit")}</button>
                                                </div>
                                            </div>

                                        </div>

                                            </Form>
                                        }

                                    </Formik>

                                </div>

                                <div className="tab-pane" id="privacy" role="tabpanel">
                                    <div>
                                        <h5 className="card-title text-decoration-underline mb-3">{t('DeleteMyAccount')}:</h5>
                                        <p className="text-muted">{t('DeleteAccountPreventions')}</p>
                                        <div>
                                            <input type="password" className="form-control" id="passwordInput" placeholder={t('Password')} value={deletereqPassword} onChange={event => setDeletereqPassword(event.target.value)} style={{maxWidth : "265px"}}/>
                                        </div>
                                        <div className="hstack gap-2 mt-3">
                                            <button  className="btn btn-soft-danger" disabled={ploading} onClick={event => deleteMyAccount()}>

                                                {
                                                    ploading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                        <span> {t('DeleteMyAccount')}</span>
                                                }

                                               </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop
});

export default   compose(connect(mapStateToProps,{logout,loadMe})) (withTranslation()(Profil));
