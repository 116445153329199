import React from 'react';
import {CLIENT} from "../../../helpers/utils";

const NotFoundSubdomain = () => {
     document.title = "Shop not found"


    return (
        <section className="page_404">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="col-sm-12   text-center">
                            <div className="four_zero_four_bg">
                                <h1 className="text-center ">404</h1>
                            </div>

                            <div className="contant_box_404">
                                <h3 className="h2">
                                   Cette boutique n'existe pas.
                                </h3>

                                <p>Vous pouvez la créer en accédant à ce lien</p>
                                <a href={CLIENT+"store/new"} className="template-btn">Créer ma boutique</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NotFoundSubdomain;