import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, expireDate, format_date, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {
    LINK_STORE_CHART,
    LINK_STORE_DELIVERER, LINK_STORE_FACEBOOK_MARKET_PLACE,
    LINK_STORE_LABEL, LINK_STORE_MESSENGER,
    LINK_STORE_PACKAGES,
    LINK_STORE_WHATSAPP,
} from '../../../../routes/type'
import {useHistory} from "react-router-dom";
import moment from "moment";
import 'moment/locale/fr'
import InstalledPlugin from "../MarketPlace/InstalledPlugin";
import LevelAccess from "../../../../components/LevelAccess";
moment().locale('fr')

const Applications = ({t,auth}) => {
    const [plugins,setPlugins] = useState([])
    const [open, setOpen] = useState(false);
    const [application, setApplication] = useState(null);

    const history = new useHistory()

    useEffect(() => {
        getPlugins()
    }, []);


    const getPlugins = async () => {
        await axios.get(SERVER_URL+"admin/plugins",attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                mergePluginSubscribe(response.data.plugins)
                if (!response.data.plugins.length) {
                    window.location.href = LINK_STORE_PACKAGES
                }
            }else{
                toast.error(t(response.data.message))
            }
        })
            .catch((err) => {
                if (err.response.status === 401){
                    window.location.reload(true)
                }
            })
    }

    const mergePluginSubscribe = (pluginData) => {
        const data = []
        auth?.plugins.forEach((item, i) => {
            const matchedFound = pluginData.filter((a) => a.id == parseInt(item.plugins));
            data[i] = {...item, ...matchedFound}
        });
        setPlugins(data)
    };

    const toggleStatus = (id,event) => {
       const  status = event ? 1 : 2
    }



    const openThisLink = (plug) => {
      switch (plug) {
          case "delivery" :
              history.push(LINK_STORE_DELIVERER)
               break;
          case "label" :
              history.push(LINK_STORE_LABEL)
              break
          case "chart" :
              history.push(LINK_STORE_CHART)
              break
          case "whatsapp" :
              history.push(LINK_STORE_WHATSAPP)
              break
          case "messenger" :
              history.push(LINK_STORE_MESSENGER)
              break
          case "facebook-marketplace" :
               history.push(LINK_STORE_FACEBOOK_MARKET_PLACE)
              break
          default:
              break;
      }
    }


    const  resubscribeTo = (id) =>{
        axios.get(SERVER_URL+"admin/plugin/"+id,attachTokenToHeaders())
            .then((response) =>{
                setOpen(true)
                setApplication(response.data.plugin)
            })
    }





    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Applications')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{auth?.shop?.shopName}</li>
                                <li className="breadcrumb-item active">{t('Applications')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <LevelAccess auth={auth} level={2}>
                <div className="row">
                    {
                        plugins?.map((plugin, key) =>
                            <div className="col-xl-2 col-md-4" key={key}>
                                <div className="card card-height-100">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="avatar-sm me-3 flex-shrink-0">
                                                <div
                                                    className={plugin[0]?.type == 1 ? "avatar-title bg-danger rounded" : "avatar-title bg-success rounded"}>
                                                    <i className="bx bx-extension fs-24"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="text-muted mb-2">{plugin[0]?.type == 1 ? t('Extension') : t('Module')}</p>
                                                <h5 className="fs-15 mb-0">{plugin[0]?.["name" + localStorage.getItem('I18N_LANGUAGE').toUpperCase()]}</h5>
                                            </div>
                                        </div>
                                        <p className="text-muted pb-1 text-center">{plugin[0]?.[localStorage.getItem('I18N_LANGUAGE')].substring(0, 50)}...</p>


                                        <div className="d-flex mb-4 align-items-center">
                                            {
                                                !plugin?.free ?
                                                    <div className="flex-grow-1 text-center">
                                                        {
                                                            expireDate(plugin.created_at) ?
                                                                <b className="badge badge-danger">{t('Expired')}</b>
                                                                :
                                                                <small>{t('Expire')} : {moment(plugin.created_at).add('days', plugin[0].period == "Year" ? 365 : 30).format("DD/MM/YYYY")}</small>

                                                        }

                                                    </div>
                                                    :
                                                    <div className="flex-grow-1 text-center">
                                                        <b className="badge badge-success">{t('Free')}</b>
                                                    </div>
                                            }
                                        </div>

                                        {
                                            (plugin.free && plugin[0].type == 2) || (plugin[0].type == 2 && plugin.statut == 1 && !expireDate(plugin.created_at)) ?
                                                <div>
                                                    <button className="btn btn-outline-success btn-block"
                                                            onClick={event => openThisLink(plugin[0].expression)}>{t('Open')}</button>
                                                </div>

                                                :

                                                <div>
                                                    <button className="btn btn-outline-dark btn-block"
                                                            onClick={event => openThisLink(plugin[0].expression)}> {t('Configuration')}</button>
                                                </div>

                                        }

                                        {
                                            !plugin.free && expireDate(plugin.created_at) ?

                                                <button className="btn btn-info btn-block"
                                                        onClick={event => resubscribeTo(plugin[0].id)}>{t('Subscription')}</button>
                                                // <div className="row">
                                                //     <button  title={t('Uninstall')}  className="offset-lg-2 btn btn-danger btn-icon col-lg-2" onClick={event => uninstallPlugin(plugin[0].id)}><i className="bx bx-trash"></i></button>
                                                // </div>

                                                :
                                                ""
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </LevelAccess>

            <InstalledPlugin
                open={open}
                setOpen={setOpen}
                plugin={application}
                auth={auth}
                resub={true}
                reload={getPlugins}
            />

        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(Applications));
