import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {attachTokenToHeaders, numberWithCommas, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {LINK_STORE_UPTURN} from "../../../../routes/type";
import {Link} from "react-router-dom";

const MyPlan = ({t,auth}) => {

    const plan  = ["Free","Starter","Shop","Store","Premium"]
    const [pricing,setPricing] = useState({})

    useEffect(() => {
        getPricing()
    }, []);

    const getPricing = async () => {
        await axios.get(SERVER_URL+'shop/pricing/'+auth?.plan?.pricing_id,attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                setPricing(response.data.plan)
            }else{
                toast.error(t(response.data.message))

            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('MyPlan')}</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{auth?.shop?.shopName}</li>
                                <li className="breadcrumb-item active">{t('MyPlan')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="offset-lg-3 col-lg-6">
                    <div className="card">
                        <div className="card-body form-steps">
                            <div className="vertical-navs-step">
                                <div className="row gy-5">
                                    <div className="col-lg-12">
                                    <div className="nav flex-column custom-nav nav-pills" role="tablist" aria-orientation="vertical">
                                        <button className={`nav-link upturn active done`}>
                                            Plan
                                        </button>

                                     </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <table className="table table-striped">
                                            <tbody>
                                              <tr>
                                                  <td>{t('Plan')}</td>
                                                  <td>{pricing?.name}</td>
                                              </tr>
                                              <tr>
                                                  <td>{t('Price')}</td>
                                                  <td>{numberWithCommas(auth?.plan.price)} Francs CFA</td>
                                              </tr>
                                              <tr>
                                                  <td>{t('StartAt')}</td>
                                                  <td>{new Date(auth?.plan?.current_period_start).toLocaleDateString('fr')}</td>
                                              </tr>
                                              <tr>
                                                  <td>{t('EndAt')}</td>
                                                  <td className="text-danger">{new Date(auth?.plan?.current_period_end).toLocaleDateString('fr')}</td>
                                              </tr>
                                             <tr>
                                                 <td colSpan={2}>
                                                     {pricing?.description}
                                                 </td>
                                             </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-12">
                                        <Link to={LINK_STORE_UPTURN} className="btn btn-success btn-block">{t('Reabonnement')}</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </AdminLayout>
    );
};

const mapStateToProps = (state) => ({
    auth : state.loginShop
});


export default   compose(connect(mapStateToProps)) (withTranslation()(MyPlan));
