import React, {useEffect, useRef, useState} from 'react';
import {CustomModal, MiddleLoading} from "../../components";
import styled from "styled-components";
import {attachTokenToHeaders, bytesToSize, CLIENT, SERVER_IMG_URL, SERVER_URL} from "../../helpers/utils";
import axios from "axios";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {FilePond} from "react-filepond";
import swal from "@sweetalert/with-react";

const Media = ({setOpen,open,t,auth,handleInput,content}) => {
    const [dataFiles, setDataFiles] = useState([]);
    const [loading,setLoading] = useState(false)
    const [files,setFiles] = useState([])
    const [selected,setSelectected] = useState("")
    const [show,setShow] = useState(false)

    const mediaPanelRef = useRef(null);

    useEffect(() => {
        loadMedia()
    }, []);




    const loadMedia = () => {
        setLoading(true);
        axios.get(SERVER_URL+"/store/media",attachTokenToHeaders())
            .then((response) => {
                setDataFiles(response.data.media)
                setLoading(false)
                if (mediaPanelRef.current) {
                    mediaPanelRef.current.scrollLeft = mediaPanelRef.current.scrollWidth;
                }
            })
    }




    const saveMedia = (file) => {

        setFiles(file.value)

        const formdata = new FormData()
        formdata.append("type","media")
        formdata.append("shop_key",auth?.shop?.shop_key)
        for(let i =0; i < file.files.length; i++) {
            formdata.append("media",file.files[i]);
        }
          setLoading(true)
        axios.post(SERVER_URL+"/store/media/add",formdata,attachTokenToHeaders(true))
            .then((response) =>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t('Success'))
                     setFiles([])
                    loadMedia()
                }
            })


    }

    const saveMultiple = () => {
        if (files.length){
            const formdata = new FormData()
            formdata.append("type","media")
            formdata.append("shop_key",auth?.shop?.shop_key)
            for(let i =0; i < files.length; i++) {
                formdata.append("media",files[i]);
            }
            setLoading(true)
            axios.post(SERVER_URL+"/store/media/add",formdata,attachTokenToHeaders(true))
                .then((response) =>{
                    setLoading(false)
                    if (response.data.succeed){
                        loadMedia()
                        toast.success(t('Success'))
                        setFiles([])
                        setShow(false)

                    }
                })
        }else{
            toast.error(t('ChooseFileError'))
        }

    }

    const deleteMedia =  async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteMedia'),
            buttons: [t("Cancel"), t('Yes')],
            icon : "warning"
        }).then(async (check) => {
            if (check){
               const data = {
                   shop_id : auth?.shop?.id,
                   shop_key : auth?.shop?.shop_key,
               }
               setSelectected("")
                handleInput(content.value,content.id)
            }
        });
    }


    const selectedFile = (event,selected) => {
        if (event.target.checked) {
            setSelectected(selected.filename)
            handleInput(SERVER_IMG_URL+auth?.shop.shop_key+"/media/"+selected.filename,content.id)
        }else {
            setSelectected("")
            handleInput(content.value,content.id)
        }
    }




    return (
       <CustomModal open={open} setOpen={setOpen} size="xl" title="Media" centered={true}>
           <div className="row">
               {/*<div className="col-lg-6">*/}


               {/*</div>*/}
               {/*<div className="col-lg-6">*/}

               {/*    <div className="row" style={{*/}
               {/*        height: "450px", overflowY: "scroll",*/}
               {/*        marginRight: "5px"*/}
               {/*    }}>*/}
               {/*        {*/}
               {/*            loading ?*/}
               {/*                <MiddleLoading/>*/}
               {/*                :*/}
               {/*                <div>*/}
               {/*                    {*/}
               {/*                        dataFiles?.length > 0 ?*/}
               {/*                            <div className="row">*/}
               {/*                                {*/}
               {/*                                    dataFiles?.map((file, key) =>*/}
               {/*                                        <div key={key} className="col-lg-12 cursor-pointer"*/}
               {/*                                             onClick={event => selectedFile(file)}*/}
               {/*                                        >*/}
               {/*                                            <div className="row" style={{*/}
               {/*                                                border: "1px solid #cccc",*/}
               {/*                                                height: "100px",*/}
               {/*                                                borderTop: key < 2 ? "1px solid #ccc" : ""*/}
               {/*                                            }}>*/}
               {/*                                                <div className="col-lg-4">*/}
               {/*                                                    <img*/}
               {/*                                                        src={SERVER_IMG_URL + auth?.shop.shop_key + "/media/" + file.filename}*/}
               {/*                                                        alt="" style={{height: "100px"}}/>*/}
               {/*                                                </div>*/}
               {/*                                                <div className="col-lg-6 pt-3 pb-3">*/}
               {/*                                                    <div>Size : {bytesToSize(file.size)}</div>*/}
               {/*                                                    <div>Type : {file.extension}</div>*/}
               {/*                                                </div>*/}
               {/*                                                <div className="col-lg-2">*/}
               {/*                                                    <div className="pt-40 d-flex">*/}
               {/*                                                        <input type="radio"*/}
               {/*                                                               onChange={event => selectedFile(file)}*/}
               {/*                                                               name="file"*/}
               {/*                                                               checked={selected == file.filename}*/}
               {/*                                                               id={key}/>*/}
               {/*                                                        <button><i className="ri-delete-bin-2-fill"*/}
               {/*                                                                   style={{marginLeft: "4px"}}></i>*/}
               {/*                                                        </button>*/}
               {/*                                                    </div>*/}
               {/*                                                </div>*/}
               {/*                                            </div>*/}
               {/*                                        </div>*/}
               {/*                                    )*/}
               {/*                                }*/}
               {/*                            </div>*/}

               {/*                            :*/}

               {/*                            <div style={{paddingTop: "200px", textAlign: "center"}}>*/}
               {/*                                <h4>{t('EmptyList')}</h4>*/}
               {/*                            </div>*/}
               {/*                    }*/}
               {/*                </div>*/}
               {/*        }*/}
               {/*    </div>*/}
               {/*</div>*/}


               <div className="filemanager">

                   <div className="top-right d-flex justify-content-between">

                       <div className="align-left">
                           <div className="breadcrumbs"><a href="/"><i className="la la-home"></i><span
                               className="folderName">&ensp;home</span></a><span><span
                               className="folderName"></span></span></div>
                       </div>


                       <div className="align-right">
                           <button
                               className="btn btn-outline-secondary btn-sm btn-icon  float-end border-secondary-subtle w-100 pl-20 pr-20 fs-12" onClick={event => setShow(true)}>
                               <i className="la la-cloud-upload-alt la-lg"></i>
                               {t('AddPicture')}
                           </button>
                       </div>

                   </div>

                   <div className="top-panel">
                       {/*<div className="row">*/}
                       {/*    <div className="col-lg-9">*/}
                       {/*        <input type="text" value={onFile} className="form-control" disabled={true}/>*/}
                       {/*        <input type="file" accept="image/*" onChange={event => saveMedia(event.target)}*/}
                       {/*               id="upload__file" style={{display: "none"}}/>*/}
                       {/*    </div>*/}
                       {/*    <div className="col-lg-3">*/}
                       {/*        <label htmlFor="upload__file" className="btn btn-success btn-block btn-sm">*/}
                       {/*            {t('AddFile')}*/}
                       {/*        </label>*/}
                       {/*    </div>*/}
                       {/*</div>*/}


                       {
                           show ?
                               <div className="text-center adding_page">
                                   <FilePond
                                       files={files}
                                       allowMultiple={true}
                                       maxFiles={9}
                                       name="file"
                                       id="file"
                                       onupdatefiles={(fileItems) => {
                                           setFiles(fileItems.map((fileItem) => fileItem.file))
                                       }}
                                       labelIdle={t('DragNdrop')}
                                   />

                                   <button onClick={event => saveMultiple()}
                                           className="btn btn-success">{t('Submit')}</button>
                               </div>
                               :
                               <div></div>
                       }


                   </div>

                   <div className="media__panel" ref={mediaPanelRef}>


                       <br/>
                       {
                           loading ?
                               <MiddleLoading/>
                               :
                               <>

                                   {dataFiles.length > 0 ?

                                       <ul className="row mt-4">
                                           {
                                               dataFiles?.map((file, key) =>
                                                   <li className="col-lg-4 cursor-pointer" key={key}>
                                                   <div className="ml-2 cursor-pointer">
                                                           <label className="media__content__all row">
                                                               <div className="media__action">
                                                                   <div className="row">
                                                                       <div className="col-lg-6" style={{marginLeft : "-10px"}}>
                                                                           <img className="bg-white"
                                                                                src={SERVER_IMG_URL + auth?.shop.shop_key + "/media/" + file.filename}
                                                                                alt="" style={{height: "120px"}}/>
                                                                       </div>
                                                                       <div className="col-lg-6">
                                                                           <div className="row">
                                                                               <div className="offset-lg-10 col-lg-2">
                                                                                   <input type="radio"
                                                                                          onChange={event => selectedFile(event,file)}
                                                                                          name="file"
                                                                                          className={"mt-1 ml-1"}
                                                                                          checked={selected == file.filename}
                                                                                          id={key}/>
                                                                               </div>
                                                                           </div>
                                                                           <small>{file.filename.substring(0,20) + "." + file.extension}</small>
                                                                           <br/>
                                                                           <div className="info d-flex justify-content-between">

                                                                               <span className="details">{bytesToSize(file.size)}</span>
                                                                           {/*    <span title="Delete" onClick={event => deleteMedia(file.id)} className="ml-40 btn btn-sm border-0 btn-delete right text-danger cursor-pointer">
                                                                        <i className="la la-trash"></i>
                                                                    </span>*/}
                                                                           </div>
                                                                       </div>
                                                                   </div>

                                                               </div>
                                                           </label>
                                                       </div>
                                                   </li>
                                               )

                                           }
                                       </ul>

                                       :

                                       <div className="nothingfound">
                                           <div className="nofiles"></div>
                                           <h2>{t('Nofileshere')}</h2>
                                       </div>
                                   }
                               </>
                       }
                   </div>
               </div>
           </div>
       </CustomModal>

    )
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(Media));
