import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomChangeLanguage, SERVER_IMG_URL, SERVER_URL} from "../../../../../helpers/utils";
import {Link, withRouter} from "react-router-dom";
import axios from "axios";
import {compose} from "redux";
import {connect} from "react-redux";
import {logUserout} from "../../../../../store/Subdomain/Oauth/action";
import swal from "@sweetalert/with-react";
import TrialModalCart from "./TrialModalCart";
import {DeleteCart} from "../../../../../store/Subdomain/cart/action";


const TrialNavbar = ({t,shopDatas,auth,logUserout,cart,DeleteCart,launchSearch}) => {

    const pathname = useRef(window.location.pathname)
    const [current, setCurrent] = useState("");
    const [total, setTotal] = useState(0);

    const [search, setSearch] = useState("");


    useEffect(() => {
        const filter = publicLinks.filter(e => e.path == pathname.current)
        if (filter.length > 0)setCurrent(filter[0].name)

    }, [pathname]);

    const publicLinks = [
        { path: "/",  name : t('Accueil') },
        { path: "/cart",  name : t('Cart') },
        { path: "/checkout",  name : t('Checkout') },
        { path: "/products",  name : t('Products') },
        { path: "/login",  name : t('Login') },
        { path: "/signup",  name : t('Signup') },
        { path: "/product/detail/:slug",  name : t('Detail') },
        { path: "/contact-us",  name : t('Contact') },
        { path: "/categories",  name : t('Categories') },
    ];



    useEffect(() => {
         if (cart.carts?.length > 0){
             let totales = 0
             cart.carts.map((c) => {
                 totales += (c.price * c.qty)
             })
             setTotal(totales)
         }
    }, [cart]);

    useEffect(() => {

        const title = publicLinks.filter(e => e.path == window.location.pathname)
         if (title.length > 0){
             document.title = shopDatas?.shop.shopName+" :: "+  title[0]?.name
         }else{
             document.title = shopDatas?.shop.shopName+" :: "+window.location.pathname.split("/").pop()
         }

    }, [window.location.pathname]);



    const startSearch = (value) => {
        launchSearch(value)
        setSearch(value)
    }


    const logoutModal = () => {
        swal({
            title : t('Logout'),
            text : t('SureToLogout'),
            buttons : [t('Cancel'),t('Yes')],
            dangerMode : true
        }).then((pass) => {
            if (pass){
                logUserout()

            }
        })
    }



    return (
        <header className="header--style-1 header--box-shadow">


            <nav className="primary-nav primary-nav-wrapper--border">
                <div className="container">


                    <div className="primary-nav">



                        <Link className="main-logo" to="/">
                            {shopDatas?.shop.logo ? <img src={SERVER_IMG_URL+shopDatas?.shop.shop_key+"/logo/"+shopDatas?.shop.logo}  id='trialLogo' style={{width : "100px"}} alt={shopDatas?.shop.shopName} /> :  <strong className="trial__logo" style={{color : "#3377ff"}}>{shopDatas?.shop.shopName}</strong>}
                        </Link>




                        <form className="main-form">
                            <label htmlFor="main-search"></label>
                            <input className="input-text input-text--border-radius input-text--style-1" value={search} onChange={event => startSearch(event.target.value)} type="text" id="main-search" placeholder={t('Search')}/>
                        </form>




                        <div className="menu-init" id="navigation">
                            <div className="ah-lg-mode">

                                <span className="ah-close">✕ Close</span>


                                <ul className="ah-list ah-list--design1 ah-list--link-color-secondary">
                                    <li className="has-dropdown">

                                        <a><i className="far fa-user-circle"></i> {
                                            auth?.isAuthenticated ?
                                                auth?.user.firstname+" "+auth?.user.lastname
                                            : t('Login')
                                        }</a>

                                        <span className="js-menu-toggle"></span>
                                        <ul style={{width:"120px"}}>

                                            {
                                                auth?.isAuthenticated ?
                                                    <>
                                                        <li><Link to="/customer/dashboard">{t('Account').toUpperCase()}</Link></li>
                                                        <li><a className="cursor-pointer" onClick={event => logoutModal()}>{t('Logout').toUpperCase()}</a></li>
                                                    </>
                                                    :
                                                    <>
                                                        <li><Link to="/signup"><span>{t('Register').toUpperCase()}</span></Link></li>
                                                        <li><Link to="/login" ><span>{t('Login').toUpperCase()}</span></Link></li>
                                                    </>

                                            }



                                        </ul>

                                    </li>
                                    <li className="has-dropdown" data-tooltip="tooltip" data-placement="left"
                                        title="Settings">

                                        <a><i className="fas fa-language"></i> {
                                            localStorage.getItem('I18N_LANGUAGE') === "fr" ? "FRANÇAIS" : "ENGLISH"
                                        }</a>



                                        <span className="js-menu-toggle"></span>
                                        <ul style={{width:"120px"}}>
                                            <li><a className={`cursor-pointer ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "u-c-brand" }`}  onClick={event => CustomChangeLanguage('fr')}>FRANCAIS</a></li>
                                            <li><a className={`cursor-pointer ${localStorage.getItem('I18N_LANGUAGE') === "en" && "u-c-brand" }`}  onClick={event => CustomChangeLanguage('en')}>ENGLISH</a></li>
                                        </ul>

                                    </li>

                                </ul>

                            </div>

                        </div>

                    </div>

                </div>
            </nav>




            <nav className="secondary-nav-wrapper">
                <div className="container">


                    <div className="secondary-nav">





                        <div className="menu-init" id="navigation2">



                            <div className="ah-lg-mode">

                                <span className="ah-close">✕ Close</span>


                                <ul className="ah-list ah-list--design2 ah-list--link-color-secondary">
                                    <li><Link to="/">{t('Home').toUpperCase()}</Link></li>
                                    <li><Link to="/categories" >{t('Categories').toUpperCase()}</Link></li>
                                    <li><Link to="/products">{t('Products').toUpperCase()}</Link></li>
                                    <li><Link  to="/contact-us" className="active"> {t('Contact').toUpperCase()}</Link></li>
                                </ul>

                            </div>

                        </div>




                        <div className="menu-init" id="navigation3">




                            <div className="ah-lg-mode">

                                <span className="ah-close">✕ Close</span>


                                <ul className="ah-list ah-list--design1 ah-list--link-color-secondary">

                                    <li><Link to="/wishlist"><i className="far fa-heart"></i> {t('WishList')}</Link></li>
                                    <li className="has-dropdown">

                                        <a className="mini-cart-shop-link"><i className="fas fa-shopping-bag"></i>

                                            <span className="total-item-round">{cart?.carts.length}</span></a>



                                        <span className="js-menu-toggle"></span>
                                        <div className="mini-cart">


                                            <div className="mini-product-container gl-scroll u-s-m-b-15">
                                                {cart?.carts.length > 0 ?
                                                <>
                                                    {
                                                        cart?.carts.map((item,key) => <div key={key}><TrialModalCart item={item} deleteOnCart={DeleteCart}  k={key} /></div>)
                                                    }
                                                </>
                                                    :
                                                    <h4 className="text-center">{t('CartEmpty')}</h4>
                                                }

                                            </div>

                                            {cart?.carts.length > 0 ?
                                                <div className="mini-product-stat">
                                                    <div className="mini-total" style={{padding: "10px"}}>
                                                        <span className="subtotal-text">Total</span>
                                                        <span className="subtotal-value">{total} Frcs</span></div>
                                                    <hr/>
                                                    <br/>
                                                    <div className="mini-action">
                                                        <Link className="btn  btn-block btn-success" to="/cart">{t("ViewCart")}</Link></div>
                                                </div>
                                                :
                                                null
                                            }

                                        </div>

                                    </li>
                                </ul>

                            </div>

                        </div>

                    </div>

                </div>
            </nav>

        </header>
    );
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
    cart : state.cart,
});

export default compose(withRouter, connect(mapStateToProps, { logUserout, DeleteCart })) (withTranslation()(TrialNavbar))
