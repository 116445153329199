import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {APPNAME, CustomChangeLanguage, SERVER_URL} from "../../../../helpers/utils";
import {Link, Redirect, useParams} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {addNewPassword} from "../../../../store/Auth/action";
import {LINK_LANDING, LINK_STORE_DASHBOARD} from "../../../../routes/type";
import bg from "../register.png";
import {Alert} from "reactstrap";
import {MiddleLoading} from "../../../../components";
import logo from "../../../../assets/logo.png";

const PasswordAdd = ({t,addNewPassword,login : {isLoading,isAuthenticated,message}}) => {

    document.title = APPNAME+" :: "+t('Password')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('Password'))
   


    const param = useParams();
    const [error,setError] = useState("")
    const [Gloading,setGLoading] = useState(true)


    useEffect(() => {
        verifyUrl();
    }, []);

    const verifyUrl = async () => {
        setGLoading(true)
        try {
            await axios.get(SERVER_URL+"check/validity/"+param.token)
                .then((response) => {
                    setGLoading(false)
                  if (response.data.succeed){

                  }else{
                      setError(response.data.message)
                  }
                })

        } catch (error) {
            setGLoading(false)
            console.log(error);
        }
    };


    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(t('PasswordRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                 t('PasswordContaint')
            )
        ,
        confpassword: Yup.string()
            .required(t('ConfPasswordRequired'))
            .oneOf([Yup.ref('password'), null], t('PasswordSame'))
        ,
    });

    const  initialValues= {
        token : param.token,
        password : "",
        confpassword: "",
        user_agent: window.navigator.userAgent
    }


    const handleSubmit = async (values) =>{
        addNewPassword(values)
    }

    if (isAuthenticated) return <Redirect to={LINK_STORE_DASHBOARD} />;

    return (
        <section className="sign-in-section p-b-2">
            <div className="container-fluid reg_panel_all">
                <div className="row">
                    <div className="col-lg-4 left__panel">
                        <div className="">
                            <div className="header__left__panel">
                                <Link to={LINK_LANDING}><h3>{APPNAME}</h3></Link>
                                <div className="d-none d-sm-block">
                                    <button
                                        className={`text-white  ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('fr')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>FR
                                    </button>
                                    <button
                                        className={`text-white ${localStorage.getItem('I18N_LANGUAGE') === "en" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('en')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>EN
                                    </button>
                                </div>
                            </div>
                            <div className="left__panel__content">
                                <h1 className="text-white" style={{fontSize: "40px"}}>{t('CustomerEspace')}</h1>
                                <p>{t('CustomerEspaceText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 bg-white">
                        <div className="">
                            <div className="row">
                                <div className="offset-lg-3 col-lg-5" style={{marginTop: "50px"}}>
                                    <div className="sign-in-up-wrapper" style={{marginTop: "175px"}}>
                                        <div className="text-center">
                                            <img src={logo} alt="kiiako" style={{width: "150px"}}/>
                                        </div>

                                        {
                                            Gloading ?

                                                <MiddleLoading/>
                                                :


                                                <>
                                                    {
                                                        error
                                                            ?

                                                            <Alert color="danger">
                                                                {t(error)}
                                                                <hr/>
                                                                {t('ExpiredLinkText')}
                                                            </Alert>
                                                            :
                                                            <Formik
                                                                initialValues={initialValues}
                                                                onSubmit={handleSubmit}
                                                                validationSchema={validationSchema}
                                                            >
                                                                {({errors, touched, values}) => (
                                                                    <Form>
                                                                        <div className="form-groups">
                                                                            <h5 className="form-title mb-20">{t('DefinePassword')}</h5>
                                                                            <div className="">
                                                                                <label
                                                                                    htmlFor="">{t('Password')}</label>
                                                                                <Field type="password" className='front'
                                                                                       name="password"
                                                                                       placeholder={t('Password')}/>
                                                                                {errors.password && touched.password ? (
                                                                                    <div
                                                                                        className='text-danger'>{errors.password}</div>
                                                                                ) : null}
                                                                            </div>
                                                                            <br/>
                                                                            <div className="">
                                                                                <label
                                                                                    htmlFor="">{t('ConfPassword')}</label>
                                                                                <Field type="password"
                                                                                       name="confpassword"
                                                                                       className='front'
                                                                                       placeholder={t('ConfPassword')}/>
                                                                                {errors.confpassword && touched.confpassword ? (
                                                                                    <div
                                                                                        className='text-danger'>{errors.confpassword}</div>
                                                                                ) : null}
                                                                            </div>
                                                                            <br/>
                                                                            <div className="">
                                                                                <button type="submit"
                                                                                        className="template-btn primary-bg-5 btn-block"
                                                                                        disabled={isLoading}>
                                                                                    {
                                                                                        isLoading ?

                                                                                            <><span
                                                                                                className="spinner-border spinner-border-sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"/> {t('Loading')}...</>
                                                                                            :
                                                                                            <span>{t('Validate')}</span>
                                                                                    }
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                    }
                                                </>


                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    login: state.loginShop,
});

export default compose(connect(mapStateToProps, {addNewPassword}))(withTranslation()(PasswordAdd));
