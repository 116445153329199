import React, {useEffect, useState} from 'react'
import {Redirect} from "react-router-dom";
import {LINK_LANDING} from "../../../../routes/type";
import Cookies from "js-cookie";
import {withTranslation} from "react-i18next";

const  Finished = ({t}) => {

    const [counter,setCounter] = useState(60)

        useEffect(() => {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            setTimeout(()=>{
                Cookies.remove("finished")
            },60000)
        }, [counter]);



     if (!Cookies.get('finished')) return <Redirect to={LINK_LANDING} />
    return(
         <div className="finish_container">
             <div className="container">
                 <div className="row">
                     <div className="offset-lg-3 col-lg-6">
                         <div className="finish_card">
                             <div style={{
                                 borderRadius: "200px",
                                 height: "200px",
                                 width: "200px",
                                 background: "#F8FAF5",
                                 margin: "0 auto"
                             }}>
                                 <i className="checkmark">✓</i>
                             </div>
                             <h1>{t('Congratulation')}</h1>
                             <p>{t('accountCreateSuccessMail')}</p>
                         </div>
                         <p className={`${counter < 20 ? "text-danger" : "text-success"}`}
                            style={{ marginTop: "10px" }}>{t('BackToHomeIn')} <b>{counter}</b> seconds</p>
                     </div>

                 </div>
             </div>

         </div>
    )
}


export default withTranslation()(Finished)
