import React, {useEffect, useState} from 'react';
import {CustomModal} from "../../../../components";
import {withTranslation} from "react-i18next";
import * as Yup from "yup";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import {Field, Form, Formik} from "formik";

const  Categories = ({open,setOpen,t,created}) => {


    const [categories, setCategories] = useState([]);
    const [loading,setLoading] = useState(false)
    const [adding,setAdding] = useState(false)
    const [isEditable,setIsEditable] = useState(false)
    const [update,setUpdate] = useState(null)

    useEffect(() => {
        getAllCategories()
    },[])

    const getAllCategories = async () => {
        await axios.get(SERVER_URL+"admin/articles",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setCategories(response.data.categories)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch(err => {
            setLoading(false)
        })
    }



    const validationSchema = Yup.object().shape({
        category: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        category: isEditable ? update.category : '',
    }


    const handleSubmit = async (values) => {
        setLoading(true)
        if (isEditable){
            await axios.put(SERVER_URL+"admin/blog/category/edit/"+update.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setAdding(false)
                    getAllCategories()
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"admin/blog/category/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setAdding(false)
                    getAllCategories()
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    const editCategory = (item) => {
        setIsEditable(true)
        setUpdate(item)
        setAdding(true)
    }

    const addCategories = () => {
        setAdding(true)
         setUpdate("")
    }

    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteCategory'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}admin/blog/categorie/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        getAllCategories()
                        created()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }


    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledCategory') : t('SureToEnabledCategory'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/blog/categorie/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllCategories()
                        created()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={t('Categories')}  size="xl">
            <div className="row mb-5">
                <div className="d-flex align-items-center align-self-center">
                    <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Categories')}</h5>

                    <div className="flex-shrink-0">
                        <button className="btn btn-primary add-btn" onClick={event => addCategories()}>
                            <i className="ri-add-line align-bottom me-1"></i> {t('Add')}
                        </button>
                    </div>
                </div>
            </div>

            {
                adding ?
                    <div className="row mb-3">
                        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                            {({ errors, touched,values }) =>
                                <Form>
                                    <div className="row">
                                        <div  className="col-lg-9">
                                            <Field  className="form-control form-control-lg" id="category" name="category" placeholder={t('Name')}/>
                                            {errors.category && touched.category ? (
                                                <div className='text-danger'>{errors.category}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-3">
                                            <button type="submit" className="btn btn-success" disabled={loading}>
                                                {
                                                    loading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                        <span> {isEditable ? t('Edit') : t('Submit')}</span>
                                                }
                                            </button>
                                            <button className="btn btn-danger ml-1" type="reset" onClick={event => setAdding(false)}>{t('Cancel')}</button>
                                        </div>
                                    </div>

                                </Form>
                            }

                        </Formik>
                    </div>
                    :
                    ""
            }
         <div className="row">
            <table className="table table-hover">
                <thead>
                <tr>
                    <th style={{width : "70%"}}>{t('Categorie')}</th>
                    <th style={{width : "20%"}}>{t('Status')}</th>
                    <th style={{width : "10%"}}>{t('Action')}</th>
                </tr>
                </thead>
                <tbody className="list form-check-all">
                {
                    loading ?
                        <tr>
                            <td colSpan="3">
                                <p className="text-center">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                </p>
                            </td>
                        </tr>

                        :

                        <>
                            {
                                categories?.length > 0
                                    ?
                                    <>
                                        {
                                            categories?.map((category,key)=>
                                                <tr key={key}>
                                                    <td>{category.category}</td>
                                                    <td>

                                                        {  category.statut == 1 ?
                                                            <b className="badge badge-soft-success">{t('Public')}</b>
                                                            :
                                                            <b className="badge badge-soft-warning">{t('Private')}</b>
                                                        }

                                                    </td>

                                                    <td className="priority">
                                                        <ul className="list-inline  mb-0">

                                                            <li className="list-inline-item">
                                                                {category.statut == 1
                                                                    ?    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(category.id,2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                    :

                                                                    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(category.id,1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                }
                                                            </li>
                                                            <li className="list-inline-item">
                                                                                     <span className="edit-item-btn cursor-pointer" onClick={event => editCategory(category)}>
                                                                                         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                     </span>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => deleteModal(category.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                    :
                                    <tr>
                                        <td colSpan="3">
                                            <p className="text-center">{t('EmptyList')}</p>
                                        </td>
                                    </tr>
                            }
                        </>
                }

                </tbody>

            </table>
         </div>
        </CustomModal>
    );
}

export default withTranslation() (Categories)
