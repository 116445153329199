import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {
    APPNAME,
    attachTokenToHeaders,
    compare_date,
    format_date,
    SERVER_IMG_URL,
    SERVER_URL
} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Paginate from "../../../../components/Paginate/Paginate";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import CreateOrUpdate from "./CreateOrUpdate";
import Authorized from "../../../../components/Authorized/Authorized";
import Authorization from "../../../../components/Authorization";

const Coupon = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('Coupons')

    const [currentCoupon,setCurrentCoupon] = useState([]);
    const [coupons,setCoupons] = useState([])

    useEffect(() => {
        getAllCoupon(auth.shop?.id)
    },[auth])

    useEffect(() => {
        setCurrentCoupon(coupons?.slice(indexOfFirstPost, indexOfLastPost))
    },[coupons])



    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])


    const [loading, setLoading] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    const getAllCoupon = async (shop_id) => {
        await axios.get(SERVER_URL+"coupon/"+shop_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setCoupons(response.data.coupons)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(coupons?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const addCoupon = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editCoupon = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }




    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteCoupon'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}coupon/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed == true) {
                        toast.success(t(response.data.message))
                        getAllCoupon(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }





    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledCoupon') : t('SureToEnabledCoupon'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}coupon/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllCoupon(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentCoupon(coupons?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = coupons.filter(coup => {
                return coup.event.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentCoupon(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(coupons?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentCoupon.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListCoupon'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}coupon/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getAllCoupon(auth.shop.id)
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }

    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Coupon')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Coupon')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <Authorization single={true}  module="coupon"  component={true} >
              <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Coupons')}</h5>
                                <Authorization single={true}  module="coupon"    type="create" >
                                <div className="flex-shrink-0">
                                    <button className="btn btn-primary add-btn" onClick={event => addCoupon()}>
                                        <i className="ri-add-line align-bottom me-1"></i> {t('Add')}
                                    </button>
                                </div>
                                </Authorization>
                            </div>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">
                                    <div className="col-lg-4">
                                        {
                                            isCheck.length > 0 &&

                                            <>
                                                {
                                                    Gloading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                        <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col" style={{width: "50px"}}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="selectAll"
                                                       id="selectAll"
                                                       onChange={handleSelectAll}
                                                       checked={isCheckAll}
                                                />
                                            </div>
                                        </th>
                                        <th className="sort">{t('Event')}</th>
                                        <th className="sort">{t('Code')}</th>
                                        <th className="sort">{t('Number')}</th>
                                        <th className="sort">{t('Type')}</th>
                                        <th className="sort">{t('Amount')}</th>
                                        <th className="sort">{t('Numberpers')} </th>
                                        <th className="sort">{t('StartAt')}</th>
                                        <th className="sort">{t('EndAt')}</th>
                                        <th className="sort">Status</th>
                                        <th className="sort">{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="11">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentCoupon?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentCoupon?.map((coupon,key)=>
                                                                    <tr key={key}>
                                                                        <th scope="row"><div className="form-check">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       name={coupon.category}
                                                                                       id={coupon.id}
                                                                                       onChange={handleClick}
                                                                                       checked={isCheck.includes(coupon.id)}
                                                                                />
                                                                            </div>
                                                                        </th>
                                                                         <td>{coupon.event}</td>
                                                                         <td><b>{coupon.code}</b></td>
                                                                         <td>
                                                                             {coupon.availability}
                                                                         </td>
                                                                         <td>  {
                                                                             coupon.type == 1 ?
                                                                                 <b className="badge badge-outline-info">{t('Amount')}</b>
                                                                                 :
                                                                                 <b className="badge badge-outline-primary">{t('Percent')}</b>
                                                                         }</td>
                                                                         <td>
                                                                             {
                                                                                 coupon.type == 1 ?
                                                                                     <>
                                                                                         {coupon.amount} Frcs
                                                                                     </>
                                                                                     :
                                                                                     <>
                                                                                         {coupon.amount} %
                                                                                     </>
                                                                             }
                                                                         </td>
                                                                         <td>{coupon.number_pers}</td>
                                                                         <td>{format_date(coupon.start_at)}</td>
                                                                         <td>{format_date(coupon.ended_at)}</td>
                                                                         <td>

                                                                             {
                                                                                 !compare_date(coupon.ended_at)

                                                                                     ?

                                                                                     <>
                                                                                         {
                                                                                             parseInt(coupon.number_pers) > 0 && coupon.statut == 1 ?
                                                                                                 <b className="badge badge-soft-success">{t('Available')}</b>
                                                                                                 :
                                                                                                 <b className="badge badge-soft-warning">{t('Unavailable')}</b>
                                                                                         }
                                                                                     </>


                                                                                     : <b className="badge badge-soft-danger">{t('Expired')}</b>



                                                                             }
                                                                         </td>
                                                                        <td className="priority">
                                                                            <ul className="list-inline  mb-0">
                                                                                <Authorization single={true}  module="coupon"   type="action" >
                                                                                <li className="list-inline-item">
                                                                                    {coupon.statut == 1
                                                                                        ?    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(coupon.id,2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                        :

                                                                                        <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(coupon.id,1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                    }
                                                                                </li>
                                                                                </Authorization>

                                                                                <Authorization single={true}  module="coupon"    type="update" >
                                                                                <li className="list-inline-item">
                                                                                     <span className="edit-item-btn cursor-pointer" onClick={event => editCoupon(coupon)}>
                                                                                         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                     </span>
                                                                                </li>
                                                                                </Authorization>

                                                                                <Authorization single={true}  module="coupon"    type="delete" >
                                                                                <li className="list-inline-item">
                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => deleteModal(coupon.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                </li>
                                                                                </Authorization>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="11">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={coupons?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>
            </Authorization>


            <CreateOrUpdate open={open} setOpen={setOpen} created={getAllCoupon}  isEdit={isEdit} updateData={updateData} auth={auth} />



        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Coupon));
