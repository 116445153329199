import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {toast} from "react-toastify";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../../helpers/utils";
import {ContentState, convertFromHTML, EditorState} from "draft-js";

const SettingsDelivery = ({t,auth}) => {

    const [deliver, setDeliver] = useState({
        enabled : false,
        received : true,
        types : [
            {
                name : "FixedAmount",
                enabled : false,
                value : "",
                description : "FixedAmountText"
            },
            {
                name : "ByKm",
                enabled : false,
                value : "",
                description : "ByKmText"
            },
            {   name : "FreeDelivery",
                enabled : false,
                value : 0,
                description : "FreeDeliveryText"
            },
        ],
        option : "",
        value : 0,
        optionKey : ""
    })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)


    useEffect(() => {
        if (auth?.shop.config) setDeliver(JSON.parse(auth?.shop.config))
    }, [auth]);

    const updateType = (key,value,name) => {
      const item = deliver.types[key]["enabled"] = value
        setDeliver({...deliver,item})
        delete deliver.item
        setDeliver(deliver)
        setDeliver({...deliver,option: name })
        setError(null)
        if (name == "FreeDelivery"){
            setLoading(true)
             axios.post(SERVER_URL+"store/config/delivery/"+auth?.shop.id,{config : deliver},attachTokenToHeaders())
                .then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                    }else{
                        toast.error(t(response.data.message))
                    }
                })
        }
    }


    const handleSubmit = async () => {
       if (!deliver.value){
          setError(t('InputRequired'))
       }else{
           setLoading(true)
           await axios.post(SERVER_URL+"store/config/delivery/"+auth?.shop.id,{config : deliver},attachTokenToHeaders())
               .then((response)=>{
                   setLoading(false)
               if (response.data.succeed){
                   toast.success(t(response.data.message))
               }else{
                   toast.error(t(response.data.message))
               }
           })
       }
    }


    const deliveryOption = () => {

    }




    return (
        <>
            <h5>{t('ShippingSettings')}</h5>
            <hr/>
            <div className="row">
                <div className="col-lg-12 mb-20">
                    <div className="d-flex align-items-center">
                        <div className="mb-0 flex-grow-1">

                            <label>{t('TakeOrderOnShop')}</label>
                            <br/>
                            <small className="text-muted">{t('TakeOrderOnShopText')}</small>

                        </div>
                        <div className="flex-shrink-0">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" onChange={event => setDeliver({...deliver,received: event.target.checked})} role="switch" id="toggle" defaultChecked={deliver.received}/>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="col-lg-12">
                    <div className="d-flex align-items-center">
                        <div className="mb-0 flex-grow-1">

                            <label>{t('ToggleDelivery')}</label>
                            <br/>
                            <small className="text-muted">{t('ToggleDeliveryText')}</small>

                        </div>
                        <div className="flex-shrink-0">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" onChange={event => setDeliver({...deliver,enabled: event.target.checked})} role="switch" id="toggle" defaultChecked={deliver.enabled}/>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    deliver.enabled ?
                        <div className="row ml-30 mt-20">
                            {
                                deliver.types.map((item,key) =>

                                    <div className="form-check form-radio-primary mb-10" key={key}>
                                        <input className="form-check-input" type="radio" onClick={event => updateType(key,event.target.checked,item.name)} defaultChecked={deliver.option === item.name} name="formradiocolor1" id={`type-${key}`} />
                                        <label className="form-check-label" htmlFor={`type-${key}`}>
                                            {t(item.name)}
                                              <br/>
                                               <small>{t(item.description)}</small>
                                        </label>

                                        {
                                            deliver.option === item.name && key < (deliver.types.length - 1) ?
                                                <div className="row ml-10 mt-10">
                                                    <div className="col-lg-8">
                                                        <input type="number" className="form-control" value={deliver.value} onChange={event => setDeliver({...deliver,value: parseInt(event.target.value),optionKey : key})} />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <button className="btn btn-success" onClick={event => handleSubmit()} disabled={loading}>
                                                            {
                                                               loading ?
                                                                   <span className=""><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</span>
                                                                   :
                                                                   <span>{t('Submit')}</span>
                                                            }
                                                        </button>
                                                    </div>

                                                    <p><small className="text-danger">{t(error)}</small></p>
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>

                                )
                            }
                        </div>
                        :
                        ""
                }



            </div>
        </>
    );
}

export default withTranslation()(SettingsDelivery)
