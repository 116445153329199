import React from 'react';

import './loading.css';

const Loader = () => {
  return (
      <div  className="loading__body" >
          <div id="preloader">
              <div id="loader"></div>
          </div>
      </div>
  );
};

export default Loader;
