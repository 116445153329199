import React from 'react';
import {withTranslation} from "react-i18next";


const ArrowPrev =() => {
     return(
         <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M1.00017 6.77879L14 6.77879" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
             <path d="M6.24316 11.9999L0.999899 6.77922L6.24316 1.55762" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
         </svg>
     )
}

const ArrowNext =() => {
 return(
     <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M13.9998 6.77883L1 6.77883" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
         <path d="M8.75684 1.55767L14.0001 6.7784L8.75684 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
     </svg>
 )
}

const ShopPaginate = ({ postsPerPage, totalPosts ,paginate,previousPage,nextPage,t,current,color}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }


    const currentPageShow = {
    background: color,
    borderColor:color,
    color: "#fff"
    }

    const addStyle = (id,on) => {
      if (on){
          document.getElementById(id).style.background = color
          document.getElementById(id).style.borderColor = color
          document.getElementById(id).style.color = "#fff"
      }else{
          document.getElementById(id).style.background = ""
          document.getElementById(id).style.borderColor = ""
          document.getElementById(id).style.color = "#101010"
      }
    }

    return (
        <div className="tp-shop-pagination mt-20">
            <div className="tp-pagination">
                <nav>
                    <ul>
                        <li>
                            <span onMouseEnter={event => addStyle('prev',true)} onMouseLeave={event => addStyle('prev',false)} id="prev" className="tp-pagination-prev prev page-numbers cursor-pointer" onClick={()=>previousPage()}>
                               <ArrowPrev />
                            </span>
                        </li>
                        {pageNumbers.map((number) => (
                        <li  key={number}   onClick={() => paginate(number)}>
                            <span className={`cursor-pointer ${current === number ? "current" : ""}`} style={current === number ? currentPageShow : {}} >{number}</span>
                        </li>
                        ))}

                        <li>
                            <span id="next" onMouseEnter={event => addStyle('next',true)} onMouseLeave={event => addStyle('next',false)}  className="next page-numbers cursor-pointer" onClick={()=>nextPage()}>
                                <ArrowNext />
                            </span>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    );
};

export default  withTranslation() (ShopPaginate);
