import React, {useEffect, useState} from 'react';
import {CustomModal} from "../../../../components";
import {withTranslation} from "react-i18next";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";

const ChooseDeliver = ({t,open,setOpen,data,created,loadData = false,shop_id}) => {

    const [delivers, setDelivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [choosed, setChoosed] = useState(null);

    useEffect(() => {
       if (loadData) loadDelivers()
    }, [loadData]);

    const loadDelivers = () => {
        axios.get(SERVER_URL+'/delivers/list/'+data?.shop_id,attachTokenToHeaders())
          .then(res => {
                setDelivers(res.data.deliverers);
            })
            .catch((err) => {
                if (err.response.status === 401){
                    window.location.reload(true)
                }
            })
    }


    const handleSubmit = async () =>{
      if (choosed){
          setLoading(true)
          try {
              await axios.post(SERVER_URL+"order/to/deliverer",{deliverer : choosed,order_id : data.order_id,shop_id : shop_id},attachTokenToHeaders()).then((response)=> {

                  setLoading(false)
                  if (response.data.succeed) {
                      toast.success(t(response.data.message))
                      setOpen(false)
                      created()
                  }

              })
          } catch (error) {console.log(error)}
      }else{
          setLoading(false)
           toast.error(t('ChooseDeliver'))
      }
    }




    return (
        <CustomModal open={open} setOpen={setOpen} title={t('ChooseDeliver')} centered={true}  size="sm">
           <div className="row">
               <h4>{t('Deliverer')}</h4>
           </div>
            <div className="row">
                <div className="col-lg-12">
                    <select name="" id="" className="form-select" value={choosed} onChange={event => setChoosed(event.target.value)}>
                        <option value="">-</option>
                        {
                            delivers.map(deliver => (
                                <option key={deliver.id} value={deliver.deliverId}>{deliver.firstname} {deliver.lastname}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="col-lg-12 mt-20">
                    <button className="btn btn-success btn-block" disabled={loading} onClick={event => handleSubmit()} >
                        {
                            loading ?
                                <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                :
                                <span> {t('Submit')}</span>
                        }
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};

export default withTranslation()(ChooseDeliver)
