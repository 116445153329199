import React from 'react';
import {numberWithCommas} from "../../../../../helpers/utils";
import {secondCalculDicount} from "../../../../../themes/utils/utils";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

const GroceryCard = ({item,server}) => {


    return (
        <div className="tp-product-sm-item-5 d-flex align-items-center mb-20">
            <div className="tp-product-sm-thumb-5 fix">
                <Link to={"/product/detail/"+item.hash_key}>
                    <img src={server+JSON.parse(item.pictures)[0]} alt=""/>
                </Link>
            </div>
            <div className="tp-product-sm-content-5">
                <div className="tp-product-sm-tag-5">
                    <span><a href="#">{item.isSubcategory ? item.subname : item.category}</a></span>
                </div>
                <h3 className="tp-product-sm-title-5">
                    <Link to={"/product/detail/"+item.hash_key}>{item.title}</Link>
                </h3>
                <div className="tp-product-rating d-sm-flex align-items-center">
                    <div className="tp-product-rating-icon">
                        {
                            item.rating ?
                                <>
                                    {
                                        [...Array(parseInt(item.rating))].map((item,key) => (
                                            <span key={key}><i className="fa-solid fa-star"></i></span>
                                        ))
                                    }
                                </>
                                :
                                ""
                        }
                    </div>
                </div>

                {
                    parseInt(item.discount) > 0 ?
                        <div className="tp-product-price-wrapper">
                            <span className="tp-product-price old-price">{numberWithCommas(item.price)}  Francs CFA</span> <br/>
                            <span className="tp-product-price new-price">{numberWithCommas(secondCalculDicount(parseInt(item.price),parseInt(item.discount)))} Francs CFA</span>
                        </div>
                        :

                        <div className="tp-product-price-wrapper">
                            <span className="tp-product-price new-price">{numberWithCommas(item.price)}  Francs CFA</span>
                        </div>
                }
            </div>
        </div>
    )
}


export default withTranslation()(GroceryCard)

