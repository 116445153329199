import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal, MiddleLoading} from "../../../../components";
import {toast} from "react-toastify";

const Features = ({t,open,setOpen,data}) => {
    const [loading, setLoading] = useState(true);
    const [addNew, setAddNew] = useState(false);

    const [fr, setFr] = useState("");
    const [en, setEn] = useState("");
    const [customData, setCustomData] = useState([]);


    useEffect(() => {
        setCustomData(data)
       setTimeout(()=>{
               setLoading(false)
       },2000)
    }, [data]);


    const addFeature = () => {
        if (fr === "" || en === ""){
            toast.error(t('InputRequired'))
        }else{
            setCustomData(customData => [...customData, {fr : fr, en : en}])
            setFr("")
            setEn("")
        }
    }

    return (
        <CustomModal open={open} setOpen={setOpen} title={t('Features')} size="md">
            <div className="row">
                {
                    loading ?
                         <MiddleLoading />
                        :

                        <table className="table table-hover">
                            <tbody>
                            {
                                addNew ?
                                    <tr>
                                        <td><input type="text" className="form-control" value={fr} onChange={event => setFr(event.target.value)} placeholder="Fr" /></td>
                                        <td><input type="text" className="form-control" value={en} onChange={event => setEn(event.target.value)} placeholder="En" /></td>
                                        <td><button className="btn btn-success btn-sm" onClick={event => addFeature()}>{t('Add')}</button></td>
                                    </tr>
                                    :
                                    ""
                            }
                            {
                                customData?.length > 0 ?
                                    <>
                                        {
                                            customData?.map((feature,key)=>
                                                <tr key={key}>
                                                    <td colSpan={3}>{feature?.[localStorage.getItem('I18N_LANGUAGE')]}</td>
                                                    {/*<td className="text-center">*/}
                                                    {/*    <i className="bx bx-x cursor-pointer" onClick={event => setAddNew(false)}></i>*/}
                                                    {/*</td>*/}
                                                </tr>
                                            )
                                        }
                                    </>
                                    :
                                    <tr>
                                        <td colSpan={3}>{t('Emptylist')}</td>
                                    </tr>
                            }
                            </tbody>
                        </table>
                }
            </div>
        </CustomModal>
    );
}

export default withTranslation()(Features)
