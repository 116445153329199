import React from 'react';
import {withTranslation} from "react-i18next";

const Paginate = ({ postsPerPage, totalPosts ,paginate,previousPage,nextPage,t,current}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }


    return (
        <div className="d-flex justify-content-end">
            <div className="pagination-wrap hstack gap-2">
                <span className={`page-item pagination-prev cursor-pointer `} onClick={()=>previousPage()}>
                    {t('Previous')}
                </span>


                <ul className="pagination listjs-pagination mb-0">

                    {pageNumbers.map((number) => (
                        <li key={number} className={`${current == number ? "active" : null}`}  onClick={() => paginate(number)}>
                            <span className="page cursor-pointer" >{number}</span></li>
                    ))}

                </ul>
                <ul className="pagination listjs-pagination mb-0"></ul>
                <span className="page-item pagination-next cursor-pointer"  onClick={()=>nextPage()}>
                    {t('Next')}
                </span>
            </div>
        </div>
    );
};

export default  withTranslation() (Paginate);