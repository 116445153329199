import React, {Fragment, useState} from 'react';
import {flatStyle} from "../../../utils/utils";

const BorderRadius = ({data,updateValue,updateUnit}) => {
    const [toggle, setToggle] = useState(false)

    return (
        <Fragment>
            <label className="header" data-header="border_radius_header" onClick={event => setToggle(!toggle)} htmlFor="header_border_radius_header">
                <span>Border radius</span>
                <div className="header-arrow"></div>
            </label>
            <input className="header_check" type="checkbox" defaultChecked={toggle} id="header_border_radius_header"/>
            {
                toggle ?
                    <div className="row ml-20 mr-20" data-section="border_radius_header">
                        <div className="mb-3 col-sm-6" data-key="border-top-left-radius">
                            <label className="form-label" htmlFor="input-model">Top Left</label>
                            <div className="input">
                                <div className="input-group css-unit" id="cssunit-border-top-left-radius">
                                    <input name="number" type="number" className="form-control"
                                           value={flatStyle(data,'borderTopLeftRadius').value}
                                           onChange={event => updateValue("borderTopLeftRadius",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select className="form-select small-arrow" name="unit"
                                                value={flatStyle(data,'borderTopLeftRadius').unit}
                                                onChange={event => updateUnit("borderTopLeftRadius",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="border-top-right-radius">
                            <label className="form-label" htmlFor="input-model">Top Right</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-border-top-right-radius"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'borderTopRightRadius').value}
                                        onChange={event => updateValue("borderTopRightRadius",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'borderTopRightRadius').unit}
                                            onChange={event => updateUnit("borderTopRightRadius",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="border-bottom-left-radius">
                            <label className="form-label" htmlFor="input-model"
                            >Bottom Left</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-border-bottom-left-radius"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'borderBottomLeftRadius').value}
                                        onChange={event => updateValue("borderBottomLeftRadius",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'borderBottomLeftRadius').unit}
                                            onChange={event => updateUnit("borderBottomLeftRadius",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="mb-3 col-sm-6"
                            data-key="border-bottom-right-radius"
                        >
                            <label className="form-label" htmlFor="input-model"
                            >Bottom Right</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-border-bottom-right-radius"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'borderBottomRightRadius').value}
                                        onChange={event => updateValue("borderBottomRightRadius",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'borderBottomRightRadius').unit}
                                            onChange={event => updateUnit("borderBottomRightRadius",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
            }
        </Fragment>

    )
}

export default BorderRadius;
