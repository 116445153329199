import React from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../../layouts/front/Layout";
import {APPNAME} from "../../../helpers/utils";
import {Link} from "react-router-dom";
import {LINK_LEGAL} from "../../../routes/type";

const ConditionsOfUsed = ({t}) => {
    document.title = APPNAME+" :: "+t('GeneralConditions')
    return (
        <Layout>
            <section className="sign-in-section p-t-150 p-b-2">
                <div className="container-fluid ">
                  <h1 className="text-center" style={{fontSize : "70px"}}>{t('GeneralConditions')}</h1>
                    <div className="row justify-content-center" style={{marginTop : "50px",marginBottom : "150px"}}>
                        <div className="col-md-8">
                            <h2>En vigueur au 01/06/2024</h2>
                            <p>Les présentes conditions générales d'utilisation (dites « CGU »)
                                ont pour objet l'encadrement juridique des modalités
                                de mise à disposition du site et des services par Tech N Ctrl et à travers
                                sa solution KIIAKO (kiiako.io)
                                définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
                            </p>

                            <br/>
                            <p>
                                Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
                            </p>

                            <br/>
                            <p>
                                Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni
                                restriction des présentes CGU par l’utilisateur.
                                Lors de l'inscription sur le site via le Formulaire d’inscription,
                                chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant
                                le texte suivant :
                                « Je reconnais avoir lu et compris les CGU et je les accepte ».
                            </p>

                            <br/>
                            <p>
                                En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se
                                doit de renoncer à l'accès des services proposés par le site.
                            </p>

                            <br/>
                            <p>
                                <b className="text-primary">kiiako.io</b> se réserve le droit de modifier
                                unilatéralement et à tout moment le contenu des présentes CGU.
                            </p>
                            <br/>
                            <h3>Article 1 : Accès au site</h3>
                            <p>Le site <b className="text-primary">kiiako.io</b> permet à l'Utilisateur un accès aux
                                services suivants :</p>

                            <ul>
                                <li><b>‣</b> Creation et customisation de son site ecommerce</li>
                                <br/>
                                <li><b>‣</b> Ventes de ses produits sur son propre site ecommerce</li>
                                <br/>
                                <li><b>‣</b> Gestion des produits</li>
                                <br/>
                                <li><b>‣</b> Gestion des commandes</li>
                                <br/>
                                <li><b>‣</b> Suivi des ventes</li>
                                <br/>
                                <li><b>‣</b> Gestion des coupons</li>
                                <br/>
                                <li><b>‣</b> Gestion des Clients</li>
                                <br/>
                                <li><b>‣</b> Gestion des paiements</li>
                                <br/>
                                <li><b>‣</b> Assurer un service apres ventes</li>
                                <br/>
                            </ul>
                            <br/>

                            <p>
                                Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à
                                Internet.
                                Tous les frais supportés par l'Utilisateur pour accéder au service (matériel
                                informatique, logiciels, connexion Internet, etc.) sont à sa charge.
                            </p>
                            <br/>

                            <p>
                                L'acces a l'espace utilisateur est soumi a un abonnement mensuel ou annuel et certains
                                services de la plateforme demeurent payants, l’utilisateur est toutefois notifié de
                                montant total à dépenser afin d’avoir accès audit service.
                            </p>

                            <br/>

                            <p>
                                L'utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit
                                s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services
                                réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes
                                concernant son état civil et ses coordonnées, notamment son adresse email, aussi à payer
                                les frais du premier abonnement a la plateforme.
                            </p>
                            <br/>

                            <p>
                                Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa
                                désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera
                                effective dans un délai raisonnable.
                            </p>

                            <br/>
                            <p>
                                Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement
                                du site ou serveur et sous réserve de toute interruption ou modification en cas de
                                maintenance, n'engage pas la responsabilité de www.kiiako.io. Dans ces cas,
                                l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou
                                suspension de service, même sans préavis.
                            </p>

                            <br/>
                            <p>
                                L'Utilisateur a la possibilité de contacter le site par messagerie électronique à
                                l’adresse email (<em className="text-primary">contact@technctrl.com</em>)
                            </p>

                            <br/>
                            <h3>Article 2 : Collecte des données</h3>
                            <br/>
                            <h4>Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles
                                dans le respect de la vie privée conformément à la loi N° 2019-014 du 29 Octobre 2019
                                relative à la protection des données à caractère personnel.</h4>
                            <br/>
                            <p>L’Utilisateur dispose d'un droit d'accès, de rectification, de suppression et
                                d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :</p>
                            <ul>
                                <li><b>‣</b> par mail à l'adresse email <em
                                    className="text-primary">contact@technctrl.com</em></li>
                                <br/>
                                <li><b>‣</b> via un formulaire de contact</li>
                            </ul>
                            <br/>
                            <h3>Article 3 : Propriété intellectuelle</h3>
                            <br/>
                            <h4>
                                Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…)
                                font l'objet d'une protection par le Code de la propriété intellectuelle et plus
                                particulièrement par le droit d'auteur.
                            </h4>
                            <br/>
                            <p>
                                L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction,
                                publication, copie des différents contenus. Il s'engage à une utilisation des contenus
                                du site dans un cadre strictement privé, toute utilisation à des fins commerciales et
                                publicitaires est strictement interdite.
                            </p>
                            <br/>
                            <p>
                                Toute représentation totale ou partielle de ce site, par quelques procédés que ce soit,
                                sans l’autorisation expresse de l’exploitant du site Internet, constituerait une
                                contrefaçon.
                            </p>
                            <br/>
                            <p>
                                L’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et
                                sa source.
                            </p>
                            <br/>
                            <h3> Article 4 : Responsabilité</h3>
                            <br/>
                            <p>
                                Les sources des informations diffusées sur le site <b
                                className="text-primary">kiiako.io</b> sont réputées fiables. Cependant, le site ne
                                garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
                            </p>
                            <br/>
                            <p>
                                Les informations communiquées sont présentées à titre indicatif et général sans valeur
                                contractuelle.
                                Malgré des mises à jour régulières, le site <b className="text-primary">kiiako.io</b> ne
                                peut être tenu responsable
                                de la modification des dispositions administratives et juridiques survenant après la
                                publication.
                                De même, le site ne peut être tenu responsable de l’utilisation et de l’interprétation
                                de l’information contenue dans ce site.
                            </p>
                            <br/>
                            <p>
                                L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de
                                passe, quelle que soit sa forme, est interdite.
                                Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site
                                décline toute responsabilité.
                            </p>
                            <br/>
                            <p>
                                Le site <b className="text-primary">kiiako.io</b> ne peut être tenu pour responsable
                                d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de
                                l’Internaute,
                                suite à une utilisation, à l’accès ou au téléchargement provenant de ce site.
                            </p>
                            <br/>
                            <p>
                                La responsabilité du site ne peut être engagée en cas de force majeure ou du fait
                                imprévisible et insurmontable d'un tiers.
                            </p>
                            <br/>
                            <p>
                                La garantie totale de la sécurité et la confidentialité des données n’est pas assurée
                                par le site. Cependant,
                                le site s’engage à mettre en œuvre toutes les méthodes requises pour le faire au mieux.
                            </p>
                            <br/>

                            <h3> Article 5 : Liens hypertextes</h3>
                            <br/>
                            <p>
                                Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens,
                                il sortira du site <b className="text-primary">kiiako.io</b> . Ce dernier n’a pas de
                                contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait,
                                en aucun cas, être responsable de leur contenu.
                            </p>
                            <br/>
                            <h3> Article 6 : Cookies</h3>
                            <br/>
                            <p>
                                L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
                            </p>
                            <br/>
                            <p>
                                Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur
                                de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site <b className="text-primary">kiiako.io</b>.
                                Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un.
                                Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme.
                                Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.
                            </p>
                            <br/>
                            <p>
                                L’information contenue dans les cookies est utilisée pour améliorer le site <b className="text-primary">kiiako.io</b>.
                            </p>
                            <br/>
                            <p>
                                En naviguant sur le site, L’Utilisateur les accepte.
                            </p>
                            <br/>
                            <p>
                                L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies.

                            </p>
                            <br/>
                            <p>
                                À défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.
                            </p>
                              <br/>
                            <p>
                                L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
                            </p>

                            <h3> Article 7 : Publications de l'utilisateur</h3>
                             <br/>
                            <p>
                                Le site Kiiako permet aux vendeurs de publier des articles sur leurs sites ecommerces.
                            </p>
                            <br/>
                            <p>
                                Dans ses publications, le membre est tenu de respecter l'éthique ainsi que les règles de droit en vigueur.
                            </p>
                            <br/>
                            <p>
                                Le site dispose du droit d’exercer une modération à priori sur les publications et peut refuser leur mise en ligne sans avoir à fournir de justification.
                            </p>
                            <br/>
                            <p>
                                Le membre garde l’intégralité de ses droits de propriété intellectuelle.
                                Toutefois, toute publication sur le site implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter, reproduire, modifier, adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété intellectuelle.
                                Cela peut se faire directement ou par l’intermédiaire d’un tiers autorisé.
                                Cela concerne notamment le droit d’utilisation de la publication sur le web et les autres canaux de distribution de la plateforme.
                            </p>
                            <br/>
                            <p>
                                ’Utilisateur est tenu responsable de tout contenu qu’il met en ligne.
                                L’Utilisateur s’engage à ne pas publier de contenus susceptibles de porter atteinte aux intérêts de tierces personnes.
                                Toutes procédures engagées en justice par un tiers lésé à l’encontre du site devront être prises en charge par l’Utilisateur.
                            </p>
                            <br/>
                            <p>
                                La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe quelle raison et sans préavis.
                            </p>
                            <br/>
                            <h3> Article 8 :  Durée du contrat</h3>
                            <br/>
                            <p>
                                e présent contrat est valable pour une durée indéterminée.
                                Le début de l’utilisation des services du site Kiiako marque l’application du contrat à l’égard de l’Utilisateur.
                            </p>
                            <br/>
                            <h3> Article 9 : Droit applicable et juridiction compétente</h3>
                            <br/>
                            <p>
                                La législation togolaise s'applique au présent contrat.
                                En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux togolais seront seuls compétents pour en connaître.
                            </p>
                            <br/>
                            <p>
                                Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à dans <Link to={LINK_LEGAL} className="text-danger">{t('Legal')}</Link>.
                            </p>

                        </div>
                    </div>
                </div>
           </section>
        </Layout>
    );
}

export default withTranslation() (ConditionsOfUsed);
