import React,{useState,useEffect} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    APPNAME,
    attachTokenToHeaders,
    bytesToSize,
    filterAndSum, format_date,
    numberWithCommas, SERVER_IMG_URL,
    SERVER_URL
} from "../../../../helpers/utils";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {Link} from "react-router-dom";
import {LINK_DOCS, LINK_STORE_MY_PLAN, LINK_STORE_UPTURN,LINK_STORE_CHANGE_PLAN} from "../../../../routes/type";
import {toast} from "react-toastify";
import swal from '@sweetalert/with-react'
import axios from 'axios'
import { Chart } from "react-google-charts";
import illustrator from "../../../../assets/admin/images/user-illustarator-2.png"
import {MiddleLoading} from "../../../../components";
import {loadMe} from "../../../../store/Auth/action";
import CountTo from "react-count-to";

const  StoreDashboard = ({t,auth,loadMe}) => {
    document.title = APPNAME+" :: "+t('Dashboard')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('Dashboard'))



    const [sloading,setSLoading] = useState(false)
    const [loading,setLoading] = useState(false)
   const [trending,setTrending] = useState([])
   const [latestOrders,setLatestOrders] = useState([])
   const [data,setData] = useState([])
   const [storagePercent,setStoragePercent] = useState(0)
    const [bestProducts, setBestProducts] = useState([]);

    const SwitchShop = () => {
        swal({
            text :   t('ActiveShop'),
            buttons : [t('Cancel'),t('Yes')],
        }).then(async (ev) => {
            if (ev){
                try {
                    setSLoading(true)
                    const response = await axios.get(`${SERVER_URL}toggle/shop/2`,attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        loadMe()
                        setTimeout(() => {
                            setLoading(false)
                            setSLoading(false)
                        },1000)
                    }
                } catch (error) {
                    console.log(error)
                    setLoading(false)
                    setSLoading(false)
                    toast.success(t("Erreur"))
                }
            }

        })
    }

    useEffect(() => {
        if (auth?.shop?.firstTime == 0) getDashboard(auth.shop?.id,auth.shop?.shop_key)
    },[auth])

    const options = {
         legend: "none",
          is3D: true,
         colors: ['#e0440e', '#0AB39C']
    };


    const getDashboard = async (shop_id,shop_key) => {
        await axios.get(`${SERVER_URL}dashboard/?shop_key=${shop_key}&shop_id=${shop_id}`,attachTokenToHeaders())
            .then((response)=>{
            if (response.data.succeed){
                setTrending(response.data.trending)
                setLatestOrders(response.data.latestOrders)
                setBestProducts(response.data.productsBest)

                setData([["Stockage", "Plan"],
                    [t('UsedStorage'),  response.data.trending?.folderSize],
                    [t('RestStorage'), (auth?.sidebar_n_storage?.storage - response.data.trending?.folderSize)]])

                const calc =  (response.data.trending?.folderSize * 100) / auth?.sidebar_n_storage?.storage
                setStoragePercent(calc)

            }else{
                toast.error(t(response.data.message))
            }
        })
            .catch((err) => {
               if (err.response.status === 401){
                   window.location.reload(true)
               }
            })
    }

    const exportOrderListAsExcel = () => {
      if (latestOrders.length > 0) {
          const headers = [
              t('Order')+" ID",
              t('Product'),
              t('Customer'),
              t('Address'),
              t('City'),
              t('Country'),
              t('Shipping'),
              t('ShippingCharge'),
              t('Discount'),
              t('PaymentMethod'),
              t('Amount'),
              t('Status')
          ]
          const url = SERVER_URL+"download/excel"
          const data = {
              headers : headers,
              shop_id : auth.shop?.id
          }
          axios
              .post(url,data, {responseType: 'blob'})
              .then(({ data }) => {
                  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                  const link = document.createElement('a');
                  link.href = downloadUrl;
                  link.setAttribute('download', auth.shop.shopName+"-"+t('Order')+'-list.xlsx')
                  document.body.appendChild(link);
                  link.click();
                  link.remove();

              });
      }else{
          toast.error(t('EmptyList'))
      }
    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Dashboard')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="#">{auth?.shop?.shopName}</a></li>
                                <li className="breadcrumb-item active">{t('Dashboard')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid">
                {
                    auth.shop?.firstTime == 1 &&

                        <div className="alert alert-success" role="alert">
                            <h1 className="alert-heading text-success">{t('WelcomeDash')} {APPNAME}</h1>
                            <hr/>
                            <p>{t('DefaultStartText')}</p>
                            <br/>

                            <p>
                                {t('ActivatedShop')} <br/>
                                <br/>
                                <button className="btn btn-success btn-sm" onClick={event => SwitchShop()} disabled={sloading}>
                                    {
                                        sloading ?
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                            </>
                                            :
                                            <span>{t('ActiveShop')}</span>
                                    }
                                </button>
                            </p>

                        </div>

                }


                {
                    (auth?.plan.trial == 0 && auth?.plan.current_period_start) || (auth?.plan.trial == 1 && auth?.plan.trial_start) ?

                <>
                    {
                        loading ?
                            <MiddleLoading />
                            :

                            <>
                                <div className="row">
                                    <div className="col-xxl-5">
                                        <div className="d-flex flex-column h-100">

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card card-animate">
                                                        <div className="card-body p-0">

                                                            {auth?.planDays <= 10 ?

                                                                <div className="alert alert-danger border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                                                                    <i data-feather="alert-triangle"
                                                                       className="text-danger me-2 icon-sm"></i>
                                                                    <div className="flex-grow-1 text-truncate">
                                                                        {t('YoutPlan')} <b className="text-uppercase">{auth?.plan.name}</b> {t('Expir')}
                                                                        <b> {auth?.planDays}</b> {t('Days')}
                                                                    </div>
                                                                </div>

                                                                : null}


                                                            {auth?.planDays <= 20 && auth?.planDays > 10 ?

                                                                <div className="alert alert-info border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                                                                    <i data-feather="alert-triangle"
                                                                       className="text-warning me-2 icon-sm"></i>
                                                                    <div className="flex-grow-1 text-truncate">
                                                                        {t('YoutPlan')} <b className="text-uppercase">{auth?.plan.name}</b> {t('Expir')}
                                                                        <b> {auth?.planDays}</b> {t('Days')}
                                                                    </div>
                                                                </div>

                                                                : null}

                                                            {auth?.planDays <= 30 && auth?.planDays > 20 || auth?.planDays >= 30 ?

                                                                <div className="alert alert-success border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                                                                    <i data-feather="alert-triangle"
                                                                       className="text-warning me-2 icon-sm"></i>
                                                                    <div className="flex-grow-1 text-truncate">
                                                                        {t('YoutPlan')} <b className="text-uppercase">{auth?.plan.name}</b> {t('Expir')} <b>{auth?.planDays}</b> {t('Days')}
                                                                    </div>
                                                                </div>

                                                                : null}

                                                            <div className="row align-items-end">
                                                                <div className="col-sm-8">
                                                                    <div className="p-3">
                                                                        {
                                                                            auth?.plan.pricing_id == 1 ?
                                                                                <p className="fs-16 lh-base">{t('YourPlanFrom')} <span className="fw-semibold">Free</span>, <br/> {t('ChoosePlan')} <i className="mdi mdi-arrow-right"></i></p>
                                                                                :

                                                                                <p className="fs-16 lh-base">{t('DetailOfYourPlan')}<i className="mdi mdi-arrow-right"></i></p>

                                                                        }
                                                                        <div className="mt-3">
                                                                            {
                                                                                auth?.plan.pricing_id == 1 ?
                                                                                    <Link to={LINK_STORE_CHANGE_PLAN} className="btn btn-success waves-effect waves-light">{t('UpgradeAccount')}</Link>
                                                                                    :
                                                                                    <Link to={LINK_STORE_MY_PLAN} className="btn btn-success waves-effect waves-light">{t('MyPlan')}</Link>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="px-3">
                                                                        <img src={illustrator} className="img-fluid" alt=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>



                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">{t('Orders').toUpperCase()}</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        <span className="counter-value" data-target="28.05">
                                                                            <CountTo to={trending?.orders??0} speed={1000} />
                                                                            </span>
                                                                    </h2>
                                                                    <p className="mb-0 text-muted">
                                                                        {t('OpenOrders')}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-primary rounded-circle fs-2">
                                                                <i className="ri-shopping-bag-2-fill align-middle"></i>
                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">{t('Products').toUpperCase()}</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        <span className="counter-value" data-target="97.66">
                                                                             <CountTo to={trending?.products??0} speed={1000} />
                                                                        </span>
                                                                    </h2>
                                                                    <p className="mb-0 text-muted">
                                                                        {t('ProductsTotal')}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-danger rounded-circle fs-2">
                                                                <i className="ri-honour-line"></i>
                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>


                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">{t('Customers').toUpperCase()}</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        <span className="counter-value" data-target="3">
                                                                             <CountTo to={trending?.customers??0} speed={1000} />
                                                                        </span>
                                                                    </h2>
                                                                    <p className="mb-0 text-muted">
                                                                        {t('CustomersTotal')}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-warning rounded-circle fs-2">
                                                                <i className="ri-user-2-line"></i>
                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">{t('Earning').toUpperCase()}</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                                                        className="counter-value"
                                                                        data-target="33.48">
                                                                        {trending?.payments ? numberWithCommas(filterAndSum(trending?.payments,"amount",0)) : 0} Frcs
                                                                    </span>
                                                                    </h2>

                                                                    <p className="mb-0 text-muted">
                                                                        {t('MoneyTotal')}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-success rounded-circle fs-2">
                                                                 <i className="ri-money-dollar-circle-fill"></i>
                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                    <div className="col-xxl-7">
                                        <div className="row h-100">
                                            <div className="col-xl-6">
                                                <div className="col-md-12">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">{t('AvailableBalance').toUpperCase()}</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        <span className="counter-value"
                                                                              data-target="28.05">{trending?.payments ? numberWithCommas(filterAndSum(trending?.payments, "amount", 2)) : null} Frcs</span>
                                                                    </h2>
                                                                    <p className="mb-0 text-muted">
                                                                        {t('MoneyTotalAvailable')}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                    <span
                                                        className="avatar-title bg-g rounded-circle fs-2">
                                                        <i className="ri-money-dollar-box-fill align-middle"></i>
                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <br/>
                                                <div className="col-md-12">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">{t('MoneyPending').toUpperCase()}</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        <span className="counter-value"
                                                                              data-target="28.05">{trending?.payments ? numberWithCommas(filterAndSum(trending?.payments, "amount", 1)) : null} Frcs</span>
                                                                    </h2>
                                                                    <p className="mb-0 text-muted">
                                                                        {t('MoneyTotalReceived')}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                                        <span
                                                                            className="avatar-title bg-success rounded-circle fs-2">
                                                                            <i className="ri-money-dollar-box-fill align-middle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="col-md-12">
                                                    <div className="card card-animate">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <p className="fw-medium text-muted mb-0">{t('MoneyReceived').toUpperCase()}</p>
                                                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        <span className="counter-value"
                                                                              data-target="28.05">{trending?.payments ? numberWithCommas(filterAndSum(trending?.payments, "amount", 3)) : null} Frcs</span>
                                                                    </h2>
                                                                    <p className="mb-0 text-muted">
                                                                        {t('MoneyTotalReceived')}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                                        <span
                                                                            className="avatar-title bg-success rounded-circle fs-2">
                                                                            <i className="ri-money-dollar-box-fill align-middle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            <div className="col-xl-6">
                                                <div className="card card-animate">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className="fw-medium text-muted mb-0">{t('Storage').toUpperCase()}</p>
                                                                <h2 className="mt-4 ff-secondary fw-semibold">
                                                                        <span
                                                                            className="counter-value">{bytesToSize(trending?.folderSize)}</span>
                                                                </h2>
                                                                <p className="mb-0 text-muted">
                                                                         <span
                                                                             className="badge bg-light text-success mb-0">
                                                                         <i className="mdi mdi-database align-middle"></i> {bytesToSize(auth?.sidebar_n_storage?.storage - trending?.folderSize)}
                                                                        </span>
                                                                    {t('RestStorage')}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className="avatar-sm flex-shrink-0">
                                                                    <span
                                                                        className="avatar-title bg-primary rounded-circle fs-2">
                                                                        <i className="mdi mdi-database align-middle"></i>
                                                                    </span>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                <div className="card card-animate">
                                                    <div className="card-header align-items-center d-flex">
                                                        <h4 className="card-title mb-0 flex-grow-1">{t('Storage')}</h4>
                                                    </div>

                                                    <div className="card-body">

                                                        {
                                                            data.length > 0
                                                                ?
                                                                <Chart
                                                                    chartType="PieChart"
                                                                    data={data}
                                                                    options={options}
                                                                    width={"100%"}
                                                                    height={"180px"}
                                                                />

                                                                : <MiddleLoading/>
                                                        }


                                                        <div className="px-2 py-2 mt-1">
                                                            <p className="mb-1">{t('UsedStorage')} <span
                                                                className="float-end">{storagePercent.toFixed(3)}%</span>
                                                            </p>
                                                            <div className="progress mt-2" style={{height: "6px"}}>
                                                                <div
                                                                    className="progress-bar progress-bar-striped bg-success"
                                                                    role="progressbar"
                                                                    style={{width: `${storagePercent}%`}}
                                                                    aria-valuenow={storagePercent}
                                                                    aria-valuemin="0"
                                                                    aria-valuemax={storagePercent}></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-xl-9">
                                        <div className="card">
                                            <div className="card-header align-items-center d-flex">
                                                <h4 className="card-title mb-0 flex-grow-1">Commandes récentes</h4>
                                                <div className="flex-shrink-0">
                                                    <button type="button" className="btn btn-info btn-sm"
                                                            onClick={event => exportOrderListAsExcel()}
                                                            disabled={!latestOrders.length}>
                                                        <i className="ri-file-list-3-line align-middle"></i> Exporter en
                                                        Excel
                                                    </button>
                                                </div>
                                            </div>


                                            <div className="card-body">
                                                <div className=" table-card">
                                                    <table
                                                        className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                                        <thead className="text-muted table-light">
                                                        <tr>
                                                            <th scope="col">ID {t('Order')}</th>
                                                            <th scope="col">{t('Amount')}</th>
                                                            <th scope="col">{t('Product')}</th>
                                                            <th scope="col">{t('Customer')}</th>
                                                            <th scope="col">Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            latestOrders?.length > 0
                                                                ?
                                                                <>
                                                                {
                                                                        latestOrders.map((order, index) => (
                                                                            <tr key={index}>
                                                                                <td><b className="text-primary">#{order?.order_id}</b></td>
                                                                                <td>{numberWithCommas(order?.total_amount)} Francs</td>
                                                                                <td>{
                                                                                    JSON.parse(order.cart_content).map((cart,k) => <b className='badge badge-primary ml-10' key={k}>{cart.name}</b>)
                                                                                }</td>
                                                                                <td>{order?.delivery_fullname}</td>

                                                                                <td className="status">
                                                                                    {order.delivery_status == 1 ? <span
                                                                                        className="badge badge-soft-primary text-uppercase">{t("Pending")}</span> : null}
                                                                                    {order.delivery_status == 2 ? <span
                                                                                        className="badge badge-soft-info text-uppercase">{t("Packed")}</span> : null}
                                                                                    {order.delivery_status == 3 ? <span
                                                                                        className="badge badge-soft-success text-uppercase">{t("OnTheWay")}</span> : null}
                                                                                    {order.delivery_status == 4 ? <span
                                                                                        className="badge badge-soft-success text-uppercase">{t("Delivered")}</span> : null}
                                                                                    {order.delivery_status == 5 ? <span
                                                                                        className="badge badge-soft-warning text-uppercase">{t("Return")}</span> : null}
                                                                                    {order.delivery_status == 6 ? <span
                                                                                        className="badge badge-soft-danger text-uppercase">{t("Cancel")}</span> : null}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </>
                                                                :

                                                                <tr>
                                                                    <td colSpan={6}><p
                                                                        className="text-center">{t('EmptyList')}</p>
                                                                    </td>
                                                                </tr>
                                                        }


                                                        </tbody>

                                                    </table>

                                                </div>
                                                <br/>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3">
                                        <div className="card">
                                            <div className="card-header align-items-center d-flex">
                                                <h4 className="card-title mb-0 flex-grow-1">Articles le plus vendu</h4>
                                            </div>


                                            <div className="card-body"  style={{minHeight : "135px"}}>
                                                <div className=" table-card">
                                                    <table
                                                        className="table table-hover table-centered align-middle table-nowrap mb-0">
                                                        <tbody>
                                                        {
                                                            bestProducts.length > 0
                                                                ?
                                                                <>
                                                                {
                                                                    bestProducts.map((product, index) =>
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="avatar-sm bg-light rounded p-1 me-2">
                                                                                        <img src={`${SERVER_IMG_URL+auth.shop?.shop_key}/products/${JSON.parse(product.pictures)[0]}`} alt="" className="img-fluid d-block"/>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h5 className="fs-14 my-1">
                                                                                            <Link to={'/store/product/detail/'+product.hash_key}
                                                                                            className="text-reset">{product.title}</Link>
                                                                                        </h5>
                                                                                        <span className="text-muted">{format_date(product.created_at)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                </>
                                                                :
                                                                <tr><td colSpan={4}><p className="text-center">{t('EmptyList')}</p></td></tr>
                                                        }


                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </>
                    }



                </>

                        : null}




            </div>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
 auth : state.loginShop
});


export default   compose(connect(mapStateToProps, {  loadMe  })) (withTranslation()(StoreDashboard));
