import {
    ADD_PASS_FAIL,
    ADD_PASS_LOADING, ADD_PASS_SUCCESS,
    LOGIN_SHOP_FAIL,
    LOGIN_SHOP_LOADING, LOGIN_SHOP_SUCCESS, LOGOUT_SUCCESS, ME_FAIL, ME_LOADING, ME_SUCCESS,
} from './type';
import Cookies from "js-cookie";

const initialState = {
    token: Cookies.get('auth-token')??null,
    isAuthenticated: false,
    isLoading: false,
    shop : null,
    planDays : 0,
    plan : [],
    me: null,
    error: null,
    appLoaded: false,
    message : null,
    sidebar_n_storage : null,
    plugins : [],
    permissions : [],
};


 const  LoginReducer = (state = initialState, { type, payload })  =>{
    switch (type) {
        case LOGIN_SHOP_LOADING:
        case ME_LOADING:
        case ADD_PASS_LOADING:
            return {
                ...state,
                isLoading: true,
                appLoaded: false,
                error: null,
            };

        case LOGIN_SHOP_SUCCESS:
        case ADD_PASS_SUCCESS:
        case ME_SUCCESS:
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + (2 * 60 * 60 * 1000));
            Cookies.set('auth-token',payload.token,{ expires: expirationDate })
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                token: payload.token,
                me: payload.me,
                shop: payload.shop,
                planDays: payload.planDays,
                plan: payload.plan,
                message : payload.message,
                sidebar_n_storage : payload.sidebar_n_storage,
                plugins : payload.plugins,
                permissions : payload.permissions,
                error: null,
            };


        case LOGIN_SHOP_FAIL:
        case ME_FAIL:
        case ADD_PASS_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                me: null,
                error: payload.error,
                appLoaded: true,
            };

        case LOGOUT_SUCCESS:
            Cookies.remove('auth-token');
            Cookies.remove('_ot')
            return {
                ...state,
                token: null,
                me: null,
                 shop : null,
                plan: [],
                planDays: 0,
                sidebar_n_storage: [],
                plugins: [],
                permissions: [],
                isAuthenticated: false,
                isLoading: false,
                error: null,
            };

        default:
            return state;
    }
}

export default LoginReducer
