import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {LINK_STORE_INVOICE} from "../../../../routes/type";
import {
    APPNAME,
    attachTokenToHeaders, Capitalize,
    checkProperties,
    DOMAINE, format_date, numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL,
} from '../../../../helpers/utils'
import axios from "axios";
import {toast} from "react-toastify";

const InvoiceDetail = ({t}) => {

    document.title = APPNAME + " :: "+ t('Invoice')
    const [detail,setDetail] = useState(null)
    const [customer,setCustomer] = useState(null)
    const [seller,setSeller] = useState(null)
    const [Gloading,setGloading] = useState(true)


    useEffect(()=>{
        getOrderSingle()
    },[])

    const getOrderSingle = async () => {
        setGloading(true)
        await axios.get(SERVER_URL+"order/single/"+window.location.pathname.split('/').pop(),attachTokenToHeaders()).then((response)=>{
            setGloading(false)
            if (response.data.succeed){
                setDetail(response.data.order[0])
                setCustomer(response.data.customer)
                setSeller(response.data.seller)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }


    const calculateSubtotal = () => {
        let price = 0
        JSON.parse(detail?.cart_content)?.map((item) => price =  price + (item.price * item.qty))
        return price
    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('InvoiceDetails')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href={LINK_STORE_INVOICE}>{t('Invoice')}</a></li>
                                <li className="breadcrumb-item active">{t('InvoiceDetails')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
                        {
                            Gloading  ?
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="text-center p-t-180 p-b-180">
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className="row justify-content-center" id="contentInvoice">

                                    <div className="col-xxl-9">
                                        <div className="card" >
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card-header border-bottom-dashed p-4">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <img src={`${SERVER_IMG_URL}${seller?.shop_key}/logo/${seller?.logo}`} className="card-logo card-logo-dark" alt={seller?.shopName} height="100"/>
                                                                <div className="mt-sm-5 mt-4">
                                                                    <h6 className="text-muted text-uppercase fw-semibold">{t('Address')}</h6>
                                                                    <p className="text-muted mb-1" id="address-details">{seller?.address}</p>
                                                                </div>
                                                            </div>
                                                            <div className="flex-shrink-0 mt-sm-0 mt-3">
                                                                <h6><span className="text-muted fw-normal">Email:</span><span id="email"> {seller?.email}</span></h6>
                                                                <h6><span className="text-muted fw-normal">{t('Website')}:</span> <a href={"https://"+seller?.domaine +'.'+ DOMAINE} className="link-primary" target="_blank" id="website">{"https://"+seller?.domaine +"."+ DOMAINE}</a></h6>
                                                                <h6 className="mb-0"><span className="text-muted fw-normal">Contact No: </span><span id="contact-no"> {seller?.phone}</span></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="card-body p-4">
                                                            <div className="row g-3">
                                                                <div className="col-lg-4">
                                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">{t('Invoice')} No</p>
                                                                    <h5 className="fs-14 mb-0">#<span id="invoice-no">{detail?.order_id}</span></h5>
                                                                </div>

                                                                <div className="col-lg-4" style={{textAlign : "center"}}>
                                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                                                                    <h5 className="fs-14 mb-0"><span id="invoice-date">{format_date(detail?.created_at)}</span></h5>
                                                                </div>


                                                                <div className="col-lg-4" style={{textAlign : "right"}}>
                                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">Total</p>
                                                                    <h5 className="fs-14 mb-0"><span id="total-amount">{numberWithCommas(detail?.total_amount)}</span> Francs</h5>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="card-body p-4 border-top border-top-dashed">
                                                            <div className="row g-3">
                                                                <div className="col-6">
                                                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">{t('BillingAddress')}</h6>
                                                                    <p className="fw-medium mb-2" id="billing-name">{customer?.firstname} {customer?.lastname}</p>
                                                                    <p className="text-muted mb-1" id="billing-address-line-1">{detail?.delivery_address} {detail?.delivery_city} , {detail?.delivery_country}</p>
                                                                    <p className="text-muted mb-1"><span>Phone : </span><span id="billing-phone-no">{customer?.phone}</span></p>
                                                                </div>

                                                                <div className="col-6" style={{textAlign : "right"}}>
                                                                    <h6 className="text-muted text-uppercase fw-semibold mb-3"> {t('ShippingAddress')}</h6>
                                                                    <p className="fw-medium mb-2" id="shipping-name">{detail?.delivery_fullname}</p>
                                                                    <p className="text-muted mb-1" id="shipping-address-line-1">{detail?.delivery_address}</p>
                                                                    <p className="text-muted mb-1"><span id="shipping-phone-no">{detail?.delivery_city} , {detail?.delivery_country}</span></p>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="card-body p-4">
                                                            <div className="table-responsive">
                                                                <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                                                                    <thead>
                                                                    <tr className="table-active">
                                                                        <th scope="col" style={{width: "50px"}}>#</th>
                                                                        <th scope="col">Details</th>
                                                                        <th scope="col">{t('Price')}</th>
                                                                        <th scope="col">{t('Quantity')}</th>
                                                                        <th scope="col" className="text-end">{t('Amount')}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody id="products-list">
                                                                    {
                                                                        JSON.parse(detail?.cart_content)?.map((item,key) =>

                                                                   <tr key={key}>
                                                                        <th scope="row">{key + 1}</th>
                                                                        <td className="text-start">
                                                                            <span className="fw-medium">{item.name}</span>
                                                                        </td>
                                                                        <td>{item.price} Frcs</td>
                                                                        <td>{item.qty}</td>
                                                                        <td className="text-end">{item.price * item.qty} Frcs</td>
                                                                    </tr>

                                                                        )
                                                                    }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="border-top border-top-dashed mt-2">
                                                                <table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{width:"250px"}}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>Sub Total</td>
                                                                        <td className="text-end">{calculateSubtotal()} Frcs</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t('Tax')} :</td>
                                                                        <td className="text-end">{detail?.tax??0} Frcs</td>
                                                                    </tr>
                                                                    {
                                                                        parseInt(detail?.discount_percent) ?

                                                                            <tr>
                                                                                <td>{t('Discount')} :</td>
                                                                                <td className="text-end"> - {detail?.discount_percent} Frcs</td>
                                                                            </tr>
                                                                            : null}
                                                                    {
                                                                        parseInt(detail?.shipping_status) == 1 ?
                                                                            <tr>
                                                                                <td>{t('ShippingCharge')} :</td>
                                                                                <td className="text-end">{detail?.shipping_charge??0} Frcs</td>
                                                                            </tr>
                                                                            : null}


                                                                    <tr className="border-top border-top-dashed fs-15">
                                                                        <th scope="row">Total :</th>
                                                                        <th className="text-end">{detail?.total_amount} Frcs</th>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                            <div className="mt-3">
                                                                <h6 className="text-muted text-uppercase fw-semibold mb-3">{t("PaymentDetails")}:</h6>
                                                                <p className="text-muted mb-1"> {t('PaymentMethod')} : <span className="fw-medium" id="payment-method">{t(Capitalize(detail?.payment_method.split('-')[0].toLowerCase()))}</span></p>
                                                                <p className="text-muted">Total : <span id="card-total-amount">{detail?.total_amount} Frcs</span></p>
                                                            </div>
                                                            <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                                                <span  onClick={event => window.print()} className="btn btn-success cursor-pointer"><i className="ri-printer-line align-bottom me-1"></i> {t('Print')}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                        }

        </AdminLayout>
    );
}

export default withTranslation()(InvoiceDetail)
