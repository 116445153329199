import React, {useEffect, useRef, useState} from 'react';
import "./accordion.css"
import {flatData} from "../../../utils/utils";

const Accordion = ({block,customStyle,toggleHightLight,isEditor}) => {
    const [items, setItems] = useState([]);
    const [openIndexes, setOpenIndexes] = useState([]);
    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const accordion = useRef()

    const getBlockSize = () => {
        setDimension({top :accordion?.current?.offsetTop, left : accordion?.current?.offsetLeft, width : accordion?.current?.offsetWidth, height : accordion?.current?.offsetHeight,nodeName : accordion?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    const openAccordion = (key) => {
        setOpenIndexes((prevOpenIndexes) => {
            if (prevOpenIndexes.includes(key)) {
                return prevOpenIndexes.filter((index) => index !== key);
            } else {
                return [...prevOpenIndexes, key];
            }
        });
    };

    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);



    return (
      <div className="row pt-10 pb-10"
           style={customStyle}
           ref={accordion}
           onMouseEnter={() => isEditor && getDimension(block, true)}
           onMouseLeave={() => isEditor && getDimension(block, false)}
      >
          <div className="col-lg-9 mx-auto">
              <div id="accordionContent" className="accordion shadow">
                  {items?.map((item, key) =>
                      <div className="card" style={{paddingBottom : "-20px"}} key={key}>
                          <div className="card-header bg-white shadow-sm border-0">
                              <h2 className="mb-0">
                                  <b
                                          aria-expanded={openIndexes.includes(key)}
                                          aria-controls={"collapse__" + key}
                                          onClick={() => openAccordion(key)}
                                          className="btn text-dark font-weight-bold text-uppercase collapsible-link">
                                      {flatData(item, "title")}
                                  </b>
                              </h2>
                          </div>
                          <div id={"collapse__" + key}
                               className={`collapse ${openIndexes.includes(key) ? 'show' : ''}`}
                               data-parent="#accordionContent">
                              <div className="card-body p-5">
                                  <p className="font-weight-light m-0">{flatData(item, "content")}</p>
                              </div>
                          </div>
                      </div>
                  )}
              </div>
          </div>
      </div>
    )
}

export default Accordion;