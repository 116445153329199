import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import placeholder from '../template/styles/placeholder.jpg'
import axios from "axios";
import {SERVER_IMG_URL,SERVER_URL} from "../../../../helpers/utils";
import TrialEmpty from "../template/blocs/TrialEmpty";
import {Link} from "react-router-dom";


const  TrialCategories = ({t}) => {


    const [shop,setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));
    const [categories,setCategories] = useState([]);


    useEffect(() => {
        if (shop){
            getCategories()
        }
    }, []);

    const getCategories = async () => {
        await axios.get(SERVER_URL+"front/alltrialhome/"+shop?.id,).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.categories)
            }else{
                // toast.error(t(response.data.message))
            }
        })
    }


    return (
        <div className=""  style={{background : "#f4f4f4"}}>
            <div className="u-s-p-y-90">
                <div className="container u-s-p-b-80">
                    <div className="row">
                        <h1 className="text-center">{t('Categories')}</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row">

                        {
                            categories?.length > 0 ?
                                <>
                                    {
                                        categories?.map((cate,key) =>
                                            <div className="col-lg-4" key={key}>
                                                <div className="bp bp--img u-s-m-b-30">

                                                       <div className="bp__wrap" style={{ boxShadow: "0 2px 15px rgba(0, 0, 0, 0.2)"}}>
                                                           <div className="bp__thumbnail">
                                                               <Link to={`/categorie/${cate.slug}`} className="aspect aspect--bg-grey u-d-block" style={{height : "250px"}} >
                                                                   <img className="aspect__img" src={cate.image ? SERVER_IMG_URL+shop?.shop_key+"/categories/"+cate.image : placeholder} alt=""/>
                                                               </Link>
                                                           </div>
                                                           <div className="bp__info-wrap">
                                                               <p className="text-center">
                                                                   <span className="bp__h1"><a>{cate.category}</a></span>
                                                               </p>
                                                               <p className="bp__p text-center"><small>{cate.description.substring(0,200)}...</small></p>
                                                           </div>
                                                       </div>

                                                </div>

                                            </div>
                                        )
                                    }
                                </>
                                :
                                <TrialEmpty />
                        }

                    </div>

                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TrialCategories)
