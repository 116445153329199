import React, {Fragment, useEffect, useRef, useState} from 'react';
import {calculDicount, flatData, secondCalculDicount} from "../../utils/utils";
import {SwiperSlide,Swiper} from "swiper/react";
import {EffectCards, Navigation, Pagination} from "swiper/modules";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {CalculateDiscount, numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import QuickView from "../../../components/Card/QuickView";
import {MiddleLoading} from "../../../components";
import IconHeart from "../../../components/Icons/IconHeart";
import IconEye from "../../../components/Icons/IconEye";
import IconShoppingCart from "../../../components/Icons/IconShoppingCart";
import {toast} from "sonner";
import moment from "moment";
import {compose} from "redux";
import {connect} from "react-redux";
import {CustomerAddCart} from "../../../store/Subdomain/CustomerCart/action";
import {addWishItem} from "../../../store/Subdomain/Wishlist/action";

const Trending = ({block,customStyle,toggleHightLight,t,isEditor,shop_data,CustomerAddCart,addWishItem}) => {

    const PRODUCT_DETAIL = "/product/detail/"
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(null)

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const trendingRef = useRef()

    const getBlockSize = () => {
        setDimension({top :trendingRef?.current?.offsetTop, left : trendingRef?.current?.offsetLeft, width : trendingRef?.current?.offsetWidth, height : trendingRef?.current?.offsetHeight,nodeName : trendingRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }




    useEffect(() => {
        if (isEditor){
            setLoading(false)
            setProducts(block.data)
        }else{
            getProductsData(shop_data?.id)
        }
    }, [isEditor])

    const getProductsData = async (id) => {
        await axios.get(SERVER_URL+"/public/products/"+id).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setProducts(response.data.products.slice(0,8))
            }
        })
    }

    const quickView = (item) => {
        setItem(item)
        setOpen(true)
    }

    const addWishlist = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
            const data ={
                id:item.id,
                category : item.category,
                picture :SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
                name:item.title,
                discount : item.discount,
                price:item.price,
                hash_key:item.hash_key
            }

            addWishItem(data)

            toast("Notification",{
                description: t('AddToWishlist') +"\n"+ moment(Date.now()).fromNow(),
                icon : <i className="ri-check-double-fill"></i>
            });

        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }

    }


    const addToCart = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
            const data = {
                id:item.id,
                qty : 1,
                category : item.category,
                picture : SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
                name:item.title,
                price: CalculateDiscount(item?.price,item?.discount)
            }
            CustomerAddCart(data)

            toast("Notification",{
                description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
                icon : <i className="ri-check-double-fill"></i>
            })
        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }
    }


    return (
        <section className="tp-trending-area pt-140 pb-150"
                 style={customStyle}
                 ref={trendingRef}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-6">
                        <div className="tp-trending-wrapper">
                            <div className="tp-section-title-wrapper-2 mb-50">
                           <span className="tp-section-title-pre-2">
                              {t('MoretoDiscover')}
                              <svg width="82" height="22" viewBox="0 0 82 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M81 14.5798C0.890564 -8.05914 -5.81154 0.0503902 5.00322 21" stroke="currentColor" strokeOpacity="0.3" strokeWidth="2" strokeMiterlimit="3.8637" strokeLinecap="round"/>
                              </svg>
                           </span>
                                <h3 className="tp-section-title-2">{t('TrendingArrivals')}</h3>
                            </div>
                            {
                                loading ? <MiddleLoading />
                                    :
                                    <div className="tp-trending-slider">
                                        <div className="tp-trending-slider-active swiper-container">
                                            <div className="swiper-wrapper">
                                                <Swiper
                                                    effect={"slide"}
                                                    grabCursor={true}

                                                    spaceBetween={20}
                                                    loop={true}
                                                    slidesPerView={products ? products.length > 1 ? 2 : 1 : 1}
                                                    modules={[EffectCards,Pagination,Navigation]}
                                                    pagination={{
                                                        el : '.tp-trending-slider-dot' , clickable : true
                                                    }}
                                                    navigation={{
                                                        nextEl : ".tp-slider-button-next",
                                                        prevEl : ".tp-slider-button-prev",
                                                        clickable : true
                                                    }}
                                                >
                                                    {
                                                        products?.slice(0,4).map((item,key) =>
                                                            <SwiperSlide key={key}>
                                                                <div className="tp-trending-item swiper-slide">
                                                                    <div className="tp-product-item-2">
                                                                        <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                                                                            <Link to={PRODUCT_DETAIL+item.hash_key}>
                                                                                <img src={isEditor ? item.picture : SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0]} alt=""/>
                                                                            </Link>
                                                                            <div className="tp-product-action-2 tp-product-action-blackStyle">
                                                                                <div className="tp-product-action-item-2 d-flex flex-column">
                                                                                    <button type="button" className="tp-product-action-btn-2 tp-product-add-cart-btn"  onClick={event => addToCart(item)}>
                                                                                         <IconShoppingCart />
                                                                                        <span className="tp-product-tooltip tp-product-tooltip-right">{t('BuyNow')}</span>
                                                                                    </button>
                                                                                    <button type="button" className="tp-product-action-btn-2 tp-product-quick-view-btn" onClick={event => quickView(item)}>
                                                                                        <IconEye />
                                                                                        <span className="tp-product-tooltip tp-product-tooltip-right">{t('QuickView')}</span>
                                                                                    </button>
                                                                                    <button type="button" className="tp-product-action-btn-2 tp-product-add-to-wishlist-btn" onClick={event => addWishlist(item)}>
                                                                                        <IconHeart />
                                                                                        <span className="tp-product-tooltip tp-product-tooltip-right">{t('AddToWishlist')}</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tp-product-content-2 pt-15">
                                                                            <div className="tp-product-tag-2">
                                                                                <Link to={PRODUCT_DETAIL+item.hash_key}>{item.category}</Link>
                                                                            </div>
                                                                            <h3 className="tp-product-title-2">
                                                                                <Link to={PRODUCT_DETAIL+item.hash_key}>{item.title}</Link>
                                                                            </h3>
                                                                            {
                                                                                item.rating ?
                                                                                    <div className="tp-product-rating d-flex align-items-center">
                                                                                        <div className="tp-product-rating-icon">
                                                                                            {
                                                                                                [...Array(parseInt(item.rating))].map((item,key) => (
                                                                                                    <span key={key}><i className="fa-solid fa-star"></i></span>
                                                                                                ))
                                                                                            }
                                                                                        </div>

                                                                                    </div>
                                                                                    :

                                                                                    ""
                                                                            }
                                                                            <small className="text-muted">{item.short_description}</small>
                                                                            {
                                                                                parseInt(item.discount) > 0 ?
                                                                                    <div className="tp-product-price-wrapper">
                                                                                        <span className="tp-product-price old-price">{numberWithCommas(item.price)}  Francs CFA</span>
                                                                                        <span className="tp-product-price new-price pl-10">{numberWithCommas(secondCalculDicount(parseInt(item.price),parseInt(item.discount)))} Francs CFA</span>
                                                                                    </div>
                                                                                    :

                                                                                    <div className="tp-product-price-wrapper">
                                                                                        <span className="tp-product-price new-price">{numberWithCommas(item.price)}  Francs CFA</span>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    }
                                                </Swiper>
                                            </div>
                                        </div>
                                        <div className="tp-trending-slider-dot tp-swiper-dot text-center mt-45"></div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-8 col-sm-10">
                        <div className="tp-trending-banner p-relative ml-35">
                            <div className="tp-trending-banner-thumb w-img include-bg"
                                 style={{background : `url("${flatData(block.contentEditable,'banner')}") no-repeat`,backgroundSize :"cover"}}>
                                <div className="tp-trending-banner-content">
                                <h3 className="tp-trending-banner-title">
                                    <Link to={flatData(block.contentEditable,'bannerLink')} className="text-white">{flatData(block.contentEditable,'bannerText')}</Link>
                                </h3>
                                <div className="tp-trending-banner-btn">
                                    <Link to={flatData(block.contentEditable,'bannerLink')} className="tp-btn tp-btn-border tp-btn-border-white tp-btn-border-white-sm">
                                        {flatData(block.contentEditable,'bannerBtnText')}
                                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 7.5L1 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuickView
                open={open}
                setOpen={setOpen}
                data={item}
                shop_data={shop_data}
            />
        </section>
    )
}

const mapStateToProps = (state) => ({
    customCart : state.customCart
});

export default compose(withRouter, connect(mapStateToProps,{CustomerAddCart,addWishItem})) (withTranslation()(Trending))
