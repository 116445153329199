import React, {Fragment, useState} from 'react';
import {flatStyle} from "../../../utils/utils";

const Border = ({data,updateValue,updateUnit}) => {
    const [toggle, setToggle] = useState(false)

    return (
      <Fragment>
          <label className="header" data-header="border_header" onClick={event => setToggle(!toggle)} htmlFor="header_border_header" >
              <span>Border</span>
              <div className="header-arrow"></div>
          </label>
          <input className="header_check" type="checkbox" defaultChecked={toggle} id="header_border_header"/>
          {
              toggle ?
                  <div className="row ml-20 mr-20" data-section="border_header" >
                      <div className="mb-3 col-sm-12" data-key="border-style">
                          <label className="form-label" htmlFor="input-model">Style</label>
                          <div className="input">
                              <div>
                                  <select className="form-select" name="border-style"
                                          value={flatStyle(data,'borderStyle').value}
                                          onChange={event => updateValue("borderStyle",event.target.value)}
                                  >
                                      <option value="">Default</option>
                                      <option value="solid">Solid</option>
                                      <option value="dotted">Dotted</option>
                                      <option value="dashed">Dashed</option>
                                  </select>
                              </div>
                          </div>
                      </div>
                      <div className="mb-3 col-sm-6" data-key="border-width">
                          <label className="form-label" htmlFor="input-model">Width</label>
                          <div className="input">
                              <div
                                  className="input-group css-unit"
                                  id="cssunit-border-width"
                              >
                                  <input
                                      name="number"
                                      type="number"
                                      className="form-control"
                                      value={flatStyle(data,'borderWidth').value}
                                      onChange={event => updateValue("borderWidth",event.target.value)}
                                  />
                                  <div className="input-group-append">
                                      <select
                                          className="form-select small-arrow"
                                          name="unit"
                                          value={flatStyle(data,'borderWidth').unit}
                                          onChange={event => updateUnit("borderWidth",event.target.value)}
                                      >
                                          <option value="em">em</option>
                                          <option value="rem">rem</option>
                                          <option value="px">px</option>
                                          <option value="%">%</option>
                                          <option value="vw">vw</option>
                                          <option value="vh">vh</option>
                                          <option value="ex">ex</option>
                                          <option value="ch">ch</option>
                                          <option value="cm">cm</option>
                                          <option value="mm">mm</option>
                                          <option value="in">in</option>
                                          <option value="pt">pt</option>
                                          <option value="auto">auto</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="mb-3 col-sm-6" data-key="border-color">
                          <label className="form-label" htmlFor="input-model">Color</label>
                          <div className="input">
                              <div>
                                  <input
                                      name="border-color"
                                      type="color"
                                      pattern="#[a-f0-9]{6}"
                                      className="form-control form-control-color"
                                      value={flatStyle(data,'borderColor').value}
                                      onChange={event => updateValue("borderColor",event.target.value)}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  : ""
          }
      </Fragment>
    )
}

export default Border;
