import {
    SUB_DELETE_WISHLIST,
    SUB_CLEAR_WISHLIST,
    SUB_ADD_WISHLIST
} from  './type';




const initialState = {
    wishlist : localStorage.getItem('478965321000') ? JSON.parse(localStorage.getItem('478965321000')).wishlist : [],
}

const CustomWishListReducer =  (state = initialState, { type, payload }) =>{
    switch(type){
        case SUB_ADD_WISHLIST:
            if(!state?.wishlist?.length){
                let wishlist = {
                    id:payload.id,
                    category : payload.category,
                    picture : payload.picture,
                    name:payload.name,
                    discount : payload.discount,
                    price:payload.price,
                    hash_key:payload.hash_key
                }
                state.wishlist.push(wishlist);
                localStorage.setItem('478965321000', JSON.stringify(state));

            }
            else{
                let check = false;
                state.wishlist.map((item)=>{
                    if(item.id==payload.id) check=true;
                });
                if(!check){
                    let _wishlist = {
                        id:payload.id,
                        category : payload.category,
                        picture : payload.picture,
                        name:payload.title,
                        discount : payload.discount,
                        price:payload.price,
                        hash_key:payload.hash_key
                    }
                    state.wishlist.push(_wishlist);
                    localStorage.setItem('478965321000', JSON.stringify(state));
                }
            }
            return{
                ...state,
            }

        case SUB_DELETE_WISHLIST:
            const re = {
                ...state,
                wishlist:state.wishlist.filter(item=>{
                    return item.id!=state.wishlist[payload].id
                })

            }
            return re
        case SUB_CLEAR_WISHLIST:
            localStorage.removeItem('478965321000')
            return {
                wishlist : []
            }
        default:
            return state;
    }
}

export default CustomWishListReducer
