import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../../utils/utils";

const Paragraph = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const paragraph = useRef()

    const getBlockSize = () => {
        setDimension({top :paragraph?.current?.offsetTop, left : paragraph?.current?.offsetLeft, width : paragraph?.current?.offsetWidth, height : paragraph?.current?.offsetHeight,nodeName : paragraph?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);
    
    return(

        <section className="pt-10 pb-10"
                 style={customStyle}
        >
            <div className="container "
                 ref={paragraph}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
            >
                <div className="row">
                    {items?.map((item, key) => <p key={key}>{flatData(item, "text")}</p>)}
                </div>
            </div>
        </section>
)
}

export default Paragraph;