import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import RegisterShop from './Register/reducer'
import PagesList from './Pages/reducer'
import TemplateListReducer from "./Templates/reducer";
import PricingReducer from "./Pricing/reducer";
import LoginReducer from "./Auth/reducer";
import HomeReducer from "./Home/reducer";
import SellingReducer from "./Sellings/reducer";
import CategorieReducer from "./Categories/reducer";
import LoginSubReducer from "./Subdomain/Oauth/reducer";
import CartReducer from "./Subdomain/cart/reducer";
import CustomCartReducer from "./Subdomain/CustomerCart/reducer";
import CustomWishListReducer from "./Subdomain/Wishlist/reducer";

const initialState = {};

const RootReducers = combineReducers({
    home : HomeReducer,
   registershop : RegisterShop,
   listshoppage : PagesList,
   list_templates : TemplateListReducer,
   list_pricing : PricingReducer,
   loginShop : LoginReducer,
   sellings : SellingReducer,
    categorie : CategorieReducer,
    subLogin : LoginSubReducer,
    cart : CartReducer,
    customCart : CustomCartReducer,
    customWishlist : CustomWishListReducer
})

export const store = createStore(RootReducers,
    initialState,
    compose(
    applyMiddleware(thunk),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    compose,
),)
