import React from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../../layouts/front/Layout";
import {
    ADRESS_MAIL,
    APPNAME,
    CapitalSocial,
    LocalSocial,
    NIF,
    OwnerShip,
    PHONE,
    RCCM,
    TECHNCTRL
} from "../../../helpers/utils";

const Legals = ({t}) => {
    document.title = APPNAME+" :: "+t('Legal')
    return (
        <Layout>
            <section className="sign-in-section p-t-150 p-b-2">
                <div className="container-fluid ">
                    <h1 className="text-center" style={{fontSize : "50px"}}>{t('Legal')}</h1>
                    <div className="row justify-content-center" style={{marginTop : "50px"}}>
                        <div className="col-md-6">
                            <div className="" style={{border : "1px solid #C2C2C2", padding : "20px",marginBottom : "80px"}}>
                                {/*<div className="d-flex justify-content-end">*/}
                                {/*    <i className="ri-printer-fill mr-15"></i>*/}
                                {/*    <i className="ri-file-pdf-fill"></i>*/}
                                {/*</div>*/}
                                <br/>
                                <h5 className="text-black font-weight-bold">{t('LegalObjectif')}</h5>

                                <p className="mt-15">
                                    {
                                        localStorage.getItem('I18N_LANGUAGE') == "fr" ?

                                            <p>
                                                Ce site a pour objectif de présenter aux visiteurs le logiciel de
                                                gestion d' Ecommerce et les différents services associés. Ce site permet
                                                aux gérants de boutique ou de supermarché de vendre leurs produits et
                                                d'être payer via les différents moyens de paiement mobile ou PayPal.
                                            </p>
                                            :
                                            <p>
                                                This site aims to present to visitors the software of
                                                Ecommerce management and the various associated services. This site allows
                                                store or supermarket managers to sell their products and
                                                to be paid via different mobile payment methods or PayPal.
                                            </p>
                                    }
                                </p>

                                <p className="mt-35"><b
                                    className="text-black font-weight-bold">{t('CompanyName')}</b> : {TECHNCTRL}</p>
                                <br/>
                                <p><b className="text-black font-weight-bold">{t('JuridicForm')}</b> : SARL U, Société à
                                    Responsabilité Limité Unipersonnel</p>
                                <br/>
                                <p><b className="text-black font-weight-bold">{t('LocalSocial')}</b> : {LocalSocial}</p>
                                <br/>
                                <p><b className="text-black font-weight-bold">{t('CapitalSocial')}</b> : {CapitalSocial}
                                </p>
                                <br/>
                                <p><b className="text-black font-weight-bold">Numéro RCCM</b> : {RCCM}</p>
                                <br/>
                                <p><b className="text-black font-weight-bold">{t('NIF')}</b> : {NIF}</p>
                                <br/>
                                <p><b className="text-black font-weight-bold">Email</b> : {ADRESS_MAIL}</p>
                                <br/>
                                <p><b className="text-black font-weight-bold">{t("Phone")}</b> : {PHONE}</p>
                                <br/>
                                <p><b className="text-black font-weight-bold">{t("OwnerShip")}</b> : {OwnerShip} <a href="https://hostinger.com/" target='_blank' className="text-primary">Hostinger</a></p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default withTranslation()(Legals);
