import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import logoImg from '../../assets/market.png'

const PictureInput = ({t,logo = logoImg,  updateLogo}) => {

    const [Picture, setPicture] = useState(logo);
    const [Preview, setPreview] = useState(logo);
    const [loading, setLoading] = useState(false);

    const handlepicture = e =>{
        setPicture(e.target.files[0])
        setPreview(URL.createObjectURL(e.target.files[0]))
    }

    const uploadLogo = () => {
        setLoading(true)
     updateLogo(Picture)
      setTimeout(() => {
          setLoading(false)
          setPicture("")
      },2000)
    }


    return (
        <div className="custom-img-container"  style={{background : `url(${Preview}) no-repeat center`,backgroundSize : "cover"}} >
            <input type="file" id="picturecustom" onChange={e=> handlepicture(e)} />
            <div className="icon-zone">
                {!loading && <label htmlFor="picturecustom"> <i className="mdi mdi-pencil"></i></label>}
                {Picture !== logo ? <>
                    {loading ?
                        <label htmlFor=""><span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"/></label>
                        :  <label  className="" onClick={event => uploadLogo()}><i className="mdi mdi-send bg-success"></i></label>
                    }
                 </> :
                    ""}
            </div>
        </div>
    );
}

export default withTranslation()(PictureInput);
