import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import plugin from "../../../../assets/plugin.jpg";
import axios from "axios";
import { APPNAME, attachTokenToHeaders, Capitalize, numberWithCommas, SERVER_URL } from '../../../../helpers/utils'
import {toast} from "react-toastify";
import {MiddleLoading} from "../../../../components";
import Authorization from "../../../../components/Authorization";
import AppDetail from "./AppDetail";

const Package = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('MarketPlace')

    const [loading, setLoading] = useState(true);
    const [packages, setPackages] = useState([]);

    const [open, setOpen] = useState(false);
    const [application, setApplication] = useState(null);
    const [pLoading, setPLoading] = useState(false)


    useEffect(() => {
        getAllPackage()
    }, []);


    const getAllPackage = async () => {
        await axios.get(SERVER_URL+"packages",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPackages(response.data.plugins)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    const checkIfInstalled = (pack) =>{
          const installed  = auth?.plugins?.filter(plugin => plugin.plugins == pack.id)
          if (installed.length > 0){
              return  <button  className="btn btn-success disabled btn-block">{t('Installed')}</button>
          }else{

              return  <button  className="btn btn-primary btn-block" onClick={event => launch(pack)}>{t('Install')}</button>
          }

    }

    const launch = (pack) => {
        setOpen(true)
        setApplication(pack)
        setPLoading(true)

        setTimeout(() => {
            setPLoading(false)
        }, 1000)
    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Marketplace')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{auth?.shop?.shopName}</li>
                                <li className="breadcrumb-item active">{t('Marketplace')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            {
                !open ?
                    <Authorization single={true}  module="plugins"  component={true} >
                        <div className="container">
                            <div className="row"style={{marginBottom : "50px"}} >
                                <div className="col-lg-6">
                                    <h1>{t('InstallAppToUpdateYourSales')}</h1>
                                    <p>{t('InstallAppToUpdateYourSalesText')}</p>
                                </div>
                                <div className="offset-lg-3 col-lg-3">
                                    <div className="plugin__top__card">
                                        <div className="plugin__top__title">

                                            <p className="plugin__top__title-text">
                                                {t('Applications')}
                                            </p>
                                            <p className="plugin__top__percent">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"
                                                     fill="currentColor" height="20" width="20">
                                                    <path
                                                        d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z">
                                                    </path>
                                                </svg>
                                            </p>
                                        </div>
                                        <div className="plugin__top__data">
                                            <p>
                                                {numberWithCommas(packages?.length)}
                                            </p>

                                            <div className="plugin__top__range">
                                                <div className="plugin__top__fill"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <br/>

                            <div className="col-xxl-12">

                                <>
                                    {
                                        loading ?
                                            <MiddleLoading />
                                            :
                                            <div className="tab-content text-muted" style={{overflow : "hidden"}}>
                                                <div className="row">
                                                    {
                                                        packages?.length > 0
                                                            ?
                                                            <>
                                                                {
                                                                    packages.map((pack, i) =>

                                                                        <div className="col-lg-3 mt-20" key={i}>
                                                                            <div className="plugin-card plugin-row">
                                                                                <p className="text-right">{pack?.type == 1 ? <b className="badge badge-danger">Extension</b> : <b className="badge badge-success">Module</b>}</p>
                                                                                <img src={plugin} alt="extension"/>

                                                                                <h1>
                                                                                    {pack?.["name"+localStorage.getItem('I18N_LANGUAGE').toUpperCase()]}
                                                                                </h1>
                                                                                <p>
                                                                                    {pack?.[localStorage.getItem('I18N_LANGUAGE')].substring(0,65)}...
                                                                                </p>

                                                                                <div className="cta-section">
                                                                                    <br/>
                                                                                    <div className="text-center">
                                                                                        <b>
                                                                                            {
                                                                                                pack?.price > 0 ? <b>{numberWithCommas(pack.price)} Frcs / {Capitalize(t(pack.period))}</b> : <b>{t('Free')}</b>
                                                                                            }
                                                                                        </b>
                                                                                    </div>
                                                                                    <br/>
                                                                                </div>
                                                                                {checkIfInstalled(pack)}
                                                                                <br/>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                    }


                                                </div>
                                            </div>
                                    }
                                </>

                            </div>
                        </div>
                    </Authorization>
                    :
                   <div>
                       {
                           pLoading ?
                               <MiddleLoading />
                               :
                               <AppDetail
                                   open={open}
                                   setOpen={setOpen}
                                   plugin={application}
                                   auth={auth}
                                   t={t}
                               />
                       }
                   </div>

            }


        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Package));
