import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, MoneyFormat, numberWithCommas, SERVER_URL} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import {Chart} from "react-google-charts";
import axios from "axios";
import {toast} from "react-toastify";
import {isNaN} from "formik";

const Dashboard = ({t}) => {
    document.title = APPNAME+" :: "+t('Dashboard')

    const [trending,setTrending] = useState([])
    const [adminAmountAvailable,setAdminAmountAvailable] = useState(0)
    const [adminAmountNotAvailable,setAdminAmountNotAvailable] = useState(0)
    const [adminAmountTotal,setAdminAmountTotal] = useState(0)
    const [pluginCount,setPluginCount] = useState(0)


    const [amountPaymentWaitForUsers,setAmountPaymentWaitForUsers] = useState(0)
    const [amountPaymentAvailableForUsers,setAmountPaymentAvailableForUsers] = useState(0)

    useEffect(() => {
        getDashboard()
    }, []);



    const getDashboard = async () => {
        await axios.get(`${SERVER_URL}admin/dashboard`,attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                setTrending(response.data.trending)
                setAdminAmountAvailable(response.data.adminAmountAvailable)
                setAdminAmountNotAvailable(response.data.adminAmountNotAvailable)

                setAmountPaymentWaitForUsers(response.data.amountPaymentWaitForUsers)
                setAmountPaymentAvailableForUsers(response.data.amountPaymentAvailableForUsers)

                setAdminAmountTotal(parseInt(response.data.adminAmountNotAvailable) + parseInt(response.data.adminAmountAvailable))
                setPluginCount(response.data.pluginCount)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    const calculPercentGrow = (data,tranche) => {
        const last = data?.filter(t => {
            if (tranche == "year"){
                const total =   new Date(t.created_at).getFullYear() == new Date().getFullYear() - 1
                return !isNaN(total) ? total : 0
            }else{
                const total =  new Date(t.created_at).getMonth() == new Date().getMonth() - 1
                return !isNaN(total) ? total : 0
            }
        })

       const percent  =  last?.length > 0 ? ((data?.length - last?.length) / data?.length) * 100 : 0

        if (percent > 0){
            return <span className="badge badge-soft-success fs-12">
                        <i className="ri-arrow-up-s-line fs-13 align-middle me-1"></i>
                       {percent.toFixed(1)}%
                  </span>
        } else{
            return <span className="badge badge-soft-danger fs-12">
                        <i className="ri-arrow-down-s-line fs-13 align-middle me-1"></i>
                     {percent.toFixed(1)}%
                  </span>
        }
    }

    const lastYearCount = (shop) => {
      const last = shop?.filter(t => new Date(t.created_at).getFullYear() == new Date().getFullYear() - 1)
        return last?.length
    }

    const fiterByCountry = (data,country) => {
      const count = data?.filter(t => t.country == country)
        return count?.length
    }



     const data = [
        [t("Country"),t('ShopCount')],
        ["Togo", fiterByCountry(trending?.shopCount,'togo')],
        ["Benin", fiterByCountry(trending?.shopCount,'benin')],
        ["Mali", fiterByCountry(trending?.shopCount,'mali')],
        ["Niger", fiterByCountry(trending?.shopCount,'niger')],
        ["Cote d'ivoire", fiterByCountry(trending?.shopCount,"cote d'ivoire")],
        ["Sénégal", fiterByCountry(trending?.shopCount,'senegal')],
    ];

     const options = {
        title: t("TotalShopByCountry"),
        chartArea: { width: "60%" },
        hAxis: {
            title: t("TotalShop"),
            minValue: 0,
        },
        vAxis: {
            title: t('Country'),
        },
    };


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Dashboard')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Dashboard')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-xxl-7">
                    <div className="row" style={{ height: "80%" }}>

                        <div className="col-xl-6 col-md-6">
                            <div className="card card-height-100">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-soft-info text-info rounded-2 fs-2 mt-2">
                                        <i className="bx bx-store-alt"></i>
                                    </span>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <p className="text-uppercase fw-medium text-muted mb-3">{t('Shops')} {t('Actived')}</p>
                                            <h1 className="fs-48 mb-3">
                                        <span
                                            className="counter-value">{numberWithCommas(trending?.shopCount?.length)}</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Not Ok*/}
                        <div className="col-xl-6 col-md-6">
                            <div className="card card-height-100">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-soft-info text-info rounded-2 fs-2 mt-2">
                                        <i className="bx bx-store-alt"></i>
                                    </span>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <p className="text-uppercase fw-medium text-muted mb-3">{t('Shops')} {t('Expired')}s</p>
                                            <h1 className="fs-48 mb-3"><span
                                                className="counter-value">{numberWithCommas(trending?.shopExpireCount)}</span>
                                            </h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12">
                            <div className="card card-height-100">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{t('ShopPerCountry')}</h4>
                                </div>
                                <div className="card-body p-0">
                                    <div>
                                        <Chart
                                            chartType="BarChart"
                                            data={data}
                                            options={options}
                                            width={"100%"}
                                            height={"330px"}

                                        />
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>

                </div>
                <div className="col-xxl-5">
                    <div className="d-flex flex-column">

                        <div className="row">

                            <div className="col-md-4">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">Demo {t('Shops')}</p>
                                                <h2 className="mt-4 ff-secondary fw-semibold">
                                                    {numberWithCommas(trending?.trialShop)}
                                                </h2>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-detail text-info"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">{t('PluginInstalled')}</p>
                                                <h2 className="mt-4 ff-secondary fw-semibold">
                                                    {numberWithCommas(pluginCount)}
                                                </h2>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-package text-success"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            {/*Not OK*/}
                            <div className="col-md-4">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">{t('Customers')} {t('Shop')}</p>
                                                <h2 className="mt-4 ff-secondary fw-semibold">
                                                    {numberWithCommas(trending?.customers)}
                                                </h2>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-user text-success"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        {/*Not OK*/}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">{t('AmountPaymentAvailableForUsers')}</p>
                                                <h1 className="mt-4 ff-secondary fw-semibold">
                                                    {MoneyFormat(!amountPaymentAvailableForUsers ? amountPaymentAvailableForUsers : amountPaymentAvailableForUsers[0]?.total_amount)} <small
                                                    className="fs-13">Frcs XOF</small>
                                                </h1>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-money text-info"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">{t('AmountPaymentWaitForUsers')}</p>
                                                <h1 className="mt-4 ff-secondary fw-semibold">
                                                    {MoneyFormat(!amountPaymentWaitForUsers ? amountPaymentWaitForUsers :amountPaymentWaitForUsers[0]?.total_amount)} <small
                                                    className="fs-13">Frcs XOF</small>
                                                </h1>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-money text-info"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        {/*Not OK*/}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">{t('AvailableBalance')}</p>
                                                <h1 className="mt-4 ff-secondary fw-semibold">
                                                    {MoneyFormat(!adminAmountAvailable ? adminAmountAvailable : adminAmountAvailable[0]?.total_amount)} <small
                                                    className="fs-13">Frcs XOF</small>
                                                </h1>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-money text-info"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">{t('MoneyPending')}</p>
                                                <h1 className="mt-4 ff-secondary fw-semibold">
                                                    {MoneyFormat(!adminAmountNotAvailable ? adminAmountNotAvailable : adminAmountNotAvailable[0]?.total_amount)} <small
                                                    className="fs-13">Frcs XOF</small>
                                                </h1>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-money text-info"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="fw-medium text-muted mb-0">{t('TotalAmountReceived')}</p>
                                                <h1 className="mt-4 ff-secondary fw-semibold">
                                                    {MoneyFormat(!adminAmountTotal ? adminAmountTotal : adminAmountTotal[0]?.total_amount)} <small className="fs-13">Frcs XOF</small>
                                                </h1>
                                            </div>
                                            <div>
                                                <div className="avatar-sm flex-shrink-0">
                                                            <span
                                                                className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                <i className="bx bx-money text-info"></i>
                                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </AdminLayout>
    );
}

export default withTranslation()(Dashboard)
