import React, {useState} from 'react';
import {SUB_CART, SUB_INDEX, SUB_PRODUCTS} from "../routes/type";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {CalculateDiscount, Capitalize, numberWithCommas} from "../../../../helpers/utils";
import SellerLayout from "../components/SellerLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {deleteWishList} from "../../../../store/Subdomain/Wishlist/action";
import {toast} from "sonner";
import moment from "moment/moment";
import {CustomerAddCart} from "../../../../store/Subdomain/CustomerCart/action";
import { Fade } from 'react-awesome-reveal'

const Wishlist = ({t,customWishlist,CustomerAddCart,deleteWishList}) => {
    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+t('WishList')

    const [qty, setQty] = useState(1);

    const addToCart = (item,key) => {

        CustomerAddCart({
            id:item.id,
            qty : qty,
            category : item.category,
            picture : item.picture,
            name:item.name,
            price:CalculateDiscount(item?.price,item?.discount)
        })

        deleteWishList(key)

        toast("Notification",{
            description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
            icon : <i className="ri-check-double-fill"></i>
        });
    }

    return (
      <Fade>
          <SellerLayout>
              <main>
                  <section className="breadcrumb__area include-bg pt-95 pb-50">
                      <div className="container">
                          <div className="row">
                              <div className="col-xxl-12">
                                  <div className="breadcrumb__content p-relative z-index-1">
                                      <h3 className="breadcrumb__title">{t('WishList')}</h3>
                                      <div className="breadcrumb__list">
                                          <span><Link to={SUB_INDEX}>{t('Home')}</Link></span>
                                          <span><Link to={SUB_PRODUCTS}>{t('Shop')}</Link></span>
                                          <span>{t('WishList')}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>


                  <section className="tp-cart-area pb-120">
                      <div className="container">
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="tp-cart-list mb-45 mr-30">
                                      <table className="table">
                                          <thead>
                                          <tr>
                                              <th  colSpan={2}  className="tp-cart-header-product">{t('Product')}</th>
                                              <th className="tp-cart-header-price">{t('Price')}</th>
                                              <th>{t('Cart')}</th>
                                              <th>Action</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {
                                              customWishlist?.wishlist?.length ?
                                                  <>
                                                      {
                                                          customWishlist?.wishlist?.map((item,idx) =>

                                                              <tr key={idx}>

                                                                  <td className="tp-cart-img">
                                                                      <Link to={"product/detail/"+item.hash_key}>
                                                                          <img src={item.picture} alt=""/></Link>
                                                                  </td>

                                                                  <td className="tp-cart-title">
                                                                      <Link to={"product/detail/"+item.hash_key}>{item.name}</Link> <br/>
                                                                      <small style={{marginLeft : "20px"}}>{item.category}</small>
                                                                  </td>

                                                                  <td className="tp-cart-price"><span>{numberWithCommas(item.price)} Francs</span></td>

                                                                  <td className="tp-cart-quantity">
                                                                      <div className="tp-product-quantity mt-10 mb-10">
                                                                           <span className="tp-cart-minus" onClick={event => setQty(qty + 1)}>
                                                                              <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                 <path d="M1 1H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                              </svg>
                                                                           </span>
                                                                          <input className="tp-cart-input" onChange={event => setQty(event.target.value)}  type="text" value={qty}/>
                                                                          <span className="tp-cart-plus" onClick={event => setQty(qty + 1)}>
                                                                              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                 <path d="M5 1V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                 <path d="M1 5H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                              </svg>
                                                                           </span>
                                                                      </div>
                                                                  </td>
                                                                  <td className="tp-cart-add-to-cart">
                                                                      <button onClick={event => addToCart(item,idx)} className="tp-btn tp-btn-2 tp-btn-blue"><i className="ri-shopping-bag-2-fill"></i></button>
                                                                  </td>


                                                                  <td className="tp-cart-action">
                                                                      <button className="tp-cart-action-btn" onClick={event => deleteWishList(idx)}>
                                                                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path fillRule="evenodd" clipRule="evenodd" d="M9.53033 1.53033C9.82322 1.23744 9.82322 0.762563 9.53033 0.46967C9.23744 0.176777 8.76256 0.176777 8.46967 0.46967L5 3.93934L1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L3.93934 5L0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L5 6.06066L8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033C9.82322 9.23744 9.82322 8.76256 9.53033 8.46967L6.06066 5L9.53033 1.53033Z" fill="currentColor"/>
                                                                          </svg>
                                                                          <span>{t('Remove')}</span>
                                                                      </button>
                                                                  </td>
                                                              </tr>

                                                          )
                                                      }
                                                  </>

                                                  :
                                                  <tr>
                                                      <td colSpan="5">
                                                          <p className="text-center">{t('EmptyList')}</p>
                                                      </td>
                                                  </tr>
                                          }


                                          </tbody>
                                      </table>
                                  </div>
                                  <div className="tp-cart-bottom">
                                      <div className="row align-items-end">
                                          <div className="col-xl-6 col-md-4">
                                              <div className="tp-cart-update">
                                                  <Link to={SUB_CART} className="tp-cart-update-btn">{t('ShoppingCart')}</Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>


              </main>
          </SellerLayout>
      </Fade>
    )
}

const mapStateToProps = (state) => ({
    customWishlist : state.customWishlist
});
export default   compose(connect(mapStateToProps,{CustomerAddCart})) (withTranslation()(Wishlist));
