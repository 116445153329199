import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";

const TrialContact = ({t}) => {
    const [shop,setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));




    return (
        <div className=""  style={{background : "#f4f4f4"}}>
            <div className="u-s-p-y-90">

                <div className="container u-s-p-b-80">
                    <div className="row">
                        <h1 className="text-center">{t('Contact')}</h1>
                    </div>
                </div>

                <div className="section__content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 u-s-m-b-30">
                                <div className="contact-o u-h-100">
                                    <div className="contact-o__wrap">
                                        <div className="contact-o__icon"><i className="fas fa-phone-volume"></i></div>

                                        <span className="contact-o__info-text-1">{t('CallUs')}</span>

                                        <span className="contact-o__info-text-2">{shop?.phone}</span>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 u-s-m-b-30">
                                <div className="contact-o u-h-100">
                                    <div className="contact-o__wrap">
                                        <div className="contact-o__icon"><i className="fas fa-envelope"></i></div>

                                        <span className="contact-o__info-text-1">EMAIL</span>

                                        <span className="contact-o__info-text-2">{shop?.email}</span>

                                    </div>
                                </div>
                            </div>
                            {
                                shop?.open_days ?
                                    <div className="col-lg-4 col-md-6 u-s-m-b-30">
                                        <div className="contact-o u-h-100">
                                            <div className="contact-o__wrap">
                                                <div className="contact-o__icon"><i className="far fa-clock"></i></div>

                                                <span className="contact-o__info-text-1">{t('OpenDays')}</span>

                                                <span className="contact-o__info-text-2">{JSON.parse(shop?.open_days).map((day,k)=>
                                                    <span key={k}>{t(day.label)} &nbsp;&nbsp;</span>
                                                )}</span>

                                                <span className="contact-o__info-text-2">{t('From')} {shop?.open_hours} {t('To')} {shop?.close_hours}</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </div>
                </div>
                
            </div>

        
        </div>
    );
}

export default withTranslation()(TrialContact);