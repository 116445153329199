import React, {useState} from 'react';
import Register from "./Register";
import Options from "./Options";
import Template from "./Template";
import Pricing from "./Pricing";
import Recap from "./Recap";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";
import {LINK_LANDING} from "../../../../routes/type";
import {APPNAME, CustomChangeLanguage} from "../../../../helpers/utils";
import bg from "../register.png";
import {withTranslation} from "react-i18next";


const Index = ({t}) => {
    const [step,setStep] = useState( Cookies.get('step') ? parseInt(Cookies.get('step')) : 1)

    return (
        <section className="sign-in-section p-b-2">
            <div className="container-fluid reg_panel_all">
                <div className="row">
                    <div className="col-lg-4 left__panel">
                        <div className="">
                            <div className="header__left__panel">
                                <Link to={LINK_LANDING}><h3>{APPNAME}</h3></Link>
                                <div className="d-none d-lg-block">
                                    <button
                                        className={`text-white  ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('fr')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>FR
                                    </button>
                                    <button
                                        className={`text-white ${localStorage.getItem('I18N_LANGUAGE') === "en" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('en')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>EN
                                    </button>
                                </div>
                            </div>
                            <div className="left__panel__content">
                                <h1 className="text-white" style={{fontSize: "40px"}}>{t('CustomerEspace')}</h1>
                                <p>{t('CustomerEspaceText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 bg-white">
                            {step === 1 && <Register setStep={setStep}/>}
                            {step === 2 && <Options setStep={setStep} />}
                            { step === 3 && <Template setStep={setStep} />}
                            { step === 4 && <Pricing setStep={setStep} />}
                            { step === 5 && <Recap setStep={setStep} />}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default  withTranslation() (Index)