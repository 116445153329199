import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal, FedaPay, MiddleLoading, PayPal} from "../../../../components";
import {APPNAME, attachTokenToHeaders, changeCurrency, numberWithCommas, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";
import {loadMe} from "../../../../store/Auth/action";

const InstalledPlugin = ({t,open,setOpen,plugin,auth,loadMe,resub = false,closed}) => {

    document.title = APPNAME+" :: Plugins"


    const [toPay, setToPay] = useState(false);
    const [loading, setLoading] = useState(false);


    const succeedPayment = async (detail,method) => {
        setLoading(true)
        const data = {
            plugin_id : plugin?.id,
            payment_id : detail.id,
            payment_method : method,
            shop_id : auth?.shop.id,
            shop_key : auth?.shop.shop_key,
            amount : plugin?.price,
            name : plugin?.expression,
            resub : resub,
            free : 0
        }
        await axios.post(SERVER_URL+"packages/install",data,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                loadMe()
                setOpen(false)
                closed(false)
                /*setTimeout(() =>{
                    window.location.reload()
                },2000)*/
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const freeInstall = async () => {
        setLoading(true)
        const data = {
            plugin_id : plugin?.id,
            payment_id : "1001001",
            payment_method : "FREE",
            shop_id : auth?.shop.id,
            shop_key : auth?.shop.shop_key,
            amount : plugin?.price,
            name : plugin?.expression,
            free : 1
        }
        await axios.post(SERVER_URL+"packages/install",data,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                loadMe()
                setOpen(false)
                closed(false)
               /* setTimeout(() =>{
                    window.location.reload()
                },2000)*/
            }else{
                toast.error(t(response.data.message))
            }
        })
    }



    return (
        <CustomModal open={open} setOpen={setOpen}  size="md" >
            {
                loading ?
                    <MiddleLoading />
                    :
                    <div className="row">

                        <p>
                            <small>Version : {plugin?.version}</small>
                        </p>


                        {
                            plugin?.price > 0 ?
                                <h1 style={{
                                    fontSize: "50px",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    textAlign : "center"
                                }}>{numberWithCommas(plugin.price)} <small><small>Frcs</small></small></h1>
                                :
                                ""
                        }
                        <br/>
                        <br/>


                        <>
                        {
                                plugin?.price > 0 ?

                                    <>
                                        {
                                            toPay ?

                                                <>
                                                    <FedaPay amount={plugin?.price} text="Payer avec Mobile Money"
                                                             succeedPayment={succeedPayment} user={auth?.shop}/>
                                                    <PayPal succeedPayment={succeedPayment}
                                                            total={changeCurrency(plugin?.price, 'USD')}/>
                                                </>
                                                :

                                                <button className="btn btn-success btn-lg"
                                                        onClick={event => setToPay(true)}>{t('Paid')}</button>

                                        }
                                    </>
                                    :

                                    <>
                                        <button className="btn btn-success btn-lg"
                                                onClick={event => freeInstall()}>{t('Install')}</button>
                                    </>
                            }
                        </>


                    </div>
            }
        </CustomModal>
    );
}


const mapStateToProps = (state) => ({
    auth: state.loginShop
});


export default compose(connect(mapStateToProps, {loadMe}))(withTranslation()(InstalledPlugin));


