import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import '../../landing.css'
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";
import {CLIENT, isDateBetweenDecemberAndJanuary, loadScript} from "../../helpers/utils";

const Layout = ({children,register}) => {

    useEffect(()=>{
        loadScript(CLIENT+'/assets/js/jquery-3.6.0.min.js')
        loadScript(CLIENT+'/assets/js/bootstrap.min.js')
        loadScript(CLIENT+'/assets/js/slick.min.js')
        loadScript(CLIENT+'/assets/js/jquery.magnific-popup.min.js')
        loadScript(CLIENT+'/assets/js/isotope.pkgd.min.js')
        loadScript(CLIENT+'/assets/js/jquery-ui.min.js')
        loadScript(CLIENT+'/assets/js/jquery.inview.min.js')
        loadScript(CLIENT+'/assets/js/jquery.nice-select.min.js')
        loadScript(CLIENT+'/assets/js/wow.min.js')
        loadScript(CLIENT+'/assets/js/main.js')
    },[])

    return (
        <div className="site-wrapper overflow-hidden">
            {!register ? <HomeHeader/> : null}

            {children}


            {
                isDateBetweenDecemberAndJanuary() &&
                <div>
                    <div className="guirlande guirlande1"></div>
                    <div className="guirlande guirlande2"></div>
                    <div className="guirlande guirlande3"></div>
                </div>
            }


            {!register ? <HomeFooter/> : null}
        </div>
    );
}


Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
