import React, {useEffect, useState} from 'react';
import AddTemplate from "./AddTemplate";
import axios from "axios";
import {attachTokenToHeaders, Capitalize, format_date, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Paginate from "../../../../../components/Paginate/Paginate";
import swal from "@sweetalert/with-react";

const _Template = ({auth,t}) => {

    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const [currentTemplates,setCurrentTemplates] = useState([]);
    const [templates,setTemplates] = useState([]);
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])


    useEffect(() => {
        getTemplates(auth.shop?.id)
    },[auth])

    useEffect(() => {
        setCurrentTemplates(templates?.slice(indexOfFirstPost, indexOfLastPost))
    },[templates])


    const getTemplates = async (shop_id) => {
        await axios.get(SERVER_URL+"label/template/"+shop_id,attachTokenToHeaders())
            .then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setTemplates(response.data.template)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(templates?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const addTemplate = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editTemplate = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }

    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteTicket'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}label/template/`+id,attachTokenToHeaders())
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        getTemplates(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }



    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-sm btn-primary" onClick={event => addTemplate()}>
                            <i className="ri-add-line align-bottom me-1"></i> {t("Add")}
                        </button>
                    </div>
                </div>
            </div>
            <br/>

            <div className="row">
                <table className="table align-middle table-nowrap mb-20" id="ticketTable">
                    <thead>
                    <tr>
                        <th className="sort" data-sort="tasks_name">Template</th>
                        <th className="sort" data-sort="client_name">{t('Type')}</th>
                        <th className="sort" data-sort="status">Configuration</th>
                        <th className="sort" data-sort="action">Action</th>
                        </tr>
                        </thead>
                        <tbody className="list form-check-all" id="ticket-list-data">
                        {
                            loading ?
                                <tr>
                                    <td colSpan="4">
                                        <p className="text-center">
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"/> {t('Loading')}...
                                        </p>
                                    </td>
                                </tr>

                                :

                                <>
                                    {
                                        currentTemplates?.length > 0
                                            ?
                                            <>
                                                {
                                                    currentTemplates?.map((temp, key) =>
                                                        <tr key={key}>
                                                            <td className="id"><span
                                                                className="fw-medium link-primary">{temp?.name}</span>
                                                            </td>
                                                            <td className="tasks_name">{Capitalize(temp?.type)}</td>
                                                            <td className="status">
                                                                <b className="badge badge-gradient-dark ml-20">{t('Height')} : {JSON.parse(temp.config).height} mm </b>
                                                                <b className="badge badge-gradient-dark ml-20">{t('Width')} : {JSON.parse(temp.config).width} mm</b>
                                                                <b className="badge badge-gradient-dark ml-20">{t('Orientation')} : {JSON.parse(temp.config).orientation}</b>
                                                                <b className="badge badge-gradient-dark ml-20">{t('PaperFormat')} : {JSON.parse(temp.config).format}</b>
                                                            </td>

                                                            <td>
                                                                <ul className="list-inline  mb-0">

                                                                    <li className="list-inline-item">
                                                                                     <span
                                                                                         className="edit-item-btn cursor-pointer"
                                                                                         onClick={event => editTemplate(temp)}>
                                                                                         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                     </span>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                                     <span
                                                                                         className="remove-item-btn cursor-pointer"
                                                                                         onClick={event => deleteModal(temp.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                            :
                                            <tr>
                                                <td colSpan="4">
                                                    <p className="text-center">{t('EmptyList')}</p>
                                                </td>
                                            </tr>
                                    }
                                </>
                        }

                        </tbody>
                    </table>
                <Paginate
                    postsPerPage={postsPerPage}
                    totalPosts={templates?.length}
                    paginate={paginate}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    current={currentPage}
                />

            </div>


            <AddTemplate open={open} setOpen={setOpen} auth={auth} created={getTemplates} isEdit={isEdit} updateData={updateData}/>
        </>
    )
}

export default _Template;
