import React, {Fragment, useEffect, useState} from 'react';
import {sectionsConfig} from "../../config/config.section";
import Section from "./section";
import {MiddleLoading} from "../../../components";
import {withTranslation} from "react-i18next";

const SectionList = ({t}) => {
    const [preview, setPreview] = useState("")
    const [sections, setSections] = useState([])
    const [allSections, setAllSections] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [onSearch, setOnSearch] = useState(false)

    useEffect(() => {
        setSections(sectionsConfig)
        setAllSections(sectionsConfig)
        setTimeout(() => {
            setLoading(false)
        },1000)
    }, []);



    return (
        <>
            <div className="drag-elements-sidepane sidepane">
                {preview != "" ?
                    <div className="block-preview" style={{width : "500px"}}><img src={preview}  alt="preview" /></div>
                    : ""
                }
                <div style={{height: "calc(100vh - 200px)"}}>

                    {
                        loading ?

                            <MiddleLoading/>
                            :

                            <ul className="sections-list clearfix" data-type="leftpanel">
                                {
                                    sections.map((section, key) =>
                                        <Fragment key={key}>
                                            <Section section={section} setPreview={setPreview}/>
                                        </Fragment>
                                    )
                                }
                            </ul>
                    }

                </div>
            </div>
        </>
    );
}

export default withTranslation()(SectionList)
