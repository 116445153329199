import React, {useEffect, useState} from 'react';
import { attachTokenToHeaders, CLIENT, DOMAINE, SERVER_IMG_URL, SERVER_URL } from '../../../../../helpers/utils'
import { Bounce, toast, Zoom } from 'react-toastify'
import axios from "axios";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import PictureInput from "../../../../../components/CustomInputs/PictureInput";
import { Alert } from 'reactstrap'
import { CnameRequired, IpRequired } from '../../../../../helpers/keys'
import { CopyToClipboard } from 'react-copy-to-clipboard/src'
import { loadMe } from '../../../../../store/Auth/action'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

const SettingShop = ({t,auth,loadMe}) => {


    const [shopName,setShopName] = useState(auth?.shop.shopName)

    const [domaine,setDomaine] = useState(auth?.shop.domaine)

    const [editShopName, setEditShopName] = useState(false);

    const [editDomaine, setEditDomaine] = useState(false);

    const [editNewDomaine, setEditNewDomaine] = useState(false);

    const [nameLoading, setNameLoading] = useState(false);

    const [domainLoading, setDomainLoading] = useState(false);

    const [ipCheckLoading, setIpCheckLoading] = useState(false);

    const [ipCheckvalue, setIpCheckValue] = useState(null);

    const [cnameCheckLoading, setCnameCheckLoading] = useState(false);

    const [cnameCheckvalue, setCnameCheckValue] = useState(null);



    const [customDomain, setCustomDomain] = useState(auth?.shop?.customdomaine);


    const animatedComponents = makeAnimated();

    const options = [
        { value: 'fr', label: 'Français' },
        { value: 'en', label: 'English' },
    ]

    useEffect(()=>{
        setShopName(auth.shop?.shopName)
    },[auth])



    const updateShopName = async () => {
        if (parseInt(auth?.plan?.pricing_id) === 5) {
            if (shopName !== auth.shop?.shopName) {
                const data = {
                    shop_id: auth?.shop.id,
                    shopName: shopName
                }

                setNameLoading(false)
                await axios.post(SERVER_URL + "setting/update/shopname", data, attachTokenToHeaders())
                    .then((response) => {
                    setNameLoading(false)
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        setEditShopName(false)
                        loadMe()
                    } else {
                        toast.error(t(response.data.message))
                    }
                }).catch(e => {
                        console.log(e)
                    })
            }
        }
    }

    const updateDomain = async () => {
         if (parseInt(auth?.plan?.pricing_id) === 5){
             if (domaine !== auth.shop?.domaine){
                 const data = {
                     shop_id: auth?.shop.id,
                     domaine : domaine
                 }
                 setDomainLoading(true)
                 await axios.post(SERVER_URL+"setting/update/domaine",data,attachTokenToHeaders()).then((response)=>{
                     setDomainLoading(false)
                     if (response.data.succeed){
                         toast.success(t(response.data.message))
                         setEditDomaine(false)
                     }else{
                         toast.error(t(response.data.message))
                     }
                 })
             }
         }
    }

    const updateLogo = async (file) =>{

        const data = new FormData()

        data.append('shop_id',auth?.shop.id)
        data.append('type',"logo")
        data.append('image',file)

        await axios.post(SERVER_URL+"/setting/update/logo",data,attachTokenToHeaders(true)).then((response)=>{
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setTimeout(() => {
                   window.location.reload()
                },3000)
            }else{
                toast.error(t(response.data.message))
            }

            return true
        })
    }

    const addNewCustomDomain = () => {

          if (customDomain === ""){
              toast.error(t("Inputrequired"))
          }else {
               const data = {
                   shop_id: auth?.shop.id,
                   custom_domain : customDomain
               }

               axios.post(SERVER_URL+"setting/add/customdomaine",data,attachTokenToHeaders()).then((response)=>{
                   if (response.data.succeed){
                       toast.success(t(response.data.message))
                       setEditNewDomaine(false)
                       setCustomDomain("")
                   }else{
                       toast.error(t(response.data.message))
                   }
               })
          }

    }

    const verifyIpOrCname = (type) => {
        const data =  {
            shop_id: auth?.shop.id,
            domaine : auth?.shop.customdomaine,
            type : type
        }

        type === "ip" ? setIpCheckLoading(true) : setCnameCheckLoading(true)

        axios.post(SERVER_URL+"store/check/domain/dns",data,attachTokenToHeaders()).then((response)=>{
            type === "ip" ? setIpCheckLoading(false) : setCnameCheckLoading(false)
            if (response.data.succeed){
                type === "ip" ? setIpCheckValue(response.data.ip) : setCnameCheckValue(response.data.cname)

            }else{
                toast.error(t(response.data.message))
            }
        })

    }


    const copyData = (type) => {
        const message = type === 1 ? <b>{t('RegisterValue') +" "+t('Copied')}</b> : <b>CNAME {t('Copied')}</b>
        toast(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            transition: Zoom,
        });
    }



    return (
        <>
            <h5>{t('Shop')}</h5>
            <hr/>
            <div className="row" style={{marginTop : "20px"}}>

                {/* Name rename*/}
                <div className="mt-2">
                    <div className="row">
                        <div className="col-lg-4 mb-30">
                            <label>{t('shopName')}</label>
                            <br/>
                            <small className="text-muted">{t('ChangeShopName')}</small>
                        </div>

                        <div className="col-lg-8">
                            <div className="row">

                                <div className="col-lg-10">
                                    <input className="front" value={shopName} readOnly={!editShopName}
                                           onChange={event => setShopName(event.target.value)} />
                                </div>
                                <div className="col-lg-2">
                                    <div className="position-relative button-container">
                                        {auth?.plan?.pricing_id !== 5 ? <div className="s-ribbon"><span>Premium</span></div> : ""}
                                        {
                                            editShopName ?
                                                <button className="btn btn-success"
                                                        disabled={nameLoading}
                                                        onClick={event => updateShopName()}>
                                                    {nameLoading ? t('Loading') : t('Edit')}
                                                </button>
                                                :
                                                <button className="btn btn-soft-dark"
                                                        disabled={auth?.plan?.pricing_id !== 5}
                                                        onClick={event => {
                                                            auth?.plan?.pricing_id !== 5 ? console.log("") : setEditShopName(true);
                                                        }}>
                                                    {t('Edit')}
                                                </button>
                                        }
                                        {editShopName &&
                                            <i className="mdi mdi-close text-muted fs-20 pl-2 cursor-pointer"
                                               onClick={event => setEditShopName(false)}></i>
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                {/* Domaine rename*/}
                <div className="" style={{ marginTop: '50px' }}>

                    <div className="row">
                        <div className="col-lg-4 mb-30">
                            <label>{t('Domaine')}
                                <a href={'https://' + auth?.shop?.domaine + '.' + DOMAINE} title={t('OpenLink')}
                                   target="_blank"><i className="mdi mdi-link"></i></a></label>
                            <br />
                            <small className="text-muted">{t('ChangeDomaineName')}</small>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-7">
                                <input className="front" value={domaine}  onChange={event => setDomaine(event.target.value.toLowerCase()
                                       .split(' ')
                                       .join('')
                                       .normalize("NFD")
                                       .replace(/[\u0300-\u036f]/g, "")
                                       .replace(/'/g, '')
                                       .replace(/"/g, '')
                                       .replace(/[()]/g, '')
                                   )}  readOnly={!editDomaine} />
                               </div>
                               <div className="col-lg-3">
                                   <h5 style={{paddingTop : "20px"}}>.{DOMAINE}</h5>
                               </div>
                                <div className="col-lg-2">
                                    <div className="position-relative button-container">
                                        {auth?.plan?.pricing_id !== 5 ? <div className="s-ribbon"><span>Premium</span></div> : ""}
                                    {
                                        editDomaine ?
                                            <button className="btn  btn-success"
                                                    disabled={domainLoading}
                                                    onClick={event => updateDomain()}>{t('Edit')}</button>
                                            : <button className="btn  btn-soft-dark"
                                                      disabled={auth?.plan?.pricing_id !== 5}
                                                      onClick={event => {
                                                          !auth?.plan?.pricing_id !== 5 ?  console.log("") : setEditDomaine(true)
                                                      }}>{t('Edit')}</button>
                                    }

                                    {editDomaine && <i className="mdi mdi-close text-muted fs-20 pl-2 cursor-pointer"
                                                       onClick={event => setEditDomaine(false)}></i>}
                                </div>

                                </div>
                            </div>
                        </div>
                    </div>




                </div>

                {/* Add custom Domaine */}
                <div className="" style={{marginTop : "50px"}}>

                    <div className="row">
                        <div className="col-lg-4 mb-30">
                            <label>{t('PersonaleDomaine')}</label>
                            <br/>
                            <small className="text-muted">{t('ChangeNewDomaineName')}</small>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-10">
                                    <input className="front" value={customDomain} placeholder="exemple.com" onChange={event => setCustomDomain(event.target.value)}  readOnly={!editNewDomaine} />
                                </div>

                                <div className="col-lg-2">
                                    {
                                        editNewDomaine? <button className="btn  btn-success"   onClick={event => addNewCustomDomain()}>{t('Edit')}</button> : <button className="btn  btn-soft-dark" onClick={event => setEditNewDomaine(true)}>{t('Edit')}</button>
                                    }

                                    {editNewDomaine &&  <i className="mdi mdi-close text-muted fs-20 pl-2 cursor-pointer" onClick={event => setEditNewDomaine(false)}></i>}
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="row">
                         <div className="offset-lg-4 col-lg-8">
                             {
                                 auth?.shop?.customdomaine  && !auth?.shop?.cname_check && !auth?.shop?.ip_check ?
                                     <div className="dns_check_panel">
                                         <h5 className="mb-30">* {t('Instructions')}</h5>

                                         <p>1. <small>{t('InstructionText1')}</small></p>
                                         <p>2. <small>{t('InstructionText2')}</small></p>
                                         <p>3. <small>{t('InstructionText3')}</small></p>

                                         <br />
                                         <br />
                                         <div className="row mb-30">
                                             <div className="col-lg-6">
                                                 <p>
                                                     <b>{t('Saving')} A {!ipCheckvalue?.isTargetIpUsed ?
                                                         <i className="bx bx-info-circle text-danger ml-20"></i> :
                                                         <i className="bx bx-check-circle text-success ml-20"></i>} </b>
                                                 </p>

                                                 <p className="text-muted">
                                                     Nom : <b>@</b>
                                                     <br />
                                                     {
                                                         ipCheckvalue && !ipCheckvalue?.isTargetIpUsed ? <>  {t('ActualValue')} : <b
                                                             className="text-danger">{ipCheckvalue?.addresses[0]}</b>
                                                             <br /></> : ''
                                                     }
                                                     {t('RequiredValue')} : <b>{IpRequired}</b>
                                                     <CopyToClipboard text={IpRequired} onCopy={event => copyData(1)}>
                                                         <span className="text-primary text-muted" title={t('Copy')}><i
                                                             className="ri-file-copy-fill ml-20 cursor-pointer"></i></span>
                                                     </CopyToClipboard>
                                                 </p>

                                                 <button className="btn btn-light btn-sm"
                                                         disabled={ipCheckLoading}
                                                         onClick={event => verifyIpOrCname('ip')}>
                                                     {
                                                         ipCheckLoading ?
                                                             <div className="">
                                                                 <span className="spinner-border spinner-border-sm"
                                                                       role="status"
                                                                       aria-hidden="true" /> {t('Vérification')}...
                                                             </div>
                                                             :
                                                             <span>
                                                                {t('Verify')}
                                                             </span>
                                                     }
                                                 </button>
                                             </div>
                                             <div className="col-lg-6">
                                                 <p>
                                                     <b>CNAME {!cnameCheckvalue?.isTargetCnameUsed ?
                                                         <i className="bx bx-info-circle text-danger ml-20"></i> :
                                                         <i className="bx bx-check-circle text-success ml-20"></i>} </b>
                                                 </p>

                                                 <p className="text-muted">
                                                     Nom : <b>www</b>
                                                     <br />
                                                     {
                                                         cnameCheckvalue && !cnameCheckvalue?.isTargetCnameUsed ? <>{t('ActualValue')} : <b
                                                             className="text-danger">{cnameCheckvalue?.cnames[0]}</b>
                                                             <br /></> : ''
                                                     }
                                                     {t('RequiredValue')} : <b> {CnameRequired}</b>

                                                     <CopyToClipboard text={CnameRequired}
                                                                      onCopy={event => copyData(2)}>
                                                         <span className="text-primary text-muted" title={t('Copy')}><i
                                                             className="ri-file-copy-fill ml-20 cursor-pointer"></i></span>
                                                     </CopyToClipboard>
                                                 </p>
                                                 <button className="btn btn-light btn-sm"
                                                         disabled={cnameCheckLoading}
                                                         onClick={event => verifyIpOrCname('cname')}>
                                                     {
                                                         cnameCheckLoading ?
                                                             <div className="">
                                                                 <span className="spinner-border spinner-border-sm"
                                                                       role="status"
                                                                       aria-hidden="true" /> {t('Vérification')}...
                                                             </div>
                                                             :
                                                             <span>
                                                                {t('Verify')}
                                                             </span>
                                                     }
                                                 </button>
                                             </div>
                                         </div>
                                         <div className="row">
                                             <Alert color="warning">
                                                 <b>
                                                     <img width="18" height="18"
                                                          src="https://img.icons8.com/emoji/48/warning-emoji.png"
                                                          alt="warning-emoji" /> {t('IncompletConnection')}
                                                 </b>
                                                 <br />
                                                 <p className="ml-20 mt-20">
                                                     {t('IncompletText')}
                                                 </p>
                                             </Alert>
                                         </div>
                                         <div className="d-flex justify-content-center">
                                             <button className="btn btn-light mb-10" onClick={event => {
                                                  loadMe()
                                                 toast.success(t('ValidateConfig'))
                                             }}>{t('ValidateConfig')}</button>
                                         </div>
                                     </div>
                                     : ''
                             }


                         </div>

                    </div>


                </div>

            </div>

            <div className="row" style={{ marginTop: '60px' }}>
                <div className="" style={{ marginTop: '50px' }}>

                    <div className="row">
                        <div className="col-lg-4">
                            <label>{t('LanguageSupport')}</label>

                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[options[0], options[1]]}
                                        isDisabled={true}
                                        className="front"
                                        isMulti
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="" style={{ marginTop: "50px" }}>

                    <div className="row">
                        <div className="col-lg-4">
                            <label>{t('LogoOfShop')}</label>

                        </div>
                        <div className="offset-lg-6 col-lg-2 text-right">
                            <PictureInput
                                logo={SERVER_IMG_URL + auth?.shop.shop_key + "/logo/" + auth?.shop.logo}
                                updateLogo={updateLogo}
                            />
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop
});


export default   compose(connect(mapStateToProps, {  loadMe  })) (withTranslation()(SettingShop));
