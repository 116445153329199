import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {FilePond} from "react-filepond";



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,categories}) => {

    const [loading,setLoading] = useState(false)
    const [pictures, setPictures] = useState([]);


    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('InputRequired')),
        category_id: Yup.string().required(t('InputRequired')),
        free: Yup.string().required(t('InputRequired')),
        price : Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        name: isEdit ? updateData.name : '',
        category_id: isEdit ? updateData.category_id : '',
        free: isEdit ? updateData.free : '',
        price: isEdit ? updateData.price : 0,
    }


    const handleSubmit = async (values) => {
        setLoading(true)

        const formData = new FormData()
        formData.append("name", values.name)
        formData.append("category_id", values.category_id)
        formData.append("free", values.free)
        formData.append("price", values.price)
        // formData.append("type", "admin")
        // formData.append("folder", "templates")
        // pictures.map((pic)=> formData.append("pictures", pic))


        if (isEdit){
            await axios.put(SERVER_URL+"admin/template/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"admin/template/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Template')}</> : <>{t('Edit')} {t('Page')}</>} size="md">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched,values }) =>
                    <Form>

                      <div className="row">
                          {/*<div className="col-lg-7">*/}
                          {/*        <div className="">*/}
                          {/*            <h5 className="fs-14 mb-1">{t('Pictures')} <b className="text-danger">*</b></h5>*/}
                          {/*            <br/>*/}
                          {/*            <FilePond*/}
                          {/*                files={pictures}*/}
                          {/*                allowMultiple={true}*/}
                          {/*                maxFiles={3}*/}
                          {/*                name="download"*/}
                          {/*                id="download"*/}
                          {/*                onupdatefiles={(fileItems) => {*/}
                          {/*                    setPictures(fileItems.map((fileItem) => fileItem.file))*/}
                          {/*                }}*/}
                          {/*                labelIdle={t('DragNdrop')}*/}
                          {/*            />*/}
                          {/*            {errors.download && <span className="text-danger">{t('InputRequired')}</span>}*/}
                          {/*            <br/>*/}
                          {/*        </div>*/}
                          {/*</div>*/}
                          <div className="col-lg-12">
                              <div className="row">
                                  <div  className="col-lg-12">
                                      <label htmlFor="name" className="form-label">{t('Name')} <b className="text-danger">*</b></label>
                                      <Field  className="form-control form-control-lg" id="name" name="name"/>
                                      {errors.name && touched.name ? (
                                          <div className='text-danger'>{errors.name}</div>
                                      ) : null}
                                  </div>

                                  <div  className="col-lg-12 mt-2">
                                      <label htmlFor="category_id" className="form-label"> {t('Category')} <b className="text-danger">*</b></label>
                                      <Field as="select"  className="form-select form-control-lg" id="category_id" name="category_id">
                                          <option value="">-</option>
                                          {
                                              categories?.length > 0 ?
                                                  <>
                                                      {
                                                          categories.map((category,key) => <option key={key} value={category.id}>{category?.[localStorage.getItem("I18N_LANGUAGE")]}</option>)
                                                      }
                                                  </>
                                                  : ""
                                          }
                                      </Field>
                                      {errors.category_id && touched.category_id ? (
                                          <div className='text-danger'>{errors.category_id}</div>
                                      ) : null}
                                  </div>

                                  <div  className="col-lg-12 mt-2">

                                      <div className="form-check form-switch">
                                          <Field  className="form-check-input" type="checkbox" id="free" name="free"/>
                                          <label htmlFor="free" className="form-label">{t('Free')}  <b className="text-danger">*</b></label>
                                      </div>
                                      {errors.free && touched.free ? (
                                          <div className='text-danger'>{errors.free}</div>
                                      ) : null}
                                  </div>

                                  <div  className="col-lg-12 mt-2">
                                      <label htmlFor="price" className="form-label">{t('Price')}  <b className="text-danger">*</b></label>
                                      <Field  type="number"  className="form-control form-control-lg" id="price" name="price"/>
                                      {errors.price && touched.price ? (
                                          <div className='text-danger'>{errors.price}</div>
                                      ) : null}
                                  </div>

                              </div>
                          </div>
                      </div>


                        <div className="row">
                            <div className="col-lg-7 pt-3">
                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                            </div>
                            <div className="col-lg-5 pt-3">
                                <button className="btn btn-block btn-success" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>
        </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)
