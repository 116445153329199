import {ADD_CART, DECREASE_QUANTITY, DELETE_CART, INCREASE_QUANTITY,CLEAR_CART} from "./type";

export function AddCart(payload){

    console.log(payload)
    return {
        type:ADD_CART,
        payload : payload
    }
}

export function DeleteCart(payload){
    return{
        type:DELETE_CART,
        payload : payload
    }
}

export function Increase(payload){
    return{
        type:INCREASE_QUANTITY,
        payload : payload
    }
}

export function Decrease(payload){
    return{
        type:DECREASE_QUANTITY,
        payload : payload
    }
}

export function ClearCart(payload){
    return{
        type:CLEAR_CART,
        payload : payload
    }
}