import React, {useState} from 'react';
import {withTranslation} from "react-i18next";

const DynamicFieldInput = ({t,setVariant}) => {

    const [data, setData] = useState([{label : "" , value : ""}]);


    const handleChange = (event,key) => {
       const {name,value} = event.target
        // setData(prevState => prevState.map((item,index) => index === key? {...item, [name] : item[name]} : item))
        const chang = [...data]
        chang[key][name] = value
        setData(chang)
        setVariant(chang)
    }

    const removeElement = (key) => {
      const del = [...data]
        del.splice(key,1)
        setData(del)
        setVariant(del)
    }


    const handleAdd = () => {
      setData(prevState => [...prevState,{label : "" , value : ""}])
    }

    return (
        <div>


            {
                data.map((val,i) =>

                <div className="row mt-20" key={i}>
                     <div className="col-lg-5">
                         <input type="text" placeholder={t('Label')} name="label" className="form-control" value={val.label} onChange={(e) => handleChange(e,i)}/>
                     </div>
                    <div className="col-lg-5">
                        <input type="text" placeholder={t('Value')} name="value" className="form-control" value={val.value} onChange={(e) => handleChange(e,i)}/>
                    </div>

                        <div className="col-lg-2">
                            <button className="btn btn-light text-danger" onClick={() => removeElement(i)}>
                                {t('Remove')}
                            </button>
                        </div>
                </div>

                )
            }

            <div className="offset-lg-10 col-lg-2 mt-20">
                <button className="btn btn-light" onClick={handleAdd} >{t('Add')}</button>
            </div>
        </div>
    );
};

export default withTranslation()(DynamicFieldInput)
