import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import placeholder from '../styles/product.png'
import {Link, useHistory, withRouter} from "react-router-dom";
import {CalculateDiscount, numberWithCommas, SERVER_IMG_URL, SortImageByProduct} from "../../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {AddCart} from "../../../../../store/Subdomain/cart/action";
import {toast} from "react-toastify";

const TrialCard = ({t,item,AddCart,galerie}) => {

    const [shop,setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));
    const [picture, setPicture] = useState(null);

    const history = useHistory()

    useEffect(() => {
       setPicture(SortImageByProduct(item.gallery_key,galerie)[0])
    }, [galerie]);



    const goTo = () =>{
        history.push(`/product/detail/${item.hash_key}`)
    }
    const addToCart = () => {
        AddCart({
            id:item.id,
            qty : 1,
            category : item.category,
            picture : SERVER_IMG_URL+shop?.shop_key+"/products/"+JSON.parse(item?.pictures)[0],
            name:item.title,
            price:CalculateDiscount(item?.price,item?.discount)
        })

        toast.success(t("AddToCart"), {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    }




    return (
        <>
            <div className="product-o product-o--hover-on product-o--radius">
                <div className="product-o__wrap">

                    <Link className="aspect aspect--bg-grey aspect--square u-d-block" to={`/product/detail/${item.hash_key}`}>
                        <img className="aspect__img" src={item?.pictures ? SERVER_IMG_URL+shop?.shop_key+"/products/"+JSON.parse(item?.pictures)[0]: placeholder} alt=""/>
                    </Link>
                    <div className="product-o__action-wrap">
                        <ul className="product-o__action-list">
                            <li>
                                <span className="cursor-pointer" onClick={event => goTo()} style={{color : "#fff"}} title="Detail"><i className="fas fa-eye"></i></span>
                            </li>
                            <li>
                                <span className="cursor-pointer"  style={{color : "#fff"}} onClick={event => addToCart()} title={t('AddToCart')}><i className="fas fa-shopping-bag"></i></span>
                            </li>

                        </ul>
                    </div>
                </div>

                <span className="product-o__category">
                    <a href="#">{item.category}</a>
                </span>

                <span className="product-o__name"><Link  to={`/product/detail/${item.hash_key}`}>{item.title}</Link></span>

                    {item.discount  ?
                                    <span className="product-o__price">{numberWithCommas(CalculateDiscount(item.price,item.discount) )} Frcs
                                    <span className="product-o__discount">{numberWithCommas(item?.price)} Frcs</span>
                                  </span>


                        :

                        <span className="product-o__price">{item?.price} Frcs</span>

                    }

            </div>
        </>
    );
}


const mapStateToProps = (state) => ({
    cart : state.cart,
});

export default compose(withRouter, connect(mapStateToProps, { AddCart })) (withTranslation()(TrialCard))
