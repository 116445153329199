import React, {useCallback, useEffect, useRef, useState} from 'react';
import {flatData} from "../../../utils/utils";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


const Galery = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [items, setItems] = useState([]);

    const photos = [
        {
            src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
            width: 4,
            height: 3
        },

    ];


    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const galery = useRef()

    const getBlockSize = () => {
        setDimension({top :galery?.current?.offsetTop, left : galery?.current?.offsetLeft, width : galery?.current?.offsetWidth, height : galery?.current?.offsetHeight,nodeName : galery?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);


    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };




    return(
        <>
            <section className="pt-10 pb-10"
                     style={customStyle}
            >
                <div className="container"
                     ref={galery}
                     onMouseEnter={() => isEditor && getDimension(block, true)}
                     onMouseLeave={() => isEditor && getDimension(block, false)}
                >
                    <Gallery photos={items.map((item) => {
                        return {src : flatData(item,'src'), width : flatData(item,'width'), height : flatData(item,'height')}
                    } )} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={items.map((item) => {
                                        return {src : flatData(item,'src'), width : flatData(item,'width'), height : flatData(item,'height')}
                                    } ).map(x => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>


                </div>
            </section>
        </>
    )
}

export default Galery;