import React, { Fragment, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Capitalize, SERVER_URL } from '../../helpers/utils'
import NotFoundSubdomain from './Required/NotFoundSubdomain';
import PageManager from './PageManager';
import { MiddleLoading } from '../../components';
import Maintenance from './Required/Maintenance';
import TrialIndex from './Trial/TrialIndex';
import Mobile from './Mobile/Mobile';
import SubLoading from '../../components/Loading/SubLoading';
import NotAvailable from './Required/NotAvailable'

const Index = ({ subdomain }) => {
    useEffect(() => {
        document.title = Capitalize(subdomain.content)
        checkSubdomain();
    }, [subdomain]);

    const [exist, setExist] = useState(true);
    const [loading, setLoading] = useState(false);
    const [shop, setShop] = useState({});

    const Created = ['app'];

    const checkSubdomain = async () => {
        setLoading(true);
        try {
            const response = subdomain.type === 'subdomain'
                ? await axios.get(`${SERVER_URL}check/subdomain/${subdomain.content}`)
                : await axios.post(`${SERVER_URL}check/domain`, subdomain);

            setLoading(false);
            if (response.data.succeed) {
                setExist(true);
                setShop(response.data);
            } else {
                setExist(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred while checking the subdomain/domain:', error);
        }
    };



    return (
        <Fragment>
            {loading ? (
                <MiddleLoading />
            ) : (
                <Fragment>
                    {Created.includes(subdomain.content) ? (
                        <Fragment>{subdomain.content === 'app' && <Mobile />}</Fragment>
                    ) : (
                        <Fragment>
                            {exist ? (
                                <Fragment>
                                    {shop?.shop?.statut === 2 ? (
                                        <Fragment>
                                            {shop?.plan?.trial === 1 ? (
                                                <TrialIndex shop={shop} domain={shop?.shop?.domaine} />
                                            ) : (
                                                <PageManager data={shop} domain={shop?.shop?.domaine} />
                                            )}
                                        </Fragment>
                                    ) :
                                        shop?.shop?.statut === 3 ?
                                            <NotAvailable domain={shop?.shop?.domaine} shopName={shop?.shop?.shopName}  />
                                            :

                                            (
                                                <Fragment>
                                                    {shop?.shop ? (
                                                        <div>
                                                            <Maintenance shopName={shop?.shop?.shopName}
                                                                         domain={shop?.shop?.domaine} />
                                                        </div>
                                                    ) : (
                                                        <SubLoading />
                                                    )}
                                                </Fragment>
                                            )
                                    }

                                </Fragment>
                            ) : (
                                <NotFoundSubdomain />
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Index;
