import React from 'react';
import {APPNAME, CLIENT, SERVER} from "../../../../../helpers/utils";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import CustomMap from "../../../../../components/CustomMap/CustomMap";

const TrialFooter = ({shopDatas,domaine,t}) => {


    return (
        <footer>
            <div className="outer-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="outer-footer__content u-s-m-b-40">

                                <span className="outer-footer__content-title">{t('Contact')}</span>
                                <div className="outer-footer__text-wrap"><i className="fas fa-home"></i>

                                    <span>{shopDatas?.shop.address}</span></div>
                                <div className="outer-footer__text-wrap"><i className="fas fa-phone-volume"></i>

                                    <span>{shopDatas?.shop.phone}</span></div>
                                <div className="outer-footer__text-wrap"><i className="far fa-envelope"></i>

                                    <span>{shopDatas?.shop.email}</span></div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="outer-footer__content u-s-m-b-40">

                                        <span className="outer-footer__content-title">Information</span>
                                        <div className="outer-footer__list-wrap">
                                            <ul>
                                                <li><a href="/cart">{t('Cart')}</a></li>
                                                <li><a href="/customer/dashboard">{t('Account')}</a></li>
                                                <li><a href="/products">{t('Products')}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="outer-footer__content" style={{height : "200px"}}>
                                <CustomMap description={shopDatas?.shop.shopName} zoom={12} position={JSON.parse(shopDatas?.shop.lat_long)} editable={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="lower-footer__content">
                                <div className="lower-footer__copyright">
                                    <span>Copyright © {new Date(shopDatas?.shop?.created_at).getFullYear()}</span> <a href={CLIENT}> {APPNAME.toUpperCase()}</a> <span>{t('AllRightReserved')}</span></div>
                                <div className="lower-footer__payment">
                                    <ul>
                                        <li><i className="fab fa-cc-paypal"></i></li>
                                        <li><i className="fab fa-cc-visa"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default withTranslation() (TrialFooter)
