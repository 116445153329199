import React from 'react';
import {expireDate} from "../helpers/utils";
import AppAuthorized from "./Authorized/AppAuthorized";
import LevelAuthorized from "./Authorized/LevelAuthorized";

const PluginAccess = ({auth,plugin,children,free}) => {
    if (auth?.plan?.pricing_id > 2){
        const exist = auth?.plugins.filter(e  => e.name == plugin)
       if (exist && free){
           return children
       }else{
           if (exist.length > 0 && !expireDate(exist[0].created_at)){
               return children
           }else{
               return <AppAuthorized />
           }
       }
    }else {
        return <LevelAuthorized />
    }
}

export default PluginAccess;
