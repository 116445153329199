import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal, RegLoading} from "../../../../components";
import ReactStars from "react-rating-stars-component";
import {Tooltip} from "reactstrap";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import tips from "../../../../assets/tips.svg"
import swal from "@sweetalert/with-react";
import {toast} from "react-toastify";


const DelivererDetail = ({open,setOpen,t,data,auth,created}) => {

    const [rating,setRating] = useState(1)
    const [ratingList,setRatingList] = useState([])
    const [tooltip,setTooltip] = useState(false)
    const [loading, setLoading] = useState(false);
    const [canRate, setCanRate] = useState(0);

    useEffect(() => {
       if (Object.entries(data).length > 0) {
           getRating(data.id)
       }
    }, [data]);


    const getRating = async (id) => {
        setLoading(true)
        try{
            await axios.get(SERVER_URL+"deliverer/rates/list/"+auth?.shop.id+"/"+id,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                     setRatingList(response.data.rates)
                    setCanRate(response.data.canRate)
                     calculateRatingList(response.data.rates)
                }
            })
        } catch (e) {
            // toast.error(e.message)
        }
    }

    const calculateRatingList = (ratesData) => {
        console.log(ratesData)
      if (ratesData.total){
          const total = parseInt(ratesData.total) / ratesData.line
          setRating(total)
      }
      else{
          setRating(0)
      }
    }

    const toggleApprouved = async (checked) =>{

        const type = checked ? 1 : 3

        await swal({
            title : t('AutoPaid'),
            text : type === 3 ? t('SureToDisabledDeliverer') : t('SureToEnabledDeliverer'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 3,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}deliverer/autopaid/${type}/${data?.id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        created()
                        setOpen(false)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const toggle = () => {
        setTooltip(!tooltip)
    };

    const rateThis = async (value) => {
        await swal({
            title : t('Rate'),
            text : t('RateThisDeliverer'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: false,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const dataRate = {
                        shop_id : auth?.shop.id,
                        deliverId : data.id,
                        rate : value,
                        hash_key : data.hash_key
                    }
                    const response = await axios.post(`${SERVER_URL}deliverer/rates`,dataRate,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setRating(value)
                        created()
                        setOpen(false)
                    }else{
                        toast.error(t(response.data.message))

                    }
                } catch (error) {
                    console.log(error)
                }
            }
        })

    }

    return (
            <CustomModal open={open} setOpen={setOpen} isHtml={true} title={`<span>Detail ${t('Of')} ${t('Delivere')}</span>`} size="md">

                {
                    loading ?
                       <div style={{marginBottom : "150px"}}>
                           <RegLoading />
                       </div>
                        :
                       <div>
                           <table className='table'>
                               <tbody>
                               <tr>
                                   <th>{t('Fullname')}</th>
                                   <td colSpan='2'>{data?.firstname} {data?.lastname}</td>
                               </tr>
                               <tr>
                                   <th>{t('Address')}</th>
                                   <td colSpan='2'>{data?.city}, {data?.country}</td>
                               </tr>
                               <tr>
                                   <th>{t('Email')}</th>
                                   <td colSpan='2'>{data?.email}</td>
                               </tr>
                               <tr>
                                   <th>{t('Phone')}</th>
                                   <td colSpan='2'>{data?.phone}</td>
                               </tr>

                               {
                                   data?.shopName ?

                                       <tr>
                                           <th>{t('Shop')}</th>
                                           <td colSpan='2'>{data?.shopName}</td>
                                       </tr>

                                       : ""
                               }

                               <tr>
                                   <th>{t('Verified')}</th>
                                   <td colSpan='2'>
                                       {data.verified == 1
                                           ? <span className="badge badge-soft-success">{t('Approuved')}</span>
                                           :  <span className="badge badge-soft-primary">{t('Pending')}</span>
                                       }
                                   </td>
                               </tr>
                               <tr>
                                   <th>{t('AutoPaid')}</th>
                                   <td colSpan='2'>
                                       {data.autoPaid == 1
                                           ? <span className="badge badge-soft-success">{t('Active')}</span>
                                           :  <span className="badge badge-soft-primary">{t('Pending')}</span>
                                       }
                                   </td>
                               </tr>
                               <tr>
                                   <th>{t('Status')}</th>
                                   <td colSpan='2'>
                                       {(data.statut == 1)
                                           ? <span className="badge badge-soft-success">{t('Active')}</span>
                                           :  <span className="badge badge-soft-danger">{t('Inactive')}</span>
                                       }
                                   </td>
                               </tr>

                               <tr>
                                   <th>
                                       {t('Rated')} <br/>
                                   </th>
                                   <td colSpan='2'>{
                                       rating > 0 ?
                                           <>
                                               {rating}<i className="bx bx-star text-warning"></i>
                                           </>
                                           : "N/F"
                                   }</td>
                               </tr>

                               {
                                   canRate > 0 ?

                                       <tr>
                                           <th>
                                               {t('Rate')} <br/>
                                           </th>
                                           <td colSpan='2'>
                                               <div style={{marginTop : '-10px'}} id="rating">
                                                   <ReactStars
                                                       count={5}
                                                       size={17}
                                                       edit={true}
                                                       value={rating}
                                                       onChange={rateThis}
                                                       emptyIcon={<i className="bx bx-star"></i>}
                                                       fullIcon={<i className="bx bx-star"></i>}
                                                       activeColor="#ffd700"
                                                   />
                                                   <Tooltip placement="right" isOpen={tooltip} target="rating" toggle={toggle}>
                                                       {t('RateTheDeliver')}
                                                   </Tooltip>
                                               </div>
                                           </td>
                                       </tr>

                                       : ""
                               }
                               </tbody>
                           </table>

                           {
                               canRate ?

                                   <>
                                       <br/>
                                       <div className="form-check form-switch">
                                           <input className="form-check-input" defaultChecked={data.autoPaid == 1} type="checkbox" role="switch" onChange={event => toggleApprouved(event.target.checked)}
                                                  id="flexSwitchCheckDefault"/>
                                           <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t('ActivateAutoPaid')}</label>
                                       </div>
                                       <br/>
                                       <p>
                                           <img className="img-responsive" src={tips} style={{color : "red"}} /> <small>{t('ActivateAutoPaidText')}</small>
                                       </p>
                                   </>

                                    : ""
                           }
                       </div>
                }

        </CustomModal>
    );
}

export default withTranslation()(DelivererDetail);
