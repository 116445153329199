import React, {Fragment} from 'react';
import {withTranslation} from "react-i18next";
import {APPNAME, CLIENT, DOMAINE} from "../../../helpers/utils";
import logo from "../../../assets/logo.png";
import {LINK_DOCS, LINK_REGISTER_STORE} from "../../../routes/type";
import mobile__001 from "../../../assets/mobile/mobile__001.png"
import mobile__010 from "../../../assets/mobile/mobile__010.png"

const Mobile = ({t}) => {
    document.title = APPNAME+" :: "+t('MobileApp')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('MobileApp'))
    document.querySelector("meta[name='description']").setAttribute("content", t('AppMobileMetaDesc')+" "+APPNAME)

    return (
        <Fragment>
            <header className="template-header navbar-center absolute-header  nav-border-bottom sticky-header">
                <div className="container-fluid container-1600">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="branding-and-language-selection branding-border-right">
                                <div className="brand-logo">
                                    <a href="">
                                        <img src={logo} style={{width : "159px",paddingTop : "20px"}} alt="logo"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="header-center"><nav className="nav-menu d-none d-xl-block"></nav></div>
                        <div className="header-right"></div>
                    </div>
                </div>


                <div className="mobile-slide-panel">
                    <div className="panel-overlay"></div>
                    <div className="panel-inner">
                        <div className="mobile-logo">
                            <a href="index.html">
                                <img src="https://demo.webtend.net/html/landio_preview/assets/img/logo-1.png" alt="Landio"/>
                            </a>
                        </div>
                        <nav className="mobile-menu">
                            <ul>
                                <li>
                                    <a href="index.html">Demos</a>
                                </li>

                            </ul>
                        </nav>
                        <a href="#" className="panel-close">
                            <i className="fal fa-times"></i>
                        </a>
                    </div>
                </div>

            </header>

            <section className="hero-area-v8">
                <div className="container">
                    <div className="row align-items-end justify-content-center">
                        <div className="col-lg-6 col-md-9">
                            <div className="hero-content">
                                <h2 className="hero-title wow fadeInDown" data-wow-delay="0.3s">{APPNAME} Delivery</h2>
                                <p className="wow fadeInLeft" data-wow-delay="0.4s">
                                    Sed perspicias unde omnis natus error voluptate dolorem laudanme totam aperiam.
                                </p>
                                <ul className="hero-btns d-flex align-items-center wow fadeInUp" data-wow-delay="0.5">
                                    <li>
                                        <a href="service-details.html" className="template-btn primary-bg-4">
                                            {t('DirectDownload')} <i className="fab fa-android"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="service-details.html" className="template-btn bordered-body-4">
                                            Google Play <i className="fab fa-google-play"></i>
                                        </a>
                                    </li>
                                </ul>

                                <span className="notice wow fadeInUp" data-wow-delay="0.6"><i
                                    className="fas fa-cloud-download-alt"></i> 523560k Download Apps</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7 d-none d-md-block">
                            <div className="hero-img wow fadeInUp" data-wow-delay="0.5">
                                <div className="hero-img-inner">
                                    <img src="https://html.designingmedia.com/apprista/assets/images/bannerphone1-img.png" alt="Hero"/>
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/hero/hero-8-circle.png" alt="Circle" className="circle-shape"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-section">
                <div className="e-wallet-boxed-container p-t-120 p-b-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="common-heading text-center m-b-30">
                                    <h2 className="title">Don’t Let Your Goals Be Frustrating to Track You'll Waste
                                        Effort Working on The Wrong Problems</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row icon-boxes-v3 justify-content-center">
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="iconic-box have-icon-overly color-v1 m-t-50">
                                    <div className="icon">
                                        <i className="far fa-cloud-download"></i>
                                    </div>
                                    <h4 className="title"><a href="#">Download Apps</a></h4>
                                    <p>
                                        But must explain mistake denouncie pleasure praisin was born complete
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="iconic-box have-icon-overly color-v2 m-t-50">
                                    <div className="icon">
                                        <i className="far fa-pencil-ruler"></i>
                                    </div>
                                    <h4 className="title"><a href="#">Registration Steps</a></h4>
                                    <p>
                                        But must explain mistake denouncie pleasure praisin was born complete
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="iconic-box have-icon-overly color-v3 m-t-50">
                                    <div className="icon">
                                        <i className="far fa-wallet"></i>
                                    </div>
                                    <h4 className="title"><a href="#">Load Your Wallet</a></h4>
                                    <p>
                                        But must explain mistake denouncie pleasure praisin was born complete
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.4s">
                                <div className="iconic-box have-icon-overly color-v4 m-t-50">
                                    <div className="icon">
                                        <i className="far fa-shield-check"></i>
                                    </div>
                                    <h4 className="title"><a href="#">Ready To Go</a></h4>
                                    <p>
                                        But must explain mistake denouncie pleasure praisin was born complete
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="payment-section p-t-150 p-b-150 p-b-md-110">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6">
                            <div className="preview-galley-v9">
                                <img src={mobile__001} alt="Image" className="image-one" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-10">
                            <div className="payment-content p-l-70 p-l-md-0 m-t-md-100">
                                <div className="common-heading m-b-50">
                                    <span className="tagline color-primary-4">Payments Systems</span>
                                    <h2 className="title">Share Payments With Family & Firends</h2>
                                </div>
                                <p className="m-b-25">
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                    doloremque laudane totam rem aperiam eaque ipsa quae abilloe
                                </p>
                                <p>
                                    Boluptatem accusantium doloremque laudane totam rem aperiam eaque ipsa quae abilloe
                                </p>

                                  <p >
                                      <a href="service-details.html" className="template-btn primary-bg-4" style={{marginRight : "10px",marginTop : "40px"}}>
                                          {t('DirectDownload')} <i className="fab fa-android"></i>
                                      </a>
                                      <a href="service-details.html" className="template-btn bordered-body-4 m-t-40">
                                          Google Play <i className="fab fa-google-play"></i>
                                      </a>
                                  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="benefit-section">
                <div className="e-wallet-boxed-container">
                    <div className="container">
                        <div className="row align-items-center justify-content-lg-between justify-content-center">
                            <div className="col-lg-5 col-md-8">
                                <div className="benefit-content">
                                    <div className="common-heading m-b-45">
                                        <span className="color-primary-4">Merchant Benefit</span>
                                        <h2 className="title">International Money Remittance</h2>
                                    </div>
                                    <ul className="check-list-3 wow fadeInUp" data-wow-delay="0.2s">
                                        <li>
                                            <h4 className="title">High Conversion</h4>
                                            <p>On the other hand denounce with righteous</p>
                                        </li>
                                        <li>
                                            <h4 className="title">No Chargeback Risk</h4>
                                            <p>Quis autem vel eum iure reprehenderit voluptate</p>
                                        </li>
                                        <li>
                                            <h4 className="title">Recurring Payments</h4>
                                            <p>Sed ut perspiciatis unde omnis iste natus</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8">
                                <div className="benefit-preview-images">
                                    <div className="image-one animate-float-bob-y">
                                        <img src="https://html.designingmedia.com/apprista/assets/images/manage-phone1.png" alt="Image"/>
                                    </div>
                                    <div className="image-two animate-float-bob-y">
                                        <img src="https://html.designingmedia.com/apprista/assets/images/manage-phone2.png" alt="Image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="brands-area">
                <div className="e-wallet-boxed-container p-t-80 p-b-30">
                    <div className="container">
                        <div className="row brands-item-grid align-items-center">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-lg-left text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-6.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-7.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-8.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-lg-right text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-9.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-lg-left text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-10.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-11.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-12.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="brand-item text-lg-right text-center m-b-50">
                                    <a href="#">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/brands/brand-13.png" alt="BrandItem"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="cta-section">
                <div className="e-wallet-boxed-container boxed-shape p-t-80 p-b-80">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-7 col-lg-6 col-md-10">
                                <div className="cta-img p-r-30 p-r-lg-0 m-b-50">
                                    <img src="https://demo.webtend.net/html/landio_preview/assets/img/cta/e-wallet.png" alt="Image" className="animate-float-bob-y"/>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-10">
                                <div className="download-text">
                                    <div className="common-heading m-b-30">
                                        <span className="tagline color-primary-4">Download E-Wallet Apps </span>
                                        <h2 className="title">We Available On Store Download Now</h2>
                                    </div>
                                    <p className="m-b-40">
                                        Sed ut perspiciatis unde omnis natus errors voluptatem accusantium doloremque
                                        laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et
                                        quasi archite beatae vitae dicta sunt explicabo.
                                    </p>

                                    <ul className="cta-btns" style={{display : "flex"}}>
                                        <li>
                                            <a href="services.html" className="template-btn bordered-body-4">
                                                Google Play <i className="fab fa-google-play"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <footer className="template-footer footer-bordered">
                <div className="footer-copyright">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-sm-auto col-12 order-lg-last">
                                <ul className="social-links">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-sm-auto col-12">
                                <p className="copyright-text text-center text-sm-right pt-4 pt-sm-0">
                                    © 2023 <a href="#">{APPNAME}</a>. All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}

export default withTranslation()(Mobile);
