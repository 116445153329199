import React  from 'react';
import {FedaCheckoutButton, FedaCheckoutContainer} from 'fedapay-reactjs';
import {FEDAPAY_PUBLIC_KEY, FEDAPAY_PUBLIC_KEY_LIVE, NODE_ENV} from "../../helpers/keys";
import {toast} from "react-toastify";
import {withTranslation} from "react-i18next";

 const FedaPay = ({text,t,amount,succeedPayment,user,include = false,style = { height: 500, width: 500, backgroundColor: '#eee' }}) => {


   const checkoutButtonOptions = {
        public_key: NODE_ENV=== "dev" ? FEDAPAY_PUBLIC_KEY : FEDAPAY_PUBLIC_KEY_LIVE,
        transaction: {
            amount: amount,
            description: 'Paiement'
        },
       customer: {
           email: NODE_ENV === "dev" ? "john.doe@example.com"  : user.email,
           lastname: NODE_ENV === "dev" ? "Doe"  : user.lastname,
           firstname: NODE_ENV === "dev" ? "John"  : user.firstname
       },
        currency: {
            iso: 'XOF'
        },
        button: {
            class: 'btn btn-primary btn-block btn-large',
            text: text
        },
        onComplete(resp) {
            const FedaPay = window['FedaPay'];
            if (resp.reason === FedaPay.DIALOG_DISMISSED) {
                toast.error(t('PaymentModalClose'));
            } else {
                const details = {
                    id : resp.transaction.id
                }
                succeedPayment(details,'MobileMoney')
            }

        }
    };

        return (
            <div style={{marginBottom : "10px"}}>
                {
                    include ?

                        <FedaCheckoutContainer options={ checkoutButtonOptions } style={style} />

                        :

                        <FedaCheckoutButton options={checkoutButtonOptions } />

                }



            </div>
        )

    }

export default withTranslation()(FedaPay)
