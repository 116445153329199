import React, {useEffect, useRef, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {LINK_STORE_CATEGORIES, LINK_STORE_SUBCATEGORIES} from "../../../../routes/type";
import {Link, useHistory} from "react-router-dom";

import { EditorState, ContentState, convertFromHTML,convertToRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import axios from "axios";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";


const ProductEdit = ({t,auth}) => {
    document.title = APPNAME+" :: " + t('Edit') +" "+ t('Product')

    const slug = useRef(window.location.pathname.split('/').pop());
    const history = useHistory()
    const [categories,setCategories] = useState([])
    const [subcategories,setSubcategories] = useState([])
    const [Gloading,setGloading] = useState(true)

    useEffect(()=>{
        getProductSingle()
    },[slug])

    const [product,setProduct] = useState(null)

    const getProductSingle = async () => {
        setGloading(true)
        await axios.get(SERVER_URL+"product/single/"+slug.current+"/"+auth.shop?.id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setProduct(response.data.product)
                setCategories(response.data.categories)
                setSubcategories(response.data.subcategories)
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(`${response.data.product?.description}`))))
                const filter = response.data.subcategories?.filter(cat => cat.category_id == response.data.product?.category_id)
                setSubcates(filter)
                setGloading(false)
            }else{
                setGloading(false)
                toast.error(t(response.data.message))
            }
        })
    }


    const badges = [
        {name : 'Hot'},
        {name : 'Trending'}
    ]

    const [errors,setErrors] = useState({
        title : false,
        description : false,
        badge : false,
        category : false,
        subcategory : false,
        short_description : false,
        stock : false,
        discount : false,
        price : false
    })

    const [subcates,setSubcates] = useState([])
    const [editorState, setEditorState] = useState("");
    const [loading,setLoading] = useState(false)

    const handleEditorChange = (data) => {
        setEditorState(data);
         let html  = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        setProduct({
            ...product,
             description: html
        })
    };

    const onChangeValue = e => {
        setProduct({
            ...product,
             [e.target.name] : e.target.value
        })

        setErrors({
            ...errors,
            [e.target.name] : false
        })
    }
    const filterSubCategory = (e) =>{

        setProduct({
            ...product,
             category: e.target.value
        })

        const filter = subcategories?.filter(cat => cat.category_id == e.target.value ?? product?.category_id)
        setSubcates(filter)
    }


    const submitProduct =  async () =>{

        var error = false

       if (product.title == "") {
          setErrors({...errors,title: true})
       }

        for (const property in product) {
           if (product[property] == ""){
               setErrors({...errors, [property] : true})
           }
        }

        for (const pr in errors) {
            if (errors[pr]){
                error = true
            }
        }


        if (!error) {

            setErrors({
                title : false,
                description : false,
                badge : false,
                category : false,
                short_description : false,
                stock : false,
                discount : false,
                price : false
            })

           setLoading(true)

           const formData = new FormData()
           formData.append('title',product.title)
           formData.append('description',product.description)
           formData.append('short_description',product.short_description)
           formData.append('category',product.category_id)
           formData.append('isSubcategory',product.isSubcategory)
           formData.append('subcategory',product.subcategory)
           formData.append('badge',product.badge)
           formData.append('price',product.price)
           formData.append('stock',product.stock)
           formData.append('discount',product.discount ? product.discount : 0)


            await axios.post(SERVER_URL+"product/edit/"+slug.current,formData,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                   history.goBack()
                }else{
                    toast.error(t(response.data.message))
                }
            })

       }
    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Edit')} {t('Product')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item">{t('Product')}</li>
                                <li className="breadcrumb-item active">{t('Edit')}</li>

                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            {
                Gloading || !product ?
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center p-t-180 p-b-180">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                </div>
                            </div>
                        </div>
                    </div>
                    :

                    <div id="createproduct-form">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="product-title-input">{t('Title')}</label>
                                            <input type="text" className="form-control" id="product-title-input" name="title" value={product?.title} onChange={event => onChangeValue(event)} placeholder="" required/>
                                            {errors.title && <span className="text-danger">{t('InputRequired')}</span>}
                                        </div>
                                        <div>
                                            <label>Description</label>

                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={handleEditorChange}
                                            />

                                            {errors.description && <span className="text-danger">{t('InputRequired')}</span>}
                                        </div>
                                    </div>
                                </div>



                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab"
                                                   href="#addproduct-general-info" role="tab">
                                                    {t('GeneralInportant')}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="card-body">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="addproduct-general-info" role="tabpanel">
                                                <p>{t('discountTextOptional')}</p>
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="stocks-input"> {t('Stocks')}</label>
                                                            <input type="text" className="form-control" id="stocks-input" name="stock" value={product?.stock} onChange={event => onChangeValue(event)} placeholder={t("Stocks")} required/>
                                                        </div>
                                                        {errors.stock && <span className="text-danger">{t('InputRequired')}</span>}
                                                    </div>
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="product-price-input">{t('Price')} <small>(F CFA)</small></label>
                                                            <div className="input-group has-validation mb-3">
                                                                <input type="text" className="form-control" placeholder={t("Price")} name="price" value={product?.price} onChange={event => onChangeValue(event)}  required/>
                                                            </div>
                                                            {errors.price && <span className="text-danger">{t('InputRequired')}</span>}

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="product-discount-input"> {t('Discount')} <small>(%)</small></label>
                                                            <div className="input-group mb-3">
                                                                <input type="text" className="form-control" placeholder={t("Discount")}  name="discount" value={product?.discount ? product?.discount : 0} onChange={event => onChangeValue(event)} />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="text-end mb-3">
                                    <button type="button" onClick={event => submitProduct()} className="btn btn-success w-sm" disabled={loading}>
                                        {
                                            loading ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                </>
                                                :
                                                <>{t('Edit')}</>
                                        }

                                    </button>
                                </div>
                            </div>


                            <div className="col-lg-4">

                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{t('Short')} Description</h5>
                                    </div>
                                    <div className="card-body">
                                        <textarea className="form-control" maxLength={100} placeholder="" rows="3" name="short_description" value={product?.short_description} onChange={event => onChangeValue(event)}></textarea>
                                        {errors.short_description && <span className="text-danger">{t('InputRequired')}</span>}
                                    </div>

                                </div>

                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Categories</h5>
                                    </div>
                                    <div className="card-body">
                                                    <p className="text-muted mb-2">
                                                        <Link to={LINK_STORE_CATEGORIES}  className="float-end text-decoration-underline cursor-pointer text-primary" style={{fontSize : "12px"}}>{t('AddNew')}</Link>
                                                        <span>{t('ChooseCategory')}</span>
                                                    </p>
                                                    <select className="form-select" id="choices-category-input" name="category" value={product?.category_id} onChange={event => filterSubCategory(event)} >
                                                        <option value="">-</option>
                                                        {
                                                            categories?.map((cate,key)=>
                                                                <option key={key} value={cate.id}>{cate.category}</option>
                                                            )
                                                        }

                                                    </select>

                                                    {errors.category && <span className="text-danger">{t('InputRequired')}</span>}

                                                    {
                                                        product?.category !== "" &&
                                                        <>
                                                            <hr/>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" defaultChecked={product?.isSubcategory== "true" ? true : false}  role="switch" id="flexSwitchCheckChecked" onChange={event => setProduct({
                                                                    ...product,
                                                                    isSubcategory: event.target.checked
                                                                })} />
                                                                <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked">{t('EnableSubCategory')}</label>
                                                            </div>
                                                        </>
                                                    }


                                                    {
                                                        product?.isSubcategory == true || product?.isSubcategory == "true" ?

                                                        <>
                                                            <hr/>
                                                            <p className="text-muted mb-2">
                                                                <Link to={LINK_STORE_SUBCATEGORIES}  className="float-end text-decoration-underline cursor-pointer text-primary" style={{fontSize : "12px"}}>{t('AddNew')}</Link>
                                                                <span>{t('ChooseSubcategory')}</span>
                                                            </p>
                                                            <select className="form-select" id="choices-category-input" name="subcategory" value={product?.subcategory} onChange={event => onChangeValue(event)} >
                                                                <option value="">-</option>
                                                                {
                                                                    subcates?.map((subcat,key)=>
                                                                        <option key={key} value={subcat.id}>{subcat.subname}</option>
                                                                    )
                                                                }

                                                            </select>

                                                            {errors.subcategory && <span className="text-danger">{t('InputRequired')}</span>}
                                                        </>

                                                       : null
                                                    }

                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">{t("Badge")}</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="hstack gap-3 align-items-start">
                                            <div className="flex-grow-1">

                                                <select name="" id="" className="form-select"
                                                        value={product.badge}
                                                        onChange={event => setProduct({
                                                            ...product,
                                                            badge: event.target.value
                                                        })}
                                                >
                                                    <option value="">-</option>
                                                    {
                                                        badges.map((badge, index) => (
                                                            <option key={index}
                                                                    value={badge.name}>{t(badge.name)}</option>
                                                        ))
                                                    }
                                                </select>

                                                {errors.badge &&
                                                    <span className="text-danger">{t('InputRequired')}</span>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
            }

        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth: state.loginShop
});
export default compose(connect(mapStateToProps, {}))(withTranslation()(ProductEdit));
