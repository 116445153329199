import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../utils/utils";

const  Banner1 = ({block,t,toggleHightLight,isEditor,customStyle}) => {

    const [items, setItems] = useState([]);


    useEffect(() => {
        setItems(block.contentEditable[0].data)
    }, [block.contentEditable]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const banner1Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :banner1Ref?.current?.offsetTop, left : banner1Ref?.current?.offsetLeft, width : banner1Ref?.current?.offsetWidth, height : banner1Ref?.current?.offsetHeight,nodeName : banner1Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    return (
        <section className="tp-feature-area tp-feature-border-radius pt-60 pb-15"
                 ref={banner1Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
                 style={customStyle}
        >
            <div className="container">
                <div className="row gx-1 gy-1 gy-xl-0">
                    {
                        items?.length > 0 ?
                            <>
                                {items?.map((item,key) =>
                                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mt-10" key={key}>
                                                    <div className="tp-feature-item d-flex align-items-start">
                                                        <div className="tp-feature-icon mr-15">
                                                            <i className={`${flatData(item,'icon')}`} style={{color : flatData(item,'iconColor'),fontSize : '28px'}}></i>
                                                        </div>
                                                        <div className="tp-feature-content">
                                                            <h3 className="tp-feature-title bold">{flatData(item,'title')}</h3>
                                                            <p>{flatData(item,'text')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                )}
                                    </>
                                    : ""
                                }

                </div>
            </div>
        </section>
    );
}

export default Banner1;
