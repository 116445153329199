import React from 'react';
import {APPNAME} from "../../../../helpers/utils";

const Started = ({t}) => {
    return (
        <>
            <h1 className="title">{t('Welcome')} {t('On')} {APPNAME}</h1>

            <p className="">
                Nous sommes ravis de vous accueillir dans la documentation de {APPNAME}, votre solution tout-en-un pour
                la création de sites e-commerce personnalisés avec des domaines personnalisés. Cette documentation
                complète a été conçue pour vous guider à travers chaque étape, de la configuration initiale à la gestion
                avancée de votre boutique en ligne.
            </p>
            <br/>

            <div>Que vous soyez un entrepreneur en herbe ou un professionnel expérimenté du commerce
                électronique, {APPNAME} vous offre les outils et les ressources nécessaires pour transformer votre
                vision en réalité. Avec notre interface conviviale, nos fonctionnalités puissantes et notre support
                dédié, vous êtes entre de bonnes mains pour créer une boutique en ligne unique et prospère.
            </div>


            <div className="mb-30">
                <p>
                    Dans cette documentation, vous trouverez des instructions détaillées, des tutoriels pas à pas, des
                    conseils pratiques et des exemples concrets pour exploiter pleinement les fonctionnalités
                    de {APPNAME}. Que vous souhaitiez personnaliser votre thème, gérer vos produits, optimiser vos
                    paiements ou promouvoir votre boutique, tout est couvert ici.

                </p>
                <p>
                    N'hésitez pas à explorer et à utiliser cette documentation comme votre guide indispensable pour
                    tirer le meilleur parti de votre expérience avec {APPNAME}. Nous sommes là pour vous accompagner à
                    chaque étape de votre parcours e-commerce et vous aider à atteindre vos objectifs commerciaux.
                </p>
                <p>
                    Prêt à commencer ? Plongez dans la documentation et lancez votre boutique en ligne dès aujourd'hui !
                </p>
            </div>

            {/*<div className="row mb-50">*/}
            {/*    <div className="offset-lg-3 col-lg-6">*/}
            {/*        <iframe height="380" style={{width: "100%"}} src="https://www.youtube.com/embed/EXYj9wecau4"*/}
            {/*                title="Werenoi (ft. Damso) - Pyramide (Clip Officiel)" frameBorder="0"*/}
            {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*                allowFullScreen></iframe>*/}
            {/*    </div>*/}
            {/*</div>*/}



        </>
    );
}

export default Started;