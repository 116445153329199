import React from 'react';
import IconShoppingCart from "../../../../../components/Icons/IconShoppingCart";
import IconEye from "../../../../../components/Icons/IconEye";
import IconHeart from "../../../../../components/Icons/IconHeart";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {CalculateDiscount, numberWithCommas} from "../../../../../helpers/utils";
import {secondCalculDicount} from "../../../../../themes/utils/utils";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";
import {CustomerAddCart, CustomerDecrease, CustomerIncrease} from "../../../../../store/Subdomain/CustomerCart/action";
import moment from "moment";
import {addWishItem} from "../../../../../store/Subdomain/Wishlist/action";

const EmeraudeCard = ({t,item,setItem,server,setOpen,color,CustomerAddCart,addWishItem}) => {


    const addWishlist = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
        const data ={
            id:item.id,
            category : item.category,
            picture : server+JSON.parse(item.pictures)[0],
            name:item.title,
            discount : item.discount,
            price:item.price,
            hash_key:item.hash_key
        }

        addWishItem(data)

        toast("Notification",{
            description: t('AddToWishlist') +"\n"+ moment(Date.now()).fromNow(),
            icon : <i className="ri-check-double-fill"></i>
        });
    }
else{
        toast.error("Notification",{
            description: t('RuptureStockSimple'),
            icon : <i className="ri-close-fill"></i>
        })
    }

    }


    const addToCart = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
            const data = {
                id:item.id,
                qty : 1,
                category : item.category,
                picture : server+JSON.parse(item.pictures)[0],
                name:item.title,
                price: CalculateDiscount(item?.price,item?.discount)
            }
            CustomerAddCart(data)

            toast("Notification",{
                description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
                icon : <i className="ri-check-double-fill"></i>
            })
        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }
    }

    return (
        <div className="tp-category-item-4 p-relative z-index-1 fix  text-center" style={{width: "100%", marginBottom: "25px",border : "1px solid #ccc",borderRadius : "5px"}}>
            <div className="tp-category-thumb-4 include-bg" style={{background : `url('${server+JSON.parse(item.pictures)[0]}')`,backgroundPosition : "center",backgroundSize : "contain",backgroundRepeat : "no-repeat"}}></div>

            <div className="tp-product-action-3 tp-product-action-4 tp-product-action-blackStyle tp-product-action-brownStyle">
                <div className="tp-product-action-item-3 d-flex flex-column">
                    <button type="button" className="tp-product-action-btn-3 tp-product-add-cart-btn"
                            onClick={event => addToCart(item)}
                    >
                        <IconShoppingCart  />
                        <span className="tp-product-tooltip">{t('BuyNow')}</span>
                    </button>
                    <button type="button" className="tp-product-action-btn-3 tp-product-quick-view-btn"
                            onClick={event => {setItem(item); setOpen(true)}}
                    >
                        <IconEye />
                        <span className="tp-product-tooltip">{t('QuickView')}</span>
                    </button>
                    <button type="button" onClick={event => addWishlist(item)} className="tp-product-action-btn-3 tp-product-add-to-wishlist-btn">
                        <IconHeart />
                        <span className="tp-product-tooltip">{t('AddToWishlist')}</span>
                    </button>
                </div>
            </div>
            <div className="tp-category-content-4">
                <h3 className="tp-category-title-4">
                    <Link to={"/product/detail/"+item.hash_key}>{item.title}</Link>
                </h3>
                <small className="text-muted">{item.short_description}</small>
                <div className="tp-category-price-wrapper-4">

                    {
                        parseInt(item.discount) > 0 ?
                            <div className="tp-product-price-wrapper">
                                <span className="tp-product-price old-price">{numberWithCommas(item.price)}  Francs CFA</span> <br/>
                                <span className="tp-product-price new-price pl-10">{numberWithCommas(secondCalculDicount(parseInt(item.price),parseInt(item.discount)))} Francs CFA</span>
                            </div>
                            :

                            <div className="tp-product-price-wrapper">
                                <span className="tp-product-price new-price">{numberWithCommas(item.price)}  Francs CFA</span>
                            </div>
                    }
                    <div className="tp-category-add-to-cart">
                        <button className="tp-category-add-to-cart-4" style={{color : color,border : "1px solid", borderColor : color}} >
                            {t('BuyNow')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    customCart : state.customCart
});

export default compose(withRouter, connect(mapStateToProps,{CustomerAddCart,CustomerIncrease,CustomerDecrease,addWishItem})) (withTranslation()(EmeraudeCard))

