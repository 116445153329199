import React, {useEffect, useRef, useState} from 'react';
import {CLIENT} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import {flatData} from "../../utils/utils";

const Social3 = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {


    const [items, setItems] = useState([]);


    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const social3Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :social3Ref?.current?.offsetTop, left : social3Ref?.current?.offsetLeft, width : social3Ref?.current?.offsetWidth, height : social3Ref?.current?.offsetHeight,nodeName : social3Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);



    return (

            <div className="tp-instagram-area pt-65 pb-15"
                 style={customStyle}
                 ref={social3Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
            >
                <div className="container">
                    <div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {
                            items?.length > 0 ?
                                <>
                                    {items?.map((item,key) =>
                                         <div className="col" key={key}>
                            <div className="tp-instagram-item p-relative z-index-1 fix mb-30 w-img">
                                <img src={flatData(item,"image")} alt=""/>
                                    <div className="tp-instagram-icon">
                                        <a href={flatData(item,"link")} target="_blank" className="popup-image">
                                            {flatData(item,"social") === "Instagram" &&  <i className="fa-brands fa-instagram"></i>}
                                            {flatData(item,"social") === "X (twitter)" &&  <i className="fa-brands fa-twitter"></i>}
                                            {flatData(item,"social") === "Facebook" &&  <i className="fa-brands fa-facebook"></i>}
                                            {flatData(item,"social") === "Dribbble" &&  <i className="fa-brands fa-dribbble"></i>}
                                            {flatData(item,"social") === "Tiktok" &&  <i className="fa-brands fa-tiktok"></i>}
                                        </a>
                                    </div>
                            </div>
                        </div>
                                    )}
                                </>

                                : ""

                        }
                    </div>
                </div>
            </div>
    )
}

export default withTranslation()(Social3)
