import {CLIENT, sha1_random} from '../../helpers/utils'
export const sectionsConfig = [
    {
        category: 'header',
        name: 'Header',
        blocks: [
            {
                id: 'c2fY5v7',
                picture: CLIENT + '/imgs/header/header1.jpg',
                name: 'Header1',
                component: 'header1',
                contentEditable: [
                    {
                        id: '0Zg5baE',
                        step : 1,
                        name: 'topbarColor',
                        type: 'color',
                        value: "#115061FF",
                    },

                    {
                        id: '5ObkpFf',
                        step : 1,
                        name: 'topbarText',
                        type: 'text',
                        value: 'Livraison gratuite pour les commandes de plus de 50.000 Francs FCFA'
                    },
                    {
                        id: 'mkz569q',
                        step : 1,
                        name: 'hotline',
                        type: 'text',
                        value: '+(228) 90 00 00 00'
                    },
                ],
            },
            {
                id: 'AQZZvgp',
                picture: CLIENT + '/imgs/header/header2.jpg',
                name: 'Header2',
                component: 'header2',
                contentEditable: [
                    {
                        id: 'N5bDNQt',
                        step : 1,
                        name: 'hotline',
                        type: 'text',
                        value: '(228) 90 00 00 00'
                    },
                ],

            },
            {
                id: 'JtZmCA6',
                picture: CLIENT + '/imgs/header/header3.jpg',
                name: 'Header3',
                component: 'header3',
                contentEditable: [
                    {
                        id: 'UJNoP58',
                        step : 1,
                        name: 'bannerColor',
                        type: 'color',
                        value: "#678E61",
                    },
                ],
            },
            {
                id: 'vvQ45sq',
                picture: CLIENT+"/imgs/header/header4.jpg",
                name: 'Header + Banner',
                component: 'header4',
                contentEditable: [
                    {
                        id: 'vvvQtab',
                        step : 1,
                        name: 'data',
                        type: 'dynamicArray',
                        data : [
                            [
                                {
                                    id: '07v7c30',
                                    step : 1,
                                    name: 'image',
                                    type: 'image',
                                    value: CLIENT+'/builder/slider/4/slider-1.png'
                                },{
                                id: '10233cd',
                                step : 1,
                                name: 'smallText',
                                type: 'text',
                                value: 'The original'
                            },
                                {
                                    id: '746029q',
                                    step : 1,
                                    name: 'bigText',
                                    type: 'text',
                                    value: 'Shine bright'
                                },

                                {
                                    id: '1036qbg',
                                    step : 1,
                                    name: 'showLink',
                                    type: 'checkbox',
                                    value: true

                                },
                                {
                                    id: 'vnbpedb',
                                    step : 1,
                                    name: 'link',
                                    type: 'text',
                                    value: "/shop/products"
                                },
                                {
                                    id: 'COm03h2',
                                    step : 1,
                                    name: 'linkText',
                                    type: 'text',
                                    value: "Discover Now"
                                },{
                                id: 'ylbjdmz4',
                                step : 1,
                                name: 'bgColor',
                                type: 'color',
                                value: "#DC3545"
                            }
                            ]
                        ]
                    },
                ],
            },

        ],
    },

    {
        category: 'slider',
        name: 'Sliders',
        blocks: [
            {
                id: '64Qav7r',
                picture: CLIENT+"/imgs/hero/hero1.jpg",
                name: 'Slider 1',
                component: 'hero1',
                contentEditable: [
                    {
                        id: '0Zg5ba5',
                        step : 1,
                        name: 'data',
                        type: 'dynamicArray',
                        data : [
                            [
                                {
                                    id: 'vbnsP04',
                                    step : 1,
                                    name: 'image',
                                    type: 'image',
                                    value: CLIENT+'/builder/slider/slider-img-1.png'
                                },{
                                id: 'as597fr',
                                step : 1,
                                name: 'smallText',
                                type: 'text',
                                value: 'My small Text'
                            },
                                {
                                    id: '03sxwok',
                                    step : 1,
                                    name: 'bigText',
                                    type: 'text',
                                    value: 'My big Text'
                                },
                                {
                                    id: '7932qsv',
                                    step : 1,
                                    name: 'subText',
                                    type: 'text',
                                    value: 'My sub Text'
                                },
                                {
                                    id: '46dxcoz',
                                    step : 1,
                                    name: 'showLink',
                                    type: 'checkbox',
                                    value: true

                                },
                                {
                                    id: 'XWO4630',
                                    step : 1,
                                    name: 'link',
                                    type: 'text',
                                    value: "/shop/products"
                                },
                                {
                                    id: 'Y1AM3dc',
                                    step : 1,
                                    name: 'linkText',
                                    type: 'text',
                                    value: "Shop Now"
                                },{
                                id: 'qwlo46q',
                                step : 1,
                                name: 'bgColor',
                                type: 'color',
                                value: "#115061"
                            }
                            ]
                        ]
                    },
                ],
            },
            {
                id: 'vvQ45sw',
                picture: CLIENT+"/imgs/hero/hero2.jpg",
                name: 'Slider 2',
                component: 'hero2',
                contentEditable: [
                    {
                        id: 'vvvQtac',
                        step : 1,
                        name: 'data',
                        type: 'dynamicArray',
                        data : [
                            [
                                {
                                    id: '47v7c30',
                                    step : 1,
                                    name: 'image',
                                    type: 'image',
                                    value: CLIENT+'/builder/slider/2/slider-2.png'
                                },{
                                id: '10239cd',
                                step : 1,
                                name: 'smallText',
                                type: 'text',
                                value: 'New Arrivals 2024'
                            },
                                {
                                    id: '746329q',
                                    step : 1,
                                    name: 'bigText',
                                    type: 'text',
                                    value: 'The Clothing Collection'
                                },

                                {
                                    id: '1036qpg',
                                    step : 1,
                                    name: 'showLink',
                                    type: 'checkbox',
                                    value: true

                                },
                                {
                                    id: 'vnbped4',
                                    step : 1,
                                    name: 'link',
                                    type: 'text',
                                    value: "/shop/products"
                                },
                                {
                                    id: 'COm03hb',
                                    step : 1,
                                    name: 'linkText',
                                    type: 'text',
                                    value: "Shop Collection"
                                },{
                                id: 'ylbjdmp4',
                                step : 1,
                                name: 'bgColor',
                                type: 'color',
                                value: "#EFF0ED"
                            }
                            ]
                        ]
                    },
                ],
            },
        ],
    },

    {
        category: 'free_block',
        name: 'FreeBlock',
        blocks: [
            {
                id: 'free__block__001',
                picture: CLIENT + "/imgs/free/collection.jpg",
                name: 'Collection',
                component: 'collection',
                contentEditable: [
                    {
                        id: 'free__block__001__001',
                        step : 1,
                        name: 'img1',
                        type: 'image',
                        value: CLIENT+"/builder/about/about-1.jpg"
                    },
                    {
                        id: 'free__block__001__003',
                        step : 1,
                        name: 'smallTitle',
                        type: 'text',
                        value: "Unity Collection"
                    },

                    {
                        id: 'free__block__001__004',
                        step : 1,
                        name: 'title',
                        type: 'text',
                        value: "Shop our limited Edition Collaborations"
                    },

                    {
                        id: 'free__block__001__005',
                        step : 1,
                        name: 'text',
                        type: 'textarea',
                        value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel mi quam. Fusce vehicula vitae mauris sit amet tempor. Donec consectetur lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    },

                    {
                        id: 'free__block__001__006',
                        step : 1,
                        name: 'text_btn',
                        type: 'text',
                        value: "Contact Us"
                    },

                    {
                        id: 'free__block__001__007',
                        step : 1,
                        name: 'btn_link',
                        type: 'text',
                        value: "/contact-us"
                    },

                ]

            },
            {
                id:  sha1_random(7),
                picture: CLIENT + "/imgs/free/aboutuscollection.jpg",
                name: 'CardBlock',
                component: 'cardblock',
                contentEditable: [
                    {
                        id: 'free__block__002__001',
                        step : 1,
                        name: 'img',
                        type: 'image',
                        value: CLIENT+"/builder/placeholder__lome.jpg"
                    },

                    {
                        id: 'free__block__002__003',
                        step : 1,
                        name: 'title',
                        type: 'text',
                        value: "Mon super titre"
                    },

                    {
                        id: 'free__block__002__004',
                        step : 1,
                        name: 'text',
                        type: 'textarea',
                        value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel mi quam. Fusce vehicula vitae mauris sit amet tempor. Donec consectetur lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    },

                    {
                        id: 'free__block__002__005',
                        step : 1,
                        name: 'leftPosition',
                        type: 'checkbox',
                        value: true
                    },

                ]

            }
        ]
    },

    {
        category: 'breadcrumb',
        name: 'Breadcrumbs',
        blocks: [
            {
                id: 'breadcru',
                picture: CLIENT+"/imgs/breadcrumbs/breadcrumbs.jpg" ,
                name: 'BreadCrumbs',
                component: 'breadcrumbs',
                contentEditable: [ {
                    id: 'cvpa120J',
                    step : 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data : [
                        [

                            {
                                id: 'qsdqsdqslkjkfv',
                                step : 1,
                                name: 'title',
                                type: 'text',
                                value: 'Home'
                            },

                            {
                                id: 'wxpgmza',
                                step : 1,
                                name: 'asLink',
                                type: 'checkbox',
                                value: false
                            },

                            {
                                id: 'lQDMLed',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://kiiako.io/'
                            }

                        ],

                    ]
                }],
            },
        ],
    },

    {
        category: 'banners',
        name: 'Banners',
        blocks: [
            {
                id: '5a2q6D2c',
                picture: CLIENT+"/imgs/banners/banner1.jpg",
                name: 'Disponibility',
                component: 'banner1',
                contentEditable: [
                    {
                        id: 'DDDQtac',
                        step : 1,
                        name: 'data',
                        type: 'dynamicArray',
                        data : [
                            [
                                {
                                    id: '77vnc30',
                                    step : 1,
                                    name: 'icon',
                                    type: 'icon',
                                    value: "ri-truck-line"
                                },
                                {
                                    id: '77vnc00',
                                    step : 1,
                                    name: 'iconColor',
                                    type: 'color',
                                    value: "#ff0942"
                                },
                                {
                                id: '402z9cd',
                                step : 1,
                                name: 'title',
                                type: 'text',
                                value: 'Free Delivery'
                            },
                                {
                                    id: '402z9hd',
                                    step : 1,
                                    name: 'text',
                                    type: 'text',
                                    value: 'Orders from all item'
                                },

                            ]
                        ]
                    },
                ],
            },
            {
                id: '0QcX5s9',
                picture: CLIENT+"/imgs/banners/banner2.jpg",
                name: 'Discount',
                component: 'banner2',
                contentEditable: [
                    {
                        id: 'disv407',
                        step: 1,
                        name: 'title',
                        type: 'title',
                        value: "Product1"
                    },

                    {
                        id: 'vokling',
                        step: 1,
                        name: 'image',
                        type: 'image',
                        value: CLIENT+'/builder/product/banner/product-banner-1.jpg'
                    },
                    {
                        id: 'disv403',
                        step: 1,
                        name: 'discount',
                        type: 'text',
                        value: "Sale 20% off all store"
                    },

                    {
                        id: 'disv404',
                        step: 1,
                        name: 'product1',
                        type: 'text',
                        value: "Smartphone BLU G91 Pro 2022"
                    },
                    {
                        id: 'disv305',
                        step: 1,
                        name: 'link',
                        type: 'text',
                        value: '/shop/products'
                    },

                    {
                        id: 'disv405',
                        step: 1,
                        name: 'linkText',
                        type: 'text',
                        value: 'Shop now'
                    },


                    {
                        id: 'disv407',
                        step: 1,
                        name: 'title',
                        type: 'title',
                        value: "Product2"
                    },

                    {
                        id: 'vokling1',
                        step: 1,
                        name: 'image2',
                        type: 'image',
                        value: CLIENT+'/builder/product/banner/product-banner-2.jpg'
                    },
                    {
                        id: 'disv408',
                        step: 1,
                        name: 'discount2',
                        type: 'text',
                        value: "Sale 35% off"
                    },

                    {
                        id: 'disv409',
                        step: 1,
                        name: 'product2',
                        type: 'text',
                        value: "HyperX Cloud II Wireless"
                    },

                    {
                        id: 'disv303',
                        step: 1,
                        name: 'link2',
                        type: 'text',
                        value: '/shop/products'
                    },

                    {
                        id: 'disv410',
                        step: 1,
                        name: 'linkText2',
                        type: 'text',
                        value: 'Shop now'
                    },

                ],
            },
            {
                id: 'QCL5569',
                picture: CLIENT+"/imgs/banners/banner3.jpg",
                name: 'Promo',
                component: 'banner3',
                contentEditable:[
                    {
                        id: 'VYRKN0v',
                        step: 1,
                        name: 'image',
                        type: 'image',
                        value: CLIENT+'/builder/banner/banner-slider-1.png'
                    },
                    {
                        id: '75kkSRi',
                        step: 1,
                        name: 'smallText',
                        type: 'text',
                        value: 'Tablet Collection 2023'
                    },
                    {
                        id: '3Lxe0DF',
                        step: 1,
                        name: 'bigText',
                        type: 'text',
                        value: 'Samsung Galaxy Tab S6, Wifi Tablet'},
                    {
                        id: '8Bag7Ih',
                        step: 1,
                        name: 'price',
                        type: 'number',
                        value: 100000
                    },
                    {
                        id: 'yLclshp',
                        step: 1,
                        name: 'discount',
                        type: 'number',
                        value: 20
                    },
                    {
                        id: 'nZBoS26',
                        step: 1,
                        name: 'linkText',
                        type: 'text',
                        value: 'Shop now'
                    },
                    {
                        id: 'i3wARyJ',
                        step: 1,
                        name: 'background',
                        type: 'color',
                        value: '#821F40'
                    }]

                ,
            },
            {
                id: 'QCLO569',
                picture: CLIENT+"/imgs/banners/banner4.jpg",
                name: 'Author Area',
                component: 'authorArea',
                contentEditable: [
                    {
                        id: 'FD58Aca',
                        step : 1,
                        name: 'partner',
                        type: 'image',
                        value: CLIENT+'/builder/brand/logo_01.png',
                    },
                    {
                        id: 'FD58Acb',
                        step : 1,
                        name: 'authorPic',
                        type: 'image',
                        value: CLIENT+'/builder/users/user-1.jpg',
                    },
                    {
                        id: 'FD58Acc',
                        step : 1,
                        name: 'authorName',
                        type: 'text',
                        value: 'Author',
                    },
                    {
                        id: 'FD58Acd',
                        step : 1,
                        name: 'authorRole',
                        type: 'text',
                        value: 'Author role',
                    },
                    {
                        id: 'FD58Ace',
                        step : 1,
                        name: 'message',
                        type: 'textarea',
                        value: 'We work with top suppliers and manufacturers to ensure that every item we sell meets our high standards for durability, performance, and style.',
                    },
                ],
            },
        ],
    },

    {
        category: 'products',
        name: 'Products',
        blocks: [
            {
                id: '5a2q6D2k',
                picture: CLIENT+"/imgs/products/trendingArrival.jpg",
                name: 'ProductTrending',
                component: 'productTrending',
                contentEditable: [],
            },
            {
                id: '0196dsp',
                picture: CLIENT+"/imgs/products/newarrival.jpg",
                name: 'NewArrival',
                component: 'newArrival',
                contentEditable: [],
                data : [
                    {
                        id:1,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/product-1.jpg",
                        title:"Brown Gown for Women",
                        badge : "trending",
                        discount : 0,
                        price: 5000,
                        hash_key: "",
                        rate: 2
                    },
                    {
                        id:2,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/product-2.jpg",
                        title:"Brown Gown for Women",
                        discount : 0,
                        badge : "trending",
                        price: 5000,
                        hash_key: "",
                        rate : 4
                    },
                    {
                        id:3,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/product-3.jpg",
                        title:"Brown Gown for Women",
                        discount : 0,
                        badge : "hot",
                        price: 5000,
                        hash_key: "",
                        rate : 5
                    },
                    {
                        id:4,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/product-4.jpg",
                        title:"Brown Gown for Women",
                        discount : 0,
                        badge : "trending",
                        price: 5000,
                        hash_key: "",
                        rate : 5
                    },
                ]
            },
            {
                id: '5a2q6D2l',
                picture: CLIENT+"/imgs/products/trending.jpg",
                name: 'Trending ',
                component: 'trending',
                contentEditable: [
                    {
                        id: 'Xxom430',
                        step : 1,
                        name: 'banner',
                        type: 'image',
                        value: CLIENT + '/builder/product/trending/banner/trending-banner.jpg',
                    },
                    {
                        id: 'cpasmw3',
                        step : 1,
                        name: 'bannerText',
                        type: 'text',
                        value: "Short Sleeve Tunic \n Tops Casual Swing",
                    },
                    {
                        id: 'bien7894',
                        step : 1,
                        name: 'bannerBtnText',
                        type: 'text',
                        value: "Explore more",
                    },   {
                        id: 'passpas',
                        step : 1,
                        name: 'bannerLink',
                        type: 'text',
                        value: "/shop/products",
                    },
                ],
                data : [
                    {
                        id:1,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/trending/trending-1.jpg",
                        title:"Brown Gown for Women",
                        discount : 0,
                        badge : "trending",
                        price: 5000,
                        hash_key: "",
                        rate: 2
                    },
                    {
                        id:2,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/trending/trending-2.jpg",
                        title:"Brown Gown for Women",
                        discount : 0,
                        badge : "trending",
                        price: 5000,
                        hash_key: "",
                        rate : 4
                    },
                    {
                        id:3,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/trending/trending-3.jpg",
                        title:"Brown Gown for Women",
                        discount : 0,
                        badge : "hot",
                        price: 5000,
                        hash_key: "",
                        rate : 5
                    },
                ]
            },
            {
                id: '5a2q6D7z',
                picture: CLIENT+"/imgs/products/weekfeatured.jpg",
                name: 'WeekFeatured',
                component: 'weekFeatured',
                contentEditable: [],
                data : [
                    {
                        id:1,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/slider/product-slider-1.jpg",
                        title:"Clothing  Collection 2023",
                        discount : 0,
                        badge : "trending",
                        price: 5000,
                        hash_key: "",
                        rate: 4
                    },
                    {
                        id:2,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/slider/product-slider-2.jpg",
                        title:"Non Slip Athletic  Tennis Walking",
                        discount : 0,
                        badge : "trending",
                        price: 5000,
                        hash_key: "",
                        rate : 4
                    },
                    {
                        id:3,
                        category : "Whitetails Store",
                        picture : CLIENT +"/builder/product/slider/product-slider-3.jpg",
                        title:"Vera Bradley Straw Tote Bag",
                        discount : 0,
                        badge : "hot",
                        price: 5000,
                        hash_key: "",
                        rate : 5
                    },
                ]
            },
        ],
    },


    {
        category: 'category',
        name: 'Category',
        blocks: [
            {
                id: 'wqp56QW',
                picture: CLIENT+"/imgs/categories/category1.jpg" ,
                name: 'Category1',
                component: 'category1',
                contentEditable: [],
            },

            {
                id: 'qqp56QW',
                picture: CLIENT+"/imgs/categories/category2.jpg" ,
                name: 'Category2',
                component: 'category2',
                contentEditable: [],
            },
        ],
    },

    {
        category: 'category',
        name: 'Contact form',
        blocks: [
            {
                id: 'kkkhbni',
                picture: CLIENT+"/imgs/contact/contact.jpg" ,
                name: 'Contact form',
                component: 'contact',
                contentEditable: [
                    {
                        id: 'FD58Acv',
                        step : 1,
                        name: 'phone',
                        type: 'text',
                        value: "+(228) 90 00 00 00)",
                    },
                    {
                        id: 'Cvdf4qq',
                        step : 1,
                        name: 'email',
                        type: 'text',
                        value: "contact@kiiako.io",
                    },
                    {
                        id: 'cvimpsr',
                        step : 1,
                        name: 'address',
                        type: 'textarea',
                        value: "Lomé - Adidogomé sagbado à 500m du marché de logoté",
                    },
                    {
                        id: 'cvimp1r',
                        step : 1,
                        name: 'facebook',
                        type: 'text',
                        value: "",
                    },
                    {
                        id: 'cvimp1e',
                        step : 1,
                        name: 'show_facebook',
                        type: 'checkbox',
                        value: true,
                    },

                    {
                        id: 'cvimp2r',
                        step : 1,
                        name: 'x(Twitter)',
                        type: 'text',
                        value: "",
                    },

                    {
                        id: 'cvimp2e',
                        step : 1,
                        name: 'show_x',
                        type: 'checkbox',
                        value: true,
                    },

                    {
                        id: 'cvimp3r',
                        step : 1,
                        name: 'instagram',
                        type: 'text',
                        value: "",
                    },

                    {
                        id: 'cvimp3e',
                        step : 1,
                        name: 'show_instagram',
                        type: 'checkbox',
                        value: true,
                    },
                    {
                        id: 'cvimp4r',
                        step : 1,
                        name: 'linkedin',
                        type: 'text',
                        value: "",
                    },

                    {
                        id: 'cvimp4e',
                        step : 1,
                        name: 'show_linkedin',
                        type: 'checkbox',
                        value: true,
                    },
                ]
            },
            {
                id: 'mamapG',
                picture: CLIENT+"/imgs/contact/contactmap.jpg" ,
                name: 'Contact map',
                component: 'contactMap',
                contentEditable: [
                    {
                        id: 'typemap',
                        step : 1,
                        name: 'type',
                        type: 'select',
                        value: "roadmap",
                        option : [
                            "roadmap",
                             "satellite",
                             "hybrid",
                             "terrain"
                        ]
                    },
                    {
                        id: 'generat',
                        step : 1,
                        name: 'Latitude',
                        type: 'text',
                        value: "6.1870448",
                    },
                    {
                        id: 'generaq',
                        step : 1,
                        name: 'Longitude',
                        type: 'text',
                        value: "1.129188",
                    },
                    {
                        id: 'zoomers',
                        step : 1,
                        name: 'zoom',
                        type: 'number',
                        value: "15",
                    },

                ],
            },
        ],
    },

    {
        category: 'testimonial',
        name: 'Testimonial',
        blocks: [
            {
                id: 'azertyu',
                picture: CLIENT+"/imgs/testimonial/testimonial.jpg",
                name: 'Testimonial',
                component: 'testimonial',
                contentEditable: [
                    {
                        id: 'busines',
                        step : 1,
                        name: 'data',
                        type: 'dynamicArray',
                        data : [
                            [
                                {
                                    id: 'vbnsP02',
                                    step : 1,
                                    name: 'customerPic',
                                    type: 'image',
                                    value: CLIENT+'/builder/users/user-1.jpg'
                                },
                                {
                                    id: '03sxwov',
                                    step : 1,
                                    name: 'customerRole',
                                    type: 'text',
                                    value: 'Customer role'
                                },
                                {
                                    id: 'as597ft',
                                    step : 1,
                                    name: 'customerName',
                                    type: 'text',
                                    value: 'Customer name'
                                },
                                {
                                    id: '7932qsa',
                                    step : 1,
                                    name: 'message',
                                    type: 'text',
                                    value: 'Customer testimonial message'
                                },
                                {
                                    id: '46dxcop',
                                    step : 1,
                                    name: 'rate',
                                    type: 'number',
                                    value: 4

                                },

                            ]
                        ]
                    },
                ],
            },
            {
                id: 'azertyk',
                picture: CLIENT+"/imgs/testimonial/testimonial2.jpg",
                name: 'Testimonial 2',
                component: 'testimonial2',
                contentEditable: [
                    {
                        id: 'busines',
                        step : 1,
                        name: 'data',
                        type: 'dynamicArray',
                        data : [
                            [
                                {
                                    id: 'vbnsP03',
                                    step : 1,
                                    name: 'customerPic',
                                    type: 'image',
                                    value: CLIENT+'/builder/users/user-1.jpg'
                                },
                                {
                                    id: '03sxwon',
                                    step : 1,
                                    name: 'customerRole',
                                    type: 'text',
                                    value: 'Customer role'
                                },
                                {
                                    id: 'as597fq',
                                    step : 1,
                                    name: 'customerName',
                                    type: 'text',
                                    value: 'Customer name'
                                },
                                {
                                    id: '7932qsz',
                                    step : 1,
                                    name: 'message',
                                    type: 'text',
                                    value: 'Suscipit tellus mauris a diam maecenas. Ut faucibus pulvinar elementum integer enim neque volutpat ac. Auctor urna nunc id cursus. Scelerisque purus semper eget duis at. Pharetra vel turpis nunc eget.'
                                },
                                {
                                    id: '46dxcoa',
                                    step : 1,
                                    name: 'rate',
                                    type: 'number',
                                    value: 4

                                },

                            ]
                        ]
                    },
                ],
            },
            {
                id: 'azertyz',
                picture: CLIENT+"/imgs/testimonial/testimonial3.jpg",
                name: 'Testimonial 3',
                component: 'testimonial3',
                contentEditable: [
                    {
                        id: 'businea',
                        step : 1,
                        name: 'data',
                        type: 'dynamicArray',
                        data : [
                            [
                                {
                                    id: 'vbnsP0w',
                                    step : 1,
                                    name: 'customerPic',
                                    type: 'image',
                                    value: CLIENT+'/builder/users/user-11.jpg'
                                },
                                {
                                    id: '03sxwov',
                                    step : 1,
                                    name: 'customerRole',
                                    type: 'text',
                                    value: 'Customer role'
                                },
                                {
                                    id: 'as597f0',
                                    step : 1,
                                    name: 'customerName',
                                    type: 'text',
                                    value: 'Customer name'
                                },
                                {
                                    id: '7932q9z',
                                    step : 1,
                                    name: 'message',
                                    type: 'text',
                                    value: 'Suscipit tellus mauris a diam maecenas. Ut faucibus pulvinar elementum integer enim neque volutpat ac. Auctor urna nunc id cursus. Scelerisque purus semper eget duis at. Pharetra vel turpis nunc eget.'
                                },
                                {
                                    id: '46oxcoa',
                                    step : 1,
                                    name: 'rate',
                                    type: 'number',
                                    value: 4

                                },

                            ]
                        ]
                    },
                ],
            },
        ],
    },



    {
        category: 'team',
        name: 'Teams',
        blocks: [
            {
                id: 'teamsae',
                picture: CLIENT+"/imgs/teams/teams1.jpg" ,
                name: 'Teams1',
                component: 'teams1',
                contentEditable: [ {
                    id: 'WZp5b2n',
                    step : 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data : [
                        [
                            {
                                id: 'blANlck10',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/teams/user1.jpg'
                            },

                            {
                                id: 'lQMLedà',
                                step : 1,
                                name: 'title',
                                type: 'text',
                                value: 'CEO'
                            },
                            {
                                id: 'lQDMLed',
                                step : 1,
                                name: 'fullname',
                                type: 'text',
                                value: 'John Doe'
                            }

                        ],

                    ]
                }],
            },
            {
                id: 'teamCVN',
                picture: CLIENT+"/imgs/teams/teams2.jpg" ,
                name: 'Teams2',
                component: 'teams2',
                contentEditable: [ {
                    id: 'mZp5b2n',
                    step : 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data : [
                        [
                            {
                                id: 'alAXlck10',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/teams/user1.jpg'
                            },

                            {
                                id: 'lQCLedà',
                                step : 1,
                                name: 'title',
                                type: 'text',
                                value: 'CEO'
                            },
                            {
                                id: 'WQDMLed',
                                step : 1,
                                name: 'fullname',
                                type: 'text',
                                value: 'John Doe'
                            },
                            {
                                id: 'WQDMLed',
                                step : 1,
                                name: 'desc',
                                type: 'textarea',
                                value: 'lorem ipsum dolor sit amet, consectetur'
                            }

                        ],

                    ]
                }],
            },
        ],
    },
    {
        category: 'social',
        name: 'Social sections',
        blocks: [
            {
                id: 'socialA',
                picture: CLIENT+"/imgs/socials/social1.jpg",
                name: 'Social 1',
                component: 'social1',
                contentEditable: [ {
                    id: '0Zg5ba3',
                    step : 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data : [
                        [
                            {
                                id: 'block10',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/4/instagram-1.jpg'
                            },

                            {
                                id: 'sotype1',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },

                            {
                                id: 'linked1',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block11',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/4/instagram-2.jpg'
                            },
                            {
                                id: 'sotype2',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked2',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block12',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/4/instagram-3.jpg'
                            },
                            {
                                id: 'sotype3',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },

                            {
                                id: 'linked3',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block13',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/4/instagram-4.jpg'
                            },
                            {
                                id: 'sotype4',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked4',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block14',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/4/instagram-5.jpg'
                            },
                            {
                                id: 'sotype5',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked5',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block15',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/4/instagram-6.jpg'
                            },

                            {
                                id: 'sotype6',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },

                            {
                                id: 'linked6',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                    ]
                }],
            },
            {
                id: 'socialB',
                picture: CLIENT+"/imgs/socials/social2.jpg",
                name: 'Social 2',
                component: 'social2',
                contentEditable: [ {
                    id: '0Zg5bai',
                    step : 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data : [
                        [
                            {
                                id: 'block10',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/2/insta-1.jpg'
                            },

                            {
                                id: 'sotype1',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },

                            {
                                id: 'linked1',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block11',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/2/insta-2.jpg'
                            },
                            {
                                id: 'sotype2',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked2',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block12',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/2/insta-3.jpg'
                            },
                            {
                                id: 'sotype3',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },

                            {
                                id: 'linked3',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block13',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/2/insta-4.jpg'
                            },
                            {
                                id: 'sotype4',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked4',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],


                    ]
                }],
            },
            {
                id: 'socialC',
                picture: CLIENT+"/imgs/socials/social3.jpg",
                name: 'Social 3',
                component: 'social3',
                contentEditable: [ {
                    id: '0Zg5baP',
                    step : 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data : [
                        [
                            {
                                id: 'block10',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/instagram-1.jpg'
                            },

                            {
                                id: 'sotype1',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },

                            {
                                id: 'linked1',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block11',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/instagram-2.jpg'
                            },
                            {
                                id: 'sotype2',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked2',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block12',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/instagram-3.jpg'
                            },
                            {
                                id: 'sotype3',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },

                            {
                                id: 'linked3',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block13',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/instagram-4.jpg'
                            },
                            {
                                id: 'sotype4',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked4',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                        [
                            {
                                id: 'block14',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/instagram/instagram-5.jpg'
                            },
                            {
                                id: 'sotype5',
                                step : 1,
                                name: 'social',
                                type: 'select',
                                value: "Instagram",
                                option : [
                                    "Instagram","X (twitter)","Facebook","Dribbble","Tiktok"
                                ]
                            },
                            {
                                id: 'linked5',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://instagram.com/'
                            }

                        ],
                    ]
                }],
            },
        ],
    },
    {
        category: 'partners',
        name: 'Partenaires',
        blocks: [
            {
                id: 'partner',
                picture: CLIENT+"/imgs/partners/partners.jpg" ,
                name: 'Partenaire',
                component: 'partners',
                contentEditable: [ {
                    id: '0Zg5b23',
                    step : 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data : [
                        [
                            {
                                id: 'block10',
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value: CLIENT+'/builder/partners/logo_02.png'
                            },


                            {
                                id: 'linkedà',
                                step : 1,
                                name: 'link',
                                type: 'text',
                                value: 'https://kiiako.io/'
                            }

                        ],

                    ]
                }],
            },
        ],
    },

    {
        category: 'footer',
        name: 'Footer',
        blocks: [
            {
                id: '1wSNOHR',
                picture: CLIENT + '/imgs/footer/footer.jpg',
                name: 'Footer',
                component: 'footer',
                contentEditable: [
                    {
                        id: '7QG9dT1',
                        step : 1,
                        name: 'background',
                        type: 'color',
                        value: "#F4F7F9",
                    },
                    {
                        id: '64fdsg5d4g',
                        step : 1,
                        name: 'facebook',
                        type: 'text',
                        value: "",
                    },
                    {
                        id: 'wgrgtSlgff',
                        step : 1,
                        name: 'show_facebook',
                        type: 'checkbox',
                        value: true,
                    },

                    {
                        id: '654sdfgsd',
                        step : 1,
                        name: 'x(Twitter)',
                        type: 'text',
                        value: "",
                    },

                    {
                        id: 'n,lydsgkj5',
                        step : 1,
                        name: 'show_x',
                        type: 'checkbox',
                        value: true,
                    },

                    {
                        id: 'qscghyuoipkjhg',
                        step : 1,
                        name: 'instagram',
                        type: 'text',
                        value: "",
                    },

                    {
                        id: 'wxvnbjnuikoihf102',
                        step : 1,
                        name: 'show_instagram',
                        type: 'checkbox',
                        value: true,
                    },
                    {
                        id: 'ffgfgc5456',
                        step : 1,
                        name: 'linkedin',
                        type: 'text',
                        value: "",
                    },

                    {
                        id: 'aqsgpmjgfbfd852',
                        step : 1,
                        name: 'show_linkedin',
                        type: 'checkbox',
                        value: true,
                    },

                ],
            },
        ],
    },
]

export const componentConfig = [
    {
        category: 'base',
        name: 'Base',
        block : [
            {
                id: sha1_random(7),
                picture: CLIENT+"/builder/icons/text_input.svg",
                name: 'Paragraph',
                component: 'paragraph',
                contentEditable: [{
                    id: '0Zg5b23',
                    step: 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data: [
                       [
                           {
                               id: sha1_random(7),
                               step : 1,
                               name: 'text',
                               type: 'textarea',
                               value: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dicta eligendi ipsa ipsam minima nulla officiis porro quasi qui similique! Architecto assumenda minima nihil quas quo. Cupiditate ipsam quidem vel?"
                           },
                       ]

                    ]
                }]
            },
            {
                id: '685FDFQS6F',
                picture: CLIENT+"/builder/icons/heading.svg",
                name: 'Heading',
                component: 'heading',
                contentEditable: [
                    {
                        id: "45AWMLFNC54",
                        step : 1,
                        name: 'type',
                        type: 'select',
                        value: "Heading 1",
                        option : [
                            "Heading 1",
                            "Heading 2",
                            "Heading 3",
                            "Heading 4",
                            "Heading 5",
                            "Heading 6"
                        ]
                    },

                    {
                        id: "652301zdnmwnht",
                        step : 1,
                        name: 'position',
                        type: 'select',
                        value: "left",
                        option : [
                            "left",
                            "right",
                            "center",
                        ]
                    },
                    {
                        id: "645cvgf698s",
                        step : 1,
                        name: 'text',
                        type: 'text',
                        value: "Heading"
                    },
                ]
            },
            {
                id: 'hrascmpbnfe',
                picture: CLIENT+"/builder/icons/hr.svg",
                name: 'Horizontal Rule',
                component: 'hr',
                contentEditable: []
            },
            {
                id: 'hrascmpbnfe',
                picture: CLIENT+"/builder/icons/file.svg",
                name: 'Iframe',
                component: 'iframe',
                contentEditable: [
                    {
                        id: "XLVKDOOZDIZODOZDZ",
                        step : 1,
                        name: 'link',
                        type: 'text',
                        value: "https://kiiako.io",
                    },
                    {
                        id: "cccvvlflffllclclsdd",
                        step : 1,
                        name: 'width',
                        type: 'text',
                        value: "640",
                    },
                    {
                        id: "NVLLDFMSDMSDLMS",
                        step : 1,
                        name: 'height',
                        type: 'text',
                        value: "480",
                    },

                ]
            },
            {
                id: 'kiiaaksskskcdssq',
                picture: CLIENT+"/builder/icons/image.svg",
                name: 'Image',
                component: 'images',
                contentEditable: [
                    {
                        id: "wwwxxxxxxxxxxx",
                        step : 1,
                        name: 'image',
                        type: 'image',
                        value: CLIENT+"/builder/images.jpg",
                    },
                    {
                        id: "aaaaaaaannnnnn",
                        step : 1,
                        name: 'width',
                        type: 'text',
                        value: "640",
                    },
                    {
                        id: "bbbbbbnbbnbnbnbnb",
                        step : 1,
                        name: 'height',
                        type: 'text',
                        value: "480",
                    },
                    {
                        id: "qqqqqksksksksk",
                        step : 1,
                        name: 'align',
                        type: 'select',
                        value: "left",
                        option : [
                            "left",
                            "center",
                            "right",
                        ]
                    },

                ]
            },
        ]
    },

    {
        category: "Elements",
        name : "elements",
        block: [
            {
                id: 'qshjlkmpjgfdfgdfgdf',
                picture: CLIENT+"/builder/icons/accordion.svg",
                name: 'Accordion',
                component: 'accordion',

                contentEditable: [{
                    id: 'xccvbcbcbtrasssd',
                    step: 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data: [
                        [
                            {
                                id: "elements_001_001",
                                step : 1,
                                name: 'title',
                                type: 'text',
                                value: "Accordion title"
                            },
                            {
                                id: "elements_001_002",
                                step : 1,
                                name: 'content',
                                type: 'textarea',
                                value: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dicta eligendi ipsa ipsam minima nulla officiis porro quasi qui similique! Architecto assumenda minima nihil quas quo. Cupiditate ipsam quidem vel?"
                            },
                        ]

                    ]
                }]
            },

            {
                id: 'galery__001',
                picture: CLIENT+"/builder/icons/images.svg",
                name: 'Galerie',
                component: 'galerie',
                contentEditable: [{
                    id: 'galery__001_01',
                    step: 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data: [
                        [
                            {
                                id: "galery__001__001",
                                step : 1,
                                name: 'src',
                                type: 'image',
                                value: CLIENT+"/builder/images.jpg"
                            },
                            {
                                id: "galery__001__002",
                                step : 1,
                                name: 'width',
                                type: 'text',
                                value: "3"
                            },

                            {
                                id: "galery__001__003",
                                step : 1,
                                name: 'height',
                                type: 'text',
                                value: "2"
                            },
                        ]

                    ]
                }]
            },

            {
                id: 'slider__001',
                picture: CLIENT+"/builder/icons/slider.svg",
                name: 'Slider',
                component: 'slider',
                contentEditable: [{
                    id: 'slider__001_',
                    step: 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data: [
                        [
                            {
                                id: "slider__001__001",
                                step : 1,
                                name: 'image',
                                type: 'image',
                                value : CLIENT+"/builder/c_images.png"
                            },
                        ]

                    ]
                }]
            },

            {
                id: 'tabs__001',
                picture: CLIENT+"/builder/icons/tabs.svg",
                name: 'Tabs',
                component: 'tabs',
                contentEditable: [{
                    id: 'tabs__001_',
                    step: 1,
                    name: 'data',
                    type: 'dynamicArray',
                    data: [
                        [
                            {
                                id: "tabs__001__001",
                                step : 1,
                                name: 'title',
                                type: 'text',
                                value: "Tabs title"
                            },
                            {
                                id: "tabs_001_002",
                                step : 1,
                                name: 'content',
                                type: 'textarea',
                                value: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dicta eligendi ipsa ipsam minima nulla officiis porro quasi qui similique! Architecto assumenda minima nihil quas quo. Cupiditate ipsam quidem vel?"
                            },
                        ]

                    ]
                }]
            },
        ]
    },

    {
        category: "Widgets",
        name : "widget",
        block: [
            {
                id: 'widget__001',
                picture: CLIENT+"/builder/icons/paypal.svg",
                name: 'PayPal',
                component: 'paypal',

                contentEditable: [

                        {
                            id: "widget__001_001_001",
                            step : 1,
                            name: 'text_btn',
                            type: 'text',
                            value: "Donate now"
                        },
                    {
                        id: "widget__001_001_002",
                        step : 1,
                        name: 'color_btn',
                        type: 'color',
                        value: "#ffc107"
                    },
                        {
                            id: "widget__001_001_003",
                            step : 1,
                            name: 'paypalId',
                            type: 'text',
                            value: "QAPNAJZ6N5G9N"
                        }, {
                            id: "widget__001_001_004",
                            step : 1,
                            name: 'content',
                            type: 'textarea',
                            value: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam deleniti dolor exercitationem, ipsum iste nam nulla ut voluptatem. Accusantium assumenda consequuntur deserunt dicta ipsum nam nobis porro quibusdam. Modi, possimus."
                        },

                ]
            },
        ]
    }

]

export const customContent = [
    {
        key : "display",
        value : "block",
        unit : "",
    },
    {
        key : "position",
        value : "static",
        unit : "",
    },
    {
        key : "top",
        value : "",
        unit : "px",
    },
    {
        key : "bottom",
        value : "",
        unit : "px",
    },
    {
        key : "left",
        value : "",
        unit : "px",
    },
    {
        key : "right",
        value : "",
        unit : "px",
    },
    {
        key : "float",
        value : "",
        unit : "",
    },
    {
        key : "opacity",
        value : "1",
        unit : "",
    },
    {
        key : "backgroundColor",
        value : "",
        unit : "",
    },
    {
        key : "color",
        value : "",
        unit : "",
    },
    {
        key : "fontSize",
        value : "",
        unit : "px",
    },
    {
        key : "fontWeight",
        value : "",
        unit : "",
    },
    {
        key : "fontFamily",
        value : "",
        unit : "",
    },
    {
        key : "textAlign",
        value : "",
        unit : "",
    },
    {
        key : "lineHeight",
        value : "",
        unit : "px",
    },
    {
        key : "letterSpacing",
        value : "",
        unit : "px",
    },
    {
        key : "textDecoration",
        value : "",
        unit : "",
    },
    {
        key : "textDecorationColor",
        value : "",
        unit : "",
    },
    {
        key : "textDecorationStyle",
        value : "",
        unit : "",
    },
    {
        key : "width",
        value : "",
        unit : "px",
    },
    {
        key : "height",
        value : "",
        unit : "px",
    },
    {
        key : "minHeight",
        value : "",
        unit : "px",
    },
    {
        key : "minWidth",
        value : "",
        unit : "px",
    },
    {
        key : "maxHeight",
        value : "",
        unit : "px",
    },
    {
        key : "maxWidth",
        value : "",
        unit : "px",
    },
    {
        key : "marginTop",
        value : "",
        unit : "px",
    },
    {
        key : "marginLeft",
        value : "",
        unit : "px",
    },
    {
        key : "marginRight",
        value : "",
        unit : "px",
    },
    {
        key : "marginBottom",
        value : "",
        unit : "px",
    },
    {
        key : "paddingTop",
        value : "",
        unit : "px",
    },
    {
        key : "paddingLeft",
        value : "",
        unit : "px",
    },
    {
        key : "paddingRight",
        value : "",
        unit : "px",
    },
    {
        key : "paddingBottom",
        value : "",
        unit : "px",
    },
    {
        key : "borderStyle",
        value : "",
        unit : "",
    },
    {
        key : "borderWidth",
        value : "",
        unit : "px",
    },
    {
        key : "borderColor",
        value : "",
        unit : "",
    },
    {
        key : "borderBottomRightRadius",
        value : "",
        unit : "px",
    },
    {
        key : "borderBottomLeftRadius",
        value : "",
        unit : "px",
    },
    {
        key : "borderTopLeftRadius",
        value : "",
        unit : "px",
    },
    {
        key : "borderTopRightRadius",
        value : "",
        unit : "px",
    }
]

export const remixIcons = [
    "arrow-go-back-fill",
    "arrow-go-back-line",
    "arrow-go-forward-fill",
    "arrow-go-forward-line",
    "arrow-right-down-fill",
    "arrow-right-down-line",
    "arrow-right-fill",
    "arrow-right-line",
    "arrow-right-up-fill",
    "arrow-right-up-line",
    "arrow-up-down-fill",
    "arrow-up-down-line",
    "arrow-up-fill",
    "arrow-up-line",
    "drag-move-2-fill",
    "drag-move-2-line",
    "drag-move-fill",
    "drag-move-line",
    "ancient-gate-fill",
    "ancient-gate-line",
    "ancient-pavilion-fill",
    "ancient-pavilion-line",
    "bank-fill",
    "bank-line",
    "building-2-fill",
    "building-2-line",
    "building-3-fill",
    "building-3-line",
    "building-4-fill",
    "building-4-line",
    "building-fill",
    "building-line",
    "community-fill",
    "community-line",
    "government-fill",
    "government-line",
    "home-2-fill",
    "home-2-line",
    "home-3-fill",
    "home-3-line",
    "home-4-fill",
    "home-4-line",
    "home-5-fill",
    "home-5-line",
    "home-6-fill",
    "home-6-line",
    "home-7-fill",
    "home-7-line",
    "home-8-fill",
    "home-8-line",
    "home-fill",
    "home-gear-fill",
    "home-gear-line",
    "home-heart-fill",
    "home-heart-line",
    "home-line",
    "home-smile-2-fill",
    "home-smile-2-line",
    "home-smile-fill",
    "home-smile-line",
    "home-wifi-fill",
    "home-wifi-line",
    "hospital-fill",
    "hospital-line",
    "hotel-fill",
    "hotel-line",
    "store-2-fill",
    "store-2-line",
    "store-3-fill",
    "store-3-line",
    "store-fill",
    "store-line",
    "advertisement-fill",
    "advertisement-line",
    "archive-drawer-fill",
    "archive-drawer-line",
    "archive-fill",
    "archive-line",
    "at-fill",
    "at-line",
    "attachment-fill",
    "attachment-line",
    "award-fill",
    "award-line",
    "bar-chart-2-fill",
    "bar-chart-2-line",
    "bar-chart-box-fill",
    "bar-chart-box-line",
    "bar-chart-fill",
    "bar-chart-grouped-fill",
    "bar-chart-grouped-line",
    "bar-chart-horizontal-fill",
    "bar-chart-horizontal-line",
    "bar-chart-line",
    "bookmark-2-fill",
    "bookmark-2-line",
    "bookmark-3-fill",
    "bookmark-3-line",
    "bookmark-fill",
    "bookmark-line",
    "briefcase-2-fill",
    "briefcase-2-line",
    "briefcase-3-fill",
    "briefcase-3-line",
    "briefcase-4-fill",
    "briefcase-4-line",
    "briefcase-5-fill",
    "briefcase-5-line",
    "briefcase-fill",
    "briefcase-line",
    "bubble-chart-fill",
    "bubble-chart-line",
    "calculator-fill",
    "calculator-line",
    "calendar-2-fill",
    "calendar-2-line",
    "calendar-check-fill",
    "calendar-check-line",
    "calendar-event-fill",
    "calendar-event-line",
    "calendar-fill",
    "calendar-line",
    "calendar-todo-fill",
    "calendar-todo-line",
    "cloud-fill",
    "cloud-line",
    "cloud-off-fill",
    "cloud-off-line",
    "copyleft-fill",
    "copyleft-line",
    "copyright-fill",
    "copyright-line",
    "creative-commons-by-fill",
    "creative-commons-by-line",
    "creative-commons-fill",
    "creative-commons-line",
    "creative-commons-nc-fill",
    "creative-commons-nc-line",
    "creative-commons-nd-fill",
    "creative-commons-nd-line",
    "creative-commons-sa-fill",
    "creative-commons-sa-line",
    "creative-commons-zero-fill",
    "creative-commons-zero-line",
    "customer-service-2-fill",
    "customer-service-2-line",
    "customer-service-fill",
    "customer-service-line",
    "donut-chart-fill",
    "donut-chart-line",
    "flag-2-fill",
    "flag-2-line",
    "flag-fill",
    "flag-line",
    "global-fill",
    "global-line",
    "honour-fill",
    "honour-line",
    "inbox-archive-fill",
    "inbox-archive-line",
    "inbox-fill",
    "inbox-line",
    "inbox-unarchive-fill",
    "inbox-unarchive-line",
    "line-chart-fill",
    "line-chart-line",
    "links-fill",
    "links-line",
    "mail-add-fill",
    "mail-add-line",
    "mail-check-fill",
    "mail-check-line",
    "mail-close-fill",
    "mail-close-line",
    "mail-download-fill",
    "mail-download-line",
    "mail-fill",
    "mail-forbid-fill",
    "mail-forbid-line",
    "mail-line",
    "mail-lock-fill",
    "mail-lock-line",
    "mail-open-fill",
    "mail-open-line",
    "mail-send-fill",
    "mail-send-line",
    "mail-settings-fill",
    "mail-settings-line",
    "mail-star-fill",
    "mail-star-line",
    "mail-unread-fill",
    "mail-unread-line",
    "mail-volume-fill",
    "mail-volume-line",
    "medal-2-fill",
    "medal-2-line",
    "medal-fill",
    "medal-line",
    "pie-chart-2-fill",
    "pie-chart-2-line",
    "pie-chart-box-fill",
    "pie-chart-box-line",
    "pie-chart-fill",
    "pie-chart-line",
    "printer-cloud-fill",
    "printer-cloud-line",
    "printer-fill",
    "printer-line",
    "profile-fill",
    "profile-line",
    "projector-2-fill",
    "projector-2-line",
    "projector-fill",
    "projector-line",
    "record-mail-fill",
    "record-mail-line",
    "registered-fill",
    "registered-line",
    "reply-all-fill",
    "reply-all-line",
    "reply-fill",
    "reply-line",
    "send-plane-2-fill",
    "send-plane-2-line",
    "send-plane-fill",
    "send-plane-line",
    "service-fill",
    "service-line",
    "slideshow-2-fill",
    "slideshow-2-line",
    "slideshow-3-fill",
    "slideshow-3-line",
    "slideshow-4-fill",
    "slideshow-4-line",
    "slideshow-fill",
    "slideshow-line",
    "stack-fill",
    "stack-line",
    "trademark-fill",
    "trademark-line",
    "window-2-fill",
    "window-2-line",
    "window-fill",
    "window-line",
    "chat-1-fill",
    "chat-1-line",
    "chat-2-fill",
    "chat-2-line",
    "chat-3-fill",
    "chat-3-line",
    "chat-4-fill",
    "chat-4-line",
    "chat-check-fill",
    "chat-check-line",
    "chat-delete-fill",
    "chat-delete-line",
    "chat-download-fill",
    "chat-download-line",
    "chat-follow-up-fill",
    "chat-follow-up-line",
    "chat-forward-fill",
    "chat-forward-line",
    "chat-heart-fill",
    "chat-heart-line",
    "chat-history-fill",
    "chat-history-line",
    "chat-new-fill",
    "chat-new-line",
    "chat-off-fill",
    "chat-off-line",
    "chat-poll-fill",
    "chat-poll-line",
    "chat-private-fill",
    "chat-private-line",
    "chat-quote-fill",
    "chat-quote-line",
    "chat-settings-fill",
    "chat-settings-line",
    "chat-smile-2-fill",
    "chat-smile-2-line",
    "chat-smile-3-fill",
    "chat-smile-3-line",
    "chat-smile-fill",
    "chat-smile-line",
    "chat-upload-fill",
    "chat-upload-line",
    "chat-voice-fill",
    "chat-voice-line",
    "discuss-fill",
    "discuss-line",
    "message-2-fill",
    "message-2-line",
    "message-3-fill",
    "message-3-line",
    "message-fill",
    "message-line",
    "question-answer-fill",
    "question-answer-line",
    "questionnaire-fill",
    "questionnaire-line",
    "video-chat-fill",
    "video-chat-line",
    "anticlockwise-2-fill",
    "anticlockwise-2-line",
    "anticlockwise-fill",
    "anticlockwise-line",
    "artboard-2-fill",
    "artboard-2-line",
    "artboard-fill",
    "artboard-line",
    "ball-pen-fill",
    "ball-pen-line",
    "blur-off-fill",
    "blur-off-line",
    "brush-2-fill",
    "brush-2-line",
    "brush-3-fill",
    "brush-3-line",
    "brush-4-fill",
    "brush-4-line",
    "brush-fill",
    "brush-line",
    "clockwise-2-fill",
    "clockwise-2-line",
    "clockwise-fill",
    "clockwise-line",
    "collage-fill",
    "collage-line",
    "compasses-2-fill",
    "compasses-2-line",
    "compasses-fill",
    "compasses-line",
    "contrast-2-fill",
    "contrast-2-line",
    "contrast-drop-2-fill",
    "contrast-drop-2-line",
    "contrast-drop-fill",
    "contrast-drop-line",
    "contrast-fill",
    "contrast-line",
    "crop-2-fill",
    "crop-2-line",
    "crop-fill",
    "crop-line",
    "drag-drop-fill",
    "drag-drop-line",
    "drop-fill",
    "drop-line",
    "edit-2-fill",
    "edit-2-line",
    "edit-box-fill",
    "edit-box-line",
    "edit-circle-fill",
    "edit-circle-line",
    "edit-fill",
    "edit-line",
    "eraser-fill",
    "eraser-line",
    "focus-2-fill",
    "focus-2-line",
    "focus-3-fill",
    "focus-3-line",
    "focus-fill",
    "focus-line",
    "grid-fill",
    "grid-line",
    "hammer-fill",
    "hammer-line",
    "ink-bottle-fill",
    "ink-bottle-line",
    "input-method-fill",
    "input-method-line",
    "layout-2-fill",
    "layout-2-line",
    "layout-3-fill",
    "layout-3-line",
    "layout-4-fill",
    "layout-4-line",
    "layout-5-fill",
    "layout-5-line",
    "layout-6-fill",
    "layout-6-line",
    "layout-bottom-2-fill",
    "layout-bottom-2-line",
    "layout-bottom-fill",
    "layout-bottom-line",
    "layout-column-fill",
    "layout-column-line",
    "layout-fill",
    "layout-grid-fill",
    "layout-grid-line",
    "layout-left-2-fill",
    "layout-left-2-line",
    "layout-left-fill",
    "layout-left-line",
    "layout-line",
    "layout-masonry-fill",
    "layout-masonry-line",
    "layout-right-2-fill",
    "layout-right-2-line",
    "layout-right-fill",
    "layout-right-line",
    "layout-row-fill",
    "layout-row-line",
    "layout-top-2-fill",
    "layout-top-2-line",
    "layout-top-fill",
    "layout-top-line",
    "magic-fill",
    "magic-line",
    "mark-pen-fill",
    "mark-pen-line",
    "markup-fill",
    "markup-line",
    "paint-brush-fill",
    "paint-brush-line",
    "paint-fill",
    "paint-line",
    "palette-fill",
    "palette-line",
    "pantone-fill",
    "pantone-line",
    "pen-nib-fill",
    "pen-nib-line",
    "pencil-fill",
    "pencil-line",
    "pencil-ruler-2-fill",
    "pencil-ruler-2-line",
    "pencil-ruler-fill",
    "pencil-ruler-line",
    "quill-pen-fill",
    "quill-pen-line",
    "ruler-2-fill",
    "ruler-2-line",
    "ruler-fill",
    "ruler-line",
    "scissors-2-fill",
    "scissors-2-line",
    "scissors-cut-fill",
    "scissors-cut-line",
    "scissors-fill",
    "scissors-line",
    "screenshot-2-fill",
    "screenshot-2-line",
    "screenshot-fill",
    "screenshot-line",
    "shape-2-fill",
    "shape-2-line",
    "shape-fill",
    "shape-line",
    "slice-fill",
    "slice-line",
    "table-alt-fill",
    "table-alt-line",
    "table-fill",
    "table-line",
    "tools-fill",
    "tools-line",
    "braces-fill",
    "braces-line",
    "brackets-fill",
    "brackets-line",
    "bug-2-fill",
    "bug-2-line",
    "bug-fill",
    "bug-line",
    "code-box-fill",
    "code-box-line",
    "code-fill",
    "code-line",
    "code-s-fill",
    "code-s-line",
    "code-s-slash-fill",
    "code-s-slash-line",
    "command-fill",
    "command-line",
    "css3-fill",
    "css3-line",
    "cursor-fill",
    "cursor-line",
    "git-branch-fill",
    "git-branch-line",
    "git-commit-fill",
    "git-commit-line",
    "git-pull-request-fill",
    "git-pull-request-line",
    "git-repository-commits-fill",
    "git-repository-commits-line",
    "git-repository-fill",
    "git-repository-line",
    "git-repository-private-fill",
    "git-repository-private-line",
    "html5-fill",
    "html5-line",
    "parentheses-fill",
    "parentheses-line",
    "terminal-box-fill",
    "terminal-box-line",
    "terminal-fill",
    "terminal-line",
    "terminal-window-fill",
    "terminal-window-line",
    "airplay-fill",
    "airplay-line",
    "barcode-box-fill",
    "barcode-box-line",
    "barcode-fill",
    "barcode-line",
    "base-station-fill",
    "base-station-line",
    "battery-2-charge-fill",
    "battery-2-charge-line",
    "battery-2-fill",
    "battery-2-line",
    "battery-charge-fill",
    "battery-charge-line",
    "battery-fill",
    "battery-line",
    "battery-low-fill",
    "battery-low-line",
    "battery-saver-fill",
    "battery-saver-line",
    "battery-share-fill",
    "battery-share-line",
    "bluetooth-connect-fill",
    "bluetooth-connect-line",
    "bluetooth-fill",
    "bluetooth-line",
    "cast-fill",
    "cast-line",
    "cellphone-fill",
    "cellphone-line",
    "computer-fill",
    "computer-line",
    "cpu-fill",
    "cpu-line",
    "dashboard-2-fill",
    "dashboard-2-line",
    "dashboard-3-fill",
    "dashboard-3-line",
    "database-2-fill",
    "database-2-line",
    "database-fill",
    "database-line",
    "device-fill",
    "device-line",
    "device-recover-fill",
    "device-recover-line",
    "dual-sim-1-fill",
    "dual-sim-1-line",
    "dual-sim-2-fill",
    "dual-sim-2-line",
    "fingerprint-2-fill",
    "fingerprint-2-line",
    "fingerprint-fill",
    "fingerprint-line",
    "gamepad-fill",
    "gamepad-line",
    "gps-fill",
    "gps-line",
    "gradienter-fill",
    "gradienter-line",
    "hard-drive-2-fill",
    "hard-drive-2-line",
    "hard-drive-3-fill",
    "hard-drive-3-line",
    "hotspot-fill",
    "hotspot-line",
    "install-fill",
    "install-line",
    "keyboard-box-fill",
    "keyboard-box-line",
    "keyboard-fill",
    "keyboard-line",
    "mac-fill",
    "mac-line",
    "macbook-fill",
    "macbook-line",
    "mouse-fill",
    "mouse-line",
    "phone-fill",
    "phone-find-fill",
    "phone-find-line",
    "phone-line",
    "phone-lock-fill",
    "phone-lock-line",
    "qr-code-fill",
    "qr-code-line",
    "qr-scan-2-fill",
    "qr-scan-2-line",
    "qr-scan-fill",
    "qr-scan-line",
    "radar-fill",
    "radar-line",
    "remote-control-2-fill",
    "remote-control-2-line",
    "remote-control-fill",
    "remote-control-line",
    "restart-fill",
    "restart-line",
    "rotate-lock-fill",
    "rotate-lock-line",
    "router-fill",
    "router-line",
    "rss-fill",
    "rss-line",
    "save-2-fill",
    "save-2-line",
    "save-3-fill",
    "save-3-line",
    "save-fill",
    "save-line",
    "scan-2-fill",
    "scan-2-line",
    "scan-fill",
    "scan-line",
    "sd-card-fill",
    "sd-card-line",
    "sd-card-mini-fill",
    "sd-card-mini-line",
    "sensor-fill",
    "sensor-line",
    "server-fill",
    "server-line",
    "shut-down-fill",
    "shut-down-line",
    "signal-wifi-1-fill",
    "signal-wifi-1-line",
    "signal-wifi-2-fill",
    "signal-wifi-2-line",
    "signal-wifi-3-fill",
    "signal-wifi-3-line",
    "signal-wifi-error-fill",
    "signal-wifi-error-line",
    "signal-wifi-fill",
    "signal-wifi-line",
    "signal-wifi-off-fill",
    "signal-wifi-off-line",
    "sim-card-2-fill",
    "sim-card-2-line",
    "sim-card-fill",
    "sim-card-line",
    "smartphone-fill",
    "smartphone-line",
    "tablet-fill",
    "tablet-line",
    "tv-2-fill",
    "tv-2-line",
    "tv-fill",
    "tv-line",
    "u-disk-fill",
    "u-disk-line",
    "uninstall-fill",
    "uninstall-line",
    "usb-fill",
    "usb-line",
    "wifi-fill",
    "wifi-line",
    "wifi-off-fill",
    "wifi-off-line",
    "wireless-charging-fill",
    "wireless-charging-line",
    "article-fill",
    "article-line",
    "bill-fill",
    "bill-line",
    "book-2-fill",
    "book-2-line",
    "book-3-fill",
    "book-3-line",
    "book-fill",
    "book-line",
    "book-mark-fill",
    "book-mark-line",
    "book-open-fill",
    "book-open-line",
    "book-read-fill",
    "book-read-line",
    "booklet-fill",
    "booklet-line",
    "clipboard-fill",
    "clipboard-line",
    "contacts-book-2-fill",
    "contacts-book-2-line",
    "contacts-book-fill",
    "contacts-book-line",
    "contacts-book-upload-fill",
    "contacts-book-upload-line",
    "draft-fill",
    "draft-line",
    "file-2-fill",
    "file-2-line",
    "file-3-fill",
    "file-3-line",
    "file-4-fill",
    "file-4-line",
    "file-add-fill",
    "file-add-line",
    "file-chart-2-fill",
    "file-chart-2-line",
    "file-chart-fill",
    "file-chart-line",
    "file-cloud-fill",
    "file-cloud-line",
    "file-code-fill",
    "file-code-line",
    "file-copy-2-fill",
    "file-copy-2-line",
    "file-copy-fill",
    "file-copy-line",
    "file-damage-fill",
    "file-damage-line",
    "file-download-fill",
    "file-download-line",
    "file-edit-fill",
    "file-edit-line",
    "file-excel-2-fill",
    "file-excel-2-line",
    "file-excel-fill",
    "file-excel-line",
    "file-fill",
    "file-forbid-fill",
    "file-forbid-line",
    "file-gif-fill",
    "file-gif-line",
    "file-history-fill",
    "file-history-line",
    "file-hwp-fill",
    "file-hwp-line",
    "file-info-fill",
    "file-info-line",
    "file-line",
    "file-list-2-fill",
    "file-list-2-line",
    "file-list-3-fill",
    "file-list-3-line",
    "file-list-fill",
    "file-list-line",
    "file-lock-fill",
    "file-lock-line",
    "file-mark-fill",
    "file-mark-line",
    "file-music-fill",
    "file-music-line",
    "file-paper-2-fill",
    "file-paper-2-line",
    "file-paper-fill",
    "file-paper-line",
    "file-pdf-fill",
    "file-pdf-line",
    "file-ppt-2-fill",
    "file-ppt-2-line",
    "file-ppt-fill",
    "file-ppt-line",
    "file-reduce-fill",
    "file-reduce-line",
    "file-search-fill",
    "file-search-line",
    "file-settings-fill",
    "file-settings-line",
    "file-shield-2-fill",
    "file-shield-2-line",
    "file-shield-fill",
    "file-shield-line",
    "file-shred-fill",
    "file-shred-line",
    "file-text-fill",
    "file-text-line",
    "file-transfer-fill",
    "file-transfer-line",
    "file-unknow-fill",
    "file-unknow-line",
    "file-upload-fill",
    "file-upload-line",
    "file-user-fill",
    "file-user-line",
    "file-warning-fill",
    "file-warning-line",
    "file-word-2-fill",
    "file-word-2-line",
    "file-word-fill",
    "file-word-line",
    "file-zip-fill",
    "file-zip-line",
    "folder-2-fill",
    "folder-2-line",
    "folder-3-fill",
    "folder-3-line",
    "folder-4-fill",
    "folder-4-line",
    "folder-5-fill",
    "folder-5-line",
    "folder-add-fill",
    "folder-add-line",
    "folder-chart-2-fill",
    "folder-chart-2-line",
    "folder-chart-fill",
    "folder-chart-line",
    "folder-download-fill",
    "folder-download-line",
    "folder-fill",
    "folder-forbid-fill",
    "folder-forbid-line",
    "folder-history-fill",
    "folder-history-line",
    "folder-info-fill",
    "folder-info-line",
    "folder-keyhole-fill",
    "folder-keyhole-line",
    "folder-line",
    "folder-lock-fill",
    "folder-lock-line",
    "folder-music-fill",
    "folder-music-line",
    "folder-open-fill",
    "folder-open-line",
    "folder-received-fill",
    "folder-received-line",
    "folder-reduce-fill",
    "folder-reduce-line",
    "folder-settings-fill",
    "folder-settings-line",
    "folder-shared-fill",
    "folder-shared-line",
    "folder-shield-2-fill",
    "folder-shield-2-line",
    "folder-shield-fill",
    "folder-shield-line",
    "folder-transfer-fill",
    "folder-transfer-line",
    "folder-unknow-fill",
    "folder-unknow-line",
    "folder-upload-fill",
    "folder-upload-line",
    "folder-user-fill",
    "folder-user-line",
    "folder-warning-fill",
    "folder-warning-line",
    "folder-zip-fill",
    "folder-zip-line",
    "folders-fill",
    "folders-line",
    "keynote-fill",
    "keynote-line",
    "markdown-fill",
    "markdown-line",
    "newspaper-fill",
    "newspaper-line",
    "numbers-fill",
    "numbers-line",
    "pages-fill",
    "pages-line",
    "sticky-note-2-fill",
    "sticky-note-2-line",
    "sticky-note-fill",
    "sticky-note-line",
    "survey-fill",
    "survey-line",
    "task-fill",
    "task-line",
    "todo-fill",
    "todo-line",
    "a-b",
    "align-bottom",
    "align-center",
    "align-justify",
    "align-left",
    "align-right",
    "align-top",
    "align-vertically",
    "asterisk",
    "attachment-2",
    "bold",
    "bring-forward",
    "bring-to-front",
    "code-view",
    "delete-column",
    "delete-row",
    "double-quotes-l",
    "double-quotes-r",
    "emphasis-cn",
    "emphasis",
    "english-input",
    "flow-chart",
    "font-color",
    "font-size-2",
    "font-size",
    "format-clear",
    "functions",
    "h-1",
    "h-2",
    "h-3",
    "h-4",
    "h-5",
    "h-6",
    "hashtag",
    "heading",
    "indent-decrease",
    "indent-increase",
    "input-cursor-move",
    "insert-column-left",
    "insert-column-right",
    "insert-row-bottom",
    "insert-row-top",
    "italic",
    "line-height",
    "link-m",
    "link-unlink-m",
    "link-unlink",
    "link",
    "list-check-2",
    "list-check",
    "list-unordered",
    "merge-cells-horizontal",
    "merge-cells-vertical",
    "mind-map",
    "node-tree",
    "omega",
    "organization-chart",
    "page-separator",
    "paragraph",
    "pinyin-input",
    "question-mark",
    "rounded-corner",
    "send-backward",
    "send-to-back",
    "separator",
    "single-quotes-l",
    "single-quotes-r",
    "sort-asc",
    "sort-desc",
    "space",
    "split-cells-horizontal",
    "split-cells-vertical",
    "strikethrough-2",
    "strikethrough",
    "subscript-2",
    "subscript",
    "superscript-2",
    "superscript",
    "table-2",
    "text-direction-l",
    "text-direction-r",
    "text-spacing",
    "text-wrap",
    "text",
    "translate-2",
    "translate",
    "underline",
    "wubi-input",
    "24-hours-fill",
    "24-hours-line",
    "auction-fill",
    "auction-line",
    "bank-card-2-fill",
    "bank-card-2-line",
    "bank-card-fill",
    "bank-card-line",
    "bit-coin-fill",
    "bit-coin-line",
    "coin-fill",
    "coin-line",
    "coins-fill",
    "coins-line",
    "copper-coin-fill",
    "copper-coin-line",
    "copper-diamond-fill",
    "copper-diamond-line",
    "coupon-2-fill",
    "coupon-2-line",
    "coupon-3-fill",
    "coupon-3-line",
    "coupon-4-fill",
    "coupon-4-line",
    "coupon-5-fill",
    "coupon-5-line",
    "coupon-fill",
    "coupon-line",
    "currency-fill",
    "currency-line",
    "exchange-box-fill",
    "exchange-box-line",
    "exchange-cny-fill",
    "exchange-cny-line",
    "exchange-dollar-fill",
    "exchange-dollar-line",
    "exchange-fill",
    "exchange-funds-fill",
    "exchange-funds-line",
    "exchange-line",
    "funds-box-fill",
    "funds-box-line",
    "funds-fill",
    "funds-line",
    "gift-2-fill",
    "gift-2-line",
    "gift-fill",
    "gift-line",
    "hand-coin-fill",
    "hand-coin-line",
    "hand-heart-fill",
    "hand-heart-line",
    "increase-decrease-fill",
    "increase-decrease-line",
    "money-cny-box-fill",
    "money-cny-box-line",
    "money-cny-circle-fill",
    "money-cny-circle-line",
    "money-dollar-box-fill",
    "money-dollar-box-line",
    "money-dollar-circle-fill",
    "money-dollar-circle-line",
    "money-euro-box-fill",
    "money-euro-box-line",
    "money-euro-circle-fill",
    "money-euro-circle-line",
    "money-pound-box-fill",
    "money-pound-box-line",
    "money-pound-circle-fill",
    "money-pound-circle-line",
    "percent-fill",
    "percent-line",
    "price-tag-2-fill",
    "price-tag-2-line",
    "price-tag-3-fill",
    "price-tag-3-line",
    "price-tag-fill",
    "price-tag-line",
    "red-packet-fill",
    "red-packet-line",
    "refund-2-fill",
    "refund-2-line",
    "refund-fill",
    "refund-line",
    "safe-2-fill",
    "safe-2-line",
    "safe-fill",
    "safe-line",
    "secure-payment-fill",
    "secure-payment-line",
    "shopping-bag-2-fill",
    "shopping-bag-2-line",
    "shopping-bag-3-fill",
    "shopping-bag-3-line",
    "shopping-bag-fill",
    "shopping-bag-line",
    "shopping-basket-2-fill",
    "shopping-basket-2-line",
    "shopping-basket-fill",
    "shopping-basket-line",
    "shopping-cart-2-fill",
    "shopping-cart-2-line",
    "shopping-cart-fill",
    "shopping-cart-line",
    "stock-fill",
    "stock-line",
    "swap-box-fill",
    "swap-box-line",
    "swap-fill",
    "swap-line",
    "ticket-2-fill",
    "ticket-2-line",
    "ticket-fill",
    "ticket-line",
    "trophy-fill",
    "trophy-line",
    "vip-crown-2-fill",
    "vip-crown-2-line",
    "vip-crown-fill",
    "vip-crown-line",
    "vip-diamond-fill",
    "vip-diamond-line",
    "vip-fill",
    "vip-line",
    "wallet-2-fill",
    "wallet-2-line",
    "wallet-3-fill",
    "wallet-3-line",
    "wallet-fill",
    "wallet-line",
    "water-flash-fill",
    "water-flash-line",
    "capsule-fill",
    "capsule-line",
    "dislike-fill",
    "dislike-line",
    "dossier-fill",
    "dossier-line",
    "empathize-fill",
    "empathize-line",
    "first-aid-kit-fill",
    "first-aid-kit-line",
    "flask-fill",
    "flask-line",
    "hand-sanitizer-fill",
    "hand-sanitizer-line",
    "health-book-fill",
    "health-book-line",
    "heart-2-fill",
    "heart-2-line",
    "heart-3-fill",
    "heart-3-line",
    "heart-add-fill",
    "heart-add-line",
    "heart-fill",
    "heart-line",
    "heart-pulse-fill",
    "heart-pulse-line",
    "hearts-fill",
    "hearts-line",
    "infrared-thermometer-fill",
    "infrared-thermometer-line",
    "lungs-fill",
    "lungs-line",
    "medicine-bottle-fill",
    "medicine-bottle-line",
    "mental-health-fill",
    "mental-health-line",
    "microscope-fill",
    "microscope-line",
    "nurse-fill",
    "nurse-line",
    "psychotherapy-fill",
    "psychotherapy-line",
    "pulse-fill",
    "pulse-line",
    "rest-time-fill",
    "rest-time-line",
    "stethoscope-fill",
    "stethoscope-line",
    "surgical-mask-fill",
    "surgical-mask-line",
    "syringe-fill",
    "syringe-line",
    "test-tube-fill",
    "test-tube-line",
    "thermometer-fill",
    "thermometer-line",
    "virus-fill",
    "virus-line",
    "zzz-fill",
    "zzz-line",
    "alipay-fill",
    "alipay-line",
    "amazon-fill",
    "amazon-line",
    "android-fill",
    "android-line",
    "angularjs-fill",
    "angularjs-line",
    "app-store-fill",
    "app-store-line",
    "apple-fill",
    "apple-line",
    "baidu-fill",
    "baidu-line",
    "behance-fill",
    "behance-line",
    "bilibili-fill",
    "bilibili-line",
    "centos-fill",
    "centos-line",
    "chrome-fill",
    "chrome-line",
    "codepen-fill",
    "codepen-line",
    "coreos-fill",
    "coreos-line",
    "dingding-fill",
    "dingding-line",
    "discord-fill",
    "discord-line",
    "disqus-fill",
    "disqus-line",
    "douban-fill",
    "douban-line",
    "dribbble-fill",
    "dribbble-line",
    "drive-fill",
    "drive-line",
    "dropbox-fill",
    "dropbox-line",
    "edge-fill",
    "edge-line",
    "evernote-fill",
    "evernote-line",
    "facebook-box-fill",
    "facebook-box-line",
    "facebook-circle-fill",
    "facebook-circle-line",
    "facebook-fill",
    "facebook-line",
    "finder-fill",
    "finder-line",
    "firefox-fill",
    "firefox-line",
    "flutter-fill",
    "flutter-line",
    "gatsby-fill",
    "gatsby-line",
    "github-fill",
    "github-line",
    "gitlab-fill",
    "gitlab-line",
    "google-fill",
    "google-line",
    "google-play-fill",
    "google-play-line",
    "honor-of-kings-fill",
    "honor-of-kings-line",
    "ie-fill",
    "ie-line",
    "instagram-fill",
    "instagram-line",
    "invision-fill",
    "invision-line",
    "kakao-talk-fill",
    "kakao-talk-line",
    "line-fill",
    "line-line",
    "linkedin-box-fill",
    "linkedin-box-line",
    "linkedin-fill",
    "linkedin-line",
    "mastercard-fill",
    "mastercard-line",
    "mastodon-fill",
    "mastodon-line",
    "medium-fill",
    "medium-line",
    "messenger-fill",
    "messenger-line",
    "microsoft-fill",
    "microsoft-line",
    "mini-program-fill",
    "mini-program-line",
    "netease-cloud-music-fill",
    "netease-cloud-music-line",
    "netflix-fill",
    "netflix-line",
    "npmjs-fill",
    "npmjs-line",
    "opera-fill",
    "opera-line",
    "patreon-fill",
    "patreon-line",
    "paypal-fill",
    "paypal-line",
    "pinterest-fill",
    "pinterest-line",
    "pixelfed-fill",
    "pixelfed-line",
    "playstation-fill",
    "playstation-line",
    "product-hunt-fill",
    "product-hunt-line",
    "qq-fill",
    "qq-line",
    "reactjs-fill",
    "reactjs-line",
    "reddit-fill",
    "reddit-line",
    "remixicon-fill",
    "remixicon-line",
    "safari-fill",
    "safari-line",
    "skype-fill",
    "skype-line",
    "slack-fill",
    "slack-line",
    "snapchat-fill",
    "snapchat-line",
    "soundcloud-fill",
    "soundcloud-line",
    "spectrum-fill",
    "spectrum-line",
    "spotify-fill",
    "spotify-line",
    "stack-overflow-fill",
    "stack-overflow-line",
    "stackshare-fill",
    "stackshare-line",
    "steam-fill",
    "steam-line",
    "switch-fill",
    "switch-line",
    "taobao-fill",
    "taobao-line",
    "telegram-fill",
    "telegram-line",
    "trello-fill",
    "trello-line",
    "tumblr-fill",
    "tumblr-line",
    "twitch-fill",
    "twitch-line",
    "twitter-fill",
    "twitter-line",
    "unsplash-fill",
    "unsplash-line",
    "vimeo-fill",
    "vimeo-line",
    "visa-fill",
    "visa-line",
    "vuejs-fill",
    "vuejs-line",
    "wechat-2-fill",
    "wechat-2-line",
    "wechat-fill",
    "wechat-line",
    "wechat-pay-fill",
    "wechat-pay-line",
    "weibo-fill",
    "weibo-line",
    "whatsapp-fill",
    "whatsapp-line",
    "windows-fill",
    "windows-line",
    "xbox-fill",
    "xbox-line",
    "xing-fill",
    "xing-line",
    "youtube-fill",
    "youtube-line",
    "zcool-fill",
    "zcool-line",
    "zhihu-fill",
    "zhihu-line",
    "anchor-fill",
    "anchor-line",
    "barricade-fill",
    "barricade-line",
    "bike-fill",
    "bike-line",
    "bus-2-fill",
    "bus-2-line",
    "bus-fill",
    "bus-line",
    "bus-wifi-fill",
    "bus-wifi-line",
    "car-fill",
    "car-line",
    "car-washing-fill",
    "car-washing-line",
    "caravan-fill",
    "caravan-line",
    "charging-pile-2-fill",
    "charging-pile-2-line",
    "charging-pile-fill",
    "charging-pile-line",
    "china-railway-fill",
    "china-railway-line",
    "compass-2-fill",
    "compass-2-line",
    "compass-3-fill",
    "compass-3-line",
    "compass-4-fill",
    "compass-4-line",
    "compass-discover-fill",
    "compass-discover-line",
    "compass-fill",
    "compass-line",
    "cup-fill",
    "cup-line",
    "direction-fill",
    "direction-line",
    "e-bike-2-fill",
    "e-bike-2-line",
    "e-bike-fill",
    "e-bike-line",
    "earth-fill",
    "earth-line",
    "flight-land-fill",
    "flight-land-line",
    "flight-takeoff-fill",
    "flight-takeoff-line",
    "footprint-fill",
    "footprint-line",
    "gas-station-fill",
    "gas-station-line",
    "globe-fill",
    "globe-line",
    "goblet-fill",
    "goblet-line",
    "guide-fill",
    "guide-line",
    "hotel-bed-fill",
    "hotel-bed-line",
    "lifebuoy-fill",
    "lifebuoy-line",
    "luggage-cart-fill",
    "luggage-cart-line",
    "luggage-deposit-fill",
    "luggage-deposit-line",
    "map-2-fill",
    "map-2-line",
    "map-fill",
    "map-line",
    "map-pin-2-fill",
    "map-pin-2-line",
    "map-pin-3-fill",
    "map-pin-3-line",
    "map-pin-4-fill",
    "map-pin-4-line",
    "map-pin-5-fill",
    "map-pin-5-line",
    "map-pin-add-fill",
    "map-pin-add-line",
    "map-pin-fill",
    "map-pin-line",
    "map-pin-range-fill",
    "map-pin-range-line",
    "map-pin-time-fill",
    "map-pin-time-line",
    "map-pin-user-fill",
    "map-pin-user-line",
    "motorbike-fill",
    "motorbike-line",
    "navigation-fill",
    "navigation-line",
    "oil-fill",
    "oil-line",
    "parking-box-fill",
    "parking-box-line",
    "parking-fill",
    "parking-line",
    "passport-fill",
    "passport-line",
    "pin-distance-fill",
    "pin-distance-line",
    "plane-fill",
    "plane-line",
    "police-car-fill",
    "police-car-line",
    "pushpin-2-fill",
    "pushpin-2-line",
    "pushpin-fill",
    "pushpin-line",
    "restaurant-2-fill",
    "restaurant-2-line",
    "restaurant-fill",
    "restaurant-line",
    "riding-fill",
    "riding-line",
    "road-map-fill",
    "road-map-line",
    "roadster-fill",
    "roadster-line",
    "rocket-2-fill",
    "rocket-2-line",
    "rocket-fill",
    "rocket-line",
    "route-fill",
    "route-line",
    "run-fill",
    "run-line",
    "sailboat-fill",
    "sailboat-line",
    "ship-2-fill",
    "ship-2-line",
    "ship-fill",
    "ship-line",
    "signal-tower-fill",
    "signal-tower-line",
    "space-ship-fill",
    "space-ship-line",
    "steering-2-fill",
    "steering-2-line",
    "steering-fill",
    "steering-line",
    "subway-fill",
    "subway-line",
    "subway-wifi-fill",
    "subway-wifi-line",
    "suitcase-2-fill",
    "suitcase-2-line",
    "suitcase-3-fill",
    "suitcase-3-line",
    "suitcase-fill",
    "suitcase-line",
    "takeaway-fill",
    "takeaway-line",
    "taxi-fill",
    "taxi-line",
    "taxi-wifi-fill",
    "taxi-wifi-line",
    "traffic-light-fill",
    "traffic-light-line",
    "train-fill",
    "train-line",
    "train-wifi-fill",
    "train-wifi-line",
    "treasure-map-fill",
    "treasure-map-line",
    "truck-fill",
    "truck-line",
    "walk-fill",
    "walk-line",
    "4k-fill",
    "4k-line",
    "album-fill",
    "album-line",
    "aspect-ratio-fill",
    "aspect-ratio-line",
    "broadcast-fill",
    "broadcast-line",
    "camera-2-fill",
    "camera-2-line",
    "camera-3-fill",
    "camera-3-line",
    "camera-fill",
    "camera-lens-fill",
    "camera-lens-line",
    "camera-line",
    "camera-off-fill",
    "camera-off-line",
    "camera-switch-fill",
    "camera-switch-line",
    "clapperboard-fill",
    "clapperboard-line",
    "closed-captioning-fill",
    "closed-captioning-line",
    "disc-fill",
    "disc-line",
    "dv-fill",
    "dv-line",
    "dvd-fill",
    "dvd-line",
    "eject-fill",
    "eject-line",
    "equalizer-fill",
    "equalizer-line",
    "film-fill",
    "film-line",
    "fullscreen-exit-fill",
    "fullscreen-exit-line",
    "fullscreen-fill",
    "fullscreen-line",
    "gallery-fill",
    "gallery-line",
    "gallery-upload-fill",
    "gallery-upload-line",
    "hd-fill",
    "hd-line",
    "headphone-fill",
    "headphone-line",
    "hq-fill",
    "hq-line",
    "image-2-fill",
    "image-2-line",
    "image-add-fill",
    "image-add-line",
    "image-edit-fill",
    "image-edit-line",
    "image-fill",
    "image-line",
    "landscape-fill",
    "landscape-line",
    "live-fill",
    "live-line",
    "mic-2-fill",
    "mic-2-line",
    "mic-fill",
    "mic-line",
    "mic-off-fill",
    "mic-off-line",
    "movie-2-fill",
    "movie-2-line",
    "movie-fill",
    "movie-line",
    "music-2-fill",
    "music-2-line",
    "music-fill",
    "music-line",
    "mv-fill",
    "mv-line",
    "notification-2-fill",
    "notification-2-line",
    "notification-3-fill",
    "notification-3-line",
    "notification-4-fill",
    "notification-4-line",
    "notification-fill",
    "notification-line",
    "notification-off-fill",
    "notification-off-line",
    "order-play-fill",
    "order-play-line",
    "pause-circle-fill",
    "pause-circle-line",
    "pause-fill",
    "pause-line",
    "pause-mini-fill",
    "pause-mini-line",
    "phone-camera-fill",
    "phone-camera-line",
    "picture-in-picture-2-fill",
    "picture-in-picture-2-line",
    "picture-in-picture-exit-fill",
    "picture-in-picture-exit-line",
    "picture-in-picture-fill",
    "picture-in-picture-line",
    "play-circle-fill",
    "play-circle-line",
    "play-fill",
    "play-line",
    "play-list-2-fill",
    "play-list-2-line",
    "play-list-add-fill",
    "play-list-add-line",
    "play-list-fill",
    "play-list-line",
    "play-mini-fill",
    "play-mini-line",
    "polaroid-2-fill",
    "polaroid-2-line",
    "polaroid-fill",
    "polaroid-line",
    "radio-2-fill",
    "radio-2-line",
    "radio-fill",
    "radio-line",
    "record-circle-fill",
    "record-circle-line",
    "repeat-2-fill",
    "repeat-2-line",
    "repeat-fill",
    "repeat-line",
    "repeat-one-fill",
    "repeat-one-line",
    "rewind-fill",
    "rewind-line",
    "rewind-mini-fill",
    "rewind-mini-line",
    "rhythm-fill",
    "rhythm-line",
    "shuffle-fill",
    "shuffle-line",
    "skip-back-fill",
    "skip-back-line",
    "skip-back-mini-fill",
    "skip-back-mini-line",
    "skip-forward-fill",
    "skip-forward-line",
    "skip-forward-mini-fill",
    "skip-forward-mini-line",
    "sound-module-fill",
    "sound-module-line",
    "speaker-2-fill",
    "speaker-2-line",
    "speaker-3-fill",
    "speaker-3-line",
    "speaker-fill",
    "speaker-line",
    "speed-fill",
    "speed-line",
    "speed-mini-fill",
    "speed-mini-line",
    "stop-circle-fill",
    "stop-circle-line",
    "stop-fill",
    "stop-line",
    "stop-mini-fill",
    "stop-mini-line",
    "surround-sound-fill",
    "surround-sound-line",
    "tape-fill",
    "tape-line",
    "video-add-fill",
    "video-add-line",
    "video-download-fill",
    "video-download-line",
    "video-fill",
    "video-line",
    "video-upload-fill",
    "video-upload-line",
    "vidicon-2-fill",
    "vidicon-2-line",
    "vidicon-fill",
    "vidicon-line",
    "voiceprint-fill",
    "voiceprint-line",
    "volume-down-fill",
    "volume-down-line",
    "volume-mute-fill",
    "volume-mute-line",
    "volume-off-vibrate-fill",
    "volume-off-vibrate-line",
    "volume-up-fill",
    "volume-up-line",
    "volume-vibrate-fill",
    "volume-vibrate-line",
    "webcam-fill",
    "webcam-line",
    "basketball-fill",
    "basketball-line",
    "bell-fill",
    "bell-line",
    "billiards-fill",
    "billiards-line",
    "boxing-fill",
    "boxing-line",
    "cactus-fill",
    "cactus-line",
    "cake-2-fill",
    "cake-2-line",
    "cake-3-fill",
    "cake-3-line",
    "cake-fill",
    "cake-line",
    "character-recognition-fill",
    "character-recognition-line",
    "door-closed-fill",
    "door-closed-line",
    "door-fill",
    "door-line",
    "door-lock-box-fill",
    "door-lock-box-line",
    "door-lock-fill",
    "door-lock-line",
    "door-open-fill",
    "door-open-line",
    "football-fill",
    "football-line",
    "fridge-fill",
    "fridge-line",
    "game-fill",
    "game-line",
    "handbag-fill",
    "handbag-line",
    "key-2-fill",
    "key-2-line",
    "key-fill",
    "key-line",
    "knife-blood-fill",
    "knife-blood-line",
    "knife-fill",
    "knife-line",
    "leaf-fill",
    "leaf-line",
    "lightbulb-fill",
    "lightbulb-flash-fill",
    "lightbulb-flash-line",
    "lightbulb-line",
    "outlet-2-fill",
    "outlet-2-line",
    "outlet-fill",
    "outlet-line",
    "ping-pong-fill",
    "ping-pong-line",
    "plant-fill",
    "plant-line",
    "plug-2-fill",
    "plug-2-line",
    "plug-fill",
    "plug-line",
    "recycle-fill",
    "recycle-line",
    "reserved-fill",
    "reserved-line",
    "scales-2-fill",
    "scales-2-line",
    "scales-3-fill",
    "scales-3-line",
    "scales-fill",
    "scales-line",
    "seedling-fill",
    "seedling-line",
    "shirt-fill",
    "shirt-line",
    "sword-fill",
    "sword-line",
    "t-shirt-2-fill",
    "t-shirt-2-line",
    "t-shirt-air-fill",
    "t-shirt-air-line",
    "t-shirt-fill",
    "t-shirt-line",
    "umbrella-fill",
    "umbrella-line",
    "voice-recognition-fill",
    "voice-recognition-line",
    "wheelchair-fill",
    "wheelchair-line",
    "add-box-fill",
    "add-box-line",
    "add-circle-fill",
    "add-circle-line",
    "add-fill",
    "add-line",
    "alarm-fill",
    "alarm-line",
    "alarm-warning-fill",
    "alarm-warning-line",
    "alert-fill",
    "alert-line",
    "apps-2-fill",
    "apps-2-line",
    "apps-fill",
    "apps-line",
    "check-double-fill",
    "check-double-line",
    "check-fill",
    "check-line",
    "checkbox-blank-circle-fill",
    "checkbox-blank-circle-line",
    "checkbox-blank-fill",
    "checkbox-blank-line",
    "checkbox-circle-fill",
    "checkbox-circle-line",
    "checkbox-fill",
    "checkbox-indeterminate-fill",
    "checkbox-indeterminate-line",
    "checkbox-line",
    "checkbox-multiple-blank-fill",
    "checkbox-multiple-blank-line",
    "checkbox-multiple-fill",
    "checkbox-multiple-line",
    "close-circle-fill",
    "close-circle-line",
    "close-fill",
    "close-line",
    "dashboard-fill",
    "dashboard-line",
    "delete-back-2-fill",
    "delete-back-2-line",
    "delete-back-fill",
    "delete-back-line",
    "delete-bin-2-fill",
    "delete-bin-2-line",
    "delete-bin-3-fill",
    "delete-bin-3-line",
    "delete-bin-4-fill",
    "delete-bin-4-line",
    "delete-bin-5-fill",
    "delete-bin-5-line",
    "delete-bin-6-fill",
    "delete-bin-6-line",
    "delete-bin-7-fill",
    "delete-bin-7-line",
    "delete-bin-fill",
    "delete-bin-line",
    "divide-fill",
    "divide-line",
    "download-2-fill",
    "download-2-line",
    "download-cloud-2-fill",
    "download-cloud-2-line",
    "download-cloud-fill",
    "download-cloud-line",
    "download-fill",
    "download-line",
    "error-warning-fill",
    "error-warning-line",
    "external-link-fill",
    "external-link-line",
    "eye-2-fill",
    "eye-2-line",
    "eye-close-fill",
    "eye-close-line",
    "eye-fill",
    "eye-line",
    "eye-off-fill",
    "eye-off-line",
    "filter-2-fill",
    "filter-2-line",
    "filter-3-fill",
    "filter-3-line",
    "filter-fill",
    "filter-line",
    "filter-off-fill",
    "filter-off-line",
    "find-replace-fill",
    "find-replace-line",
    "forbid-2-fill",
    "forbid-2-line",
    "forbid-fill",
    "forbid-line",
    "function-fill",
    "function-line",
    "history-fill",
    "history-line",
    "indeterminate-circle-fill",
    "indeterminate-circle-line",
    "information-fill",
    "information-line",
    "list-settings-fill",
    "list-settings-line",
    "loader-2-fill",
    "loader-2-line",
    "loader-3-fill",
    "loader-3-line",
    "loader-4-fill",
    "loader-4-line",
    "loader-5-fill",
    "loader-5-line",
    "loader-fill",
    "loader-line",
    "lock-2-fill",
    "lock-2-line",
    "lock-fill",
    "lock-line",
    "lock-password-fill",
    "lock-password-line",
    "lock-unlock-fill",
    "lock-unlock-line",
    "login-box-fill",
    "login-box-line",
    "login-circle-fill",
    "login-circle-line",
    "logout-box-fill",
    "logout-box-line",
    "logout-box-r-fill",
    "logout-box-r-line",
    "logout-circle-fill",
    "logout-circle-line",
    "logout-circle-r-fill",
    "logout-circle-r-line",
    "menu-2-fill",
    "menu-2-line",
    "menu-3-fill",
    "menu-3-line",
    "menu-4-fill",
    "menu-4-line",
    "menu-5-fill",
    "menu-5-line",
    "menu-add-fill",
    "menu-add-line",
    "menu-fill",
    "menu-fold-fill",
    "menu-fold-line",
    "menu-line",
    "menu-unfold-fill",
    "menu-unfold-line",
    "more-2-fill",
    "more-2-line",
    "more-fill",
    "more-line",
    "notification-badge-fill",
    "notification-badge-line",

    "question-fill",
    "question-line",
    "radio-button-fill",
    "radio-button-line",
    "refresh-fill",
    "refresh-line",
    "search-2-fill",
    "search-2-line",
    "search-eye-fill",
    "search-eye-line",
    "search-fill",
    "search-line",
    "settings-2-fill",
    "settings-2-line",
    "settings-3-fill",
    "settings-3-line",
    "settings-4-fill",
    "settings-4-line",
    "settings-5-fill",
    "settings-5-line",
    "settings-6-fill",
    "settings-6-line",
    "settings-fill",
    "settings-line",
    "share-box-fill",
    "share-box-line",
    "share-circle-fill",
    "share-circle-line",
    "share-fill",
    "share-forward-2-fill",
    "share-forward-2-line",
    "share-forward-box-fill",
    "share-forward-box-line",
    "share-forward-fill",
    "share-forward-line",
    "share-line",
    "shield-check-fill",
    "shield-check-line",
    "shield-cross-fill",
    "shield-cross-line",
    "shield-fill",
    "shield-flash-fill",
    "shield-flash-line",
    "shield-keyhole-fill",
    "shield-keyhole-line",
    "shield-line",
    "shield-star-fill",
    "shield-star-line",
    "shield-user-fill",
    "shield-user-line",
    "side-bar-fill",
    "side-bar-line",
    "spam-2-fill",
    "spam-2-line",
    "spam-3-fill",
    "spam-3-line",
    "spam-fill",
    "spam-line",
    "star-fill",
    "star-half-fill",
    "star-half-line",
    "star-half-s-fill",
    "star-half-s-line",
    "star-line",
    "star-s-fill",
    "star-s-line",
    "subtract-fill",
    "subtract-line",
    "thumb-down-fill",
    "thumb-down-line",
    "thumb-up-fill",
    "thumb-up-line",
    "time-fill",
    "time-line",
    "timer-2-fill",
    "timer-2-line",
    "timer-fill",
    "timer-flash-fill",
    "timer-flash-line",
    "timer-line",
    "toggle-fill",
    "toggle-line",
    "upload-2-fill",
    "upload-2-line",
    "upload-cloud-2-fill",
    "upload-cloud-2-line",
    "upload-cloud-fill",
    "upload-cloud-line",
    "upload-fill",
    "upload-line",
    "zoom-in-fill",
    "zoom-in-line",
    "zoom-out-fill",
    "zoom-out-line",
    "account-box-fill",
    "account-box-line",
    "account-circle-fill",
    "account-circle-line",
    "account-pin-box-fill",
    "account-pin-box-line",
    "account-pin-circle-fill",
    "account-pin-circle-line",
    "admin-fill",
    "admin-line",
    "aliens-fill",
    "aliens-line",
    "bear-smile-fill",
    "bear-smile-line",
    "body-scan-fill",
    "body-scan-line",
    "contacts-fill",
    "contacts-line",
    "criminal-fill",
    "criminal-line",
    "emotion-2-fill",
    "emotion-2-line",
    "emotion-fill",
    "emotion-happy-fill",
    "emotion-happy-line",
    "emotion-laugh-fill",
    "emotion-laugh-line",
    "emotion-line",
    "emotion-normal-fill",
    "emotion-normal-line",
    "emotion-sad-fill",
    "emotion-sad-line",
    "emotion-unhappy-fill",
    "emotion-unhappy-line",
    "genderless-fill",
    "genderless-line",
    "ghost-2-fill",
    "ghost-2-line",
    "ghost-fill",
    "ghost-line",
    "ghost-smile-fill",
    "ghost-smile-line",
    "group-2-fill",
    "group-2-line",
    "group-fill",
    "group-line",
    "men-fill",
    "men-line",
    "mickey-fill",
    "mickey-line",
    "open-arm-fill",
    "open-arm-line",
    "parent-fill",
    "parent-line",
    "robot-fill",
    "robot-line",
    "skull-2-fill",
    "skull-2-line",
    "skull-fill",
    "skull-line",
    "spy-fill",
    "spy-line",
    "star-smile-fill",
    "star-smile-line",
    "team-fill",
    "team-line",
    "travesti-fill",
    "travesti-line",
    "user-2-fill",
    "user-2-line",
    "user-3-fill",
    "user-3-line",
    "user-4-fill",
    "user-4-line",
    "user-5-fill",
    "user-5-line",
    "user-6-fill",
    "user-6-line",
    "user-add-fill",
    "user-add-line",
    "user-fill",
    "user-heart-fill",
    "user-heart-line",
    "user-line",
    "user-location-fill",
    "user-location-line",
    "user-received-2-fill",
    "user-received-2-line",
    "user-received-fill",
    "user-received-line",
    "user-search-fill",
    "user-search-line",
    "user-settings-fill",
    "user-settings-line",
    "user-shared-2-fill",
    "user-shared-2-line",
    "user-shared-fill",
    "user-shared-line",
    "user-smile-fill",
    "user-smile-line",
    "user-star-fill",
    "user-star-line",
    "user-unfollow-fill",
    "user-unfollow-line",
    "user-voice-fill",
    "user-voice-line",
    "women-fill",
    "women-line",
    "blaze-fill",
    "blaze-line",
    "celsius-fill",
    "celsius-line",
    "cloud-windy-fill",
    "cloud-windy-line",
    "cloudy-2-fill",
    "cloudy-2-line",
    "cloudy-fill",
    "cloudy-line",
    "drizzle-fill",
    "drizzle-line",
    "earthquake-fill",
    "earthquake-line",
    "fire-fill",
    "fire-line",
    "flashlight-fill",
    "flashlight-line",
    "flood-fill",
    "flood-line",
    "foggy-fill",
    "foggy-line",
    "hail-fill",
    "hail-line",
    "haze-2-fill",
    "haze-2-line",
    "haze-fill",
    "haze-line",
    "heavy-showers-fill",
    "heavy-showers-line",
    "meteor-fill",
    "meteor-line",
    "mist-fill",
    "mist-line",
    "moon-clear-fill",
    "moon-clear-line",
    "moon-cloudy-fill",
    "moon-cloudy-line",
    "moon-fill",
    "moon-foggy-fill",
    "moon-foggy-line",
    "moon-line"
]



