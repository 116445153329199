import axios from 'axios';

import {
    LOAD_RECAP_FAIL,
    LOAD_RECAP_LOADING, LOAD_RECAP_SUCCESS,
    LOAD_SHOP_FAIL,
    LOAD_SHOP_LOADING, LOAD_SHOP_SUCCESS,
    STORE_SHOP_FAIL,
    STORE_SHOP_LOADING, STORE_SHOP_SUCCESS

} from './type';
import {SERVER_URL} from "../../helpers/utils";
import Cookies from "js-cookie";



export const storeShop = (data) => async (dispatch) => {
    dispatch({
        type: STORE_SHOP_LOADING,
    });
    try {
        const response = await axios.post(SERVER_URL+'register/shop',data);
        if (response.data.succeed){
            dispatch({
                type: STORE_SHOP_SUCCESS,
                payload: { key : response.data.key,message : response.data.message},
            });
        }else{
            dispatch({
                type: STORE_SHOP_FAIL,
                payload: { error: response?.data.message},
            });
        }


    } catch (err) {
        dispatch({
            type: STORE_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};

export const loadShopsList = () => async (dispatch) => {
    dispatch({
        type: LOAD_SHOP_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'shop/list');
        if (response.data.succeed){
            dispatch({
                type: LOAD_SHOP_SUCCESS,
                payload: { list : response.data.list},
            });
        }else{
            dispatch({
                type: LOAD_SHOP_FAIL,
                payload: { error: response?.data.message},
            });
        }


    } catch (err) {
        dispatch({
            type: STORE_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};

export const loadRecapContent = () => async (dispatch) => {
    dispatch({
        type: LOAD_RECAP_LOADING,
    });
    try {
        const response = await axios.post(SERVER_URL+'shop/recap',{shop_key : Cookies.get('shop_key')});

        if (response.data.succeed){
            dispatch({
                type: LOAD_RECAP_SUCCESS,
                payload: { transaction : response.data.transaction},
            });
        }else{
            dispatch({
                type: LOAD_RECAP_FAIL,
                payload: { error: response?.data.message},
            });
        }


    } catch (err) {
        dispatch({
            type: STORE_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};




