import React from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../../layouts/front/Layout";
import {APPNAME, TECHNCTRL, TECHNCTRL_LINK} from "../../../helpers/utils";

const Policies = ({t}) => {
    document.title = APPNAME+" :: "+t('PrivacyPolicy')
    return (
        <Layout>
            <section className="sign-in-section p-t-150 p-b-2">
                <div className="container-fluid ">
                    <h1 className="text-center" style={{fontSize : "70px"}}>{t('PrivacyPolicy')}</h1>
                    <div className="row justify-content-center" style={{marginTop : "50px"}}>
                        <div className="col-md-8 mb-90">
                          <p>
                              {" "}{" "}{" "} La révolution numérique sur le continent africain a généré de vastes préoccupations liées à la sécurité et à la protection des données à caractère personnel,
                              se transformant en un défi majeur pour les États. Les données à caractère personnel deviennent aujourd'hui aussi précieuses que le pétrole peut l'être,
                              avec un fort potentiel économique qui grandit chaque année. C'est dans cette optique que le Togo s’est doté d’une loi protectrice des données.
                              Il s’agit de la loi N° 2019-014 du 29 octobre 2019 relative à la protection des données à caractère personnel.
                          </p>

                            <p>
                                {" "}{" "}{" "}  C’est à travers cette loi que le Togo réglemente la collecte, le traitement, la transmission,
                                le stockage, l'usage et la protection des données à caractère personnel. Afin de garantir une confiance
                                numérique durable entre les citoyens et les fournisseurs de services numériques, il s’avère essentiel que la vie privée de ceux-ci,
                                et tout particulièrement leurs données personnelles, soient protégées. Le texte adopté a pour objectif de garantir que tout traitement
                                de données personnelles ne porte atteinte aux libertés et aux droits fondamentaux des personnes physiques.
                                Elle s'applique à toute personne (physique ou morale), toute organisation (publique ou privée),
                                qui collecte, traite, transmet, stock et utilise des données à caractère personnel pour son compte ou non.
                                Les données à caractère personnel sont traitées de manière confidentielle et protégée conformément aux dispositions
                                du chapitre IV de loi et plus précisément en ses articles 51 et 52. Le responsable du traitement des données
                                est tenu de prendre toute précaution utile au regard de la nature des données et, notamment, pour empêcher qu’elles soient déformées,
                                endommagées, ou que des tiers non autorisés y aient accès. Aussi, le traitement des données à caractère personnel est strictement confidentiel.
                                Il est effectué exclusivement par des personnes qui agissent sous l’autorité du responsable du traitement et seulement sur ses instructions.
                            </p>

                            <p>
                                Le site web <b className="text-primary">https//kiiako.io</b> est détenu par <a href={TECHNCTRL_LINK} target="_blank" className="text-black fs-16"><b>{TECHNCTRL}</b></a>.
                            </p>

                            <p>
                                Nous avons adopté cette politique de confidentialité, qui détermine la manière dont nous traitons les informations collectées par la plateforme KIIAKO,
                                qui fournit également les raisons pour lesquelles nous devons collecter certaines données personnelles vous concernant.
                                Par conséquent, vous devez lire cette politique de confidentialité avant d'utiliser les services de KIIAKO.
                            </p>

                            <p>
                                Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité et la sécurité.
                            </p>

                            <h4>Les informations personnelles que nous collectons :</h4>

                            <p>
                                Lorsque vous visitez le site https://kiiako.io, nous recueillons automatiquement certaines informations sur votre appareil,
                                notamment des informations sur votre navigateur web, votre adresse IP, votre localisation, votre fuseau horaire et certains des cookies installés sur votre appareil.
                                En outre, lorsque vous naviguez sur {APPNAME}, nous recueillons des informations sur les pages web ou les produits individuels que vous consultez,
                                sur le site web ou les termes de recherche qui vous ont renvoyé au site et sur la manière dont vous interagissez avec le site.
                                Nous désignons ces informations collectées automatiquement par le terme "informations sur les appareils".
                                En outre, nous pourrions collecter les données personnelles que vous nous fournissez
                                (y compris, mais sans s'y limiter, le nom, le prénom, l'adresse, les informations de paiement, etc.)
                                lors de l'inscription afin de pouvoir exécuter le contrat. Nous demandons votre autorisation avant d’accéder à d’autres applications de votre canal,
                                à savoir, le micro, la caméra et aussi la géolocalisation.
                            </p>

                            <h4>Pourquoi traitons-nous vos données ?</h4>

                            <p>
                                Notre priorité absolue est la sécurité des données des clients et, à ce titre,
                                nous ne pouvons traiter que des données minimales sur les utilisateurs,
                                uniquement dans la mesure où cela est absolument nécessaire pour maintenir le site web.
                                Les informations collectées automatiquement sont utilisées seulement pour identifier
                                les cas potentiels d'abus et établir des informations statistiques concernant l'utilisation de nos services.
                                Ces informations statistiques ne sont pas autrement agrégées de manière à identifier un utilisateur particulier du système.
                            </p>

                            <p>
                                Vous pouvez visiter le site web sans nous dire qui vous êtes, ni révéler d'informations,
                                par lesquelles quelqu'un pourrait vous identifier comme un individu spécifique et identifiable.
                                Toutefois, si vous souhaitez utiliser certaines fonctionnalités du site web, ou si vous souhaitez recevoir
                                notre lettre d'information ou fournir d'autres détails en remplissant un formulaire,
                                vous pouvez nous fournir des données personnelles, telles que votre e-mail,
                                votre prénom, votre nom, votre ville de résidence, votre organisation, votre numéro de téléphone.
                                Vous pouvez choisir de ne pas nous fournir vos données personnelles. Cependant,
                                il se peut alors que vous ne puissiez pas profiter de certaines fonctionnalités du site web.
                                Les utilisateurs qui ne savent pas quelles informations sont obligatoires sont invités à nous contacter via <a href="mailto:contact@technctrl.com" className="text-primary">contact@technctrl.com</a>.
                            </p>

                            <h4>Vos droits :</h4>
                            <i>Vous disposez des droits suivants liés à vos données personnelles :</i>

                            <ul>
                                <br/>
                                <li> Le droit d'être informé.</li>
                                <br/>
                                <li> Le droit d'accès.</li>
                                <br/>
                                <li>Le droit de rectification.</li>
                                <br/>
                                <li>Le droit à l'effacement.</li>
                                <br/>
                                <li>Le droit de restreindre le traitement.</li>
                                <br/>
                                <li>Le droit à la portabilité des données.</li>
                                <br/>
                                <li>Le droit d'opposition.</li>
                                <br/>
                                <li>Les droits relatifs à la prise de décision automatisée et au profilage.</li>
                                <br/>
                            </ul>

                            <br/>
                            <p>Si vous souhaitez exercer ce droit, veuillez nous contacter via les coordonnées ci-dessous.</p>
                            <p>En outre, nous notons que nous traitons vos informations afin d'exécuter les contrats que nous pourrions avoir avec vous
                                (par exemple, si vous passez une commande par le biais du site), ou autrement pour poursuivre nos intérêts commerciaux légitimes énumérés ci-dessus.</p>

                            <h4>Liens vers d'autres sites web :</h4>
                            <br/>
                            <p>Notre site web peut contenir des liens vers d'autres sites web qui ne sont pas détenus ou contrôlés par nous.
                                Sachez que nous ne sommes pas responsables de ces autres sites web ou des pratiques de confidentialité des tiers.
                                Nous vous encourageons à être attentif lorsque vous quittez notre site web et à lire les déclarations
                                de confidentialité de chaque site web susceptible de collecter des informations personnelles.</p>

                            <h4>Sécurité de l'information :</h4>
                            <p>
                                Nous sécurisons les informations que vous fournissez sur des serveurs informatiques dans un environnement contrôlé et sécurisé,
                                protégé contre tout accès, utilisation ou divulgation non autorisés. Nous conservons des garanties administratives,
                                techniques et physiques raisonnables pour nous protéger contre tout accès, utilisation, modification et divulgation non autorisés
                                des données personnelles sous son contrôle et sa garde. Toutefois, aucune transmission de données sur Internet ou sur un réseau
                                sans fil ne peut être garantie.
                            </p>

                            <h4>Divulgation légale :</h4>
                            <p>
                                Nous divulguerons toute information que nous collectons, utilisons ou recevons si la loi l'exige ou l'autorise,
                                par exemple pour nous conformer à une citation à comparaître ou à une procédure judiciaire similaire,
                                et lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits,
                                votre sécurité ou celle d'autrui, enquêter sur une fraude ou répondre à une demande du gouvernement.
                            </p>

                            <h4>Informations de contact :</h4>

                            <p>
                                Si vous souhaitez nous contacter pour comprendre davantage la présente politique
                                ou si vous souhaitez nous contacter concernant toute question relative aux droits individuels et
                                à vos informations personnelles, vous pouvez envoyer un courriel à <a
                                href="mailto:contact@technctrl.com">contact@technctrl.com</a>.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default withTranslation() (Policies);
