import {PayPalButtons } from "@paypal/react-paypal-js";
import React from 'react';

const  PayPal = ({total,succeedPayment}) => {
    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        amount: {
                            value: total,
                            currency : "USD"
                        },
                    },
                ],
            })
            .then((orderID) => {
                return orderID;
            });
    }

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            console.log(details)
            succeedPayment(details,'PayPal')
        });
    }

    const  onError = (err) =>{
        console.log( err.toString())
    }


    return (
        <PayPalButtons
            style={{ color: "gold", label: "pay", height: 40, }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
            forceReRender={[total]}
        />
    );
}

export default PayPal;
