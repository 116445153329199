import React, {useEffect, useRef, useState} from 'react';
import {attachTokenToHeaders, CLIENT, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import axios from "axios";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Category1 = ({block,t,toggleHightLight,isEditor,customStyle,shop_data}) => {

    const [categories, setCategories] = useState([])
    const [subcategories, setSubcategories] = useState([])

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const category1Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :category1Ref?.current?.offsetTop, left : category1Ref?.current?.offsetLeft, width : category1Ref?.current?.offsetWidth, height : category1Ref?.current?.offsetHeight,nodeName : category1Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        if(shop_data){
            getCategories(shop_data.id)
        }
    }, [shop_data]);


    const getCategories = async (id) => {
        await axios.get(SERVER_URL+"public/categories/"+id).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.categories)
                setSubcategories(response.data.subcategories)
            }
        })
    }



    return (
        <section className="tp-product-category pt-60 pb-15"
                 ref={category1Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
                 style={customStyle}
        >
            <div className="container">
                {
                    isEditor ?
                        <div className="row row-cols-xl-5 row-cols-lg-5 row-cols-md-4">
                            <div className="col">
                                <div className="tp-product-category-item text-center mb-40">
                                    <div className="tp-product-category-thumb fix">
                                        <a href="#">
                                            <img src={CLIENT+"/builder/product/category/product-cat-1.png"} alt="product-category"/>
                                        </a>
                                    </div>
                                    <div className="tp-product-category-content">
                                        <h3 className="tp-product-category-title">
                                            <a href="#">Headphones</a>
                                        </h3>
                                        <p>20 Product</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="tp-product-category-item text-center mb-40">
                                    <div className="tp-product-category-thumb fix">
                                        <a href="#">
                                            <img src={CLIENT+"/builder/product/category/product-cat-2.png"} alt="product-category"/>
                                        </a>
                                    </div>
                                    <div className="tp-product-category-content">
                                        <h3 className="tp-product-category-title">
                                            <a href="#">Mobile Phone</a>
                                        </h3>
                                        <p>25 Product</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="tp-product-category-item text-center mb-40">
                                    <div className="tp-product-category-thumb fix">
                                        <a href="#">
                                            <img src={CLIENT+"/builder/product/category/product-cat-1.png"} alt="product-category"/>
                                        </a>
                                    </div>
                                    <div className="tp-product-category-content">
                                        <h3 className="tp-product-category-title">
                                            <a href="#">CPU Heat Pipes</a>
                                        </h3>
                                        <p>57 Product</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="tp-product-category-item text-center mb-40">
                                    <div className="tp-product-category-thumb fix">
                                        <a href="#">
                                            <img src={CLIENT+"/builder/product/category/product-cat-4.png"} alt="product-category"/>
                                        </a>
                                    </div>
                                    <div className="tp-product-category-content">
                                        <h3 className="tp-product-category-title">
                                            <a href="#">Smart Watch</a>
                                        </h3>
                                        <p>44 Product</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="tp-product-category-item text-center mb-40">
                                    <div className="tp-product-category-thumb fix">
                                        <a href="#">
                                            <img src={CLIENT+"/builder/product/category/product-cat-5.png"} alt="product-category"/>
                                        </a>
                                    </div>
                                    <div className="tp-product-category-content">
                                        <h3 className="tp-product-category-title">
                                            <a href="#">With Bluetooth </a>
                                        </h3>
                                        <p>81 Product</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        :

                        <div className="row row-cols-xl-5 row-cols-lg-5 row-cols-md-4">
                            {
                                categories?.length > 0 ?
                                    <>
                                    {
                                        categories?.slice(0,5).map((category,key) =>
                                            <div className="col" key={key}>
                                                <div className="tp-product-category-item text-center mb-40">
                                                    <div className="tp-product-category-thumb fix">
                                                        <Link to={"/shop/"+category.slug}>
                                                            <img src={`
                                                            ${category.image ? SERVER_IMG_URL+shop_data.shop_key+"/categories/thumb/"+category.image : CLIENT+"/builder/placeholder-image.png"}`} style={{padding : category.image ? '0' : "40px",borderRadius : category.image ? '50%' : "0"}}  alt="product-category"/>
                                                        </Link>
                                                    </div>
                                                    <div className="tp-product-category-content">
                                                        <h3 className="tp-product-category-title">
                                                            <Link to={"/shop/"+category.slug}>{category.category}</Link>
                                                        </h3>
                                                        <p>{category.count??0} {t('Products')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    </>
                                    :
                                   ""
                            }
                        </div>
                }
            </div>
        </section>
    )
}

export default withTranslation()(Category1)
