import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, ROOT, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import Authorized from "../../../../components/Authorized/Authorized";
import {compose} from "redux";
import {connect} from "react-redux";
import avatar from "../../../../assets/admin/images/avatar-blank.png";
import Paginate from "../../../../components/Paginate/Paginate";
import axios from "axios";
import swal from "@sweetalert/with-react";
import {toast} from "react-toastify";
import CreateOrUpdate from "./CreateOrUpdate";
import DelivererDetail from "./DelivererDetail";
import InviteDeliverer from "./InviteDeliverer";
import Authorization from "../../../../components/Authorization";
import PluginAccess from "../../../../components/PluginAccess";

const Deliverer = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('Deliverer')

    const [currentDeliverer,setCurrentDeliverer] = useState([]);
    const [deliverer,setDeliverer] = useState([]);
    const [access,setAccess] = useState([]);

    useEffect(() => {
        getDeliverer(auth.shop?.id)
    },[auth])

    useEffect(() => {
        setCurrentDeliverer(deliverer?.slice(indexOfFirstPost, indexOfLastPost))
    },[deliverer])

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [loading,setLoading] = useState(true)
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])
    const [popOpen,setPopOpen] = useState(false)
    const [inviteOpen, setInviteOpen] = useState(false);


    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);



    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    const getDeliverer = async () => {
        try{
            await axios.get(SERVER_URL+"deliverer",attachTokenToHeaders())
                .then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    setDeliverer(response.data.deliverer)
                    setAccess(response.data.access)
                    setLoading(false)
                }else{
                    setLoading(false)
                }
            })
                .catch((err) => {
                    if (err.response.status === 401){
                        window.location.reload(true)
                    }
                })
        } catch (e) {
            setLoading(false)
            // toast.error(e.message)
        }
    }


    const addDeliverer = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editDeliverer = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }

    const detailDeliverer = (item) => {
        setUpdateData(item)
        setPopOpen(true)
    }


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(deliverer?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteDeliverer'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}deliverer/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed == true) {
                        toast.success(t(response.data.message))
                        getDeliverer(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }


    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledDeliverer') : t('SureToEnabledDeliverer'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}deliverer/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getDeliverer(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentDeliverer(deliverer?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = deliverer.filter(deliver => {
                return deliver.firstname.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentDeliverer(filter)
        }
    }


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(deliverer.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentDeliverer.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListDeliverer'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}deliverer/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getDeliverer(auth.shop.id)
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }


    const inviteDeliverer = async (item) => {
         setInviteOpen(true)
        setUpdateData(item)
    }


    const checkAccess = (id,deliveryId) => {
       const d = access.filter(accs => accs.shop_id === id && accs.deliverId === deliveryId)
        if (d.length > 0 && d[0].statut === 1) {
            return true
        }else{
            return false
        }
    }

    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Deliverer')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Deliverer')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <PluginAccess plugin="delivery" auth={auth} free={true}>
                    <div className="row">
                                <div className="col-lg-12">
                                    <div className="card" id="tasksList">
                                        <div className="card-header border-0">
                                            <div className="d-flex align-items-center">
                                                <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Deliverer')}</h5>
                                                <div className="flex-shrink-0">
                                                    <button className="btn btn-primary add-btn" onClick={event => addDeliverer()}>
                                                        <i className="ri-add-line align-bottom me-1"></i> {t('Delivere')}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body border border-dashed border-end-0 border-start-0">
                                            <div>
                                                <div className="row g-3">
                                                    <div className="col-lg-4">

                                                        {
                                                            isCheck.length > 0 &&

                                                            <>
                                                                {
                                                                    Gloading ?
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                                        :
                                                                        <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="offset-lg-4 col-lg-4">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                                            <i className="ri-search-line search-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-card mb-4">
                                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                                    <thead className="table-light text-muted">
                                                    <tr>
                                                        <th scope="col" style={{width: "50px"}}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       name="selectAll"
                                                                       id="selectAll"
                                                                       onChange={handleSelectAll}
                                                                       checked={isCheckAll}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th className="sort">{t('Country')}</th>
                                                        <th className="sort">{t('Shop')}</th>
                                                        <th className="sort">{t('Avatar')}</th>
                                                        <th className="sort">{t('Lastname')}</th>
                                                        <th className="sort">{t('Firstname')}</th>
                                                        <th className="sort">{t('Email')}</th>
                                                        <th className="sort">{t('Phone')}</th>
                                                        <th className="sort">{t('Verified')}</th>
                                                        <th className="sort">{t('AutoPaid')}</th>
                                                        <th className="sort">Status</th>
                                                        <th className="sort">{t('Action')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                    {
                                                        loading ?
                                                            <tr>
                                                                <td colSpan="12">
                                                                    <p className="text-center">
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            :

                                                            <>
                                                                {
                                                                    currentDeliverer?.length > 0
                                                                        ?
                                                                        <>
                                                                            {
                                                                                Array.from(new Set(currentDeliverer))?.map((deliver,key)=>
                                                                                    <tr key={key}>
                                                                                        <th scope="row">
                                                                                            <div className="form-check">
                                                                                                <input className="form-check-input"
                                                                                                       type="checkbox"
                                                                                                       name={deliver.firstname}
                                                                                                       id={deliver.id}
                                                                                                       onChange={handleClick}
                                                                                                       checked={isCheck.includes(deliver.id)}
                                                                                                />
                                                                                            </div>
                                                                                        </th>
                                                                                        <td>{deliver.country.toUpperCase()}</td>
                                                                                        <td>{deliver.shopName ? deliver.shopName : <b className="badge badge-outline-primary">{ROOT}</b>}</td>
                                                                                        <td>
                                                                                            {
                                                                                                deliver.avatar
                                                                                                    ?
                                                                                                    <div className="avatar-group">
                                                                                            <span className="avatar-group-item">
                                                                                            <img src={`${SERVER_IMG_URL}${auth.shop.shop_key}/deliverer/${deliver.image}`} alt="" className="rounded-circle avatar-xxs"/>
                                                                                            </span>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="avatar-group">
                                                                                            <span className="avatar-group-item">
                                                                                            <img src={avatar} alt="" className="rounded-circle avatar-xxs"/>
                                                                                            </span>
                                                                                                    </div>
                                                                                            }
                                                                                        </td>
                                                                                        <td>{deliver.lastname}</td>
                                                                                        <td>{deliver.firstname}</td>
                                                                                        <td>{deliver.email}</td>
                                                                                        <td>{deliver.phone}</td>
                                                                                        <td>
                                                                                            {deliver.verified == 1 ? <span className="badge badge-soft-success">{t('Approuved')}</span> :  ""}
                                                                                            {deliver.verified == 0 ? <span className="badge badge-soft-primary">{t('Pending')}</span> :  ""}
                                                                                            {deliver.verified == 2 ? <span className="badge badge-soft-danger">{t('Reject')}</span> :  ""}
                                                                                        </td>
                                                                                        <td>
                                                                                            {deliver.autoPaid == 1 ? <span className="badge badge-soft-success">{t('Active')}</span> :  ""}
                                                                                            {deliver.autoPaid != 1 && deliver.autoPaid != 3 ? <span className="badge badge-soft-primary">{t('Pending')}</span> :  ""}
                                                                                            {deliver.autoPaid == 3 ? <span className="badge badge-soft-danger">{t('Disabled')}</span> :  ""}
                                                                                        </td>
                                                                                        <td>
                                                                                            {(deliver.statut == 1)
                                                                                                ? <span className="badge badge-soft-success">{t('Active')}</span>
                                                                                                :  <span className="badge badge-soft-danger">{t('Inactive')}</span>
                                                                                            }
                                                                                        </td>
                                                                                        <td className="priority">
                                                                                            <ul className="list-inline  mb-0">

                                                                                                {
                                                                                                    auth?.shop.id == deliver.shop_id ?

                                                                                                        <>
                                                                                                            <li className="list-inline-item">
                                                                                                                 <span
                                                                                                                     className="remove-item-btn cursor-pointer"
                                                                                                                     onClick={event => detailDeliverer(deliver)}>
                                                                                                                     <i className=" bx bx-message-square-detail align-bottom me-2 text-success"></i>
                                                                                                                 </span>
                                                                                                            </li>

                                                                                                            <li className="list-inline-item">
                                                                                                               <span className="edit-item-btn cursor-pointer" title={t('Disabled')} onClick={event => toggleStatus(deliver.id, 2)}>
                                                                                                                    <i className=" bx bx-x align-bottom me-2 text-dark"></i>
                                                                                                              </span>
                                                                                                            </li>

                                                                                                   <li className="list-inline-item">
                                                                                                     <span
                                                                                                         className="edit-item-btn cursor-pointer" title={t('Edit')}
                                                                                                         onClick={event => editDeliverer(deliver)}>
                                                                                                         <i className="bx bx-edit-alt align-bottom me-2 text-primary"></i>
                                                                                                     </span>
                                                                                                   </li>
                                                                                                            <li className="list-inline-item">
                                                                                                                 <span
                                                                                                                     className="remove-item-btn cursor-pointer" title={t('Delete')}
                                                                                                                     onClick={event => deleteModal(deliver.id)}>
                                                                                                                     <i className="bx bx-trash-alt align-bottom me-2 text-danger"></i>
                                                                                                                 </span>
                                                                                                            </li>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <li className="list-inline-item">
                                                                                                                 <span
                                                                                                                     className="remove-item-btn cursor-pointer" title={t('Detail')}
                                                                                                                     onClick={event => detailDeliverer(deliver)}>
                                                                                                                     <i className=" bx bx-message-square-detail align-bottom me-2 text-success"></i>
                                                                                                                 </span>
                                                                                                            </li>

                                                                                                            {
                                                                                                               !checkAccess( auth?.shop.id,deliver.id) ?
                                                                                                                    <li className="list-inline-item">
                                                                                                                 <span className="remove-item-btn cursor-pointer" title={t('Invite')} onClick={event => inviteDeliverer(deliver)} id={`mailsend-${deliver.id}`}>
                                                                                                                     <i className=" bx bx-transfer-alt align-bottom me-2 text-info"></i>
                                                                                                                 </span>
                                                                                                            </li> : ""
                                                                                                            }

                                                                                                        </>

                                                                                                }

                                                                                            </ul>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </>
                                                                        :
                                                                        <tr>
                                                                            <td colSpan="12">
                                                                                <p className="text-center">{t('EmptyList')}</p>
                                                                            </td>
                                                                        </tr>
                                                                }
                                                            </>
                                                    }

                                                    </tbody>
                                                </table>
                                            </div>

                                            <Paginate
                                                postsPerPage={postsPerPage}
                                                totalPosts={deliverer?.length}
                                                paginate={paginate}
                                                previousPage={previousPage}
                                                nextPage={nextPage}
                                                current={currentPage}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>
                    <CreateOrUpdate open={open} setOpen={setOpen} created={getDeliverer} isEdit={isEdit} updateData={updateData} auth={auth} />
                    <DelivererDetail setOpen={setPopOpen} open={popOpen} data={updateData} auth={auth} created={getDeliverer} />
                    <InviteDeliverer setOpen={setInviteOpen} open={inviteOpen} data={updateData} auth={auth} />
            </PluginAccess>
        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Deliverer));
