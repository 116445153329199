import React from 'react';
import { withTranslation } from 'react-i18next';

const ShareLocationOnMap = ({ location, t }) => {
    const [latitude, longitude] = location
        .replace('[', '')
        .replace(']', '')
        .split(',')
        .map(coord => parseFloat(coord.trim()));

    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const message = `${t('OpenOnGoogleMaps')}: ${googleMapsUrl}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/?text=${encodedMessage}`;

    return (
        <div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                {t('ShareOnWhatsApp')} <i className="ri-whatsapp-fill cursor-pointer text-success"></i>
            </a>
        </div>
    );
};

export default withTranslation()(ShareLocationOnMap);
