import React from 'react';
import {withTranslation} from "react-i18next";

const TrialSuccess = ({t}) => {
    return (
        <div className="finish_container">
            <div className="finish_card">
                <div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin:"0 auto"}}>
                    <i className="checkmark">✓</i>
                </div>
                <h1>{t('Congratulation')}</h1>
                <br/>
                <p>{t('OrderPlaceSuccessFully')}</p>
            </div>
        </div>
    );
}

export default withTranslation()(TrialSuccess)
