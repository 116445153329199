import React from 'react';
import {withTranslation} from "react-i18next";
import './404.css'
import {LINK_CONTACT, LINK_LANDING, LINK_LIST_SHOPS, LINK_LOGIN_STORE} from "../../routes/type";
import {Link} from "react-router-dom";
import {APPNAME} from "../../helpers/utils";

const NotFound = ({t}) => {
    document.title = APPNAME+" :: "+t('NotFound')

    return (
       <div className="error404">
             <div className="error404_top">
                 <h1 className="error404_big_title">404</h1>
                 <div className="error404_top_content">
                     <p className="error404_title">{t('404_title')}</p>
                     <p className="error404_description">{t('404_description')}</p>
                 </div>
             </div>
           <hr/>
           <div className="error404_subcontent">
               <p>{t('404_subtext')}</p>
               <div className="error404_buttons">
                   <Link to={LINK_LANDING}>{t('Home')}</Link>
                   <Link to={LINK_LIST_SHOPS}>{t('Shops')}</Link>
                   <Link to={LINK_LOGIN_STORE}>{t('LoginShop')}</Link>
                   <Link to={LINK_CONTACT}>{t('Contact')}</Link>
               </div>
           </div>
       </div>
    );
}

export default withTranslation()(NotFound);