import React, {useState} from 'react';
import {CLIENT, format_date_complet, SERVER_IMG_URL} from "../../../../../helpers/utils";
import defaulAvatar from "../../../../../assets/admin/images/avatar-blank.png";

const _Card = ({item,auth,shop}) => {



    return (
        <div className="tp-product-details-review-avater d-flex align-items-start">
            <div className="tp-product-details-review-avater-thumb pt-10">
                <a href="#">
                    <img src={item.avatar ?SERVER_IMG_URL+shop.shop_key+"/customers/"+ item.avatar :defaulAvatar}  alt=""/>
                </a>
            </div>
            <div className="tp-product-details-review-avater-content pl-20">
                <div className="tp-product-details-review-avater-rating d-flex align-items-center">
                    {
                        item.rate ?
                            <>
                                {
                                    [...Array(parseInt(item.rate))].map((item,key) => (
                                        <span key={key}><i className="fa-solid fa-star"></i></span>
                                    ))
                                }
                            </>
                            :
                            ""
                    }
                </div>
                <h3 className="tp-product-details-review-avater-title" style={{fontWeight : "bold"}}>{item.firstname} {item.lastname}</h3>
                <span className="tp-product-details-review-avater-meta">{format_date_complet(item?.created_at)} </span>

                <div className="tp-product-details-review-avater-comment">
                    <p>{
                        item.comment
                    }</p>
                </div>
            </div>
        </div>
    )
}

export default _Card;
