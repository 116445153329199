import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal, MiddleLoading, RegLoading} from "../../../../components";
import axios from "axios";
import {bytesToSize, numberWithCommas, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";


const PricingModal = ({open,setOpen,title,data,t,ChoosePricing}) => {

    const [plan, setPlan] = useState(null);
    const [loading, setLoading] = useState(true);
    const [storages,setStorages] = useState([])

    useEffect(() => {
        if (data?.pricing_key) getThisPlan()
    }, [data]);

    const getThisPlan = async () => {
        await axios.get(SERVER_URL+"pricing/single/"+data?.pricing_key).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setStorages(response.data.storage)
                setPlan(response.data.plan)
            }else{
             toast.error(t(response.data.message))
            }
        })
    }


const html = `<b  style="background:${data?.color};border-radius: 3px;text-transform: capitalize;color: #fff;padding: 3px 15px;font-size: 14px">${data?.name}</b>`

    return (
        <CustomModal open={open} setOpen={setOpen} title={title}  size="md">
            <div className="template_showing_content_header">
                <h3 dangerouslySetInnerHTML={{__html: html }}></h3>
                <button className="btn btn-success btn-sm" onClick={event => ChoosePricing(data)}>{t('Choose')}</button>
            </div>
            <br/>
           <div className="row">
               {
                   loading ?
                       <RegLoading />
                       :
                       <table className="table">
                           <tbody>
                           <tr>
                               <td className="price-table-help">{t('Price')}</td>
                               <td><b>{numberWithCommas(data?.price??0)}</b> Frcs</td>
                           </tr>
                           <tr>
                               <td className="price-table-help">{t('Domaine')}</td>
                               <td>{plan?.domaine_name && <i className="fa fa-check text-success"></i>}</td>
                           </tr>
                           <tr>
                               <td className="price-table-help">{t('ThemeCustomizable')}</td>
                               <td>{plan?.theme_customizable ? <i className="fa fa-check text-success"></i> :<i className="fa fa-times text-danger"></i> }</td>
                           </tr>

                           <tr>
                               <td className="price-table-help">{t('MultiLangue')}</td>
                               <td>{plan?.multi_langue ? <i className="fa fa-check text-success"></i> :<i className="fa fa-times text-danger"></i> }</td>
                           </tr>



                           <tr>
                               <td className="price-table-help">{t('FolderSize')}</td>
                               <td>{bytesToSize(storages[parseInt(plan.folder_size) - 1]?.storage)}</td>
                           </tr>

                           <tr>
                               <td className="price-table-help">{t('Sponsorship')}</td>
                               <td>{plan?.sponsor_shipping ? <i className="fa fa-check text-success"></i> :<i className="fa fa-times text-danger"></i> }</td>
                           </tr>

                           <tr>
                               <td className="price-table-help">{t('AccountNumber')}</td>
                               <td>{plan?.account_number}</td>
                           </tr>

                           <tr>
                               <td className="price-table-help">{t('MobileAccess')}</td>
                               <td>{plan?.mobile_access ? <i className="fa fa-check text-success"></i> :<i className="fa fa-times text-danger"></i> }</td>
                           </tr>

                           <tr>
                               <td className="price-table-help">{t('PaymentMethod')}</td>
                               <td>{plan?.payment_method == "none" ? <i className="fa fa-times text-danger"></i> : <i className="fa fa-check text-success"></i>}</td>
                           </tr>

                           <tr>
                               <td className="price-table-help">{t('Support')}</td>
                               <td>{plan?.support_tech ? <i className="fa fa-check text-success"></i> :<i className="fa fa-times text-danger"></i> }</td>
                           </tr>
                           </tbody>
                       </table>
               }
           </div>
        </CustomModal>
    );
}

export default withTranslation()(PricingModal);
