import React, {useEffect} from 'react';
import {withTranslation} from "react-i18next";
import Hero from "./contents/Hero";
import Part1 from "./contents/Part1";
import Part2 from "./contents/Part2";
import Service from "./contents/Service";
import Part3 from "./contents/Part3";
import Pricing from "./contents/Pricing";
import {APPNAME, getUserGeolocation} from "../../../helpers/utils";
import Layout from "../../../layouts/front/Layout";


const  Landing = ({t}) => {
    document.title = APPNAME+" :: "+t('HomeTitle')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('HomeTitle'))
    document.querySelector("meta[name='description']").setAttribute("content", "Vous songez à gérer votre business en ligne ? Configurez votre boutique en ligne entièrement fonctionnel sans aucune connaissance en codage.")

    useEffect(() => {
        getUserGeolocation()
    }, []);


    return (
        <>
            <>
                    <Layout>
                         <Hero />
                          <Part1/>
                          <Part2 />
                          <Service />
                          <Pricing />
                        <Part3 />
                        <a href="#" className="back-to-top" id="scroll-top">
                            <i className="far fa-angle-up"></i>
                        </a>
                    </Layout>
            </>
        </>

    );
}

export default withTranslation()(Landing);
