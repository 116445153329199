import React, {memo} from 'react';
import {useDrag, useDrop} from "react-dnd";
import ItemTypes from "../../../config/config.type";

const SectionCard =  memo(function SectionCard({ el, moveCard, findCard,DeleteSection}) {
    const originalIndex = findCard(el.item.id).index
    const [{ isDragging }, drag] = useDrag(() => ({
            type: ItemTypes.CARD,
            item: { id : el.item.id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item
                const didDrop = monitor.didDrop()
                if (!didDrop) {
                    moveCard(droppedId, originalIndex)
                }
            },
        }),
        [el.item.id, originalIndex, moveCard],
    )
    const [, drop] = useDrop(() => ({
            accept: ItemTypes.CARD,
            hover({ id: draggedId }) {
                if (draggedId !== el.item.id) {
                    const { index: overIndex } = findCard(el.item.id)
                    moveCard(draggedId, overIndex)
                }
            },
        }),
        [findCard, moveCard],
    )
    const style = {
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
        backgroundColor: 'white',
        cursor: 'move',
    }

    return (
        <div className="section-item" ref={(node) => drag(drop(node))}
             style={{ ...style, opacity :  isDragging ? 0.5 : 1}}
        >
            <div className="controls">
                <div className="handle"></div>
                <div className="info">
                    <div className="name">
                        <span>{el.item.name}</span>
                    </div>
                </div>

            </div>
            <div className="" style={{
                right: "0.5rem",
                top: "0.7rem",
                padding: "0.3rem 0.5rem",
                cursor: "pointer",
                position: "absolute",
            }}
             onClick={event => DeleteSection(el.item.id)}
            >
                <span className="delete-btn"  title="Remove section"><i className="la la-trash text-danger"></i></span>
            </div>
        </div>
    );
})

export default SectionCard;
