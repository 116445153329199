import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../../components/SellerLayout";
import { checkCurrentUrlSubdomain, CLIENT, SERVER_URL } from '../../../../../helpers/utils'
import {withTranslation} from "react-i18next";
import Dashboard from "./Dashboard";
import Orders from "./Orders";
import Profil from "./Profil";
import {compose} from "redux";
import {connect} from "react-redux";
import {loadSubMe, logUserout} from "../../../../../store/Subdomain/Oauth/action";
import axios from "axios";
import Address from "./Address";
import SubLoading from "../../../../../components/Loading/SubLoading";
import Support from "./Support";
import { Fade } from 'react-awesome-reveal'

const AdminContent = ({t,logUserout,auth,loadSubMe}) => {

    const [loading, setLoading] = useState(true)
    const [step, setStep] = useState(1);
    const [shop, setShop] = useState({});

    useEffect(()=>{
        checkSubdomain()

    },[])



    const checkSubdomain = async () =>{
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShop(response.data)
            }
        })
    }




    return (
        <>
            {
                loading ?
                      <SubLoading /> :
                      <Fade>
                          <SellerLayout>
                              <section className="profile__area pt-120 pb-120">
                                  <div className="container">
                                      <div className="profile__inner p-relative">
                                          <div className="profile__shape">
                                              <img className="tp-login-shape-1" src={CLIENT+"/builder/login/laptop.png"} alt=""/>
                                              <img className="tp-login-shape-2" src={CLIENT+"/builder/login/man.png"} alt=""/>
                                              <img className="tp-login-shape-3" src={CLIENT+"/builder/login/login-shape-1.png"} alt=""/>
                                              <img className="tp-login-shape-4" src={CLIENT+"/builder/login/login-shape-2.png"} alt=""/>
                                              <img className="tp-login-shape-5" src={CLIENT+"/builder/login/login-shape-3.png"} alt=""/>
                                          </div>
                                          <div className="row">
                                              <div className="col-xxl-3 col-lg-3">
                                                  <div className="profile__tab">
                                                      <nav>
                                                          <div className="nav nav-tabs tp-tab-menu flex-column" >
                                                              <button className={`nav-link ${step === 1 ? "active" : "" }`} style={{padding : '25px',background : step === 1 && shop?.shop?.color+"30",color : step === 1 && shop?.shop?.color}} onClick={event => setStep(1)}>
                                                                  <span><i className="fa-regular fa-user-pen"></i></span>
                                                                  {t('Dashboard')}
                                                              </button>
                                                              <button className={`nav-link ${step === 2 ? "active" : "" }`} style={{padding : '25px',background : step === 2 && shop?.shop?.color+"30",color : step === 2 && shop?.shop?.color}} onClick={event => setStep(2)}>
                                                                  <span><i className="fa-regular fa-shopping-cart"></i></span>
                                                                  {t('Orders')}
                                                              </button>
                                                              {/*<button className={`nav-link ${step === 3 ? "active" : "" }`} style={{padding : '25px',background : step === 3 && shop?.shop?.color+"30",color : step === 3 && shop?.shop?.color}} onClick={event => setStep(3)}>*/}
                                                              {/*    <span><i className="fa-light fa-location-dot"></i></span> {t('Address')}*/}
                                                              {/*</button>*/}
                                                              {/*<button className={`nav-link ${step === 4 ? "active" : "" }`} style={{padding : '25px',background : step === 4 && shop?.shop?.color+"30",color : step === 4 && shop?.shop?.color}} onClick={event => setStep(4)}>*/}
                                                              {/*    <span><i className="fa-light fa-money-bill-1"></i></span>*/}
                                                              {/*    {t('Payments')}*/}
                                                              {/*</button>*/}
                                                              <button className={`nav-link ${step === 5 ? "active" : "" }`} style={{padding : '25px',background : step === 5 && shop?.shop?.color+"30",color : step === 5 && shop?.shop?.color}} onClick={event => setStep(5)}>
                                                                  <span><i className="fa-regular fa-user-headset"></i></span>
                                                                  {t('Support')}
                                                              </button>
                                                              <button className={`nav-link ${step === 6 ? "active" : "" }`} style={{padding : '25px',background : step === 6 && shop?.shop?.color+"30",color : step === 6 && shop?.shop?.color}} onClick={event => setStep(6)}>
                                                                  <span><i className="fa-regular fa-user-pen"></i></span>{t('Profil')}
                                                              </button>
                                                              <span id="marker-vertical" className="tp-tab-line d-none d-sm-inline-block"></span>
                                                          </div>
                                                      </nav>
                                                  </div>
                                              </div>
                                              <div className="col-xxl-9 col-lg-9">
                                                  <div className="profile__tab-content">
                                                      <div className="tab-content">
                                                          <div className={`tab-pane fade ${step === 1 ? "show active" : ""}`}>
                                                              <Dashboard logUserout={logUserout} auth={auth} shop={shop?.shop}  />
                                                          </div>
                                                          <div className={`tab-pane fade ${step === 2 ? "show active" : ""}`}>
                                                              <Orders auth={auth}  />
                                                          </div>
                                                          <div className={`tab-pane fade ${step === 3 ? "show active" : ""}`}>
                                                              <Address auth={auth} />
                                                          </div>
                                                          <div className={`tab-pane fade ${step === 4 ? "show active" : ""}`} >

                                                          </div>
                                                          <div className={`tab-pane fade ${step === 5 ? "show active" : ""}`} >
                                                              <Support t={t} user_id={auth?.user.id} shop_id={shop?.shop.id} />
                                                          </div>
                                                          <div className={`tab-pane fade ${step === 6 ? "show active" : ""}`}>
                                                              <Profil auth={auth} loadSubMe={loadSubMe} setStep={setStep} />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </section>
                          </SellerLayout>
                      </Fade>
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    auth : state.subLogin,
});
export default   compose(connect(mapStateToProps,{logUserout,loadSubMe})) (withTranslation()(AdminContent));
