import React, {useEffect, useState} from 'react';
import {RegLoading} from "../components";
import CustomRender from "./config/config.block";

const Preview = ({data,isEditor}) => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {setLoading(false)},2000)
    }, [data]);

    return (
        <>
            {
                loading ?
                    <RegLoading /> :
                    <>
                        {data.map(({item}, index) => (
                            <div key={index}>
                                <CustomRender block={item} isEditor={isEditor}  />
                            </div>
                        ))}
                    </>
            }
        </>
    )
}

export default Preview;
