import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, database_date, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,auth}) => {

    const [loading,setLoading] = useState(false)



    const validationSchema = Yup.object().shape({
        type: Yup.string().required(t('InputRequired')),
        period: Yup.string().required(t('InputRequired')),
        nameEN: Yup.string().required(t('InputRequired')),
        nameFR: Yup.string().required(t('InputRequired')),
        en: Yup.string().required(t('InputRequired')),
        fr: Yup.string().required(t('InputRequired')),
        price: Yup.number().required(t('InputRequired')),
        expression: Yup.string().required(t('InputRequired'))
    });

    const initialValues = {
        type: isEdit ? updateData.type : '',
        period: isEdit ? updateData.period : '',
        nameEN: isEdit ? updateData.nameEN : '',
        nameFR: isEdit ? updateData.nameFR : '',
        en: isEdit ? updateData.en : '',
        fr: isEdit ? updateData.fr : '',
        price: isEdit ? updateData.price : '',
        expression: isEdit ? updateData.expression : '',
        features: isEdit ? JSON.parse(updateData.features) : [{fr : '', en : ''}],
    }


    const handleSubmit = async (values) => {
        setLoading(true)
        if (isEdit){
            await axios.put(SERVER_URL+"admin/plugin/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"admin/plugin/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Plugin')}</> : <>{t('Edit')} {t('Plugin')}</>} size="xl">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched,values }) =>
                    <Form>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="col-lg-12">
                                    <label htmlFor="type" className="form-label">{t('Type')} <b
                                        className="text-danger">*</b></label>
                                    <Field as="select" className="form-select form-control-lg" id="type" name="type">
                                        <option value="">-</option>
                                        <option value="1">Extension</option>
                                        <option value="2">Module</option>
                                    </Field>
                                    {errors.type && touched.type ? (
                                        <div className='text-danger'>{errors.type}</div>
                                    ) : null}
                                </div>

                                <div className="col-lg-12">
                                    <br/>
                                    <label htmlFor="expression" className="form-label"> Expression <b className="text-danger">*</b></label>
                                    <Field className="form-control form-control-lg" id="expression" name="expression"/>
                                    {errors.expression && touched.expression ? (
                                        <div className='text-danger'>{errors.expression}</div>
                                    ) : null}
                                </div>
                                <div className="col-lg-12">
                                    <br/>
                                    <label htmlFor="nameFR" className="form-label">{t('Name')} Français <b
                                        className="text-danger">*</b></label>
                                    <Field className="form-control form-control-lg" id="nameFR" name="nameFR"/>
                                    {errors.nameFR && touched.nameFR ? (
                                        <div className='text-danger'>{errors.nameFR}</div>
                                    ) : null}
                                </div>
                                <div className="col-lg-12">
                                    <br/>
                                    <label htmlFor="nameEN" className="form-label">{t('Name')} English <b
                                        className="text-danger">*</b></label>
                                    <Field className="form-control form-control-lg" id="nameEN" name="nameEN"/>
                                    {errors.nameEN && touched.nameEN ? (
                                        <div className='text-danger'>{errors.nameEN}</div>
                                    ) : null}
                                </div>
                                <div className="col-lg-12">
                                    <br/>
                                    <label htmlFor="price" className="form-label">{t('Price')} <b
                                        className="text-danger">*</b></label>
                                    <Field type="number" className="form-control form-control-lg" id="price"
                                           name="price"/>
                                    {errors.price && touched.price ? (
                                        <div className='text-danger'>{errors.price}</div>
                                    ) : null}
                                </div>


                                <div className="col-lg-12">
                                    <br/>
                                    <label htmlFor="period" className="form-label">{t('Period')} <b
                                        className="text-danger">*</b></label>
                                    <Field as="select" className="form-select form-control-lg" id="period"
                                           name="period">
                                        <option value="">-</option>
                                        <option value="Month">{t("Month")}</option>
                                        <option value="Year">{t("Year")}</option>
                                    </Field>
                                    {errors.period && touched.period ? (
                                        <div className='text-danger'>{errors.period}</div>
                                    ) : null}
                                </div>

                            </div>
                            <div className="col-lg-8" style={{borderLeft: "1px solid #ccc"}}>
                                <h5>{t('Description')} </h5>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label htmlFor="fr" className="form-label">Français <b
                                            className="text-danger">*</b></label>
                                        <Field as="textarea" className="form-control" id="fr" name="fr"/>
                                        {errors.fr && touched.fr ? (
                                            <div className='text-danger'>{errors.fr}</div>
                                        ) : null}
                                    </div>

                                    <div  className="col-lg-6">
                                        <label htmlFor="en" className="form-label">English <b className="text-danger">*</b></label>
                                        <Field as="textarea"  className="form-control" id="en" name="en"/>
                                        {errors.en && touched.en ? (
                                            <div className='text-danger'>{errors.en}</div>
                                        ) : null}
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <h5>{t('Features')}  <b className="text-danger">*</b></h5>
                                        <FieldArray
                                            name="features"
                                        >
                                            {({push,remove,insert}) => (
                                                <div>
                                                    {
                                                        values?.features ?
                                                            <div>
                                                                {values?.features?.map((_,index) => (
                                                                    <div key={index} className="row mb-2">
                                                                        <div className="col-lg-6">
                                                                            <Field name={`features.${index}.fr`} className="form-control form-control-lg"  />
                                                                        </div>
                                                                        <div className="col-lg-5">
                                                                            <Field name={`features.${index}.en`} className="form-control form-control-lg" />
                                                                        </div>
                                                                        <div className="col-lg-1">
                                                                            <button type="button" className="btn  btn-danger" onClick={() => remove(index)}>-</button>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                <button type="button" className="mt-2  btn  btn-success" onClick={() => push({ fr: '', en: '' })}>{t('AddFeatures')}</button>

                                                            </div>
                                                            :
                                                            <div>

                                                            </div>
                                                    }
                                                </div>
                                            )}
                                        </FieldArray>
                                        {errors.features && touched.features ? (
                                            <div className='text-danger'>{errors.features}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-8 pt-3">
                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                            </div>
                            <div className="col-lg-4 pt-3">
                                <button className="btn btn-success btn-block" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>
        </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)
