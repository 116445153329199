import React, {useEffect, useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {toast} from "react-toastify";
import axios from "axios";
import { attachTokenToHeaders, Capitalize, SERVER_URL } from '../../../../helpers/utils'



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,module}) => {

    useEffect(() => {
     setTimeout(() => {
        if (open) {
            createEmpty(module)
        }
     },1000)
    }, [open]);


    const createEmpty = (modules) => {
        const PermissionData = []
        modules.map((module) => {
            PermissionData.push({
                name:module.name,
                permissions: {
                    show : false,
                    create : false,
                    update : false,
                    action : false,
                    delete : false,
                }
            })
        })

        setpermissions(PermissionData)
    }

    const [loading,setLoading] = useState(false)
    const [name,setName] = useState("")
    const [permissions,setpermissions] = useState([])


    const checkedOne = (name,event,type) => {
        const NewPerm = permissions
        NewPerm.forEach((element, index) => {
            if (element.name === name) {
                element.permissions[type] = event
            }
        });

        setpermissions(NewPerm)

    }

    const checkedALl = (name,event = true) => {
        checkedOne(name,event,'show')
        checkedOne(name,event,'create')
        checkedOne(name,event,'update')
        checkedOne(name,event,'delete')
        checkedOne(name,event,'action')


        document.getElementById(name+".show").checked  = event
        document.getElementById(name+".create").checked = event
        document.getElementById(name+".update").checked = event
        document.getElementById(name+".action").checked = event
        document.getElementById(name+".delete").checked  = event
        document.getElementById(name+".all").checked  = event

    }

    const submitPermissions = async () => {
        if (!permissions.length){
            toast.error(t('ChooseModulePermissions'))
        }
        else if (name === ""){
            toast.error(t('PermissionNameRequired'))
        }

        else{

            const data = {
                permissions : JSON.stringify(permissions),
                name : name
            }

            setLoading(true)

            if (isEdit){
                await axios.post(SERVER_URL+"admin/permissions/edit/"+updateData.id,data,attachTokenToHeaders()).then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setOpen(false)
                        created()
                    }else{
                        toast.error(t(response.data.message))
                    }
                })

            }
            else{
                await axios.post(SERVER_URL+"admin/permissions/add",data,attachTokenToHeaders()).then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setOpen(false)
                        created()
                    }else{
                        toast.error(t(response.data.message))
                    }
                })

            }
        }
    }




    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Permissions')}</> : <>{t('Edit')} {t('Permissions')}</>} size="xl">
             <div className="row">
                 <div className="offset-lg-10 col-lg-2 text-right">
                     <button className="btn btn-success" disabled={loading} onClick={event => submitPermissions()}>
                         {
                             loading ?
                                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                 :
                                 <span> {isEdit ? t('Edit') : t('Submit')}</span>
                         }

                     </button>
                 </div>
             </div>
            <br/>
            <div className="row">

                <div className="col-lg-12">
                    <div className="form-group">
                        <label htmlFor="">{t('Name')}</label>
                        <input type="text" className="front" name="name" value={name} onChange={event => setName(event.target.value)} />
                    </div>
                </div>

                <table className="table  table-striped ">
                    <thead>
                    <tr>
                        <th>Modules</th>
                        <th>{t('Liste')}</th>
                        <th>{t('Create')}</th>
                        <th>{t('Update')}</th>
                        <th>{t('Delete')}</th>
                        <th>{t('Actions')}</th>
                        <th>{t('All')}</th>
                    </tr>
                    </thead>
                    <tbody id="permissions">
                    {
                        module?.length > 0 ?
                            <>
                                {
                                    module?.map((mod,key)=>
                                        <tr key={key}>
                                            <td>{t(Capitalize(mod.name))}</td>

                                            <td>
                                                <div className="form-check form-switch" dir="ltr">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={`${mod.name}.show`} defaultChecked={false} onClick={event => checkedOne(mod.name,event.target.checked,'show')} />
                                                    <label className="form-check-label"
                                                           htmlFor={`${mod.name}.show`}></label>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="form-check form-switch" dir="ltr">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={`${mod.name}.create`} defaultChecked={false} onClick={event => checkedOne(mod.name,event.target.checked,'create')}/>
                                                    <label className="form-check-label"
                                                           htmlFor={`${mod.name}.create`}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch" dir="ltr">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={`${mod.name}.update`} defaultChecked={false} onClick={event => checkedOne(mod.name,event.target.checked,'update') }/>
                                                    <label className="form-check-label"
                                                           htmlFor={`${mod.name}.update`}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch" dir="ltr">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={`${mod.name}.delete`} defaultChecked={false} onClick={event => checkedOne(mod.name,event.target.checked,'delete')} />
                                                    <label className="form-check-label"
                                                           htmlFor={`delete-${mod.name}`}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch" dir="ltr">
                                                    <input type="checkbox" className="form-check-input"
                                                           id={`${mod.name}.action`} defaultChecked={false}  onClick={event => checkedOne(mod.name,event.target.checked,'action')} />
                                                    <label className="form-check-label"
                                                           htmlFor={`${mod.name}.action`}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch" dir="ltr">
                                                    <input type="checkbox" className="form-check-input allchecked" id={`${mod.name}.all`}  onClick={event => checkedALl(mod.name,event.target.checked)}/>
                                                    <label className="form-check-label" htmlFor={`${mod.name}.all`}></label>
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                }
                            </>
                            :

                            <tr>
                                <td></td>
                            </tr>
                    }


                    </tbody>
                </table>

            </div>
        </CustomModal>
    );
}





export default  withTranslation() (CreateOrUpdate)
