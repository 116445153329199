import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {AddCart} from "../../../../store/Subdomain/cart/action";
import {CalculateDiscount} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import TrialEmpty from "../template/blocs/TrialEmpty";


const  TrialWishlist = ({t,AddCart}) => {

    const [wishlist, setWishlist] = useState([]);


    useEffect(() => {
        getWishlist()
    }, []);



    const getWishlist = () => {
      setWishlist(JSON.parse(localStorage.getItem('wishlist')))
    }

    const addToCart = (item) => {
        AddCart({
            id:item.id,
            qty : 1,
            category : item.category,
            picture : item.picture,
            name:item.title,
            price:CalculateDiscount(item?.price,item?.discount)
        })
        removeWish(item)

        toast.success(t("AddToCart"), {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    }


    const removeWish = (item) => {
       const data = []

         for (let k = 0; k < wishlist.length; k++){
            if ( wishlist[k].id !== item.id){
                data.push(wishlist[k])
            }
         }
         localStorage.setItem('wishlist',JSON.stringify(data))

        getWishlist()


    }

    const EmptyWishlist = () => {
        localStorage.removeItem('wishlist')
        getWishlist()
    }

    return (
        <div className="" >

            <div className="u-s-p-b-60 u-s-p-t-90">
                <div className="section__intro u-s-m-b-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section__text-wrap">
                                    <h1 className="section__heading u-c-secondary">{t('WishList')}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section__content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">

                                {
                                    wishlist?.length > 0 ?

                                        <>
                                            {
                                                wishlist?.map((wish,key) =>

                                                        <div key={key} className="w-r u-s-m-b-30">
                                                            <div className="w-r__container">
                                                                <div className="w-r__wrap-1">
                                                                    <div className="w-r__img-wrap">

                                                                        <img className="u-img-fluid" src={wish.picture} alt=""/></div>
                                                                    <div className="w-r__info">
                                                                        <span className="w-r__name">
                                                                            <a href="product-detail.html">{wish.title}</a>
                                                                        </span>
                                                                         <span className="w-r__category">
                                                                            <a href="shop-side-version-2.html">{wish.category}</a>
                                                                         </span>
                                                                         <span className="w-r__price"> {wish.price} Frcs
                                                                             {wish.discount > 0 &&  <span className="w-r__discount">{(wish.price * wish.discount)/100} Frcs</span>}
                                                                         </span>
                                                                    </div>
                                                                </div>
                                                                <div className="w-r__wrap-2">

                                                                    <span className="w-r__link btn--e-brand-b-2 cursor-pointer"  onClick={event => addToCart(wish)}>{t('AddNewToCart')}</span>

                                                                    <Link className="w-r__link btn--e-transparent-platinum-b-2"  to={`/product/detail/${wish.hash_key}`}>{t('See')}</Link>

                                                                    <span className="w-r__link btn--e-transparent-platinum-b-2 cursor-pointer" onClick={event => removeWish(wish)}>{t('Delete')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            }
                                        </>
                                        :

                                        <h3 className="text-center">{t('EmptyList')} <br/></h3>
                                }


                            </div>
                            <div className="col-lg-12">
                                <div className="route-box">
                                    <div className="route-box__g">
                                        <Link className="route-box__link"  to="/products" ><i className="fas fa-long-arrow-alt-left"></i><span>{t('Shop')}</span></Link></div>

                                    {
                                        wishlist?.length > 0 ?  <div className="route-box__g">

                                            <span className="route-box__link cursor-pointer" onClick={event => EmptyWishlist()}><i className="fas fa-trash"></i><span>{t('Emptied')}</span></span>

                                        </div> : null }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}


const mapStateToProps = (state) => ({
    auth: state.subLogin,
    cart : state.cart,
});

export default compose(withRouter, connect(mapStateToProps, {AddCart})) (withTranslation()(TrialWishlist))
