import React from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';

const CustomModal = ({open,setOpen,title,size,children,isHtml,centered = false,fullscreen,header = true}) => {
    return (
        <Modal isOpen={open} toggle={() => setOpen(false)} backdrop="static" size={size} centered={centered} fullscreen={fullscreen}>
            {header && <ModalHeader className='text-center text-uppercase' toggle={() => setOpen(false)}>{isHtml ? <span dangerouslySetInnerHTML={{__html: title }}/>  : title}</ModalHeader>}
            <ModalBody className="form-modal">
                {children}
            </ModalBody>
        </Modal>
    );
}

CustomModal.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CustomModal;
