import React, {useEffect} from 'react';
import {BUILDER} from "../../helpers/utils";
import logo from "../../assets/logo.png"
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import swal from "@sweetalert/with-react";

const TopBar = ({
                    t,
                    auth,
                    toggleSides,
                    setDevice,
                    preview,
                    setPreview,
                    screenshot,
                    saveTemplate,
                    setElements,
                    device,
                    toggleNavigator,
                     elements
                }) => {

const history = useHistory()


    useEffect(() => {

    }, []);


    const fullscreen = () => {
        const elem = document.body
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    const gotBack = () => {
        swal({
            title : t('Exit'),
            text : t('SureToQuitEditor'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check)  history.goBack()
        });


    }


    const goToPreview = () => {
        localStorage.setItem('themepreview',JSON.stringify(elements))
         history.push('/preview/theme')
    }




    const eraseCanvas = () => {
        setElements([])
    }


    return (
        <div id="top-panel" className="bg-light">
            <div className="btn-group float-start" role="group">
                <h3 className="pl-10 pt-10">K-Builder</h3>
            </div>


            <div className="btn-group me-3" role="group" id="control-panel">
                <button className="btn btn-light" title="Toggle left column" onClick={event => toggleSides('left')}>
                    <i className="ri-layout-5-line"></i>
                </button>

                {
                    auth?.me.role_id == 3 &&
                    <button className="btn btn-light" title={t('Screenshot')} onClick={event => screenshot()}
                            id="designer-mode-btn" data-bs-toggle="button" aria-pressed="false"
                            data-vvveb-action="setDesignerMode">
                        <i className="bx bx-camera"></i>
                    </button>
                }

                <button className="btn btn-light" title={t('EraseCanvas')} onClick={event => eraseCanvas()}>
                    <i className="ri-eraser-line"></i>
                </button>


                <button className="btn btn-light" title={t("Preview")} onClick={event =>  auth?.me.role_id !== 3 ? setPreview(!preview) :  goToPreview()}>
                    {preview ? <i className="ri-eye-off-fill"></i> : <i className="ri-eye-fill"></i>}
                </button>

                <button className="btn btn-light" title={t('Fullscreen') + " (F11)"} onClick={event => fullscreen()}
                        id="fullscreen-btn" data-bs-toggle="button" aria-pressed="false">
                    <i className="ri-fullscreen-fill"></i>
                </button>

                <button className="btn btn-light" title={t('ToggleNavigator')} onClick={event => toggleNavigator()}
                        id="fullscreen-btn" data-bs-toggle="button" aria-pressed="false" >
                    <i className="bx bx-layer"></i>
                </button>

            </div>


            <div className="btn-group me-3 float-end" role="group" id="actions">
                <button className="btn btn-success btn-sm" style={{marginTop : "7px"}} title={t('Save')} id="save-btn"
                        onClick={event => saveTemplate()}>
                    <small> {t('SaveProgression')}</small>
                </button>

                <button className="btn btn-danger btn-sm ml-1 mt-10" style={{marginTop : "7px"}} title={t('Close')}  onClick={event => gotBack()}>
                   <small> {t('Close')}</small>
                </button>
            </div>


            <div className="float-end me-3" id="preview-panel">

                <div className="btn-group responsive-btns" role="group">


                    <button id="mobile-view" data-view="mobile" className={device == "mobile" ? "btn btn-primary" : "btn btn-light"} title={t('Mobile')} onClick={event => setDevice("mobile")}>
                        <i className="la la-mobile"></i>
                    </button>

                    <button id="tablet-view"  data-view="tablet" className={device == "tablet" ? "btn btn-primary" : "btn btn-light"} title={t('Tablet')} onClick={event => setDevice("tablet")}>
                        <i className="la la-tablet"></i>
                    </button>

                    <button id="desktop-view"  data-view="" className={device == "desktop" ? "btn btn-primary" : "btn btn-light"}  title={t('Desktop')}  onClick={event => setDevice("desktop")}>
                        <i className="la la-laptop"></i>
                    </button>

                </div>

            </div>

        </div>
    );
}

export default withTranslation()(TopBar);
