import React, {useState} from 'react';
import {CustomModal, MiddleLoading} from "../../../../../components";
import {attachTokenToHeaders, Capitalize, SERVER_URL} from "../../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";

const ChooseChannel = ({open,setOpen,data,t,shop_key,created}) => {

    const [loading, setLoading] = useState(false);

    const chooseChannel = async (item) => {
        setLoading(true)
        await axios.post(SERVER_URL+"store/slack/save/channels/"+shop_key,{channel : item.id},attachTokenToHeaders())
            .then((response) =>{
                setLoading(false)
                if (response.data.succeed){
                   toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })
    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={t('ChooseSlackChannel')} size="md" >
            {
                loading ?

                    <MiddleLoading />
                    :

                    <div className="row">
                        {
                            data?.length > 0
                                ?
                                <div className="row">
                                    {
                                        data?.map((item,key) =>

                                            <div className="col-lg-12">
                                                <label className="option__upturn" style={{marginLeft : "10px"}} htmlFor="opt_1" key={key}>
                                                    <p className="option--description" style={{fontSize : "14px",fontWeight : "200"}}> #{Capitalize(item.name)}</p>
                                                    <p className="option--cost"></p>
                                                    <input type="radio"  defaultChecked={false}  id="opt_1" name="upturn" value={item.id} onChange={event => chooseChannel(item)}  />
                                                </label>
                                            </div>

                                        )
                                    }
                                </div>
                                :
                                ""
                        }
                    </div>
            }
        </CustomModal>
    );
};

export default ChooseChannel;
