import React from 'react';
import {withTranslation} from "react-i18next";

const TrialEmpty = ({t}) => {
    return (
        <div className="">
            <div className="u-s-p-y-60">
                <div className="section__content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 u-s-m-b-30">
                                <div className="empty">
                                    <div className="empty__wrap">
                                        <span className="empty__big-text">{t('Empty')}</span>
                                        <span className="empty__text-1">{t('AllListEmpty')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default withTranslation()(TrialEmpty);