import React from 'react';

import soft1 from './hero/sof-analysis-01.jpg'
import soft3 from './hero/sof-analysis-03.jpg'
import line2 from '../../../../assets/front/img/particle/title-line-2.png'
import dashboard from "./hero/orders.png"

import {withTranslation} from "react-i18next";
import { Link } from 'react-router-dom'
import { LINK_REGISTER_STORE } from '../../../../routes/type'

const  Part2 = ({t}) => {
    return (
        <>
          <section className="sof-design-layout p-t-130 p-b-130">
            <div className="container">
              <div className="row align-items-center justify-content-center no-gutters">
                <div className="col-lg-7  wow fadeInLeft" data-wow-delay="0.3s">
                  <div className="software-preview m-b-md-80 animate-float-bob-y">
                    <img src={dashboard} alt="Image" style={{borderRadius: "10px",border : "1px solid #ddd",height : "400px"}}/>
                  </div>
                </div>
                <div className="col-lg-5 col-md-10  wow fadeInRight" data-wow-delay="0.2s">
                  <div className="software-text-block p-l-50 p-l-lg-30 p-l-md-0">
                    <div className="common-heading m-b-40">
                      <span className="tagline color-primary-5">{t('DesignInterActif')}</span>
                      <h2 className="title">{t('KeepThingsSimple')}</h2>
                    </div>
                    <p>
                      {t('ManageEasilyYourOrders')}
                    </p>
                      <Link to={LINK_REGISTER_STORE} className="btn btn-info uppercase"
                            data-wow-delay="0.4s">{t('RegisterShop')}
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="collaboration-section p-t-130 p-b-130 overflow-hidden">
            <div className="container">
              <div className="row justify-content-lg-between justify-content-center align-items-center">
                <div className="col-lg-5 col-md-10  wow fadeInLeft" data-wow-delay="0.4s">
                  <div className="collaboration-text p-l-30 p-l-md-0">
                    <div className="common-heading title-line-bottom m-b-40">
                      <span className="tagline">{t('InstallApp')}</span>
                      <h2 className="title">
                        {t('InstallAppToUpdateYourSales')}
                      </h2>
                      <img src={line2} alt="Image" className="Line"/>
                    </div>
                    <p className="m-b-15">
                      {t('InstallAppToUpdateYourSalesText')}
                    </p>
                    <Link to={LINK_REGISTER_STORE} className="btn btn-info uppercase"
                          data-wow-delay="0.4s">{t('StartNow')}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-10  wow fadeInUp" data-wow-delay="0.5s">
                  <div className="preview-galley-v4 m-l-100 m-b-md-80 ">
                    <img
                        src={soft1}
                        className="preview-image-1"
                        style={{width: "435px"}}
                        alt="Live collaboration"
                    />

                    <img
                        src={soft3}
                        className="preview-image-3 animate-float-bob-y"
                        alt="Chat Activity"
                    />
                    <img
                        src={soft1}
                        className="preview-image-4 animate-float-bob-x"
                        alt="Chat onboard"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    );
}

export default withTranslation()(Part2);
