import React, {useEffect, useState} from 'react';
import axios from "axios";
import {format_date_complet, numberWithCommas, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";


const _Dashboard = ({t,auth}) => {
    document.title = JSON.parse(localStorage.getItem('__S78456321__'))?.shopName+" :: "+t('Dashboard')
    const [orders, setOrders] = useState([]);
    const [pending, setPending] = useState(0);
    useEffect(() => {
        getUserStats()
    }, []);

    const getUserStats = async () => {
        await axios.get(SERVER_URL+"shop/stats/"+auth?.user?.id,).then((response)=>{
            if (response.data.succeed){
                setOrders(response.data.orders)
                setPending(response.data.pending)
            }else{
                 toast.error(t(response.data.message))
            }
        })
    }


    return (
        <>
            <div className="">
                <div className="row">
                    <div className="col-lg-3 u-s-m-b-30">
                        <div className="dash__box bg-warning text-white dash__box--shadow-2 u-h-100">
                            <div className="dash__pad-3">
                                <h3 className="dash__h2 u-s-m-b-8 text-white">{t('OrdersOnPending').toUpperCase()}</h3>
                                <h1 className="text-white">{pending}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 u-s-m-b-30">
                        <div className="dash__box bg-info text-white dash__box--shadow-2 u-h-100">
                            <div className="dash__pad-3">
                                <h3 className="dash__h2 u-s-m-b-8 text-white">{t('Orders').toUpperCase()}</h3>
                                <h1 className="text-white">{orders?.length}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dash__box dash__box--shadow dash__box--bg-white dash__box--radius" style={{padding : '10px'}}>
                <h3 className="dash__h2 u-s-p-xy-20">{t('RecentOrders')}</h3>

                <div className="gl-scroll">
                    <table className="table table-hover" style={{width : "100% !important",marginTop : "20px"}}>
                        <thead>
                        <tr>
                            <th>{t('Order')} #</th>
                            <th>{t('PlacedOn')}</th>
                            <th>{t('Product')}</th>
                            <th>Total</th>
                             <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            orders?.length > 0 ?

                                <>
                                {
                                    orders.slice(0,3).map((order, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><b>#{order.order_id}</b></td>
                                                <td>{format_date_complet(order.created_at)}</td>
                                                <td>{JSON.parse(order?.cart_content).map((item) => <b className="badge badge-outline-primary" style={{margin : "1px"}}>{item.name}</b>)}</td>
                                                <td>{numberWithCommas(order.total_amount)} Frcs</td>
                                                <td className="status">
                                                    {order.delivery_status == 1 ? <span className="badge badge-primary text-uppercase">{t("Pending")}</span> : null}
                                                    {order.delivery_status == 2 ? <span className="badge badge-info text-uppercase">{t("OnTheWay")}</span> : null}
                                                    {order.delivery_status == 3 ? <span className="badge badge-success text-uppercase">{t("Delivered")}</span> : null}
                                                    {order.delivery_status == 4 ? <span className="badge badge-warning text-uppercase">{t("Return")}</span> : null}
                                                    {order.delivery_status == 5 ? <span className="badge badge-danger text-uppercase">{t("Cancel")}</span> : null}
                                                </td>

                                            </tr>)
                                    })
                                }
                                </>
                                :
                                <tr>
                                    <td colSpan={5}>
                                        <p className="text-center">
                                            {t('EmptyList')}
                                        </p>
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default _Dashboard;
