import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, format_date, SERVER_URL} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import Authorization from "../../../../components/Authorization";
import {Link} from "react-router-dom";
import Paginate from "../../../../components/Paginate/Paginate";
import CreateOrUpdate from "./CreateOrUpdate";
import {compose} from "redux";
import {connect} from "react-redux";
import Assign from "./Assign";

const AdminSupport = ({t,auth}) => {
    document.title = APPNAME+" :: "+t('Support')

    const [currentTickets,setCurrentTickets] = useState([]);
    const [tickets,setTickets] = useState([]);

    useEffect(() => {
        getTickets()
    },[auth])

    useEffect(() => {
        setCurrentTickets(tickets?.slice(indexOfFirstPost, indexOfLastPost))
    },[tickets])

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [loading,setLoading] = useState(true)
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [openS,setOpenS] = useState(false)
    const [ticket_id,seticket_id] = useState("")
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])


    const [users,setUsers] = useState([])
    const [customers,setCustomers] = useState([])

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);





    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    const getTickets = async () => {
        await axios.get(SERVER_URL+"admin/tickets",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setTickets(response.data.tickets)
                setUsers(response.data.users)
                setCustomers(response.data.customers)
                setLoading(false)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
    }



    const addTicket = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editTicket = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(tickets?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteTicket'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}ticket/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed == true) {
                        toast.success(t(response.data.message))
                        getTickets()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }


    const filterSupport = (users,id) =>{
        const support = users?.filter((user) => user.id == id)
        return support[0]
    }


    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledTicket') : t('SureToEnabledTicket'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}ticket/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getTickets(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentTickets(tickets?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = tickets.filter(ticket => {
                return ticket.title.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentTickets(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(tickets.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentTickets.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const filterNumber = (priority) =>{
        const filter = tickets.filter(e => e.priority == priority)
        return filter.length
    }

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListTickets'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}ticket/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getTickets(auth.shop.id)
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }

    const  assignUser = (ticket_id) => {
        seticket_id(ticket_id)
        setOpenS(true)
    }

    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Support')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{t('Support')}</li>
                                <li className="breadcrumb-item active">{t('Support')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

                <>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-muted mb-0">Total Tickets</p>
                                            <h2 className="mt-4 ff-secondary fw-semibold"><span className="counter-value" data-target="547">{tickets?.length}</span></h2>
                                        </div>
                                        <div>
                                            <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-soft-info text-info rounded-circle fs-4">
                                            <i className="ri-ticket-2-line"></i>
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-muted mb-0">{t('Low')} {t('Priority')}</p>
                                            <h2 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{filterNumber(3)}</span></h2>
                                        </div>
                                        <div>
                                            <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-soft-info text-info rounded-circle fs-4">
                                            <i className="ri-ticket-line"></i>
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-muted mb-0">{t('Medium')} {t('Priority')}</p>
                                            <h2 className="mt-4 ff-secondary fw-semibold"><span className="counter-value" >{filterNumber(2)}</span></h2>
                                        </div>
                                        <div>
                                            <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-soft-info text-info rounded-circle fs-4">
                                                    <i className="ri-ticket-line"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-muted mb-0">{t('High')} {t('Priority')}</p>
                                            <h2 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{filterNumber(1)}</span></h2>
                                        </div>
                                        <div>
                                            <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-soft-info text-info rounded-circle fs-4">
                                                    <i className="ri-ticket-line"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card" id="ticketsList">
                                <div className="card-header border-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">Tickets</h5>
                                        <div className="flex-shrink-0">
                                            <button className="btn btn-danger add-btn" onClick={event => addTicket()}>
                                                <i className="ri-add-line align-bottom me-1"></i>  Ticket
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body border border-dashed border-end-0 border-start-0">
                                    <div>
                                        <div className="row g-3">
                                            <div className="col-lg-4">

                                                {
                                                    isCheck.length > 0 &&

                                                    <>
                                                        {
                                                            Gloading ?
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                                :
                                                                <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className="offset-lg-4 col-lg-4">
                                                <div className="search-box">
                                                    <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table-responsive table-card mb-4">
                                        <table className="table align-middle table-nowrap mb-0" id="ticketTable">
                                            <thead>
                                            <tr>
                                                <th scope="col" style={{width: "50px"}}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               name="selectAll"
                                                               id="selectAll"
                                                               onChange={handleSelectAll}
                                                               checked={isCheckAll}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="sort" data-sort="id">ID</th>
                                                <th className="sort" data-sort="tasks_name">{t('Title')}</th>
                                                <th className="sort" data-sort="client_name">{t('Customer')}</th>
                                                <th className="sort" data-sort="support_tech">{t('Support')}</th>
                                                <th className="sort" data-sort="create_date">{t('CreatedAt')}</th>
                                                <th className="sort" data-sort="status">Status</th>
                                                <th className="sort" data-sort="priority">{t("Priority")}</th>
                                                <th className="sort" data-sort="action">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list form-check-all" id="ticket-list-data">
                                            {
                                                loading ?
                                                    <tr>
                                                        <td colSpan="9">
                                                            <p className="text-center">
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    :

                                                    <>
                                                        {
                                                            currentTickets?.length > 0
                                                                ?
                                                                <>
                                                                    {
                                                                        currentTickets?.map((ticket,key)=>
                                                                            <tr key={key}>
                                                                                <th scope="row">
                                                                                    <div className="form-check">
                                                                                        <input className="form-check-input"
                                                                                               type="checkbox"
                                                                                               name={ticket.title}
                                                                                               id={ticket.id}
                                                                                               onChange={handleClick}
                                                                                               checked={isCheck.includes(ticket.id)}
                                                                                        />
                                                                                    </div>
                                                                                </th>
                                                                                <td className="id"><span className="fw-medium link-primary">#{ticket?.ticket_id}</span></td>
                                                                                <td className="tasks_name">{ticket?.title}</td>
                                                                                <td className="client_name">{ticket?.firstname}  {ticket?.lastname}</td>
                                                                                <td className="support_id">
                                                                                    {
                                                                                        ticket?.support_id != 0 ?
                                                                                            <>
                                                                                                {filterSupport(users,ticket.support_id)?.firstname} {filterSupport(users,ticket.support_id)?.lastname}
                                                                                            </>
                                                                                            :
                                                                                           <i className="text-danger">{t('NotAssigned')}</i>
                                                                                    }
                                                                                </td>
                                                                                <td className="create_date">{format_date(ticket?.created_at)}</td>
                                                                                <td className="status">
                                                                                    {ticket?.statut == 1 ?  <span className="badge badge-soft-info text-uppercase">{t('New')}</span> : null}
                                                                                    {ticket?.statut == 2 ?  <span className="badge badge-soft-success text-uppercase">{t('Open')}</span> : null}
                                                                                    {ticket?.statut == 3 ?  <span className="badge badge-soft-warning text-uppercase">{t('Inprogress')}</span> : null}
                                                                                    {ticket?.statut == 4 ?  <span className="badge badge-soft-dangertext-uppercase">{t('Closed')}</span> : null}

                                                                                </td>
                                                                                <td className="priority">
                                                                                    {ticket?.priority == 1 ? <span className="badge bg-danger text-uppercase">{t('High')}</span> : null}
                                                                                    {ticket?.priority == 2 ? <span className="badge bg-warning text-uppercase">{t('Medium')}</span> : null}
                                                                                    {ticket?.priority == 3 ? <span className="badge bg-info text-uppercase">{t('Low')}</span> : null}

                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        ticket?.support_id !== 0 ?
                                                                                            <ul className="list-inline  mb-0">

                                                                                                <li className="list-inline-item">
                                                                                                    <Link className="edit-item-btn" to={`/admin/support/${ticket?.hash_key}`}>
                                                                                                        <i className="mdi mdi-eye align-bottom me-2 text-primary"></i>
                                                                                                    </Link>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                     <span className="edit-item-btn cursor-pointer" onClick={event => editTicket(ticket)}>
                                                                                         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                     </span>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => deleteModal(ticket.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            :
                                                                                            <ul className="list-inline mb-0">
                                                                                                <li className="list-inline-item">
                                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => assignUser(ticket.id)}>
                                                                                                         <i className="bx bx-user-plus fs-20 align-bottom me-2"></i> <span className="fs-10">{t('AssignSupport')}</span>
                                                                                                     </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                <tr>
                                                                    <td colSpan="9">
                                                                        <p className="text-center">{t('EmptyList')}</p>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </>
                                            }

                                            </tbody>
                                        </table>

                                    </div>
                                    <Paginate
                                        postsPerPage={postsPerPage}
                                        totalPosts={tickets?.length}
                                        paginate={paginate}
                                        previousPage={previousPage}
                                        nextPage={nextPage}
                                        current={currentPage}
                                    />

                                </div>

                            </div>
                        </div>

                    </div>
                </>

            <CreateOrUpdate auth={auth} created={getTickets} isEdit={isEdit}  customers={customers} open={open} setOpen={setOpen} updateData={updateData} />

            <Assign
             open={openS}
             setOpen={setOpenS}
             ticket={ticket_id}
             created={getTickets}
             users={users}
            />

        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(AdminSupport));
