export const LOGIN_SHOP_LOADING = 'LOGIN_SHOP_LOADING';
export const LOGIN_SHOP_SUCCESS = 'LOGIN_SHOP_SUCCESS';
export const LOGIN_SHOP_FAIL = 'LOGIN_SHOP_FAIL';

export const ME_LOADING = 'ME_LOADING';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAIL = 'ME_FAIL';

export const ADD_PASS_LOADING = 'ADD_PASS_LOADING';
export const ADD_PASS_SUCCESS = 'ADD_PASS_SUCCESS';
export const ADD_PASS_FAIL = 'ADD_PASS_FAIL';


export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
