import React, {useEffect, useState} from 'react';
import {CustomModal, MiddleLoading} from "../../../../components";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";

const ShopConfig = ({open,setOpen,slug,t,shop_key,loadData}) => {

    const [data, setData] = useState({
        type: "dropdown",
        filter_position: "left",
        page_layout: "grid"
    })
    const [oldData,setOldData] = useState({})
    const [loading,setLoading] = useState(false)


    useEffect(() => {
        getShopData(shop_key)
    }, []);


    const handleRadio = (event) => {
      setData({
          ...data,
          [event.target.name] : event.target.value
      })
    }

    const getShopData = async (key) => {
        await axios.get(SERVER_URL+"store/template/page/"+key+"/Shop").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setData(JSON.parse(response.data.page.content))
                setOldData(JSON.parse(response.data.page.content))
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }


    const submitConfig = async () => {
        setLoading(true)
        const content = {
            content : JSON.stringify({...oldData, type: data.type, filter_position : data.filter_position, page_layout : data.page_layout})
        }
        await axios.put(SERVER_URL+"store/template/page/edit/"+slug,content,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setOpen(false)
                loadData()
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
    }


    return (
        <CustomModal open={open} setOpen={setOpen}  size="lg"  title={t("ProductPageConfig")} header={false} >

                    <div className="row mt-20">
                        <div className="col-lg-4">
                            <div className="row">
                               <div className="col-lg-12">
                                   <h4 >{t('FilterType')}</h4>
                                   <hr/>
                               </div>
                            </div>
                            <div style={{marginLeft : "20px",marginRight : "20px"}}>
                                <div className="row">

                                    <div className="col-lg-9">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{t('Dropdown')}
                                            <br/>
                                            <small>{t('FilterStayLikeDropdown')}</small>
                                        </label>

                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-check form-radio-primary">
                                            <input className="form-check-input"   onClick={event => handleRadio(event)} defaultChecked={data?.type == "dropdown"} value="dropdown" name="type" type="radio" role="switch" id="flexSwitchCheckChecked" />
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <label className="form-check-label" htmlFor="offcanvas">{t('Offcanvas')}
                                         <br/>
                                            <small>{t('FilterStayLikeOffcanvas')}</small>
                                        </label>

                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-check form-radio-primary">
                                            <input className="form-check-input"  onClick={event => handleRadio(event)} defaultChecked={data?.type == "offcanvas"} value="offcanvas" name="type" type="radio" role="switch" id="offcanvas" />
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <label className="form-check-label" htmlFor="sidebar">{t('Sidebar')}
                                          <br/>
                                            <small>{t('FilterStayLikeSidebar')}</small>
                                        </label>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-check form-radio-primary">
                                            <input className="form-check-input"   onClick={event => handleRadio(event)} defaultChecked={data?.type == "sidebar"} value="sidebar"  name="type" type="radio" role="switch" id="sidebar" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                       <div className="col-lg-4">
                           <div className="row">
                               <div className="col-lg-12">
                                   <h4 >{t('Filterlayout')}</h4>
                                   <hr/>
                               </div>
                           </div>
                           <div style={{marginLeft : "20px",marginRight : "20px"}}>
                               <div className="row">
                                   <div className="col-lg-9">
                                       <label className="form-check-label" htmlFor="left">{t('Left')} <br/>
                                       <small>{t('FilterStayLikeSidebarLeft')}</small>
                                       </label>
                                   </div>
                                   <div className="col-lg-3">
                                       <div className="form-check form-radio-primary pt-10">
                                           <input className="form-check-input"   onClick={event => handleRadio(event)} defaultChecked={data?.filter_position == "left"} value="left" name="filter_position" type="radio" role="switch" id="left" />
                                       </div>
                                   </div>
                               </div>
                               <br/>
                               <div className="row">
                                   <div className="col-lg-9">
                                       <label className="form-check-label" htmlFor="right">{t('Right')} <br/>
                                       <small>{t('FilterStayLikeSidebarRight')}</small>
                                       </label>
                                   </div>
                                   <div className="col-lg-3">
                                       <div className="form-check form-radio-primary pt-10">
                                           <input className="form-check-input"   onClick={event => handleRadio(event)} defaultChecked={data?.filter_position == "right"} value="right" type="radio" name="filter_position" role="switch" id="right" />
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>

                        <div className="col-lg-4">
                            <div className="row">
                              <div className="col-lg-12">
                                  <h4>{t('Layout')}</h4>
                                  <hr/>
                              </div>
                            </div>
                            <div style={{marginLeft : "20px",marginRight : "20px"}}>

                                <div className="row">
                                    <div className="col-lg-9">
                                        <label className="form-check-label" htmlFor="fullwidth">{t('FullWidth')}<br/>
                                        <small>{t('PageShowFullPage')}</small>
                                        </label>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-check form-radio-primary pt-10">
                                            <input className="form-check-input"   onClick={event => handleRadio(event)} defaultChecked={data?.page_layout == "fullwidth"} value="fullwidth" name="page_layout" type="radio" role="switch" id="fullwidth" />
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <label className="form-check-label" htmlFor="grid">{t('Grid')} <br/>
                                        <small>{t('PageShowGridPage')}</small>
                                        </label>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-check form-radio-primary pt-10">
                                            <input className="form-check-input"   onClick={event => handleRadio(event)} defaultChecked={data?.page_layout == "grid"} value="grid" name="page_layout" type="radio" role="switch" id="grid" />
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <label className="form-check-label" htmlFor="list">{t('Liste')} <br/>
                                        <small>{t('PageShowListPage')}</small>
                                        </label>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-check form-radio-primary pt-10">
                                            <input className="form-check-input"   onClick={event => handleRadio(event)} defaultChecked={data?.page_layout == "list"} value="list"   name="page_layout" type="radio" role="switch" id="list" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="offset-lg-8 col-lg-4 mt-50" >
                                <div className="d-flex flex-row justify-content-end">
                                    <button className="btn btn-outline-danger" onClick={event => setOpen(false)}>{t('Close')}</button>
                                    <button className="btn btn-success ml-20" onClick={event => submitConfig()}>
                                        {
                                            loading ?
                                                <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                                :
                                                <span>{t('Submit')}</span>
                                        }

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

        </CustomModal>
    )
}

export default withTranslation()(ShopConfig);
