import {Fragment, useEffect, useState} from "react";
import {CustomModal} from "../../components";
import CustomInput from "./CustomInput";



const EdiGroupInputs = ({inputs, setOpen, open, t,idx,handleInput}) => {
    return(
        <CustomModal size="md" setOpen={setOpen} open={open}  title={t('EditBlock')+" "+idx}   >
            <div className="row">
                {
                    inputs?.map((item,key)=>
                        <Fragment key={key}>
                            <CustomInput t={t} content={item}  handleInput={handleInput} />
                        </Fragment>
                    )
                }
            </div>
        </CustomModal>
    )
}



const DynamicBlockInputs = ({item,t,updateData}) =>{
    const [input, setinput] = useState([])
    const [open, setOpen] = useState(false);
    const [idx, setIdx] = useState(0);
    const [datas, setData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);


    const handleClick = () => {
      setData([...datas,defaultData])
        updateData([...datas,defaultData])
    }



    useEffect(()=>{
        setDefaultData(item?.data[0])
        setData(item?.data)
    },[])

    const handleInput = (data,id) => {
        const newState = input.map(obj => {
            if (obj.id === id) {
                return {...obj, value: data};
            }
            return obj;
        });

        setinput(newState)

        const veryNew = datas.map((obj,index) => {
            if (index === (idx - 1)) {
                return [...newState];
            }
            return obj;
        });

        setData(veryNew)
        updateData(veryNew)

    }


    const editBlock = (block,ix) => {
        setinput(block)
        setOpen(true)
        setIdx(ix)
    }
    const deleteBlock = (key) => {
        const newData = []
       for (let i = 0; i < datas.length; i++) {
           if (i !== key){
              newData.push(datas[i])
           }
       }

       setData(newData)
        updateData(newData)
    }


    return (
        <>
            {
                datas?.map((item,key) =>
                    <div key={key}  className="dynamic_block_container">
                        <h5>Block {key + 1}</h5>
                         <i className="la la-pencil" onClick={event =>  editBlock(item,key + 1)}></i>
                        {datas?.length > 1 ?  <i className="la la-trash text-danger" onClick={event => deleteBlock(key)}></i> : ""}
                    </div>
                )
            }

            <div className="row">
                <div className=" offset-lg-8 col-lg-4">
                    <button className="btn btn-info btn-block" onClick={event => handleClick()}>
                        <i className="ri-add-line"></i>
                    </button>
                </div>

            </div>

            <EdiGroupInputs open={open} setOpen={setOpen} inputs={input} t={t} idx={idx} handleInput={handleInput} />

        </>
    );

}

export default  DynamicBlockInputs
