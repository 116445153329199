import {
    PAGE_SHOP_FAIL,
    PAGE_SHOP_LOADING, PAGE_SHOP_SUCCESS,
} from './type';

const initialState = {
    isLoading : true,
    list : [],
    error: null,
};


export default function (state = initialState, { type, payload }) {
    switch (type) {
        case PAGE_SHOP_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case PAGE_SHOP_SUCCESS:
            return {
                ...state,
                list: payload.pageList,
                isLoading: false,
            };

        case PAGE_SHOP_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error,
            };

        default:
            return state;
    }
}