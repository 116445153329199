import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {loadShopTemplates} from "../../../../store/Templates/action";
import {APPNAME, numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import Cookies from "js-cookie";
import axios from "axios";
import {toast} from "react-toastify";
import {RegLoading} from "../../../../components";
import TemplateModal from "./TemplateModal";
import buildSelf from "../../../../assets/buildSelf.png"


const Template = ({t,list_templates : {isLoading,templates,error},loadShopTemplates,setStep}) => {

    document.title = APPNAME+" :: "+t('ChooseTemplate')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('ChooseTemplate'))
   

    const [all_price,setAllPrice] = useState(Cookies.get('total_price') ?? 0)
    const [loading,setLoading] = useState(false)
    const [GLoading,setGLoading] = useState(false)
    const [open,setOpen] = useState(false)
    const [data,setData] = useState(null)

    useEffect(()=>{
        loadShopTemplates()
    },[loadShopTemplates])


    const selectTemplate = (template) => {
        setOpen(true)
        setData(template)
    }

    const ChoosedTemplate = async (template) =>{
        setLoading(true)
        setOpen(false)

        if (typeof template === 'string'){
            const data = {
                theme : JSON.stringify({
                    id : 0,
                    picture : "empty",
                    price : 0,
                    name : "Empty"
                }),
                shop_key : Cookies.get('shop_key'),
                price :   0
            }
            setAllPrice(0)
            Cookies.set('total_price',0)

            await axios.post(SERVER_URL+"template/saved",data).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    setGLoading(true)
                    setTimeout(()=>{
                        setGLoading(false)
                        setStep(4)
                        Cookies.set('step',4)
                    },2000)

                }else{
                    toast.error(t(response.data.message))
                }
            })


        }else{
            const data = {
                theme : JSON.stringify({
                    id : template.id,
                    picture : template.picture1,
                    price : template.price || 0,
                    name : template.name,
                }),
                temp_key : template.hash_key,
                shop_key : Cookies.get('shop_key'),
                price :  template.price || 0
            }

            const price = parseInt(Cookies.get('total_price')) + parseInt(template.price || 0)
            setAllPrice(price)
            Cookies.set('total_price',price)

            await axios.post(SERVER_URL+"template/saved",data).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    setGLoading(true)
                    setTimeout(()=>{
                        setGLoading(false)
                        setStep(4)
                        Cookies.set('step',4)
                    },2000)

                }else{
                    toast.error(t(response.data.message))
                }
            })
        }
    }


    return (
        <div className="">
            <div className="row wow fadeInUp pt-20 pl-20 pb-10" data-wow-delay="0.1s">
                <div className="col-md-12 uppercase">
                    <h2 className="page-title">{t('ChooseTemplate')}  </h2>
                    <hr/>
                </div>
            </div>


            {
                isLoading || loading || GLoading

                    ?
                    <RegLoading/>
                    :

                    <div className="row theme-list"  style={{maxHeight : 'calc(100vh - 100px)', overflowY : "scroll"}}>
                        <div className="col-md-4">
                            <div className="empty_template" onClick={event => ChoosedTemplate('empty')}>
                                <div className="content">
                                    <img src={buildSelf} alt=""/>
                                    <p>{t('FromZero')}</p>
                                </div>
                            </div>
                        </div>


                        {
                            templates.length > 0
                                ?
                                <>
                                    {
                                        templates.map((template, key) =>

                                            <div key={key} className="col-md-4 mb-20"
                                                 onClick={event => selectTemplate(template)}>
                                                <div className="custom_template" title={t('Choose')}>
                                                    <div className="card-wrapper">
                                                        <div className="card-item">
                                                            <figure className="card-image">
                                                                <img
                                                                    src={SERVER_IMG_URL + 'templates/' + template.picture1}
                                                                    alt="..."/>
                                                            </figure>
                                                            <a className="card-content">
                                                                <p className="card-title text-center">{template.name}</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                                :
                                ""
                        }
                    </div>

            }

            <TemplateModal
                title={t("Template")}
                open={open}
                setOpen={setOpen}
                data={data}
                ChoosedTemplate={ChoosedTemplate}
            />

        </div>
    );
}

const mapStateToProps = (state) => ({
    list_templates: state.list_templates,
});


export default compose(connect(mapStateToProps, {loadShopTemplates}))(withTranslation()(Template));
