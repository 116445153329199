import React, {useEffect, useState} from 'react';
import Generate from "./Generate";
import axios from "axios";
import {attachTokenToHeaders, SERVER_IMG_URL, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import Paginate from "../../../../../components/Paginate/Paginate";

const _QrCode = ({t,auth}) => {
    const [open, setOpen] = useState(false)
    const [labels, setLabels] = useState([])
    const [currentLabels,setCurrentLabels] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [loading,setLoading] = useState(true);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentLabels(labels?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage,labels])

    useEffect(() => {
        getLabel()
    }, []);

    const getLabel = async () => {
        await axios.get(SERVER_URL+"label/qrcode/"+auth?.shop.id,attachTokenToHeaders())
            .then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    setLabels(response.data.labels)
                }else{
                    toast.error(t(response.data.message))
                }
            })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(labels?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };



    const deleteFile = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteLabelFile'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(SERVER_URL+"label/delete/"+id,attachTokenToHeaders())
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        getLabel()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }


    const downloadFile = (filename) => {
        const data = {
            shop_key : auth?.shop?.shop_key,
            filename : filename,
            type : "code"
        }

        axios.post(SERVER_URL+'seller/download/file',data,attachTokenToHeaders(false,true,true))
            .then((response) => {
                if (response){
                    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', filename)
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(downloadUrl);
                    toast.success(t('FileDownloaded'))
                }
            })
    }



    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={event => setOpen(true)}
                        >
                            <i className="ri-add-line align-bottom me-1"></i> {t("Add")}
                        </button>
                    </div>
                </div>
            </div>
            <br />

            <div className="row mt-10">
                <table
                    className="table align-middle table-nowrap mb-20"
                    id="ticketTable"
                >
                    <thead>
                    <tr>
                        <th style={{width : "10%"}}>{t("NbrCopy")}</th>
                        <th className="sort" data-sort="client_name">{t("Products")}</th>
                        <th style={{width : "10%"}} >Action</th>
                    </tr>
                    </thead>
                    <tbody className="list form-check-all" id="ticket-list-data">
                    {loading ? (
                        <tr>
                            <td colSpan="7">
                                <p className="text-center">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t("Loading")}...
                                </p>
                            </td>
                        </tr>
                    ) : (
                        <>
                            {currentLabels?.length > 0 ? (
                                <>
                                    {currentLabels?.map((lab, key) => (
                                        <tr key={key}>
                                            <td style={{textAlign : "center"}}><b>{lab.numberPerPdf}</b></td>
                                            <td>{
                                                JSON.parse(lab.products).map((product,k) => <b className="badge badge-secondary" key={k}>{product.name}</b>)
                                            } </td>
                                            <td>
                                                <button onClick={event => downloadFile(lab.archiveName)}
                                                        className="btn btn-icon btn-light btn-sm"><i
                                                    className="ri-download-2-fill"></i></button>
                                                <i className="ri-delete-bin-2-fill ml-10 cursor-pointer text-danger"
                                                   onClick={event => deleteFile(lab.id)}></i>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="4">
                                        <p className="text-center">{t("EmptyList")}</p>
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                    </tbody>
                </table>
                <Paginate
                    postsPerPage={postsPerPage}
                    totalPosts={labels?.length}
                    paginate={paginate}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    current={currentPage}
                />
            </div>

            <Generate
                type="qrcode"
                shop_id={auth?.shop.id}
                open={open}
                setOpen={setOpen}
                reload={getLabel}
                auth={auth}
            />
        </>
    );
}

export default _QrCode;
