import React from 'react';

const _Error = () => {
    return (
        <div>

        </div>
    );
};

export default _Error;
