import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../utils/utils";
import {withTranslation} from "react-i18next";

const Teams1 = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {
    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const team1 = useRef()

    const getBlockSize = () => {
        setDimension({top :team1?.current?.offsetTop, left : team1?.current?.offsetLeft, width : team1?.current?.offsetWidth, height : team1?.current?.offsetHeight,nodeName : team1?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);

    return(
        <section className="container pt-120"
                 style={customStyle}
                 ref={team1}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
        >

            <div className="teams1">
                <div className="responsive-container-block outer-container">
                    <div className="responsive-container-block inner-container">
                        <div
                            className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-4 wk-ipadp-4 headings-container">
                            <p className="text-blk heading-text">
                                {t('MeetOurTeam')}
                            </p>
                            <p className="text-blk sub-heading-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla et sagittis,
                                vestibulum risus lacus sit.
                            </p>
                        </div>
                        <div
                            className="responsive-cell-block wk-desk-8 wk-ipadp-8 wk-tab-12 wk-mobile-12 team-members-container">

                            {
                                items.map((item, k) =>
                                    <div key={k}
                                         className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-mobile-12 wk-tab-12 card-container">
                                        <div className="">
                                            <img className="card___img"
                                                 src={flatData(item, "image")}
                                                 alt=""/>
                                            <p className="pl-70">
                                                <span className="text-blk name">
                                                {flatData(item, "fullname")}
                                            </span>
                                                <span className="text-blk position">
                                                {flatData(item, "title")}
                                            </span>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}

export default withTranslation()(Teams1)
