import {Swiper, SwiperSlide} from "swiper/react";
import React, {useEffect, useRef, useState} from "react";
import {flatData} from "../../../utils/utils";
import {Autoplay, Navigation, Pagination} from "swiper/modules";

const CSwiper = ({block,customStyle,toggleHightLight,isEditor}) => {
    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const cswiper = useRef()

    const getBlockSize = () => {
        setDimension({top :cswiper?.current?.offsetTop, left : cswiper?.current?.offsetLeft, width : cswiper?.current?.offsetWidth, height : cswiper?.current?.offsetHeight,nodeName : cswiper?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);


    return (
        <section className="pt-10 pb-10"
                 style={customStyle}
                 ref={cswiper}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
        >
           <div className="row">
               <div className="col-lg-12">
                   <div className="tp-slider-active tp-slider-variation swiper-container">
                       <div className="swiper-wrapper">
                           <Swiper
                               speed={1000}
                               grabCursor={true}
                               autoplay={{
                                   delay: 5000,
                                   disableOnInteraction: false,
                               }
                               }
                               loop={true}
                               slidesPerView={items.length > 1 ? 1 : "auto"}
                               modules={[Autoplay, Pagination, Navigation]}
                               pagination={{
                                   el: '.swiper-pagination-bullets', clickable: true
                               }}
                               navigation={{
                                   nextEl: ".tp-slider-button-next",
                                   prevEl: ".tp-slider-button-prev",
                                   clickable: true
                               }}
                           >
                               {
                                   items?.length > 0 ?
                                       <>
                                           {items?.map((item, key) =>
                                               <SwiperSlide key={key}>
                                                   <img src={flatData(item, "image")}
                                                        style={{width: "100%"}}/>
                                               </SwiperSlide>
                                           )}
                                       </>
                                       : ""
                               }
                           </Swiper>
                       </div>
                       <div className="tp-slider-arrow tp-swiper-arrow d-none d-lg-block">
                           <button type="button" className="tp-slider-button-prev">
                               <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                   <path d="M7 13L1 7L7 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                         strokeLinejoin="round"/>
                               </svg>
                           </button>
                           <button type="button" className="tp-slider-button-next">
                               <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                   <path d="M1 13L7 7L1 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                         strokeLinejoin="round"/>
                               </svg>
                           </button>
                       </div>

                       <div
                           className="tp-slider-dot tp-swiper-dot swiper-pagination-clickable swiper-pagination-bullets"></div>

                   </div>
               </div>
           </div>
        </section>
    );
};

export default CSwiper;
