import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import L from "leaflet"
import {RegLoading} from "../index";

const CustomMap = ({
position,
description,
zoom = 18,
getPosition,
editable = true,
}) => {

    const [location, setLocation] = useState([]);

    useEffect(() => {
        if("geolocation" in navigator){
            navigator.geolocation.getCurrentPosition((position)=>{
                let lat = position.coords.latitude
                let long = position.coords.longitude
                setLocation([lat,long])
            })
        }
        else{
            console.warn("Geolocation is not supported by this browser")
        }
    }, []);

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
               setLocation([e.latlng?.lat, e.latlng?.lng])
               getPosition([e.latlng?.lat, e.latlng?.lng])
            },
        });
        return null;
    };



    return (
        <>
            {
                location?.length ?
                    <MapContainer
                        center={position??location}
                        zoom={zoom}
                        scrollWheelZoom={false}
                        className="cursor-pointer"
                        zoomControl={true}
                    >
                        {
                            editable ? <LocationFinderDummy /> : ""
                        }
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position??location}>
                            <Popup>
                                {description}
                            </Popup>
                        </Marker>
                    </MapContainer>
                    :
                    <RegLoading />
            }
        </>
    );
}

let DefaultLocation = L.icon({
    iconUrl : 'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png'
})

L.Marker.prototype.options.icon = DefaultLocation

export default withTranslation()(CustomMap);
