import React, {useCallback, useState} from 'react';
import SectionList from "./left/SectionList";
import PositionList from "./left/PositionList";
import {withTranslation} from "react-i18next";
import ComponentList from "./left/ComponentList";
import folder from "../assets/icons/blue-open-folder-11570.svg"
import {useHistory} from "react-router-dom";
import CopyPage from "./left/CopyPage";
import RightContent from "./right/RightContent";
import RightStyle from "./right/RightStyle";


const LeftPanel = ({elements,setElements,t,pageList,templateName,content,setStep,step,substep,setSubstep}) => {

    const [open, setOpen] = useState(false);
    const [copyData, setCopyData] = useState(null);

    const history = useHistory()
    const changePage = (hash_key) => {
        history.push(`/admin/template/builder/${hash_key}`)
    }

    const copyPage = (item) => {
        setOpen(true)
        setCopyData(item)
    }

    return (
        <div id="left-panel">

            {
                !pageList[0]?.shop_key ?
                    <div id="filemanager">
                        <div className="header">
                            <span className="text-secondary"><i className="la la-file la-lg"></i> Pages</span>
                        </div>

                        <div className="tree" style={{overflowY : "scroll"}}>
                            <ol>
                                <li data-folder="content" className="folder">
                                    <label htmlFor="content" className="uppercase font-weight-bold"><span>{templateName}</span></label>
                                    <input type="checkbox" id="content" defaultChecked={true}/>

                                    <ol>
                                        {
                                            pageList?.map((item,key) =>
                                                <li  className={`file ${item.hash_key === window.location.href.split('/').pop() ? "active" : ""}`} key={key}>
                                                    <label htmlFor="album" >
                                                        <span className="" onClick={event => changePage(item.hash_key)}> <i className="ri-file-4-line" style={{fontSize : "15px"}}></i> {item.name}</span>
                                                        <div className="file-actions">
                                                            <button className="rename btn btn-outline-primary" onClick={event => changePage(item.hash_key)} title={t('Edit')}>
                                                                <i className="la la-pen"></i>
                                                            </button>
                                                            <button className="duplicate btn btn-outline-primary" onClick={event => copyPage(item)} title={t('Copy')}>
                                                                <i className="la la-copy"></i>
                                                            </button>
                                                        </div>
                                                    </label>
                                                    <input type="checkbox" id="album"/>
                                                </li>
                                            )
                                        }


                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                    : ""
            }


            <div className="drag-elements">

                <div className="header">
                    <ul className="nav nav-tabs  nav-fill" id="elements-tabs" role="tablist">
                        <li className="nav-item sections-tab">
                            <a className={`nav-link cursor-pointer ${step === 1 ? "active" : ""}`} id="block-menu" onClick={event => {setStep(1);setSubstep(1)}} title="Sections">
                                <i className="la la-stream"></i>
                            </a>
                        </li>
                        {/*<li className="nav-item component-tab">*/}
                        {/*    <a className={`nav-link cursor-pointer ${step === 2 ? "active" : ""}`} onClick={event => {setStep(2);setSubstep(3)}}  title="Components">*/}
                        {/*        <i className="la la-box"></i>*/}
                        {/*    </a>*/}
                        {/*</li>*/}

                        <li className="nav-item component-configuration-tab" role="presentation">
                            <a className={`nav-link cursor-pointer ${step === 3 ? "active" : ""}`} id="block-settings" onClick={event => {setStep(3);setSubstep(4)}}>
                                <i className="la la-gear"></i>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content">
                        <div className={`tab-pane ${step === 1 ? "show active sections" :""}`} id="sections" role="tabpanel" aria-labelledby="sections-tab">
                            <ul className="nav nav-tabs nav-fill sections-tabs" id="properties-tabs" role="tablist">
                                <li className="nav-item content-tab">
                                    <a className={`nav-link cursor-pointer ${substep == 1 ? "active" : ""}`}
                                       onClick={event => setSubstep(1)}>
                                        <i className="la la-plus"></i>
                                        <div><span> Sections</span></div>
                                    </a>
                                </li>
                                <li className="nav-item components-tab">
                                    <a className={`nav-link cursor-pointer ${substep == 3 ? "active" : ""}`}
                                       onClick={event => setSubstep(3)}>
                                        <i className="la la-box"></i>
                                        <div><span>Components</span></div>
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div className={`tab-pane ${substep === 1 ? "show active" : ""}`} id="sections-new-tab"
                                     data-section="content" role="tabpanel" aria-labelledby="content-tab">
                                    <SectionList/>
                                </div>

                                <div className={`tab-pane components ${substep === 3 ? "show active" : ""}`}
                                     id="components" data-section="components" role="tabpanel"
                                     aria-labelledby="components-tab">
                                    <ComponentList />
                                </div>
                            </div>

                        </div>

                        <div className={`tab-pane ${step === 2 ? "show active" : ""}`} id="components-tabs"
                             role="tabpanel" aria-labelledby="components-tab">

                            <ul className="nav nav-tabs nav-fill sections-tabs" role="tablist">

                            </ul>

                            <div className="tab-content">


                            </div>
                        </div>


                        <div className={`tab-pane ${step === 3 ? "show active" : ""}`}>
                            <div className="component-properties">

                                <ul className="nav nav-tabs nav-fill" id="properties-tabs" role="tablist">
                                <li className="nav-item content-tab">
                                        <a className={`nav-link cursor-pointer ${substep === 4 ? "active" : ""}`}
                                           onClick={event => {
                                               setSubstep(4)
                                           }}>
                                            <i className="la la-lg la-sliders-h"></i> <span>{t('Content')}</span></a>
                                    </li>
                                    <li className="nav-item style-tab">
                                        <a className={`nav-link cursor-pointer ${substep === 5 ? "active" : ""}`}
                                           onClick={event => {
                                               setSubstep(5)
                                           }}>
                                            <i className="la la-lg la-paint-brush"></i> <span>{t('Style')}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item style-tab">
                                        <a className={`nav-link cursor-pointer ${substep == 2 ? "active" : ""}`}
                                           onClick={event => setSubstep(2)}><i className="la la-th-list"></i> <span>Positions</span>
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    <div className={`tab-pane ${substep === 4 ? "show active" : ""}`}
                                         style={{maxHeight: "100vh", overflowY: "scroll", paddingBottom: "200px"}}
                                         id="content-tab" data-section="content" role="tabpanel"
                                         aria-labelledby="content-tab">
                                        <RightContent t={t} content={content} setElements={setElements}
                                                      elements={elements}/>
                                    </div>

                                    <div className={`tab-pane ${substep === 5 ? "show active" : ""}`} id="style-tab"
                                         data-section="style" role="tabpanel" aria-labelledby="style-tab">
                                        <RightStyle t={t} content={content} setElements={setElements} elements={elements}/>
                                    </div>

                                    <div className={`tab-pane ${substep === 2 ? "show active" : ""}`} id="sections-list"
                                         data-section="style" role="tabpanel" aria-labelledby="style-tab">
                                        <PositionList elements={elements} setElements={setElements} t={t}/>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <CopyPage
                setOpen={setOpen}
                open={open}
                item={copyData}
            />
        </div>
    );
}

export default withTranslation()(LeftPanel)
