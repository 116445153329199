import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import {Link, Redirect, useHistory, withRouter} from "react-router-dom";
import {LINK_CONDITIONS} from "../../../../routes/type";
import axios from "axios";
import {SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";


const  TrialSignup = ({t,auth}) => {

    const [loading, setLoading] = useState(false);
    const [shopInfo, setShopInfo] = useState(null);

    useEffect(() => {
        setShopInfo(JSON.parse(localStorage.getItem('__S78456321__')))
    }, []);

    const history = useHistory()


    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required(t('InputRequired')),
        lastname: Yup.string().required(t('InputRequired')),
        email: Yup.string().required(t('InputRequired')),
        phone: Yup.string().required(t('InputRequired')),
        country: Yup.string().required(t('InputRequired')),
        city: Yup.string().required(t('InputRequired')),
        password: Yup.string()
            .required(t('PasswordRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('PasswordContaint')
            )
        ,
        conf_password: Yup.string()
            .required(t('ConfPasswordRequired'))
            .oneOf([Yup.ref('password'), null], t('PasswordSame'))
    });

    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        password: '',
        conf_password: '',
    }

    const handleSubmit = async (values) =>{
        setLoading(true)
        values.shop_id = shopInfo?.id
        await axios.post(SERVER_URL+"subdomain/signup",values).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                 history.push('/login')
            }else{
                toast.error(t(response.data.message))
            }
        })

    }

    if (auth.user) return <Redirect to="/customer/dashboard" exact={true} />


    return (
        <div  style={{background : "#f4f4f4"}} >



            <div className="u-s-p-b-60 u-s-p-t-60">


                <div className="section__content">
                    <div className="container">
                        <div className="row row--center">
                            <div className="col-lg-8 col-md-10 u-s-m-b-30 ">
                                <div className="l-f-o bg-white">
                                    <div className="l-f-o__pad-box">

                                        <h3 className="gl-h2">{t('SignupAccount')}</h3>

                                        <div className="u-s-p-t-30">
                                         <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                                {({ errors, touched }) =>
                                                    <Form>
                                                        <div className="row u-s-p-b-15">
                                                            <div className="col-lg-6">
                                                                <Field name="firstname" type="text" className='front'  placeholder={t('Firstname')} />
                                                                {errors.firstname && touched.firstname ? (
                                                                    <div className='text-danger'>{errors.firstname}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Field name="lastname"  className='front'  placeholder={t('Lastname')} />
                                                                {errors.lastname && touched.lastname ? (
                                                                    <div className='text-danger'>{errors.lastname}</div>
                                                                ) : null}
                                                            </div>

                                                        </div>
                                                        <div className="row u-s-p-b-15">
                                                            <div className="col-lg-6">
                                                                <Field name="email" type="email" className='front'  placeholder={t('Email')} />
                                                                {errors.email && touched.email ? (
                                                                    <div className='text-danger'>{errors.email}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Field name="phone" type="" className='front'  placeholder={t('Phone')} />
                                                                {errors.phone && touched.phone ? (
                                                                    <div className='text-danger'>{errors.phone}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row u-s-p-b-15">
                                                            <div className="col-lg-6">
                                                                <Field name="country" type="" className='front'  placeholder={t('Country')} />
                                                                {errors.country && touched.country ? (
                                                                    <div className='text-danger'>{errors.country}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <Field name="city" type="" className='front'  placeholder={t('City')} />
                                                                {errors.city && touched.city ? (
                                                                    <div className='text-danger'>{errors.city}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row u-s-p-b-15">
                                                            <div className="col-lg-6">
                                                                <Field name="password" type="password" className='front' placeholder={t('Password')} />
                                                                {errors.password && touched.password ? (
                                                                    <div className='text-danger'>{errors.password}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <Field name="conf_password" type="password" className='front' placeholder={t('ConfPassword')} />
                                                                {errors.conf_password && touched.conf_password ? (
                                                                    <div className='text-danger'>{errors.conf_password}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row u-s-p-b-15">
                                                            <span style={{fontSize : "13px",padding :  "10px 15px"}}>
                                                               {t('MessageBeforeRegister')} <Link to={LINK_CONDITIONS}> {t('Termsndcondition')}</Link>
                                                           </span>

                                                              <div className="offset-lg-8 col-lg-4">
                                                                  <button  type="submit" className="btn btn-block btn-info"  disabled={loading} style={{background: "#3377ff"}}>
                                                                      {
                                                                          loading ?
                                                                              <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                                                              :
                                                                              <span>{t('Signup')}</span>
                                                                      }
                                                                  </button>

                                                              </div>
                                                        </div>
                                                        <hr/>
                                                        <br/>
                                                            <div className="links">
                                                                <Link to="/login" className="">{t('Login')}</Link>
                                                                <Link to="signup" className="">{t('ForgetPassword')}</Link>
                                                            </div>

                                                    </Form>
                                                }

                           </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
});

export default compose(withRouter, connect(mapStateToProps  )) (withTranslation()(TrialSignup))
