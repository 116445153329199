import TrialHome from "../pages/TrialHome";
import TrialCart from "../pages/TrialCart";
import TrialCheckout from "../pages/TrialCheckout";
import TrialProducts from "../pages/TrialProducts";
import TrialProductDetail from "../pages/TrialProductDetail";
import TrialLogin from "../pages/TrialLogin";
import TrialSignup from "../pages/TrialSignup";
import TrialCategories from "../pages/TrialCategories";
import TrialDashboard from "../pages/customer/TrialDashboard";
import TrialWishlist from "../pages/TrialWishlist";
import TrialContact from "../pages/TrialContact";
import TrialSuccess from "../pages/TrialSuccess";
import NotFoundTrialPage from "../pages/NotFoundTrialPage";
import TrialSubcategories from "../pages/TrialSubcategories";

const publicLinks = [
    { path: "/",  component : TrialHome },
    { path: "/cart",  component : TrialCart },
    { path: "/checkout",  component : TrialCheckout },
    { path: "/products",  component : TrialProducts },
    {path: "/login",  component : TrialLogin },
    { path: "/signup",  component : TrialSignup },
    { path: "/categories",  component : TrialCategories },
    { path: "/contact-us",  component : TrialContact },

    { path: "/product/detail/:slug",  component : TrialProductDetail },
    { path: "/wishlist",  component : TrialWishlist },
    { path: "/success",  component : TrialSuccess },

    { path: "/categorie/:slug",  component : TrialSubcategories },


    { path: "*",  component : NotFoundTrialPage },
];

const privateLinks = [
    { path: "/customer/dashboard",  component : TrialDashboard , isLogged : true },
];

const routeList = privateLinks.concat(publicLinks)


export {routeList}
