import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {SERVER_URL} from "../../helpers/utils";
import {toast} from "react-toastify";

const BlackFeedback = ({auth,shop,t}) => {

    const [rating,setRating] = useState(5)
    const [show,setShow] = useState(false)
    const [submitted,setSubmitted] = useState(false)

    const handleFeedback = async () =>{
        await axios.get(SERVER_URL+`front/rate/${rating}/${shop?.id}/${auth?.user?.id}`).then((response)=>{
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setSubmitted(true)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const toggleShow = () => show ? setShow(false) : setShow(true)



    return (
       <>
           <div className="feedback_btn" style={{background : shop?.color}} onClick={event => toggleShow()}>
               <i className={`${show ? "fa fa-times" : "fa fa-stars"}`}></i>
           </div>
           <div className={`black__fb__wrapper ${show ? "black__fb__wrapper__show" : ""}`}>
               {
                   !submitted ?
                       <div className="feedback__card"  id="intro">
                           <header>
                                <span style={{background : shop?.color+"50"}}>
                                    <img src="https://raw.githubusercontent.com/A7med3bdulBaset/Rating-card/90ec3ec9bdb20d937d17725b013b16fc6dcfeca4/img/icon-star.svg" alt="star"/>
                                </span>
                           </header>
                           <h3 style={{color : shop?.color}}>  {t('RateExp')}</h3>
                           <p>
                               {t('RateUsText')}.
                           </p>
                           <div className="list" id="options">
                               <li><span onClick={event => setRating(1)} className={`option cursor-pointer ${rating  === 1 ? "choosed" : "" }`}>1</span></li>
                               <li><span  onClick={event => setRating(2)} className={`option cursor-pointer ${rating  === 2 ? "choosed" : "" }`}>2</span></li>
                               <li><span  onClick={event => setRating(3)} className={`option cursor-pointer ${rating  === 3 ? "choosed" : "" }`}>3</span></li>
                               <li><span  onClick={event => setRating(4)} className={`option cursor-pointer ${rating  === 4 ? "choosed" : "" }`}>4</span></li>
                               <li><span  onClick={event => setRating(5)} className={`option cursor-pointer ${rating  === 5 ? "choosed" : "" }`}>5</span></li>
                           </div>
                           <button style={{background : shop?.color, color : "#fff"}} onClick={event => handleFeedback()}>{t('Rate')}</button>
                       </div>

                       :

                       <div className="feedback__card" id="feedresult">
                           <div className="image">
                               <img src="https://raw.githubusercontent.com/A7med3bdulBaset/Rating-card/90ec3ec9bdb20d937d17725b013b16fc6dcfeca4/img/illustration-thank-you.svg" alt="Thanks"/>
                           </div>
                           <p className="ss"><span id="selected"></span></p>
                           <h3>{t('Thanks')}</h3>
                           <p>
                               {t('FeedBackThanksText')}
                           </p>
                       </div>
               }




           </div>
       </>
    );
};

export default withTranslation()(BlackFeedback)
