import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {CalculateDiscount, numberWithCommas, SERVER_IMG_URL} from "../../../../../helpers/utils";
import IconShoppingCart from "../../../../../components/Icons/IconShoppingCart";
import IconEye from "../../../../../components/Icons/IconEye";
import IconHeart from "../../../../../components/Icons/IconHeart";
import {secondCalculDicount} from "../../../../../themes/utils/utils";
import {withTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";
import {CustomerAddCart, CustomerDecrease, CustomerIncrease} from "../../../../../store/Subdomain/CustomerCart/action";
import moment from "moment";
import {addWishItem} from "../../../../../store/Subdomain/Wishlist/action";

const OldCard = ({item,server,setItem,t,setOpen,CustomerAddCart,addWishItem}) => {

    const addWishlist = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
        const data ={
            id:item.id,
            category : item.category,
            picture : server+JSON.parse(item.pictures)[0],
            name:item.title,
            discount : item.discount,
            price:item.price,
            hash_key:item.hash_key
        }

        addWishItem(data)

        toast("Notification",{
            description: t('AddToWishlist') +"\n"+ moment(Date.now()).fromNow(),
            icon : <i className="ri-check-double-fill"></i>
        });
        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }

    }
    const addToCart = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
            const data = {
                id:item.id,
                qty : 1,
                category : item.category,
                picture : server+JSON.parse(item.pictures)[0],
                name:item.title,
                price: CalculateDiscount(item?.price,item?.discount)
            }
            CustomerAddCart(data)

            toast("Notification",{
                description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
                icon : <i className="ri-check-double-fill"></i>
            })
        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }
    }


    return (
        <div className="tp-product-item-2 mb-40">
            <div className="tp-product-thumb-2 pRelative z-index-1 fix w-img">
                <Link to={"/product/detail/"+item.hash_key}>
                    <img src={server+JSON.parse(item.pictures)[0]}  alt=""/>
                </Link>

                <div className="tp-product-action-2 tp-product-action-blackStyle">
                    <div className="tp-product-action-item-2 d-flex flex-column ">
                        <button type="button" className="tp-product-action-btn-2 tp-product-add-cart-btn"
                          onClick={event => addToCart(item)}
                        >
                            <IconShoppingCart />
                            <span className="tp-product-tooltip tp-product-tooltip-right">{t('BuyNow')}</span>
                        </button>
                        <button type="button" className="tp-product-action-btn-2 tp-product-quick-view-btn"
                                onClick={event => {setItem(item); setOpen(true)}}
                        >
                            <IconEye />
                            <span className="tp-product-tooltip tp-product-tooltip-right">{t('QuickView')}</span>
                        </button>
                        <button type="button" onClick={event => addWishlist(item)} className="tp-product-action-btn-2 tp-product-add-to-wishlist-btn">
                            <IconHeart />
                            <span className="tp-product-tooltip tp-product-tooltip-right">{t('AddToWishlist')}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="tp-product-content-2 pt-15">
                <div className="tp-product-tag-2 text-muted" style={{fontSize : "10px"}}>
                    <a href="#">{item.isSubcategory ? item.subname : item.category}</a>
                </div>
                <h3 className="tp-product-title-2">
                    <Link
                        to={"/product/detail/"+item.hash_key}
                    >{item.title}</Link>
                </h3>
                <div className="tp-product-rating-icon tp-product-rating-icon-2">
                    {
                        item.rating ?
                            <>
                                {
                                    [...Array(parseInt(item.rating))].map((item,key) => (
                                        <span key={key}><i className="fa-solid fa-star"></i></span>
                                    ))
                                }
                            </>
                            :
                            ""
                    }

                    <small className="text-muted">{item.short_description}</small>
                </div>
                {
                    parseInt(item.discount) > 0 ?
                        <div className="tp-product-price-wrapper">
                            <span className="tp-product-price old-price">{numberWithCommas(item.price)}  Francs CFA</span>
                            <span className="tp-product-price new-price pl-10">{numberWithCommas(secondCalculDicount(parseInt(item.price),parseInt(item.discount)))} Francs CFA</span>
                        </div>
                        :

                        <div className="tp-product-price-wrapper">
                            <span className="tp-product-price new-price">{numberWithCommas(item.price)}  Francs CFA</span>
                        </div>
                }
            </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    customCart : state.customCart
});

export default compose(withRouter, connect(mapStateToProps,{CustomerAddCart,CustomerIncrease,CustomerDecrease,addWishItem})) (withTranslation()(OldCard))

