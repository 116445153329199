import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../components/SellerLayout";
import {Link, useLocation} from "react-router-dom";
import {SUB_INDEX} from "../routes/type";
import {withTranslation} from "react-i18next";
import axios from "axios";
import { checkCurrentUrlSubdomain, SERVER_IMG_URL, SERVER_URL } from '../../../../helpers/utils'
import GroceryCard from "../components/Cards/GroceryCard";
import DefaultCard from "../components/Cards/DefaultCard";
import OldCard from "../components/Cards/OldCard";
import EmeraudeCard from "../components/Cards/EmeraudeCard";
import {toast} from "react-toastify";
import QuickView from "../../../../components/Card/QuickView";
import { Fade } from 'react-awesome-reveal'

const Result = ({t}) => {
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [pageData, setPageData] = useState([])
    const [shop, setShop] = useState({});
    const [item, setItem] = useState({})
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        checkSubdomain()

    },[searchParams.get('search_query')])

    const checkSubdomain = async () => {
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShop(response.data)
                searchElement(response.data.shop.id)
                getPageData(response.data)
            }
        })
    }

    const getPageData = async (data) => {
        setLoading(true)
        await axios.get(SERVER_URL+"store/template/page/"+data?.shop_key+"/Shop").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                const data = response?.data?.page?.content
                if (data)  {
                    setPageData(JSON.parse(data))
                }
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })

    }

    const searchElement = (shop_id) => {
        const name = searchParams.get('search_query')
         axios.get(SERVER_URL+"sub/search/"+name+"/"+shop_id)
             .then((response) =>{
                 if (response.data.succeed){
                      setProducts(response.data.products)
                 }
             })
    }


    return (
        <Fade>
            <SellerLayout>
                <main>
                    <section className="breadcrumb__area include-bg pt-100 pb-50 breadcrumb__padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="breadcrumb__content pRelative z-index-1">
                                        <h3 className="breadcrumb__title">{t('Result')}</h3>
                                        <div className="breadcrumb__list">
                                            <span><Link to={SUB_INDEX}>{t('Home')}</Link></span>
                                            <span>{t('SearchResult')} : {products.length} {t('Find')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="tp-shop-area pb-120">
                        <div className="container">
                            <div className="row">
                                {
                                    products?.length > 0
                                        ?
                                        <>
                                            {
                                                products?.map((product,key)=>
                                                    <div className="col-lg-3" key={key}>
                                                        {pageData?.card === "grocery" && <GroceryCard item={product} server={SERVER_IMG_URL+shop?.shop?.shop_key+"/products/thumb/"} color={shop?.shop.color} />}
                                                        {pageData?.card === "default" || !pageData ?  <DefaultCard item={product} server={SERVER_IMG_URL+shop?.shop?.shop_key+"/products/thumb/"} setItem={setItem} setOpen={setOpen} color={shop?.shop.color} /> : ""}
                                                        {pageData?.card === "defaultWithoutBord" && <OldCard item={product} server={SERVER_IMG_URL+shop?.shop?.shop_key+"/products/thumb/"} setItem={setItem} setOpen={setOpen}  color={shop?.shop.color} />}
                                                        {pageData?.card === "emeraude" && <EmeraudeCard item={product} server={SERVER_IMG_URL+shop?.shop?.shop_key+"/products/thumb/"} setItem={setItem} setOpen={setOpen}  />}

                                                    </div>
                                                )
                                            }
                                        </>
                                        :

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="empty">
                                                        <div className="empty__wrap">
                                                            <span className="empty__big-text">{t('Empty')}</span>
                                                            <span className="empty__text-1">{t('SearchListEmpty')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                }
                            </div>
                        </div>
                    </section>




                </main>

                <QuickView
                    data={item}
                    shop_data={shop?.shop}
                    setOpen={setOpen}
                    open={open}
                />

            </SellerLayout>
        </Fade>
    )
}

export default withTranslation()(Result)
