import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {
    attachTokenToHeaders, CalculateDiscount,
    calulateRate,
    numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL, SortImageByProduct
} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {AddCart} from "../../../../store/Subdomain/cart/action";


const  TrialProductDetail = ({t,match,AddCart}) => {

    document.title = JSON.parse(localStorage.getItem('__S78456321__'))?.shopName+" :: "+t('Detail')

    const [shop, setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));
    const [detail,setDetail] = useState(null)
    const [picture,setPicture] = useState([])
    const [categories,setCategories] = useState([])
    const [subcategories,setSubcategories] = useState([])
    const [loading,setLoading] = useState(true)
    const [galery,setGalery] = useState("")
    const [quantity, setQuantity] = useState(1);

    const [step, setStep] = useState(1);
    const [reviews, setReviews] = useState([]);

    useEffect(()=>{
        getProductSingle()
    },[shop])


    const getProductSingle = async () => {
        await axios.get(SERVER_URL+"product/front/single/"+match.params.slug+"/"+shop?.id).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setDetail(response.data.product)
                setSubcategories(response.data?.subcategories)
                setCategories(response.data?.categories)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    useEffect(() => {
        getReviews(detail)
    }, [detail]);


    const getReviews = async (ev) => {
        await axios.get(SERVER_URL+"product/reviews/"+ev?.id).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setReviews(response.data.reviews)

            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    const choosePicture = (img) => {
        setGalery(img)
    }
    const filterCategory = (id) => {
        const filter = categories.filter(e => e.id == id)
        return filter[0]?.category
    }

    const filterSubCategory = (id) => {
        const filter = subcategories.filter(e => e.subcategory == id)
        return filter[0]?.subname
    }




    const addToCart = (item) => {
        AddCart({
            id:item.id,
            qty : 1,
            category : item.category,
            picture : SERVER_IMG_URL+shop?.shop_key+"/products/"+galery,
            name:item.title,
            price:CalculateDiscount(item?.price,item?.discount)
        })
        toast.success(t("AddToCart"), {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    }


    useEffect(() => {
       if (detail) setGalery(JSON.parse(detail?.pictures))
    }, [detail]);



    return (
        <div className="">

            <div className="u-s-p-t-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">


                            <div className="pd-breadcrumb u-s-m-b-30">
                                <ul className="pd-breadcrumb__list">
                                    <li className="has-separator">

                                        <Link to="/" >{t('Home')}</Link></li>

                                    <>
                                        {
                                            detail?.isSubcategory == "true" ?  <li className="has-separator"><Link  to="/">{filterSubCategory(detail?.subcategory_id)}</Link></li> :  <li className="has-separator"><Link  to="/">{filterCategory(detail?.category_id)}</Link></li>
                                        }
                                    </>

                                    <li className="is-marked"><a >{detail?.title}</a></li>
                                </ul>
                            </div>

                            <div className="pd u-s-m-b-30">
                                <div className=" pd-wrap">
                                    <div id="pd-o-initiate">
                                        {
                                            detail ?

                                                <>
                                                    {
                                                        JSON.parse(detail.pictures)[0]?.length > 0
                                                            ?
                                                            <div className="pd-o-img-wrap"  data-src={SERVER_IMG_URL+shop?.shop_key+"/products/"+JSON.parse(detail.pictures)[0]}>
                                                                <img className="u-img-fluid" src={SERVER_IMG_URL+shop?.shop_key+"/products/"+galery}  alt=""/>
                                                            </div>
                                                            :

                                                            <p className="text-center">
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                            </p>

                                                    }
                                                </>

                                                : ""
                                        }
                                    </div>

                                </div>
                                <div className="u-s-m-t-15">
                                    <div className="">
                                        <div className="row">
                                            {
                                                detail ?

                                                    <>
                                                        {
                                                            JSON.parse(detail?.pictures)?.length > 0
                                                                ?

                                                                <>
                                                                    {
                                                                        JSON.parse(detail?.pictures).map((pic,key) =>
                                                                            <div className="col-lg-3 bg-grey-color" key={key}>
                                                                                <img className="u-img-fluid" onClick={event => choosePicture(pic)} src={SERVER_IMG_URL+shop?.shop_key+"/products/thumb/"+pic} alt=""/>
                                                                            </div>



                                                                        )
                                                                    }
                                                                </>

                                                                :

                                                                <p className="text-center">
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                                </p>

                                                        }
                                                    </>

                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-7">


                            <div className="pd-detail">
                                <div>

                                    <span className="pd-detail__name">{detail?.title}</span></div>
                                <div>

                                    <div className="pd-detail__inline">

                                    {detail?.discount ?
                                      <>
                                          <span className="pd-detail__price">{numberWithCommas(CalculateDiscount(detail?.price,detail?.discount) )} Frcs</span>
                                          <span className="pd-detail__discount">({detail?.discount}% OFF)</span>
                                          <del className="pd-detail__del">{numberWithCommas(detail?.price)} Frcs</del>
                                      </>

                                        :

                                        <span className="pd-detail__price">{detail?.price} Frcs</span>

                                    }


                                    </div>
                                </div>

                                <div className="u-s-m-b-15">
                                    <div className="pd-detail__inline"><span className="pd-detail__stock">{detail?.stock} stock</span></div>
                                </div>
                                <div className="u-s-m-b-15">

                                    <span className="pd-detail__preview-desc">
                                     {detail?.short_description}
                                    </span>
                                </div>


                                <div className="u-s-m-b-15">
                                    <div className="" style={{marginTop : "40px"}}>
                                        <div className="pd-detail-inline-2">
                                            <div className="u-s-m-b-15"><button className="btn btn-success" onClick={event => addToCart(detail)}>{t('BuyNow')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className="u-s-p-y-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pd-tab">
                                <div className="u-s-m-b-30">
                                    <ul className="nav pd-tab__list">
                                        <li className="nav-item"><a className={`nav-link ${step === 1 ? "active" : null}`} onClick={event => setStep(1)}>DESCRIPTION</a></li>
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    <div className={`tab-pane ${step === 1 ?  " fade show active" : null}`}>
                                        <div className="pd-tab__desc">
                                            <div className="u-s-m-b-15">
                                                <div dangerouslySetInnerHTML={{__html: detail?.description }} />
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}


const mapStateToProps = (state) => ({
    cart : state.cart,
});

export default compose(withRouter, connect(mapStateToProps, { AddCart })) (withTranslation()(TrialProductDetail))
