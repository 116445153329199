import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../components/SellerLayout";
import {Link, useParams} from "react-router-dom";
import {SUB_CATEGORY, SUB_INDEX} from "../routes/type";
import {withTranslation} from "react-i18next";
import axios from "axios";
import { Capitalize, checkCurrentUrlSubdomain, CLIENT, SERVER_IMG_URL, SERVER_URL } from '../../../../helpers/utils'
import { Fade } from 'react-awesome-reveal'

const Categories = ({t}) => {

    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+t('Categories')

    const [slice, setSlice] = useState(4)
    const [categories, setCategories] = useState({})

    const [shop, setShop] = useState({});

    useEffect(()=>{
        checkSubdomain()

    },[])



    const checkSubdomain = async () => {
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            if (response.data.succeed){
                setShop(response.data)
                getCategories(response.data.shop.id)
            }
        })
    }

    const getCategories = async (id) => {
        await axios.get(SERVER_URL+"public/categories/"+id).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.categories)
            }
        })
    }

    return (
     <Fade>
         <SellerLayout>
             <section className="breadcrumb__area include-bg pt-80 pb-50">
                 <div className="container">
                     <div className="row">
                         <div className="col-xxl-12">
                             <div className="breadcrumb__content p-relative z-index-1">
                                 <h2  style={{fontSize : "40px"}}>{t('Categories')}</h2>
                                 <div className="breadcrumb__list">
                                     <span><Link to={SUB_INDEX}>{t('Home')}</Link></span>
                                     <span>{t('Categories')}</span>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </section>

             <section className="tp-category-area pb-120">
                 <div className="container">
                     <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-3">
                         {
                             categories?.length > 0 ?
                                 <>
                                     {
                                         categories?.slice(0,slice).map((category,key) =>
                                             <div className="col" key={key}>
                                                 <div className="tp-product-category-item text-center mb-40">
                                                     <div className="tp-product-category-thumb fix">
                                                         <Link to={"/shop/"+category.slug} style={{width : "280px",height : "280px"}}>
                                                             <img src={`${category.image ? SERVER_IMG_URL+shop?.shop.shop_key+"/categories/thumb/"+category.image : CLIENT+"/builder/default-image.jpg"}`} className="img-thumbnail"  alt="product-category"/>
                                                         </Link>
                                                     </div>
                                                     <div className="tp-product-category-content">
                                                         <h3 className="tp-product-category-title">
                                                             <Link to={"/shop/"+category.slug}>{category.category}</Link>
                                                         </h3>
                                                         <p>{category.count??0} {t('Products')}</p>
                                                     </div>
                                                 </div>
                                             </div>
                                         )
                                     }
                                 </>
                                 :
                                 <div className="">
                                     <div className="u-s-p-y-60">
                                         <div className="section__content">
                                             <div className="container">
                                                 <div className="row">
                                                     <div className="col-lg-12 col-md-12 u-s-m-b-30">
                                                         <div className="empty">
                                                             <div className="empty__wrap">
                                                                 <span className="empty__big-text">{t('Empty')}</span>
                                                                 <span className="empty__text-1">{t('AllListEmpty')}</span>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>

                                 </div>
                         }
                     </div>
                     {
                         categories?.length > slice ?
                             <div className="row">
                                 <div className="col-xl-12">
                                     <div className="tp-category-main-result text-center mb-35 mt-35">
                                         <p>{t('Showing')} {categories?.length > slice ? slice : 0} {t('Subcategories').toLowerCase()} {t('On').toLowerCase()} {categories?.length} </p>
                                         <div className="tp-category-main-result-bar">
                                             <span data-width="40%"></span>
                                         </div>
                                     </div>
                                     {
                                         slice <= categories.length ?
                                             <div className="tp-category-main-more text-center cursor-pointer">
                            <span onClick={event => setSlice(slice + 4)} className="tp-load-more-btn">
                              {t('LoadMore')}
                            </span>
                                             </div>
                                             : ""
                                     }
                                 </div>
                             </div>

                             : ""
                     }
                 </div>
             </section>

         </SellerLayout>
     </Fade>

    )
}

export default withTranslation()(Categories)
