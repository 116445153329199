import axios from 'axios';

import {
    PRICING_FAIL,
    PRICING_LOADING, PRICING_SUCCESS

} from './type';
import {SERVER_URL} from "../../helpers/utils";



export const loadShopPricing = () => async (dispatch) => {
    dispatch({
        type: PRICING_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'shop/pricing');
            dispatch({
                type: PRICING_SUCCESS,
                payload: { pricing : response.data.pricing},
            });



    } catch (err) {
        dispatch({
            type: PRICING_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};


