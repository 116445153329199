import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";


const SettingsTerms = ({t,auth}) => {


    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML("<p>...</p>"))))
    const [editorStateEn, setEditorStateEn] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML("<p>...</p>"))))
    const [fr, setFr] = useState("");
    const [en, setEn] = useState("");

    const [loadingf, setLoadingf] = useState(false);
    const [loadinge, setLoadinge] = useState(false);


    const handleEditorChangeFr = (data) => {
        setEditorState(data);
        let html  = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        setFr(html)
    };

    const handleEditorChangeEn = (data) => {
        setEditorStateEn(data);
        let html  = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()))
        setEn(html)
    };


    useEffect(() => {
        getTerms()
    }, []);


    const getTerms = async () =>{
        await axios.get(SERVER_URL+"store/terms/"+auth?.shop.id,attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(response.data?.term ? convertFromHTML(response.data?.term?.fr) : convertFromHTML("<p></p>"))))
                setEditorStateEn(EditorState.createWithContent(ContentState.createFromBlockArray(response.data?.term ? convertFromHTML(response.data?.term?.en) : convertFromHTML("<p></p>"))))

            }else{
                toast.error(t(response.data.message))
            }
        })
    }





    const submitTerms = async (type) => {

         const content = {
              shop_id : auth?.shop.id,
              en : en,
              fr : fr
          }

       if (content){
           type === "fr" ? setLoadingf(true) : setLoadinge(true)
           await axios.post(SERVER_URL+"store/terms",content,attachTokenToHeaders()).then((response)=>{
               type === "fr" ? setLoadingf(false) : setLoadinge(false)
               if (response.data.succeed){
                   toast.success(t(response.data.message))
               }else{
                   type === "fr" ? setLoadingf(true) : setLoadinge(true)
                   toast.error(t(response.data.message))
               }
           })
       }
    }


    return (
        <>
            <h5>{t('GeneralConditions')}</h5>
            <hr/>
            <br/>
            <div className="container">
                <div className="row">
                    <h6 style={{display : "flex",flexDirection : "row", justifyContent : "space-between"}}>{t('Français')}
                        <span>
                           {
                               loadingf ?
                                   <span className=""><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</span>
                                   :
                                   <span className="icon-demo-content cursor-pointer" onClick={event => submitTerms("fr")}><i className="bx bxs-save"></i> {t('Save')}</span>
                           }
                        </span>
                    </h6>
                    <div>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={handleEditorChangeFr}
                        />
                    </div>
                </div>

                <br/>
                <hr/>
                <br/>
                <br/>
                <div className="row">
                    <h6 style={{display : "flex",flexDirection : "row", justifyContent : "space-between"}}>{t('English')}
                        <span>
                           {
                               loadinge ?
                                   <span className=""><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</span>
                                   :
                                   <span className="icon-demo-content cursor-pointer" onClick={event => submitTerms("en")}><i className="bx bxs-save"></i> {t('Save')}</span>
                           }
                        </span>
                    </h6>
                    <div>
                        <Editor
                            editorState={editorStateEn}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={handleEditorChangeEn}
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default withTranslation()(SettingsTerms)
