import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, database_date, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,auth}) => {

    const [loading,setLoading] = useState(false)



    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('InputRequired')),
        description: Yup.string().required(t('InputRequired')),
        icon : Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        name: isEdit ? updateData.name : '',
        description: isEdit ? updateData.description : '',
        icon: isEdit ? updateData.icon : '',
    }


    const handleSubmit = async (values) => {
        setLoading(true)
        if (isEdit){
            await axios.put(SERVER_URL+"admin/page/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"admin/page/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Page')}</> : <>{t('Edit')} {t('Page')}</>} size="md">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched,values }) =>
                    <Form>
                                <div className="row">
                                    <div  className="col-lg-12">
                                        <label htmlFor="name" className="form-label">{t('Name')} <b className="text-danger">*</b></label>
                                        <Field  className="form-control form-control-lg" id="name" name="name"/>
                                        {errors.name && touched.name ? (
                                            <div className='text-danger'>{errors.name}</div>
                                        ) : null}
                                    </div>

                                    <div  className="col-lg-12 mt-2">
                                        <label htmlFor="description" className="form-label">Description <b className="text-danger">*</b></label>
                                        <Field as="textarea"  className="form-control form-control-lg" id="description" name="description"/>
                                        {errors.description && touched.description ? (
                                            <div className='text-danger'>{errors.description}</div>
                                        ) : null}
                                    </div>

                                    <div  className="col-lg-12 mt-2">
                                        <label htmlFor="icon" className="form-label">Icone <small>(Font awesome)</small> <b className="text-danger">*</b></label>
                                        <Field   className="form-control form-control-lg" id="icon" name="icon"/>
                                        {errors.icon && touched.icon ? (
                                            <div className='text-danger'>{errors.icon}</div>
                                        ) : null}
                                    </div>

                        </div>


                        <div className="row">
                            <div className="col-lg-8 pt-3">
                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                            </div>
                            <div className="col-lg-4 pt-3">
                                <button className="btn btn-success btn-block" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>
        </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)
