import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal, MiddleLoading} from "../../../../components";
import {SERVER_IMG_URL} from "../../../../helpers/utils";


const TemplateModal = ({open,setOpen,title,data,t,ChoosedTemplate}) => {

    const [img, setImg] = useState('');

    useEffect(() => {
      setImg(data?.picture1)
    }, [data]);


    return (
        <CustomModal open={open} setOpen={setOpen} title={title+" "+data?.name} size="xl">
            <div className="row" style={{height : "580px",borderTop : "1px solid #c1c1c1"}}>
                <div className="col-lg-3">
                    <div className="template_picture_list">
                       <div className={`template_hover_box ${img === data?.picture1 ? "active":null}`} onClick={event => setImg(data?.picture1)}>
                           <img src={SERVER_IMG_URL+'templates/'+data?.picture1} alt=""/>
                       </div>
                        <div className={`template_hover_box ${img === data?.picture2 ? "active":null}`} onClick={event => setImg(data?.picture2)}>
                            <img src={SERVER_IMG_URL+'templates/'+data?.picture2} alt=""/>
                        </div>
                        <div className={`template_hover_box ${img === data?.picture3 ? "active":null}`} onClick={event => setImg(data?.picture3)}>
                            <img src={SERVER_IMG_URL+'templates/'+data?.picture3} alt=""/>
                        </div>

                    </div>


                </div>
                <div className="col-lg-9">
                    <div className="template_showing_content">
                       <div className="template_showing_content_header">
                           <h3> 🚩 {data?.name} <small className="btn btn-outline-primary btn-sm">{t('New')}</small> </h3>
                           <button className="btn btn-success btn-sm" onClick={event => ChoosedTemplate(data)}>{t('UsedThisTemplate')}</button>
                       </div>
                               <h4 className="text-success">{data?.price} Frcs</h4>
                        <p>{t('TemplateDetailText')}</p>
                        <div className="row">
                            <div className="offset-lg-1 col-lg-10 template_showing_content_element">
                                {
                                    img != "" ?
                                        <img src={SERVER_IMG_URL+'templates/'+img} alt=""/>
                                        :
                                        <MiddleLoading />
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </CustomModal>
    );
}

export default withTranslation()(TemplateModal);
