import React from 'react';
import {CLIENT} from "../../../../../helpers/utils";
import {Link} from "react-router-dom";
import {SUB_INDEX} from "../../routes/type";
import {withTranslation} from "react-i18next";

const SubNotFound = ({t}) => {
    return (
        <div>
            <main>
                <section className="tp-error-area pt-110 pb-110">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8 col-md-10">
                                <div className="tp-error-content text-center">
                                    <div className="tp-error-thumb">
                                        <img src={CLIENT+"/builder/error/error.png"} alt=""/>
                                    </div>

                                    <h3 className="tp-error-title">{t('404_title')}</h3>
                                    <p>{t('404_description')}</p>

                                    <Link to={SUB_INDEX} className="tp-error-btn">{t('Home')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </div>

    )
}

export default withTranslation()(SubNotFound)
