import React, {Fragment, useEffect, useState} from 'react';
import CustomInput from "../../forms/CustomInput";
import Display from "./part/Display";
import Typography from "./part/Typography";
import Size from "./part/Size";
import Margin from "./part/Margin";
import Padding from "./part/Padding";
import Border from "./part/Border";
import BorderRadius from "./part/BorderRadius";
import {customContent} from "../../config/config.section";


const RightStyle = ({t,content,setElements,elements}) => {


    const [contentStyle, setContentStyle] = useState([])
    const [customStyle, setCustomStyle] = useState({})

    useEffect(() => {
        if (elements.filter(e => e.item?.id == content?.id).length > 0){
            setContentStyle(customContent)
        }else{
            setContentStyle([])
        }
    }, [elements.length,content?.name]);


    const updateData = () => {
        const updateInfo = elements.map( (element) => {
            if (element.item.id === content.id) {
                const item = {...element.item, customStyle : customStyle}
                return {...element,item};
            }else{
                return element;
            }
        })

        setElements(updateInfo)
    }


    const updateValue = (key,value) => {
        const data = contentStyle
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === key){
                data[i].value = value
                setContentStyle([...data])
                setCustomStyle([...data])
            }
        }

        updateData()
    }

    const updateUnit = (key,value) => {
        const data = contentStyle
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === key){
                data[i].unit = value
                setContentStyle([...data])
                setCustomStyle([...data])
            }
        }

        updateData()
    }



    return (
        <>

            {
                content?.contentEditable?.length > 0  ?
                    <div className="row" style={{height : content?.contentEditable && "100vh",overflowY : "scroll"}}>
                         {
                             content?.contentEditable?.filter(e => e.step === 2 ).length > 0 ?
                                 <div className="p-3 row">
                                     {
                                         content?.contentEditable?.filter(e => e.step === 2 ).map((content,key) =>
                                             <div className="col-lg-12 pb-3" style={{borderBottom : "0.1em solid #f4f4f4" }} key={key}>
                                                 <CustomInput content={content} t={t}  />
                                             </div>
                                         )
                                     }
                                 </div>: ""
                         }


                         <div style={{height : "calc(100% - 200px", overflowY : 'scroll'}}>
                             <Display
                                 data={contentStyle}
                                  updateValue={updateValue}
                                 updateUnit={updateUnit}
                             />
                             <Typography
                                 data={contentStyle}
                                 updateValue={updateValue}
                                 updateUnit={updateUnit}
                              />
                             <Size
                                 data={contentStyle}
                                 updateValue={updateValue}
                                 updateUnit={updateUnit}
                             />
                             <Margin
                                 data={contentStyle}
                                 updateValue={updateValue}
                                 updateUnit={updateUnit}
                             />
                             <Padding
                                 data={contentStyle}
                                 updateValue={updateValue}
                                 updateUnit={updateUnit}
                             />
                             <Border
                                 data={contentStyle}
                                 updateValue={updateValue}
                                 updateUnit={updateUnit}
                             />
                             <BorderRadius
                                 data={contentStyle}
                                 updateValue={updateValue}
                                 updateUnit={updateUnit}
                             />
                             {/*<Background
                                  data={contentStyle}
                                  updateValue={updateValue}
                                  updateUnit={updateUnit}
                             />*/}
                         </div>


                    </div>
                    :

                    <div className="alert alert-dismissible fade show alert-primary m-3" role="alert" >
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        <strong>{t('NoSelectedElement')}</strong><br/> {t('SelectElement')}
                    </div>

            }

        </>
    );
}

export default RightStyle;
