import React from 'react';
import {withTranslation} from "react-i18next";
import moment from "moment/moment";
import 'moment/locale/fr'

const Chat = ({data,auth,t}) => {
    return (
        <>
            {
                auth?.me.id != data.user_id &&
                    <div className="chat__receiver">
                        <div className="chat__receiver-chat">
                            <div className="chat__receiver-msg">{data.message}</div>
                            <div className="chat__send-time">{moment(data?.created_at).fromNow()}</div>
                        </div>
                    </div>
                    }

            {
                auth?.me.id == data.user_id &&
                <div className="chat__sender">
                    <div className="chat__sender-chat">
                        <div className="chat__sender-msg">{data.message}</div>
                        <div className="chat__send-time">{moment(data?.created_at).fromNow()}</div>
                    </div>
                </div>
            }
        </>
    );
}

export default withTranslation()(Chat)
