import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const DropFilter = ({t,categories,filterByCategory,filterByBadge}) => {

    const badges = [
        {name : 'Hot'},
        {name : 'Trending'}
    ]


    return (
        <div className="row">
            <div className="col-lg-6">

                <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">Categories</h3>

                    <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-categories" style={{height : "350px !important"}}>
                            <ul style={{paddingLeft : "0rem"}} >
                                {
                                    categories?.map((cate,key) =>

                                        <li key={key}><a className="cursor-pointer" onClick={event => filterByCategory(cate.id)} >{cate.subname} <span>{cate.count??0}</span></a></li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">{t('FilterByBadge')}</h3>

                    <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-checkbox-circle-list">
                            <ul>

                                {
                                    badges.map((item, key) =>
                                        <li key={key}>
                                            <div className="tp-shop-widget-checkbox-circle cursor-pointer"
                                                 onClick={event => filterByBadge(item.name)}>
                                                <b className="badge badge-dark" style={{textTransform: "uppercase"}}>{t(item.name)}</b>
                                        </div>
                                        </li>
                                    )
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default withTranslation()(DropFilter)
