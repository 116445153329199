import React, {useEffect, useRef, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, format_date, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {getCustomers} from "../../../../store/Sellings/action";
import Paginate from "../../../../components/Paginate/Paginate";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import {Link} from "react-router-dom";
import Authorization from "../../../../components/Authorization";


const Customers = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('Customers')
    const tableRef = useRef(null);

    const [currentCustomers, setCurrentCustomers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState([]);


    useEffect(() => {
      getCustomers(auth.shop?.id)
    },[auth])

    useEffect(() => {
      setCurrentCustomers(customers?.slice(indexOfFirstPost, indexOfLastPost))
    },[customers])

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    const getCustomers = async (shop_id) => {
        await axios.get(SERVER_URL+"customers/"+shop_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setCustomers(response.data.list)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(customers?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentCustomers(customers?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = customers.filter(cate => {
                return cate.firstname.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentCustomers(filter)
        }
    }


    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledCustomer') : t('SureToEnabledCustomer'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}customer/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getCustomers(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }



    return (
        <AdminLayout>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">{t('Customers')}</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">{APPNAME}</a></li>
                                    <li className="breadcrumb-item active">{t('Customers')}</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                <Authorization single={true}  module="customers"  trial={true}  component={true} >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card" id="customerList">
                            <div className="card-header border-bottom-dashed">

                                <div className="row g-4 align-items-center">
                                    <div className="col-sm">
                                        <div>
                                            <h5 className="card-title mb-0">{t('List')} {t('Customers')}</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-bottom-dashed border-bottom">
                                <form>
                                    <div className="row g-3">
                                        <div className="offset-sm-8 col-sm-4">
                                            <div className="search-box">
                                                <input type="text" className="form-control search" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                                    <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="card-body">

                                <div className="table-card">
                                   <table className="table align-middle" id="customerTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th><b>#</b></th>
                                        <th className="sort" data-sort="customer_name">{t('Fullname')}</th>
                                        <th className="sort" data-sort="email">Email</th>
                                        <th className="sort" data-sort="phone">{t('Tel')}</th>
                                        <th className="sort" data-sort="date">{t('Joined')}</th>
                                        <th className="sort" data-sort="status">Status</th>
                                        <th className="sort" data-sort="action">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">

                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="7">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentCustomers?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentCustomers?.map((customer,key)=>

                                                                    <tr key={key}>
                                                                        <td><b># {key + 1}</b></td>
                                                                        <td className="customer_name">{customer.firstname} {customer.lastname}</td>
                                                                        <td className="email">{customer.email}</td>
                                                                        <td className="phone">{customer.phone}</td>
                                                                        <td className="date">{format_date(customer.created_at)}</td>
                                                                        <td className="status">
                                                                            {customer.statut == 1
                                                                                ? <span className="badge badge-soft-success">{t('Active')}</span>
                                                                                :  <span className="badge badge-soft-danger">{t('Block')}</span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                                <li className="list-inline-item edit">
                                                                                    <Link className="text-primary d-inline-block edit-item-btn0" to={`/store/customer/detail/${customer.hash_key}`}>
                                                                                        <i className="mdi mdi-eye fs-16"></i>
                                                                                    </Link>
                                                                                </li>
                                                                                <Authorization single={true}  module="customers"  trial={true}  type="action" >
                                                                                <li className="list-inline-item" >
                                                                                    {customer.statut == 1
                                                                                        ?    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(customer.id,2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                        :

                                                                                        <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(customer.id,1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                    }
                                                                                </li>
                                                                                </Authorization>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>

                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="7">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }


                                    </tbody>
                                </table>
                                </div>

                              <Paginate
                                  postsPerPage={postsPerPage}
                                  totalPosts={customers?.length}
                                  paginate={paginate}
                                  previousPage={previousPage}
                                  nextPage={nextPage}
                                  current={currentPage}
                              />
                            </div>
                        </div>

                    </div>

                </div>
                </Authorization>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
    customers : state.sellings
});
export default   compose(connect(mapStateToProps, { getCustomers })) (withTranslation()(Customers));
