import React, {useEffect, useRef, useState} from 'react';
import {CLIENT} from "../../../helpers/utils";
import {flatData} from "../../utils/utils";
import {withTranslation} from "react-i18next";

const Social1 = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {


    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const social1Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :social1Ref?.current?.offsetTop, left : social1Ref?.current?.offsetLeft, width : social1Ref?.current?.offsetWidth, height : social1Ref?.current?.offsetHeight,nodeName : social1Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);



    return (
        <section className="tp-instagram-area tp-instagram-style-4 pt-65 pb-15"
                 style={customStyle}
                 ref={social1Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
        >
            <div className="container-fluid pl-20 pr-20">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-section-title-wrapper-4 mb-50 text-center">
                            <h3 className="tp-section-title-4">{t('TrendsOnImage')}</h3>
                            <p>{t('VisitOurPages')}</p>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-lg-6 row-cols-sm-2 row-cols-1 gx-2 gy-2 gy-lg-0">
                    {
                        items?.length > 0 ?
                            <>
                                {items?.map((item,key) =>
                                                <div className="col mt-10" key={key}>
                                                   <div className="tp-instagram-item-2 w-img">
                                                  <img src={flatData(item,"image")} alt=""/>
                                                    <div className="tp-instagram-icon-2">
                                                        <a href={flatData(item,"link")} target="_blank" className="popup-image">
                                                            {flatData(item,"social") === "Instagram" &&  <i className="fa-brands fa-instagram"></i>}
                                                            {flatData(item,"social") === "X (twitter)" &&  <i className="fa-brands fa-twitter"></i>}
                                                            {flatData(item,"social") === "Facebook" &&  <i className="fa-brands fa-facebook"></i>}
                                                            {flatData(item,"social") === "Dribbble" &&  <i className="fa-brands fa-dribbble"></i>}
                                                            {flatData(item,"social") === "Tiktok" &&  <i className="fa-brands fa-tiktok"></i>}
                                                        </a>
                                                    </div>
                                    </div>
                                               </div>
                                    )}
                            </>

                            : ""

                    }

                </div>
            </div>
        </section>
    )
}

export default withTranslation()(Social1)
