import React, {Fragment, useState} from 'react';
import {CLIENT, SERVER_URL} from "../../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import _Card from "./_card";
import ReactStars from "react-rating-stars-component";
import {Alert} from "reactstrap";
import axios from "axios";
import {toast} from "react-toastify";

const Reviews = ({reviews,t,me,product,shop,created,calculReviews,reviewsStats}) => {
    const [rating,setRating] = useState(5)
    const [message,setMessage] = useState("")
    const [loading, setLoading] = useState(false)



     const handleSubmit = async () => {
        if (message === "") {

        }else if(rating === "") {

        }
        else{
            const data = {
                message : message,
                rating : rating,
                product_id : product,
                shop_id : shop?.id,
                customer_id : me?.id
            }

            setLoading(true)

            await axios.post(SERVER_URL+"subdomain/add/review",data).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    created(shop?.id)
                    setMessage("")
                    setRating(5)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
     }

     const calculPercent = (rate) => {
        if (rate){
            return  Math.round((rate * 100) / reviews.length)
        }
        else{
            return 0
        }
     }



   if (me){
       return (
           <div className="tp-product-details-review-wrapper pt-60">
               <div className="row">
                   <div className="col-lg-6">
                       <div className="tp-product-details-review-statics">

                           <div className="tp-product-details-review-number d-inline-block mb-50">
                               <h3 className="tp-product-details-review-number-title">{t("CustomerReviews")}</h3>
                               <div className="tp-product-details-review-summery d-flex align-items-center">
                                   <div className="tp-product-details-review-summery-value">
                                       <span>{Math.round(calculReviews(reviews))}</span>
                                   </div>
                                   <div className="tp-product-details-review-summery-rating d-flex align-items-center">
                                       {
                                           calculReviews(reviews) > 0 ?
                                               <>
                                                   {
                                                       [...Array(parseInt(calculReviews(reviews)))].map((item,key) => (
                                                           <span key={key}><i className="fa-solid fa-star"></i></span>
                                                       ))
                                                   }
                                               </>
                                               :
                                               ""
                                       }
                                       <p>({reviews?.length} {t("Reviews")})</p>
                                   </div>
                               </div>
                               <div className="tp-product-details-review-rating-list">

                                   {
                                       reviewsStats.length > 0 &&

                                       <>
                                           {
                                               reviewsStats?.map((data,idx) =>
                                                   <div className="tp-product-details-review-rating-item d-flex align-items-center" key={idx}>
                                                       <span>{data.rate} {t('Stars')}</span>
                                                       <div className="tp-product-details-review-rating-bar">
                                                           <span className="tp-product-details-review-rating-bar-inner" data-width={calculPercent(data.count)} style={{width: calculPercent(data.count)+"%"}}></span>
                                                       </div>
                                                       <div className="tp-product-details-review-rating-percent">
                                                           <span>{calculPercent(data.count)}%</span>
                                                       </div>
                                                   </div>
                                               )
                                           }
                                       </>
                                   }





                               </div>
                           </div>


                           <div className="tp-product-details-review-list pr-110">
                               <h3 className="tp-product-details-review-title">{t('Ratings&Reviews')}</h3>


                               {
                                   reviews.length > 0 ?
                                       <div style={{height : "300px", overflowY : "scroll"}}>
                                           {
                                               reviews.map((review,key) =>
                                                   <Fragment key={key}>
                                                       <_Card  item={review} shop={shop} />
                                                   </Fragment>
                                               )
                                           }
                                       </div>
                                       :
                                       <Alert color="info">
                                           {t('BeTheFirstToReview')}
                                       </Alert>
                               }
                           </div>
                       </div>
                   </div>
                   <div className="col-lg-6">
                       <div className="tp-product-details-review-form">
                           <h3 className="tp-product-details-review-form-title">{t('ReviewThisProduct')}</h3>
                           <div>
                               <div className="tp-product-details-review-form-rating d-flex align-items-center">
                                   <p className="mt-20">{t('YourRating')} :</p>
                                   <div className="tp-product-details-review-form-rating-icon d-flex align-items-center mt-20">
                                       <ReactStars
                                           count={5}
                                           size={20}
                                           edit={true}
                                           value={rating}
                                           onChange={setRating}
                                           emptyIcon={<i className="far fa-star"></i>}
                                           halfIcon={<i className="fa fa-star-half-alt"></i>}
                                           fullIcon={<i className="fa fa-star"></i>}
                                           activeColor="#FFB21D"
                                       />
                                   </div>
                               </div>
                               <div className="tp-product-details-review-input-wrapper">
                                   <div className="tp-product-details-review-input-box">
                                       <div className="tp-product-details-review-input">
                                           <textarea id="msg" name="msg"  onChange={event => setMessage(event.target.value)} value={message}  placeholder={t('YourReviewHere')}></textarea>
                                       </div>
                                       <div className="tp-product-details-review-input-title">
                                           <label htmlFor="msg">{t('YourReview')}</label>
                                       </div>
                                   </div>
                               </div>

                               <div className="tp-product-details-review-btn-wrapper">
                                   <button className="tp-product-details-review-btn" style={{background : shop?.color}} disabled={loading} onClick={event => handleSubmit()}>
                                       {
                                           loading ?
                                               <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                               :
                                               <span> {t('Submit')}</span>
                                       }
                                   </button>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       )
   }
   else{
       return (
          <div className="mt-30">
              <Alert color="info">
                  {t('BeTheFirstToReview')}
              </Alert>
          </div>
       )
   }
}

export default withTranslation()(Reviews)
