import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {FilePond} from "react-filepond";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";

const  ImageUpdate = ({open,setOpen,t,updateData,created,auth}) => {
    const [loading,setLoading] = useState(false)
    const [files,setFiles] = useState([])


    const submitCategoryPicture = async (e) =>{
        e.preventDefault()
        setLoading(true)
        if (files.length > 0){
            try {

                const formdata = new FormData()
                formdata.append("type", "admin")
                formdata.append("folder", "templates")
                files.map((pic)=> formdata.append("pictures", pic))

                await axios.post(SERVER_URL+"admin/template/picture/"+updateData,formdata,attachTokenToHeaders(true)).then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setOpen(false)
                        setFiles([])
                        created()
                    }else{
                        toast.error(t(response.data.message))
                    }
                })


            } catch (e) {
                console.log(e)
            }
        }
    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={<>{t('Add')} {t('Picture')}</>} size="lg">
                        <div className="row">
                            <div className="adding_page">
                                <label htmlFor="file" className="form-label">{t('Picture')}</label>
                                <FilePond
                                    files={files}
                                    allowMultiple={true}
                                    maxFiles={3}
                                    name="file"
                                    id="file"
                                    onupdatefiles={(fileItems) => {
                                        setFiles(fileItems.map((fileItem) => fileItem.file))
                                    }}
                                    labelIdle={t('DragNdrop')}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="offset-lg-4 col-lg-4 pt-3">
                                <button className="btn btn-success btn-block" disabled={loading} onClick={event => submitCategoryPicture(event)}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
        </CustomModal>
    );
}

export default ImageUpdate;