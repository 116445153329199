import React from 'react';
import {Link} from "react-router-dom";
import {
    LINK_STORE_CATEGORIES,
    LINK_STORE_CUSTOMERS,
    LINK_STORE_DASHBOARD,
    LINK_STORE_ORDERS,
    LINK_STORE_PAYMENT, LINK_STORE_PRODUCTS, LINK_STORE_SUBCATEGORIES, LINK_PRICING, LINK_STORE_CHANGE_PLAN
} from "../../routes/type";
import {withTranslation} from "react-i18next";
import {ActiveMenu, APPNAME} from "../../helpers/utils";

const TrialSidebar = ({t,auth}) => {


    return (
        <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title"><span data-key="t-menu">Menu</span></li>
            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_DASHBOARD)}`} to={LINK_STORE_DASHBOARD} >
                    <i className="ri-dashboard-2-line"></i> <span >{t('Dashboard')}</span>
                </Link>
            </li>

            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-pages">{t('Selling')}</span></li>

            <li className="nav-item">
                <Link to={LINK_STORE_ORDERS} className={`nav-link menu-link ${ActiveMenu(LINK_STORE_ORDERS)}`} >
                    <i className="ri-shopping-bag-2-fill"></i> <span>{t('Orders')}</span>
                </Link>

            </li>

            <li className="nav-item">
                <Link  to={LINK_STORE_CUSTOMERS} className={`nav-link menu-link ${ActiveMenu(LINK_STORE_CUSTOMERS)}`} >
                    <i className="ri-account-circle-line"></i> <span>{t('Customers')}</span>
                </Link>

            </li>

            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_PAYMENT)}`} to={LINK_STORE_PAYMENT}>
                    <i className="ri-pages-line"></i> <span>{t('Payments')}</span>
                </Link>
            </li>



            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-components">{t('ShopBuilder')}</span>
            </li>

            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_CATEGORIES)}`} to={LINK_STORE_CATEGORIES} >
                    <i className="ri-pencil-ruler-2-line"></i> <span data-key="t-base-ui">{t("Categories")}</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_SUBCATEGORIES)}`} to={LINK_STORE_SUBCATEGORIES} >
                    <i className="ri-stack-line"></i> <span>{t("SubCategories")}</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_PRODUCTS)}`} to={LINK_STORE_PRODUCTS} >
                    <i className="ri-honour-line"></i> <span>{t("Products")}</span>
                </Link>
            </li>

            <div className="sidebar__bottom__box" role="alert">
                <div className="sidebar__bottom__box__head">
                    <span className="badge badge-danger" style={{color : "#fff"}}>{t('FreeTrial')}</span>
                    <Link to={LINK_STORE_CHANGE_PLAN} className="btn btn-success waves-effect waves-light">{t('Upgrade')}</Link>
                </div>
                <hr/>
                <p>{t('TrialSuggessText')}</p>


            </div>

        </ul>

    );
}

export default  withTranslation()(TrialSidebar)
