import React,{useEffect,useState} from 'react';
import {CustomModal} from "../../../../../components";
import QRCode from "qrcode";
import OtpInput from "../../../../../components/OtpInput/OtpInput";


const _OptModal = ({open,setOpen,secret,verifyOtp,t})  => {

    const [loading, setLoading] = useState(true);
    const [qrcode, setQrcode] = useState(null);
    const [otp, setOtp] = useState("");

    useEffect(() => {
           if (secret?.otpauth_url)generateQR(secret?.otpauth_url)
    }, [secret]);


    const generateQR = async text => {
        try {
            const link = await QRCode.toDataURL(text)
            setQrcode(link)
            setLoading(false)
        } catch (err) {
            return console.error(err);
        }
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
       verifyOtp(otp)
    }

    return (
        <>
            <CustomModal open={open} setOpen={setOpen} title={"Two-Factor Authentication (2FA)"} >

                <div className="relative">
                    <div className="p-6 space-y-4">
                        <h5 className="text-primary m-t-20">
                            {t('Config2FA')}
                        </h5>
                        <div className='pl-2 fs-13'>
                            <li>
                                {t('InstallApp2FA')}
                            </li>
                            <br/>
                            <li>{t('2FASelectIcon')}</li>
                            <br/>
                            <li>
                                {t('2FAChooseOption')}
                            </li>
                            <br/>
                        </div>
                        <div>
                            <h5 className="text-primary m-t-20">{t('ScanQRcode')}</h5>
                            <div className="text-center">
                                {loading ?
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                    :
                                    <img
                                        className=""
                                        src={qrcode}
                                        alt="qrcode url"
                                    />
                                }
                            </div>
                        </div>
                        <div>
                            <h5 className="text-primary m-t-20">{t('Input2FACode')}</h5>
                            <p className="text-sm">SecretKey : {secret?.base32} (Base32 encoded)</p>
                        </div>
                        <div>
                            <h5 className="text-primary m-t-20">{t('VerifyCode')}</h5>
                            <p className="text-sm">
                                {t('VerificodeToComplet')}:
                            </p>
                        </div>
                        <form onSubmit={handleSubmit}>

                            <OtpInput length={6}  onInputComplete={setOtp} />
                           {/*<input placeholder="Authentication Code" type="number" name="token" className="form-control" />*/}

                              <br/>

                            <div className="row">

                                <div className="offset-lg-3 col-lg-6">
                                    <button type="submit" className="btn btn-primary btn-block">

                                        {
                                            loading ?
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>

                                                : <span>{t('Checking')}</span>
                                        }

                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default _OptModal;
