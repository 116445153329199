import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Paginate from "../../../../components/Paginate/Paginate";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import avatar from "../../../../assets/admin/images/avatar-blank.png"
import Authorization from "../../../../components/Authorization";
import _Invitation from "./_invitation";

const AdminUsers = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('Users')

    const [currentUsers,setCurrentUsers] = useState([]);
    const [users,setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getUsers()
    },[])

    useEffect(() => {
        setCurrentUsers(users?.slice(indexOfFirstPost, indexOfLastPost))
    },[users])

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [loading,setLoading] = useState(true)
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);





    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;



    useEffect(() => {
        setCurrentUsers(users?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])


    const getUsers = async () => {
        try{
            await axios.get(SERVER_URL+"admin/users/"+auth?.me.id,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    setUsers(response.data.users)
                    setRoles(response.data.roles)
                    setLoading(false)
                }else{
                    setLoading(false)
                }
            })
        } catch (e) {
            setLoading(false)
            // toast.error(e.message)
        }
    }



    const inviteCollabo = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    // const editUser = (item) => {
    //     setIsEdit(true)
    //     setUpdateData(item)
    //     setOpen(true)
    // }


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(users?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteUser'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}user/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        getUsers()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }




    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledUser') : t('SureToEnabledUser'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}user/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getUsers()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentUsers(users?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = users.filter(user => {
                return user.firstname.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentUsers(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(users.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentUsers.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListUsers'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}user/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getUsers()
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }




    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Users')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item">{t('Users')}</li>
                                <li className="breadcrumb-item active">{t('Users')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>


            <Authorization single={true}  module="users" component={true} >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card" id="tasksList">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Users')}</h5>

                                    <Authorization single={true}  module="users" type="create" >
                                        <div className="flex-shrink-0">
                                            <button className="btn btn-primary add-btn" onClick={event => inviteCollabo()}>
                                                <i className="ri-add-line align-bottom me-1"></i> {t('InviteCollaborater')}
                                            </button>
                                        </div>
                                    </Authorization>
                                </div>
                            </div>
                            <div className="card-body border border-dashed border-end-0 border-start-0">
                                <div>
                                    <div className="row g-3">
                                        <div className="col-lg-4">

                                            {
                                                isCheck.length > 0 &&

                                                <>
                                                    {
                                                        Gloading ?
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                            :
                                                            <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="offset-lg-4 col-lg-4">
                                            <div className="search-box">
                                                <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-card mb-4">
                                    <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                        <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col" style={{width: "50px"}}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                           name="selectAll"
                                                           id="selectAll"
                                                           onChange={handleSelectAll}
                                                           checked={isCheckAll}
                                                    />
                                                </div>
                                            </th>
                                            <th className="sort">{t('Avatar')}</th>
                                            <th className="sort">{t('Lastname')}</th>
                                            <th className="sort">{t('Firstname')}</th>
                                            <th className="sort">{t('Email')}</th>
                                            <th className="sort">Status</th>
                                            <th className="sort">{t('Action')}</th>
                                        </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                        {
                                            loading ?
                                                <tr>
                                                    <td colSpan="7">
                                                        <p className="text-center">
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                        </p>
                                                    </td>
                                                </tr>

                                                :

                                                <>
                                                    {
                                                        currentUsers?.length > 0
                                                            ?
                                                            <>
                                                                {
                                                                    currentUsers?.map((user,key)=>
                                                                        <tr key={key}>
                                                                            <th scope="row">
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input"
                                                                                           type="checkbox"
                                                                                           name={user.firstname}
                                                                                           id={user.id}
                                                                                           onChange={handleClick}
                                                                                           checked={isCheck.includes(user.id)}
                                                                                    />
                                                                                </div>
                                                                            </th>
                                                                            <td>
                                                                                {
                                                                                    user.avatar
                                                                                        ?
                                                                                        <div className="avatar-group">
                                                                                    <span className="avatar-group-item">
                                                                                    <img src={`${SERVER_IMG_URL}${auth.shop.shop_key}/user/${user.image}`} alt="" className="rounded-circle avatar-xxs"/>
                                                                                    </span>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="avatar-group">
                                                                                    <span className="avatar-group-item">
                                                                                    <img src={avatar} alt="" className="rounded-circle avatar-xxs"/>
                                                                                    </span>
                                                                                        </div>
                                                                                }
                                                                            </td>
                                                                            <td>{user.lastname}</td>
                                                                            <td>{user.firstname}</td>
                                                                            <td>{user.email}</td>
                                                                            <td>
                                                                                {user.statut == 1
                                                                                    ? <span className="badge badge-success text-uppercase">{t('Active')}</span>
                                                                                    :  <span className="badge badge-danger text-uppercase">{t('Inactive')}</span>
                                                                                }
                                                                            </td>
                                                                            <td className="priority">
                                                                                <ul className="list-inline  mb-0">
                                                                                    <Authorization single={true}  module="users"    type="action" >
                                                                                        <li className="list-inline-item">
                                                                                            {user.statut == 1
                                                                                                ?    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(user.id,2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                                :

                                                                                                <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(user.id,1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                            }
                                                                                        </li>
                                                                                    </Authorization>
                                                                                    {/*<li className="list-inline-item">*/}
                                                                                    {/*     <span className="edit-item-btn cursor-pointer" onClick={event => editCategory(user)}>*/}
                                                                                    {/*         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>*/}
                                                                                    {/*     </span>*/}
                                                                                    {/*</li>*/}
                                                                                    <Authorization single={true}  module="users"    type="delete" >
                                                                                        <li className="list-inline-item">
                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => deleteModal(user.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                        </li>
                                                                                    </Authorization>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan="7">
                                                                    <p className="text-center">{t('EmptyList')}</p>
                                                                </td>
                                                            </tr>
                                                    }
                                                </>
                                        }

                                        </tbody>
                                    </table>
                                </div>

                                <Paginate
                                    postsPerPage={postsPerPage}
                                    totalPosts={users?.length}
                                    paginate={paginate}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    current={currentPage}
                                />
                            </div>

                        </div>

                    </div>

                </div>
            </Authorization>

            <_Invitation open={open} setOpen={setOpen} created={getUsers}  auth={auth} roles={roles}  />

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(AdminUsers));
