import React, {useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {flatData} from "../../utils/utils";
import {CLIENT, SERVER_IMG_URL} from "../../../helpers/utils";
import {SUB_INDEX, SUB_LOGIN, SUB_TERMS, SUB_WISHLIST} from "../../../pages/Sub/Seller/routes/type";
import {Link} from "react-router-dom";

const Footer = ({t,block,toggleHightLight,customStyle,isEditor,shop_data}) => {

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const footerRef = useRef()

    const getBlockSize = () => {
        setDimension({top :footerRef?.current?.offsetTop, left : footerRef?.current?.offsetLeft, width : footerRef?.current?.offsetWidth, height : footerRef?.current?.offsetHeight,nodeName : footerRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()
        toggleHightLight(data,dimension,status)
    }


    return (
        <footer ref={footerRef}
                onMouseEnter={() => isEditor && getDimension(block,true) }
                onMouseLeave={() => isEditor && getDimension(block,false)}
                style={customStyle}
        >
            <div className="tp-footer-area" style={{background : flatData(block.contentEditable,"background")}}>
                <div className="tp-footer-top pt-95 pb-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6">
                                <div className="tp-footer-widget footer-col-1 mb-50">
                                    <div className="tp-footer-widget-content">
                                        <div className="tp-footer-logo">
                                            <Link to={isEditor ? "#" : SUB_INDEX}>
                                                <img
                                                    src={shop_data ? SERVER_IMG_URL + "/" + shop_data.shop_key + "/logo/" + shop_data.logo : CLIENT + '/imgs/logo/logo.png'}
                                                    alt="logo" style={{width: "135px"}}/>
                                            </Link>
                                        </div>
                                        <p className="tp-footer-desc">

                                            {!shop_data ? "Lorem ipsum dolor sit amet, consectetur adipisicing elit." : shop_data.description}
                                        </p>

                                        <div className="tp-footer-social">
                                            {flatData(block.contentEditable, 'show_facebook') &&
                                                <a href={flatData(block.contentEditable, 'facebook')} target='_blank'
                                                   style={{marginLeft: "10px"}}><i
                                                    className="fa-brands fa-facebook-f"></i></a>}
                                            {flatData(block.contentEditable, 'show_x') &&
                                                <a href={flatData(block.contentEditable, 'x')} target='_blank'
                                                   style={{marginLeft: "10px"}}><i className="fa-brands fa-twitter"></i></a>}
                                            {flatData(block.contentEditable, 'show_instagram') &&
                                                <a href={flatData(block.contentEditable, 'instagram')} target='_blank'
                                                   style={{marginLeft: "10px"}}><i
                                                    className="fa-brands fa-instagram"></i></a>}
                                            {flatData(block.contentEditable, 'show_linkedin') &&
                                                <a href={flatData(block.contentEditable, 'linkedin')} target='_blank'
                                                   style={{marginLeft: "10px"}}><i
                                                    className="fa-brands fa-linkedin-in"></i></a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="tp-footer-widget footer-col-2 mb-50">
                                    <h4 className="tp-footer-widget-title">{t('Account')}</h4>
                                    <div className="tp-footer-widget-content">
                                        <ul>
                                            <li><Link to={SUB_WISHLIST}>{t('WishList')}</Link></li>
                                            <li><Link to={SUB_LOGIN}>{t('MyAccount')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <div className="tp-footer-widget footer-col-3 mb-50">
                                    <h4 className="tp-footer-widget-title">Information</h4>
                                    <div className="tp-footer-widget-content">
                                        <ul>
                                            <li><a href="#">{t('About')}</a></li>
                                            <li><a href="#">{t('Service')}</a></li>
                                            <li><Link to={SUB_TERMS}>Terms & Conditions</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <div className="tp-footer-widget footer-col-4 mb-50">
                                    <h4 className="tp-footer-widget-title">{t('TalkToUs')}</h4>
                                    <div className="tp-footer-widget-content">
                                        <div className="tp-footer-talk mb-20">
                                            <span>{t('GotQuestionsCallUs')}</span>
                                            <h4><a href={`tel:${!shop_data ? "+228 90 00 00 00" : shop_data.phone}`}>{!shop_data ? "+228 90 00 00 00" : shop_data.phone}</a></h4>
                                        </div>
                                        <div className="tp-footer-contact">
                                            <div className="tp-footer-contact-item d-flex align-items-start">
                                                <div className="tp-footer-contact-icon">
                                                 <span>
                                                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                     <path d="M1 5C1 2.2 2.6 1 5 1H13C15.4 1 17 2.2 17 5V10.6C17 13.4 15.4 14.6 13 14.6H5" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                     <path d="M13 5.40039L10.496 7.40039C9.672 8.05639 8.32 8.05639 7.496 7.40039L5 5.40039" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                     <path d="M1 11.4004H5.8" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                     <path d="M1 8.19922H3.4" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                  </svg>
                                               </span>
                                                </div>
                                                <div className="tp-footer-contact-content">
                                                    <p><a href={`mailto:${!shop_data ? "example@mail.com" : shop_data.email}`}>{!shop_data ? "example@mail.com" : shop_data.email}</a></p>
                                                </div>
                                            </div>
                                            <div className="tp-footer-contact-item d-flex align-items-start">
                                                <div className="tp-footer-contact-icon">
                                                   <span>
                                                      <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                         <path d="M8.50001 10.9417C9.99877 10.9417 11.2138 9.72668 11.2138 8.22791C11.2138 6.72915 9.99877 5.51416 8.50001 5.51416C7.00124 5.51416 5.78625 6.72915 5.78625 8.22791C5.78625 9.72668 7.00124 10.9417 8.50001 10.9417Z" stroke="currentColor" strokeWidth="1.5"/>
                                                         <path d="M1.21115 6.64496C2.92464 -0.887449 14.0841 -0.878751 15.7889 6.65366C16.7891 11.0722 14.0406 14.8123 11.6313 17.126C9.88298 18.8134 7.11704 18.8134 5.36006 17.126C2.95943 14.8123 0.210885 11.0635 1.21115 6.64496Z" stroke="currentColor" strokeWidth="1.5"/>
                                                      </svg>
                                                   </span>
                                                </div>
                                                <div className="tp-footer-contact-content">
                                                    <p>{!shop_data ? "Lomé - TOGO" : shop_data.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tp-footer-bottom">
                    <div className="container">
                        <div className="tp-footer-bottom-wrapper">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="tp-footer-copyright">
                                        <p>© {shop_data ? new Date(shop_data?.created_at).getFullYear() : "2024"} {shop_data?.shopName}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="tp-footer-payment text-md-end">
                                        <p>
                                            <img src={CLIENT+"/builder/icon/tmoney.png"} style={{width : "24px"}} alt={shop_data?.shopName}/>
                                            <img src={CLIENT+"/builder/icon/flooz.png"} style={{width : "24px"}} alt={shop_data?.shopName}/>
                                            <img src={CLIENT+"/builder/icon/payment-option.png"} alt={shop_data?.shopName}/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
);
}

export default withTranslation()(Footer)
