import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../utils/utils";

const ContactMap = ({block,customStyle,toggleHightLight,t,isEditor}) => {

    const [position, setPosition] = useState("")


    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })


    const contactMapRef = useRef()

    const getBlockSize = () => {
        setDimension({top :contactMapRef?.current?.offsetTop, left : contactMapRef?.current?.offsetLeft, width : contactMapRef?.current?.offsetWidth, height : contactMapRef?.current?.offsetHeight,nodeName : contactMapRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    useEffect(() => {
        const link = "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15864.510927810985!2d1.1833053999999998!3d6.2468938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2stg!4v1694262466817!5m2!1sfr!2stg"
        const google = "https://maps.google.com/maps?q="+flatData(block.contentEditable,"Latitude")+","+flatData(block.contentEditable,"Longitude")+"&output=embed"

        setPosition(google)
    }, []);


    return (
        <section className="tp-map-area"
                 style={customStyle}
                 ref={contactMapRef}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false) }

        >
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-map-wrapper">
                           {/* <div className="tp-map-hotspot">*/}
                           {/*<span className="tp-hotspot tp-pulse-border">*/}
                           {/*   <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                           {/*      <circle cx="6" cy="6" r="6" fill={flatData(block.contentEditable,'PinColor')}></circle>*/}
                           {/*   </svg>*/}
                           {/*</span>*/}
                           {/* </div>*/}
                            <div className="tp-map-iframe">
                               <iframe  src={"https://maps.google.com/maps?q="+flatData(block.contentEditable,"Latitude")+","+flatData(block.contentEditable,"Longitude")+"&z="+flatData(block.contentEditable,"zoom")+"&ie=UTF8&iwloc=B&output=embed"}></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactMap;
