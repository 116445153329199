import React, {useEffect, useState} from 'react';

const Hightlight = ({size,block,DeleteSection}) => {

    const [styles, setStyles] = useState({})

    useEffect(() => {
        setStyles({
            display: size.width > 0 ? "block" : "none",
            top: `${size.top}px`,
            left: `${size.left}px`,
            width: `${size.width}px`,
            height: `${size.height}px`,
           /* position : "relative",
            zIndex : 9999*/
        })
    }, [block.id]);




    return (
        <div id="highlight-box" style={styles}>

            <div id="highlight-name">
                <span className="name">{block.name}</span>
                <span className="type">{size?.nodeName}</span>
                <span className="ml-2 cursor-pointer" onClick={event => DeleteSection(block.id)}><i className="bx bx-trash"></i></span>
            </div>

            {/*<div id="section-actions" className="">*/}
            {/*    <span id="add-section-btn" title="Add element" onClick={(event) => toggleModal(true)}>*/}
            {/*        <i className="la la-plus"></i>*/}
            {/*    </span>*/}
            {/*</div>*/}
        </div>
    )
}

export default Hightlight;
