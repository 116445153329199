import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";


const _Profil = ({t,auth}) => {

    document.title = JSON.parse(localStorage.getItem('__S78456321__'))?.shopName+" :: "+t('Profile')

    const [orders, setOrders] = useState([]);
    useEffect(() => {
        getUserOrders()
    }, []);


    const getUserOrders = async () => {
        await axios.get(SERVER_URL+"shop/stats/"+auth?.user?.id,).then((response)=>{
            if (response.data.succeed){
                setOrders(response.data.orders)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }



    return (
        <div>
            <div className="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30" style={{padding : "10px"}}>
                <div className="dash__pad-2">
                    <h3 className="dash__h1 u-s-m-b-14">{t('Profile')}</h3>

                    <div className="row">
                        <div className="col-lg-6 u-s-m-b-30">
                            <div
                                className="dash__box dash__box--bg-grey dash__box--shadow-2 u-h-100" style={{background : "#f4f4f4"}}>
                                <div className="dash__pad-3">
                                    <h4 className="dash__h2 u-s-m-b-8">{t('ProfilPersonal').toUpperCase()}</h4>

                                    <span className="dash__text">{auth?.user.firstname} {auth?.user.lastname}</span>

                                    <span className="dash__text">{auth?.user.email}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 u-s-m-b-30">
                            <div
                                className="dash__box dash__box--bg-grey dash__box--shadow-2 u-h-100" style={{background : "#f4f4f4"}}>
                                <div className="dash__pad-3">
                                    <h4 className="dash__h2 u-s-m-b-8">{t('BillingAddress').toUpperCase()}</h4>
                                    <span className="dash__text-2 u-s-m-b-8">{orders.length > 0 ? orders[0].delivery_fullname : ""}</span>
                                    <span className="dash__text">{orders.length > 0 ? orders[0].delivery_address : ""} -  {orders.length > 0 ? orders[0].delivery_city : ""} -  {orders.length > 0 ? orders[0].delivery_country : ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
    cart : state.cart,
});


export default compose(withRouter, connect(mapStateToProps )) (withTranslation()(_Profil))

