import {
    LOGIN_LOADING,
    LOGOUT_USER_SUCCESS,
    SUB_ME_FAIL,
    SUB_ME_LOADING, SUB_ME_SUCCESS, LOGIN_SUCCESS, LOGIN_FAIL
} from './type';


const initialState = {
    token: localStorage.getItem('__S78456100__')??null,
    isAuthenticated: false,
    loading: false,
    user: null,
    error: null,
    message : null,
};


 const  LoginSubReducer = (state = initialState, { type, payload })  =>{
    switch (type) {
        case LOGIN_LOADING:
        case SUB_ME_LOADING:
            return {
                ...state,
                loading: true,
                appLoaded: false,
                error: null,
            };

        case LOGIN_SUCCESS:
        case SUB_ME_SUCCESS:
            localStorage.setItem('__S78456350__',JSON.stringify(payload.user))
            localStorage.setItem('__S78456100__',payload.token)
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                token: payload.token,
                user: payload.user,
                message : payload.message,
                error: null,
            };


        case LOGIN_FAIL:
        case SUB_ME_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: payload.error,
                appLoaded: true,
            };

        case LOGOUT_USER_SUCCESS:
            localStorage.removeItem('__S78456350__')
            localStorage.removeItem('__S78456100__')
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                loading: false,
                error: null,
            };

        default:
            return state;
    }
}

export default LoginSubReducer
