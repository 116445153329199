import React from 'react';
import {withTranslation} from "react-i18next";
import moment from "moment/moment";
import 'moment/locale/fr'

const _Chat = ({data,auth,t}) => {
    return (
        <>
            {
                auth?.user.id != data.user_id &&
                <div className="seller__msg left-msg">
                    <div className="msg-bubble">
                        <div className="msg-info">
                            <div className="msg-info-name">Support</div>
                            <div className="msg-info-time">{moment(data?.created_at).fromNow()}</div>
                        </div>

                        <div className="msg-text">
                            {data.message}
                        </div>
                    </div>
                </div>
            }

            {
                auth?.user.id == data.user_id &&

                <div className="seller__msg right-msg">
                    <div className="msg-bubble">
                        <div className="msg-info">
                            <div className="msg-info-time">{moment(data?.created_at).fromNow()}</div>
                        </div>

                        <div className="msg-text">
                            {data.message}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default withTranslation()(_Chat)
