import React from 'react';
import {withTranslation} from "react-i18next";
import placeholder from '../styles/placeholder.jpg'
import {Link} from "react-router-dom";
import {SERVER_IMG_URL} from "../../../../../helpers/utils";

const TrialCardVertical = ({t,category,shop}) => {
    return (
        <div className="column-product__item m-t-20">
            <div className="product-l">
                <div className="product-l__img-wrap">
                    <Link to={`/categorie/${category?.slug}`} className="aspect aspect--square u-d-block product-l__link">
                        <img className="aspect__img" src={category.image ? SERVER_IMG_URL+shop?.shop_key+"/categories/"+category.image : placeholder} alt=""/>
                    </Link>
                </div>
                <div className="product-l__info-wrap">
                    <br/>
                    <span className="product-l__name"><Link to={`/categorie/${category?.slug}`}>{category?.category?.toUpperCase()}</Link></span>
                    <span className="product-l__category" style={{fontSize : "12px"}}>
                       {category?.description.substring(0, 100)}...
                    </span>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TrialCardVertical);
