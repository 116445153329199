import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {APPNAME, DOMAINE, SERVER_URL} from "../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import Layout from "../../../layouts/front/Layout";


const Contact = ({t}) => {
    document.title = APPNAME+" :: "+t('Contact')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('Contact'))
    document.querySelector("meta[name='description']").setAttribute("content", "Avez-vous des questions ? Commençons à parler")

    const  [loading,setLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        fullname: Yup.string()
            .required(t('FullnameRequired')),
        phone: Yup.string()
            .required(t('PhoneRequired')),
        email: Yup.string()
            .required(t('EmailRequired')),
        sujet: Yup.string()
            .required(t('SubjectRequired')),
        message: Yup.string()
            .required(t('MessageRequired')),
    });

    const initialValues = {
        fullname: '',
        phone: '',
        email: '',
        sujet: '',
        message: '',
    }

    const handleSubmit = async (values,{resetForm}) => {
        setLoading(true)
        await axios.post(SERVER_URL+"contact/message",values).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                resetForm({values : ""})
                 toast.success(t(response.data.message))
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    return (
        <Layout>

            <section className="shop-area p-t-180 p-b-130">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        <div className="header_custom_pricing">
                            <p>
                                {t('Contact')}
                            </p>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            </section>

            <section className="blog-area  p-b-130">
                <div className="container">
                    <div className="row justify-content-lg-start justify-content-center">

                        <div className="col-lg-12">
                            <div className="contact-form-area m-t-md-100">
                                <div className="common-heading tagline-boxed m-b-40">
                                    <h2 className="title">{t('DoYouHaveQuestions')}</h2>
                                </div>
                                <div className="contact-form-v2">
                                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                        {({ errors, touched }) =>
                                            <Form className="row">
                                        <div className="form-group col-lg-6 input-field m-b-30">
                                            <Field type="text" id="fullname" placeholder={t('FullName')} name="fullname" style={{width : "100%"}}  />
                                                <label htmlFor="fullname">{t('Name')}</label>
                                            {errors.fullname && touched.fullname ? (
                                                <div className='text-danger'>{errors.fullname}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-lg-6 input-field m-b-30">
                                            <Field type="text" id="phone" placeholder={t('Phone')} name="phone" style={{width : "100%"}} />
                                                <label htmlFor="phone">Phone</label>
                                            {errors.phone && touched.phone ? (
                                                <div className='text-danger'>{errors.phone}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-lg-6 input-field m-b-30">
                                            <Field type="email" id="email" placeholder="Email" name="email"  style={{width : "100%"}}/>
                                                <label htmlFor="email">Email</label>
                                            {errors.email && touched.email ? (
                                                <div className='text-danger'>{errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-lg-6 input-field m-b-30">
                                            <Field type="text" id="subject" placeholder={t('SubjectPlaceholder')} style={{width : "100%"}} name="sujet"/>
                                                <label htmlFor="subject">{t('Subject')}</label>
                                            {errors.sujet && touched.sujet ? (
                                                <div className='text-danger'>{errors.sujet}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group  input-field textarea-field m-b-30">
                                            <Field as="textarea" id="message" placeholder="Message" name="message" />
                                            {errors.message && touched.message ? (
                                                <div className='text-danger'>{errors.message}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group  mb-0 input-field right" >
                                            <button type="submit" className="template-btn primary-bg-5" disabled={loading}>

                                                {
                                                    loading ?
                                                        <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>{t('Loading')}...</>
                                                        :
                                                        <span>{t('Send')} <i className="fas fa-arrow-right"></i></span>
                                                }

                                                </button>
                                        </div>
                                            </Form>
                                        }

                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}

export default   withTranslation() (Contact);
