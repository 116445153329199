import {Fragment, useEffect, useRef, useState} from "react";
import {withTranslation} from "react-i18next";
import {flatData} from "../../utils/utils";

const BreadCrumbs = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {
    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const breadcrumbs = useRef()

    const getBlockSize = () => {
        setDimension({top :breadcrumbs?.current?.offsetTop, left : breadcrumbs?.current?.offsetLeft, width : breadcrumbs?.current?.offsetWidth, height : breadcrumbs?.current?.offsetHeight,nodeName : breadcrumbs?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);

    return(
        <section className="breadcrumb__area include-bg pt-30 pb-50"
                 style={customStyle}
                 ref={breadcrumbs}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
        >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="breadcrumb__content p-relative z-index-1">
                                <h1 className="breadcrumb__title">{flatData(items[items.length - 1],'title')}</h1>
                                <div className="breadcrumb__list">
                                    {items.map((item, index) =>
                                        <Fragment key={index}>

                                            {
                                                flatData(item, "asLink") ?
                                                    <span>
                                                        <a href={!isEditor && flatData(item, "link")}>{flatData(item, "title")}</a>
                                                    </span>

                                                    :
                                                    <span>{flatData(item, "title")}</span>

                                            }

                                        </Fragment>
                                                )}
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default withTranslation()(BreadCrumbs)
