import SubNotFound from "../views/SubNotFound/SubNotFound";
import {
    Home,
    Service,
    About,
    Contact,
    Shop
} from "../views/Dynamic"
import {
    SUB_ABOUT, SUB_CART, SUB_CATEGORY, SUB_CHECKOUT,
    SUB_CONTACT, SUB_CUSTOMER_DASHBOARD,
    SUB_INDEX,
    SUB_LOGIN,
    SUB_PRODUCTS,
    SUB_REGISTER,
    SUB_SERVICE, SUB_TERMS,
    SUB_VERIFY_PASS, SUB_WISHLIST
} from "./type";
import {
    Cart,
    Categories,
    Checkout,
    Login,
    Register,
    Update,
    Verify,
    Wishlist,
    Subcategories,
    ProductBySubcategories,
    ProductDetail, AdminContent, SuccessLogin, Tracked, Result,TermConditions
} from "../views";
import Invoice from "../views/Customers/Invoice";
import OrderDetail from "../views/Customers/OrderDetail";
import SupportConversation from "../views/Customers/SupportConversation";


const subDynamicRoutes = [

    { name : "Home", path: SUB_INDEX, isLogged : false, component : Home},
    { name : "Shop", path: SUB_PRODUCTS, isLogged : false, component : Shop},
    { name : "Contact", path: SUB_CONTACT, isLogged : false, component : Contact},
    { name : "About", path: SUB_ABOUT, isLogged : false, component : About},
    { name : "Services", path: SUB_SERVICE, isLogged : false, component : Service},
]

const subPublicRoutes = [
    { name : "Login", path: SUB_LOGIN, isLogged : false, component : Login},
    { name : "Register", path: SUB_REGISTER, isLogged : false, component : Register},
    { name : "Verify", path: SUB_VERIFY_PASS, isLogged : false, component : Verify},
    { name : "Update", path: "/update/password/:token", isLogged : false, component : Update},
    { name : "Cart", path: SUB_CART, isLogged : false, component : Cart},
    { name : "Categories", path: SUB_CATEGORY, isLogged : false, component : Categories},
    { name : "Checkout", path: SUB_CHECKOUT, isLogged : false, component : Checkout},
    { name : "WishList", path: SUB_WISHLIST, isLogged : false, component : Wishlist},
    { name : "Subcategories", path: "/shop/:category", isLogged : false, component : Subcategories},
    { name : "ProductBySub", path: "/:category/:subcategory", isLogged : false, component : ProductBySubcategories},
    { name : "ProductDetail", path: "/product/detail/:slug", isLogged : false, component : ProductDetail},
    { name : "TermConditions", path: SUB_TERMS, isLogged : false, component : TermConditions},

    { name : "SuccessGoogleLogin", path: "/oauth/google/success/:token", isLogged : false, component : SuccessLogin},


     // Admin
    { name : "CustomerDashboard", path: SUB_CUSTOMER_DASHBOARD, isLogged : true, component : AdminContent},
    { name : "TrackOrder", path: "/order/track/:order_id", isLogged : true, component : Tracked},
    { name : "InvoiceDetail", path: "/order/invoice/:order_id", isLogged : true, component : Invoice},
    { name : "InvoiceDetail", path: "/results", isLogged : false, component : Result},
    { name : "OrderDetail", path: "/order/detail/:order_id", isLogged : true, component : OrderDetail},
    { name : "SupportDetail", path: "/user/support/:ticket_id", isLogged : true, component : SupportConversation},

    { path: "*",  component : SubNotFound },


]

const subPrivateRoutes = [


]




export { subDynamicRoutes, subPublicRoutes , subPrivateRoutes }
