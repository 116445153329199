import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {toast} from "react-toastify";
import axios from "axios";
import {SERVER_URL} from "../../../../helpers/utils";
import line2 from '../../../../assets/front/img/particle/title-line-2.png'
import bg from '../../../../assets/front/img/particle/newsletter-bg.png'
import particle2 from '../../../../assets/front/img/particle/particle-2.png'
import particle3 from '../../../../assets/front/img/particle/particle-3.png'
import particle4 from '../../../../assets/front/img/particle/particle-4.png'
import particle5 from '../../../../assets/front/img/particle/particle-5.png'

const  Part3 = ({t}) => {

    const [loading,setLoading] = useState(false)
    const [newsletter,setNewsletter] = useState("")

    const submitNewsletter =  async (e) => {
        e.preventDefault()

        if (newsletter === ""){
            toast.error(t('EmailRequired'))
        }else{
            setLoading(true)

            await axios.post(SERVER_URL+"newsletter/subscriber",{email : newsletter}).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    setNewsletter("")
                    toast.success(t(response.data.message))
                }else{
                    toast.error(t(response.data.message))
                }
            })
        }
    }



    return (
        <section className="newsletter-area bg-cover-center bg-soft-grey-color p-t-130 p-b-130"
                 style={{backgroundImage: `url(${bg})`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-10 col-md-11">
                        <div className="newsletter-text">
                            <div className="common-heading text-center m-b-40 p-0 px-lg-5">
                                <span className="tagline">{t('SubscribeNewsletter')}</span>
                                <h2 className="title">{t('SubscribeNewsletterTobeInfomed')}</h2>
                                <img src={line2} alt="Image" className="Line"/>
                            </div>

                            <form onSubmit={event => submitNewsletter(event)} className="newsletter-form form-two">
                                <div className="input-field">
                                    <input type="email" placeholder="Email" value={newsletter} onChange={event => setNewsletter(event.target.value)} style={{border : "none"}} required />
                                        <button className="template-btn template-btn-2 primary-bg-5">
                                            {
                                                loading
                                                ?
                                                    <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>{t('Loading')}...</>
                                                    :
                                                    <>
                                                        {t('Subscribe')}
                                                        <i className="far fa-arrow-right"></i>
                                                    </>
                                            }
                                        </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
         <div className="newsletter-particle-effect d-none d-md-block">
                <img className="particle-1 animate-float-bob-y" src={particle2} alt=""/>
                <img className="particle-2 animate-zoominout" src={particle3} alt=""/>
                <img className="particle-3 animate-zoominout" src={particle4} alt=""/>
                <img className="particle-4 animate-zoominout" src={particle5} alt=""/>
        </div>
        </section>
    );
}

export default withTranslation()(Part3);