import React, {useEffect} from 'react';
import {withTranslation} from "react-i18next";
import {
    LINK_ADMIN_DASHBOARD, LINK_LANDING,
    LINK_PASSWORD_UPDATE,
    LINK_REGISTER_STORE
    , LINK_STORE_STARTER
} from "../../../routes/type";
import {Link, Redirect, withRouter} from "react-router-dom";
import {loginUser} from "../../../store/Auth/action";
import {compose} from "redux";
import {connect} from "react-redux";
import { Formik, Form, Field } from "formik"
import { toast } from 'react-toastify';
import * as Yup from "yup"
import {APPNAME, CustomChangeLanguage, SUPPORT_MAIL} from "../../../helpers/utils";
import logo from "../../../assets/logo.png"


const Login = ({t,auth,loginUser}) => {

    document.title = APPNAME+" :: "+t('LoginShop')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('LoginShop'))


    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('EmailRequired')),
        password: Yup.string()
            .required(t('PasswordRequired')),
    });

         const initialValues = {
            email: '',
            password: '',
           user_agent : window.navigator.userAgent
        }

    const handleSubmit = async (values) => {
        loginUser(values)
    }


    useEffect(()=>{
        if (auth.error && auth.error !== "AccountDisabled") toast.error(t(auth.error))
    },[auth.error])


    if (auth.me?.role_id === 2) return <Redirect to={LINK_STORE_STARTER} exact={true} />
    if (auth.me?.role_id === 3) return <Redirect to={LINK_ADMIN_DASHBOARD} />
    return (
        <section className="sign-in-section p-b-2">
            <div className="container-fluid reg_panel_all">
                <div className="row">
                    <div className="col-lg-4 left__panel">
                        <div className="">
                            <div className="header__left__panel">
                                <Link to={LINK_LANDING}><h3>{APPNAME}</h3></Link>
                                <div className="d-none d-sm-block">
                                    <button  className={`text-white  ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "selected_lng_white" }`} onClick={event => CustomChangeLanguage('fr')} style={{border : "none",background : "none",marginRight : "10px"}}>FR</button>
                                    <button  className={`text-white ${localStorage.getItem('I18N_LANGUAGE') === "en" && "selected_lng_white"}`} onClick={event => CustomChangeLanguage('en')} style={{border : "none",background : "none",marginRight : "10px"}}>EN</button>
                                </div>
                            </div>

                            <div className="left__panel__content">
                                <h1 className="text-white" style={{fontSize : "40px"}}>{t('CustomerEspace')}</h1>
                                <p>{t('CustomerEspaceText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 bg-white">
                        <div className="row">
                            <div className="offset-lg-3 col-lg-5" style={{marginTop: "50px"}}>
                                <div className="sign-in-up-wrapper" style={{marginTop: "175px"}}>
                                    <div className="text-center">
                                        <img src={logo} alt="kiiako" style={{width: "150px"}}/>
                                    </div>

                                    {
                                        auth.error === "AccountDisabled" ?
                                            <div className="alert alert-danger" role="alert">
                                                {t('AccountDisabled')} <b>{SUPPORT_MAIL}</b>
                                            </div>
                                            :
                                            null
                                    }
                                    <Formik initialValues={initialValues} onSubmit={handleSubmit}
                                            validationSchema={validationSchema}>
                                        {({errors, touched}) =>
                                            <Form>
                                                <div className="">
                                                    <h4 className="form-title">{t('LogToAccount')}</h4>
                                                    <div className="field-group">
                                                        <label htmlFor="">Email</label>
                                                        <Field name="email" type="email" autoFocus className='front' placeholder={t('Email')}/>
                                                        {errors.email && touched.email ? (
                                                            <div className='text-danger'>{errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="field-group">
                                                        <label htmlFor="">{t('Password')}</label>
                                                        <Field name="password" type="password" className='front'
                                                               placeholder={t('Password')}/>
                                                        {errors.password && touched.password ? (
                                                            <div className='text-danger'>{errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="">
                                                        <button className="template-btn primary-bg-5 btn-block"
                                                                type="submit">
                                                            {
                                                                auth.isLoading ?
                                                                    <><span className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"/> {t('Loading')}...</>
                                                                    :
                                                                    <span>{t('LoginShop')}</span>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-note">
                                                    <p className="text-center text-muted">{t('RegisterBefore')} <Link to={LINK_REGISTER_STORE} className="underline text-primary">{t('Register')}</Link>
                                                        <br/>

                                                        <Link to={LINK_PASSWORD_UPDATE}
                                                              className="forgot-password underline text-primary"
                                                              style={{textDecoration: "underline !important"}}>{t('ForgetPassword')}</Link>

                                                    </p>


                                                </div>
                                            </Form>
                                        }

                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default compose(withRouter, connect(mapStateToProps, {loginUser}))(withTranslation()(Login))
