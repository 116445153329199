import i18n from '../locales/i18n'
import { currencyLink, ExchangeKey } from './keys'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment/moment'
import { decrypt, encrypt } from 'n-krypta'
import CryptoJS from 'crypto-js'

export const APPNAME = "Kiiako"
 export const HOSTER = ["kiiako"]
export const CLI = "kiiako.io"

export const BUILDER = APPNAME+" Builder"

export const ROOT = "Kiiako.io"

export const CLIENT = "https://kiiako.io"

export const CLIENT_LIST = ["kiiako.io", "kiiako.localhost","http://kiiako.io","https://kiiako.io","http://kiiako.io/", "https://kiiako.io/","http://kiiako.localhost","http://kiiako.localhost/",'kiiako']

export const DOMAINE = window.location.host

     export const SERVER = window.location.origin === "http://kiiako.localhost" ? "http://localhost:5000" : "https://api.kiiako.io"
   //export const SERVER = "http://localhost:5000"

const secretEncryption = 'subdomaineFreeKiiako';


export const SUPPORT_MAIL = "support@technctrl.com"

export const TECHNCTRL = "Tech N Ctrl"
export const TECHNCTRL_LINK = "https://technctrl.com"

export const RCCM ="TG-LFW-01-2024-B13-00605"
export const CapitalSocial = "1.000.000 Francs CFA"
export const LocalSocial =  "Adidogomé Sagbado  à 400m du marché de Logoté"
export const PHONE =  "+ (228) 92 36 35 33"

export const NIF = "1001936284"
export const ADRESS_MAIL = "piouhassan@gmail.com"
export const OwnerShip = "le site est la propriété de Tech N Ctrl Sarl U. Il est administré au Togo et hébergé sur "

export const secretEncryptCode = "subdomainPaidKiiako";

export const Facebook_link = "https://www.facebook.com/profile.php?id=61557349304103"
export const X_link = "https://twitter.com/KKiiako"
export const Youtube_link = "https://www.youtube.com/channel/UCRsygBj0p9zGRxIIBGX_J4w"
export const Linkedin_link = "https://www.linkedin.com/showcase/103105423"
export const instagram_link = "https://www.instagram.com/kiiako.saas"

export const GooglePlayLinkDeliverer = ""
export const AppStoreLinkDeliverer = ""

export const services = [
    {
        fr : 'Ecommerce personnalisé',
        en : 'Personalized ecommerce',
        content : '',
        contenu : ''
    },
    {
        fr : 'Gestion des ventes',
        en : 'Sales management',
        content : '',
        contenu : ''
    },
    {
        fr : 'Gestion des livreurs',
        en : 'Management of delivery people',
        content : '',
        contenu : ''
    },
    {
        fr : 'Gestion de stock',
        en : 'Stock management',
        content : '',
        contenu : ''
    },

    {
        fr : "Genération d'étiquettes",
        en : 'Label generation',
        content : '',
        contenu : ''
    },

    {
        fr : "Gestion des cartes cadeau",
        en : 'Gift card management',
        content : '',
        contenu : ''
    },

    {
        fr : "Fidélisation des clients",
        en : 'Customer loyalty',
        content : '',
        contenu : ''
    },

    {
        fr : "Paiements automatisé",
        en : 'Automated payments',
        content : '',
        contenu : ''
    },

    {
        fr : "Genération de facture",
        en : 'Invoice generation',
        content : '',
        contenu : ''
    },



]


export const encryptData = (text) => {
    return CryptoJS.AES.encrypt(
        JSON.stringify(text),
        secretEncryptCode
    ).toString();
};

export const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretEncryptCode);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

export const SERVER_URL = SERVER+"/api/v1/"
export const SERVER_IMG_URL = SERVER+"/uploads/"

export const MOBILE_APP = "Kiiako Delivery"



export const PUBLIC_GOOGLE_OAUTH_REDIRECT_URL= SERVER_URL+"sessions/oauth/google"


export const loadCurrency = () => {
    // const dayOfWeekDigit = new Date().toLocaleDateString('fr-FR', {weekday: 'long'})
    // if (localStorage.getItem('rates') == null){
    //     axios.get(currencyLink, {
    //         params: {
    //             app_id: ExchangeKey
    //         }
    //     })
    //         .then((response) => {
    //             localStorage.setItem('rates',JSON.stringify(response.data.rates))
    //             return response.data
    //         }).catch((error) =>{
    //         console.log(error)
    //     })
    // }else{
    //     return JSON.parse(localStorage.getItem('rates'))
    // }
}


const getCurrency = () => {
    // const dayOfWeekDigit = new Date().toLocaleDateString('fr-FR', {weekday: 'long'})
    if (localStorage.getItem('rates') == null){
        axios.get(currencyLink)
            .then((response) => {
                localStorage.setItem('rates',JSON.stringify(response.data.rates))
                return response.data.rates
            }).catch((error) =>{
            console.log(error)
        })
    }else{
        return JSON.parse(localStorage.getItem('rates'))
    }
}

export const filesFolder = (shop_key,type) =>{
     return SERVER_IMG_URL+"/"+shop_key+"/"+type+"/"
}

export const filterAndSum = (payment,prop,type) =>{
    let sum = 0;
    if (type != 0){
        const filter = payment.filter(e => e.type == type)
        if (filter.length > 0){
            filter.forEach(obj => {
                for (let property in obj) {
                    if(property === prop)
                        sum += parseInt(obj[property]);
                }
            })
        }
    }else {
        payment.forEach(obj => {
            for (let property in obj) {
                if(property === prop)
                    sum += parseInt(obj[property]);
            }
        })
    }
    return sum
}

export const  numberWithCommas = (num) => {
    if(num){
        return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }else{
        return 0
    }
}

export const loadScript = (src) => {
    var tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    var body = document.getElementById('body');
    body.appendChild(tag)

}

export const CustomChangeLanguage = lang => {
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
     return lang
}

export const attachTokenToHeaders = (file = false,sub = false,blob = false) => {
    const token = Cookies.get('auth-token')

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };


    if (file) {
        config.headers['Accept'] = "application/json"
         config.headers['Content-Type'] =  'application/x-www-form-urlencoded'
    }

    if (token) {
        config.headers['auth-token'] = token;
    }

    if (sub){
        const subToken = localStorage.getItem('__S78456100__')
        config.headers['auth-token'] = subToken;
    }

    if(blob) config.responseType = 'blob'

    return config;
}

export const changeCurrency = (amount,currency,length = 2) => {
    //  const rates = getCurrency()
    //  const oldCurrency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "XOF"
    // const cc = (amount * rates[currency]) /rates[oldCurrency]
    //   const result = cc ? cc : 1
    const result  = amount * 600
    return  result.toFixed(length)
}

export const ActiveMenu = (path) =>{
    if (window.location.pathname === path) return "active"
}

const  padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export const  format_date = (date)  => {

    const dat = new Date(date)
    return [
        padTo2Digits(dat.getDate()),
        padTo2Digits(dat.getMonth() + 1),
        dat.getFullYear(),
    ].join('/');
}

export const  format_date_complet = (date)  => {

    const dat = new Date(date)
    const cDate =  [
        padTo2Digits(dat.getDate()),
        padTo2Digits(dat.getMonth() + 1),
        dat.getFullYear(),
    ].join('/');

    const cHours =  [
        padTo2Digits(dat.getHours()),
        padTo2Digits(dat.getMinutes()),
    ].join(':')

    return [
        cDate,
        cHours
    ].join(' - ')

}

export const compare_date = (date) => {
    var d1 = new Date();
    var d2 = new Date(date);

    if (d1.getTime() > d2.getTime()){
        return true;
    }else{
        return false;
    }
}

export const  database_date = (date)  => {
    const dat = new Date(date)

    return [
        dat.getFullYear(),
        padTo2Digits(dat.getMonth() + 1),
        padTo2Digits(dat.getDate())
    ].join('-');
}

export const bytesToSize = (bytes,decimals = 1) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const checkProperties = (obj) => {
    for (var key in obj) {
        if (obj[key] !== null && obj[key] != "")
            return false;
    }
    return true;
}

export const ChatBotTheme = {
    background: '#f5f8fb',
    headerBgColor: '#3377ff',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#3377ff',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
};

export const sha1_random = (length) => {
    var result           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const getUserGeolocation = () =>{
    if("geolocation" in navigator){
        navigator.geolocation.getCurrentPosition((position)=>{
            let lat = position.coords.latitude
            let long = position.coords.longitude
            return  [lat,long]
        })
    }
    else{
        console.warn("Geolocation is not supported by this browser")
    }

}

export const isEmail = (val) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(val);
}

export const filterProductByDate = (data,daysBefore) =>{

        const begin = moment().subtract(daysBefore, 'days').startOf('day')
        const end = moment()



  const datas = data.filter(a =>  {

       return  (new Date(a.created_at).getTime() >= begin && new Date(a.created_at).getTime() <= end)
    })



   const result = data.filter(d => {
       let time = new Date(d.created_at).getTime();
        return (begin < time && time < end);
    })


    return datas

}

export  const CalculateDiscount = (amount , discount) => {
    if(discount != null){
        return parseInt(amount) - ((parseInt(amount) * discount) / 100)
    }else{
        return amount
    }
}

export const calulateRate = (reviews) => {
  if (reviews.length > 0){
      let all = 0
      reviews.map((e)=> all += parseInt(e.rate))

      return all / reviews.length
  }
  return 0
}

export const SortImageByProduct  = (galery_key,galeries) =>{
   if (galeries?.length){
       return galeries?.filter((g) => g.product_key == galery_key)
   }else{
       return []
   }
}

export  const cryptAndSaveCart = (cart = []) => {
      if (cart.length > 0){
          const encryptedObject = encrypt(cart, secretEncryption)
          localStorage.setItem('__shop__item__',encryptedObject)
      } else{
          localStorage.removeItem('__shop__item__')
      }

}

export  const decryptAndShowCart = () => {
      const stored =  localStorage.getItem('__shop__item__')
    if (stored){
        return decrypt(stored,secretEncryption)

    }else{
        return []
    }
}

export  const downloadFileFromServer = (link,fileName,extension) => {
    axios({
        url: link,
        method: 'GET',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': Cookies.get('auth-token')
        }
    }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName+"."+extension); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}

export const compareDateWithToday = (date) => {
    const CloseDate = moment()
    const CreateDate = moment(date).add(7, 'days').startOf('day')
    return CreateDate.isAfter(CloseDate);
}

export const updateDataOnArrayOfObject = ( data ,module,type, value ) => {

    for (var i in data) {
        if (data[i].name == module) {
            data[i][type] = value;
        }
    }
}

export const Capitalize = (str) => {
    let Capital_letter=str?.charAt(0)?.toUpperCase();
    return Capital_letter+str?.slice(1);
}

export  const MoneyFormat = (amount) => {
    if (amount){
        const num = amount?.toString().replace(/[^0-9.]/g, '');
        if (num < 1000) {
            return num;
        }
        let si = [
            {v: 1E3, s: "K"},
            {v: 1E6, s: "M"},
            {v: 1E9, s: "B"},
            {v: 1E12, s: "T"},
            {v: 1E15, s: "P"},
            {v: 1E18, s: "E"}
        ];
        let index;
        for (index = si.length - 1; index > 0; index--) {
            if (num >= si[index].v) {
                break;
            }
        }
        return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
    }else{
        return 0
    }
}

export const CheckPermissions = (permissions, module, single = true, bloc = [],  type = "show") => {
    if (single) {
        // Filtrer les permissions basées sur le nom du module
        const filter = permissions?.filter((per) => per.name === module);
        if (filter?.length) {
            return filter[0].permissions?.[type] || false; // Retourner la permission ou false si non définie
        } else {
            return false;
        }
    } else {
        // Vérifier si au moins un élément dans bloc a show à true
        const hasPermissionWithShowTrue = permissions.some(element =>
            bloc.includes(element.name.toLowerCase()) && element.permissions?.show === true
        );

        console.log("HasPermissionWithShow", hasPermissionWithShowTrue);
        return hasPermissionWithShowTrue;
    }


};


export const IsoToEmoji = (code) => {
   return code.split('').map(letter => letter.charCodeAt(0) % 32 + 0x1F1E5).map(n => String.fromCodePoint(n)).join('')
}


export const isDateBetweenDecemberAndJanuary = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 11, 15);
    const endDate = new Date(currentDate.getFullYear() + 1, 0, 5);

    return currentDate >= startDate && currentDate <= endDate;
};

export  const expireDate = (date) => {
    const aYearFromNow = new Date(date);
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    return compare_date(aYearFromNow)
}

export const storage_manage = (storage,size) => {
 return axios.get(SERVER_URL+"storage/used",attachTokenToHeaders())
  .then((response) =>{
    const used = response.data.used
     const rest = storage - (used + 131072)
     const diff = rest - size
       return diff > 0;
  })

}


export function hasEmptyValue(obj) {
    if(obj){
        for (const key in obj) {
            if (!obj[key]) {
                return true;
            }
        }
        return false;
    }else{
        return true
    }
}

export function deepEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

export function formatPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + ' ' + match[3] + ' ' + match[4] + ' ' + match[5];
    }

    return phoneNumber;
}

export const checkIfStringStartWith = (element) => {
   return element.startsWith('[')
}

const  hexToRgb = (hex) =>  {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
}

const luminance = (r, g, b) => {
    const a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export const  isColorLight = (hex)  => {
    const { r, g, b } = hexToRgb(hex);
    // Calculate the luminance
    const lum = luminance(r, g, b);
    // A threshold of 0.5 can be used to determine if the color is light or dark
    return lum > 0.5;
}

export const checkCurrentUrlSubdomain = () => {
    try {
        const urlObj = new URL(window.location.href);
        const hostname = urlObj.hostname;
        const parts = hostname.split('.');
        if (parts.length > 2) {
            const subdomain = parts.slice(0, -2).join('.');
            return {
                type: 'subdomain',
                content: subdomain,
            };
        } else {
            return {
                type: 'domain',
                content : hostname,
            };
        }
    } catch (e) {
        console.error('Invalid URL');
        return null;
    }
}


export const isShopClosed = (currentTime, openingTime, closingTime)  => {

    const convertToMinutes = time => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const currentMinutes = convertToMinutes(currentTime);
    const openingMinutes = convertToMinutes(openingTime);
    const closingMinutes = convertToMinutes(closingTime);

    return currentMinutes < openingMinutes || currentMinutes > closingMinutes;
}

export const getCurrentMinutes = () => {
    const now = new Date();

    const currentHours = now.getHours();    // Hours (0-23)
    const currentMinutes = now.getMinutes(); // Minutes (0-59)

// Format it as HH:MM
    return `${currentHours.toString().padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}`;



}

export const TimeStampToMinute = (timestamp) => {
    const hours = Math.floor(timestamp / 1000 / 60 / 60);
    const minutes = Math.floor((timestamp / 1000 / 60) % 60);
    const seconds = Math.floor((timestamp / 1000) % 60);

    return `${hours}h ${minutes}m ${seconds}s`;
}


export  function isPhoneNumber(phone) {
    const phoneRegex = /^\d{10,15}$/;

    return phoneRegex.test(phone);
}
