import React, {useEffect} from 'react';
import {withTranslation} from "react-i18next";
import "./loading.css"
const SubLoading = ({t}) => {

    useEffect(() => {
        const dots = document.getElementById("dots")
        animate(dots, "dots--animate")
    }, []);
    const animate = (element, className) => {
        element.classList.add(className);
        setTimeout(() => {
            element.classList.remove(className);
            setTimeout(() => {
                animate(element, className);
            }, 500);
        }, 2500);
    }




    return (
        <div className="subloading">
            <h1>{t('Loading')}
                 <div id="dots">
                     <span className="sub__dot z"></span>
                     <span className="sub__dot f"></span>
                     <span className="sub__dot s"></span>
                     <span className="sub__dot t">
                         <span className="sub__dot l"></span>
                     </span>
                 </div>
            </h1>
        </div>
    )
}

export default  withTranslation()(SubLoading);
