import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {LINK_ADMIN_DASHBOARD, LINK_STORE_DASHBOARD} from "../../routes/type";
import ShopkeeperSidebar from "./shopkeeperSidebar";
import AdminSidebar from "./adminSidebar";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import TrialSidebar from "./trialSidebar";
import {APPNAME} from "../../helpers/utils";

const Sidebar = ({t,auth}) => {

const [home,setHome] = useState(LINK_STORE_DASHBOARD)

    const [theme, setTheme] = useState("");


    useEffect(()=>{
          if (auth.me?.role_id == 3){
              setHome(LINK_ADMIN_DASHBOARD)
          }


          return () =>{
          }
      },[auth])

    useEffect(() => {
        setTheme(document.querySelector("html").getAttribute("data-sidebar"))
    }, [document.querySelector("html").getAttribute("data-sidebar")]);



    return (
        <div className="app-menu navbar-menu">
            <div className="navbar-brand-box">
                <Link to={home}  className="logo logo-dark">
                    <span className="logo-sm">
                            <h1 style={{color : theme === "light" ? "#101010" : "#fff"}}>{auth?.me?.role_id == 2 ? auth?.shop?.shopName.substring(0,1) : APPNAME.substring(0,1)}</h1>
                        </span>
                    <span className="logo-lg">
                           <h1 style={{color : theme === "light" ? "#101010" : "#fff"}}>{auth?.me?.role_id == 2 ? auth?.shop?.shopName : APPNAME}</h1>
                        </span>
                </Link>
                <Link to={home} className="logo logo-light">
                    <span className="logo-sm">
                            <h1 style={{color : theme === "light" ? "#101010" : "#fff"}}>{auth?.me?.role_id == 2 ? auth?.shop?.shopName.substring(0,1) : APPNAME.substring(0,1)}</h1>
                        </span>
                    <span className="logo-lg">
                           <h1 style={{color : theme === "light" ? "#101010" : "#fff"}}>{auth?.me?.role_id == 2 ? auth?.shop?.shopName : APPNAME}</h1>
                        </span>
                </Link>
                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover">
                    <i className="ri-record-circle-line"></i>
                </button>
            </div>

            <div id="scrollbar">
                <div className="container-fluid">

                    <div id="two-column-menu"></div>
                    {
                        auth?.plan?.trial ?
                            <TrialSidebar   auth={auth} />
                            :

                            <>
                                {auth?.me?.role_id == 2 && <ShopkeeperSidebar auth={auth} />}
                                {auth?.me?.role_id == 3 && <AdminSidebar   auth={auth}/>}
                            </>
                    }
                </div>
            </div>

            <div className="sidebar-background"></div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default compose(withRouter, connect(mapStateToProps, { })) (withTranslation()(Sidebar))
