import React, { useEffect, useState } from 'react'
import logo from '../../../../assets/logo-white.png'
import { withTranslation } from 'react-i18next'
import { APPNAME, numberWithCommas, SERVER_URL, TimeStampToMinute } from '../../../../helpers/utils'
import axios from 'axios'
import { toast } from 'sonner'

const Received = ({t}) => {

    document.title = APPNAME+" :: "+t('WithdrawMoney');

    const [timeRemaining, setTimeRemaining] = useState('');
    const [loading, setLoading] = useState(true);
    const [paying, setPaying] = useState(false);
    const [error, setErrors] = useState("");
    const [data, setData] = useState(null);


    useEffect(() => {
        const targetTime = new Date();
        targetTime.setHours(23, 50, 0, 0);

        const updateCountdown = () => {
            const now = new Date();
            const difference = targetTime - now;

            if (difference > 0) {
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / (1000 * 60)) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setTimeRemaining(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                );
            } else {
                setTimeRemaining(0);
            }
        };

        updateCountdown();
        const intervalId = setInterval(updateCountdown, 1000);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
            checkData()
    }, [])

    const checkData = () => {
           const pathname = window.location.pathname.split('/')[3]
            if (pathname === "admin"){
                const hash_key = window.location.pathname.split('/').pop()
                axios.get(SERVER_URL +'withdraw/verification/admin/' + hash_key)
                    .then(response => {
                        setLoading(false)
                        if (response.data.succeed){
                            setData(response.data.withdraw)
                            setErrors("")
                        }else{
                            setErrors(t(response.data.message))
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }else{
                const hash_key = window.location.pathname.split('/').pop()
                axios.get(SERVER_URL +'withdraw/verification/' + hash_key)
                    .then(response => {
                        setLoading(false)
                        if (response.data.succeed){
                            setData(response.data.withdraw)
                            setErrors("")
                        }else{
                            setErrors(t(response.data.message))
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
    }


    const ActionToPaid = () => {
        const dataInfo = {
            hash_key : data?.hash_key,
            method : data?.withdraw_method,
        }
        setPaying(true)
        const pathname = window.location.pathname.split('/')[3]
        if (pathname === "admin"){
            axios.post(SERVER_URL + 'withdraw/paid/admin/money',dataInfo)
                .then(response => {
                    setPaying(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                    }else{
                        toast.error(t(response.data.message))
                    }
                })
        }else{
            axios.post(SERVER_URL + 'withdraw/paid/money',dataInfo)
                .then(response => {
                    setPaying(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                    }else{
                        toast.error(t(response.data.message))
                    }
                })
        }


    }

    return (
        <div className="bg-heaven">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-3">
                    <div style={{ position: "absolute", marginTop: "30%", width: "100%" }}>
                        <div className="text-center">
                            <img src={logo} alt="" style={{ width: '200px' }} />
                        </div>
                        <br />
                        <div className="card">
                                    <div className="card-body">
                                        {
                                            timeRemaining === 0 ?
                                                <>
                                                    <h3 className="text-center text-danger">{t("WithdrawLinkExpired")}</h3>
                                                   <p className="text-center" style={{ fontSize: '13px' }}>{t('WithdrawLinkExpiredText')}</p>
                                                </>
                                                :
                                                <>
                                                    {
                                                        loading ?
                                                            <div style={{display : 'flex',justifyContent : "center",padding : "150px 0"}}>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> {t('Verification')}
                                                            </div>

                                                            :
                                                            error ?

                                                                <div className="text-center">
                                                                    {error}
                                                                </div>
                                                                :
                                                                <>
                                                                    <h1 className="received__title">
                                                                        {numberWithCommas(data?.amount)} <small>CFA XOF</small>
                                                                    </h1>


                                                                    <p style={{ fontSize: '13px' }}>
                                                                        {t('TimeBeforeExpired')} : <b className="ml-20 text-danger">{timeRemaining}</b>
                                                                    </p>

                                                                    <p style={{ fontSize: '13px' }}>
                                                                        {t('Method')} :  {data?.withdraw_method == 1 ? 'Mobile money' : 'PayPal'}
                                                                    </p>

                                                                    <p style={{ fontSize: '13px' }}>
                                                                        <b className="text-danger">Important ! :</b>
                                                                        <span className="text-muted">
                                                                {t('WithdrawMoneyText')}
                                                            </span>
                                                                    </p>

                                                                    <hr />

                                                                    <div className="row">
                                                                        <div className="col-lg-7">
                                                                            <input type="text" value={window.location.pathname.split('/')[3] === "admin" ? data?.receive_number : data?.paid_number } className="front"
                                                                                   readOnly={true}
                                                                                   disabled={true} />
                                                                        </div>
                                                                        <div className="col-lg-5">
                                                                            <button
                                                                                disabled={paying}
                                                                                className="btn btn-block btn-primary btn-lg"> {
                                                                                paying ?
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm"
                                                                                        role="status" aria-hidden="true" />
                                                                                    :

                                                                                    <span onClick={event => ActionToPaid()}>{t('Withdraw')}</span>
                                                                            }
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </>

                                                    }
                                                </>
                                        }
                                    </div>
                                </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Received)