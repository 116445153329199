import React, {Fragment} from 'react';
import {CLIENT} from "../../../helpers/utils";

import {componentConfig} from "../../config/config.section";
import Components from "./Components";
const ComponentList = () => {




    return (
        <div className="drag-elements-sidepane sidepane">
            <div style={{height: "calc(100% - 200px)",overflowY : "scroll"}}>

                <ul className="components-list clearfix" data-type="leftpanel">
                    {
                        componentConfig.map((item,key) =>

                            <li className="header" data-section="Elements" data-search="" key={key}>
                                <label className="header" htmlFor={`leftpanel_component_${item.name}`}>{item.name}
                                    <div className="header-arrow"></div>
                                </label>

                                <input className="header_check" type="checkbox" defaultChecked={false}
                                       id={`leftpanel_component_${item.name}`} />
                                <ol>
                                    {
                                        item.block.map((el,k)  => <Fragment key={k}> <Components component={el} section={item} /></Fragment>)
                                    }
                                </ol>

                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}

export default ComponentList;
