import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../../layouts/front/Layout";
import {Link} from "react-router-dom";
import {LINK_REGISTER_STORE} from "../../../routes/type";
import {APPNAME} from "../../../helpers/utils";

const AboutUs = ({t}) => {

    document.title = APPNAME+" :: "+t('About')

    return (
        <Layout>
            <section className="about-us-area p-t-180 p-b-130 border-bottom-primary">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8">
                            <div className="header_custom_pricing">
                                <h1>{t('About')}</h1>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="container">
                    <div className="row justify-content-center p-t-180">
                        <div className="col-xl-7 col-lg-6 col-md-10">
                            <div className="preview-image-showcase p-r-70 p-r-lg-40 p-r-md-0 p-b-md-40">
                                <div className="images-left">
                                    <div className="image-hover-zoom-overly">
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/preview-gallery/about-thumbnail-1.jpg" alt="About thumbnail one"/>
                                    </div>
                                    <img src="https://demo.webtend.net/html/landio_preview/assets/img/preview-gallery/about-thumbnail-3.jpg" alt="About thumbnail three"/>
                                </div>
                                <div className="images-right">
                                    <img src="https://demo.webtend.net/html/landio_preview/assets/img/preview-gallery/about-thumbnail-2.jpg" alt="About thumbnail two"/>
                                        <img src="https://demo.webtend.net/html/landio_preview/assets/img/preview-gallery/about-thumbnail-4.jpg" alt="About thumbnail four"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-9">
                            <div className="about-us-content">
                                <div className="common-heading tagline-boxed m-b-30">
                                    <span className="tagline">About Company</span>
                                    <h2 className="title">We're Help Customers Get Their Success</h2>
                                </div>
                                <p>
                                    Sed perspiciatis unde omnis iste natus error voluptatem accusantium doloremque
                                    laudantium totam rem aperams eaque ipsa quae abillo inventore veritatis
                                </p>

                                <div className="simple-icon-boxes row m-t-40">
                                    <div className="col-sm-6">
                                        <div className="simple-icon-box m-b-40">
                                            <div className="icon">
                                                <i className="fal fa-users"></i>
                                            </div>
                                            <h5 className="title">Build Real Users</h5>
                                            <p>
                                                Unde omnis natus volu accusantium doloremqie laudantium totam
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="simple-icon-box m-b-40">
                                            <div className="icon">
                                                <i className="fal fa-tools"></i>
                                            </div>
                                            <h5 className="title">Process Easily</h5>
                                            <p>
                                                But I must explain you this mistak denouncinge pleasure praising
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <a href="#" className="template-btn bordered-btn">Learn More <i
                                    className="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="team-member-area section-half-dots-pattern-fixed p-t-130 p-b-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-lg-6">
                            <div className="common-heading tagline-boxed text-center m-b-60">
                                <span className="tagline">Creative Team</span>
                                <h2 className="title">We Have Professional Creative Team </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row team-row">

                            <div className="col-md-4 col-sm-6 team-wrap">
                                <div className="team-member text-center">
                                    <div className="team-img">
                                        <img src="http://deothemes.com/envato/enigma/html/img/team_1.jpg" alt=""/>
                                            <div className="overlay">
                                                <div className="team-details text-center">
                                                    <p>
                                                        Our web design team will spend time with our digital marketing
                                                        team.
                                                    </p>
                                                    <div className="socials mt-20">
                                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                                        <a href="#"><i className="fa fa-google-plus"></i></a>
                                                        <a href="#"><i className="fa fa-envelope"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <h6 className="team-title">John Marshall</h6>
                                    <span>Designer</span>
                                </div>
                            </div>


                            <div className="col-md-4 col-sm-6 team-wrap">
                                <div className="team-member text-center">
                                    <div className="team-img">
                                        <img src="http://deothemes.com/envato/enigma/html/img/team_2.jpg" alt=""/>
                                            <div className="overlay">
                                                <div className="team-details text-center">
                                                    <p>
                                                        Our web design team will spend time with our digital marketing
                                                        team.
                                                    </p>
                                                    <div className="socials mt-20">
                                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                                        <a href="#"><i className="fa fa-google-plus"></i></a>
                                                        <a href="#"><i className="fa fa-envelope"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <h6 className="team-title">Steve Roberts</h6>
                                    <span>Photographer</span>
                                </div>
                            </div>


                            <div className="col-md-4 col-sm-6 team-wrap">
                                <div className="team-member last text-center">
                                    <div className="team-img">
                                        <img src="http://deothemes.com/envato/enigma/html/img/team_3.jpg" alt=""/>
                                            <div className="overlay">
                                                <div className="team-details text-center">
                                                    <p>
                                                        Our web design team will spend time with our digital marketing
                                                        team.
                                                    </p>
                                                    <div className="socials mt-20">
                                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                                        <a href="#"><i className="fa fa-google-plus"></i></a>
                                                        <a href="#"><i className="fa fa-envelope"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <h6 className="team-title">Michael Cartney</h6>
                                    <span>Designer</span>
                                </div>
                            </div>


                        </div>
                </div>
            </section>

            <section className="counter-section section-with-map-bg bg-primary-color p-t-80 p-b-80 p-t-md-160">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-9">

                            <div className="preview-galley-v3 m-b-md-100">
                                <img className="preview-image-1" src="https://demo.webtend.net/html/landio_preview/assets/img/preview-gallery/count-down.png"
                                     alt="Preview Image"/>
                                    <img className="preview-image-2" src="https://demo.webtend.net/html/landio_preview/assets/img/preview-gallery/count-down-top.png"
                                         alt="Preview Image"/>
                                        <img className="preview-image-3"
                                             src="https://demo.webtend.net/html/landio_preview/assets/img/preview-gallery/count-down-bottom.png" alt="Preview Image"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">

                            <div className="row counter-items-v1 p-xl-5">
                                <div className="col-6">
                                    <div className="counter-item m-b-60">
                                        <div className="icon">
                                            <i className="fal fa-users"></i>
                                        </div>
                                        <div className="counter-wrap">
                                            <span className="counter">96327</span>
                                            <span className="suffix">+</span>
                                        </div>
                                        <p className="title">Daily Active Users</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="counter-item m-b-60">
                                        <div className="icon">
                                            <i className="fal fa-file-alt"></i>
                                        </div>
                                        <div className="counter-wrap">
                                            <span className="counter">78630</span>
                                            <span className="suffix">+</span>
                                        </div>
                                        <p className="title">Project Complate</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="counter-item">
                                        <div className="icon">
                                            <i className="fal fa-cloud-download"></i>
                                        </div>
                                        <div className="counter-wrap">
                                            <span className="counter">56326</span>
                                            <span className="suffix">+</span>
                                        </div>
                                        <p className="title">Download Software</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="counter-item">
                                        <div className="icon">
                                            <i className="fal fa-gem"></i>
                                        </div>
                                        <div className="counter-wrap">
                                            <span className="counter">63020</span>
                                            <span className="suffix">+</span>
                                        </div>
                                        <p className="title">5 Star Rating Reviews</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default withTranslation() (AboutUs);
