import React from 'react';
import {useDrag} from "react-dnd";
import itemTypes from "../../../config/config.type";
import {sha1_random} from "../../../../helpers/utils";

const Blocks = ({block,section,setPreview}) => {

    const [{ isDragging }, drag] = useDrag({
        type: itemTypes.CARD,
        item: {
            id : block.id+sha1_random(4),
            type:  section.category,
            component: block.component,
            name : block.name,
            contentEditable : block.contentEditable,
            data : block?.data
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });


    return (
        <li data-section={section.name}
            ref={drag}
            onMouseEnter={()=> setPreview(block.picture)}
            onMouseLeave={()=> setPreview("")}
            data-type={`${section.name.toLowerCase()}/${block.name.toLowerCase()}`}
            style={{backgroundRepeat: "no-repeat",opacity : isDragging ? 0.5 : 1}}>
            <span className="name">{block.name}</span>
            <div className="add-section-btn" title="Drag"><i className="ri-drag-drop-line"></i></div>
            <img className="preview" src={block.picture} loading="lazy" alt={block.name}/>
        </li>
    );
}

export default Blocks;
