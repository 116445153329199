import React,{useState} from 'react';
import Layout from "../../../layouts/front/Layout";
import {withTranslation} from "react-i18next";
import {APPNAME} from "../../../helpers/utils";
import Started from "./contents/Started";
import Regis from "./contents/Regis";
import Subs from "./contents/Subs";
import Page from "./contents/Page";
import Shop from "./contents/Shop";

const  Docs = ({t}) => {

    document.title = APPNAME+ " :: "+t('Docs')

    const [step,setStep] = useState(1)


    return (
        <Layout>

          <section className="m-t-110">

             <div className="doc_page">
                 <div className="doc_left_content">
                     <ul>
                         <li><span className={`cursor-pointer ${step == 1 ? "active" : null}`} onClick={event => setStep(1)}>{t("GetStart")}</span></li>
                         <li><span className={`cursor-pointer ${step == 2 ? "active" : null}`} onClick={event => setStep(2)}>{t("Register")}</span></li>
                         <li><span className={`cursor-pointer ${step == 3 ? "active" : null}`} onClick={event => setStep(3)}>{t('Subscription')}</span></li>
                         <li><span className={`cursor-pointer ${step == 4 ? "active" : null}`} onClick={event => setStep(4)}>Pages</span></li>
                         <li><span className={`cursor-pointer ${step == 5 ? "active" : null}`} onClick={event => setStep(5)}>{t('Shop')}</span></li>
                         <li><span className={`cursor-pointer ${step == 6 ? "active" : null}`} onClick={event => setStep(6)}>{t('Orders')}</span></li>
                         <li><span className={`cursor-pointer ${step == 7 ? "active" : null}`} onClick={event => setStep(7)}>{t('Customers')}</span></li>
                         <li><span className={`cursor-pointer ${step == 8 ? "active" : null}`} onClick={event => setStep(8)}>{t('Support')}</span></li>
                         <li><span className={`cursor-pointer ${step == 9 ? "active" : null}`} onClick={event => setStep(9)}>{t('Payment')}</span></li>
                         <li><span className={`cursor-pointer ${step == 10 ? "active" : null}`} onClick={event => setStep(10)}>{t('Collaborater')}s</span></li>
                         <li><span className={`cursor-pointer ${step == 11 ? "active" : null}`} onClick={event => setStep(11)}>{t('Setting')}</span></li>
                     </ul>
                 </div>

                 <div className="doc_content_content">
                     {step == 1 ? <Started t={t} /> : null}
                     {step == 2 ? <Regis t={t} /> : null}
                     {step == 3 ? <Subs t={t} /> : null}
                     {step == 4 ? <Page t={t} /> : null}
                     {step == 5 ? <Shop t={t} /> : null}
                 </div>

                 <div className="doc_right_content">
                     {
                         step == 5
                         ?
                             <div>
                               <ul>
                                   <li className="cursor-pointer">Catégories</li>
                                   <li className="cursor-pointer">Sub Categories</li>
                                   <li className="cursor-pointer">Products</li>
                                   <li className="cursor-pointer">Coupon</li>
                               </ul>
                             </div>
                             :
                             null
                     }
                 </div>
             </div>

          </section>
        </Layout>
    );
}

export default withTranslation() (Docs)