import React, {Fragment, useState} from 'react';
import {flatStyle} from "../../../utils/utils";

const Padding = ({data,updateValue,updateUnit}) => {
    const [toggle, setToggle] = useState(false)

    return (
        <Fragment>
            <label className="header" data-header="paddings_header" onClick={event => setToggle(!toggle)} htmlFor="header_paddings_header" >
                <span>Padding</span>
                <div className="header-arrow"></div>
            </label>
            <input className="header_check" type="checkbox" defaultChecked={toggle} id="header_paddings_header"/>

            {
                toggle ?
                    <div className="row mr-20 ml-20" data-section="paddings_header" >
                        <div className="mb-3 col-sm-6" data-key="padding-top">
                            <label className="form-label" htmlFor="input-model">Top</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-padding-top"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'paddingTop').value}
                                        onChange={event => updateValue("paddingTop",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'paddingTop').unit}
                                            onChange={event => updateUnit("paddingTop",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="padding-right">
                            <label className="form-label" htmlFor="input-model">Right</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-padding-right"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'paddingRight').value}
                                        onChange={event => updateValue("paddingRight",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'paddingRight').unit}
                                            onChange={event => updateUnit("paddingRight",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="padding-bottom">
                            <label className="form-label" htmlFor="input-model">Bottom</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-padding-bottom"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'paddingBottom').value}
                                        onChange={event => updateValue("paddingBottom",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'paddingBottom').unit}
                                            onChange={event => updateUnit("paddingBottom",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="padding-left">
                            <label className="form-label" htmlFor="input-model">Left</label>
                            <div className="input">
                                <div className="input-group css-unit" id="cssunit-padding-left">
                                    <input name="number" type="number" className="form-control"
                                           value={flatStyle(data,'paddingLeft').value}
                                           onChange={event => updateValue("paddingLeft",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select className="form-select small-arrow" name="unit"
                                                value={flatStyle(data,'paddingLeft').unit}
                                                onChange={event => updateUnit("paddingLeft",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
            }
        </Fragment>

    )
}

export default Padding;
