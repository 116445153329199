import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import axios from "axios";
import {APPNAME, CustomChangeLanguage, SERVER_URL} from "../../../helpers/utils";
import {RegLoading} from "../../../components";
import {LINK_LANDING} from "../../../routes/type";
import bg from "../Register/register.png";



const Invitation = ({t}) => {

    document.title = APPNAME+" :: "+t('Delivere')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('Delivere'))



    const [Gloading,setGLoading] = useState(false)
    const [deliverer,setDeliverer] = useState(null)


    let history = useHistory()


    useEffect(() => {
        checkDeliverOnAccess()
    }, []);

    const checkDeliverOnAccess = async () =>{

        try {
            await axios.get(SERVER_URL+"deliverer/check/access/"+window.location.pathname.split("/").pop())
                .then((response) => {
                    setGLoading(false)
                    if (response.data.succeed){
                        setDeliverer(response.data.deliverer)
                        if (response.data.deliverer?.statut == 1){
                            setTimeout(() => {
                                history.push("/")
                            },2000)

                        }

                    }else{
                        toast.error(t(response.data.message))
                    }
                })

        } catch (error) {
            setGLoading(false)
            setGLoading(false)
            console.log(error);
        }
    }


    const toggleActivation = async (type) =>{
        setGLoading(true)
        await axios.get(SERVER_URL+"deliverer/invitation/"+type+"/"+deliverer.id).then((response)=>{
            setGLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setTimeout(() => {
                    history.push("/")
                },2000)
            }else{
                toast.error(t(response.data.message))
            }
        })

    }




    return (
        <section className="sign-in-section p-b-2">
            <div className="container-fluid reg_panel_all">
                <div className="row">
                    <div className="col-lg-4 left__panel">
                        <div className="">
                            <div className="header__left__panel">
                                <Link to={LINK_LANDING}><h3>{APPNAME}</h3></Link>
                                <div className="d-none d-sm-block">
                                    <button
                                        className={`text-white  ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('fr')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>FR
                                    </button>
                                    <button
                                        className={`text-white ${localStorage.getItem('I18N_LANGUAGE') === "en" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('en')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>EN
                                    </button>
                                </div>
                            </div>
                            <div className="left__panel__content">
                                <h1 className="text-white" style={{fontSize: "40px"}}>{t('CustomerEspace')}</h1>
                                <p>{t('CustomerEspaceText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 bg-white">
                        <div className="">
                            <>
                                {
                                    Gloading ?

                                        <RegLoading />
                                        :

                                        <div className="offset-lg-2 col-lg-8 center_register" >
                                            <h2 className="text-center">{t('ConfirmDelivererInvitationCreation')}</h2>
                                            <hr/>
                                            <p>
                                                {t('ConfirmDelivererInvitationCreationText')}
                                            </p>
                                            <br/>
                                            <br/>
                                            <p className="text-center">
                                                <button className="template-btn primary-bg-5" onClick={event => toggleActivation(1)}>{t('AcceptInvitation')}</button>
                                                <button className="template-btn primary-bg-3 ml-2" onClick={event => toggleActivation(3)}>{t('RejectInvitation')}</button>
                                            </p>
                                        </div>
                                }

                            </>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    home : state.home
});


export default   compose(connect(mapStateToProps )) (withTranslation()(Invitation));
