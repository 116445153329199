import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminLayout from '../../../../../layouts/admin/AdminLayout'
import { FACEBOOK_APP_ID } from '../../../../../helpers/keys'
import Cookies from 'js-cookie'
import FacebookCatalog from './FacebookCatalog'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LINK_STORE_APPS } from '../../../../../routes/type'
import { APPNAME } from '../../../../../helpers/utils'

const ConnectFacebook = ({t,auth}) => {

    document.title = APPNAME+ " :: Connect Facebook marketplace"

    const [userAccess, setUserAccessToken] = useState( Cookies.get('facebook__' + auth?.shop?.shop_key));

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v17.0',
            });

            window.FB.AppEvents.logPageView();
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    const handleLogin = () => {
        // Connexion de l'utilisateur à Facebook
        window.FB.login(
            function (response) {
                if (response.authResponse) {
                    const userAccessToken = response.authResponse.accessToken;
                    Cookies.set('facebook__' + auth?.shop?.shop_key,userAccessToken)
                    setUserAccessToken(userAccessToken)
                } else {
                    console.log('Connexion annulée ou refusée.');
                }
            },
            { scope: 'catalog_management,business_management' }
        );
    };



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Facebook marketplace</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{auth?.shop?.shopName}</li>
                                <li className="breadcrumb-item"><Link to={LINK_STORE_APPS}>{t('Applications')}</Link>
                                </li>
                                <li className="breadcrumb-item active">Facebook marketplace</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">


                {
                    Cookies.get('facebook__' + auth?.shop?.shop_key) || userAccess ?
                        <FacebookCatalog account={userAccess ||  Cookies.get('facebook__' + auth?.shop?.shop_key)} />
                        :

                        <div className="offset-lg-2 col-lg-8">
                            <div className="card">
                                <div className="card-body text-center pt-150 pb-150">
                                    <h5>Veuillez vous connecter à votre compte Facebook pour récupérer votre
                                        catalogue.</h5>
                                    <p>L'accès à votre catalogue Facebook vous permettra d'administrer vos produits et vos commandes.</p>

                                    <div className="row mt-50">

                                        <div className="offset-lg-4 col-lg-4">
                                            <button type="button" onClick={handleLogin}
                                                    className="btn btn-primary btn-label waves-effect waves-light">
                                                <i className="ri-facebook-fill label-icon align-middle fs-16 me-2"></i> Se connecter à Facebook
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                }

            </div>

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
})
export default compose(connect(mapStateToProps))(withTranslation()(ConnectFacebook));

