import React, {useEffect, useState} from 'react';
import  "./feedback.css"


import emoji1 from './emojis/emoji-1.png'
import emoji2 from './emojis/emoji-2.png'
import emoji3 from './emojis/emoji-3.png'
import emoji4 from './emojis/emoji-4.png'
import emoji5 from './emojis/emoji-5.png'
import ReactStars from "react-rating-stars-component";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {SERVER_URL} from "../../helpers/utils";
import {toast} from "react-toastify";

const FeedBack = ({t,shop,auth}) => {

    const [rating,setRating] = useState(5)
    const [emo,setEmo] = useState(emoji5)
    const [show,setShow] = useState(false)

    useEffect(()=>{

     },[])

    const handleFeedback = async () =>{
        await axios.get(SERVER_URL+`front/rate/${rating}/${shop?.shop.id}/${auth?.user?.id}`).then((response)=>{
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setShow(false)
            }else{
                 toast.error(t(response.data.message))
            }
        })
    }



    const toggleShow = () => show ? setShow(false) : setShow(true)

    const ratingChanged = (newRating) => {
        setRating(newRating)
        if (newRating === 1) setEmo(emoji1)
        if (newRating === 2) setEmo(emoji2)
        if (newRating === 3) setEmo(emoji3)
        if (newRating === 4) setEmo(emoji4)
        if (newRating === 5) setEmo(emoji5)
    };

    return (
        <div>
            <div className="feedback_btn" onClick={event => toggleShow()}>
                <i className={`${show ? "fa fa-times" : "fa fa-stars"}`}></i>
            </div>
            <div className={`feedback_wrapper ${show ? "feedback_show" : ""}`}>
                <div className="content">
                    <div className="outer">
                        <div className="emojis">
                            <li><img src={emo} alt=""/></li>
                        </div>
                    </div>
                    <ReactStars
                        count={5}
                        size={35}
                        edit={true}
                        value={rating}
                        onChange={ratingChanged}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                    />
                    <button className='btn btn-sm btn-success' onClick={event => handleFeedback()}>{t('Rate')}</button>
                </div>
                <div className="feeback_footer">
                    <span className="text">
                        {t('RateExp')}
                    </span>
                    <span className="numb">
                        {rating} {t('OutOf')} 5
                    </span>
                </div>
            </div>
        </div>
    );
}




export default  withTranslation()(FeedBack)