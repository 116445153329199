export const CATEGORIES_SHOP_LOADING = 'CATEGORIES_SHOP_LOADING';
export const CATEGORIES_SHOP_SUCCESS = 'CATEGORIES_SHOP_SUCCESS';
export const CATEGORIES_SHOP_FAIL = 'CATEGORIES_SHOP_FAIL';

export const SUBCATEGORIES_SHOP_LOADING = 'SUBCATEGORIES_SHOP_LOADING';
export const SUBCATEGORIES_SHOP_SUCCESS = 'SUBCATEGORIES_SHOP_SUCCESS';
export const SUBCATEGORIES_SHOP_FAIL = 'SUBCATEGORIES_SHOP_FAIL';


export const PRODUCTS_SHOP_LOADING = 'PRODUCTS_SHOP_LOADING';
export const PRODUCTS_SHOP_SUCCESS = 'PRODUCTS_SHOP_SUCCESS';
export const PRODUCTS_SHOP_FAIL = 'PRODUCTS_SHOP_FAIL';