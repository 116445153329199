import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import Paginate from "../../../../components/Paginate/Paginate";
import {compose} from "redux";
import {connect} from "react-redux";
import {getProducts} from "../../../../store/Categories/action";
import {Link} from "react-router-dom";
import {LINK_STORE_PRODUCT_CREATE} from "../../../../routes/type";
import swal from "@sweetalert/with-react";
import axios from "axios";
import {toast} from "react-toastify";
import Authorization from "../../../../components/Authorization";


const Products = ({t,products : {products,isLoading,categories,subcategories,galerie},auth,getProducts}) => {
    document.title = APPNAME+" :: "+t('Products')

    useEffect(() => {
        getProducts(auth.shop?.id)
    },[auth])

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [loading,setLoading] = useState(false)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    const [currentProducts, setCurrentProducts] = useState([]);



    useEffect(() => {
        setCurrentProducts(products?.slice(indexOfFirstPost, indexOfLastPost))
    },[subcategories])



    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(products?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteProduct'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}product/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed == true) {
                        toast.success(t(response.data.message))
                        getProducts(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }


    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledProduct') : t('SureToEnabledProduct'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}product/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getProducts(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentProducts(products?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = products.filter(product => {
                return product.title.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentProducts(filter)
        }
    }


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(products.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ==  currentProducts.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }
    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListProduct'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setLoading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}product/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                         getProducts(auth.shop.id)
                        setIsCheckAll(false)
                        setIsCheck([])
                        setLoading(false)
                    }
                } catch (error) {
                    setLoading(false)
                    console.log(error)
                }
            }
        });

    }




    const filterCategory = (id) => {
        const filter = categories.filter(e => e.id == id)
        return filter[0].category
    }

    const filterSubCategory = (id) => {
        const filter = subcategories.filter(e => e.id == id)
        return filter[0]?.subname
    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Products')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Products')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <Authorization single={true} trial={true} module="products" component={true} >
             <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Products')}</h5>
                                <Authorization single={true}  module="products"  trial={true}  type="create" >
                                <div className="flex-shrink-0">
                                    <Link to={LINK_STORE_PRODUCT_CREATE} className="btn btn-primary add-btn" >
                                        <i className="ri-add-line align-bottom me-1"></i> {t('Products')}
                                    </Link>
                                </div>
                                </Authorization>
                            </div>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">
                                    <div className="col-lg-4">
                                        {
                                            isCheck.length > 0 &&

                                            <>
                                                {
                                                    loading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                        <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">

                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col" style={{width: "50px"}}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="selectAll"
                                                       id="selectAll"
                                                       onChange={handleSelectAll}
                                                       checked={isCheckAll}
                                                />
                                            </div>
                                        </th>
                                        <th className="sort">{t('Product')}</th>
                                        <th className="sort">{t('Price')}</th>
                                        <th className="sort">{t('Order')}</th>
                                        <th className="sort">{t('Stocks')}</th>
                                        <th className="sort">{t('Discount')}</th>
                                        <th className="sort">Status</th>
                                        <th className="sort">{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        isLoading ?
                                            <tr>
                                                <td colSpan="8">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentProducts?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentProducts.map((product,key)=>
                                                                    <tr key={key}>
                                                                        <th scope="col" style={{ width: '50px' }}>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       name={product.title}
                                                                                       id={product.id}
                                                                                       onChange={handleClick}
                                                                                       checked={isCheck.includes(product.id)}
                                                                                />
                                                                            </div>
                                                                        </th>
                                                                        <td data-column-id="product">
                                                                                <span>
                                                                                    <div
                                                                                        className="d-flex align-items-center">
                                                                                        <div
                                                                                            className="flex-shrink-0 me-3">
                                                                                            <div
                                                                                                className="avatar-sm bg-light rounded p-1">
                                                                                                <img
                                                                                                    src={SERVER_IMG_URL + auth.shop?.shop_key + '/products/' + JSON.parse(product.pictures)[0]}
                                                                                                    alt=""
                                                                                                    className="img-fluid d-block" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flex-grow-1">
                                                                                            <h5 className="fs-14 mb-1">
                                                                                                <Link
                                                                                                    to={'/store/product/detail/' + product.hash_key}
                                                                                                    className="text-dark">{product.title}</Link>
                                                                                            </h5>
                                                                                            <p className="text-muted mb-0">
                                                                                            <>
                                                                                              {
                                                                                                  product.isSubcategory == 'true' ? t('SubCategorie') : t('Categorie')
                                                                                              }
                                                                                            </> :

                                                                                                 <>
                                                                                              {
                                                                                                  product.isSubcategory == 'true' ?
                                                                                                      <span
                                                                                                          className="fw-medium">{filterSubCategory(product.subcategory)}</span> :
                                                                                                      <span
                                                                                                          className="fw-medium">{filterCategory(product.category_id)}</span>
                                                                                              }
                                                                                            </>

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </span>
                                                                        </td>
                                                                        <td>{numberWithCommas(product.price)} Frcs</td>
                                                                        <td>{product.orders ?? 0}</td>
                                                                        <td>
                                                                                <span>
                                                                                    {
                                                                                        product.stock > 10 ?
                                                                                            <b className="text-success fs-12 fw-medium">
                                                                                                {product.stock}
                                                                                            </b>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    product.stock === 0 ?
                                                                                                        <span
                                                                                                            className="text-danger fs-12 fw-medium">
                                                                                                        {t('RuptureStockSimple')}
                                                                                                  </span>
                                                                                                        :
                                                                                                        <b className="text-warning fs-12 fw-medium">
                                                                                                            {product.stock}
                                                                                                        </b>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </span>
                                                                        </td>
                                                                        <td>{product.discount ?? 0}</td>

                                                                        <td>
                                                                            {product.statut == 1
                                                                                ? <span
                                                                                    className="badge badge-soft-success">{t('Public')}</span>
                                                                                : <span
                                                                                    className="badge badge-soft-danger">{t('Private')}</span>
                                                                            }
                                                                        </td>
                                                                        <td className="priority">
                                                                            <ul className="list-inline  mb-0">
                                                                                <Authorization single={true}
                                                                                               module="products"
                                                                                               trial={true}
                                                                                               type="action">
                                                                                    <li className="list-inline-item">
                                                                                        {product.statut == 1
                                                                                            ? <span
                                                                                                className="edit-item-btn cursor-pointer"
                                                                                                onClick={event => toggleStatus(product.id, 2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                            :

                                                                                            <span
                                                                                                className="edit-item-btn cursor-pointer"
                                                                                                onClick={event => toggleStatus(product.id, 1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                        }
                                                                                    </li>
                                                                                </Authorization>

                                                                                <Authorization single={true}
                                                                                               module="products"
                                                                                               trial={true}
                                                                                               type="action">
                                                                                    <li className="list-inline-item">
                                                                                        <Link className="cursor-pointer"
                                                                                              to={'/store/product/detail/' + product.hash_key}>
                                                                                            <i className="ri-eye-fill align-bottom me-2"></i>
                                                                                        </Link>
                                                                                    </li>
                                                                                </Authorization>

                                                                                <Authorization single={true}
                                                                                               module="products"
                                                                                               trial={true}
                                                                                               type="update">
                                                                                    <li className="list-inline-item">
                                                                                        <Link
                                                                                            className="edit-item-btn cursor-pointer"
                                                                                            to={'/store/product/edit/' + product.hash_key}>
                                                                                            <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                        </Link>
                                                                                    </li>
                                                                                </Authorization>

                                                                                <Authorization single={true}
                                                                                               module="products"
                                                                                               trial={true}
                                                                                               type="delete">
                                                                                    <li className="list-inline-item">
                                                                                         <span
                                                                                             className="remove-item-btn cursor-pointer"
                                                                                             onClick={event => deleteModal(product.id)}>
                                                                                             <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                         </span>
                                                                                    </li>
                                                                                </Authorization>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="8">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={products?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>
            </Authorization>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
    products : state.categorie
});
export default   compose(connect(mapStateToProps, { getProducts })) (withTranslation()(Products));
