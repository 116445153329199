import React, {Fragment, memo, useEffect, useState} from 'react';
import ScrollToTop from "../../components/Scroll/ScrollToTop";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {RouteMiddleware} from "../../middleware/route.middleware";
import {subDynamicRoutes,subPrivateRoutes,subPublicRoutes} from "./Seller/routes";
import {compose} from "redux";
import {connect} from "react-redux";
import Maintenance from "./Required/Maintenance";
import axios from "axios";
import {attachTokenToHeaders, SERVER_IMG_URL, SERVER_URL} from "../../helpers/utils";
import {toast} from "react-toastify";
import {loadSubMe} from "../../store/Subdomain/Oauth/action";
import BlackFeedback from "../../components/FeedBack/BlackFeedback";
import SubLoading from "../../components/Loading/SubLoading";
import NotAvailable from "./Required/NotAvailable";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import {withTranslation} from "react-i18next";
import MessengerCustomerChat from 'react-messenger-customer-chat';

const PageManager = ({data, domaine, auth,loadSubMe,t}) => {

    const [whatsapp, setWhatsapp] = useState({})
    const [whatsappConf, setWhatsappConf] = useState([])

    const [messenger, setMessenger] = useState({})
    const [messengerConf, setMessengerConf] = useState([])

    const [subroutelist, SetSubroutelist] = useState([])

    const locate = window.location.pathname


    useEffect(() => {
       filterExtension("whatsapp")
       filterExtension("messenger")
    }, []);

    useEffect(() => {
        filterExtension("chart")

    }, [locate]);

    const filterExtension = (extension) =>{
        const arr =  data?.plugins?.filter(e => e.name == extension && e.statut == 1)

        switch (extension) {
            case 'whatsapp':
                setWhatsappConf(arr)
                if (arr.length > 0) {setWhatsapp(JSON.parse(arr[0].config))}
                break
            case 'messenger':
                setMessengerConf(arr)
                if (arr.length > 0) {setMessenger(JSON.parse(arr[0].config))}
                break
            case 'chart':
                if (arr.length > 0) {visitorCounter()}else{}
                break
            default:
        }

    }

    useEffect(() => {
        loadPages(data?.shop?.shop_key)
    }, [data?.shop?.shop_key]);


    useEffect(() => {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = SERVER_IMG_URL+data?.shop?.shop_key+"/logo/"+data?.shop?.logo
        link.sizes= "16*16"
    }, [data]);


    const loadPages = async (key) => {
        await axios.get(SERVER_URL + "pages/list/" + key).then((response) => {
            if (response.data.succeed) {
                const content = response.data.pages?.content
                let pages = JSON.parse(content)
                let names = []
                pages.map((page) => names.push(page.name))
                const list = subDynamicRoutes.filter((page) => names.includes(page.name))
                SetSubroutelist([...list, ...subPublicRoutes, ...subPrivateRoutes])
            } else {
                toast.error(t(response.data.message))
            }
        })

    }

    useEffect(() => {
        if (!auth.appLoaded && !auth.loading && auth.token && !auth.isAuthenticated) {
            loadSubMe();
        }
    }, [auth,loadSubMe]);

    const visitorCounter = () => {
        const pagesArray = [...subDynamicRoutes,...subPrivateRoutes,...subPublicRoutes]
         const exist = pagesArray.filter(e => e.path == locate)
      if (exist.length > 0) {
          const values = {
              shop_id : data?.shop.id,
              page : window.location.pathname,
              pageName :exist[0].name
          }

          axios.post(SERVER_URL+"setvisitorcounter",values,attachTokenToHeaders())
              .then((response) => {
                  if (response.data.succeed) {
                  }else{
                  }
              })
      }

    }



    return (
        <>
            {
                data.planDays > 0 ?
                    <>
                        {
                            auth?.loading ?
                                <SubLoading />
                                :

                                <Router>
                                    <ScrollToTop history={Router.history}/>
                                    {
                                        subroutelist?.length > 0 ?
                                            <Switch>
                                                {subroutelist.map((route, idx) => {
                                                    if (route.isLogged) {
                                                        return <RouteMiddleware exact key={idx} path={route.path} component={route.component} isAuthenticated={auth.isAuthenticated}/>
                                                    } else {
                                                        return <Route exact key={idx} path={route.path} component={route.component}/>
                                                    }
                                                })}
                                            </Switch>
                                            :
                                            <Maintenance domain={domaine} shopName={data?.shop?.shopName}/>
                                    }

                                    {auth?.isAuthenticated  && (!messengerConf.length || !whatsappConf.length) ?  <BlackFeedback shop={data?.shop} auth={auth} /> : ""}

                                </Router>
                        }
                    </>
                    :

                    <NotAvailable domain={domaine} shopName={data.shop.shopName} />
            }


            {
                whatsappConf.length >  0  ?

                    <div className="superZIndex">
                        <WhatsAppWidget
                            companyName={whatsapp?.companyName ? whatsapp?.companyName : data?.shop?.shopName}
                            phoneNumber={whatsapp?.phoneNumber ? whatsapp?.phoneNumber : data?.shop?.phone}
                            sendButtonText={whatsapp?.sendButtonText ? whatsapp?.sendButtonText : t('Send')}
                            inputPlaceHolder={whatsapp?.inputPlaceHolder ? whatsapp?.inputPlaceHolder : t('Message') + "..."}
                            replyTimeText={whatsapp?.replyTimeText ? whatsapp?.replyTimeText : t('ReplyOnTime')}
                            message={whatsapp?.message ? whatsapp?.message : t('messageToWhatsapp')}
                        />
                    </div>
                    :

                    null

            }


            {
                messengerConf.length > 0 && messenger ?
                    <MessengerCustomerChat
                        pageId={messenger?.appId}
                        appId={messenger?.pageId}
                        autoLogAppEvents={true}
                        xfbml={true}
                        themeColor={data?.shop?.color}
                        greetingDialogDisplay="show"
                    />
                    :
                    null
            }

        </>


    );
}


const mapStateToProps = (state) => ({
    auth: state.subLogin
});

export default compose(connect(mapStateToProps, {loadSubMe}))(withTranslation()(PageManager));

