import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import { APPNAME, attachTokenToHeaders, SERVER_URL } from '../../../../helpers/utils'
import {toast} from "react-toastify";
import Editor from "../../../../themes/Editor";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const PageBuilder = ({auth,t}) => {

    document.title = APPNAME+" :: "+t('PageBuilder')

    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState(null)
    const [list, setList] = useState([])
    const [template, setTemplate] = useState(null)
    const slug = window.location.href.split('/').pop()

    useEffect(() => {
        getPageData()
    }, [slug]);

    const getPageData = async () => {
        setLoading(true)
        const slug = window.location.href.split('/').pop()
        await axios.get(SERVER_URL+"store/template/page/"+slug,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPageData(response.data.page)
                setList(response.data.list)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
            .catch((err) => {
                if (err.response.status === 401){
                    window.location.reload(true)
                }
            })

    }

    const saveTemplate = async (data) => {
        const slug = window.location.href.split('/').pop()
        const content = {
            content : JSON.stringify(data)
        }
        await axios.put(SERVER_URL+"store/template/page/edit/"+slug,content,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
    }



    return (
        <div>
            <Editor
                auth={auth}
                loading={loading}
                list={list}
                data={pageData}
                saveTemplate={saveTemplate}
                templateName={template}
                shop_data={auth?.shop}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});

export default compose(withRouter, connect(mapStateToProps, {})) (withTranslation()(PageBuilder))
