import React, {useEffect, useRef, useState} from 'react';
import {Link, Redirect, useParams} from "react-router-dom";
import SellerLayout from "../components/SellerLayout";
import {
    Capitalize,
    checkCurrentUrlSubdomain,
    CLIENT,
    numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL,
} from '../../../../helpers/utils'
import {withTranslation} from "react-i18next";
import axios from "axios";
import {SUB_INDEX} from "../routes/type";
import ShopPaginate from "../../../../components/Paginate/ShopPaginate";
import {MiddleLoading} from "../../../../components";
import {secondCalculDicount} from "../../../../themes/utils/utils";
import QuickView from "../../../../components/Card/QuickView";
import {toast} from "react-toastify";
import GroceryCard from "../components/Cards/GroceryCard";
import DefaultCard from "../components/Cards/DefaultCard";
import OldCard from "../components/Cards/OldCard";
import EmeraudeCard from "../components/Cards/EmeraudeCard";
import { Fade } from 'react-awesome-reveal'

const ProductBySubcategories = ({t}) => {

    const params = useParams()

    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState([])
    const [type, setType] = useState("grid")
    const [currentProducts,setCurrentProducts] = useState([]);
    const [products, setProducts] = useState([])
    const [subcategory, setSubcategory] = useState({})
    const [item, setItem] = useState({})
    const [open, setOpen] = useState(false)
    const [sorting, setSorting] = useState("default")


    const [shop, setShop] = useState({});

    const [className, setClassName] = useState("row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1");


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(15);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentProducts(products?.slice(indexOfFirstPost, indexOfLastPost))
    },[products])


    useEffect(() => {
        setCurrentProducts(products?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(products?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };



    useEffect(()=>{
        checkSubdomain()

    },[params])


    const checkSubdomain = async () => {
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShop(response.data)
                getPageData(response.data)
                getProductBycategory(response.data.shop.id)
            }
        })
    }

    const getProductBycategory = async (shop_id) => {
        await axios.get(SERVER_URL+"seller/products/"+shop_id+"/"+params.subcategory).then((response)=>{
            if (response.data.succeed){
                setProducts(response.data.products)
                setSubcategory(response.data.subcategory)
            }
            setLoading(false)
        })
    }


    const getPageData = async (data) => {
        setLoading(true)
        await axios.get(SERVER_URL+"store/template/page/"+data?.shop.shop_key+"/Shop").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                const data = response?.data?.page?.content
                if (data)  {
                    setPageData(JSON.parse(data))

                    switch (JSON.parse(data).card){
                        case "grocery":
                            setClassName("row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1");
                            break;
                        case "emeraude" :
                            setClassName("row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1")
                            break;
                        default :
                            setClassName("row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1")

                    }
                }


            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })

    }

    const sortBy = (option) => {
        setSorting(option)
        if (option === "LtoH") {
            const lth = products.sort((a, b) => a.price - b.price);
            setCurrentProducts(lth);
        }
        else if (option === "HtoL") {
            const htl = products.sort((a, b) => a.price - b.price).reverse();
            setCurrentProducts(htl);
        }
        else if (option === "rateHigh"){
            const hr = products.filter(e => parseInt(e.rating) > 3)
            setCurrentProducts(hr)
        }else{
            setCurrentProducts(products)
        }
    }


    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+params?.subcategory



    return (
       <Fade>
           <SellerLayout>
               <main>
                   <section className="breadcrumb__area include-bg pt-100 pb-50 breadcrumb__padding">
                       <div className={pageData.page_layout === 'fullwidth' ? 'container-fluid' : 'container'}>
                           <div className="row">
                               <div className="col-xxl-12">
                                   <div className="breadcrumb__content pRelative z-index-1">
                                       <h3 className="breadcrumb__title">{subcategory?.subname}</h3>
                                       <div className="breadcrumb__list">
                                           <span><Link to={SUB_INDEX}>{t('Home')}</Link></span>
                                           <span><Link
                                               to={'/shop/' + params.category}>{subcategory?.category}</Link></span>
                                           <span>{subcategory?.subname}</span>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>


                   {
                       loading ?
                           <MiddleLoading />
                           :
                           <section className="tp-shop-area pb-120 tp-shop-full-width-padding">
                               <div className={pageData.page_layout === 'fullwidth' ? 'container-fluid' : 'container'}>
                                   <div className="row">
                                       <div className="col-xl-12">
                                           <div className="tp-shop-main-wrapper">
                                               <div className="tp-shop-top mb-45">
                                                   <div className="row">
                                                       <div className="col-xl-6 col-lg-6 col-md-6">
                                                           <div className="tp-shop-top-left d-flex align-items-center ">
                                                               <div className="tp-shop-top-tab tp-tab">
                                                                   <ul className="nav nav-tabs" id="productTab"
                                                                       role="tablist">
                                                                       <li className="nav-item" role="presentation">
                                                                           <button className={`nav-link ${type === 'grid' ? 'active' : ''}`}
                                                                               onClick={event => setType('grid')}>
                                                                               <svg width="18" height="18"
                                                                                    viewBox="0 0 18 18" fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                   <path
                                                                                       d="M16.3327 6.01341V2.98675C16.3327 2.04675 15.906 1.66675 14.846 1.66675H12.1527C11.0927 1.66675 10.666 2.04675 10.666 2.98675V6.00675C10.666 6.95341 11.0927 7.32675 12.1527 7.32675H14.846C15.906 7.33341 16.3327 6.95341 16.3327 6.01341Z"
                                                                                       stroke="currentColor"
                                                                                       strokeWidth="1.5"
                                                                                       strokeLinecap="round"
                                                                                       strokeLinejoin="round" />
                                                                                   <path
                                                                                       d="M16.3327 15.18V12.4867C16.3327 11.4267 15.906 11 14.846 11H12.1527C11.0927 11 10.666 11.4267 10.666 12.4867V15.18C10.666 16.24 11.0927 16.6667 12.1527 16.6667H14.846C15.906 16.6667 16.3327 16.24 16.3327 15.18Z"
                                                                                       stroke="currentColor"
                                                                                       strokeWidth="1.5"
                                                                                       strokeLinecap="round"
                                                                                       strokeLinejoin="round" />
                                                                                   <path
                                                                                       d="M7.33268 6.01341V2.98675C7.33268 2.04675 6.90602 1.66675 5.84602 1.66675H3.15268C2.09268 1.66675 1.66602 2.04675 1.66602 2.98675V6.00675C1.66602 6.95341 2.09268 7.32675 3.15268 7.32675H5.84602C6.90602 7.33341 7.33268 6.95341 7.33268 6.01341Z"
                                                                                       stroke="currentColor"
                                                                                       strokeWidth="1.5"
                                                                                       strokeLinecap="round"
                                                                                       strokeLinejoin="round" />
                                                                                   <path
                                                                                       d="M7.33268 15.18V12.4867C7.33268 11.4267 6.90602 11 5.84602 11H3.15268C2.09268 11 1.66602 11.4267 1.66602 12.4867V15.18C1.66602 16.24 2.09268 16.6667 3.15268 16.6667H5.84602C6.90602 16.6667 7.33268 16.24 7.33268 15.18Z"
                                                                                       stroke="currentColor"
                                                                                       strokeWidth="1.5"
                                                                                       strokeLinecap="round"
                                                                                       strokeLinejoin="round" />
                                                                               </svg>
                                                                           </button>
                                                                       </li>
                                                                       <li className="nav-item" role="presentation">
                                                                           <button className={`nav-link ${type === 'list' ? 'active' : ''}`} onClick={event => setType('list')}>
                                                                               <svg width="16" height="15"
                                                                                    viewBox="0 0 16 15" fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                   <path d="M15 7.11108H1"
                                                                                         stroke="currentColor"
                                                                                         strokeWidth="2"
                                                                                         strokeLinecap="round"
                                                                                         strokeLinejoin="round" />
                                                                                   <path d="M15 1H1" stroke="currentColor"
                                                                                         strokeWidth="2"
                                                                                         strokeLinecap="round"
                                                                                         strokeLinejoin="round" />
                                                                                   <path d="M15 13.2222H1"
                                                                                         stroke="currentColor"
                                                                                         strokeWidth="2"
                                                                                         strokeLinecap="round"
                                                                                         strokeLinejoin="round" />
                                                                               </svg>
                                                                           </button>
                                                                       </li>
                                                                   </ul>
                                                               </div>
                                                               <div className="tp-shop-topResult">
                                                                   <p>{t('Showing')} {indexOfFirstPost + 1}–{indexOfLastPost} {t('Of')} {products?.length} {t('Results').toLowerCase()}</p>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div className="col-xl-6 col-lg-6 col-md-6">
                                                           <div
                                                               className="tp-shop-topRight tp-shop-topRight-2 d-sm-flex align-items-center justify-content-md-end">
                                                               <div className="">
                                                                   <select className="tp-shop-top-select nice-select"
                                                                           onChange={event => sortBy(event.target.value)}
                                                                           value={sorting} style={{
                                                                       borderRadius: '0',
                                                                       paddingRight: '5px',
                                                                   }}>
                                                                       <option
                                                                           value="default">{t('DefaultSorting')}</option>
                                                                       <option value="LtoH">{t('LowtoHight')}</option>
                                                                       <option value="HtoL">{t('HightoLow')}</option>
                                                                       <option value="rateHigh">{t('RateHigh')}</option>
                                                                   </select>
                                                               </div>

                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="tp-shop-items-wrapper tp-shop-item-primary">
                                                   <div className="tab-content" id="productTabContent">
                                                       <div
                                                           className={`tab-pane fade ${type === 'grid' ? 'show active' : ''}`}>
                                                           <div className={className}>
                                                               {
                                                                   currentProducts?.length > 0
                                                                       ?
                                                                       <>
                                                                           {
                                                                               currentProducts?.map((product, key) =>
                                                                                   <div className="col" key={key}>
                                                                                       {pageData?.card === 'grocery' &&
                                                                                           <GroceryCard item={product}
                                                                                                        server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                        color={shop?.shop.color} />}
                                                                                       {pageData?.card === 'default' || !pageData ?
                                                                                           <DefaultCard item={product}
                                                                                                        server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                        setItem={setItem}
                                                                                                        setOpen={setOpen}
                                                                                                        color={shop?.shop.color} /> : ''}
                                                                                       {pageData?.card === 'defaultWithoutBord' &&
                                                                                           <OldCard item={product}
                                                                                                    server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                    setItem={setItem}
                                                                                                    setOpen={setOpen}
                                                                                                    color={shop?.shop.color} />}
                                                                                       {pageData?.card === 'emeraude' &&
                                                                                           <EmeraudeCard item={product}
                                                                                                         server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                         setItem={setItem}
                                                                                                         setOpen={setOpen} />}
                                                                                       {
                                                                                           !pageData?.card &&
                                                                                           <DefaultCard item={product}
                                                                                                        server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                        setItem={setItem}
                                                                                                        setOpen={setOpen}
                                                                                                        color={shop?.shop.color} />
                                                                                       }
                                                                                   </div>,
                                                                               )
                                                                           }
                                                                       </>
                                                                       :

                                                                       <div className="container">
                                                                           <div className="row">
                                                                               <div className="col-lg-12 col-md-12">
                                                                                   <div className="empty">
                                                                                       <div className="empty__wrap">
                                                                                        <span
                                                                                            className="empty__big-text">{t('Empty')}</span>
                                                                                           <span
                                                                                               className="empty__text-1">{t('AllListEmpty')}</span>
                                                                                       </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>

                                                                       </div>
                                                               }

                                                           </div>
                                                       </div>
                                                       <div className={`tab-pane fade ${type === 'list' ? 'show active' : ''}`}>
                                                           <div className="tp-shop-list-wrapper tp-shop-item-primary mb-70">
                                                               <div className="row justify-content-center">
                                                                   <div className="col-xl-8">
                                                                       {
                                                                           currentProducts?.length > 0
                                                                               ?
                                                                               <>
                                                                                   {
                                                                                       currentProducts?.map((product, key) =>
                                                                                           <div
                                                                                               className="tp-product-list-item d-md-flex"
                                                                                               key={key}>
                                                                                               <div
                                                                                                   className="tp-product-list-thumb pRelative fix">
                                                                                                   <Link
                                                                                                       to={'/product/detail/' + product.hash_key}>
                                                                                                       <img
                                                                                                           src={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/' + JSON.parse(product.pictures)[0]}
                                                                                                           style={{
                                                                                                               width: '350px',
                                                                                                               height: '310px',
                                                                                                               border: '1px solid #ccc',
                                                                                                               borderRight: '0',
                                                                                                               padding: '10px',
                                                                                                           }} alt="" />
                                                                                                   </Link>
                                                                                               </div>
                                                                                               <div
                                                                                                   className="tp-product-list-content">
                                                                                                   <div
                                                                                                       className="tp-product-content-2 pt-15">
                                                                                                       <div
                                                                                                           className="tp-product-tag-2">
                                                                                                           <Link
                                                                                                               to={'/'}>{product.category}</Link>
                                                                                                       </div>
                                                                                                       <h3 className="tp-product-title-2">
                                                                                                           <Link
                                                                                                               to={''}>{product.title}</Link>
                                                                                                       </h3>
                                                                                                       <div
                                                                                                           className="tp-product-rating-icon tp-product-rating-icon-2">

                                                                                                           {
                                                                                                               product.rating ?
                                                                                                                   <>
                                                                                                                       {
                                                                                                                           [...Array(parseInt(product.rating))].map((item, key) => (
                                                                                                                               <span
                                                                                                                                   key={key}><i
                                                                                                                                   className="fa-solid fa-star"></i></span>
                                                                                                                           ))
                                                                                                                       }
                                                                                                                   </>
                                                                                                                   :
                                                                                                                   ''
                                                                                                           }
                                                                                                       </div>
                                                                                                       {
                                                                                                           product?.discount &&
                                                                                                           <>
                                                                                                               {
                                                                                                                   parseInt(product?.discount) > 0 ?
                                                                                                                       <div
                                                                                                                           className="tp-product-details-price-wrapper mb-20">
                                                                                                                        <span
                                                                                                                            className="tp-product-details-price old-price">{numberWithCommas(product?.price)} Francs CFA</span>
                                                                                                                           <span
                                                                                                                               className="tp-product-details-price new-price bold pl-10">{numberWithCommas(secondCalculDicount(parseInt(product?.price), parseInt(product?.discount)))} Francs CFA</span>
                                                                                                                       </div>
                                                                                                                       :
                                                                                                                       <div
                                                                                                                           className="tp-product-details-price-wrapper mb-20">
                                                                                                                        <span
                                                                                                                            className="tp-product-details-price new-price">{numberWithCommas(product?.price)} Francs CFA</span>
                                                                                                                       </div>
                                                                                                               }
                                                                                                           </>
                                                                                                       }
                                                                                                       <p>{product.short_description}</p>
                                                                                                       <div
                                                                                                           className="tp-product-list-add-to-cart">
                                                                                                           <button
                                                                                                               className="tp-product-list-add-to-cart-btn">{t('BuyNow')}</button>
                                                                                                       </div>
                                                                                                   </div>
                                                                                               </div>
                                                                                           </div>,
                                                                                       )
                                                                                   }
                                                                               </>
                                                                               :

                                                                               <div className="">
                                                                                   <div className="u-s-p-y-60">
                                                                                       <div className="section__content">
                                                                                           <div className="container">
                                                                                               <div className="row">
                                                                                                   <div
                                                                                                       className="col-lg-12 col-md-12 u-s-m-b-30">
                                                                                                       <div
                                                                                                           className="empty">
                                                                                                           <div
                                                                                                               className="empty__wrap">
                                                                                                            <span
                                                                                                                className="empty__big-text">{t('Empty')}</span>
                                                                                                               <span
                                                                                                                   className="empty__text-1">{t('AllListEmpty')}</span>
                                                                                                           </div>
                                                                                                       </div>
                                                                                                   </div>
                                                                                               </div>
                                                                                           </div>
                                                                                       </div>
                                                                                   </div>

                                                                               </div>
                                                                       }

                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                               <ShopPaginate
                                                   postsPerPage={postsPerPage}
                                                   totalPosts={products?.length}
                                                   paginate={paginate}
                                                   previousPage={previousPage}
                                                   nextPage={nextPage}
                                                   current={currentPage}
                                                   color={shop?.shop.color}
                                               />
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </section>
                   }


               </main>
               <QuickView
                   data={item}
                   shop_data={shop?.shop}
                   setOpen={setOpen}
                   open={open}
               />
           </SellerLayout>
       </Fade>
    )
}

export default withTranslation()(ProductBySubcategories)

