import React, {Fragment, useState} from 'react';
import {flatStyle} from "../../../utils/utils";

const Display = ({data,updateValue,updateUnit}) => {

    const [toggle, setToggle] = useState(false)

    return (
       <Fragment>
           <label className="header" htmlFor="header_display_header" onClick={event => setToggle(!toggle)}>
               <span>Display</span>
               <div className="header-arrow"></div>
           </label>
           <input className="header_check" type="checkbox" defaultChecked={toggle}  id="header_display_header"/>

           {
               toggle ?
                   <div className="row ml-10 mr-10" data-section="display_header">
                       <div className="mb-3 col-sm-6" data-key="display">
                           <label className="form-label" htmlFor="input-model">Display</label>
                           <div className="input">
                               <div>
                                   <select className="form-select" name="display" value={flatStyle(data,'display').value} onChange={event => updateValue("display",event.target.value)} >
                                       <option value="block">Block</option>
                                       <option value="inline">Inline</option>
                                       <option value="inline-block">
                                           Inline Block
                                       </option>
                                       <option value="inline-block">
                                           Inline Block
                                       </option>
                                       <option value="flex">Flex</option>
                                       <option value="inline-flex">Inline Flex</option>
                                       <option value="grid">Grid</option>
                                       <option value="inline-grid">Inline grid</option>
                                       <option value="table">Table</option>
                                       <option value="table-row">Table Row</option>
                                       <option value="list-item">List Item</option>
                                       <option value="inherit">Inherit</option>
                                       <option value="initial">Initial</option>
                                       <option value="none">none</option>
                                   </select>
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-6" data-key="position">
                           <label className="form-label" htmlFor="input-model">Position</label>
                           <div className="input">
                               <div>
                                   <select className="form-select" name="position"
                                           value={flatStyle(data,'position').value}
                                           onChange={event => updateValue("position",event.target.value)}
                                   >
                                       <option value="static">Static</option>
                                       <option value="fixed">Fixed</option>
                                       <option value="relative">Relative</option>
                                       <option value="absolute">Absolute</option>
                                   </select>
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-6" data-key="top">
                           <label className="form-label" htmlFor="input-model">Top</label>
                           <div className="input">
                               <div className="input-group css-unit" id="cssunit-top">
                                   <input
                                       name="number"
                                       type="number"
                                       className="form-control"
                                       value={flatStyle(data,'top').value}
                                       onChange={event => updateValue("top",event.target.value)}
                                   />
                                   <div className="input-group-append">
                                       <select className="form-select small-arrow"  name="unit"
                                               value={flatStyle(data,'top').unit}
                                               onChange={event => updateUnit("top",event.target.value)}
                                       >
                                           <option value="em">em</option>
                                           <option value="rem">rem</option>
                                           <option value="px">px</option>
                                           <option value="%">%</option>
                                           <option value="vw">vw</option>
                                           <option value="vh">vh</option>
                                           <option value="ex">ex</option>
                                           <option value="ch">ch</option>
                                           <option value="cm">cm</option>
                                           <option value="mm">mm</option>
                                           <option value="in">in</option>
                                           <option value="pt">pt</option>
                                           <option value="auto">auto</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-6" data-key="left">
                           <label className="form-label" htmlFor="input-model">Left</label>
                           <div className="input">
                               <div className="input-group css-unit" id="cssunit-left">
                                   <input
                                       name="number"
                                       type="number"
                                       className="form-control"
                                       value={flatStyle(data,'left').value}
                                       onChange={event => updateValue("left",event.target.value)}
                                   />
                                   <div className="input-group-append">
                                       <select
                                           className="form-select small-arrow"
                                           name="unit"
                                           value={flatStyle(data,'left').unit}
                                           onChange={event => updateUnit("left",event.target.value)}
                                       >
                                           <option value="em">em</option>
                                           <option value="rem">rem</option>
                                           <option value="px">px</option>
                                           <option value="%">%</option>
                                           <option value="vw">vw</option>
                                           <option value="vh">vh</option>
                                           <option value="ex">ex</option>
                                           <option value="ch">ch</option>
                                           <option value="cm">cm</option>
                                           <option value="mm">mm</option>
                                           <option value="in">in</option>
                                           <option value="pt">pt</option>
                                           <option value="auto">auto</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-6" data-key="bottom">
                           <label className="form-label" htmlFor="input-model">Bottom</label>
                           <div className="input">
                               <div className="input-group css-unit" id="cssunit-bottom">
                                   <input
                                       name="number"
                                       type="number"
                                       className="form-control"
                                       value={flatStyle(data,'bottom').value}
                                       onChange={event => updateValue("bottom",event.target.value)}
                                   />
                                   <div className="input-group-append">
                                       <select
                                           className="form-select small-arrow"
                                           name="unit"
                                           value={flatStyle(data,'bottom').unit}
                                           onChange={event => updateUnit("bottom",event.target.value)}
                                       >
                                           <option value="em">em</option>
                                           <option value="rem">rem</option>
                                           <option value="px">px</option>
                                           <option value="%">%</option>
                                           <option value="vw">vw</option>
                                           <option value="vh">vh</option>
                                           <option value="ex">ex</option>
                                           <option value="ch">ch</option>
                                           <option value="cm">cm</option>
                                           <option value="mm">mm</option>
                                           <option value="in">in</option>
                                           <option value="pt">pt</option>
                                           <option value="auto">auto</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-6" data-key="right">
                           <label className="form-label" htmlFor="input-model">Right</label>
                           <div className="input">
                               <div className="input-group css-unit" id="cssunit-right">
                                   <input
                                       name="number"
                                       type="number"
                                       className="form-control"
                                       value={flatStyle(data,'right').value}
                                       onChange={event => updateValue("right",event.target.value)}
                                   />
                                   <div className="input-group-append">
                                       <select
                                           className="form-select small-arrow"
                                           name="unit"
                                           value={flatStyle(data,'right').unit}
                                           onChange={event => updateUnit("right",event.target.value)}
                                       >
                                           <option value="em">em</option>
                                           <option value="rem">rem</option>
                                           <option value="px">px</option>
                                           <option value="%">%</option>
                                           <option value="vw">vw</option>
                                           <option value="vh">vh</option>
                                           <option value="ex">ex</option>
                                           <option value="ch">ch</option>
                                           <option value="cm">cm</option>
                                           <option value="mm">mm</option>
                                           <option value="in">in</option>
                                           <option value="pt">pt</option>
                                           <option value="auto">auto</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-12" data-key="float">
                           <label className="form-label" htmlFor="input-model">Float</label>
                           <div className="input">
                               <div className="btn-group btn-group-sm btn-group-fullwidth clearfix" role="group">
                                   <input name="float" className="btn-check" type="radio" value="none" id="rb-6float0" />
                                   <label className="btn btn-outline-primary" htmlFor="rb-6float0" title="None">
                                       <i className="la la-times"></i>
                                   </label>
                                   <input name="float" className="btn-check" type="radio" value="left" id="rb-6float1" autoComplete="off"/>
                                   <label className="btn btn-outline-primary" htmlFor="rb-6float1" title="Left">
                                       <i className="la la-align-left"></i>
                                   </label>
                                   <input name="float" className="btn-check" type="radio" value="right" id="rb-6float2" autoComplete="off"/>
                                   <label className="btn btn-outline-primary" htmlFor="rb-6float2" title="Right">
                                       <i className="la la-align-right"></i>
                                   </label>
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-12" >
                           <label className="form-label" htmlFor="input-model">Opacity</label>
                           <div className="input">
                               <div className="input-range">
                                   <input
                                       name="opacity"
                                       type="number"
                                       min="0"
                                       max="1"
                                       step="0.1"
                                       value={flatStyle(data,'opacity').value}
                                       onChange={event => updateValue("opacity",event.target.value)}
                                       className="form-control"
                                   />
                               </div>
                           </div>
                           <br/>
                       </div>

                       <div className="mb-3 col-sm-6 inline" data-key="background-color">
                           <label className="form-label" htmlFor="input-model">Background Color</label>
                           <div className="input">
                               <div>
                                   <input
                                       name="background-color"
                                       type="color"
                                       pattern="#[a-f0-9]{6}"
                                       className="form-control form-control-color"
                                       value={flatStyle(data,'backgroundColor').value}
                                       onChange={event => updateValue("backgroundColor",event.target.value)}
                                   />
                               </div>
                           </div>
                       </div>
                       <div className="mb-3 col-sm-6 inline" data-key="color">
                           <label className="form-label" htmlFor="input-model">Text Color</label>
                           <div className="input">
                               <div>
                                   <input
                                       name="color"
                                       type="color"
                                       pattern="#[a-f0-9]{6}"
                                       className="form-control form-control-color"
                                       value={flatStyle(data,'color').value}
                                       onChange={event => updateValue("color",event.target.value)}
                                   />
                               </div>
                           </div>
                       </div>
                   </div>
                   :
                   ""
           }
       </Fragment>
    )
}

export default Display;
