import React from 'react';
import LevelAuthorized from "./Authorized/LevelAuthorized";

const LevelAccess = ({auth,children, level = 3,link= false}) => {
    if (auth?.plan?.pricing_id > level){
           return children
    }else {
       if (link){
           return ""
       }else{
           return <LevelAuthorized />
       }
    }
}

export default LevelAccess;
