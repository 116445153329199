import React from 'react';
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";

const Answer = ({initialValues,addNewComment,validationSchema,loading,t,isSub= false}) => {
    return (
        <div className="flex-grow-1 ms-2 ms-sm-3">
            <Formik initialValues={initialValues} onSubmit={addNewComment} validationSchema={validationSchema}>
                {({ errors, touched,values }) =>
                    <Form>
                        <div className="row">
                            <div className="col-lg-11">
                                {!isSub ? <h5>{t('AddComment')}</h5> : "" }
                                <Field as="textarea" className="form-control py-1 px-1" rows="2" placeholder={t('AddComment')+"..."}  name="comment"/>
                            </div>
                            <div className="col-lg-1">
                                <button className={`btn btn-info  ${isSub ? "" : "m-t-30"}`} disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span><i className="fas fa-arrow-right"></i></span>
                                    }

                                </button>
                            </div>
                        </div>


                    </Form>
                }

            </Formik>
        </div>
    );
}

export default withTranslation()(Answer);
