import React from 'react';
import {withTranslation} from "react-i18next";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import es from 'react-phone-input-2/lang/es.json'
const CustomPhoneInput = ({phone,setPhone,t,setCountry}) => {
    return (
        <>
            <PhoneInput
                country='tg'
                localization={es}
                value={phone}
                regions='africa'
                inputClass="form-control form-control-lg"
                specialLabel={t('Phone')}
                inputStyle={{width : "100%"}}
                onChange={(phone,country) => {
                    setPhone(phone)
                    setCountry(country)
                }}
            />
        </>
    )
}

export default withTranslation()(CustomPhoneInput);