import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {MiddleLoading} from "../../../../components";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {Link} from "react-router-dom";
import {LINK_STORE_APPS} from "../../../../routes/type";
import _Dashboard from "./parts/_Dashboard";
import _BarCode from "./parts/_BarCode";
import _QrCode from "./parts/_QrCode";
import _Template from "./parts/_Template";
import axios from "axios";
import PluginAccess from "../../../../components/PluginAccess";

const Labels = ({t,auth}) => {

    const [loading, setLoading] = useState(true)
    const [step, setStep] = useState(1)
    const [label, setLabel] = useState(null)

    const getLabelInfo = () => {
         axios.get(SERVER_URL+"package/store/label",attachTokenToHeaders())
             .then((response) => {
                 setLoading(false)
              setLabel(response.data.plugin)
         }).catch((err) => {
             if (err.response.status === 401){
                 window.location.reload(true)
             }
         })
    }

    useEffect(() => {
        getLabelInfo()
    }, []);


    return (
        <AdminLayout>
               <div className="row">
                   <div className="col-12">
                       <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                           <h4 className="mb-sm-0">{t('Labels')}</h4>

                           <div className="page-title-right">
                               <ol className="breadcrumb m-0">
                                   <li className="breadcrumb-item">{APPNAME}</li>
                                   <li className="breadcrumb-item"><Link to={LINK_STORE_APPS}>{t('Applications')}</Link></li>
                                   <li className="breadcrumb-item active">{t('Labels')}</li>
                               </ol>
                           </div>

                       </div>
                   </div>
               </div>
            <PluginAccess plugin="label" auth={auth} free={false}>
               {
                   loading ?
                       <MiddleLoading/>
                       :
                       <div className="row">
                           <div className="col-xxl-12">
                               <div className="card">
                                   <div className="card-header align-items-center d-flex">
                                       <div className="flex-grow-1 oveflow-hidden"></div>
                                       <div className="flex-shrink-0 ms-2">
                                           <ul className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                               <li className="nav-item" role="presentation">
                                                   <a className={`nav-link cursor-pointer ${step === 1 ? "active" : ""}`} onClick={event => setStep(1)}>
                                                       {t('Dashboard')}
                                                   </a>
                                               </li>
                                               <li className="nav-item" role="presentation">
                                                   <a className={`nav-link cursor-pointer ${step === 2 ? "active" : ""}`} onClick={event => setStep(2)}>
                                                       {t('BarCode')}
                                                   </a>
                                               </li>

                                               <li className="nav-item" role="presentation">
                                                   <a className={`nav-link cursor-pointer ${step === 3 ? "active" : ""}`} onClick={event => setStep(3)}>
                                                       {t('QrCode')}
                                                   </a>
                                               </li>
                                               <li className="nav-item" role="presentation">
                                                   <a className={`nav-link cursor-pointer ${step === 4 ? "active" : ""}`} onClick={event => setStep(4)}>
                                                       Template
                                                   </a>
                                               </li>
                                           </ul>
                                       </div>
                                   </div>
                                   <div className="card-body">
                                       <div className="tab-content text-muted">
                                           {step === 1 ? <_Dashboard auth={auth} t={t} label={label} setStep={setStep} /> : ""}
                                           {step === 2 ? <_BarCode auth={auth} t={t} /> : ""}
                                           {step === 3 ? <_QrCode auth={auth} t={t} /> : ""}
                                           {step === 4 ? <_Template auth={auth} t={t}  /> : ""}
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
               }
           </PluginAccess>
        </AdminLayout>
    );
};

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(Labels));
