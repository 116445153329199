import React, {Fragment, useState} from 'react';
import {flatStyle} from "../../../utils/utils";

const Margin = ({data,updateValue,updateUnit}) => {
    const [toggle, setToggle] = useState(false)

    return (
    <Fragment>
        <label className="header" data-header="margins_header" onClick={event => setToggle(!toggle)} htmlFor="header_margins_header" >
         <span>Margin</span>
         <div className="header-arrow"></div>
        </label>
        <input className="header_check" type="checkbox" defaultChecked={toggle} id="header_margins_header"/>

        {
            toggle ?
                <div className="row ml-20 mr-20" data-section="margins_header" >
                    <div className="mb-3 col-sm-6" data-key="margin-top">
                        <label className="form-label" htmlFor="input-model">Top</label>
                        <div className="input">
                            <div
                                className="input-group css-unit"
                                id="cssunit-margin-top"
                            >
                                <input
                                    name="number"
                                    type="number"
                                    className="form-control"
                                    value={flatStyle(data,'marginTop').value}
                                    onChange={event => updateValue("marginTop",event.target.value)}
                                />
                                <div className="input-group-append">
                                    <select
                                        className="form-select small-arrow"
                                        name="unit"
                                        value={flatStyle(data,'marginTop').unit}
                                        onChange={event => updateUnit("marginTop",event.target.value)}
                                    >
                                        <option value="em">em</option>
                                        <option value="rem">rem</option>
                                        <option value="px">px</option>
                                        <option value="%">%</option>
                                        <option value="vw">vw</option>
                                        <option value="vh">vh</option>
                                        <option value="ex">ex</option>
                                        <option value="ch">ch</option>
                                        <option value="cm">cm</option>
                                        <option value="mm">mm</option>
                                        <option value="in">in</option>
                                        <option value="pt">pt</option>
                                        <option value="auto">auto</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 col-sm-6" data-key="margin-right">
                        <label className="form-label" htmlFor="input-model">Right</label>
                        <div className="input">
                            <div
                                className="input-group css-unit"
                                id="cssunit-margin-right"
                            >
                                <input
                                    name="number"
                                    type="number"
                                    className="form-control"
                                    value={flatStyle(data,'marginRight').value}
                                    onChange={event => updateValue("marginRight",event.target.value)}
                                />
                                <div className="input-group-append">
                                    <select
                                        className="form-select small-arrow"
                                        name="unit"
                                        value={flatStyle(data,'marginRight').unit}
                                        onChange={event => updateUnit("marginRight",event.target.value)}
                                    >
                                        <option value="em">em</option>
                                        <option value="rem">rem</option>
                                        <option value="px">px</option>
                                        <option value="%">%</option>
                                        <option value="vw">vw</option>
                                        <option value="vh">vh</option>
                                        <option value="ex">ex</option>
                                        <option value="ch">ch</option>
                                        <option value="cm">cm</option>
                                        <option value="mm">mm</option>
                                        <option value="in">in</option>
                                        <option value="pt">pt</option>
                                        <option value="auto">auto</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 col-sm-6" data-key="margin-bottom">
                        <label className="form-label" htmlFor="input-model">Bottom</label>
                        <div className="input">
                            <div
                                className="input-group css-unit"
                                id="cssunit-margin-bottom"
                            >
                                <input
                                    name="number"
                                    type="number"
                                    className="form-control"
                                    value={flatStyle(data,'marginBottom').value}
                                    onChange={event => updateValue("marginBottom",event.target.value)}
                                />
                                <div className="input-group-append">
                                    <select
                                        className="form-select small-arrow"
                                        name="unit"
                                        value={flatStyle(data,'marginBottom').unit}
                                        onChange={event => updateUnit("marginBottom",event.target.value)}
                                    >
                                        <option value="em">em</option>
                                        <option value="rem">rem</option>
                                        <option value="px">px</option>
                                        <option value="%">%</option>
                                        <option value="vw">vw</option>
                                        <option value="vh">vh</option>
                                        <option value="ex">ex</option>
                                        <option value="ch">ch</option>
                                        <option value="cm">cm</option>
                                        <option value="mm">mm</option>
                                        <option value="in">in</option>
                                        <option value="pt">pt</option>
                                        <option value="auto">auto</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 col-sm-6" data-key="margin-left">
                        <label className="form-label" htmlFor="input-model">Left</label>
                        <div className="input">
                            <div
                                className="input-group css-unit"
                                id="cssunit-margin-left"
                            >
                                <input
                                    name="number"
                                    type="number"
                                    className="form-control"
                                    value={flatStyle(data,'marginLeft').value}
                                    onChange={event => updateValue("marginLeft",event.target.value)}
                                />
                                <div className="input-group-append">
                                    <select
                                        className="form-select small-arrow"
                                        name="unit"
                                        value={flatStyle(data,'marginLeft').unit}
                                        onChange={event => updateUnit("marginLeft",event.target.value)}
                                    >
                                        <option value="em">em</option>
                                        <option value="rem">rem</option>
                                        <option value="px">px</option>
                                        <option value="%">%</option>
                                        <option value="vw">vw</option>
                                        <option value="vh">vh</option>
                                        <option value="ex">ex</option>
                                        <option value="ch">ch</option>
                                        <option value="cm">cm</option>
                                        <option value="mm">mm</option>
                                        <option value="in">in</option>
                                        <option value="pt">pt</option>
                                        <option value="auto">auto</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""
        }
    </Fragment>
    )
}

export default Margin;
