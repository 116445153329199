import React from 'react';
import {withTranslation} from "react-i18next";

const PublicPaginate = ({ postsPerPage, totalPosts ,paginate,previousPage,nextPage,t,current}) => {

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }


    return (
        <>
            {
                totalPosts > 0 ?

                <ul className="post-pagination">
                       <li><a className='cursor-pointer' onClick={()=>previousPage()}><i className="fas fa-angle-left"></i></a></li>
                                {pageNumbers.map((number) => (
                                     <li key={number}   onClick={() => paginate(number)}><a className={`cursor-pointer ${current == number ? "active" : null}`} >{number}</a></li>
                              ))}
                         <li><a className='' onClick={()=>nextPage()}><i className="fas fa-angle-right"></i></a></li>
                </ul>

                        : null
                    }
        </>
    );
};

export default  withTranslation() (PublicPaginate);
