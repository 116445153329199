import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal} from "../../../../components";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";

const InviteDeliverer = ({open,setOpen,t,data,auth}) => {

    const [loading, setLoading] = useState(false)
    const sendInvitationTo = async () => {

        setLoading(true)
        const dataSend = {
            shop_id : auth?.shop.id,
            shopName : auth?.shop.shopName,
            email : auth?.shop.email,
            deliverId : data.id,
            deliverEmail : data.email
        }

        try {
            await axios.post(`${SERVER_URL}deliverer/invite`,dataSend,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed) {
                    toast.success(t(response.data.message))
                     setOpen(false)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    return (
        <CustomModal open={open} setOpen={setOpen} isHtml={true}  size="md" centered={true}>
            <div className="p-2 text-center">
                <lord-icon src="https://cdn.lordicon.com/gzmgulpl.json" trigger="loop" colors="primary:#405189,secondary:#f06548" style={{width:"90px",height:"90px"}}></lord-icon>
                <div className="mt-4 text-center">
                    <h4 className="fs-semibold">{t('InviteDeliverer')} <b>{data?.firstname} {data?.lastname}</b></h4>
                    <p className="text-muted fs-14 mb-4 pt-1">{t('InviteDelivererText')}</p>
                    <div className="hstack gap-2 justify-content-center remove">
                        <button className="btn btn-success" id="delete-record" onClick={event => sendInvitationTo()}>{t('SendInvitation')}</button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
}

export default withTranslation()(InviteDeliverer);
