import {SUB_ADD_WISHLIST, SUB_CLEAR_WISHLIST, SUB_DELETE_WISHLIST} from "./type";

export function addWishItem(payload){
    return {
        type:SUB_ADD_WISHLIST,
        payload : payload
    }
}

export function  deleteWishList(payload){
    return{
        type:SUB_DELETE_WISHLIST,
        payload : payload
    }
}



export function  clearWishList(payload){
    return{
        type:SUB_CLEAR_WISHLIST,
        payload : payload
    }
}
