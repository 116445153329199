import React, {useEffect, useState} from 'react';
import {APPNAME, numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {loadShopPricing} from "../../../../store/Pricing/action";
import Cookies from "js-cookie";
import axios from "axios";
import {toast} from "react-toastify";
import {RegLoading} from "../../../../components";
import PricingModal from "./PricingModal";


const Pricing = ({t,list_pricing : {isLoading,error,pricing},loadShopPricing,setStep}) => {

    document.title = APPNAME+" :: "+t('Pricing')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('Pricing'))
   

    const [all_price,setAllPrice] = useState(0)
    const [loading,setLoading] = useState(false)
    const [GLoading,setGLoading] = useState(false)
    const [open,setOpen] = useState(false)
    const [data,setData] = useState(null)

    useEffect(()=>{
        loadShopPricing()
    },[])

    const choosePlan = async (plan) =>{
        window.scrollTo(0,0)
        setLoading(true)
        const data = {
            plan : plan,
            shop_key : Cookies.get('shop_key'),
        }
        await axios.post(SERVER_URL+"pricing/choose",data).then((response)=>{
            setLoading(false)
            setOpen(false)

            if (response.data.succeed){
                setGLoading(true)
                setTimeout(()=>{
                    setGLoading(false)
                    setStep(5)
                    Cookies.set('step',5)
                    const price = parseInt(all_price) + parseInt(plan.price)
                    Cookies.set('total_price',price)
                    setAllPrice(price)
                },2000)

            }else{
                toast.error(t(response.data.message))
            }
        })

    }

    const selectPlan= (plan) => {
        setOpen(true)
        setData(plan)
    }



    return (
        <div className="">
            <div className="row wow fadeInUp mt-20"  data-wow-delay="0.1s">
                <div className="col-md-10">
                    <h2 className="page-title">{t('Pricing')}  </h2>
                </div>
            </div>
            <hr/>

            <div className="p-5">
                {
                    isLoading || loading || GLoading

                        ?
                        <RegLoading/>
                        :

                        <div className="row">
                            <div className=" col-md-12">
                                <div className="row">
                                    {
                                        pricing.length > 0 ?
                                            <>
                                                {
                                                    pricing.slice(1).map((price,key)=>

                                                        <div key={key} className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                                                             data-wow-delay="0.3s"
                                                             style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInUp"}}>
                                                            <div className="pricing-table m-t-30">

                                                                <div className="plan-cost">
                                                                    <span className="currency">XOF</span>
                                                                    <span className="price">{numberWithCommas(price.price)}</span>
                                                                    <span className="plan-type">/ {t('Month')}</span>
                                                                </div>

                                                                <button  className="template-btn primary-bg-5" onClick={event => selectPlan(price)}>{t('GetStart')} <i className="fas fa-arrow-right"></i></button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <>
                                                liste vide
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                }

            </div>

            <PricingModal
                title={t("Pricing")}
                open={open}
                setOpen={setOpen}
                data={data}
                ChoosePricing={choosePlan}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    list_pricing : state.list_pricing
});


export default   compose(connect(mapStateToProps, {  loadShopPricing })) (withTranslation()(Pricing));