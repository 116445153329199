import React, {useEffect, useState} from 'react';
import hero from "../template/styles/hero.jpg"
import {withTranslation} from "react-i18next";
import axios from "axios";
import {APPNAME, filterProductByDate, SERVER_URL, SortImageByProduct} from "../../../../helpers/utils";
import TrialCard from "../template/blocs/TrialCard";
import hero1 from "../template/styles/01.jpg"
import hero2 from "../template/styles/02.jpg"
import {Link} from "react-router-dom";
import TrialCardVertical from "../template/blocs/TrialCardVertical";

const  TrialHome = ({t}) => {

    const [shop,setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));
    const [categories,setCategories] = useState([]);
    const [products,setProducts] = useState([]);
    const [galeries,setGaleries] = useState([]);


    useEffect(() => {
       if (shop){
           getCategories()
       }
    }, []);

    const getCategories = async () => {
        await axios.get(SERVER_URL+"front/alltrialhome/"+shop?.id,).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.categories)
                setProducts(response.data.products)
                setGaleries(response.data.galeries)
            }else{

            }
        })
    }


    return (
        <>
            <div className="s-skeleton s-skeleton--bg-grey">
                <div className="primary-style-1" id="hero-slider">
                    <div className="hero-slide hero-slide--1" style={{ background: `url(${hero}) center center cover no-repeat`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="slider-content slider-content--animation">

                                        <h1 className="content-span-1 u-c-white">{t('WelcomeOnShop')}</h1>

                                        <span className="content-span-2 u-c-white">{shop?.shopName}</span>

                                        <span className="content-span-3 u-c-white" style={{width : "50%"}}>{t('FindQualityGoodPrice')}</span>

                                        <br/>
                                        <Link className="btn btn-lg btn-info" to="/products">{t('ShopNow')}</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                products?.length > 0
                ?
                    <div>

                        <div className="u-s-p-y-60">


                            <div className="section__intro u-s-m-b-46">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="section__text-wrap">
                                                <h1 className="section__heading u-c-secondary u-s-m-b-12">{t('DiscoverOurProducts')}</h1>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="section__content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-5 u-s-m-b-30">

                                            <a className="collection">
                                                <div className="aspect aspect--bg-grey aspect--square">

                                                    <img className="aspect__img collection__img" src={hero1}
                                                         alt=""/></div>
                                            </a></div>
                                        <div className="col-lg-7 col-md-7 u-s-m-b-30">

                                            <a className="collection">
                                                <div className="aspect aspect--bg-grey aspect--1286-890">

                                                    <img className="aspect__img collection__img" src={hero2}
                                                         alt=""/></div>
                                            </a></div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {products?.length > 0 ?
                             <div>

                                 <div className="u-s-m-t-60 u-s-m-b-60">


                                     <div className="section__intro u-s-m-b-16">
                                         <div className="container">
                                             <div className="row">
                                                 <div className="col-lg-12">
                                                     <div className="section__text-wrap">
                                                         <h2 className="section__heading u-c-secondary u-s-m-b-12">Top 5 {t('Categories')}</h2>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>




                                     <div className="section__content u-s-m-t-60">
                                         <div className="container">
                                             <div className="row">
                                                 <div className="col-lg-12">
                                                     <div className="row">
                                                         {
                                                             categories?.length > 0 ?
                                                                 <>
                                                                     {
                                                                         categories?.slice(0,5).map((cate,k) =>
                                                                             <div key={k} className="col-lg-4">
                                                                                 <TrialCardVertical  category={cate} shop={shop} />
                                                                             </div> )
                                                                     }
                                                                 </>
                                                                 :
                                                                 null
                                                         }

                                                     </div>

                                                 </div>
                                             </div>
                                         </div>
                                     </div>

                                 </div>

                             </div>

                            :
                            null}

                        <div className="banner-bg">


                            <div className="section__content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="banner-bg__countdown">
                                                <div className="countdown countdown--style-banner"
                                                     data-countdown="2020/05/01"></div>
                                            </div>
                                            <div className="banner-bg__wrap">
                                                <div className="banner-bg__text-1">
                                                    <span className="u-c-white">{shop?.shopName}</span>
                                                </div>

                                                <Link className="banner-bg__shop-now btn--e-secondary" to="/categories">{t('Categories')}</Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {filterProductByDate(products,2)?.length > 0 ?
                            <div className="u-s-p-y-60">


                                <div className="section__intro u-s-m-b-46">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section__text-wrap">
                                                    <h1 className="section__heading u-c-secondary u-s-m-b-12">{t('NewArrivals')}</h1>

                                                    <span className="section__span u-c-silver">{t("FindHereOurNewArrival")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <div className="section__content">
                                    <div className="container">
                                        <div className="row">
                                            {
                                                filterProductByDate(products,2)?.length > 0 ?
                                                    <>
                                                        {
                                                            products?.slice(0,12).map((product,k) =><div  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 u-s-m-b-30" key={k}>
                                                                <TrialCard item={product} galerie={galeries} /></div>)
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div> : null}


                    </div>

                    : null
            }
        </>
    );
}

export default withTranslation()(TrialHome)
