import React from 'react';
import {withTranslation} from "react-i18next";
import './customInputs.css'

const CustomRadioInput = ({item,onClick,options,t}) => {



    return (
        <div className="radio-button">
            <label className="custom-radio">
                <input type="radio"  defaultChecked={item.selected_default? item.selected_default : false} disabled={item.selected_default} readOnly={item.selected_default} checked={options.filter(e => e.id == item.id).length} onClick={onClick}/>
                 <div className="radio-btn">
                     <i className="ri-check-fill"></i>
                     <div className="radio-body-content">
                         {
                             item.icon ? <i className={item.icon}></i> : null
                         }
                         <h3>{t(item.name)}</h3>
                     </div>
                 </div>



            </label>
        </div>
    );
}

export default withTranslation()(CustomRadioInput);