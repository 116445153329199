import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import Authorization from "../../../../components/Authorization";
import {APPNAME, attachTokenToHeaders, compare_date, format_date, SERVER_URL} from "../../../../helpers/utils";
import Paginate from "../../../../components/Paginate/Paginate";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import {compose} from "redux";
import {connect} from "react-redux";
import CreateTicket from "./CreateTicket";
import {Link} from "react-router-dom";

const Assistance = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('ToAdmin')


    const [currentSupport,setCurrentSupport] = useState([]);
    const [supports,setSupports] = useState([])

    useEffect(() => {
        getAllSupport(auth.me?.id)
    },[auth])

    useEffect(() => {
        setCurrentSupport(supports?.slice(indexOfFirstPost, indexOfLastPost))
    },[supports])



    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [open,setOpen] = useState(false)


    const [loading, setLoading] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    const getAllSupport = async (user_id) => {
        await axios.get(SERVER_URL+"supports/"+user_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setSupports(response.data.tickets)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }



    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(supports?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const addSupport = () => {
        setOpen(true)
    }


    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentSupport(supports?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = supports.filter(support => {
                return support.name.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentSupport(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(supports?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentSupport.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('ToAdmin')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{t('Support')}</li>
                                <li className="breadcrumb-item active">{t('ToAdmin')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>


            <Authorization single={true}  module="supportToCustomer" component={true} >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card" id="tasksList">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Supports')}</h5>
                                    <Authorization single={true}  module="support"   type="create" >
                                        <div className="flex-shrink-0">
                                            <button className="btn btn-success add-btn" onClick={event => addSupport()}>
                                                <i className="ri-add-line align-bottom me-1"></i> {t('Ticket')}
                                            </button>
                                        </div>
                                    </Authorization>
                                </div>
                            </div>
                            <div className="card-body border border-dashed border-end-0 border-start-0">
                                <div>
                                    <div className="row g-3">

                                        <div className="offset-lg-8 col-lg-4">
                                            <div className="search-box">
                                                <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-card mb-4">
                                    <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                        <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col" style={{width: "50px"}}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                           name="selectAll"
                                                           id="selectAll"
                                                           onChange={handleSelectAll}
                                                           checked={isCheckAll}
                                                    />
                                                </div>
                                            </th>
                                            <th className="sort">{t('Subject')}</th>
                                            <th className="sort">{t('Message')}</th>
                                            <th className="sort">{t('CreatedAt')}</th>
                                            <th className="sort">Status</th>
                                            <th className="sort">{t('Discussion')}</th>
                                        </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                        {
                                            loading ?
                                                <tr>
                                                    <td colSpan="7">
                                                        <p className="text-center">
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                        </p>
                                                    </td>
                                                </tr>

                                                :

                                                <>
                                                    {
                                                        currentSupport?.length > 0
                                                            ?
                                                            <>
                                                                {
                                                                    currentSupport?.map((support,key)=>
                                                                        <tr key={key}>
                                                                            <th scope="row"><div className="form-check">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       name={support.category}
                                                                                       id={support.id}
                                                                                       onChange={handleClick}
                                                                                       checked={isCheck.includes(support.id)}
                                                                                />
                                                                            </div>
                                                                            </th>
                                                                            <td>{support.title}</td>
                                                                            <td>{support.description.substring(0,75)}...</td>
                                                                            <td className="create_date">{format_date(support?.created_at)}</td>
                                                                            <td className="status">
                                                                                {support?.statut == 1 ?  <span className="badge badge-soft-info text-uppercase">{t('New')}</span> : null}
                                                                                {support?.statut == 2 ?  <span className="badge badge-soft-success text-uppercase">{t('Open')}</span> : null}
                                                                                {support?.statut == 3 ?  <span className="badge badge-soft-warning text-uppercase">{t('Inprogress')}</span> : null}
                                                                                {support?.statut == 4 ?  <span className="badge badge-soft-dangertext-uppercase">{t('Closed')}</span> : null}

                                                                            </td>

                                                                            <td>
                                                                                <Link to={`/store/support/${support.hash_key}`}>{t('Open')}</Link>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan="7">
                                                                    <p className="text-center">{t('EmptyList')}</p>
                                                                </td>
                                                            </tr>
                                                    }
                                                </>
                                        }

                                        </tbody>
                                    </table>
                                </div>

                                <Paginate
                                    postsPerPage={postsPerPage}
                                    totalPosts={supports?.length}
                                    paginate={paginate}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    current={currentPage}
                                />
                            </div>

                        </div>

                    </div>

                </div>
            </Authorization>

            <CreateTicket
                open={open}
                setOpen={setOpen}
                created={getAllSupport}
                auth={auth}
                />
        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Assistance));
