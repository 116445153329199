import React, { useEffect, useRef, useState } from 'react';
import CustomMap from "../../../../../components/CustomMap/CustomMap";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { checkCurrentUrlSubdomain, numberWithCommas, SERVER_URL } from '../../../../../helpers/utils'
import { toast } from "react-toastify";
import { getDistance, convertDistance } from 'geolib';

const _Map = ({ t, saveOrder, checkoutData, setStep, setCheckoutData }) => {

    const ulpar = window.location.search;
    const urlParams = new URLSearchParams(ulpar);
    const [loading, setLoading] = useState(false);
    const [distance, setDistance] = useState(0);
    const [positions, setPositions] = useState([]);
    const [shop, setShop] = useState(null);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        const checkSubdomain = async () => {
            const content = checkCurrentUrlSubdomain()
            await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    setShop(response.data.shop)
                }
            })
        };

        const setDefaultPosition = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;
                    setPositions([latitude, longitude]);
                    // Calculate initial distance and shipping based on the user's current position
                    if (shop?.lat_long) {
                        const shopPosition = JSON.parse(shop.lat_long);
                        const distanceMeters = getDistance(
                            { latitude: shopPosition[0], longitude: shopPosition[1] },
                            { latitude: latitude, longitude: longitude }
                        );
                        const distanceKm = Math.round(convertDistance(distanceMeters, "km"));
                        setDistance(distanceKm);
                        calculateShipping(distanceKm);
                    }
                }, (error) => {
                    console.error('Error getting user position:', error);
                });
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };

        checkSubdomain();
        setDefaultPosition();
    }, [shop?.lat_long]);

    const getPosition = (positions) => {
        if (positions.length && shop?.lat_long) {
            const shopPosition = JSON.parse(shop.lat_long);
            const distanceMeters = getDistance(
                { latitude: shopPosition[0], longitude: shopPosition[1] },
                { latitude: positions[0], longitude: positions[1] }
            );
            const distanceKm = Math.round(convertDistance(distanceMeters, "km"));
            setDistance(distanceKm);
            setPositions(positions);
            calculateShipping(distanceKm);
        }
    };

    const calculateShipping = (distanceKm) => {
        const shippingType = urlParams.get('shipping_type');
        const updatedCheckoutData = { ...checkoutData, delivery_latlng: JSON.stringify(positions) };

        if (shippingType === "ByKm") {
            updatedCheckoutData.shipping_charge = checkoutData.shipping_data * distanceKm;
        } else if (shippingType === "FixedAmount") {
            updatedCheckoutData.shipping_charge = checkoutData.shipping_data;
        } else if (shippingType === "FreeDelivery") {
            updatedCheckoutData.shipping_charge = 0;
        }

        setChanged(true);
        setCheckoutData(updatedCheckoutData);
    };

    const validatePosition = () => {
        setLoading(true);
        setCheckoutData(prevData => ({
            ...prevData,
            total_amount: prevData.total_amount + prevData.shipping_charge
        }));

        if (positions.length) {
            if (["CASHONDELIVERY-payment", "CHEQUERECEIVERY-payment", "DIRECTBANK-payment"].includes(checkoutData.payment_method)) {
                saveOrder(checkoutData);
            } else {
                setStep(3);
            }
        } else {
            toast.error(t('ChoosedYourPosition'));
        }
    };

    return (
        <div>
            <section className="tp-checkout-area pb-120" style={{ background: "#EFF1F5" }}>
                <div className="container">
                    <div className="fixed_map_recap">
                        <span><b>{t('Unite')}</b>: {urlParams.get('shipping_type') === "FreeDelivery" ? 0 : checkoutData.shipping_data} <sub>Francs</sub></span> <br />
                        <hr />
                        <span><b>{t('Distance')}</b>: {distance} <sub>Km</sub></span>
                        <hr />
                        <span><b>{t('ShippingCharge')}</b>: {numberWithCommas(checkoutData.shipping_charge)} <sub>Francs</sub></span> <br />
                        <hr />
                        <small>{changed && distance === 0 ? t('CloseToShop') : ""}</small>
                    </div>

                    <CustomMap editable={true} zoom={15} description={t('Yourposition')} getPosition={getPosition} defaultCenter={positions} />

                    <div className="row">
                        <div className="offset-lg-4 col-lg-4 mt-20">
                            <button
                                onClick={validatePosition}
                                disabled={loading}
                                className="tp-checkout-btn w-100"
                                style={{ background: positions.length ? shop?.color : "#AAA" }}
                            >
                                {loading ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        {t('Loading')}...
                                    </>
                                ) : (
                                    <span>{t('Next')}</span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default withTranslation()(_Map);
