import React from 'react';

const Shop = ({t}) => {
    return (
        <>
            <h1 className="title">{t('ShopBuilder')}</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A cumque ea, laudantium optio possimus quae veritatis. Accusantium animi aspernatur assumenda, hic in itaque iure laborum odit porro repellat repellendus ullam!
            </p>
            <br/>
            <h3>{t('Category')}</h3>
            <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut blanditiis consectetur
                consequatur delectus eligendi fugit ipsum magnam maiores necessitatibus nisi obcaecati pariatur
                quae quam sint tempora, ut voluptatem! Ad, vero?
            </div>
            <div>Consectetur corporis explicabo ipsum iusto laborum perferendis sed unde voluptas? Iusto,
                nulla, quae. Delectus dolorem doloremque eveniet explicabo illum inventore ipsum iste itaque
                maxime, nemo quae quaerat, quas, similique! Eligendi.
            </div>
            <div>Alias animi aspernatur eius, expedita quia quis veritatis! Architecto autem esse impedit minus
                mollitia nam perferendis perspiciatis qui quo sed. Ab ad consectetur dolore ea harum id
                mollitia, obcaecati quo?
            </div>
            <div>Ad assumenda beatae cupiditate delectus distinctio dolorem dolores doloribus est et fuga illo
                impedit libero maiores molestiae officia placeat quia quibusdam quidem, rem repellat sit
                tempore, unde! Exercitationem, provident, sint!
            </div>
            <div>A commodi corporis dolor doloremque eveniet ipsa labore necessitatibus nisi, nulla placeat
                provident, ratione saepe voluptatum? Aliquam cupiditate earum error est inventore ipsa, itaque
                laboriosam molestiae, perferendis praesentium, rerum tempora?
            </div>
            <div>A corporis dolore doloribus, illum inventore ipsum minima natus unde. Et incidunt ipsa
                mollitia neque quaerat quisquam quo sapiente sed unde? Architecto at autem eum hic labore
                possimus quia velit.
            </div>
            <div>Animi corporis deserunt eum id incidunt labore neque nobis officiis optio quae qui quisquam
                rem repellat reprehenderit, tempore ut voluptatum? Alias eius et in ipsam molestias perferendis
                tenetur vel voluptate.
            </div>
            <div>Amet assumenda eaque error excepturi expedita magni modi neque nihil nobis provident quisquam
                quo ratione sequi suscipit, tempore. Harum illum iusto nam pariatur sed! Beatae doloribus ea
                eaque expedita repellendus!
            </div>
            <div>Ad animi asperiores commodi ducimus earum eligendi et exercitationem hic ipsam itaque maxime
                nam nobis nostrum omnis placeat praesentium provident, quaerat quam recusandae repellendus sed
                similique tempora unde voluptatibus voluptatum.
            </div>
            <div>Corporis cupiditate facilis illo inventore nam nobis omnis, optio perspiciatis tempore
                temporibus, vel voluptas voluptatem? Ab adipisci beatae dignissimos est et explicabo impedit
                incidunt, non, odit quia quo repudiandae voluptas.
            </div>
            <div>Ab aperiam consequuntur cum earum enim eos explicabo hic illo ipsa iure laborum laudantium
                magnam magni nam nemo nisi non officiis placeat possimus quam quibusdam, repellendus sunt, vero
                voluptate voluptates?
            </div>
            <div>Ab ad aliquam animi architecto consequuntur corporis delectus doloremque dolores eos error,
                facilis id iste laboriosam libero nemo non nostrum optio praesentium, quia quidem quod
                recusandae rem repellat sint veritatis.
            </div>
            <div>Dolore eligendi enim est ipsa itaque officia quidem sapiente. Ad cum dolor, ea eius est
                eveniet excepturi illo inventore, libero magni molestias necessitatibus, numquam officiis
                possimus quaerat similique ullam ut!
            </div>
            <div>A cum labore laudantium nemo, quasi quisquam repudiandae suscipit tempora! Aliquid corporis
                dolor, dolorem dolores doloribus eos excepturi mollitia neque nulla, numquam quas sit suscipit
                tempore. Dolorum error iste suscipit?
            </div>
            <div>A, delectus dolorem ducimus eos est ex id in ipsum laboriosam molestiae nulla, placeat quam,
                ratione rerum sed sequi voluptate! Assumenda delectus eum fugit impedit nam nisi quidem
                quisquam velit!
            </div>
            <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ad aspernatur assumenda delectus
                doloremque ea, eos harum id impedit incidunt maxime, necessitatibus nesciunt obcaecati,
                perferendis quas ratione repellendus sunt tenetur?
            </div>
            <div>Accusantium adipisci alias amet blanditiis consequatur cum cumque dolorem ea enim et
                exercitationem facilis, hic inventore magni molestiae nam nihil nulla perspiciatis placeat
                possimus quo ratione sequi suscipit ullam veritatis?
            </div>
            <div>Qui quos saepe ullam voluptas! Aperiam cupiditate dolor et impedit in modi vero. A aspernatur
                eveniet minus nisi non. Asperiores eius impedit in minima modi nesciunt quasi rem rerum
                voluptatibus?
            </div>
            <div>Blanditiis, cumque harum laborum minus possimus sapiente similique voluptatibus? Accusamus
                asperiores, consequuntur explicabo ipsa officiis tempore velit veritatis voluptatum. Adipisci
                autem error iste maiores molestiae quae quam saepe sint suscipit.
            </div>
            <div>Asperiores beatae dolorem, ea eligendi mollitia neque perferendis quae totam! Architecto at
                atque distinctio dolor dolore dolorem eum ex, exercitationem, molestias mollitia nam, omnis
                pariatur qui quo similique ut voluptate!
            </div>
            <div>Accusamus animi natus porro quas veritatis! Aliquam animi assumenda blanditiis, consectetur
                cum cupiditate debitis delectus deleniti eligendi et eum illum ipsa maiores necessitatibus
                numquam possimus quisquam quo recusandae reiciendis veniam!
            </div>
            <div>Ab atque aut consectetur culpa, doloribus ea enim esse, est excepturi expedita incidunt minus
                molestiae molestias obcaecati, quaerat rem repellat repellendus repudiandae saepe sequi
                similique tempora veritatis voluptas voluptates voluptatibus!
            </div>
            <div>Architecto doloribus eaque id incidunt laborum, maxime nihil numquam officiis quis quo quos
                recusandae, rerum veniam? Culpa cumque esse nam recusandae veniam? Consectetur debitis ex
                libero, nesciunt quis sunt unde.
            </div>
            <div>Aliquid dicta doloribus dolorum ea eaque, enim error fuga ipsam magni officiis omnis placeat
                rerum tempora ut voluptatibus? Adipisci aliquam doloremque exercitationem expedita illum minima
                numquam qui quos ullam vel.
            </div>
            <div>Ab atque cum debitis distinctio eos ex explicabo facilis fugit hic libero molestiae molestias
                nihil officia, pariatur porro quaerat repellendus reprehenderit unde vel velit veniam voluptas
                voluptate! Beatae, dolorem, eligendi.
            </div>
            <div>Accusamus alias amet asperiores aspernatur atque debitis deserunt dignissimos dolores esse
                exercitationem harum inventore ipsum labore minima minus molestias optio quaerat quam, quod
                rem, reprehenderit saepe sint sunt unde voluptatibus!
            </div>
            <div>A, aspernatur cumque debitis earum, error incidunt iusto labore nostrum officiis omnis
                pariatur ratione sed temporibus vitae voluptatum! Dicta, perferendis quos! Iste, itaque,
                pariatur. Ad exercitationem in rem veniam veritatis!
            </div>
            <div>Accusamus amet architecto atque culpa ducimus et maiores nemo, nisi non numquam odio, quae
                quasi qui quisquam ratione rem repellendus reprehenderit, sunt tempore voluptate. Corporis
                debitis fugiat ipsum repellendus voluptas?
            </div>
            <div>Cum dignissimos ducimus fuga quam saepe. Accusantium amet animi atque, blanditiis cum
                cupiditate eaque excepturi harum laudantium maiores nisi perferendis perspiciatis provident,
                quisquam ratione sunt temporibus vel vitae voluptate voluptatem!
            </div>
            <div>Consequatur debitis dicta est eveniet facilis ipsa iste laudantium, nesciunt, omnis quam quod
                recusandae sit tempore totam, velit veritatis voluptates! Cum dolore est inventore ipsam iusto
                numquam quidem vel voluptatum.
            </div>
            <div>Assumenda, cumque doloremque ea eius enim eum inventore laudantium magnam magni molestias
                officia perferendis porro praesentium quae quam quibusdam sunt ullam veritatis voluptas
                voluptatibus. Eos error nesciunt nobis possimus sit?
            </div>
            <div>Amet dolore ex facere porro quam quidem quis rem? Aliquid cumque, ea facilis incidunt iusto
                molestiae neque nostrum quasi quisquam quos vero voluptate, voluptatum. Debitis eaque libero
                molestiae necessitatibus quod!
            </div>
            <div>Accusantium aliquam aliquid asperiores aspernatur atque aut corporis cumque delectus
                dignissimos ducimus, error harum illum ipsam ipsum iste itaque labore molestias nisi officiis
                omnis quae quas quod sit sunt vitae!
            </div>
            <div>Accusamus, aliquid asperiores debitis distinctio dolorem exercitationem expedita id illum
                laudantium molestiae quas soluta tenetur unde. Accusamus architecto atque distinctio, dolore
                doloribus fugiat illo illum, laudantium modi molestiae placeat sit?
            </div>
            <div>Aut distinctio enim est, iste nam quidem quod rem sit vitae voluptatem! Assumenda dolor in
                necessitatibus nemo possimus quos velit! Beatae eveniet ex facilis, fugiat magni nihil non
                omnis reiciendis?
            </div>
        </>
    );
}

export default Shop;