import React, {useEffect, useState} from 'react';
import axios from "axios";
import {format_date, format_date_complet, numberWithCommas, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Orders = ({auth,t,generateInvoice}) => {

    const [orders, setOrders] = useState([]);


    useEffect(() => {
        getUserOrders()
    }, [auth]);



    const getUserOrders = async () => {
        await axios.get(SERVER_URL+"shop/stats/"+auth?.user?.id,).then((response)=>{
            if (response.data.succeed){
                setOrders(response.data.orders)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }



    return (
        <div className="profile__ticket table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th>{t('Order')} #</th>
                    <th>{t('PlacedOn')}</th>
                    <th>{t('Product')}</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th scope="col">Detail</th>
                </tr>
                </thead>
                <tbody>
                {
                    orders?.length > 0 ?

                        <>
                            {
                                orders.map((order, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><b>#{order.order_id}</b></td>
                                            <td>{format_date(order.created_at)}</td>
                                            <td>{JSON.parse(order?.cart_content).map((item,k) => <b key={k} className="badge badge-outline-primary" style={{margin : "1px"}}>{item.name}</b>)}</td>
                                            <td>{numberWithCommas(order.total_amount)} Frcs</td>
                                            <td className="status">
                                                {order.delivery_status == 1 ? <span className="badge badge-primary text-uppercase">{t("Pending")}</span> : null}
                                                {order.delivery_status == 2 ? <span className="badge badge-info text-uppercase">{t("Packed")}</span> : null}
                                                {order.delivery_status == 3 ? <span className="badge badge-info text-uppercase">{t("OnTheWay")}</span> : null}
                                                {order.delivery_status == 4 ? <span className="badge badge-success text-uppercase">{t("Delivered")}</span> : null}
                                                {order.delivery_status == 5 ? <span className="badge badge-warning text-uppercase">{t("Return")}</span> : null}
                                                {order.delivery_status == 6 ? <span className="badge badge-danger text-uppercase">{t("Cancel")}</span> : null}

                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    {order.delivery_status == 4 ?
                                                        <Link to={"/order/invoice/"+order.order_id} className="tp-logout-btn">{t('Invoice')}</Link>
                                                        : ""}

                                                    {order.shipping_status && order.delivery_status != 4 ?
                                                        <Link to={"/order/track/"+order.order_id} className="tp-btn-btn d-flex  ml-10">
                                                           <span className="mr-10">
                                                           <svg width="14" height="14" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                             <path d="M7.99377 10.1461C9.39262 10.1461 10.5266 9.0283 10.5266 7.64946C10.5266 6.27061 9.39262 5.15283 7.99377 5.15283C6.59493 5.15283 5.46094 6.27061 5.46094 7.64946C5.46094 9.0283 6.59493 10.1461 7.99377 10.1461Z" stroke="currentColor" strokeWidth="1.5"/>
                                                             <path d="M1.19707 6.1933C2.79633 -0.736432 13.2118 -0.72843 14.803 6.2013C15.7365 10.2663 13.1712 13.7072 10.9225 15.8357C9.29079 17.3881 6.70924 17.3881 5.06939 15.8357C2.8288 13.7072 0.263493 10.2583 1.19707 6.1933Z" stroke="currentColor" strokeWidth="1.5"/>
                                                          </svg>
                                                       </span> <span>{t('Track')}</span>
                                                        </Link> : ""}
                                                    <Link to={"/order/detail/"+order.order_id} className="tp-logout-btn">{t('Detail')}</Link>
                                                </div>
                                            </td>
                                        </tr>)
                                })
                            }
                        </>
                        :
                        <tr>
                            <td colSpan={6}>
                                <p className="text-center">
                                    {t('EmptyList')}
                                </p>
                            </td>
                        </tr>
                }
                </tbody>
            </table>
        </div>
    );
};

export default withTranslation()(Orders)
