import {
    CATEGORIES_SHOP_LOADING,
    CATEGORIES_SHOP_SUCCESS,
    CATEGORIES_SHOP_FAIL,
    SUBCATEGORIES_SHOP_LOADING,
    SUBCATEGORIES_SHOP_SUCCESS,
    SUBCATEGORIES_SHOP_FAIL,
    PRODUCTS_SHOP_LOADING,
    PRODUCTS_SHOP_SUCCESS,
    PRODUCTS_SHOP_FAIL
} from './type';

const initialState = {
    isLoading : true,
    subcategories : [],
    categories : [],
    products : [],
    galerie : [],
    error: null,
};


 const  CategorieReducer =  (state = initialState, { type, payload }) => {


    switch (type) {
        case CATEGORIES_SHOP_LOADING:
        case SUBCATEGORIES_SHOP_LOADING:
        case PRODUCTS_SHOP_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case CATEGORIES_SHOP_SUCCESS:
            return {
                ...state,
                categories: payload.categories,
                isLoading: false,
            };

        case PRODUCTS_SHOP_SUCCESS:
            return {
                ...state,
                products: payload.products,
                categories: payload.categories,
                subcategories: payload.subcategories,
                galerie: payload.galerie,
                isLoading: false,
            };


        case SUBCATEGORIES_SHOP_SUCCESS:
            return {
                ...state,
                subcategories: payload.subcategories,
                categories: payload.categories,
                isLoading: false,
            };


        case CATEGORIES_SHOP_FAIL:
        case SUBCATEGORIES_SHOP_FAIL:
        case PRODUCTS_SHOP_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error,
            };

        default:
            return state;
    }
}

export default CategorieReducer