import React, {useEffect, useRef, useState} from 'react';
import {CLIENT, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import axios from "axios";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Category2 = ({block,t,toggleHightLight,isEditor,customStyle,shop_data}) => {

    const [categories, setCategories] = useState([])
    const [slice, setSlice] = useState(3)
    const [subcategories, setSubcategories] = useState([])

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const category2Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :category2Ref?.current?.offsetTop, left : category2Ref?.current?.offsetLeft, width : category2Ref?.current?.offsetWidth, height : category2Ref?.current?.offsetHeight,nodeName : category2Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    useEffect(() => {
        if(shop_data){
            getCategories(shop_data.id)
        }
    }, [shop_data]);


    const getCategories = async (id) => {
        await axios.get(SERVER_URL+"public/categories/"+id).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.categories)
                setSubcategories(response.data.subcategories)
            }
        })
    }




    return (
        <section className="tp-category-area pt-60 pb-15"
                 ref={category2Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
                 style={customStyle}
        >
            <div className="container">

                {
                    isEditor ?
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="tp-category-main-box mb-25 p-relative fix" style={{background : "#F3F5F7"}}>
                                    <div className="tp-category-main-thumb include-bg transition-3" style={{background :  `url('${CLIENT}/builder/category/main/category-main-1.jpg')`}}></div>
                                    <div className="tp-category-main-content">
                                        <h3 className="tp-category-main-title">
                                            <a href="shop.html">Headphones</a>
                                        </h3>
                                        <span className="tp-category-main-item">23 Products</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="tp-category-main-box mb-25 p-relative fix" style={{background : "#F3F5F7"}}>
                                    <div className="tp-category-main-thumb include-bg transition-3" style={{background :  `url('${CLIENT}/builder/category/main/category-main-2.jpg')`}}></div>
                                    <div className="tp-category-main-content">
                                        <h3 className="tp-category-main-title">
                                            <a href="shop.html">Smart Watches</a>
                                        </h3>
                                        <span className="tp-category-main-item">07 Products</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="tp-category-main-box mb-25 p-relative fix" style={{background : "#F3F5F7"}}>
                                    <div className="tp-category-main-thumb include-bg transition-3" style={{background :  `url('${CLIENT}/builder/category/main/category-main-3.jpg')`}}></div>
                                    <div className="tp-category-main-content">
                                        <h3 className="tp-category-main-title">
                                            <a href="shop.html">Laptops</a>
                                        </h3>
                                        <span className="tp-category-main-item">14 Products</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        :

                        <div className="row">
                            {
                                categories?.length > 0 ?
                                    <>
                                    {
                                        categories?.slice(0,slice).map((category,key) =>
                                        <div className="col-lg-4 col-sm-6" key={key}>
                                            <div className="tp-category-main-box mb-25 p-relative fix" style={{background : "#F3F5F7"}}>
                                                <div className="tp-category-main-thumb include-bg transition-3" style={{background :  `url('${category.image ? SERVER_IMG_URL+shop_data.shop_key+"/categories/thumb/"+category.image : CLIENT+"/builder/cplaceholder.png"}') no-repeat`,backgroundPosition : "center", backgroundSize : "contain", width : "200px", marginLeft : "100px"}}></div>
                                                <div className="tp-category-main-content">
                                                    <h3 className="tp-category-main-title">
                                                        <Link  to={"/shop/"+category.slug} className={category?.image ? "" : "text-black"} >{category.category}</Link>
                                                    </h3>
                                                    <span className={category?.image ? "tp-category-main-item" : "tp-category-main-item text-black"}>{category.count??0} {t('Products')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    }
                                    </>
                                    :
                                    <h1>{t('EmptyList')}</h1>
                            }

                        </div>

                }

                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-category-main-result text-center mb-35 mt-35">
                            <p>{t('Showing')} {isEditor ? 3 : slice} {t('Of')} {isEditor ? 3 :categories?.length} {t('Categories').toLowerCase()}</p>
                            <div className="tp-category-main-result-bar">
                                <span data-width="40%"></span>
                            </div>
                        </div>
                        {
                            slice < categories.length ?
                                <div className="tp-category-main-more text-center cursor-pointer">
                            <span onClick={event => setSlice(slice + 3)} className="tp-load-more-btn">
                              {t('LoadMore')}
                            </span>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withTranslation()(Category2)
