import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminLayout from '../../../../layouts/admin/AdminLayout'
import {APPNAME, attachTokenToHeaders, numberWithCommas, SERVER_URL, sha1_random} from '../../../../helpers/utils'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Paginate from "../../../../components/Paginate/Paginate";

const List = ({t}) => {

    const [loading, setLoading] = useState(true);
    const [lists, setLists] = useState([]);

    const params = window.location.pathname.split('/').pop()


    useEffect(() => {
        getPageOfTemplate()
    }, [])

    const getPageOfTemplate = async () => {
        await axios.get(SERVER_URL+"admin/template/"+params,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setLists(response.data.pages)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Pages')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item">{t('Template')}</li>
                                <li className="breadcrumb-item active">{t('Pages')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            {/*{sha1_random(10)} <br/>*/}



            <div className="row">
                <div className="card">
                    <div className="card-header border-0">
                        <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Pages')}</h5>
                        </div>
                    </div>
                    <div className="card-body border border-dashed border-end-0 border-start-0">
                        <div className="table-card mb-4">
                            <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                <thead className="table-light text-muted">
                                <tr>
                                    <th className="sort">{t('Name')}</th>
                                    <th className="sort">{t('Link')}</th>
                                    <th className="sort">Status</th>
                                    <th className="sort" style={{width : "15%"}}>{t('Action')}</th>
                                </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                {
                                    loading ?
                                        <tr>
                                            <td colSpan="4">
                                                <p className="text-center">
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                </p>
                                            </td>
                                        </tr>

                                        :

                                        <>
                                            {
                                                lists?.length > 0
                                                    ?
                                                    <>
                                                        {
                                                            lists?.map((page,key)=>
                                                                <tr key={key}>
                                                                    <td>{page.name}</td>
                                                                    <td><i className="text-primary">{page.pathname}</i>   </td>

                                                                    <td>

                                                                        {  page.statut == 1 ?
                                                                            <b className="badge badge-soft-success">{t('Available')}</b>
                                                                            :
                                                                            <b className="badge badge-soft-warning">{t('Unavailable')}</b>
                                                                        }

                                                                    </td>
                                                                    <td className="priority">
                                                                        <ul className="list-inline  mb-0">
                                                                            <li className="list-inline-item">
                                                                                <Link className="edit-item-btn" to={`/admin/template/builder/${page.hash_key}`}>
                                                                                    <small>Page builder</small>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan="4">
                                                            <p className="text-center">{t('EmptyList')}</p>
                                                        </td>
                                                    </tr>
                                            }
                                        </>
                                }

                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

            </div>


        </AdminLayout>
    )
}

export default withTranslation()(List)
