import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import Paginate from "../../../../components/Paginate/Paginate";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import CreateOrUpdate from "./CreateOrUpdate";


const AdminPermissions = ({t,auth}) => {


    document.title = APPNAME+" :: "+t('Permissions')

    const [currentPermission,setCurrentpermission] = useState([]);
    const [permissions,setPermissions] = useState([])

    useEffect(() => {
        getAllPermissions()
    },[])

    useEffect(() => {
        setCurrentpermission(permissions?.slice(indexOfFirstPost, indexOfLastPost))
    },[permissions])



    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])
    const [modules,setModules] = useState([])

    const [loading, setLoading] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    const getAllPermissions = async () => {
        await axios.get(SERVER_URL+"admin/permissions",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPermissions(response.data.permissions)
                setModules(response.data.modules)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(permissions?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const addPermission = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editPermission = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }




    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeletePermission'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}/admin/permission/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed == true) {
                        toast.success(t(response.data.message))
                        getAllPermissions()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }





    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledPermission') : t('SureToEnabledPermission'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/permission/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllPermissions()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentpermission(permissions?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = permissions.filter(coup => {
                return coup.event.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentpermission(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(permissions?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentPermission.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListPermission'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}admin/permission/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getAllPermissions()
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Permissions')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{t('Users')}</li>
                                <li className="breadcrumb-item active">{t('Permissions')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>


            <div className="col-lg-12">
                        <div className="card" id="tasksList">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Permissions')}</h5>

                                    <div className="flex-shrink-0">
                                        <button className="btn btn-primary add-btn" onClick={event => addPermission()}>
                                            <i className="ri-add-line align-bottom me-1"></i> {t('Add')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border border-dashed border-end-0 border-start-0">
                                <div>
                                    <div className="row g-3">
                                        <div className="col-lg-4">
                                            {
                                                isCheck.length > 0 &&

                                                <>
                                                    {
                                                        Gloading ?
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                            :
                                                            <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="offset-lg-4 col-lg-4">
                                            <div className="search-box">
                                                <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-card mb-4">
                                    <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                        <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col" style={{width: "50px"}}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                           name="selectAll"
                                                           id="selectAll"
                                                           onChange={handleSelectAll}
                                                           checked={isCheckAll}
                                                    />
                                                </div>
                                            </th>
                                            <th className="sort">{t('Name')}</th>
                                            <th className="sort">Permissions</th>
                                            <th className="sort">Status</th>
                                            <th className="sort">{t('Action')}</th>
                                        </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                        {
                                            loading ?
                                                <tr>
                                                    <td colSpan="5">
                                                        <p className="text-center">
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                        </p>
                                                    </td>
                                                </tr>

                                                :

                                                <>
                                                    {
                                                        currentPermission?.length > 0
                                                            ?
                                                            <>
                                                                {
                                                                    currentPermission?.map((permission,key)=>
                                                                        <tr key={key}>
                                                                            <th scope="row"><div className="form-check">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       name={permission.name}
                                                                                       id={permission.id}
                                                                                       onChange={handleClick}
                                                                                       checked={isCheck.includes(permission.id)}
                                                                                />
                                                                            </div>
                                                                            </th>
                                                                            <td>{permission.name}</td>
                                                                            <td>

                                                                                {
                                                                                    JSON.parse(permission.content).map((content) => {
                                                                                        if (content.permissions.show){
                                                                                           return  <b className="badge badge-success ml-1">{t(content.name)}</b>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            <td className="status">
                                                                                {permission.statut == 1
                                                                                    ? <span className="badge badge-soft-success">{t('Active')}</span>
                                                                                    :  <span className="badge badge-soft-danger">{t('Block')}</span>
                                                                                }
                                                                            </td>
                                                                            <td className="priority">
                                                                                <ul className="list-inline  mb-0">
                                                                                    <li className="list-inline-item">
                                                                                        {permission.statut == 1
                                                                                            ?    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(permission.id,2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                            :

                                                                                            <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(permission.id,1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                        }
                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => deleteModal(permission.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan="5">
                                                                    <p className="text-center">{t('EmptyList')}</p>
                                                                </td>
                                                            </tr>
                                                    }
                                                </>
                                        }

                                        </tbody>
                                    </table>
                                </div>

                                <Paginate
                                    postsPerPage={postsPerPage}
                                    totalPosts={permissions?.length}
                                    paginate={paginate}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    current={currentPage}
                                />
                            </div>

                        </div>

                    </div>

            <CreateOrUpdate open={open} setOpen={setOpen} created={getAllPermissions} isEdit={isEdit} module={modules} updateData={updateData} auth={auth} />
        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(AdminPermissions));
