import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, database_date, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,auth}) => {

    const [loading,setLoading] = useState(false)


    const validationSchema = Yup.object().shape({
        code: Yup.string().required(t('InputRequired')),
        event: Yup.string().required(t('InputRequired')),
        availability: Yup.string().required(t('InputRequired')),
        type: Yup.string().required(t('InputRequired')),
        amount: Yup.string().required(t('InputRequired')),
        start_at: Yup.string().required(t('InputRequired')),
        ended_at: Yup.string().required(t('InputRequired')),
        number_pers: Yup.string().required(t('InputRequired'))
    });

    const initialValues = {
        code: isEdit ? updateData.code : '',
        event: isEdit ? updateData.event : '',
        availability: isEdit ? updateData.availability : '',
        type: isEdit ? updateData.type : '',
        amount: isEdit ? updateData.amount : '',
        start_at: isEdit ? database_date(updateData.start_at) : '',
        ended_at: isEdit ? database_date(updateData.ended_at) : '',
        number_pers: isEdit ? updateData.number_pers : '',
    }


    const handleSubmit = async (values) => {
        setLoading(true)
        const shop_id = auth.shop.id

        values.shop_id  = shop_id


        if (isEdit){
            await axios.post(SERVER_URL+"coupon/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"coupon/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    return (
      <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Coupon')}</> : <>{t('Edit')} {t('Coupon')}</>} size="lg">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ errors, touched }) =>
                  <Form>
                 <div className="row">
                     <div className="col-lg-6">
                         <label htmlFor="event" className="form-label">{t('Event')} <b className="text-danger">*</b></label>
                         <Field  className="front" id="category" name="event"/>
                         {errors.event && touched.event ? (
                             <div className='text-danger'>{errors.event}</div>
                         ) : null}
                     </div>
                     <div className="col-lg-6">
                         <label htmlFor="code" className="form-label">{t('Code')} <b className="text-danger">*</b></label>
                         <Field  className="front" id="code" name="code"/>
                         {errors.code && touched.code ? (
                             <div className='text-danger'>{errors.code}</div>
                         ) : null}
                     </div>

                     <div  className="col-lg-6">
                         <br/>
                         <label htmlFor="type" className="form-label">{t('Type')} <b className="text-danger">*</b></label>
                         <Field as="select" className="form-select" id="type" name="type">
                             <option value="">-</option>
                             <option value="1">{t('Amount')}</option>
                             <option value="2">{t('Percent')}</option>
                         </Field>
                         {errors.type && touched.type ? (
                             <div className='text-danger'>{errors.type}</div>
                         ) : null}
                     </div>
                     <div  className="col-lg-6">
                         <br/>
                         <label htmlFor="amount" className="form-label"> {t('Percent')} / {t('Amount')} <b className="text-danger">*</b></label>
                         <Field  className="front" id="amount" name="amount"/>
                         {errors.amount && touched.amount ? (
                             <div className='text-danger'>{errors.amount}</div>
                         ) : null}
                     </div>
                     <div  className="col-lg-6">
                         <br/>
                         <label htmlFor="start_at" className="form-label">{t('StartAt')} <b className="text-danger">*</b></label>
                         <Field type="date"  className="front" id="start_at" name="start_at"/>
                         {errors.start_at && touched.start_at ? (
                             <div className='text-danger'>{errors.start_at}</div>
                         ) : null}
                     </div>
                     <div  className="col-lg-6">
                         <br/>
                         <label htmlFor="ended_at" className="form-label">{t('EndAt')} <b className="text-danger">*</b></label>
                         <Field  type="date" className="front" id="ended_at" name="ended_at"/>
                         {errors.ended_at && touched.ended_at ? (
                             <div className='text-danger'>{errors.ended_at}</div>
                         ) : null}
                     </div>
                     <div  className="col-lg-6">
                         <br/>
                         <label htmlFor="availability" className="form-label">{t('Number')} <b className="text-danger">*</b></label>
                         <Field  type="number" className="front" id="category_id" name="availability"/>
                         {errors.availability && touched.availability ? (
                             <div className='text-danger'>{errors.availability}</div>
                         ) : null}
                     </div>
                     <div  className="col-lg-6">
                         <br/>
                         <label htmlFor="number_pers" className="form-label">{t('Numberpers')} <b className="text-danger">*</b></label>
                         <Field type="number"  className="front" id="number_pers" name="number_pers"/>
                         {errors.number_pers && touched.number_pers ? (
                             <div className='text-danger'>{errors.number_pers}</div>
                         ) : null}
                     </div>


                 </div>
                     <div className="row">
                         <div className="col-lg-8 pt-3">
                             <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                         </div>
                         <div className="col-lg-4 pt-3">
                             <button className="btn btn-success btn-block" disabled={loading}>
                                 {
                                     loading ?
                                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                         :
                                         <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                 }
                             </button>
                         </div>
                     </div>
                  </Form>
              }

          </Formik>
      </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)