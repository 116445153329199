import React, { useState, useRef, useEffect } from 'react';
import { useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DraggableMenu = ({ children, containerRef }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [, drag] = useDrag(() => ({
        type: 'menu',
        item: { position },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const delta = monitor.getDifferenceFromInitialOffset();
            if (delta) {
                const newX = position.x + delta.x;
                const newY = position.y + delta.y;
                setPosition(getBoundedPosition(newX, newY));
            }
        },
    }));

    const getBoundedPosition = (x, y) => {
        const containerRect = containerRef.current.getBoundingClientRect();
        const menuWidth = 200; // Approx width of your menu
        const menuHeight = 100; // Approx height of your menu
        const boundedX = Math.min(Math.max(0, x), containerRect.width - menuWidth);
        const boundedY = Math.min(Math.max(0, y), containerRect.height - menuHeight);
        return { x: boundedX, y: boundedY };
    };

    useEffect(() => {
        const initialPosition = getBoundedPosition(0, 0);
        setPosition(initialPosition);
    }, [containerRef]);

    return (
        <div
            ref={drag}
            style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
                backgroundColor: 'white',
                padding: '10px',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                cursor: 'move',
                width: '200px', // Adjust as needed
                height: '100px', // Adjust as needed
            }}
        >
            {children}
        </div>
    );
};

const Container = () => {
    const containerRef = useRef(null);

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                ref={containerRef}
                style={{
                    position: 'relative',
                    width: "100%",
                    height: '500px',
                    border: '1px solid black',
                    overflow: 'hidden',
                }}
            >
                <DraggableMenu containerRef={containerRef}>
                    <p>This is a floating menu</p>
                    <button>Option 1</button>
                    <button>Option 2</button>
                </DraggableMenu>
            </div>
        </DndProvider>
    );
};

export default Container;
