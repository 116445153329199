import React from 'react';
import Draggable from "react-draggable";
import list from "../assets/icons/list.svg";
import swal from "@sweetalert/with-react";

const Navigator = ({elements,setElements,t,toggleNavigator}) => {




    const DeleteSection = (item_id) => {

        swal({
            title : t('Delete'),
            text : t('SureToDelete'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check)  {
                const ele = elements.filter(e => e.item.id !== item_id)
                setElements(ele)
            }
        });


    }


    return (
        <Draggable>
            <div id="tree-list" style={{
                width: '200px',
                height: '500px',
                background: 'white',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                border : '1px solid #ccc',
                borderRadius: '5px',
                position: 'absolute',
                top: '150px',
                right: '50px',
                zIndex: 9999
            }}>
                <div className="header d-flex justify-content-between"
                     style={{padding: "10px 10px 0 10px", cursor: "grab"}}>
                    <div>{t('Navigator')}</div>
                    <button className="btn btn-sm"  onClick={event => toggleNavigator()}>
                        <i className="bx bx-x"></i>
                    </button>
                </div>

                <div className="tree" style={{
                    height: '100%',
                    overflowY: 'auto'
                }}>
                    {elements?.length > 0 ? (
                        <ul className="list-group" >
                            {elements.map((el, key) => (
                                <li className="list-group-item py-1" key={key}>
                                    <label htmlFor={key} className="d-flex justify-content-between">
                                        <div>
                                            <img src={list} style={{width : "20px",height : "20px",marginRight : "10px"}} alt=""/>
                                            <small  className="fs-10">{el.item.name}</small>
                                        </div>
                                        <i className="bx bx-trash pt-2 text-danger cursor-pointer"
                                           onClick={() => DeleteSection(el.item.id)}></i>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    ) : ""}
                </div>
            </div>

        </Draggable>
    );
}

export default Navigator;