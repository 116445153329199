import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, Capitalize, format_date, SERVER_URL} from "../../../../helpers/utils";
import {Link} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";
import {getProducts} from "../../../../store/Categories/action";
import Paginate from "../../../../components/Paginate/Paginate";
import swal from "@sweetalert/with-react";
import Authorization from "../../../../components/Authorization";

const Orders = ({t,auth}) => {
    document.title = APPNAME+" :: "+t('Orders')

    const [loading,setLoading] = useState(true)
    const [orders,setOrders] = useState([])
    const [allOrders,setAllOrders] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [currentOrders,setCurrentOrders] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isMobile,setIsMobile] = useState(0)


    useEffect(()=>{
        getAllOrders(auth.shop?.id)
    },[auth])

    const getAllOrders = async (shop_id) => {
        await axios.get(SERVER_URL+"orders/"+shop_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setOrders(response.data.orders)
                setAllOrders(response.data.orders)

                setCurrentOrders(response.data.orders?.slice(indexOfFirstPost, indexOfLastPost))
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;



    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(orders?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const filterOrder = (status) =>{
        setIsMobile(status)
        if (status == "all"){
            setCurrentOrders(allOrders?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const filter = allOrders.filter(e => e.delivery_status == status)
            setCurrentOrders(filter?.slice(indexOfFirstPost, indexOfLastPost))
        }

    }

    useEffect(() => {
        setCurrentOrders(allOrders?.slice(indexOfFirstPost, indexOfLastPost))
    },[allOrders,currentPage])


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
       currentOrders.map(li => {
            if (li.shipping_status == 1)
                setIsCheck([...isCheck,li.id])
        } )
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ==  currentOrders.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }
    };


    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentOrders(allOrders?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = allOrders.filter(order => {
                return order.order_id.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentOrders(filter)
        }
    }


    const transferToMobile = async () => {

        await swal({
            title : t('TransfertTomobile'),
            text : t('SureTransfertTomobile'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: true,
            icon : "success"
        }).then(async (val) => {
            if (val){
                try {
                    await axios.post(SERVER_URL+"order/tomobile",{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                        .then((response)=> {
                        if (response.data.succeed) {
                            toast.success(t(response.data.message))
                        }

                    })

                } catch (error) {
                    console.log(error)
                }
            }
        });

    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Orders')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><span>{APPNAME}</span></li>
                                <li className="breadcrumb-item active">{t('Orders')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <Authorization single={true}  module="orders" component={true} trial={true} >
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="orderList">
                        <div className="card-header  border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">{t('List')} {t('Orders')}</h5>
                                <div className="flex-shrink-0">

                                </div>
                            </div>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">
                                    <div className="col-lg-8">


                                        {
                                            isCheck.length > 0 &&

                                            <>
                                                {
                                                    loading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                       <div>
                                                          <p> {t('TransfertToMobile')}  <button className="btn btn-sm btn-info" onClick={event => transferToMobile()}>{t('Transfert')}</button></p>
                                                       </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body pt-0">
                           <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                                    <li className="nav-item">
                                        <span className="nav-link active All py-3 cursor-pointer" onClick={event => filterOrder('all')} data-bs-toggle="tab" id="All" role="tab" aria-selected="true">
                                            <i className="ri-store-2-fill me-1 align-bottom "></i> {t('All')}
                                        </span>
                                    </li>

                               <li className="nav-item">
                                        <span className="nav-link py-3 Pickups cursor-pointer text-primary" data-bs-toggle="tab" id="Pending" role="tab" aria-selected="false" onClick={event => filterOrder(1)}>
                                            <i className="ri-shopping-bag-2-fill me-1 align-bottom text-primary"></i>  {t('Pending')}
                                            {/*<span className="badge bg-danger align-middle ms-1">2</span>*/}
                                        </span>
                               </li>

                               <li className="nav-item">
                                        <span className="nav-link py-3 Pickups cursor-pointer text-info" data-bs-toggle="tab" id="Pickups" role="tab" aria-selected="false" onClick={event => filterOrder(2)}>
                                            <i className="ri-truck-line me-1 align-bottom text-info"></i>  {t('OnTheWay')}
                                            {/*<span className="badge bg-danger align-middle ms-1">2</span>*/}
                                        </span>
                               </li>

                                    <li className="nav-item">
                                        <span className="nav-link py-3 Delivered cursor-pointer text-success" data-bs-toggle="tab" id="Delivered" onClick={event => filterOrder(4)}
                                            role="tab" aria-selected="false">
                                            <i className="ri-checkbox-circle-line me-1 align-bottom text-success"></i>  {t('Delivered')}
                                        </span>
                                    </li>

                                    <li className="nav-item">
                                        <span className="nav-link py-3 Returns cursor-pointer text-warning" data-bs-toggle="tab" id="Returns" role="tab" aria-selected="false" onClick={event => filterOrder(5)}>
                                            <i className="ri-arrow-left-right-fill me-1 align-bottom text-warning"></i> {t('Returns')}
                                        </span>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link py-3 Cancelled cursor-pointer text-danger" data-bs-toggle="tab" id="Cancelled" href="#cancelled" role="tab" aria-selected="false" onClick={event => filterOrder(6)}>
                                            <i className="ri-close-circle-line me-1 align-bottom text-danger"></i> {t('Cancelled')}
                                        </span>
                                    </li>
                                </ul>


                            <div className="table-card">
                                <table className="table table-nowrap align-middle">
                                    <thead className="text-muted table-light">
                                    <tr className="text-uppercase">
                                        { isMobile == 1 ? <th scope="col" style={{width: "50px"}}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="selectAll"
                                                       id="selectAll"
                                                       onChange={handleSelectAll}
                                                       checked={isCheckAll}
                                                />
                                            </div>
                                        </th> : null}
                                        <th className="sort" data-sort="id">ID</th>
                                        <th className="sort" data-sort="customer_name">{t('Customer')}</th>
                                        <th className="sort" data-sort="product_name">{t('Product')}</th>
                                        <th className="sort" data-sort="date">Date {t('Order')}</th>
                                        <th className="sort" data-sort="amount">{t('Amount')}</th>
                                        <th className="sort" data-sort="payment">{t('PaymentMethod')}</th>
                                        <th className="sort" data-sort="status">Status</th>
                                        <th className="sort" data-sort="city">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?

                                            <tr>
                                                <td colSpan="8">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>
                                            :

                                           <>
                                               {
                                                   currentOrders.length > 0 ?

                                                       <>
                                                           {
                                                               currentOrders.map((order,key) =>
                                                                   <tr key={key}>
                                                                       { isMobile == 1   ?
                                                                           <th scope="col" style={{width: "50px"}}>
                                                                               {
                                                                                   order.shipping_status == 1 ?
                                                                                       <div className="form-check">
                                                                                           <input className="form-check-input" type="checkbox"
                                                                                                  name={order.order_id}
                                                                                                  id={order.id}
                                                                                                  onChange={handleClick}
                                                                                                  checked={isCheck.includes(order.id)}
                                                                                           />
                                                                                       </div>
                                                                                       :

                                                                                       ""
                                                                               }

                                                                       </th> : null}
                                                                       <td className="id">
                                                                           <Link to={`/store/order/${order.order_id}`}
                                                                              className="fw-medium link-primary">#{order.order_id}</Link></td>
                                                                       <td className="customer_name">{order.firstname} {order.lastname}</td>
                                                                       <td className="product_name"><span className="text-muted cursor-pointer" style={{textDecoration : "underline"}}>{JSON.parse(order.cart_content)?.length} {t('Product')}(s)</span></td>
                                                                       <td className="date">{format_date(order.order_date)}</td>
                                                                       <td className="amount">{order.total_amount} Frcs</td>
                                                                       <td className="payment">{t(Capitalize(order.payment_method.split('-')[0].toLowerCase()))}</td>
                                                                       <td className="status">
                                                                           {order.delivery_status == 1 ? <span className="badge badge-soft-primary text-uppercase">{t("Pending")}</span> : null}
                                                                           {order.delivery_status == 2 ? <span className="badge badge-soft-info text-uppercase">{t("Packed")}</span> : null}
                                                                           {order.delivery_status == 3 ? <span className="badge badge-soft-success text-uppercase">{t("OnTheWay")}</span> : null}
                                                                           {order.delivery_status == 4 ? <span className="badge badge-soft-success text-uppercase">{t("Delivered")}</span> : null}
                                                                           {order.delivery_status == 5 ? <span className="badge badge-soft-warning text-uppercase">{t("Return")}</span> : null}
                                                                           {order.delivery_status == 6 ? <span className="badge badge-soft-danger text-uppercase">{t("Cancel")}</span> : null}
                                                                       </td>
                                                                       <td>
                                                                           <ul className="list-inline hstack gap-2 mb-0">
                                                                               <li className="list-inline-item" data-bs-toggle="tooltip"
                                                                                   data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                                   <Link to={`/store/order/${order.order_id}`}
                                                                                         className="text-primary d-inline-block underline">
                                                                                      Detail
                                                                                   </Link>
                                                                               </li>
                                                                           </ul>
                                                                       </td>
                                                                   </tr>
                                                               )
                                                           }
                                                       </>

                                                       :

                                                       <tr>
                                                           <td colSpan="8">
                                                               <p className="text-center">{t('EmptyList')}</p>
                                                           </td>
                                                       </tr>


                                               }
                                           </>

                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={orders?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>
                    </div>

                </div>

            </div>
            </Authorization>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop
});

export default   compose(connect(mapStateToProps, { getProducts })) (withTranslation()(Orders));
