import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {Field, Form, Formik} from "formik";
import {withTranslation} from "react-i18next";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";

const  Assign = ({t,open,setOpen,ticket,users,created}) => {

    const [loading,setLoading] = useState(false)


    const validationSchema = Yup.object().shape({
        support_id: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        support_id:  '',
        ticket_id: ticket,
    }

    const handleSubmit = async (values) => {
        setLoading(true)
            await axios.post(SERVER_URL+"ticket/support/add",values,attachTokenToHeaders(true)).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={t('ChooseSupport')}  size="sm">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched }) =>
                    <Form>
                        <div className="row ">
                            <div className="col-lg-12">
                                <br/>
                                <label htmlFor="support_id" className="form-label">{t('Admin')} <b className="text-danger">*</b></label>
                                <Field as="select" className="form-select" id="support_id" name="support_id">
                                    <option value="">-</option>
                                    {
                                        users?.length > 0 ?

                                            <>
                                                {
                                                    users?.map((cust,key) => <option key={key} value={cust.id}>{cust.firstname} {cust.lastname}</option>)
                                                }
                                            </>
                                            : null
                                    }
                                </Field>
                                {errors.support_id && touched.support_id ? (
                                    <div className='text-danger'>{errors.support_id}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 pt-3">
                                <button className="btn btn-success btn-block" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>
        </CustomModal>
    );
}

export default withTranslation()(Assign)
