import React  from "react";

import Header1 from "../plugins/headers/Header1";
import Header2 from "../plugins/headers/Header2";
import Header3 from "../plugins/headers/Header3";


import Footer from "../plugins/footers/Footer";
import ProductsTrending from "../plugins/products/ProductsTrending";
import Banner1 from "../plugins/banners/Banner1";
import Banner2 from "../plugins/banners/Banner2";
import Banner3 from "../plugins/banners/Banner3";
import NewArrival from "../plugins/products/NewArrival";
import Category1 from "../plugins/categories/Category1";
import Category2 from "../plugins/categories/Category2";
import Hero1 from "../plugins/heros/Hero1";
import Hero2 from "../plugins/heros/Hero2";
import Trending from "../plugins/products/Trending";
import Testimonial from "../plugins/testimonial/Testimonial";
import Contact from "../plugins/contactForms/Contact";
import AuthorArea from "../plugins/banners/AuthorArea";
import ContactMap from "../plugins/contactForms/ContactMap";
import Testimonial2 from "../plugins/testimonial/Testimonial2";
import Header4 from "../plugins/headers/header4";
import Social1 from "../plugins/socials/Social1";
import Social2 from "../plugins/socials/Social2";
import Social3 from "../plugins/socials/Social3";
import Testimonial3 from "../plugins/testimonial/Testimonial3";
import WeekFeatured from "../plugins/products/WeekFeatured";
import {flatCustomStyle} from "../utils/utils";
import Partners from "../plugins/partners/Partners";
import Teams1 from "../plugins/teams/Teams1";
import Teams2 from "../plugins/teams/Teams2";
import BreadCrumbs from "../plugins/breadcrumbs/BreadCrumbs";
import Paragraph from "../plugins/components/Base/Paragraph";
import CHeader from "../plugins/components/Base/CHeader";
import Horizontal from "../plugins/components/Base/Horizontal";
import IFrame from "../plugins/components/Base/IFrame";
import Images from "../plugins/components/Base/Images";
import Container from "../plugins/components/Bootstrap/Container/Container";
import AccordionComponent from "../plugins/components/Elements/Accordion";
import CSwiper from "../plugins/components/Elements/CSwiper";
import CPayPal from "../plugins/components/Widgets/CPayPal";
import Galery from "../plugins/components/Elements/Galery";
import Collection from "../plugins/freeblocks/Collection";
import CardBlock from "../plugins/freeblocks/CardBlock";

 const CustomComponent = {

        // Header
        header1 : Header1,
        header2 : Header2,
        header3 : Header3,
        header4 : Header4,

        // Hero
        hero1 : Hero1,
        hero2 : Hero2,

        // Footer
        footer :  Footer,

       // Products
       productTrending : ProductsTrending,
       newArrival : NewArrival,
       trending : Trending,
       weekFeatured : WeekFeatured,

        // Banners
        banner1 : Banner1,
        banner2 : Banner2,
        banner3 : Banner3,
        authorArea : AuthorArea,

       // Categories
       category1 : Category1,
       category2 : Category2,

     // Testimonials
     testimonial : Testimonial,
     testimonial2 : Testimonial2,
     testimonial3 : Testimonial3,

     // Contact
     contact : Contact,
     contactMap : ContactMap,

     social1 : Social1,
     social2 : Social2,
     social3 : Social3,

     partners : Partners,
     teams1 : Teams1,
     teams2 : Teams2,


     // Free Boxes

     collection : Collection,
     cardblock : CardBlock,


     breadcrumbs : BreadCrumbs,

     //   Components
     paragraph : Paragraph,
     heading : CHeader,
     hr : Horizontal,
     iframe : IFrame,
     images : Images,

    //  Bootstrap

    container : Container,



     // Element
     accordion : AccordionComponent,
     slider : CSwiper,
     galerie : Galery,

     // Widget
     paypal : CPayPal


}




 const CustomRender = ({block,toggleHightLight,isEditor,shop_data,device}) => {


     return(
                     typeof CustomComponent[block.component] != "undefined"
                     ? React.createElement(CustomComponent[block.component],{block : block,toggleHightLight : toggleHightLight,isEditor : isEditor,shop_data : shop_data,customStyle : flatCustomStyle(block.customStyle),device : device})
                     :  React.createElement(() => <div>The component {block.component} has not been created</div>)
     )


}

export default  CustomRender
