import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, bytesToSize, database_date, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,storages}) => {

    const [loading,setLoading] = useState(false)



    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('InputRequired')),
        description: Yup.string().required(t('InputRequired')),
        color : Yup.string().required(t('InputRequired')),
        price : Yup.string().required(t('InputRequired')),
        theme_customizable : Yup.boolean().required(t('InputRequired')),
        multi_langue : Yup.boolean().required(t('InputRequired')),
        domaine_name : Yup.string().required(t('InputRequired')),
        folder_size : Yup.string().required(t('InputRequired')),
        sponsor_shipping : Yup.boolean().required(t('InputRequired')),
        account_number : Yup.string().required(t('InputRequired')),
        mobile_access : Yup.boolean().required(t('InputRequired')),
        payment_method : Yup.string().required(t('InputRequired')),
        support_tech: Yup.boolean().required(t('InputRequired')),
    });

    const initialValues = {
        name: isEdit ? updateData.name : '',
        description: isEdit ? updateData.description : '',
        price: isEdit ? updateData.price : '',
        color: isEdit ? updateData.color : '',

        theme_customizable : isEdit ? updateData.theme_customizable : false,
        multi_langue : isEdit ? updateData.multi_langue : false,
        sponsor_shipping : isEdit ? updateData.sponsor_shipping : false,
        mobile_access : isEdit ? updateData.mobile_access : false,
        support_tech: isEdit ? updateData.support_tech : false,
        domaine_name : isEdit ? updateData.domaine_name : "domaine",
        folder_size : isEdit ? updateData.folder_size : "",
        account_number : isEdit ? updateData.account_number : "",
        payment_method : isEdit ? updateData.payment_method : "",
    }


    const handleSubmit = async (values) => {
        setLoading(true)
        if (isEdit){
            await axios.put(SERVER_URL+"admin/pricing/edit/"+updateData.pricing_key,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"admin/pricing/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }



    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Pricing')}</> : <>{t('Edit')} {t('Pricing')}</>} size="xl">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched,values, setFieldValue }) =>
                    <Form>
                       <div className="row">
                                    <div className="col-lg-4" style={{borderRight : "1px solid #ccc"}}>
                                        <div  className="">
                                            <label htmlFor="name" className="form-label">{t('Name')} <b className="text-danger">*</b></label>
                                            <Field  className="form-control form-control-lg" id="name" name="name"/>
                                            {errors.name && touched.name ? (
                                                <div className='text-danger'>{errors.name}</div>
                                            ) : null}
                                        </div>
                                        <br/>

                                        <div  className="">
                                            <label htmlFor="description" className="form-label">{t('Description')} <b className="text-danger">*</b></label>
                                            <Field as="textarea"  className="form-control form-control-lg" id="description" name="description"/>
                                            {errors.description && touched.description ? (
                                                <div className='text-danger'>{errors.description}</div>
                                            ) : null}
                                        </div>

                                       <br/>

                                        <div  className="">
                                            <label htmlFor="price" className="form-label">{t('Price')} <b className="text-danger">*</b></label>
                                            <Field  className="form-control form-control-lg" id="price" name="price"/>
                                            {errors.price && touched.price ? (
                                                <div className='text-danger'>{errors.price}</div>
                                            ) : null}
                                        </div>
                                         <br/>
                                        <div  className="">
                                            <label htmlFor="color" className="form-label">{t('Color')} <b className="text-danger">*</b></label>
                                            <Field  type="color" className="form-control form-control-color w-100 form-control-lg"  colorpick-eyedropper-active="true" id="color" name="color"/>
                                            {errors.color && touched.color ? (
                                                <div className='text-danger'>{errors.color}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row">
                                          <div className="col-lg-4">
                                              <div  className="">
                                                  <label htmlFor="theme_customizable" className="form-label">{t("ThemeCustomizable")} <b className="text-danger">*</b></label>
                                                  <div className="form-check form-switch mb-3">
                                                      <Field type="checkbox" className="form-check-input"
                                                              disabled={isEdit}
                                                             value={values.theme_customizable} defaultChecked={values.theme_customizable} checked={values.theme_customizable} id="theme_customizable" name="theme_customizable"/>
                                                  </div>

                                                  {errors.theme_customizable && touched.theme_customizable ? (
                                                      <div className='text-danger'>{errors.theme_customizable}</div>
                                                  ) : null}
                                              </div>
                                          </div>
                                          <div className="col-lg-4">
                                              <div  className="">
                                                  <label htmlFor="multi_langue" className="form-label">{t("MultiLangue")} <b className="text-danger">*</b></label>
                                                  <div className="form-check form-switch mb-3">
                                                      <Field type="checkbox" className="form-check-input"  disabled={isEdit} defaultChecked={values.multi_langue} id="multi_langue" name="multi_langue"/>
                                                  </div>

                                                  {errors.multi_langue && touched.multi_langue ? (
                                                      <div className='text-danger'>{errors.multi_langue}</div>
                                                  ) : null}
                                              </div>
                                          </div>
                                            <div className="col-lg-4">
                                                <div  className="">
                                                    <label htmlFor="sponsor_shipping" className="form-label">{t("Sponsorship")} <b className="text-danger">*</b></label>
                                                    <div className="form-check form-switch mb-3">
                                                        <Field type="checkbox" className="form-check-input"  disabled={isEdit} defaultChecked={values.sponsor_shipping} id="sponsor_shipping" name="sponsor_shipping"/>
                                                    </div>

                                                    {errors.sponsor_shipping && touched.sponsor_shipping ? (
                                                        <div className='text-danger'>{errors.sponsor_shipping}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div  className="">
                                                    <label htmlFor="mobile_access" className="form-label">{t("MobileAccess")} <b className="text-danger">*</b></label>
                                                    <div className="form-check form-switch mb-3">
                                                        <Field type="checkbox" className="form-check-input" disabled={isEdit}  defaultChecked={values.mobile_access} id="mobile_access" name="mobile_access"/>
                                                    </div>

                                                    {errors.mobile_access && touched.mobile_access ? (
                                                        <div className='text-danger'>{errors.mobile_access}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div  className="">
                                                    <label htmlFor="support_tech" className="form-label">{t("Support")} <b className="text-danger">*</b></label>
                                                    <div className="form-check form-switch mb-3">
                                                        <Field type="checkbox" className="form-check-input"  disabled={isEdit} defaultChecked={values.support_tech} id="support_tech" name="support_tech"/>
                                                    </div>

                                                    {errors.support_tech && touched.support_tech ? (
                                                        <div className='text-danger'>{errors.support_tech}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                             <br/>
                                            <hr/>
                                            <div  className="col-lg-6">
                                                <br/>
                                                <label htmlFor="domaine_name" className="form-label">{t('Domaine')} <b className="text-danger">*</b></label>
                                                <Field  className="form-control form-control-lg" id="domaine_name" disabled={true} readOnly={true} name="domaine_name"/>
                                                {errors.domaine_name && touched.domaine_name ? (
                                                    <div className='text-danger'>{errors.domaine_name}</div>
                                                ) : null}
                                            </div>

                                            <div  className="col-lg-6">
                                                <br/>
                                                <label htmlFor="folder_size" className="form-label">{t('FolderSize')} <b className="text-danger">*</b></label>
                                                <Field  as="select" className="form-select form-control-lg" id="folder_size"  name="folder_size">
                                                    <option value="">-</option>
                                                    {
                                                        storages?.length > 0 ?
                                                            <>
                                                            {
                                                               storages.map((storage,key) => <option key={key} value={storage.id}>{bytesToSize(storage.storage)}</option>)
                                                            }
                                                            </>
                                                            : ""
                                                    }
                                                </Field>
                                                {errors.folder_size && touched.folder_size ? (
                                                    <div className='text-danger'>{errors.folder_size}</div>
                                                ) : null}
                                            </div>


                                            <div  className="col-lg-6">
                                                <br/>
                                                <label htmlFor="account_number" className="form-label">{t('AccountNumber')} <b className="text-danger">*</b></label>
                                                <Field  type="number" className="form-control form-control-lg" id="account_number"  name="account_number"/>
                                                {errors.account_number && touched.account_number ? (
                                                    <div className='text-danger'>{errors.account_number}</div>
                                                ) : null}
                                            </div>

                                            <div  className="col-lg-6">
                                                <br/>
                                                <label htmlFor="payment_method" className="form-label">{t('PaymentMethod')} <b className="text-danger">*</b></label>
                                                <Field className="form-control form-control-lg" id="payment_method"  name="payment_method"/>
                                                {errors.payment_method && touched.payment_method ? (
                                                    <div className='text-danger'>{errors.payment_method}</div>
                                                ) : null}
                                            </div>



                                        </div>
                                    </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8 pt-3">
                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                            </div>
                            <div className="col-lg-4 pt-3">
                                <button className="btn btn-success btn-block" type="submit" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>
        </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)
