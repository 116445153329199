import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDrop} from "react-dnd";
import itemTypes from "../config/config.type";
import CustomRender from "../config/config.block";
import {RegLoading} from "../../components";
import "../assets/libs/libs.css"
import Hightlight from "../forms/Hightlight";
import SectionPanel from "./center/SectionPanel";
import {toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import IframeWrapper from "../forms/IframeWrapper";
import CustomIframe from "./CustomIframe";
import swal from "@sweetalert/with-react";

const Canvas = ({device,toShoot,setRightStep,setContent,setElements,elements,setStep,t,loading,shop_data}) => {


    const [hightlightInfo, setHightLightInfo] = useState({
        block : {},
        size : {},
        status : false
    });


    const [modal, setModal] = useState(false);

    const EditChoosedComponent = (element) => {
        setContent(element)
        setRightStep(4)
        setStep(3)
    }


    const toggleHightLight = (ele,sizes,status) => {
        if(status){
            setHightLightInfo({size: sizes, block: ele, status: true})
        }else{
            setHightLightInfo({size: {top :0, left : 0, width : 0, height : 0}, block: ele, status: true})
        }
    }


    const [{ isOver }, drop] = useDrop({
        accept: itemTypes.CARD,
        drop: (item, monitor) => {
            const newElement = {
                item
            };
            EditChoosedComponent(item)
            setElements(prevElements => [...prevElements, newElement]);
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const addnewBlock = (block,position) => {
        setElements(prevElements => [...prevElements, {item : block}]);
        toast.info(t("BlockAdd"), {
            position: "bottom-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    }


    const insertBefore = (array, elementToInsertBefore, newObject)  =>{
        const index = array.findIndex(element => element === elementToInsertBefore);

        if (index !== -1) {
            return array.slice(0, index).concat(newObject, array.slice(index));
        }

        return array;
    }

    const insertAfter = (array, elementToInsertAfter, newObject) => {
        const index = array.findIndex(element => element === elementToInsertAfter);

        if (index !== -1) {
            return array.slice(0, index + 1).concat(newObject, array.slice(index + 1));
        }

        return array;
    }


    const DeleteSection = (item_id) => {

        swal({
            title : t('Delete'),
            text : t('SureToDelete'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check)  {
                const ele = elements.filter(e => e.item.id !== item_id)
                setElements(ele)
            }
        });


    }



    const tablet = {
        position: 'absolute',
        width: '768px',
        left: '40%',
        transform: 'translateX(-50%) calc(-384px + 25vw)',
        height : "94vh"
    }

    const mobile = {
        position: 'absolute',
        width: '412px',
        left: '50%',
        transform: 'translateX(-50%) calc(-206px + 25vw)',
        height : "94vh"
    };

    return (
        <div>
            {
                device === "desktop" ?

                    <div id="canvas" ref={toShoot} style={{overflowX: "hidden"}}>

                        <SectionPanel setOpen={setModal} open={modal} addnewBlock={addnewBlock}/>

                        <Hightlight block={hightlightInfo.block} size={hightlightInfo.size} DeleteSection={DeleteSection}/>

                        {
                            loading ? <RegLoading/> :
                                <div ref={drop} className={`superTemplate ${isOver ? "onIsOver" : "bg-white"}`}
                                     style={{height: "100vh"}}>
                                        {elements.map(({item}, index) => (
                                            <div key={index}
                                                 className="cursor-pointer"
                                                 onClick={event => EditChoosedComponent(item)}>
                                                <CustomRender
                                                    block={item}
                                                    toggleHightLight={toggleHightLight}
                                                    isEditor={true}
                                                    shop_data={shop_data}
                                                    device={device}
                                                />
                                            </div>
                                        ))}
                                </div>
                        }
                    </div>

                    :
                    <div>
                       {/* <CustomIframe device={device}>

                        </CustomIframe>*/}

                        <iframe src="/preview/theme" style={device === "tablet" ? tablet : mobile}></iframe>
                    </div>

            }
        </div>

    );
}

export default withTranslation()(Canvas)
