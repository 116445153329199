import {SUB_INCREASE_QUANTITY,
    SUB_DECREASE_QUANTITY,
    SUB_ADD_CART ,
    SUB_DELETE_CART,
    SUB_CLEAR_CART} from "./type";

export function CustomerAddCart(payload){
    return {
        type:SUB_ADD_CART,
        payload : payload
    }
}

export function  CustomerDeleteCart(payload){
    return{
        type:SUB_DELETE_CART,
        payload : payload
    }
}

export function  CustomerIncrease(payload){
    return{
        type:SUB_INCREASE_QUANTITY,
        payload : payload
    }
}

export function  CustomerDecrease(payload){
    return{
        type: SUB_DECREASE_QUANTITY,
        payload : payload
    }
}

export function  CustomerClearCart(payload){
    return{
        type:SUB_CLEAR_CART,
        payload : payload
    }
}
