import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {FilePond} from "react-filepond";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";

const  ImageUpdate = ({open,setOpen,t,productId,created,auth,length}) => {
    const [loading,setLoading] = useState(false)
    const [files,setFiles] = useState([])


    const submitCategoryPicture = async (e) =>{
        e.preventDefault()
        setLoading(true)
        if (files.length > 0){
            try {
                const shop_id = auth?.shop?.id

                const formdata = new FormData()
                formdata.append('productId',productId)
                formdata.append('type',"products")
                formdata.append('shop_key',auth.shop.shop_key)

                for(let i =0; i < files.length; i++) {
                    formdata.append("galerie",files[i]);
                }


                await axios.post(SERVER_URL+"product/update/picture",formdata,attachTokenToHeaders(true)).then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setOpen(false)
                        setFiles([])
                        created(shop_id)
                    }else{
                        toast.error(t(response.data.message))
                    }
                })


            } catch (e) {
                console.log(e)
            }
        }
    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={<>{t('Add')} {t('Picture')}</>} size={length > 1 ? "lg" : "sm"}>
                        <div className="row">
                            <div>
                                <label htmlFor="file" className="form-label">{t('Picture')}</label>
                                <FilePond
                                    files={files}
                                    allowMultiple={true}
                                    maxFiles={length}
                                    name="file"
                                    id="file"
                                    onupdatefiles={(fileItems) => {
                                        setFiles(fileItems.map((fileItem) => fileItem.file))
                                    }}
                                    labelIdle={t('DragNdrop')}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 pt-3">
                                <button className="btn btn-success btn-block" disabled={loading} onClick={event => submitCategoryPicture(event)}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
        </CustomModal>
    );
}

export default ImageUpdate;