import React, {useEffect, useRef, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import { APPNAME, attachTokenToHeaders, numberWithCommas, SERVER_IMG_URL, SERVER_URL } from '../../../../helpers/utils'
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {getProducts} from "../../../../store/Categories/action";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import {toast} from "react-toastify";
import swal from '@sweetalert/with-react'
import ProductImageUpdate from './ProductImageUpdate'
import defaulAvatar from '../../../../assets/admin/images/avatar-blank.png'

const ProductDetail =  ({t,products : {products,isLoading,categories,subcategories},auth,getProducts}) => {

    const [detail,setDetail] = useState(null)
    const [picture,setPicture] = useState([])
    const [loading,setLoading] = useState(true)
    const [galery,setGalery] = useState("")
    const [open,setOpen] = useState(false)
    const [rating, setRating] = useState([]);
    const [reviews, setReviews] = useState([]);


    useEffect(()=>{
         getProducts(auth.shop?.id)
       getProductSingle()
    },[auth])


    const slug = useRef(window.location.pathname.split('/').pop());


    const getProductSingle = async () => {
        await axios.get(SERVER_URL+"product/single/"+slug.current+"/"+auth.shop?.id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setDetail(response.data.product)
                setPicture(JSON.parse(response.data?.product?.pictures))
                setRating(response.data?.ratings)
                setReviews(response.data?.reviews)
                setGalery(JSON.parse(response.data?.product?.pictures)[0])
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }


    const filterCategory = (id) => {
        const filter = categories.filter(e => e.category_id == id)
        return filter[0]?.category
    }

    const filterSubCategory = (id) => {
        const filter = subcategories.filter(e => e.subcategory == id)
        return filter[0]?.subname
    }

    const deletePicture =  async (filename) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeletePicture'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                 await acceptedDelete(filename)
            }
        });

    }



    const acceptedDelete = async (filename) => {
        try {
            const data = {
                id: detail?.id,
                filename: filename,
            };
            const response = await axios.post(`${SERVER_URL}product/delete-picture`, data, attachTokenToHeaders());
            if (response.data.succeed) {
                const updatedPictures = picture.filter(pic => pic !== filename);
                setPicture(updatedPictures);

                if (galery === filename) {
                    setGalery(updatedPictures[0] || "");
                }

                toast.success(t("Picturedeleted"));
            } else {
                toast.error(t(response.data.message));
            }
        } catch (err) {
            console.error(err);
            toast.error("An error occurred while deleting the picture.");
        }

    };


 const choosePicture = (img) => {
        setGalery(img)
 }


    const calculReviews = (reviews) => {
        const totalRate = reviews.reduce((sum, review) => sum + review.rate, 0);
        const count = totalRate / reviews.length;
        return count || 0;
    };


        const ratingCounts = reviews.reduce((acc, review) => {
            acc[review.rate] = (acc[review.rate] || 0) + 1;
            return acc;
        }, {});


        const getPercentage = (count) => (count / reviews.length) * 100;


    return (
        <AdminLayout >
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{detail?.title}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Products')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            {
                                loading || isLoading ?
                                    <p className="text-center p-t-150 p-b-150">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                    </p>
                                    :

                                    <div className="row gx-lg-5">
                                        <div className="col-xl-4 col-md-8 mx-auto">
                                            <div className="product-img-slider sticky-side-div">
                                                <div className="swiper product-thumbnail-slider p-2 rounded">
                                                    <div className="img-thumbnail bg-white" style={{display : "flex",justifyContent : "center",width : "100%", height : "365px"}}  >
                                                        {
                                                            picture?.length > 0
                                                                ?

                                                                <div  className="">
                                                                    <img src={SERVER_IMG_URL+auth.shop?.shop_key+"/products/"+galery} alt=""   className="img-fluid d-block "/>
                                                                </div>

                                                                :

                                                                <p className="text-center">
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                                </p>

                                                        }
                                                    </div>
                                                </div>

                                                <div className="swiper product-nav-slider mt-2">


                                                    <div className="swiper-wrapper">

                                                        <div className="row mt-2" style={{ display: 'flex' }}>
                                                            {
                                                                picture?.length > 0
                                                                    ?

                                                                    <>
                                                                        {
                                                                            picture.map((pic, key) =>
                                                                                    <div key={key} className="col-lg-3">
                                                                                        <div
                                                                                            className="delete_event_picture  cursor-pointer">
                                                                                            <span><i
                                                                                                className="mdi mdi-trash-can-outline"
                                                                                                onClick={event => deletePicture(pic)}></i></span>
                                                                                            <img
                                                                                                onClick={event => choosePicture(pic)}
                                                                                                src={SERVER_IMG_URL + auth.shop?.shop_key + '/products/thumb/' + pic}
                                                                                                alt={key}
                                                                                                className="img-thumbnail" />
                                                                                        </div>
                                                                                    </div>
                                                                            )
                                                                        }
                                                                    </>

                                                                    :

                                                                    <p className="text-center">
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true" /> {t('Loading')}...
                                                                    </p>

                                                            }

                                                        </div>


                                                    </div>
                                                    {
                                                        (5 - picture.length) > 0 ?
                                                              <button className="btn btn-sm btn-light mt-30" onClick={event => setOpen(true)}>
                                                                {t('AddPicture')}
                                                            </button>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-xl-8">
                                            <div className="mt-xl-0" style={{ marginTop: "50px" }}>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h4>{detail?.title}</h4>
                                                        <div className="hstack gap-3 flex-wrap">
                                                            <div>
                                                                <>
                                                                    {
                                                                        detail?.isSubcategory ? t('SubCategorie') : t('Categorie')
                                                                    }
                                                                </>
                                                                :

                                                                <>
                                                                    {
                                                                        detail?.isSubcategory ? <span
                                                                                className="fw-medium">{filterSubCategory(detail?.subcategory_id)}</span> :
                                                                            <span
                                                                                className="fw-medium">{filterCategory(detail?.category_id)}</span>
                                                                    }
                                                                </>
                                                            </div>

                                                            <div className="vr"></div>
                                                            <div className="text-muted">{t("Added")} : <span
                                                                className="text-body fw-medium">{moment(detail?.created_at).format("DD MMM , YYYY")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div>
                                                            <Link to={`/store/product/edit/${slug.current}`}
                                                                  className="btn btn-light" title="Edit">
                                                                <i className="ri-pencil-fill align-bottom"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                                                    <div className="text-muted fs-16">
                                                        {
                                                            detail?.rating ?

                                                                <ReactStars
                                                                    count={5}
                                                                    size={35}
                                                                    edit={false}
                                                                    value={detail?.rating ?? 0}
                                                                    emptyIcon={<i className="far fa-star"></i>}
                                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                    fullIcon={<i className="fa fa-star"></i>}
                                                                    activeColor="#ffd700"
                                                                />

                                                                :

                                                                <b>N/A</b>
                                                        }
                                                    </div>
                                                    {/*<div className="text-muted">( 5.50k Customer Review )</div>*/}
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div
                                                                        className="avatar-title rounded bg-transparent text-success fs-24">
                                                                        <i className="ri-money-dollar-circle-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">{t('Price')} :</p>
                                                                    <h5 className="mb-0">{numberWithCommas(detail?.price)}
                                                                        <small style={{ fontSize: '12px' }}>Frcs</small>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div
                                                                        className="avatar-title rounded bg-transparent text-success fs-24">
                                                                        <i className="ri-file-copy-2-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">{t('Order')} :</p>
                                                                    <h5 className="mb-0">{detail?.orders ?? 0}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="p-2 border border-dashed rounded">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm me-2">
                                                                    <div
                                                                        className="avatar-title rounded bg-transparent text-success fs-24">
                                                                        <i className="ri-stack-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <p className="text-muted mb-1">Stocks :</p>
                                                                    <h5 className="mb-0">{detail?.stock ?? 0}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="mt-4 text-muted">
                                                    <h5 className="fs-14">Description :</h5>
                                                    <p>{detail?.short_description}</p>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="mt-3">
                                                            <h5 className="fs-14">Badge :</h5>
                                                            <ul className="list-unstyled">
                                                                <li className="py-1">
                                                                    <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i> {detail?.badge}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h6>{t('AdditionalInfo')}</h6>
                                                        <table className="mt-30 table  table-bordered">
                                                            <tbody>

                                                            {
                                                                detail?.additional_info ?
                                                                    <>
                                                                        {
                                                                            JSON.parse(detail.additional_info).map((item, key) =>

                                                                                <tr key={key}>
                                                                                    <td>{item.label}</td>
                                                                                    <td>{item.value}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </>
                                                                    :

                                                                    <tr></tr>
                                                            }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="product-content" style={{ marginTop: '50px' }}>
                                                    <h5 className="fs-14 mb-3">Product Description :</h5>
                                                    <nav>
                                                        <ul className="nav nav-tabs nav-tabs-custom nav-success"
                                                            id="nav-tab"
                                                            role="tablist">
                                                            <li className="nav-item">
                                                                <a className="nav-link active" id="nav-detail-tab"
                                                                   data-bs-toggle="tab"
                                                                   href="#nav-detail" role="tab"
                                                                   aria-controls="nav-detail"
                                                                   aria-selected="false">Details</a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                    <div className="tab-content border border-top-0 p-4"
                                                         id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-detail"
                                                             role="tabpanel" aria-labelledby="nav-detail-tab">
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: detail?.description }} />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="" style={{ marginTop: "50px" }}>
                                                    <div>
                                                        <h5 className="fs-14 mb-3">{t('Ratings&Reviews')}</h5>
                                                    </div>
                                                    <div className="row gy-4 gx-0">
                                                        <div className="col-lg-4">
                                                            <div>
                                                                <div className="pb-3">
                                                                    <div className="bg-light px-3 py-2 rounded-2 mb-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-grow-1">
                                                                                {
                                                                                    reviews.length > 0 ?

                                                                                        <ReactStars
                                                                                            count={5}
                                                                                            size={20}
                                                                                            edit={false}
                                                                                            value={Math.round(calculReviews(reviews))}
                                                                                            emptyIcon={<i
                                                                                                className="far fa-star"></i>}
                                                                                            halfIcon={<i
                                                                                                className="fa fa-star-half-alt"></i>}
                                                                                            fullIcon={<i
                                                                                                className="fa fa-star"></i>}
                                                                                            activeColor="#ffd700"
                                                                                        />

                                                                                        :

                                                                                        <b>N/A</b>
                                                                                }
                                                                            </div>
                                                                            <div className="flex-shrink-0">
                                                                                <h6 className="mb-0">{Math.round(calculReviews(reviews))}
                                                                                    <i className="fa fa-star text-warning" />
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <div className="text-muted">Total <b
                                                                            className="fw-medium">{reviews?.length}</b> {t('Reviews').toLowerCase()}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-3">
                                                                    {[5, 4, 3, 2, 1].map((star) => (
                                                                        <div className="row align-items-center g-2"
                                                                             key={star}>
                                                                            <div className="col-auto">
                                                                                <div className="p-2">
                                                                                    <h6 className="mb-0">{star} {t('Stars').toLowerCase()}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="p-2">
                                                                                    <div
                                                                                        className="progress animated-progress progress-sm">
                                                                                        <div
                                                                                            className={`progress-bar ${star >= 4 ? 'bg-success' : star === 3 ? 'bg-warning' : 'bg-danger'}`}
                                                                                            role="progressbar"
                                                                                            style={{ width: `${getPercentage(ratingCounts[star] || 0)}%` }}
                                                                                            aria-valuenow={getPercentage(ratingCounts[star] || 0)}
                                                                                            aria-valuemin="0"
                                                                                            aria-valuemax="100"
                                                                                        ></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                <div className="p-2">
                                                                                    <h6 className="mb-0 text-muted">{ratingCounts[star] || 0}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-8">
                                                            <div className="ps-lg-4">
                                                                <div
                                                                    className="d-flex flex-wrap align-items-start gap-3">
                                                                    <h5 className="fs-14">{t('Reviews')}: </h5>
                                                                </div>

                                                                <div className="me-lg-n3 pe-lg-4" data-simplebar
                                                                     style={{ maxHeight: '225px' }}>
                                                                    <ul className="list-unstyled mb-0">
                                                                        {reviews.map((review,key) => (
                                                                            <li className="py-2" key={key}>
                                                                                <div
                                                                                    className="border border-dashed rounded p-3">


                                                                                    <div className="d-flex flex-grow-1 gap-2">
                                                                                        <a href="#" className="d-block">
                                                                                            <img
                                                                                                src={review.avatar ? SERVER_IMG_URL + auth?.shop.shop_key + '/customers/' + review.avatar : defaulAvatar}
                                                                                                alt=""
                                                                                                className="avatar-sm rounded object-fit-cover material-shadow" />
                                                                                        </a>

                                                                                        <div className="hstack gap-3 w-100">
                                                                                            <div className="vr"></div>
                                                                                            <div
                                                                                                className="flex-grow-1">
                                                                                                <div className="d-flex justify-content-between w-50">

                                                                                                    <h5 className="fs-14 mb-0">{review.lastname} {review.firstname}</h5>

                                                                                                    <div
                                                                                                        className="badge rounded-pill bg-success mb-0">
                                                                                                        <i className="mdi mdi-star"></i> {review.rate}
                                                                                                    </div>

                                                                                                    <p className="text-muted fs-11 mb-0">{new Date(review.created_at).toLocaleDateString()}</p>
                                                                                                </div>


                                                                                                <p className="text-muted mb-0" style={{paddingTop : "5px"}}>{review.comment}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                            }
                        </div>

                    </div>

                </div>

            </div>

            <ProductImageUpdate
                length={5 - picture.length}
                productId={detail?.id}
                open={open}
                setOpen={setOpen}
                created={getProductSingle}
                auth={auth}
                t={t}
            />
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
    products: state.categorie,
})

export default compose(connect(mapStateToProps, { getProducts }))(withTranslation()(ProductDetail));
