import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../../utils/utils";

const CHeader = ({block,customStyle,toggleHightLight,isEditor}) => {


    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const cheader = useRef()

    const getBlockSize = () => {
        setDimension({top :cheader?.current?.offsetTop, left : cheader?.current?.offsetLeft, width : cheader?.current?.offsetWidth, height : cheader?.current?.offsetHeight,nodeName : cheader?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    const heading = (option,text,position) => {
        switch (option) {
            case "Heading 1" :
                return <h1 style={{textAlign : position}}>{text}</h1>
            case "Heading 2" :
                return <h2 style={{textAlign : position}}>{text}</h2>
            case "Heading 3" :
                return <h3 style={{textAlign : position}}>{text}</h3>
            case "Heading 4" :
                return <h4 style={{textAlign : position}}>{text}</h4>
            case "Heading 5" :
                return <h5 style={{textAlign : position}}>{text}</h5>
            case "Heading 6" :
                return <h6 style={{textAlign : position}}>{text}</h6>
            default:;
        }
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);
    
    return (
        <section className="pt-10 pb-10"
                 style={customStyle}
                 ref={cheader}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
        >
           <div className="container">
               {heading(flatData(block.contentEditable, 'type'),flatData(block.contentEditable, 'text'),flatData(block.contentEditable, 'position'))}
           </div>
        </section>
    )
}

export default CHeader;