import {
    INCREASE_QUANTITY,
    DECREASE_QUANTITY,
    ADD_CART ,
    DELETE_CART,
    CLEAR_CART
} from  './type';
import {cryptAndSaveCart, decryptAndShowCart} from "../../../helpers/utils";


const initialState = {
    numberCart : decryptAndShowCart()?.length??0,
    carts: decryptAndShowCart()??[],
}


const CartReducer =  (state = initialState, { type, payload }) =>{
    switch(type){
        case ADD_CART:
            if(state.numberCart==0){
                let cart = {
                    id:payload.id,
                    qty : payload.qty,
                    picture : payload.picture,
                     name:payload.name,
                    category : payload.category,
                    price:payload.price
                }
                state.carts.push(cart);
                cryptAndSaveCart(state.carts)
            }
            else{
                let check = false;
                state.carts.map((item,key)=>{
                    if(item.id==payload.id){
                        state.carts[key].qty++;
                        check=true;
                    }
                });
                if(!check){
                    let _cart = {
                        id:payload.id,
                        qty:1,
                        name:payload.name,
                        category : payload.category,
                        picture : payload.picture,
                        price:payload.price
                    }
                    state.carts.push(_cart);
                    cryptAndSaveCart(state.carts)
                }
            }
            return{
                ...state,
                numberCart: state.numberCart + 1
            }
        case INCREASE_QUANTITY:
            state.numberCart++
            state.carts[payload].qty++;
            cryptAndSaveCart(state.carts)
            return{
                ...state
            }
        case DECREASE_QUANTITY:
            let qty = state.carts[payload].qty;
            if(qty>1){
                state.numberCart--;
                state.carts[payload].qty--;
            }
            cryptAndSaveCart(state)

            return{
                ...state
            }
        case DELETE_CART:
            let qty_ = state.carts[payload].qty;
            const re = {
                ...state,
                numberCart:state.numberCart - qty_,
                carts:state.carts.filter(item=>{
                    return item.id!=state.carts[payload].id
                })

            }
            cryptAndSaveCart(re)
            return re
        case CLEAR_CART:
            localStorage.removeItem('__shop__item__')
            return {
                numberCart : 0,
                carts : []
            }
        default:
            return state;
    }
}

export default CartReducer
