import React, {useState} from 'react';
import {CustomModal} from "../../../../../components";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";

const AddTemplate = ({open,setOpen,t,auth,isEdit,updateData,created}) => {

    const [loading, setLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        type: Yup.string().required(t('InputRequired')),
        name: Yup.string().required(t('InputRequired')),
        width: Yup.number().required(t('InputRequired')),
        height: Yup.number().required(t('InputRequired')),
        orientation: Yup.string().required(t('InputRequired')),
        format : Yup.string().required(t('InputRequired')),
    });


    const initialValues = {
        type : isEdit ? updateData.type : "",
        name : isEdit ? updateData.name : "",
        width : isEdit ? JSON.parse(updateData.config).width : 52,
        height : isEdit ? JSON.parse(updateData.config).height : 30,
        orientation : isEdit ? JSON.parse(updateData.config).orientation : "portrait",
        format : isEdit ? JSON.parse(updateData.config).format : "A4"
    }




    const handleSubmit = async (values) => {
        setLoading(true)
        const shop_id = auth.shop.id

        const data  = {
            name : values.name,
            shop_id : shop_id,
            type : values.type,
            config : JSON.stringify({
                height : values.height,
                width : values.width,
                orientation : values.orientation,
                format : values.format
            })

        }


        if (isEdit){
            await axios.put(SERVER_URL+"label/template/"+updateData.id,data,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"label/template",data,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }

    return(
        <CustomModal open={open} setOpen={setOpen} size="lg" title={isEdit ? t('EditTemplate') :t("AddTemplate")} >
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched,values }) =>
                    <Form>
                        <div className="row">

                           <div className="col-lg-12 mb-20">
                               <div className="row">
                                   <div className="col-lg-6">
                                       <Field type="radio" id="barcode" value="barcode" name="type"/>
                                       <label htmlFor="barcode" className="form-label pl-20">{t('BarCode')} </label>
                                   </div>
                                   <div className="col-lg-6">
                                       <Field type="radio" id="qrcode" value="qrcode" name="type"/>
                                       <label htmlFor="qrcode" className="form-label pl-20">{t('QrCode')} </label>
                                   </div>
                               </div>
                               {errors.type && touched.type ? (
                                   <div className='text-danger'>{errors.type}</div>
                               ) : null}
                           </div>

                            <div className="col-lg-12">
                                <label htmlFor="name" className="form-label">{t('Name')} <b
                                    className="text-danger">*</b></label>
                                <Field className="front" id="name" name="name"/>
                                {errors.name && touched.name ? (
                                    <div className='text-danger'>{errors.name}</div>
                                ) : null}
                            </div>


                            <div className="col-lg-12 mt-20">
                                <h5>{t('Config')}</h5>
                            </div>


                            <div className="col-lg-6 mt-10">
                                <label htmlFor="width" className="form-label">{t('Width')} (mm) <b
                                    className="text-danger">*</b></label>
                                <Field className="front" id="width" name="width"/>
                                {errors.width && touched.width ? (
                                    <div className='text-danger'>{errors.width}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6 mt-10">
                                <label htmlFor="height" className="form-label">{t('Height')} (mm) <b
                                    className="text-danger">*</b></label>
                                <Field className="front" id="height" name="height"/>
                                {errors.height && touched.height ? (
                                    <div className='text-danger'>{errors.height}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6 mt-10">
                                <label htmlFor="orientation" className="form-label">{t('Orientation')} <b
                                    className="text-danger">*</b></label>
                                <Field as="select" className="form-select form-control-lg" id="orientation" name="orientation">
                                    <option value="">-</option>
                                    <option value="portrait">{t('Portrait')}</option>
                                    <option value="landscape">{t('Landscape')}</option>
                                </Field>
                                {errors.orientation && touched.orientation ? (
                                    <div className='text-danger'>{errors.orientation}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6 mt-10">
                                <label htmlFor="format" className="form-label">{t('PaperFormat')} <b
                                    className="text-danger">*</b></label>
                                <Field as="select" className="form-select form-control-lg" id="format" name="format">
                                    <option value="">-</option>
                                    <option value="A1">A1</option>
                                    <option value="A2">A2</option>
                                    <option value="A3">A3</option>
                                    <option value="A4">A4</option>
                                    <option value="A5">A5</option>
                                </Field>
                                {errors.format && touched.format ? (
                                    <div className='text-danger'>{errors.format}</div>
                                ) : null}
                            </div>

                        </div>
                        <div className="row mt-40">
                            <div className="offset-lg-9 col-lg-3 d-flex justify-content-end">
                                <button className="btn btn-block  btn-primary "
                                        disabled={loading} type="submit">
                                    {
                                        loading ?
                                            <><span className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"/>{t('Loading')}...</>
                                            :
                                            <span>{t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>


                    </Form>
                }

            </Formik>
        </CustomModal>
                    )
                }

                export default withTranslation()(AddTemplate)
