import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import SettingShop from "./subpanel/SettingsShop";
import SettingsDelivery from "./subpanel/SettingsDelivery";
import SettingsPayment from "./subpanel/SettingsPayment";
import SettingsNotif from "./subpanel/SettingsNotif";
import {compose} from "redux";
import {connect} from "react-redux";
import SettingsSponsor from "./subpanel/SettingsSponsor";
import SettingsSecurity from "./subpanel/SettingsSecurity";
import SettingsTerms from "./subpanel/SettingsTerms";
import Authorization from "../../../../components/Authorization";
import LevelAccess from "../../../../components/LevelAccess";

const Settings = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('Settings')

    const ulpar = window.location.search

    const urlParams = new URLSearchParams(ulpar);

    const [step,setStep] = useState(3)

    useEffect(()=>{
        chooseMenu()
    },[ulpar])

    const chooseMenu = () => {
      const choosed = urlParams.get("step")
        switch (choosed){
            case "notification" :
                setStep(6)
                break;
            case "delivery" :
                setStep(4)
                break;
            case "sponsors" :
                setStep(7)
                break;
            case "security" :
                setStep(1)
                break;
            case "payment" :
                setStep(5)
                break;
            default :
                setStep(3)
                break;

        }
    }

    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Settings')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a>{APPNAME}</a></li>
                                <li className="breadcrumb-item active">{t('Settings')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <Authorization single={true}  module="products" component={true} >
                    <div className="row">
                        <div className="col-lg-2">
                            <ul className="card list-group">
                                <li className={`list-group-item cursor-pointer ${step == 3 ? "active" : null}`} onClick={event => setStep(3)}>
                                    {t('Shop')}
                                </li>
                                <LevelAccess auth={auth}  level={2} link={true}>
                                <li className={`list-group-item cursor-pointer ${step == 4 ? "active" : null}`} onClick={event => setStep(4)}>
                                    {t('Shipping')}
                                </li>

                                </LevelAccess>
                                <li className={`list-group-item cursor-pointer ${step == 5 ? "active" : null}`} onClick={event => setStep(5)}>
                                    {t('Payment')}
                                </li>

                                <li className={`list-group-item cursor-pointer ${step == 6 ? "active" : null}`} onClick={event => setStep(6)}>
                                    {t('Notifications')}
                                </li>

                                <LevelAccess auth={auth} link={true}>
                                    <li className={`list-group-item cursor-pointer ${step == 7 ? "active" : null}`}
                                        onClick={event => setStep(7)}>
                                        {t('Sponsorship')}
                                    </li>
                                </LevelAccess>
                                <li className={`list-group-item cursor-pointer ${step == 1 ? "active" : null}`}
                                    onClick={event => setStep(1)}>
                                    {t('Security')}
                                </li>

                                <li className={`list-group-item cursor-pointer ${step == 8 ? "active" : null}`} onClick={event => setStep(8)}>
                                    {t('GeneralConditions')}
                                </li>


                            </ul>
                        </div>
                        <div className="col-lg-10">
                            <div className="card">
                                <div className="card-body">
                                    {step == 1 ? <SettingsSecurity auth={auth}  t={t}   /> : null}
                                    {step == 3 ? <SettingShop  auth={auth}  t={t} /> : null}
                                    {step == 4? <SettingsDelivery  auth={auth}  t={t}  /> : null}
                                    {step == 5 ? <SettingsPayment  auth={auth}  t={t}  /> : null}
                                    {step == 6 ? <SettingsNotif   auth={auth}  t={t}  /> : null}
                                    {step == 7 ? <SettingsSponsor   auth={auth}  t={t}  /> : null}
                                    {step == 8 ? <SettingsTerms   auth={auth}  t={t}  /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
            </Authorization>

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});

export default   compose(connect(mapStateToProps)) (withTranslation()(Settings));
