import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import Select from 'react-select'
import makeAnimated from 'react-select/animated'



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,auth}) => {

    const [loading,setLoading] = useState(false)
    const [country, setCountry] = useState([]);

    const animatedComponents = makeAnimated();


    const countryOptions = [
        { value: 'benin' , label: "Benin"},
        { value: "cote d'ivoire" , label: "Cote d'ivoire"},
        { value: 'mali' , label: "Mali"},
        { value: 'niger' , label: "Niger"},
        { value: 'senegal' , label: "Sénégal"},
        { value: 'togo' , label: "Togo"},
    ]


    const validationSchema = Yup.object().shape({
        firstname : Yup.string().required(t('InputRequired')),
        lastname : Yup.string().required(t('InputRequired')),
        sex : Yup.string().required(t('InputRequired')),
        email : Yup.string().required(t('InputRequired')),
        phone : Yup.string().required(t('InputRequired')),
        city : Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        firstname : isEdit ? updateData.firstname : '',
        lastname : isEdit ? updateData.lastname : '',
        sex : isEdit ? updateData.sex : '',
        email : isEdit ? updateData.email : '',
        phone : isEdit ? updateData.phone : '',
        city : isEdit ? updateData.city : '',
        country : isEdit ? updateData.country : '',
    }


    const handleSubmit = async (values) => {

        if(country.value === ""){
            document.getElementById('country').classList.add('is-invalid');
        }
        else{
            values.country = country.value;
            setLoading(true)
            if (isEdit){
                await axios.put(SERVER_URL+"admin/commercial/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setOpen(false)
                        created()
                    }else{
                        toast.error(t(response.data.message))
                    }
                })

            }
            else{
                await axios.post(SERVER_URL+"admin/commercial/add",values,attachTokenToHeaders()).then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setOpen(false)
                        created()
                    }else{
                        toast.error(t(response.data.message))
                    }
                })

            }
        }

    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Commercial')}</> : <>{t('Edit')} {t('Commercial')}</>} size="lg">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched,values }) =>
                    <Form>
                        <div className="row">

                            <div className="col-lg-6">
                                <label htmlFor="lastname" className="form-label">Nom <b
                                    className="text-danger">*</b></label>
                                <Field className="front" id="lastname" name="lastname" />
                                {errors.lastname && touched.lastname ? (
                                    <div className="text-danger">{errors.lastname}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6">
                                <label htmlFor="firstname" className="form-label">Prénom(s) <b
                                    className="text-danger">*</b></label>
                                <Field className="front" id="firstname" name="firstname" />
                                {errors.email && touched.email ? (
                                    <div className="text-danger">{errors.email}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6 mt-10">
                                <label htmlFor="email" className="form-label">Email <b
                                    className="text-danger">*</b></label>
                                <Field type="email" className="front" id="email" name="email" />
                                {errors.email && touched.email ? (
                                    <div className="text-danger">{errors.email}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6 mt-10">
                                <label htmlFor="phone" className="form-label">{t('Phone')} <b
                                    className="text-danger">*</b></label>
                                <Field className="front" id="phone" name="phone" />
                                {errors.phone && touched.phone ? (
                                    <div className="text-danger">{errors.phone}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6 mt-10">
                                <label htmlFor="sex" className="form-label">Sexe <b
                                    className="text-danger">*</b></label>
                                <Field as="select" className="form-select" id="sex" name="sex">
                                    <option value="">-</option>
                                    <option value="M">Homme</option>
                                    <option value="F">Femme</option>
                                </Field>
                                {errors.sex && touched.sex ? (
                                    <div className="text-danger">{errors.sex}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-6 mt-10">
                                <label htmlFor="city" className="form-label">Ville <b
                                    className="text-danger">*</b></label>
                                <Field className="front" id="city" name="city" />
                                {errors.city && touched.city ? (
                                    <div className="text-danger">{errors.city}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label htmlFor="country">{t('Country')} <b
                                        className="text-danger">*</b></label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue={country}
                                        isDisabled={false}
                                        onChange={setCountry}
                                        id="country"
                                        className="front"
                                        options={countryOptions}
                                    />
                                </div>
                            </div>

                        </div>



                        <div className="row">
                            <div className="col-lg-8 pt-3">
                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                            </div>
                            <div className="col-lg-4 pt-3">
                                <button className="btn btn-success btn-block" type="submit" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true" />
                                            :
                                            <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>
        </CustomModal>
    );
}

export default withTranslation()(CreateOrUpdate)
