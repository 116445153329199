import React, {Fragment, useState} from 'react';
import {flatStyle} from "../../../utils/utils";

const Size = ({data,updateValue,updateUnit}) => {
    const [toggle, setToggle] = useState(false)

    return (
        <Fragment>
            <label className="header" data-header="size_header" onClick={event => setToggle(!toggle)} htmlFor="header_size_header" >
                <span>Size</span>
                <div className="header-arrow"></div>
            </label>
            <input className="header_check" type="checkbox"  defaultChecked={toggle} id="header_size_header"/>

            {
                toggle ?
                    <div className="row ml-20 mr-20" data-section="size_header" >
                        <div className="mb-3 col-sm-6" data-key="width">
                            <label className="form-label" htmlFor="input-model">Width</label>
                            <div className="input">
                                <div className="input-group css-unit" id="cssunit-width">
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'width').value}
                                        onChange={event => updateValue("width",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'width').unit}
                                            onChange={event => updateUnit("width",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="height">
                            <label className="form-label" htmlFor="input-model">Height</label>
                            <div className="input">
                                <div className="input-group css-unit" id="cssunit-height">
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'height').value}
                                        onChange={event => updateValue("height",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'width').unit}
                                            onChange={event => updateUnit("width",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="min-width">
                            <label className="form-label" htmlFor="input-model"
                            >Min Width</label
                            >
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-min-width"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'minWidth').value}
                                        onChange={event => updateValue("minWidth",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'minWidth').unit}
                                            onChange={event => updateUnit("minWidth",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="min-height">
                            <label className="form-label" htmlFor="input-model">Min Height</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-min-height"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'minHeight').value}
                                        onChange={event => updateValue("minHeight",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'minHeight').unit}
                                            onChange={event => updateUnit("minHeight",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="max-width">
                            <label className="form-label" htmlFor="input-model">Max Width</label>
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-max-width"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                        value={flatStyle(data,'maxWidth').value}
                                        onChange={event => updateValue("maxWidth",event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                            value={flatStyle(data,'maxWidth').unit}
                                            onChange={event => updateUnit("maxWidth",event.target.value)}
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-sm-6" data-key="max-height">
                            <label className="form-label" htmlFor="input-model"
                            >Max Height</label
                            >
                            <div className="input">
                                <div
                                    className="input-group css-unit"
                                    id="cssunit-max-height"
                                >
                                    <input
                                        name="number"
                                        type="number"
                                        className="form-control"
                                    />
                                    <div className="input-group-append">
                                        <select
                                            className="form-select small-arrow"
                                            name="unit"
                                        >
                                            <option value="em">em</option>
                                            <option value="rem">rem</option>
                                            <option value="px">px</option>
                                            <option value="%">%</option>
                                            <option value="vw">vw</option>
                                            <option value="vh">vh</option>
                                            <option value="ex">ex</option>
                                            <option value="ch">ch</option>
                                            <option value="cm">cm</option>
                                            <option value="mm">mm</option>
                                            <option value="in">in</option>
                                            <option value="pt">pt</option>
                                            <option value="auto">auto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
            }

        </Fragment>

    )
}

export default Size;
