import React, {Fragment, useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {filterSubcategories, flatData, slugify} from "../../utils/utils";
import {Link, useHistory} from "react-router-dom";
import {CLIENT, CustomChangeLanguage, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import {
    SUB_ABOUT, SUB_CART,
    SUB_CONTACT,
    SUB_INDEX,
    SUB_LOGIN,
    SUB_PRODUCTS,
    SUB_SERVICE,
    SUB_WISHLIST
} from "../../../pages/Sub/Seller/routes/type";
import axios from "axios";
import swal from "@sweetalert/with-react";
import {compose} from "redux";
import {connect} from "react-redux";
import {logUserout} from "../../../store/Subdomain/Oauth/action";
import CartRight from "../../../components/Card/CartRight";
import {toast} from "sonner";

const  Header1 = ({block,t,toggleHightLight,isEditor,customStyle,shop_data,logUserout,customCart,customWishlist,auth,device}) => {


    const [lang, setLang] = useState(false)
    const [account, setAccount] = useState(false)
    const [display, setDisplay] = useState(false)
    const [mobileToggle, setMobileToggle] = useState(false)
    const [showHeader, setShowHeader] = useState(false)
    const [categories, setCategories] = useState([])
    const [subcategories, setSubcategories] = useState([])
    const [open, setOpen] = useState(false)
    const [pages, setPages] = useState([])
    const history = useHistory()
    const [dlgblock, setDlgblock] = useState(true)
    const [dlgnone, setDlgnone] = useState(false)

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const header1Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :header1Ref?.current?.offsetTop, left : header1Ref?.current?.offsetLeft, width : header1Ref?.current?.offsetWidth, height : header1Ref?.current?.offsetHeight,nodeName : header1Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

         toggleHightLight(data,dimension,status)
    }

    useEffect(() => {
        const handleScroll = _ => {
            if (window.pageYOffset > 100) {
                setShowHeader(true)
            } else {
                setShowHeader(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return _ => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    useEffect(() => {
        if (device == "desktop") {
           setDlgblock(true)
           setDlgnone(false)
        }
        else if(device == "tablet") {}
        else if (device == "mobile") {
            setDlgblock(false)
            setDlgnone(true)
        }
    }, [device]);


    const loadPages = async (key) => {
        await axios.get(SERVER_URL + "pages/list/" + key).then((response) => {
            if (response.data.succeed) {
                const content = response.data.pages?.content
                let pages = JSON.parse(content)
                let names = []
                pages.map((page) => names.push(page.name))
                setPages(names)
            } else {
                toast.error(t(response.data.message))
            }
        })

    }

    useEffect(() => {
       if (shop_data)  loadPages(shop_data.shop_key)
    }, [shop_data]);


    useEffect(() => {
        if(shop_data){
            getCategories(shop_data.id)
        }
    }, [shop_data]);




    const getCategories = async (id) => {
        await axios.get(SERVER_URL+"/public/categories/"+id).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.categories)
                setSubcategories(response.data.subcategories)
            }
        })
    }


    const logoutModal = () => {
        swal({
            title : t('Logout'),
            text : t('SureToLogout'),
            buttons : [t('Cancel'),t('Yes')],
            dangerMode : true
        }).then((pass) => {
            if (pass){
                logUserout()
                setAccount(false)
            }
        })
    }


    const handleSearch = (e) => {
      e.preventDefault()
       if (!isEditor){
           console.log(e.target[0].value)
           if (e.target[0].value === ""){
               toast.error("Notification",{
                   description: t('InputRequired'),
                   icon : <i className="ri-close-fill"></i>
               })
           }else{
               history.push("/results?search_query=" + slugify(e.target[0].value))
           }
       }
    }


    return (
        <div
            ref={header1Ref}
             onMouseEnter={() => isEditor && getDimension(block,true)}
             onMouseLeave={() => isEditor && getDimension(block,false)}
             style={customStyle}
        >

            <div className={`offcanvas__area offcanvas__radius ${mobileToggle ? 'offcanvas-opened' : ""} `}>
                <div className="offcanvas__wrapper">
                    <div className="offcanvas__close" >
                        <button className="offcanvas__close-btn offcanvas-close-btn" onClick={event => setMobileToggle(false)}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1L1 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="offcanvas__content">
                        <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
                            <div className="offcanvas__logo logo">
                                <Link to={SUB_INDEX}>
                                    <img src={shop_data? SERVER_IMG_URL+"/"+shop_data.shop_key+"/logo/"+shop_data.logo : CLIENT + '/imgs/logo/logo.png'} alt="logo" style={{width : "120px"}}/>
                                </Link>
                            </div>
                        </div>
                        <div className="offcanvas__category pb-40">
                            <button className="tp-offcanvas-category-toggle" onClick={event => setDisplay(!display)}>
                                <i className="fa-solid fa-bars"></i>
                                 Categories
                            </button>
                            <div className="tp-category-mobile-menu">
                                <nav className="tp-category-menu-content" style={{display : display ? "block" : "none"}}>
                                    {
                                        isEditor ?
                                            <ul className="pl-0">
                                              <li className="has-dropdown">
                                                <a href="#" className="has-mega-menu">

                                                    Electronics
                                                    <button className="dropdown-toggle-btn"><i className="fa-regular fa-angle-right"></i></button></a>

                                                <ul className="mega-menu tp-submenu">
                                                    <li>
                                                        <a href="#" className="mega-menu-title">Featured</a>
                                                        <ul>
                                                            <li>
                                                                <a href="#"><img src={`${CLIENT}assets/img/header/menu/menu-1.jpg`} alt=""/></a>
                                                            </li>
                                                            <li>
                                                                <a href="#">New Arrivals</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Best Seller</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Top Rated</a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <a href="#" className="mega-menu-title">Computer &amp; Laptops</a>
                                                        <ul>
                                                            <li>
                                                                <a href="#"><img src={`${CLIENT}assets/img/header/menu/menu-2.jpg`} alt=""/></a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Top Brands</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Weekly Best Selling</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Most Viewed</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="mega-menu-title">Accessories</a>
                                                        <ul>
                                                            <li>
                                                                <a href="#"><img src={`${CLIENT}assets/img/header/menu/menu-3.jpg`} alt=""/></a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Headphones</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">TWS Earphone</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Gaming Headset</a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                </ul>
                                            </li>
                                            </ul>
                                            :
                                            <ul className="pl-0">
                                                {
                                                    categories?.map((category,key) =>
                                                        <li className={  filterSubcategories(subcategories,category.id)?.length > 0 ? "has-dropdown" : ""} key={key}>
                                                            <Link to={"/shop/"+category.slug}>
                                                                {category.category}
                                                            </Link>

                                                            {
                                                                filterSubcategories(subcategories,category.id)?.length > 0 ?
                                                                    <>
                                                                        <ul className="tp-submenu">
                                                                            { filterSubcategories(subcategories,category.id).map((sub,index) =>
                                                                                <li key={index}><Link to={"/"+slugify(category.category)+"/"+sub.slug}>{sub.subname}</Link></li>
                                                                            )

                                                                            }
                                                                        </ul>
                                                                    </>
                                                                    :
                                                                    ""
                                                            }
                                                        </li>
                                                    ) }
                                            </ul>
                                    }
                            </nav>
                            </div>
                        </div>
                        <div className="tp-main-menu-mobile fix d-lg-none mb-40">
                            <nav className="tp-main-menu-content">
                              <ul className="p-0">
                                <li><Link to={isEditor ? "#" :  SUB_INDEX}>{t('Home')}</Link></li>

                                <li><Link to={isEditor ? "#" :  SUB_PRODUCTS}>{t('Products')}</Link></li>

                                <li><Link to={isEditor ? "#" :  SUB_SERVICE}>{t('Services')}</Link></li>

                                <li><Link to={isEditor ? "#" :  SUB_ABOUT}>{t('About')}</Link></li>

                                <li><Link to={isEditor ? "#" :  SUB_CONTACT}>{t('Contact')}</Link></li>
                            </ul>
                            </nav>
                        </div>


                    </div>
                    <div className="offcanvas__bottom">
                        <div className="offcanvas__footer d-flex align-items-center justify-content-between">
                            <div className="offcanvas__currency-wrapper currency">
                                <span className="offcanvas__currency-selected-currency" id="tp-offcanvas-currency-toggle">{t('Currency')} : XOF</span>
                            </div>
                            <div className="offcanvas__select language">
                                <div className="offcanvas__lang d-flex align-items-center justify-content-md-end">
                                    <div className="offcanvas__lang-img mr-15">
                                        <img src={CLIENT+"/admin/images/flags/french.svg"} alt=""/>
                                    </div>
                                    <div className="offcanvas__lang-wrapper">
                                        <span className="offcanvas__lang-selected-lang tp-lang-toggle" id="tp-offcanvas-lang-toggle">English</span>
                                        <ul className="offcanvas__lang-list tp-lang-list">
                                            <li>Français</li>
                                            <li>English</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tp-bottom-menu-sticky" className="tp-mobile-menu d-lg-none">
                <div className="container">
                    <div className="row row-cols-4">
                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <Link to={isEditor ? "#" :  SUB_PRODUCTS} className="tp-mobile-item-btn">
                                    <i className="flaticon-store"></i>
                                    <span>{t('Shop')} </span>
                                </Link>
                            </div>
                        </div>

                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <Link to={isEditor ? "#" :  SUB_WISHLIST} className="tp-mobile-item-btn">
                                    <i className="flaticon-love"></i>
                                    <span>{t('WishList')}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <Link to={isEditor ? "#" :  SUB_LOGIN} className="tp-mobile-item-btn">
                                    <i className="flaticon-user"></i>
                                    <span>{t('Account')}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <button className="tp-mobile-item-btn tp-offcanvas-open-btn" onClick={event => setMobileToggle(true)}>
                                    <i className="flaticon-menu-1"></i>
                                    <span>Menu</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header>
                <div className="tp-header-area p-relative z-index-11 tp-header-style-primary">

                    <div className={`tp-header-top p-relative z-index-1 d-none d-md-block`}
                     style={{background : flatData(block.contentEditable,'topbarColor')}}
                    >
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="tp-header-welcome d-flex align-items-center">
                                           <span>
                                              <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                 <path d="M14.6364 1H1V12.8182H14.6364V1Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                 <path d="M14.6364 5.54545H18.2727L21 8.27273V12.8182H14.6364V5.54545Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                 <path d="M5.0909 17.3636C6.3461 17.3636 7.36363 16.3461 7.36363 15.0909C7.36363 13.8357 6.3461 12.8182 5.0909 12.8182C3.83571 12.8182 2.81818 13.8357 2.81818 15.0909C2.81818 16.3461 3.83571 17.3636 5.0909 17.3636Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                 <path d="M16.9091 17.3636C18.1643 17.3636 19.1818 16.3461 19.1818 15.0909C19.1818 13.8357 18.1643 12.8182 16.9091 12.8182C15.6539 12.8182 14.6364 13.8357 14.6364 15.0909C14.6364 16.3461 15.6539 17.3636 16.9091 17.3636Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                              </svg>
                                           </span>
                                        <p>{flatData(block.contentEditable,"topbarText")}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="tp-header-top-right d-flex align-items-center justify-content-end">
                                        <div className="tp-header-top-menu d-flex align-items-center justify-content-end">
                                            <div className="tp-header-top-menu-item tp-header-lang">
                                                <span className="tp-header-lang-toggle" id="tp-header-lang-toggle"
                                                      onClick={event => setLang(!lang)}>

                                                    {
                                                        localStorage.getItem('I18N_LANGUAGE') === "fr" ? "Français" : "English"
                                                    }
                                                </span>
                                                <ul className={lang ? 'tp-lang-list-open' : ""}>
                                                    <li style={{padding : "5px 10px 5px 10px"}}>
                                                        <a className="cursor-pointer" onClick={event => CustomChangeLanguage('fr')}>Français</a>
                                                    </li>
                                                    <li style={{padding : "5px 10px 5px 10px"}}>
                                                        <a className="cursor-pointer" onClick={event => CustomChangeLanguage('en')}>English</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tp-header-top-menu-item tp-header-currency">
                                                <span className="tp-header-currency-toggle" id="tp-header-currency-toggle">XOF</span>
                                            </div>
                                            {
                                                auth?.isAuthenticated ?
                                                    <div className="tp-header-top-menu-item tp-header-setting">
                                                <span className="tp-header-setting-toggle" id="tp-header-setting-toggle"
                                                      onClick={event => setAccount(!account)}>{t('Account')}</span>
                                                        <ul className={account ? 'tp-setting-list-open' : ""}>
                                                            <li style={{padding: "5px 10px 5px 10px"}}>
                                                                <Link
                                                                    to={isEditor ? "#" : SUB_INDEX}>{t('Profile')}</Link>
                                                            </li>

                                                            <li style={{padding: "5px 10px 5px 10px"}}
                                                                onClick={event => logoutModal()}>
                                                                <a className="cursor-pointer">{t('Logout')}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tp-header-main tp-header-sticky">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2 col-md-4 col-6">
                                    <div className="logo">
                                        <Link to={SUB_INDEX}>
                                            <img
                                                src={shop_data ? SERVER_IMG_URL + "/" + shop_data.shop_key + "/logo/" + shop_data.logo : CLIENT + '/imgs/logo/logo.png'}
                                                alt="logo" style={{width: "120px"}}/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-7 d-none d-lg-block">
                                <div className="tp-header-search pl-70">
                                        <form  onSubmit={handleSearch}>
                                            <div className="tp-header-search-wrapper d-flex align-items-center"
                                             style={{border : `2px solid ${flatData(block.contentEditable,"topbarColor")}`}}
                                            >
                                                <div className="tp-header-search-box">
                                                    <input type="text" name="search" placeholder={t('Search')}/>
                                                </div>
                                                <div className="tp-header-search-btn" >
                                                    <button type="submit" style={{background : flatData(block.contentEditable,"topbarColor")}}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M19 19L14.65 14.65" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-3 col-md-8 col-6">
                                    <div className="tp-header-main-right d-flex align-items-center justify-content-end">
                                        <div className="tp-header-login d-none d-lg-block">
                                            <Link to={SUB_LOGIN} className="d-flex align-items-center">
                                                <div className="tp-header-login-icon">
                                                    <span>
                                                       <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <circle cx="8.57894" cy="5.77803" r="4.77803" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                          <path fillRule="evenodd" clipRule="evenodd" d="M1.00002 17.2014C0.998732 16.8655 1.07385 16.5337 1.2197 16.2311C1.67736 15.3158 2.96798 14.8307 4.03892 14.611C4.81128 14.4462 5.59431 14.336 6.38217 14.2815C7.84084 14.1533 9.30793 14.1533 10.7666 14.2815C11.5544 14.3367 12.3374 14.4468 13.1099 14.611C14.1808 14.8307 15.4714 15.27 15.9291 16.2311C16.2224 16.8479 16.2224 17.564 15.9291 18.1808C15.4714 19.1419 14.1808 19.5812 13.1099 19.7918C12.3384 19.9634 11.5551 20.0766 10.7666 20.1304C9.57937 20.2311 8.38659 20.2494 7.19681 20.1854C6.92221 20.1854 6.65677 20.1854 6.38217 20.1304C5.59663 20.0773 4.81632 19.9641 4.04807 19.7918C2.96798 19.5812 1.68652 19.1419 1.2197 18.1808C1.0746 17.8747 0.999552 17.5401 1.00002 17.2014Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                       </svg>
                                                    </span>
                                                </div>
                                                <div className="tp-header-login-content d-none d-xl-block">
                                                    <span>Hello, {!auth?.isAuthenticated && t('LoginTo')}</span>
                                                    <h5 className="tp-header-login-title">{auth?.isAuthenticated ? auth?.user?.firstname+" "+auth?.user?.lastname  : t('YourAccount')}</h5>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="tp-header-action d-flex align-items-center ml-50">
                                            <div className="tp-header-action-item d-none d-lg-block">
                                                <Link  className="tp-header-action-btn" to={isEditor ? "#" :  SUB_WISHLIST}>
                                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.239 18.8538C13.4096 17.5179 15.4289 15.9456 17.2607 14.1652C18.5486 12.8829 19.529 11.3198 20.1269 9.59539C21.2029 6.25031 19.9461 2.42083 16.4289 1.28752C14.5804 0.692435 12.5616 1.03255 11.0039 2.20148C9.44567 1.03398 7.42754 0.693978 5.57894 1.28752C2.06175 2.42083 0.795919 6.25031 1.87187 9.59539C2.46978 11.3198 3.45021 12.8829 4.73806 14.1652C6.56988 15.9456 8.58917 17.5179 10.7598 18.8538L10.9949 19L11.239 18.8538Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M7.26062 5.05302C6.19531 5.39332 5.43839 6.34973 5.3438 7.47501" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    <span className="tp-header-action-badge"
                                                          style={{background : flatData(block.contentEditable,'topbarColor')}}
                                                    >{customWishlist?.wishlist?.length??0}</span>
                                                </Link>
                                            </div>
                                            <div className="tp-header-action-item">
                                                <span className="tp-header-action-btn cartmini-open-btn cursor-pointer" onClick={event => setOpen(true)} >
                                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.48626 20.5H14.8341C17.9004 20.5 20.2528 19.3924 19.5847 14.9348L18.8066 8.89359C18.3947 6.66934 16.976 5.81808 15.7311 5.81808H5.55262C4.28946 5.81808 2.95308 6.73341 2.4771 8.89359L1.69907 14.9348C1.13157 18.889 3.4199 20.5 6.48626 20.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.34902 5.5984C6.34902 3.21232 8.28331 1.27803 10.6694 1.27803V1.27803C11.8184 1.27316 12.922 1.72619 13.7362 2.53695C14.5504 3.3477 15.0081 4.44939 15.0081 5.5984V5.5984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M7.70365 10.1018H7.74942" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M13.5343 10.1018H13.5801" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    <span className="tp-header-action-badge"
                                                          style={{background : flatData(block.contentEditable,'topbarColor')}}
                                                    >{customCart?.carts.length}</span>
                                                </span>
                                            </div>
                                            <div className="tp-header-action-item d-lg-none">
                                                <button type="button" className="tp-header-action-btn tp-offcanvas-open-btn" onClick={event => setMobileToggle(true)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" viewBox="0 0 30 16">
                                                        <rect x="10" width="20" height="2" fill="currentColor"/>
                                                        <rect x="5" y="7" width="25" height="2" fill="currentColor"/>
                                                        <rect x="10" y="14" width="20" height="2" fill="currentColor"/>
                                                    </svg>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tp-header-bottom tp-header-bottom-border d-none d-lg-block">
                        <div className="container">
                            <div className="tp-mega-menu-wrapper p-relative">
                                <div className="row align-items-center">
                                    <div className="col-xl-3 col-lg-3">
                                        <div className="tp-header-category tp-category-menu tp-header-category-toggle">
                                            <button className="tp-category-menu-btn tp-category-menu-toggle" style={{background : flatData(block.contentEditable,"topbarColor")}} onClick={event => setDisplay(!display)}>
                                                 <span>
                                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                       <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1ZM0 7C0 6.44772 0.447715 6 1 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H1C0.447715 8 0 7.55228 0 7ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H1Z" fill="currentColor"/>
                                                    </svg>
                                                 </span>
                                                {t('Categories')}
                                            </button>
                                            <nav className="tp-category-menu-content">
                                                {
                                                    isEditor ?
                                                        <ul style={{display : display ? "block" : "none"}}>
                                                            <li className="has-dropdown">
                                                                <a href="#" className="has-mega-menu">
                                                                    Electronics
                                                                    </a>
                                                                <ul className="mega-menu tp-submenu">
                                                                    <li>
                                                                        <a href="#" className="mega-menu-title">Featured</a>
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#"><img src={`${CLIENT}assets/img/header/menu/menu-1.jpg`} alt=""/></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">New Arrivals</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">Best Seller</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">Top Rated</a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>

                                                                    <li>
                                                                        <a href="#" className="mega-menu-title">Computer &amp; Laptops</a>
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#"><img src={`${CLIENT}assets/img/header/menu/menu-2.jpg`} alt=""/></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">Top Brands</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">Weekly Best Selling</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">Most Viewed</a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" className="mega-menu-title">Accessories</a>
                                                                        <ul>
                                                                            <li>
                                                                                <a href="#"><img src={`${CLIENT}assets/img/header/menu/menu-3.jpg`} alt=""/></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">Headphones</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">TWS Earphone</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">Gaming Headset</a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>

                                                                </ul>
                                                            </li>
                                                        </ul>

                                                        :
                                                        <ul style={{display : display ? "block" : "none"}} className="pl-0 pt-20">
                                                            {
                                                                categories?.map((category,key) =>
                                                                    <li className={  filterSubcategories(subcategories,category.id)?.length > 0 ? "has-dropdown" : ""} key={key}>
                                                                        <Link to={"/shop/"+category.slug}>
                                                                            {category.category}
                                                                        </Link>

                                                                        {
                                                                            filterSubcategories(subcategories,category.id)?.length > 0 ?
                                                                                <>
                                                                                    <ul className="tp-submenu">
                                                                                        { filterSubcategories(subcategories,category.id).map((sub,index) =>
                                                                                            <li key={index}><Link to={"/"+slugify(category.category)+"/"+sub.slug}>{sub.subname}</Link></li>
                                                                                        )

                                                                                        }
                                                                                    </ul>
                                                                                </>
                                                                                :
                                                                                ""
                                                                        }
                                                                    </li>
                                                                ) }
                                                        </ul>
                                                }

                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="main-menu menu-style-1">
                                            <nav className="tp-main-menu-content">
                                                <ul>
                                                    { pages.includes("Home") ?  <li><Link to={isEditor ? "#" : SUB_INDEX} style={{color :   window.location.pathname === SUB_INDEX ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_INDEX && "bold" }}>{t('Home')}</Link></li>: ""}
                                                    {pages.includes("Shop") ?   <li><Link to={isEditor ? "#" : SUB_PRODUCTS} style={{color :   window.location.pathname === SUB_PRODUCTS ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_PRODUCTS && "bold" }}>{t('Shop')}</Link></li>: ""}
                                                    {pages.includes("About") ?   <li><Link to={isEditor ? "#" : SUB_ABOUT} style={{color :   window.location.pathname === SUB_ABOUT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_ABOUT && "bold" }}>{t('About')}</Link></li>: ""}
                                                    {pages.includes("Services") ?  <li><Link to={isEditor ? "#" : SUB_SERVICE} style={{color :   window.location.pathname === SUB_SERVICE ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_SERVICE && "bold" }}>{t('Service')}</Link></li>: ""}
                                                    {pages.includes("Contact") ?  <li><Link to={isEditor ? "#" : SUB_CONTACT} style={{color :   window.location.pathname === SUB_CONTACT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_CONTACT && "bold" }}>{t('Contact')}</Link></li>: ""}

                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3">
                                        <div className="tp-header-contact d-flex align-items-center justify-content-end">
                                            <div className="tp-header-contact-icon">
                                                 <span>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                       <path fillRule="evenodd" clipRule="evenodd" d="M1.96977 3.24859C2.26945 2.75144 3.92158 0.946726 5.09889 1.00121C5.45111 1.03137 5.76246 1.24346 6.01544 1.49057H6.01641C6.59631 2.05874 8.26011 4.203 8.35352 4.65442C8.58411 5.76158 7.26378 6.39979 7.66756 7.5157C8.69698 10.0345 10.4707 11.8081 12.9908 12.8365C14.1058 13.2412 14.7441 11.9219 15.8513 12.1515C16.3028 12.2459 18.4482 13.9086 19.0155 14.4894V14.4894C19.2616 14.7414 19.4757 15.0537 19.5049 15.4059C19.5487 16.6463 17.6319 18.3207 17.2583 18.5347C16.3767 19.1661 15.2267 19.1544 13.8246 18.5026C9.91224 16.8749 3.65985 10.7408 2.00188 6.68096C1.3675 5.2868 1.32469 4.12906 1.96977 3.24859Z" stroke={flatData(block.contentEditable,"topbarColor")} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                       <path d="M12.936 1.23685C16.4432 1.62622 19.2124 4.39253 19.6065 7.89874" stroke={flatData(block.contentEditable,"topbarColor")} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                       <path d="M12.936 4.59337C14.6129 4.92021 15.9231 6.23042 16.2499 7.90726" stroke={flatData(block.contentEditable,"topbarColor")} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                 </span>
                                            </div>
                                            <div className="tp-header-contact-content">
                                                <h5>{t('CallUs')} :</h5>
                                                <p><Link to={flatData(block.contentEditable,"hotline")}>{flatData(block.contentEditable,"hotline")}</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div id="header-sticky-2" className={`tp-header-sticky-area ${showHeader ? "header-sticky header-sticky-2" : ""}`}>
                <div className="container">
                    <div className="tp-mega-menu-wrapper p-relative">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                <div className="logo">
                                    <Link to={SUB_INDEX}>
                                        <img src={shop_data? SERVER_IMG_URL+"/"+shop_data.shop_key+"/logo/"+shop_data.logo : CLIENT + '/imgs/logo/logo.png'} alt="logo" style={{width : "120px"}}/>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 d-none d-md-block">
                                <div className="tp-header-sticky-menu main-menu menu-style-1">
                                    <nav id="mobile-menu">
                                        <ul>
                                            { pages.includes("Home") ?  <li><Link to={isEditor ? "#" : SUB_INDEX} style={{color :   window.location.pathname === SUB_INDEX ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_INDEX && "bold" }}>{t('Home')}</Link></li>: ""}
                                            {pages.includes("Shop") ?   <li><Link to={isEditor ? "#" : SUB_PRODUCTS} style={{color :   window.location.pathname === SUB_PRODUCTS ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_PRODUCTS && "bold" }}>{t('Shop')}</Link></li>: ""}
                                            {pages.includes("About") ?   <li><Link to={isEditor ? "#" : SUB_ABOUT} style={{color :   window.location.pathname === SUB_ABOUT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_ABOUT && "bold" }}>{t('About')}</Link></li>: ""}
                                            {pages.includes("Services") ?  <li><Link to={isEditor ? "#" : SUB_SERVICE} style={{color :   window.location.pathname === SUB_SERVICE ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_SERVICE && "bold" }}>{t('Service')}</Link></li>: ""}
                                            {pages.includes("Contact") ?  <li><Link to={isEditor ? "#" : SUB_CONTACT} style={{color :   window.location.pathname === SUB_CONTACT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_CONTACT && "bold" }}>{t('Contact')}</Link></li>: ""}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                <div className="tp-header-action d-flex align-items-center justify-content-end ml-50">

                                    <div className="tp-header-action-item d-none d-lg-block">
                                        <Link to={SUB_WISHLIST} className="tp-header-action-btn">
                                            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.239 18.8538C13.4096 17.5179 15.4289 15.9456 17.2607 14.1652C18.5486 12.8829 19.529 11.3198 20.1269 9.59539C21.2029 6.25031 19.9461 2.42083 16.4289 1.28752C14.5804 0.692435 12.5616 1.03255 11.0039 2.20148C9.44567 1.03398 7.42754 0.693978 5.57894 1.28752C2.06175 2.42083 0.795919 6.25031 1.87187 9.59539C2.46978 11.3198 3.45021 12.8829 4.73806 14.1652C6.56988 15.9456 8.58917 17.5179 10.7598 18.8538L10.9949 19L11.239 18.8538Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.26062 5.05302C6.19531 5.39332 5.43839 6.34973 5.3438 7.47501" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span className="tp-header-action-badge"
                                                  style={{background : flatData(block.contentEditable,'topbarColor')}}
                                            >{customWishlist?.wishlist?.length??0}</span>
                                        </Link>
                                    </div>
                                    <div className="tp-header-action-item">
                                        <Link to={SUB_CART} className="tp-header-action-btn cartmini-open-btn">
                                            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.48626 20.5H14.8341C17.9004 20.5 20.2528 19.3924 19.5847 14.9348L18.8066 8.89359C18.3947 6.66934 16.976 5.81808 15.7311 5.81808H5.55262C4.28946 5.81808 2.95308 6.73341 2.4771 8.89359L1.69907 14.9348C1.13157 18.889 3.4199 20.5 6.48626 20.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.34902 5.5984C6.34902 3.21232 8.28331 1.27803 10.6694 1.27803V1.27803C11.8184 1.27316 12.922 1.72619 13.7362 2.53695C14.5504 3.3477 15.0081 4.44939 15.0081 5.5984V5.5984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.70365 10.1018H7.74942" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M13.5343 10.1018H13.5801" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span className="tp-header-action-badge"
                                                  style={{background : flatData(block.contentEditable,'topbarColor')}}
                                            >{customCart?.carts.length}</span>
                                        </Link>
                                    </div>
                                    <div className="tp-header-action-item d-lg-none">
                                        <button type="button" className="tp-header-action-btn tp-offcanvas-open-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" viewBox="0 0 30 16">
                                                <rect x="10" width="20" height="2" fill="currentColor"/>
                                                <rect x="5" y="7" width="25" height="2" fill="currentColor"/>
                                                <rect x="10" y="14" width="20" height="2" fill="currentColor"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CartRight open={open} setOpen={setOpen} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth : state.subLogin,
    customCart : state.customCart,
    customWishlist : state.customWishlist
});
export default   compose(connect(mapStateToProps,{logUserout})) (withTranslation()(Header1));

