import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, numberWithCommas, SERVER_URL, sha1_random} from "../../../../helpers/utils";
import Paginate from "../../../../components/Paginate/Paginate";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import {Link} from "react-router-dom";
import CreateorUpdate from "./CreateorUpdate";
import ImageUpdate from "./ImageUpdate";

const AdminTemplate = ({t}) => {

    document.title = APPNAME+" :: "+t('Templates')

    const [currentTemplates,setCurrentTemplates] = useState([]);
    const [templates,setTemplates] = useState([])
    const [categories,setCategories] = useState([])

    useEffect(() => {
        getAllTemplate()
    },[])


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])

    const [loading, setLoading] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const [popOpen,setPopOpen] = useState(false)


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentTemplates(templates?.slice(indexOfFirstPost, indexOfLastPost))
    },[templates])

    useEffect(() => {
        setCurrentTemplates(templates?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])


    const getAllTemplate = async () => {
        await axios.get(SERVER_URL+"admin/templates",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setTemplates(response.data.template)
                setCategories(response.data.categories)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(templates?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const addTemplate = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editTemplate = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }



    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteTemplate'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}admin/template/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        getAllTemplate()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }





    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledTemplate') : t('SureToEnabledTemplate'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/template/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllTemplate()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentTemplates(templates?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = templates.filter(category => {
                return category.name.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentTemplates(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(templates?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentTemplates.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListTemplate'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}admin/template/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getAllTemplate()
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }

    const editPicture = (hash_key) => {
      setPopOpen(true)
        setUpdateData(hash_key)
    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Template')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Template')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Template')}</h5>
                                <div className="flex-shrink-0">
                                    <button className="btn btn-primary add-btn" onClick={event => addTemplate()}>
                                        <i className="ri-add-line align-bottom me-1"></i> {t('Add')}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">
                                    <div className="col-lg-4">
                                        {
                                            isCheck.length > 0 &&

                                            <>
                                                {
                                                    Gloading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                        <>
                                                            {
                                                                Gloading ?
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                                    :
                                                                    <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                            }
                                                        </>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col" style={{width: "50px"}}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="selectAll"
                                                       id="selectAll"
                                                       onChange={handleSelectAll}
                                                       checked={isCheckAll}
                                                />
                                            </div>
                                        </th>
                                        <th className="sort">{t('Name')}</th>
                                        <th className="sort">{t('Category')}</th>
                                        <th className="sort">{t('Free')}</th>
                                        <th className="sort">{t('Price')}</th>
                                        <th className="sort">Status</th>
                                        <th className="sort" style={{width : "15%"}}>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="7">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentTemplates?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentTemplates?.map((template,key)=>
                                                                    <tr key={key}>
                                                                        <th scope="row"><div className="form-check">
                                                                            <input className="form-check-input"
                                                                                   type="checkbox"
                                                                                   name={template.name}
                                                                                   id={template.id}
                                                                                   onChange={handleClick}
                                                                                   checked={isCheck.includes(template.id)}
                                                                            />
                                                                        </div>
                                                                        </th>
                                                                        <td>{template.name}</td>
                                                                        <td>{
                                                                            template?.[localStorage.getItem('I18N_LANGUAGE')]
                                                                        }</td>
                                                                        <td>{
                                                                            template.free ? <b>{t('Free')}</b> : <b>{t('Payant')}</b>
                                                                        }</td>
                                                                        <td>{numberWithCommas(template.price)} Frcs</td>
                                                                        <td>

                                                                            {  template.statut == 1 ?
                                                                                <b className="badge badge-soft-success">{t('Available')}</b>
                                                                                :
                                                                                <b className="badge badge-soft-warning">{t('Unavailable')}</b>
                                                                            }

                                                                        </td>
                                                                        <td className="priority">
                                                                            <ul className="list-inline  mb-0">

                                                                                <li className="list-inline-item">
                                                                                    <Link className="edit-item-btn"
                                                                                          to={`/admin/template/${template.hash_key}`}>
                                                                                        <i className="ri-apps-line text-info align-bottom me-2"></i>
                                                                                    </Link>
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                    <span className="edit-item-btn cursor-pointer" onClick={event => editPicture(template.hash_key)}>
                                                                                        <i className="ri-pencil-fill text-info align-bottom me-2"></i>
                                                                                    </span>
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                    {template.statut == 1
                                                                                        ? <span
                                                                                            className="edit-item-btn cursor-pointer"
                                                                                            onClick={event => toggleStatus(template.id, 2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                        :

                                                                                        <span
                                                                                            className="edit-item-btn cursor-pointer"
                                                                                            onClick={event => toggleStatus(template.id, 1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                    }
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                     <span
                                                                                         className="remove-item-btn cursor-pointer"
                                                                                         onClick={event => deleteModal(template.hash_key)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                        <td colSpan="7">
                                                            <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={templates?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>

            <CreateorUpdate
               created={getAllTemplate}
               open={open}
               setOpen={setOpen}
               updateData={updateData}
               isEdit={isEdit}
               categories={categories}
            />

            <ImageUpdate open={popOpen} setOpen={setPopOpen} created={getAllTemplate} t={t}  updateData={updateData} />

        </AdminLayout>
    );
}

export default withTranslation()(AdminTemplate)
