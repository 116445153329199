import React from 'react';
import {CalculateDiscount, CLIENT, numberWithCommas, SERVER_IMG_URL} from "../../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import IconShoppingCart from "../../../../../components/Icons/IconShoppingCart";
import IconEye from "../../../../../components/Icons/IconEye";
import IconHeart from "../../../../../components/Icons/IconHeart";
import {secondCalculDicount} from "../../../../../themes/utils/utils";
import {toast} from "sonner";
import {compose} from "redux";
import {connect} from "react-redux";
import {CustomerAddCart, CustomerDecrease, CustomerIncrease} from "../../../../../store/Subdomain/CustomerCart/action";
import moment from "moment/moment";
import {addWishItem} from "../../../../../store/Subdomain/Wishlist/action";

const DefaultCard = ({server,item,setItem,setOpen,t,color,CustomerAddCart,addWishItem}) => {


    const addWishlist = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
        const data ={
            id:item.id,
            category : item.category,
            picture : server+JSON.parse(item.pictures)[0],
            name:item.title,
            discount : item.discount,
            price:item.price,
            hash_key:item.hash_key
        }

        addWishItem(data)

        toast("Notification",{
            description: t('AddToWishlist') +"\n"+ moment(Date.now()).fromNow(),
            icon : <i className="ri-check-double-fill"></i>
        });

    }
   else{
        toast.error("Notification",{
            description: t('RuptureStockSimple'),
            icon : <i className="ri-close-fill"></i>
        })
    }

    }


    const addToCart = (item) => {
       if (item?.stock && parseInt(item?.stock) > 0){
           const data = {
               id:item.id,
               qty : 1,
               category : item.category,
               picture : server+JSON.parse(item.pictures)[0],
               name:item.title,
               price: CalculateDiscount(item?.price,item?.discount)
           }
           CustomerAddCart(data)

           toast("Notification",{
               description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
               icon : <i className="ri-check-double-fill"></i>
           })
       }
       else{
           toast.error("Notification",{
               description: t('RuptureStockSimple'),
               icon : <i className="ri-close-fill"></i>
           })
       }
    }


    return (
        <div className="tp-product-item p-relative transition-3 mb-25">
            <div className="tp-product-thumb p-relative fix m-img">

                <Link to={"/product/detail/"+item.hash_key}>
                    <img src={server+JSON.parse(item.pictures)[0]}  alt=""/>
                </Link>

                {
                    item.badge ?
                        <div className="tp-product-badge">
                            <span className={`product-${item.badge.toLowerCase()}`}>{t(item.badge)}</span>
                        </div>
                        :
                        ""
                }


                <div className="tp-product-action">
                    <div className="tp-product-action-item d-flex flex-column">
                        <button type="button" className="tp-product-action-btn tp-product-add-cart-btn"
                         onClick={event => addToCart(item)}
                        >
                            <IconShoppingCart />
                            <span className="tp-product-tooltip">{t('BuyNow')}</span>
                        </button>
                        <button type="button" className="tp-product-action-btn tp-product-quick-view-btn"
                                onClick={event => {setItem(item); setOpen(true)}}
                        >
                            <IconEye />

                            <span className="tp-product-tooltip">{t('QuickView')}</span>
                        </button>
                        <button type="button" onClick={event => addWishlist(item)} className="tp-product-action-btn tp-product-add-to-wishlist-btn">
                            <IconHeart />
                            <span className="tp-product-tooltip">{t('AddToWishlist')}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="tp-product-content">
                <div className="tp-product-category">
                    <a href="#">{item.isSubcategory ? item.subname : item.category}</a>
                </div>
                <h3 className="tp-product-title">
                    <Link
                        to={"/product/detail/"+item.hash_key}
                    >{item.title}</Link>
                </h3>
                <div className="tp-product-rating d-flex align-items-center">
                    <div className="tp-product-rating-icon">
                        {
                            item.rating ?
                                <>
                                    {
                                        [...Array(parseInt(item.rating))].map((item,key) => (
                                            <span key={key}><i className="fa-solid fa-star"></i></span>
                                        ))
                                    }
                                </>
                                :
                                ""
                        }
                        <small className="text-muted">{item.short_description}</small>
                    </div>

                </div>
                {
                    parseInt(item.discount) > 0 ?
                        <div className="tp-product-price-wrapper">
                            <span className="tp-product-price old-price">{numberWithCommas(item.price)}  Francs CFA</span>
                            <span className="tp-product-price new-price pl-10" style={{color : color}}>{numberWithCommas(secondCalculDicount(parseInt(item.price),parseInt(item.discount)))} Francs CFA</span>
                        </div>
                        :

                        <div className="tp-product-price-wrapper">
                            <span className="tp-product-price new-price" style={{color : color}}>{numberWithCommas(item.price)}  Francs CFA</span>
                        </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    customCart : state.customCart
});

export default compose(withRouter, connect(mapStateToProps,{CustomerAddCart,CustomerIncrease,CustomerDecrease,addWishItem})) (withTranslation()(DefaultCard))

