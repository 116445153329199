import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {
    LINK_ADMIN_DASHBOARD,
    LINK_ADMIN_PLUGINS,
    LINK_ADMIN_CATEGORIES,
    LINK_ADMIN_PAYMENTS,
    LINK_ADMIN_PRICING,
    LINK_ADMIN_SHOPS,
    LINK_ADMIN_SUPPORT,
    LINK_ADMIN_PARAMS,
    LINK_ADMIN_TEMPLATES,
    LINK_ADMIN_MODULES,
    LINK_ADMIN_STORAGE,
    LINK_ADMIN_CONTACT,
    LINK_ADMIN_PAGES,
    LINK_ADMIN_BLOG,
    LINK_ADMIN_USERS,
    LINK_ADMIN_DEMO,
    LINK_ADMIN_COMMERCIAUX,
    LINK_ADMIN_PROMO,
    LINK_ADMIN_EVENTS, LINK_ADMIN_PERMISSIONS,

} from '../../routes/type'
import {withTranslation} from "react-i18next";
import {ActiveMenu} from "../../helpers/utils";
import Authorization from '../../components/Authorization'
import AdminAuthorization from '../../components/AdminAuthorization'

const AdminSidebar = ({t,auth}) => {


    useEffect(() => {

        const links = document.querySelectorAll(   `#navbar-nav  a`)


        for (let i =0;i < links.length;i++) {
            if (links[i].getAttribute('href') === window.location.pathname) {

                if (links[i].parentElement.parentElement.parentElement.getAttribute('id')){
                    const doc = document.getElementById(links[i].parentElement.parentElement.parentElement.getAttribute('id'))
                    const parent = document.getElementById(links[i].parentElement.parentElement.parentElement.getAttribute('id')+'-parent')
                    parent.classList.remove("collapsed")
                    document.getElementById(links[i].parentElement.parentElement.parentElement.getAttribute('id')+'-parent').setAttribute("aria-expanded", "true");
                    doc.classList.add("show")
                }

            }
        }


    }, [window.location]);


    const toggleCollapsed = (id) => {
        const doc = document.getElementById(id)
        const parent = document.getElementById(id+'-parent')

        if(parent.classList.contains("collapsed")){
            parent.classList.remove("collapsed")
            document.getElementById(id+'-parent').setAttribute("aria-expanded", "true");
            doc.classList.add("show")
        }else{
            parent.classList.add("collapsed")
            document.getElementById(id+'-parent').setAttribute("aria-expanded", "false");
            doc.classList.remove("show")
        }

    }



    return (
        <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title"><span data-key="t-menu">Menu</span></li>
            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_DASHBOARD)}`} to={LINK_ADMIN_DASHBOARD}>
                    <i className="ri-dashboard-2-line"></i> <span>{t('Dashboard')}</span>
                </Link>
            </li>


            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-pages">{t('App')}</span></li>


            <AdminAuthorization single={false} module="messages" bloc={['messages','blogs', 'stockages', 'modules']} >
            <li className="nav-item">
                <a className="nav-link menu-link collapsed" id="utils-parent"
                   onClick={event => toggleCollapsed('utils')}
                   data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="shop">
                    <i className="ri-stack-line"></i> <span data-key="t-apps">{t('Utils')}</span>
                </a>
                <div className="menu-dropdown collapse" id="utils">
                    <ul className="nav nav-sm flex-column">
                        <AdminAuthorization single={true}  module="messages">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_CONTACT}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_CONTACT)}`}>
                                <span>{t('Messages')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true}  module="blogs">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_BLOG} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_BLOG)}`}>
                                <span>{t('Blog')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true}  module="stockages">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_STORAGE}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_STORAGE)}`}>
                                <span>{t('Stockages')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true}  module="modules">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_MODULES}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_MODULES)}`}>
                                <span>{t('Modules')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>
                    </ul>
                </div>
            </li>
            </AdminAuthorization>

            <AdminAuthorization single={false} module="events" bloc={['events','promotions', 'commercial']} >
            <li className="nav-item">
                <a className="nav-link menu-link collapsed" id="selling-parent"
                   onClick={event => toggleCollapsed('selling')}
                   data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="selling">
                    <i className="ri-exchange-dollar-fill"></i> <span data-key="t-apps">{t('Promotions')}</span>
                </a>
                <div className="menu-dropdown collapse" id="selling">
                    <ul className="nav nav-sm flex-column">
                        <AdminAuthorization single={true}  module="commercial">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_COMMERCIAUX}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_COMMERCIAUX)}`}>
                                <span>{t('Commerciaux')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true}  module="promotions">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_PROMO}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_PROMO)}`}>
                                <span>{t('Promotions')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true}  module="events">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_EVENTS}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_EVENTS)}`}>
                                <span>{t('Events')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>
                    </ul>
                </div>
            </li>
            </AdminAuthorization>


            <AdminAuthorization single={false} module="events" bloc={['events','promotions', 'commercial']} >
            <li className="nav-item">
                <a className="nav-link menu-link collapsed" id="list-parent"
                   onClick={event => toggleCollapsed('list')}
                   data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="list">
                    <i className="ri-stack-line"></i> <span data-key="t-apps">{t('Listes')}</span>
                </a>
                <div className="menu-dropdown collapse" id="list">
                    <ul className="nav nav-sm flex-column">
                        <AdminAuthorization single={true}  module="shops">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_SHOPS}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_SHOPS)}`}>
                                <span>{t('Shops')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true}  module="payments">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_PAYMENTS}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_PAYMENTS)}`}>
                                <span>{t('Payments')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true}  module="demo">
                        <li className="nav-item">
                            <Link to={LINK_ADMIN_DEMO}
                                  className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_DEMO)}`}>
                                <span>{t('DemoAccount')}</span>
                            </Link>
                        </li>
                        </AdminAuthorization>
                    </ul>
                </div>
            </li>
            </AdminAuthorization>

            <AdminAuthorization single={true}  module="support">
            <li className="nav-item">
                <Link to={LINK_ADMIN_SUPPORT} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_SUPPORT)}`}>
                    <i className="bx bx-support"></i> <span>{t('Support')}</span>
                </Link>
            </li>
            </AdminAuthorization>

            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-pages">{t('Register')}</span>
            </li>


            <AdminAuthorization single={true}  module="categories">
            <li className="nav-item">
                <Link to={LINK_ADMIN_CATEGORIES} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_CATEGORIES)}`}>
                    <i className="bx bx-border-all"></i> <span>{t('Categories')}</span>
                </Link>
            </li>
            </AdminAuthorization>


            <AdminAuthorization single={true}  module="pages">
            <li className="nav-item">
                <Link to={LINK_ADMIN_PAGES} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_PAGES)}`}>
                    <i className="ri-list-ordered"></i> <span>{t('Pages')}</span>
                </Link>
            </li>
            </AdminAuthorization>


            <AdminAuthorization single={true}  module="templates">
            <li className="nav-item">
                <Link to={LINK_ADMIN_TEMPLATES} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_TEMPLATES)}`}>
                    <i className="ri-pencil-line"></i> <span>{t('Templates')}</span>
                </Link>
            </li>
            </AdminAuthorization>


            <AdminAuthorization single={false} module="users" bloc={['collaborater','permissions','plugins','tarifications','params']} >
            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-pages">{t('Config')}</span></li>
            </AdminAuthorization>

            <AdminAuthorization single={true}  module="plugins">
            <li className="nav-item">
                <Link to={LINK_ADMIN_PLUGINS} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_PLUGINS)}`}>
                    <i className="bx bx-chip"></i> <span>{t('Plugins')}</span>
                </Link>
            </li>
            </AdminAuthorization>


            <AdminAuthorization single={true}  module="tarifications">
            <li className="nav-item">
                <Link to={LINK_ADMIN_PRICING} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_PRICING)}`}>
                    <i className="bx bxs-planet"></i> <span>{t('Pricing')}</span>
                </Link>
            </li>
            </AdminAuthorization>


            <AdminAuthorization single={false} module="users" bloc={['collaborater','permissions']} >
            <li className="nav-item">
                <a className="nav-link menu-link collapsed" id="users-parent"
                   onClick={event => toggleCollapsed('users')}
                   data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="users">
                    <i className="ri-user-add-fill"></i> <span data-key="t-apps">{t('Users')}</span>
                </a>
                <div className="menu-dropdown collapse" id="users">
                    <ul className="nav nav-sm flex-column">
                        <AdminAuthorization single={true} module="permissions">
                            <li className="nav-item">
                                <Link className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_PERMISSIONS)}`}
                                      to={LINK_ADMIN_PERMISSIONS}>
                                    <span>{t('Permissions')}</span>
                                </Link>
                            </li>
                        </AdminAuthorization>

                        <AdminAuthorization single={true} module="collaborater">
                            <li className="nav-item">
                                <Link className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_USERS)}`}
                                      to={LINK_ADMIN_USERS}>
                                    <span>{t('Collaborater')}s</span>
                                </Link>
                            </li>
                        </AdminAuthorization>
                    </ul>
                </div>
            </li>
            </AdminAuthorization>


            <AdminAuthorization single={true} module="params">
            <li className="nav-item">
                <Link to={LINK_ADMIN_PARAMS} className={`nav-link menu-link ${ActiveMenu(LINK_ADMIN_PARAMS)}`}>
                    <i className="ri-settings-3-line"></i> <span>{t('Params')}</span>
                </Link>
            </li>
            </AdminAuthorization>


        </ul>

    );
}

export default withTranslation()(AdminSidebar)
