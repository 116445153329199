import React, {useEffect, useRef, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {attachTokenToHeaders, format_date, SERVER_URL, sha1_random} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import Chat from "../../../../components/Chat/Chat";
import socket from '../../../../helpers/services/BroadCasts'
import notificationSound from '../../../../assets/notifications/message.wav'

const Discussion = ({t,auth}) => {



    const [bg,setBg] = useState("bg-info")
    const [loading,setLoading] = useState(true)
    const [mloading,setMloading] = useState(false)
    const [detail, setDetail] = useState(null);
    const [messages, setmessages] = useState([]);
    const [answer, setAnswer] = useState("");
    const [socketConnected, setSocketConnected] = useState(false);


    const dummy = useRef();



    useEffect(() => {
      socket.emit("setup",auth?.me);
      socket.on('connected',()=> {
          setSocketConnected(true)
      } )

    }, []);



    useEffect(() => {
        socket.on("support_message-"+auth?.me.id, data => {
            if (data.toUser_id == auth.me.id) {
                getSupportInfo(window.location.pathname.split('/').pop())
                const  audio = new Audio(notificationSound)
                audio.play().catch(function (error) {
                    console.log(error)});
                toast.info(t('NewMessage'))
            }else {
                getSupportInfo(window.location.pathname.split('/').pop())
            }

        });

        return () => socket.disconnect();
    }, []);


    useEffect(()=>{
        getSupportInfo(window.location.pathname.split('/').pop())
    },[window.location.pathname.split('/').pop()])



    useEffect(()=>{
        if (detail?.priority == 1)  setBg("bg-danger")
        if (detail?.priority == 2)  setBg("bg-warning")
        if (detail?.priority == 3)  setBg("bg-info")
    },[detail])

    const getSupportInfo = async (hash_key) => {
        await axios.get(SERVER_URL+"support/"+hash_key,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setDetail(response.data.detail)
                setmessages(response.data.messages)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const handleChatmessage = async (e) => {

        e.preventDefault()


        let  userId = auth?.me?.id
        let  to = auth?.me.id

        if (detail.customer_id === auth?.me?.id){
            userId = auth?.me?.id
            to = detail?.support_id
        } else{
            userId = detail.customer_id
            to = auth?.me?.id
        }

        let  nowTime = new Date()
        let type = "TEXT"

        const data = {
            message : answer,
            user_id : detail.customer_id === auth?.me?.id ? auth?.me?.id : detail?.support_id,
            shop_id : detail?.shop_id,
            ticket_hash : detail?.hash_key,
            toUser_id : detail.customer_id === auth?.me?.id ? detail?.support_id : detail.customer_id,
            type,
            channel_key : window.location.pathname.split('/').pop(),
            lng : localStorage.getItem('I18N_LANGUAGE')
        }
        setMloading(true)

        console.log(data)

        await axios.post(SERVER_URL+"message/add",data,attachTokenToHeaders()).then((response)=>{
            setMloading(false)
            if (response.data.succeed){
                setAnswer("")
                getSupportInfo(window.location.pathname.split('/').pop())

                    dummy.current.scrollIntoView({ behavior: 'smooth' });

            }else{
                toast.error(t(response.data.message))
            }
        }).catch((error)=>{
            setMloading(false)
        })



    }

    return (
        <AdminLayout>

            {
                loading ?
                    <div className="card">
                        <div className="card-body">
                            <p className="text-center p-t-150 p-b-150">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                            </p>
                        </div>
                    </div>

                    :

                    <>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card mt-n4 mx-n4 mb-n5">
                                    <div className={bg}>
                                        <div className="card-body pb-4 mb-5">
                                            <div className="row">
                                                <div className="col-md">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-auto">
                                                            <div className="avatar-md mb-md-0 mb-4">
                                                                <div className="avatar-title bg-white rounded-circle">
                                                                    <i className="mdi mdi-ticket text-muted fs-36"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md">
                                                            <h4 className="fw-semibold text-white" id="ticket-title">#{detail?.ticket_id} - {detail?.title}</h4>
                                                            <div className="hstack gap-3 flex-wrap">
                                                                <div className="text-white">{t("CreatedAt")} : <span className="fw-medium " id="create-date">{format_date(detail?.created_at)}</span></div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row">
                            <div className={detail?.customer_id === auth?.me.id ? "offset-lg-2 col-xxl-8" : "col-xxl-9"} >
                                <div className="card">
                                    <div className="card-body p-4">
                                        <h6 className="fw-semibold text-uppercase mb-3"> {t('Subject')}</h6>
                                        <p className="text-muted">{detail?.description}</p>

                                    </div>

                                    <h5 className="card-title" style={{padding : "20px"}}>{t('Chat')}</h5>

                                    <div className="card-body p-4" style={{background : "#f4f4f4"}}>
                                        <div   className="px-3 mx-n3 chat__box">

                                            {
                                               messages?.length > 0 ?

                                                   <>
                                                   {
                                                       messages.map((message,key)=>
                                                         <div key={key}>
                                                             <Chat auth={auth} data={message}  />
                                                         </div>
                                                       )
                                                   }
                                                   </>
                                                   : ""
                                            }
                                            <span ref={dummy}></span>


                                        </div>

                                    </div>

                                    <div style={{padding : "20px"}}>
                                        {
                                            detail?.support_id != 0 ?
                                                <form  onSubmit={handleChatmessage}  className="mt-3">
                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <label htmlFor="exampleFormControlTextarea1" className="form-label">{t("Answer")}</label>
                                                            <textarea className="form-control bg-light border-dark" onChange={event => setAnswer(event.target.value)} value={answer} id="exampleFormControlTextarea1" rows="3" placeholder={t('EnterComment')}></textarea>
                                                        </div>
                                                        <div className="col-lg-12 text-end">

                                                            <button type="submit" className="btn btn-success" disabled={mloading}>
                                                                {
                                                                    mloading ?
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                                        :
                                                                        <span>{t('Send')}</span>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                :
                                                <div className="alert alert-danger m-t-100" role="alert">
                                                    <br/>
                                                    <p>{t('TicketIsNotAssigned')}</p>
                                                </div>
                                        }
                                    </div>

                                </div>

                            </div>

                            <div className="col-xxl-3" style={{display : detail?.customer_id === auth?.me.id ? "none" : "block"}}>
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Ticket Details</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table table-borderless align-middle mb-0">
                                                <tbody>
                                                <tr>
                                                    <td className="fw-medium">Ticket</td>
                                                    <td>#{detail?.ticket_id}</td>
                                                </tr>

                                                <tr>
                                                    <td className="fw-medium">Status:</td>
                                                    <td>
                                                        <select className="form-select" id="t-status" defaultValue={detail?.statut}>
                                                            <option value>Status</option>
                                                            <option value="1">{t('New')}</option>
                                                            <option value="2">{t('Open')}</option>
                                                            <option value="3">{t('Inprogress')}</option>
                                                            <option value="4">{t('Closed')}</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">{t('Priority')}</td>
                                                    <td>
                                                        {detail?.priority == 1 ? <span className="badge bg-danger text-uppercase">{t('High')}</span> : null}
                                                        {detail?.priority == 2 ? <span className="badge bg-warning text-uppercase">{t('Medium')}</span> : null}
                                                        {detail?.priority == 3 ? <span className="badge bg-info text-uppercase">{t('Low')}</span> : null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">{t('CreatedAt')}</td>
                                                    <td id="c-date">{format_date(detail?.created_at)}</td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </>
            }

        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});

export default   compose(connect(mapStateToProps)) (withTranslation()(Discussion));
