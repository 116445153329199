import axios from 'axios';

import {
    LOGIN_SHOP_SUCCESS,
    LOGIN_SHOP_FAIL,
    LOGIN_SHOP_LOADING,
    ME_LOADING,
    ME_SUCCESS,
    ME_FAIL,
    ADD_PASS_LOADING,
    ADD_PASS_FAIL,
    ADD_PASS_SUCCESS,
    LOGOUT_SUCCESS
} from './type';
import {attachTokenToHeaders, SERVER_URL} from "../../helpers/utils";
import Cookies from 'js-cookie'

export const loadMe = () => async (dispatch) => {
    dispatch({ type: ME_LOADING });
    if (Cookies.get('auth-token')){
        try {
            const options = attachTokenToHeaders();
            const response = await axios.get(SERVER_URL+'user/me', options);
            dispatch({
                type: ME_SUCCESS,
                payload: {
                    token : Cookies.get('auth-token'),
                    me: response.data.user,
                    shop: response.data.shop,
                    planDays: response.data.planDays,
                    plan: response.data.plan,
                    sidebar_n_storage: response.data.sidebar_n_storage,
                    plugins: response.data.plugins,
                    permissions: response.data.permissions,
                },
            });
        } catch (err) {
            dispatch({
                type: ME_FAIL,
                payload: { error: err },
            });
        }
    }else{
        dispatch({ type: LOGOUT_SUCCESS });
    }
};

export const loginUser = (formData) => async (dispatch) => {
    dispatch({ type: LOGIN_SHOP_LOADING });
    try {
       await axios.post(SERVER_URL+'auth/login', formData).then((response)=>{
           if (response.data.succeed){
               dispatch({
                   type: LOGIN_SHOP_SUCCESS,
                   payload: {
                       token: response.data.token,
                       me: response.data.user,
                       shop: response.data.shop,
                       planDays: response.data.planDays,
                       plan: response.data.plan,
                       sidebar_n_storage: response.data.sidebar_n_storage,
                       plugins: response.data.plugins,
                       permissions: response.data.permissions,
                   },
               });

           }
           else{
               dispatch({
                   type: LOGIN_SHOP_FAIL,
                   payload: { error : response.data.message },
               });
           }
       })



    } catch (err) {
        dispatch({
            type: LOGIN_SHOP_FAIL,
            payload: { error: err.message },
        });
    }
};

export const addNewPassword = (formData) => async (dispatch) => {
    dispatch({ type: ADD_PASS_LOADING });
    try {
       await axios.post(SERVER_URL+'add/password', formData).then((response)=>{
           if (response.data.succeed){
               dispatch({
                   type: ADD_PASS_SUCCESS,
                   payload: { message : response.data.message, token: response.data.token, me: response.data.me, shop: response.data.shop, planDays: response.data.planDays, plan: response.data.plan, sidebar_n_storage: response.data.sidebar_n_storage},
               });

               dispatch(loadMe);
           }
           else{
               dispatch({
                   type: ADD_PASS_FAIL,
                   payload: { error : response.data.message },
               });
           }
       })



    } catch (err) {
        dispatch({
            type: ADD_PASS_FAIL,
            payload: { error: err.message },
        });
    }
};

export const logout = () => async (dispatch) => {
    dispatch({ type: LOGOUT_SUCCESS });
};


