import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {
    APPNAME,
    attachTokenToHeaders,
    Capitalize,
    format_date_complet,
    numberWithCommas, SERVER_IMG_URL,
    SERVER_URL
} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";
import avatar from "../../../../assets/admin/images/avatar-blank.png"
import {Link} from "react-router-dom";
import ChooseDeliver from "./ChooseDeliver";
import CustomMap from '../../../../components/CustomMap/CustomMap'
import ShareLocationOnMap from '../../../../components/Share/ShareLocationOnMap'


const OrderDetail = ({t,auth}) => {

    document.title = APPNAME + " :: "+ t('OrderDetail')
    const [detail,setDetail] = useState(null)
    const [Gloading,setGloading] = useState(true)
    const [open,setOpen] = useState(false)

    const [shipping,setShipping] = useState(null)




    const options = [
        {status : 1, name : "Pending"},
        {  status : 4, name : "Delivered"},
    ]

    const [status,setStatus] = useState(0)


    useEffect(()=>{
        getOrderSingle()
    },[])

    const getOrderSingle = async () => {
        setGloading(true)
        await axios.get(SERVER_URL+"order/single/"+window.location.pathname.split('/').pop(),attachTokenToHeaders()).then((response)=>{
            setGloading(false)
            if (response.data.succeed){
                setDetail(response.data.order[0])
                setShipping(response.data?.shipping[0])
                setStatus(response.data.order[0].delivery_status)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }





    const calculateSubtotal = () => {
        let price = 0
        JSON.parse(detail?.cart_content)?.map((item) => price =  price + (item.price * item.qty)    )
        return price
    }

    const ToggleOrderStatus = async (value) => {
         setStatus(value)
        await axios.get(SERVER_URL+"order/toggle/"+value+"/"+window.location.pathname.split('/').pop(),attachTokenToHeaders()).then((response)=>{
            setGloading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                getOrderSingle()
            }else{
                toast.error(t(response.data.message))
            }
        })
    }



    return (
       <AdminLayout>
           <div className="row">
               <div className="col-12">
                   <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                       <h4 className="mb-sm-0">{t('OrderDetail')} #{detail?.order_id}</h4>

                       <div className="page-title-right">
                           <ol className="breadcrumb m-0">
                               <li className="breadcrumb-item"><span>{APPNAME}</span></li>
                               <li className="breadcrumb-item"><span>{t('Orders')}</span></li>
                               <li className="breadcrumb-item active">#{detail?.order_id}</li>
                           </ol>
                       </div>

                   </div>
               </div>
           </div>

           {
               Gloading  ?
                   <div className="col-lg-12">
                       <div className="card">
                           <div className="card-body">
                               <div className="text-center p-t-180 p-b-180">
                                   <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                               </div>
                           </div>
                       </div>
                   </div>
                   :

                <div className="row">
               <div className="col-xl-9">
                   <div className="card">
                       <div className="card-header">
                           <div className="d-flex align-items-center">
                               <h5 className="card-title flex-grow-1 mb-0">{t("Order")} #{detail?.order_id}</h5>

                               {
                                   (!detail?.shipping_status || (detail?.statut !== 6 && !auth?.plugins.some(e => e.name === "delivery"))) && (
                                       <div className="flex-shrink-1 p-r-10">
                                           <select
                                               name=""
                                               className="form-select form-select-sm"
                                               value={status}
                                               onChange={event => ToggleOrderStatus(event.target.value)}
                                               id=""
                                           >
                                               <option value="" disabled>{t('ChangeStatus')}</option>
                                               {options.map((op, key) => (
                                                   <option key={key} value={op.status}>{t(op.name)}</option>
                                               ))}
                                           </select>
                                       </div>
                                   )
                               }
                               {
                                   (detail?.shipping_status && parseInt(detail?.shipping_status) === 1)
                                   && (detail?.statut && parseInt(detail?.statut) === 1) &&
                                   auth?.plugins.some(e => e.name === "delivery") &&
                                   (detail?.delivery_status && parseInt(detail?.delivery_status) === 1)
                                       ? (
                                       <button
                                           className="btn btn-sm btn-info m-r-10"
                                           onClick={() => setOpen(true)}
                                       >
                                           {t('Shipping')}
                                       </button>
                                   ) : ""
                               }

                               <div className="flex-shrink-0">
                                   <Link  className="btn btn-success btn-sm" to={`/store/invoice/${detail?.order_id}`}>
                                       <i className="ri-download-2-fill align-middle me-1"></i>{t('Invoice')}
                                   </Link>
                               </div>
                           </div>
                       </div>
                       <div className="card-body">
                           <div className="table-card">
                               <table className="table table-nowrap align-middle table-borderless mb-0">
                                   <thead className="table-light text-muted">
                                   <tr>
                                       <th scope="col">{t('Product')}</th>
                                       <th scope="col">{t('Price')}</th>
                                       <th scope="col">{t('Quantity')}</th>
                                       <th scope="col">{t('Category')}</th>
                                       <th scope="col" className="text-end">Total</th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                   {
                                       JSON.parse(detail?.cart_content)?.map((item,key) =>

                                           <tr key={key}>
                                               <td>
                                                   <div className="d-flex">
                                                       <div className="flex-shrink-0 avatar-sm bg-light rounded p-1">
                                                           <img src={item.picture} alt="" className="img-fluid d-block" style={{width : "60px"}}/>
                                                       </div>
                                                       <div className="flex-grow-1 ms-3">
                                                           <h5 className="fs-15">
                                                               <a href="#" className="link-primary">{item.name}</a>
                                                           </h5>
                                                       </div>
                                                   </div>
                                               </td>
                                               <td>{numberWithCommas(item.price)} Frcs</td>
                                               <td>{item.qty}</td>
                                               <td>{item.category}</td>
                                               <td className="fw-medium text-end">
                                                   {numberWithCommas(item.price * item.qty)} Frcs
                                               </td>
                                           </tr>

                                       )
                                   }



                                   <tr className="border-top border-top-dashed">
                                       <td colSpan="3"></td>
                                       <td colSpan="2" className="fw-medium p-0">
                                           <table className="table table-borderless mb-0">
                                               <tbody>
                                               <tr className="border-top">
                                                   <td>Sub Total :</td>
                                                   <td className="text-end">{numberWithCommas(calculateSubtotal(true))} Frcs</td>
                                               </tr>
                                               {
                                                   parseInt(detail?.discount_percent) ?

                                                       <tr className="border-top">
                                                           <td>{t('Discount')} : ({detail?.coupon})</td>
                                                           <td className="text-end text-danger"> - {numberWithCommas(detail?.discount_percent)} Frcs</td>
                                                       </tr>
                                                       : null}


                                               {
                                                   parseInt(detail?.shipping_status) == 1 ?
                                               <tr className="border-top">
                                                   <td>{t('ShippingCharge')} :</td>
                                                   <td className="text-end">{numberWithCommas(detail?.shipping_charge)??0} Frcs</td>
                                               </tr>
                                                       : null}

                                               {/*<tr className="border-top">*/}
                                               {/*    <td>{t('Tax')} :</td>*/}
                                               {/*    <td className="text-end">{detail?.tax??0} Frcs</td>*/}
                                               {/*</tr>*/}
                                               <br/>
                                               <tr className="border-top border-top-dashed" >
                                                   <th scope="row">Total :</th>
                                                   <th className="text-end">{numberWithCommas(parseInt(detail?.total_amount) + parseInt(detail?.shipping_charge))} Frcs</th>
                                               </tr>
                                               <br/>
                                               </tbody>
                                           </table>
                                       </td>
                                   </tr>
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>

                   {
                       shipping ?

                          <div>
                              {
                                  (detail?.shipping_status && parseInt(detail?.shipping_status) === 1)
                                  && (detail?.statut && parseInt(detail?.statut) === 1) &&
                                  auth?.plugins.some(e => e.name === "delivery") &&
                                  (detail?.delivery_status && parseInt(detail?.delivery_status) === 1)
                                  && (
                                      <div className="card">
                                          <div className="card-header">
                                              <div className="d-sm-flex align-items-center">
                                                  <h5 className="card-title flex-grow-1 mb-0">{t('OrderStatus')}</h5>
                                              </div>
                                          </div>
                                          <div className="card-body">
                                              <div className="profile-timeline">
                                                  <div className="accordion accordion-flush" id="accordionFlushExample">
                                                      <div className="accordion-item border-0">
                                                          <div className="accordion-header" id="headingOne">
                                                              <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                  <div className="d-flex align-items-center">
                                                                      <div className="flex-shrink-0 avatar-xs">
                                                                          <div className="avatar-title bg-success rounded-circle">
                                                                              <i className="ri-shopping-bag-line"></i>
                                                                          </div>
                                                                      </div>
                                                                      <div className="flex-grow-1 ms-3">
                                                                          <h6 className="fs-15 mb-0 fw-semibold">{t('OrderPlaced')}</h6>
                                                                      </div>
                                                                  </div>
                                                              </a>
                                                          </div>
                                                          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                              <div className="accordion-body ms-2 ps-5 pt-0">
                                                                  <h6 className="mb-1">{t('OrderPassed')}.</h6>
                                                                  <p className="text-muted">{format_date_complet(shipping?.placed)}</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="accordion-item border-0">
                                                          <div className="accordion-header" id="headingTwo">
                                                              <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse"
                                                                 href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                  <div className="d-flex align-items-center">
                                                                      <div className="flex-shrink-0 avatar-xs">
                                                                          <div className={`avatar-title rounded-circle ${shipping?.packed ? "bg-success" : "bg-light text-success"}`}>
                                                                              <i className="mdi mdi-gift-outline"></i>
                                                                          </div>
                                                                      </div>
                                                                      <div className="flex-grow-1 ms-3">
                                                                          <h6 className="fs-15 mb-1 fw-semibold">{t('Packed')}</h6>
                                                                      </div>
                                                                  </div>
                                                              </a>
                                                          </div>
                                                          {
                                                              shipping?.packed ?
                                                                  <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                      <div className="accordion-body ms-2 ps-5 pt-0">
                                                                          <h6 className="mb-1">{t('ItemHasBeenTaken')} <b className="text-primary">({shipping?.firstname} {shipping?.lastname})</b></h6>
                                                                          <p className="text-muted mb-0">{format_date_complet(shipping?.packed)}</p>
                                                                      </div>
                                                                  </div>
                                                                  : ""
                                                          }
                                                      </div>

                                                      <div className="accordion-item border-0">
                                                          <div className="accordion-header" id="headingFour">
                                                              <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                  <div className="d-flex align-items-center">
                                                                      <div className="flex-shrink-0 avatar-xs">
                                                                          <div className={`avatar-title rounded-circle ${shipping?.onway ? "bg-success" : "bg-light text-success"}`}>
                                                                              <i className="ri-takeaway-fill"></i>
                                                                          </div>
                                                                      </div>
                                                                      <div className="flex-grow-1 ms-3">
                                                                          <h6 className="fs-15 mb-0 fw-semibold">{t('OnTheWay')}</h6>
                                                                      </div>
                                                                  </div>
                                                              </a>
                                                          </div>
                                                          {
                                                              shipping?.onway ?
                                                                  <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                      <div className="accordion-body ms-2 ps-5 pt-0">
                                                                          <h6 className="fs-14">{t('Delivere')} : <b>{shipping?.firstname} {shipping?.lastname}</b></h6>
                                                                          <h6 className="mb-1">{t('ItemOnWay')}</h6>
                                                                          <p className="text-muted mb-0">{format_date_complet(shipping?.onway)}</p>
                                                                      </div>
                                                                  </div>

                                                                  : ""
                                                          }
                                                      </div>
                                                      <div className="accordion-item border-0">
                                                          <div className="accordion-header" id="headingFive">
                                                              <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFile" aria-expanded="false">
                                                                  <div className="d-flex align-items-center">
                                                                      <div className="flex-shrink-0 avatar-xs">
                                                                          <div
                                                                              className={`avatar-title rounded-circle ${shipping?.delivered ? "bg-success" : "bg-light text-success"}`}>
                                                                              <i className="mdi mdi-package-variant"></i>
                                                                          </div>
                                                                      </div>
                                                                      <div className="flex-grow-1 ms-3">
                                                                          <h6 className="fs-15 mb-0 fw-semibold">{t('Delivered')}</h6>
                                                                      </div>
                                                                  </div>
                                                              </a>
                                                          </div>
                                                          {
                                                              shipping?.delivered ?
                                                                  <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                                      <div className="accordion-body ms-2 ps-5 pt-0">
                                                                          <h6 className="fs-14">{t('Delivere')} : <b>{shipping?.firstname} {shipping?.lastname}</b></h6>
                                                                          <h6 className="mb-1">{t('ItemhasBeenDelivered')}</h6>
                                                                          <p className="text-muted mb-0">{format_date_complet(shipping?.delivered)}</p>
                                                                      </div>
                                                                  </div>

                                                                  : ""
                                                          }

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  )
                              }
                          </div>
                           :
                           ""
                   }

                   {
                       ((detail?.shipping_status && parseInt(detail?.shipping_status) === 1) && detail?.delivery_latlng) ? (
                           <div className="card">
                               <div className="card-header">
                                   <div className="d-sm-flex align-items-center">
                                       <h5 className="card-title flex-grow-1 mb-0">{t('Map')}</h5>
                                       <div className="flex-shrink-1 p-r-10">
                                          <ShareLocationOnMap location={detail?.delivery_latlng} />
                                       </div>
                                   </div>
                               </div>
                               <div className="card-body">
                                      <CustomMap editable={false} zoom={15} description={t('CustomerPosition')}  defaultCenter={JSON.parse(detail?.delivery_latlng)} />
                               </div>
                           </div>
                       ) : ""
                   }



               </div>

               <div className="col-xl-3">
                   <div className="card">
                       <div className="card-header">
                           <div className="d-flex">
                               <h5 className="card-title flex-grow-1 mb-0">
                                   <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i> {t('OrderTrack')}
                               </h5>
                           </div>
                       </div>
                       <div className="card-body">
                           <div className="text-center">
                               {detail?.delivery_status == 1 ? <lord-icon src="https://cdn.lordicon.com/slkvcfos.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"100px",height:"100px"}}></lord-icon> : null}
                               {detail?.delivery_status == 2 ? <lord-icon src="https://cdn.lordicon.com/vpgfeeei.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"100px",height:"100px"}}></lord-icon> : null}
                               {detail?.delivery_status == 3 ? <lord-icon src="https://cdn.lordicon.com/uetqnvvg.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"100px",height:"100px"}}></lord-icon> : null}
                               {detail?.delivery_status == 4 ?  <lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"100px",height:"100px"}}></lord-icon> : null}
                               {detail?.delivery_status == 5 ? <lord-icon src="https://cdn.lordicon.com/iiueiwdd.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"100px",height:"100px"}}></lord-icon> : null}
                               {detail?.delivery_status == 6 ?   <i className="fa fa-window-close fa-5x text-danger"></i> : null}


                               {detail?.delivery_status == 1 ? <h3 className="">{t("OrderPlaced")}</h3>: null}
                               {detail?.delivery_status == 2 ? <h3 className="">{t("Packed")}</h3>: null}
                               {detail?.delivery_status == 3 ? <h3 className="">{t("OnTheWay")}</h3> : null}
                               {detail?.delivery_status == 4 ? <h3 className="">{t("Delivered")}</h3> : null}
                               {detail?.delivery_status == 5  ? <h3 className="">{t("Return")}</h3>  : null}
                               {detail?.delivery_status == 6  ? <h3 className="">{t("Cancel")}</h3> : null}

                               { detail?.delivery_status == 2 && detail?.statut == 2 ?  <p className="text-success mb-0">{t('TransfertToDelivery')}</p> : null}

                           </div>
                       </div>
                   </div>
                   <div className="card">
                       <div className="card-header">
                           <div className="d-flex">
                               <h5 className="card-title flex-grow-1 mb-0">{t("Customer")}</h5>
                               <div className="flex-shrink-0">
                                   <Link to={`/store/customer/detail/${detail.hash_key}`} className="link-secondary">{t('ViewProfile')}</Link>
                               </div>
                           </div>
                       </div>
                       <div className="card-body">
                           <ul className="list-unstyled mb-0 vstack gap-3">
                               <li>
                                   <div className="d-flex align-items-center">
                                       <div className="flex-shrink-0">
                                           <img src={detail?.avatar ? SERVER_IMG_URL+auth?.shop?.shop_key+"/customers/"+detail?.avatar : avatar} alt="" className="avatar-sm rounded"/>
                                       </div>
                                       <div className="flex-grow-1 ms-3">
                                           <h6 className="fs-14 mb-1">{detail?.firstname} {detail?.lastname}</h6>
                                       </div>
                                   </div>
                               </li>
                               <li><i className="ri-mail-line me-2 align-middle text-muted fs-16"></i> {detail?.email}</li>
                               <li><i className="ri-phone-line me-2 align-middle text-muted fs-16"></i> {detail?.phone}</li>
                           </ul>
                       </div>
                   </div>

                   {
                       detail?.shipping_status == 1 ?

                              <div className="card">
                               <div className="card-header">
                                   <h5 className="card-title mb-0">
                                       <i className="ri-map-pin-line align-middle me-1 text-muted"></i> {t('BillingAddress')}
                                   </h5>
                               </div>
                               <div className="card-body">
                                   <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                                       <li className="fw-medium fs-14"><b>{t('Fullname')}</b> :  {detail?.delivery_fullname}</li>
                                       <li><b>{t('Country')}</b> : {detail?.delivery_country}</li>
                                       <li><b>{t('City')}</b> : {detail?.delivery_city}</li>
                                       <li><b>{t('Address')}</b> : {detail?.delivery_address}</li>
                                   </ul>
                               </div>
                           </div>

                           : null

                   }


                   <div className="card">
                       <div className="card-header">
                           <h5 className="card-title mb-0"><i
                               className="ri-secure-payment-line align-bottom me-1 text-muted"></i> {t('PaymentDetails')}</h5>
                       </div>
                       <div className="card-body">
                           <div className="row">
                               <div className="col-lg-12 d-flex align-items-center mb-2">
                                   <div className="flex-shrink-0">
                                       <p className="text-muted mb-0">Transactions:</p>
                                   </div>
                                   <div className="flex-grow-1 ms-2">
                                       <h6 className="mb-0">#{detail?.transaction}</h6>
                                   </div>
                               </div>
                               <div className="col-lg-12 d-flex align-items-center mb-2">
                                   <div className="flex-shrink-0">
                                       <p className="text-muted mb-0">{t('PaymentMethod')}:</p>
                                   </div>
                                   <div className="flex-grow-1 ms-2">
                                       <h6 className="mb-0">{t(Capitalize(detail?.payment_method.split('-')[0].toLowerCase()))}</h6>
                                   </div>
                               </div>
                           </div>
                           <div className="row">
                               <div className="col-lg-12 d-flex align-items-center">
                                   <div className="flex-shrink-0">
                                       <p className="text-muted mb-0"><b>Total</b> :</p>
                                   </div>
                                   <div className="flex-grow-1 ms-2">
                                       <h6 className="mb-0">{numberWithCommas(detail?.total_amount)} Frcs</h6>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>


               </div>

           </div>
           }

           <ChooseDeliver open={open} setOpen={setOpen} data={detail} created={getOrderSingle} loadData={open}  shop_id={auth?.shop.id}/>

       </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop
});
export default   compose(connect(mapStateToProps, {  })) (withTranslation()(OrderDetail));
