import React from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal} from "../../../../components";


const Message = ({t,open,setOpen,data}) => {

    return (
        <CustomModal open={open} setOpen={setOpen} title={t('Message')} size="md">
            <div className="row">
                <h4>{data?.sujet}</h4>
                <br/>
                <br/>
            <p>
                {data?.message}
            </p>
            </div>
        </CustomModal>
    );
}

export default withTranslation()(Message)
