import {CLIENT} from "../../helpers/utils";

export const flatData = (data,name) =>{
    return  data?.filter((dat) => dat.name == name)[0]?.value
}

export const flatStyle = (data,name) => {
    return data?.filter((dat) => dat.key == name)[0]
}

export const flatCustomStyle = (data) => {
    let style = {}

    for (let i = 0; i < data?.length; i++){
        if (data[i].value !== ""){
            style[data[i].key] = data[i].value+""+data[i].unit
        }
    }

    return style
}

export const calculDicount = (data) =>{
    const discount = (data.price * data.discount) / 100

    return data.price - discount
}

export const secondCalculDicount = (price,discount) =>{
    const sec_discount = (price * discount) / 100

    return price - sec_discount
}


export const filterSubcategories = (data,id) => {
    return data?.filter(sub => sub.category_id == id)
}

export const slugify = (string) => {
    return string .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
}

