import React, {useRef, useState} from 'react';
import {CLIENT, numberWithCommas} from "../../../helpers/utils";
import {flatData, secondCalculDicount} from "../../utils/utils";
import {Link} from "react-router-dom";


const Banner3 = ({block,t,toggleHightLight,customStyle,isEditor}) => {


    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const banner3Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :banner3Ref?.current?.offsetTop, left : banner3Ref?.current?.offsetLeft, width : banner3Ref?.current?.offsetWidth, height : banner3Ref?.current?.offsetHeight,nodeName : banner3Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    return (
        <div className="tp-product-banner-area"
             ref={banner3Ref}
             onMouseEnter={() => isEditor ? getDimension(block,true) : console.log("")}
             onMouseLeave={() => isEditor ? getDimension(block,false) : console.log("")}
             style={customStyle}
        >
            <div className="container">
                <div className="tp-product-banner-slider fix">
                    <div className="tp-product-banner-slider-active swiper-container">
                        <div className="swiper-wrapper">
                            <div className="tp-product-banner-inner  p-relative z-index-1 fix swiper-slide" style={{background : flatData(block.contentEditable,"background")}}>
                                <h4 className="tp-product-banner-bg-text">PROMO</h4>
                                <div className="row align-items-center">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="tp-product-banner-content p-relative z-index-1">
                                            <span className="tp-product-banner-subtitle">{flatData(block.contentEditable,'smallText')}</span>
                                            <h3 className="tp-product-banner-title text-white" >{flatData(block.contentEditable,'bigText')}</h3>
                                            <div className="tp-product-banner-price mb-40">
                                                <span className="old-price">{numberWithCommas(flatData(block.contentEditable,'price'))} Francs</span>
                                                <p className="new-price">{numberWithCommas(secondCalculDicount(flatData(block.contentEditable,'price'),flatData(block.contentEditable,'discount')))} Francs</p>
                                            </div>
                                            <div className="tp-product-banner-btn">
                                                <Link to={flatData(block.contentEditable,'link')} className="tp-btn tp-btn-2">{flatData(block.contentEditable,'linkText')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="tp-product-banner-thumb-wrapper p-relative">
                                            <div className="tp-product-banner-thumb text-end p-relative z-index-1">
                                                <img src={flatData(block.contentEditable,'image')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner3;
