import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../../components/SellerLayout";
import { checkCurrentUrlSubdomain, CLIENT, SERVER_IMG_URL, SERVER_URL } from '../../../../../helpers/utils'
import {Field, Form, Formik} from "formik";
import {withTranslation} from "react-i18next";
import * as Yup from "yup";
import {SUB_LOGIN} from "../../routes/type";
import {Link} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {Alert} from "reactstrap";
import SubLoading from "../../../../../components/Loading/SubLoading";
import { Fade } from 'react-awesome-reveal'

const Verify = ({t}) => {
    const [sloading, setSLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sumitted, setSubmitted] = useState(false)


    const [shop, setShop] = useState({});

    useEffect(()=>{
        checkSubdomain()

    },[])



    const checkSubdomain = async () =>{
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            if (response.data.succeed){
                setShop(response.data)
            }
        })
    }



    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('InputRequired')),
    });

    const initialValues = {
        email: ''
    }

    const handleSubmit = async (values) =>{
        setLoading(true)
        values.logo = SERVER_IMG_URL+shop?.shop.shop_key+"/logo/"+shop?.shop.logo
        values.shopName = shop?.shop.shopName
        values.client = window.location.origin
        await axios.post(SERVER_URL+"subdomain/verify/pass",values).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setSubmitted(true)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    return (
        <>
            {
                sloading ?
                    <SubLoading /> :
                    <Fade>
                     <SellerLayout>
                     <main>
                         <section className="tp-login-area pb-140 pt-100 p-relative z-index-1 fix">
                             <div className="tp-login-shape">
                                 <img className="tp-login-shape-1" src={CLIENT+"/builder/login/login-shape-1.png"} alt=""/>
                                 <img className="tp-login-shape-2" src={CLIENT+"/builder/login/login-shape-2.png"} alt=""/>
                                 <img className="tp-login-shape-3" src={CLIENT+"/builder/login/login-shape-3.png"} alt=""/>
                                 <img className="tp-login-shape-4" src={CLIENT+"/builder/login/login-shape-4.png"} alt=""/>
                             </div>
                             <div className="container">
                                 <div className="row justify-content-center">
                                     <div className="col-xl-6 col-lg-8">
                                         <div className="tp-login-wrapper">
                                             {
                                                 sumitted ?
                                                     <Alert color="success">
                                                           <h3 className="text-success">{t('Success')}</h3>
                                                         <hr/>
                                                         <p>{t('ForgetpasswordTextAfter')}.</p>
                                                     </Alert>
                                                     :

                                                     <>
                                                         <div className="tp-login-top text-center mb-30">
                                                             <h3 className="tp-login-title">{t('ResetPasswordSecond')}</h3>
                                                             <p>{t('ResetPasswordSecondText')}</p>
                                                         </div>
                                                         <div className="tp-login-option">

                                                             <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                                                 {({ errors, touched }) =>
                                                                     <Form>
                                                                         <div className="tp-login-input-wrapper">
                                                                             <div className="tp-login-input-box">
                                                                                 <div className="tp-login-input">
                                                                                     <Field name="email" type="email"   placeholder="example@mail.com" />
                                                                                 </div>
                                                                                 <div className="tp-login-input-title">
                                                                                     <label htmlFor="email">Email</label>
                                                                                 </div>
                                                                                 {errors.email && touched.email ? (
                                                                                     <div className='text-danger'>{errors.email}</div>
                                                                                 ) : null}
                                                                             </div>

                                                                         </div>

                                                                         <div className="tp-login-bottom">
                                                                             <button type="submit" className="tp-login-btn w-100" disabled={loading}>
                                                                                 {
                                                                                     loading ?
                                                                                         <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                                                                         :
                                                                                         <span> {t('Send')}</span>
                                                                                 }
                                                                             </button>
                                                                         </div>
                                                                     </Form>
                                                                 }

                                                             </Formik>
                                                         </div>
                                                         <div className="tp-login-suggetions pt-20 d-sm-flex align-items-center justify-content-center">
                                                             <div className="tp-login-forgot">
                                                                 <span>{t('RememberPassword')} <Link to={SUB_LOGIN}>{t('Login').toLowerCase()}</Link></span>
                                                             </div>
                                                         </div>
                                                     </>
                                             }
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </section>
                     </main>
                    </SellerLayout>
                    </Fade>
            }
        </>

    )
}

export default withTranslation() (Verify)
