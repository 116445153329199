import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {CalculateDiscount, SERVER_IMG_URL, SERVER_URL, SortImageByProduct} from "../../../../helpers/utils";
import TrialEmpty from "../template/blocs/TrialEmpty";
import placeholder from '../template/styles/product.png'
import {Link, withRouter} from "react-router-dom";
import {AddCart} from "../../../../store/Subdomain/cart/action";
import {compose} from "redux";
import {connect} from "react-redux";
import {toast} from "react-toastify";


const  TrialProducts = ({t,AddCart}) => {


    const [shop,setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));
    const [products,setProducts] = useState([]);
    const [galeries,setGaleries] = useState([]);





    useEffect(() => {
        if (shop){
            getProducts()
        }
    }, []);

    useEffect(() => {
       if (products.length > 0){

       }
    },[window.location.search])

    const getProducts = async () => {
        await axios.get(SERVER_URL+"front/alltrialhome/"+shop?.id,).then((response)=>{
            if (response.data.succeed){
                setProducts(response.data.products)
                setGaleries(response.data.galeries)

                if (window.location.search){
                    const l = window.location.search.replace(/^./, "").split("=")
                    filterProduct(l[0],l[1],response.data.products)
                }
            }else{
                // toast.error(t(response.data.message))
            }
        })
    }


    const addToCart = (item) => {
        AddCart({
            id:item.id,
            qty : 1,
            category : item.category,
            picture : SERVER_IMG_URL+shop?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
            name:item.title,
            price:CalculateDiscount(item?.price,item?.discount)
        })
        toast.success(t("AddToCart"), {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    }

   const filterProduct = (filterBy,value,productArray) => {
       if (filterBy == "filter-by-category"){
           const data = productArray.filter(product => product.category_id == value)
           setProducts(data)
       }else if (filterBy == "filter-by-subcategory"){
           const data = productArray.filter(product => product.subcategory == value)
           setProducts(data)
       }
   }

    const [isList, setIsList] = useState(false);

    const [show, setShow] = useState(8);

    const changeShow = (value) => {
        if (value == "all"){
            setShow(products.length)
        }else{
            setShow(value)
        }
    }


    const addWishlist = (item) => {
        const data ={
            id:item.id,
            category : item.category,
            picture : SERVER_IMG_URL+shop?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
            name:item.title,
            discount : item.discount,
            price:item.price,
            hash_key:item.hash_key
        }
        let wishlists = localStorage.getItem('wishlist')

        const wish = wishlists ? JSON.parse(wishlists) : []


        if (wish.length > 0){
            wish.push(data)
            localStorage.setItem('wishlist', JSON.stringify(wish))
        }else{
            const arr = []
            arr.push(data)
            localStorage.setItem('wishlist', JSON.stringify(arr))
        }

        toast.success(t("AddToWishlist"), {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    }




    return (
        <div className=""  style={{background : "#f4f4f4"}}>
            <div className="u-s-p-y-90">

                <div className="container u-s-p-b-80">
                    <div className="row">
                        <h1 className="text-center">{t('Products')}</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="shop-p">
                                <div className="shop-p__toolbar u-s-m-b-30">
                                    <div className="shop-p__tool-style">
                                        <div className="tool-style__group u-s-m-b-8">

                                            <span className={`js-shop-grid-target ${!isList ? "is-active" : null}`} onClick={event => setIsList(false)}>{t('Grid')}</span>

                                            <span className={`js-shop-list-target ${isList ? "is-active" : null}`} onClick={event => setIsList(true)}>{t('Liste')}</span></div>
                                        <div>
                                            <div className="tool-style__form-wrap">
                                                <div className="u-s-m-b-8">
                                                    <select className="select-box select-box--primary-style" value={show} onChange={event => changeShow(event.target.value)}>
                                                    <option value="8">{t('Show')}: 8</option>
                                                    <option value="12">{t('Show')}: 12</option>
                                                    <option value="16">{t('Show')}: 16</option>
                                                    <option value="all">{t('Show')}: {t('All')}</option>
                                                </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shop-p__collection">
                                    <div className={`row ${!isList ? "is-grid-active" : "is-list-active"}`}>

                                        {
                                            products?.length > 0 ?

                                                <>
                                                    {
                                                        products?.slice(0,show).map((product,key) =>
                                                             <div className="col-lg-3 col-md-4 col-sm-6" key={key}>
                                                                <div className="product-m">
                                                                    <div className="product-m__thumb">
                                                                        <Link className="aspect aspect--bg-grey aspect--square u-d-block" to={`/product/detail/${product.hash_key}`}>
                                                                            <img className="aspect__img" src={product.pictures ? SERVER_IMG_URL+shop?.shop_key+"/products/"+JSON.parse(product.pictures)[0] : placeholder} alt=""/>
                                                                        </Link>

                                                                        <div className="product-m__add-cart">
                                                                            <a className="btn btn-success" onClick={event => addToCart(product)}>{t('BuyNow')}</a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-m__content">
                                                                        <div className="product-m__category">
                                                                            <a href="#">{product.category}</a></div>
                                                                        <div className="product-m__name">
                                                                            <Link to={`/product/detail/${product.gallery_key}`}>{product.title}</Link>
                                                                        </div>
                                                                        <div className="product-m__price">{product.price} Frcs</div>
                                                                        <div className="product-m__hover">
                                                                            <div className="product-m__preview-description">
                                                                                <span>{product.short_description}...</span>
                                                                            </div>
                                                                            <div className="product-m__wishlist">
                                                                                <span className="far fa-heart cursor-pointer"  onClick={event => addWishlist(product)} title=""></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    }
                                                </> :

                                                <TrialEmpty />
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart : state.cart,
});

export default compose(withRouter, connect(mapStateToProps, { AddCart })) (withTranslation()(TrialProducts))
