export const NODE_ENV = "prod"

export const ExchangeKey = "344113d8f7c745a7ac1211649e333d92"
export const currencyLink = "https://openexchangerates.org/api/latest.json"


export const PAYPAL_DEV_CLIENT_ID = "ARC57PcmfeIrJNhZLiulVFbnldcBuFGr4x9zQ6QAcMUo9OcvVl4lG-4e2stkt_fd7s4tERYhxK7t4Syc"
export const PAYPAL_PROD_CLIENT_ID = "AaLN6V8tRlipM1C5yEG4SHEonPqEfEHtNaTQbDNSi0VQuMRTPAbS6Crg4n9YH1zchZJ2FFISEtbt-QtQ"


export const FEDAPAY_PUBLIC_KEY = "pk_sandbox_pW6U7GFA-JgtSKFuLYcfki7B"
export const FEDAPAY_PUBLIC_KEY_LIVE = "pk_live_HdjccpRE2FRfc5BOQHhdonIC"


export const MAPBOX_TOKEN = "pk.eyJ1Ijoic2VlcGF0b25waHVpIiwiYSI6ImNsbDVkc255bzBhYjUzaG9naXVweHVidzcifQ.dsVppK2BNGrw8Z8uulO8Fg"

export const GOOGLE_CLIENT_ID = "717025536761-8j9vue7p3uq62qo3fv9q31n6tj6u13fi.apps.googleusercontent.com"

export const FACEBOOK_APP_ID = "865123941992082";

export const IpRequired = "104.21.67.171"

export const CnameRequired = "proxy-ssl.kiiako.io"

