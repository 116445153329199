import React, {useEffect, useState} from 'react';
import {
    APPNAME,
    attachTokenToHeaders,
    CLI,
    CustomChangeLanguage,
    DOMAINE,
    filesFolder,
    MOBILE_APP,
    SERVER_URL
} from "../../helpers/utils";
import {compose} from "redux";
import {Link, useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import swal from '@sweetalert/with-react'
import {
    LINK_ADMIN_NOTIFICATION,
    LINK_LANDING, LINK_STORE_CHANGE_PLAN, LINK_STORE_DASHBOARD, LINK_STORE_NOTIFICATION,
    LINK_STORE_PROFIL,
    LINK_STORE_SETTINGS,
    LINK_STORE_UPTURN
} from "../../routes/type";
import {logout} from "../../store/Auth/action";
import avatar from "../../assets/admin/images/avatar-blank.png"
import french from "../../assets/admin/images/flags/french.svg"
import english from "../../assets/admin/images/flags/us.svg"
import { toast } from 'react-toastify';
import socket from '../../helpers/services/BroadCasts'
import notificationSound from "../../assets/notifications/message.wav"
import axios from "axios";
import moment from "moment/moment";
import 'moment/locale/fr'
import bell from '../../assets/admin/images/svg/bell.svg'
import Authorization from '../../components/Authorization'


const Navbar = ({t,auth,logout}) => {


    const [drop1, setDrop1] = useState(false);
    const [drop2, setDrop2] = useState(false);
    const [drop3, setDrop3] = useState(false);
    const [drop4, setDrop4] = useState(false);
    const [drop5, setDrop5] = useState(false);
    const [dropSwipe, setDropSwipe] = useState(false);
    const [messageNotifications, setMessageNotifications] = useState([]);
    const [simpleNotifications, setSimpleNotifications] = useState([]);

    const history = useHistory();

    useEffect(() => {
        if (auth?.me.role_id === 2) getAllNotifications();
        getMessageNotifications();
    }, []);

    useEffect(() => {
        if (auth?.shop?.firstTime == 0) {
            if ((auth?.plan.trial == 0 && auth?.plan.current_period_start) ||
                (auth?.plan.trial == 1 && auth?.plan.trial_start)) {
                if (auth?.planDays < 3 && window.location.pathname === LINK_STORE_DASHBOARD) {
                    toast.error(`${t('DayRest')} ${auth?.planDays} ${t('Days')}`);
                }
            }
        }
    }, [auth]);

    const getAllNotifications = async () => {
        try {
            const response = await axios.get(SERVER_URL + "notifications/shop/" + auth?.shop?.id, attachTokenToHeaders());
            if (response.data.succeed) {
                setSimpleNotifications(response.data.notifications);
            } else {
                toast.error(t(response.data.message));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getMessageNotifications = async () => {
        try {
            const response = await axios.get(SERVER_URL + "notifications/" + auth?.me.id, attachTokenToHeaders());
            if (response.data.succeed) {
                setMessageNotifications(response.data.notifications);
            } else {
                toast.error(t(response.data.message));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const logoutModal = () => {
        swal({
            title: t('Logout'),
            text: t('SureToLogout'),
            buttons: [t('Cancel'), t('Yes')],
            dangerMode: true
        }).then((pass) => {
            if (pass) {
                logout();
            }
        });
    };

    const sideBarSwipe = () => {
        setDropSwipe(!dropSwipe);
        const sidebarSize = dropSwipe ? "lg" : "sm";
        document.querySelector("html").setAttribute("data-sidebar-size", sidebarSize);
    };

    useEffect(() => {
        const notificationMessageListener = (data) => {
            if (data.toUser_id == auth?.me.id) {
                const audio = new Audio(notificationSound);
                audio.play();
                getMessageNotifications();
                toast.info(data);
            }
        };

        const shopNotificationListener = (data) => {
            if (auth?.me.role_id === 2 && data.toUser_id == auth?.shop.id) {
                const audio = new Audio(notificationSound);
                audio.play();
                getAllNotifications();
                toast.info(data);
            }
        };

        socket.on("notification_message-" + auth?.me?.id, notificationMessageListener);
        socket.on("notification_message-" + auth?.shop?.id, shopNotificationListener);

        return () => {
            socket.off("notification_message-" + auth?.me?.id, notificationMessageListener);
            socket.off("notification_message-" + auth?.shop?.id, shopNotificationListener);
        };
    }, [auth]);



    const gotoMessage = async (link,id) => {
        await axios.get(SERVER_URL+"notification/read/"+id,attachTokenToHeaders()).then((response)=>{
            getAllNotifications()
            history.push(link)

        })
    }


    function copyToClipboard() {
        navigator.clipboard.writeText("https://" + auth?.shop?.domaine + "." + DOMAINE)
       toast.info(t('LinkCopied'))
    }


    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box horizontal-logo">
                            <a href="#" className="logo logo-dark">
                        <span className="logo-sm">
                            <h1 style={{color: "#fff"}}>{auth ? auth?.shop?.shopName.substring(0, 1) : APPNAME}</h1>
                        </span>
                                <span className="logo-lg">
                             <h1 style={{color: "#fff"}}>{auth ? auth?.shop?.shopName : APPNAME}</h1>
                        </span>
                            </a>

                            <a href="#" className="logo logo-light">
                               <span className="logo-sm">
                            <h1 style={{color: "#fff"}}>{auth ? auth?.shop?.shopName.substring(0, 1) : APPNAME}</h1>
                        </span>
                                <span className="logo-lg">
                           <h1 style={{color: "#fff"}}>{auth ? auth?.shop?.shopName : APPNAME}</h1>
                        </span>
                            </a>
                        </div>

                        <button type="button"
                                className="customBtn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon" onClick={event => sideBarSwipe()}>
                            <span className="hamburger-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </button>

                    </div>


                    <div className="d-flex align-items-center">


                        {
                            auth?.me?.role_id === 2 ?

                                <>
                                    <div className="dropdown ms-1 topbar-head-dropdown header-item">
                                        <div className="input-group mr-10">
                                            <input type="text" className="form-control" readOnly={true}
                                                   value={'https://' + auth?.shop?.domaine + '.' + DOMAINE}
                                                   style={{ height: '35px' }}
                                                   aria-label="Dollar amount (with dot and two decimal places)" />
                                            <span className="input-group-text" id="Tooltip-link"
                                                  onClick={() => copyToClipboard()}
                                                  style={{ height: '35px', cursor: 'pointer' }}><i
                                                className="ri-file-copy-fill"></i></span>
                                        </div>
                                    </div>

                                    <div className="dropdown ms-1 topbar-head-dropdown header-item">
                                        <a href={'https://' + auth?.shop?.domaine + '.' + DOMAINE}
                                           className="badge badge-info fs-12"
                                           style={{ paddingTop: '10px', paddingBottom: '10px', marginRight: '10px' }}
                                           target="_blank" rel="noreferrer" title={t(auth?.shop.shopName)}>
                                            <span
                                                className="mdi mdi-store-settings-outline"></span> {t('See')} {t('Shop')}
                                        </a>
                                    </div>


                                    <div className="dropdown ms-1 topbar-head-dropdown header-item">
                                        <b className="badge" style={{
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            backgroundColor: auth?.plan?.color,
                                            marginRight: '10px',
                                        }}>{auth?.plan?.name?.toUpperCase()} <i
                                            className="ri-pencil-fill badge badge-danger"></i></b>
                                    </div>
                                    {auth?.plan.trial_start || auth?.plan.current_period_start ?
                                        <div className="dropdown ms-1 topbar-head-dropdown header-item">
                                            <b className={`badge ${auth?.planDays <= 10 ? 'badge-danger' : 'badge-success'}`}
                                               style={{
                                                   paddingTop: '10px',
                                                   paddingBottom: '10px',
                                               }}>{t('Expir')} {auth?.planDays} {t('Days')}</b>
                                        </div>
                                        : null
                                    }
                                </>
                                :
                                ''

                        }


                        <div className="dropdown ms-1 topbar-head-dropdown header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                    onClick={event => setDrop5(!drop5)}
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                {localStorage.getItem('I18N_LANGUAGE') === 'fr' &&
                                    <img id="header-lang-img" src={french} alt="lg" height="20" className="rounded" />}
                                {localStorage.getItem('I18N_LANGUAGE') === 'en' &&
                                    <img id="header-lang-img" src={english} alt="lg" height="20" className="rounded"/>}


                            </button>
                            <div className={`dropdown-menu dropdown-menu-end  ${drop5 ? "show" : null}`}

                                 onMouseLeave={() => setTimeout(() => {
                                     setDrop5(false)
                                 }, 1000)}>


                                <span className="dropdown-item notify-item language py-2 cursor-pointer"
                                      onClick={event => CustomChangeLanguage('en')}
                                >
                                    <img src={english} alt="user-image" className="me-2 rounded"
                                         height="18"/>
                                        <span className="align-middle">English</span>
                                </span>


                                <span className="dropdown-item notify-item language cursor-pointer"
                                      onClick={event => CustomChangeLanguage('fr')}
                                >
                                    <img src={french} alt="user-image" className="me-2 rounded"
                                         height="18"/>
                                        <span className="align-middle">français</span>
                                </span>
                            </div>
                        </div>

                        {/*<div className="dropdown topbar-head-dropdown ms-1 header-item">*/}
                        {/*    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"*/}
                        {/*            data-bs-toggle="dropdown" aria-haspopup={drop4} aria-expanded={drop4}*/}
                        {/*            onClick={event => setDrop4(!drop4)}>*/}

                        {/*        <i className='bx bx-category-alt fs-22'></i>*/}
                        {/*    </button>*/}
                        {/*    <div*/}
                        {/*        className={`dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 ${drop4 ? "show" : null}`}*/}

                        {/*        onMouseLeave={() => setTimeout(() => {*/}
                        {/*            setDrop4(false)*/}
                        {/*        }, 1000)}*/}
                        {/*    >*/}
                        {/*        <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">*/}
                        {/*            <div className="row align-items-center">*/}
                        {/*                <div className="col">*/}
                        {/*                    <h6 className="m-0 fw-semibold fs-15"> {t('OurApp')} </h6>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}

                        {/*        <div className="p-2">*/}
                        {/*            <div className="row g-0">*/}
                        {/*                <div className="col">*/}
                        {/*                    <a className="dropdown-icon-item" href={"http://app." + CLI}>*/}
                        {/*                        <i className="fa fa-mobile fa-2x text-info"></i>*/}
                        {/*                        <span>{MOBILE_APP}</span>*/}
                        {/*                    </a>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="dropdown topbar-head-dropdown ms-1 header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                    id="page-header-message-dropdown" data-bs-toggle="dropdown"
                                    onClick={event => setDrop2(!drop2)}
                                    aria-haspopup={drop2}
                                    aria-expanded={drop2}>
                                <i className='bx bx-envelope fs-22'></i>
                                <span
                                    className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                    {messageNotifications?.length}
                                    <span className="visually-hidden">Messages</span>
                                </span>
                            </button>
                            <div
                                className={`dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 ${drop2 ? "show" : null}`}

                                onMouseLeave={() => setTimeout(() => {
                                    setDrop2(false)
                                }, 1000)}
                            >

                                <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                    <div className="p-3">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h6 className="m-0 fs-16 fw-semibold text-white"> Messages </h6>
                                            </div>
                                            <div className="col-auto dropdown-tabs">
                                                <span
                                                    className="badge badge-soft-light fs-13"> {messageNotifications?.length} {t('New')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-content" id="notificationItemsTabContent">
                                    <div className="tab-pane fade show active py-2 ps-2">
                                        <div style={{maxHeight: "300px"}} className="pe-2">

                                            {
                                                messageNotifications.length > 0 ?
                                                    <>
                                                        {
                                                            messageNotifications?.map((notif, key) =>
                                                                <div
                                                                    className={`text-reset notification-item d-block dropdown-item position-relative ${notif.statut == 1 ? "active" : ""}`}
                                                                    key={key}>
                                                                    <div className="d-flex">
                                                                        <div className="avatar-xs me-3">
                                                                    <span
                                                                        className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                                                        <i className="bx bx-badge-check"></i>
                                                                    </span>
                                                                        </div>
                                                                        <div className="flex-1">
                                                                    <span className="stretched-link cursor-pointer"
                                                                          onClick={event => gotoMessage(notif.link, notif.id)}>
                                                                        <h6 className="mt-0 mb-2 lh-base">
                                                                            {notif.message}
                                                                        </h6>
                                                                    </span>
                                                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i
                                                                        className="mdi mdi-clock-outline"></i> {moment(notif?.created_at).fromNow()}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    :

                                                    <div className="empty-notification-elem">
                                                        <div className="w-25 w-sm-50 pt-3 mx-auto"><img
                                                            src={bell}
                                                            className="img-fluid"
                                                            alt="user-pic"/></div>
                                                        <div className="text-center pb-5 mt-2"><h6
                                                            className="fs-18 fw-semibold lh-base">
                                                            {t('YouDoNotHaveNotification')} </h6></div>
                                                    </div>

                                            }


                                            <div className="my-3 text-center">
                                                <Link
                                                    to={auth.me.role_id === 3 ? LINK_ADMIN_NOTIFICATION : LINK_STORE_NOTIFICATION}
                                                    className="btn btn-soft-success waves-effect waves-light">
                                                    {t('ViewAllNotif')}
                                                    <i className="ri-arrow-right-line align-middle"></i>
                                                </Link>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>


                        {/*Simple Notifiation*/}

                        {
                            auth?.me?.role_id === 2 ?
                                <div className="dropdown topbar-head-dropdown ms-1 header-item">
                                    <button type="button"
                                            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                            id="page-header-notifications-dropdown" data-bs-toggle="dropdown"
                                            onClick={event => setDrop3(!drop3)}
                                            aria-haspopup={drop3}
                                            aria-expanded={drop3}>
                                        <i className='bx bx-bell fs-22'></i>
                                        <span
                                            className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                    {simpleNotifications?.length}
                                            <span className="visually-hidden">Messages</span>
                                </span>
                                    </button>
                                    <div
                                        className={`dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 ${drop3 ? "show" : null}`}

                                        onMouseLeave={() => setTimeout(() => {
                                            setDrop3(false)
                                        }, 1000)}>

                                        <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                            <div className="p-3">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                                    </div>
                                                    <div className="col-auto dropdown-tabs">
                                                        <span
                                                            className="badge badge-soft-light fs-13"> {simpleNotifications?.length} {t('New')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-content" id="notificationItemsTabContent">
                                            <div className="tab-pane fade show active py-2 ps-2">
                                                <div style={{maxHeight: "300px"}} className="pe-2">

                                                    {
                                                        simpleNotifications.length > 0 ?
                                                            <>
                                                                {
                                                                    simpleNotifications?.map((notif, key) =>
                                                                            <div
                                                                                className={`text-reset notification-item d-block dropdown-item position-relative mb-10 ${notif.statut == 1 ? "active" : ""}`}
                                                                                key={key}>
                                                                                <div className="d-flex">
                                                                                    <div className="avatar-xs me-3">
                                                                    <span
                                                                        className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                                                        <i className="bx bx-badge-check"></i>
                                                                    </span>
                                                                                    </div>
                                                                                    <div className="flex-1">
                                                                    <span className="stretched-link cursor-pointer"
                                                                          onClick={event => gotoMessage(notif.link, notif.id)}>
                                                                        <h6 className="mt-0 mb-2 lh-base">
                                                                            {t(notif.message)}
                                                                        </h6>
                                                                    </span>
                                                                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                            <span><i
                                                                                className="mdi mdi-clock-outline"></i> {moment(notif?.created_at).fromNow()}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    )
                                                                }
                                                            </>

                                                            :

                                                            <div className="empty-notification-elem">
                                                                <div className="w-25 w-sm-50 pt-3 mx-auto"><img
                                                                    src={bell}
                                                                    className="img-fluid"
                                                                    alt="user-pic"/></div>
                                                                <div className="text-center pb-5 mt-2"><h6
                                                                    className="fs-18 fw-semibold lh-base">
                                                                    {t('YouDoNotHaveNotification')} </h6></div>
                                                            </div>

                                                    }

                                                    <div className="my-3 text-center">
                                                        <Link
                                                            to={auth.me.role_id === 3 ? LINK_ADMIN_NOTIFICATION : LINK_STORE_NOTIFICATION}
                                                            className="btn btn-soft-success waves-effect waves-light">
                                                            {t('ViewAllNotif')}
                                                            <i className="ri-arrow-right-line align-middle"></i>
                                                        </Link>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                : ""
                        }

                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button type="button" className={`customBtn ${drop1 ? "show" : null}`}
                                    onClick={() => setDrop1(!drop1)}
                                    id="page-header-user-dropdown"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded={drop1}>
                        <span className="d-flex align-items-center">
                            <img className="rounded-circle header-profile-user"
                                 src={`${auth.me?.avatar ? filesFolder(auth.me?.shop_key, "users") + auth.me?.avatar : avatar}`}
                                 alt="avatar"/>
                            <span className="text-start ms-xl-2">
                                <span
                                    className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{auth.me?.firstname} {auth.me?.lastname}</span>
                                <span
                                    className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{auth?.me.role_id == 2 ? t('Shop') : t('Admin')}</span>
                            </span>
                        </span>
                            </button>
                            <div className={`dropdown-menu dropdown-menu-end ${drop1 ? "show" : null}`}
                                 onMouseLeave={() => setTimeout(() => {
                                     setDrop1(false)
                                 }, 1000)}>

                                <h6 className="dropdown-header">{t('Welcome')} {auth.me?.firstname}</h6>
                                <Link className="dropdown-item" to={LINK_LANDING}>
                                    <i className="mdi mdi-home text-muted fs-16 align-middle me-1"></i>
                                    <span className="align-middle">{t('Home')}</span>
                                </Link>

                                {
                                    auth?.plan?.pricing_id != 1 && auth?.me.role_id == 2 ?
                                        <>
                                            <Link className="dropdown-item" to={LINK_STORE_PROFIL}>
                                                <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                                                <span className="align-middle">{t("Profile")}</span>
                                            </Link>

                                            {
                                                !auth?.me.isGest  ?

                                                    <>
                                                        <div className="dropdown-divider"></div>
                                                        <Link className="dropdown-item" to={LINK_STORE_UPTURN}>
                                                            <i className="mdi mdi-repeat-once text-muted fs-16 align-middle me-1"></i>
                                                            <span className="align-middle">{t('Subscription')}</span>
                                                        </Link>

                                                        <Link className="dropdown-item" to={LINK_STORE_CHANGE_PLAN}>
                                                            <i className="mdi mdi-arrow-top-right text-muted fs-16 align-middle me-1"></i>
                                                            <span className="align-middle">{t('UpturnMyPlan')}</span>
                                                        </Link>
                                                    </>

                                                    :

                                                    ""

                                            }

                                            <Authorization single={true} module="settings">
                                                <div className="dropdown-divider"></div>
                                                <Link className="dropdown-item" to={LINK_STORE_SETTINGS}>
                                                    <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                                                    <span className="align-middle">{t('Settings')}</span>
                                                </Link>
                                            </Authorization>
                                        </>
                                        : null

                                }

                                <span className="dropdown-item cursor-pointer" onClick={event => logoutModal()}>
                                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                                    <span className="align-middle">{t('Logout')}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default compose(withRouter, connect(mapStateToProps, {logout}))(withTranslation()(Navbar))
