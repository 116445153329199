import React, {useEffect, useRef, useState} from 'react';
import NotFoundTrialPage from "./pages/NotFoundTrialPage";
import TrialNavbar from "./template/blocs/TrialNavbar";
import './template/styles/trial.css'
import ScrollToTop from "../../../components/Scroll/ScrollToTop";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {RouteMiddleware} from "../../../middleware/route.middleware";
import {routeList} from "./routes/routes";
import {compose} from "redux";
import {connect} from "react-redux";
import {loadSubMe} from "../../../store/Subdomain/Oauth/action";
import TrialFooter from "./template/blocs/TrialFooter";
import {FeedBack} from "../../../components";
import NotFoundSubdomain from "../Required/NotFoundSubdomain";
import {compare_date} from "../../../helpers/utils";


const TrialIndex = ({shop,domaine,subAuth,loadSubMe}) => {

    const pathname = useRef(window.location.pathname)
    const [searchValue, setSearchValue] = useState("");



    useEffect(() => {
        localStorage.setItem('__S78456321__',JSON.stringify(shop.shop))
        localStorage.setItem('__S78456329__',JSON.stringify(shop.plan))
    }, [shop]);



    useEffect(()=>{
        if (window.location.hash === '#_=_') window.location.hash = '';
        // loadSubMe()
    },[])


    useEffect(() => {
        if (!subAuth.loading && subAuth.token && !subAuth.isAuthenticated) {
             loadSubMe();
        }
    }, [subAuth]);

    const launchSearch = (value) => {
       setSearchValue(value)
    }


    return (
        <div>
            {
                !compare_date(shop.trial_end) ?

                    <>
                        {!routeList.find(e => e.path === pathname.current)
                            ? <NotFoundTrialPage domaine={domaine} />
                            : <>
                                <div className="trial__content__bloc__style">

                                    <Router>
                                        <TrialNavbar shopDatas={shop} domaine={domaine}  launchSearch={launchSearch}/>
                                        <ScrollToTop history={Router.history}  />
                                        <Switch>
                                            {routeList.map((route, idx) => {
                                                if(route.isLogged){
                                                    return <RouteMiddleware  exact key={idx} path={route.path} component={route.component} isAuthenticated={subAuth.isAuthenticated} />
                                                }else{

                                                    return <Route exact  key={idx} path={route.path} component={route.component}  />
                                                }
                                            })}
                                        </Switch>
                                    </Router>

                                    {subAuth?.isAuthenticated ?  <FeedBack shop={shop} auth={subAuth} /> : ""}

                                    <TrialFooter shopDatas={shop} domaine={domaine} />


                                </div>
                                {/*<Brand show={showable} />*/}
                            </>

                        }

                    </>

                    :

                    <NotFoundSubdomain />
            }
        </div>
    )

}


const mapStateToProps = (state) => ({
    subAuth : state.subLogin,
});

export default compose(connect(mapStateToProps, { loadSubMe }))(TrialIndex);
