import React, {Fragment, useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {loadShopsList} from "../../../store/Register/action";
import {APPNAME, DOMAINE, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import ReactStars from "react-rating-stars-component/dist/react-stars";
import {loadHome} from "../../../store/Home/action";
import placeholder from './empty_shop.png'
import Layout from "../../../layouts/front/Layout";
import {MiddleLoading} from "../../../components";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();


const  ShopsList = ({t,register : {list,isLoading},loadShopsList,home,loadHome}) => {

    document.title = APPNAME+" :: "+t('ShopList')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('ShopList'))


    const [shoplist,setShopList] = useState([])
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(0);
    const [categoryOptions, setCategoryOptions] = useState([{label :t('All'),value: 0}]);
    const [rates, setRates] = useState([]);
    const [stillShopOpen, setStillShopOpen] = useState(false);

    useEffect(()=>{
        loadShopsList()
        loadHome()
    },[loadShopsList])

    useEffect(()=>{
        setShopList(list)
    },[list])

    useEffect(() => {
        home.categories.map((category)=>{setCategoryOptions(prevState => [...prevState, {label : category?.[localStorage.getItem('I18N_LANGUAGE')], value : category.id}])})
    }, [localStorage.getItem('I18N_LANGUAGE'),home]);


    useEffect(() => {
        getRates()
    }, []);


    const countryOptions = [
        { value: '' , label: t("All")},
        { value: 'benin' , label: "Benin"},
        { value: "cote d'ivoire" , label: "Cote d'ivoire"},
        { value: 'mali' , label: "Mali"},
        { value: 'niger' , label: "Niger"},
        { value: 'senegal' , label: "Sénégal"},
        { value: 'togo' , label: "Togo"},
    ]


    const ratedOptions = [
        { value: '' , label: t("All")},
        { value: '1' , label: t('RateHigh')},
        { value: "2" , label: t('HightoLow')},
    ]


    const getRates = async () => {
        await axios.get(SERVER_URL+"front/rates").then((response)=>{
            if (response.data.succeed){
                setRates(response.data.rates)
            }else{
                // toast.error(t(response.data.message))
            }
        })
    }

    const filterByCategory = (id) => {
        setLoading(true)
        setSelected(id)
         setTimeout(()=>{
             if (id == 0){
                 setShopList(list)
             }else {
                 const filter = list.filter((k) => k.category == id)
                 setShopList(filter)
             }
             setLoading(false)
         },1000)

    }

    const filterByCountry = (value) => {
        setLoading(true)
        setSelected(value)
         setTimeout(()=>{
             if (value === ""){
                 setShopList(list)
             }else {
                 const filter = list.filter((k) => k.country == value)
                 setShopList(filter)
             }
             setLoading(false)
         },1000)

    }

    const filterByRate = (value) => {
      if (value == ""){
         setShopList(list)
      }else{
          const  filter = shoplist.filter(k => {
              return k.shopName.toLowerCase().includes(value.toLowerCase())
          })

          setShopList(filter)
      }
    }


    const calculateRate = (shop_id) => {
        let toto = 0
        const filter = rates?.filter((k) => k.shop_id == shop_id)
        for (let k = 0;k < filter.length; k++){
            toto +=  filter[k].rate
        }

        return toto / filter.length

    }

    const resetFilter = () => {
      setLoading(true)
        setTimeout(() =>{
            setShopList(list)
            setLoading(false)
        },1000)
    }


    const gotoPage = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.setAttribute("target","_blank");
        link.click();
        document.body.removeChild(link);
    }

    const convertToMinutes = time => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const stillOpen = (event) => {
        setLoading(true)
        const now = new Date();
        const currentMinutes = now.getHours() * 60 + now.getMinutes();

        setStillShopOpen(event.target.checked);

        if (event.target.checked) {
            const openedShops = shoplist.filter(shop => {
                if (shop.open_hours){
                    const openingMinutes = convertToMinutes(shop.open_hours);
                    const closingMinutes = convertToMinutes(shop.close_hours);

                    return currentMinutes >= openingMinutes && currentMinutes <= closingMinutes;
                }
            });

            setTimeout(() => {
                setShopList(openedShops);
                setLoading(false)
            },1000)

        } else {
            setTimeout(() => {
                setShopList(list);
                setLoading(false)
            },1000)
        }
    };



    return (
        <Layout>
            {
                isLoading ?
                    <MiddleLoading />
                    :
                    <section className="shop-area p-t-180 p-b-130" >
                        <div className="container">
                            <div className="row">
                                <div className="offset-lg-2 col-lg-8">
                                    <div className="header_custom_pricing">
                                        <h1>{t('Shops')}</h1>
                                        <p>
                                            {t('ShopList')}
                                        </p>
                                        <br/>
                                    </div>
                                </div>
                            </div>



                        </div>




                        <div className="container-fluid">
                                {/*<pre>*/}
                                {/*    {JSON.stringify(shoplist,null,2)}*/}
                                {/*</pre>*/}

                            <div className="offset-lg-1 col-lg-10">
                                <hr />
                                <div className="prduct-items-wrap p-t-10">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className=" pb-20 pt-20">
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            defaultValue={''}
                                                            isDisabled={false}
                                                            onChange={(newValue) => filterByCountry(newValue.value)}
                                                            className="front"
                                                            options={countryOptions}
                                                            placeholder={t('Pays')}

                                                        />
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            defaultValue={''}
                                                            isDisabled={false}
                                                            onChange={(newValue) => filterByCategory(newValue.value)}
                                                            className="front"
                                                            options={categoryOptions}
                                                            placeholder={t('Categories')}

                                                        />
                                                    </div>
                                                    <div className="col-lg-6"></div>

                                                    <div className="col-lg-2 d-flex justify-content-end">
                                                        <div
                                                            className="form-check form-switch form-switch-md mb-3 mt-1">
                                                            <input type="checkbox" className="form-check-input"
                                                                   defaultValue={stillShopOpen}
                                                                   onClick={event => stillOpen(event)}
                                                                   id="sidebarUserProfile" />
                                                            <label className="form-check-label text-muted"
                                                                   style={{ fontWeight: '200', fontSize: '12px' }}
                                                                   htmlFor="sidebarUserProfile">{t('StillOpen')}</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row team-members mt-30">
                                        <p>
                                            {t('Total')} {shoplist?.length} {t('Shops')}
                                        </p>
                                        <br/>
                                        <br/>

                                        {
                                            isLoading || loading ?
                                                <div className="return_top">
                                                    <MiddleLoading />

                                                </div>

                                                :

                                                <>
                                                    {
                                                        shoplist?.length > 0
                                                            ?

                                                            <>

                                                                {
                                                                    shoplist.map((shop, key) =>

                                                                        <div
                                                                            className="col-xl-2 col-md-3 cursor-pointer"
                                                                            key={key}>
                                                                            <div className="shop_list_card">
                                                                                {shop.trial ?
                                                                                    <div className="ribbon">
                                                                                        <span>Demo</span>
                                                                                    </div> : null}
                                                                                <div
                                                                                    onClick={event => gotoPage(`https://${shop.domaine}.${DOMAINE}`)}
                                                                                    style={{
                                                                                        background: `url('${shop.logo ? SERVER_IMG_URL + shop.shop_key + '/logo/' + shop.logo : placeholder}')`,
                                                                                        backgroundSize: 'contain',
                                                                                        backgroundRepeat: 'no-repeat',
                                                                                        backgroundPosition: 'center center',
                                                                                        height: '150px',
                                                                                        maxWidth: '60%',
                                                                                        marginLeft: '20%',
                                                                                        borderBottom: '1px solid rgba(0,0,0,0.3)',
                                                                                        marginBottom: '10px',
                                                                                    }}>

                                                                                </div>
                                                                                <div className="content">
                                                                                    <p className="text-center">
                                                                                        <a href={`https://${shop.domaine}.${DOMAINE}`} target="_blank"><b>{shop.shopName}</b></a>
                                                                                    </p>

                                                                                    <small>{shop?.address}</small>
                                                                                    <br />
                                                                                    <br />


                                                                                    {
                                                                                        calculateRate(shop.id) ?
                                                                                            <span className="badge"
                                                                                                  title={t('Rated')}
                                                                                                  style={{
                                                                                                      backgroundColor: '#ffd700',
                                                                                                      fontWeight: '200',
                                                                                                      color: '#101010',
                                                                                                  }}>
                                                                                                 {calculateRate(shop.id)}
                                                                                                <i className="ri-star-fill"></i>
                                                                                                </span>
                                                                                            : ''

                                                                                    }

                                                                                    <span className="badge ml-1"
                                                                                          style={{
                                                                                              backgroundColor: '#ccc',
                                                                                              fontWeight: '200',
                                                                                              color: '#101010',
                                                                                          }}
                                                                                          title={t('OpenH')}>{shop?.open_hours}</span>

                                                                                    <span className="badge ml-1"
                                                                                          style={{
                                                                                              backgroundColor: '#ccc',
                                                                                              fontWeight: '200',
                                                                                              color: '#101010',
                                                                                          }}
                                                                                          title={t('CloseH')}>{shop?.close_hours}</span>

                                                                                    <a className="badge ml-1"
                                                                                       target="_blank"
                                                                                       href={`https://www.google.com/maps?q=${shop.lat_long ? JSON.parse(shop.lat_long)[0] : ''},${shop.lat_long ? JSON.parse(shop.lat_long)[1] : ''}`}
                                                                                       style={{
                                                                                           backgroundColor: '#ccc',
                                                                                           fontWeight: '200',
                                                                                           color: '#101010',
                                                                                           cursor: 'pointer',
                                                                                       }} title="Geolocalisation"><i
                                                                                        className="bx bx-map-pin"></i></a>


                                                                                </div>
                                                                            </div>
                                                                        </div>,
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            <div className="row p-t-100 p-b-150"
                                                                 style={{ width: '100%' }}>
                                                                <div className="col-md-12">
                                                                    <h3 className="text-center">{t('EmptyList')}</h3>
                                                                </div>
                                                            </div>
                                                    }
                                                </>
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
            }

        </Layout>
    );
}

const mapStateToProps = (state) => ({
    register: state.registershop,
    home: state.home
});

export default compose(connect(mapStateToProps, {loadShopsList, loadHome}))(withTranslation()(ShopsList));
