import React, {useEffect} from 'react';
import {FedaPay, PayPal} from "../../../../../components";
import {changeCurrency} from "../../../../../helpers/utils";

const _Payment = ({setStep,saveOrder,checkoutData,setCheckoutData}) => {

       const succeedPayment = (details) => {
           const data = checkoutData
           data.payment_id =details.id
           setCheckoutData(data)
           saveOrder(data)
       }


    const errorHandler = (status) => {
        if (status) {
            setStep(1)
        }
    }


    return (
        <section className="tp-checkout-area pb-120" style={{background : "#EFF1F5"}}>
            <div className="container" >

                {
                    checkoutData.payment_method === "PAYPAL-payment" ?

                        <div className='row'>
                            <div className="offset-lg-4 col-lg-4">
                                <PayPal succeedPayment={succeedPayment} total={changeCurrency(checkoutData.total_amount +  parseInt(checkoutData.shipping_charge))}  />
                            </div>
                        </div>


                        : ""


                }


                {
                    checkoutData.payment_method === "MOMO-payment" ?

                        <FedaPay
                            amount={checkoutData.total_amount  + parseInt(checkoutData.shipping_charge)}
                            succeedPayment={succeedPayment}
                            text={""}
                            errorHandler={errorHandler}
                            include={true}
                            style={{ height: "800px", width: "100%", backgroundColor: '#eee' }}
                            user={{
                                email : checkoutData.delivery_email,
                                firstname : checkoutData.delivery_fullname.split(" ")[0],
                                lastname : checkoutData.delivery_fullname.split(" ")[1]
                            }} />

                        : ""


                }


        </div>
        </section>
    );
};

export default _Payment;
