import React,{useState,useEffect} from 'react';
import {attachTokenToHeaders, CLIENT, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import {MiddleLoading} from "../../../../../components";
import axios from "axios";
import {withTranslation} from "react-i18next";
import swal from "@sweetalert/with-react";
import slack from "../../../../../assets/slack.png"
import _ChooseChannel from "./_ChooseChannel";
import LevelAccess from "../../../../../components/LevelAccess";

const SettingsDash = ({t,auth}) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState(null);
    const [channel, setChannel] = useState(null);

    const [nwhatsapp, setNwhatsapp] = useState(false);

    const ulpar = window.location.search

    const urlParams = new URLSearchParams(ulpar);


    useEffect(() => {
       if (urlParams.get('slackstatus') === "success"){
           toast.success(t(urlParams.get('message')))
           getNotificationSettings()
       }
       else if (urlParams.get('slackstatus') === "failed"){
           toast.error(t(urlParams.get('message')))
       }
    }, [window.location.search]);


    useEffect(() => {
        getNotificationSettings()
        return () => {};
    }, []);

    const getNotificationSettings = async () =>{
        await axios.get(SERVER_URL+"store/settings/notify",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setNotifications(response.data.noti)
                setNwhatsapp(response.data.noti?.whatsapp_notify)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    const Notifywhatsapp = async (ev) => {
      if(ev.target.checked){
          swal({
              title: t('WhatsappNotify'),
              text : t('WhatsappNotifyText'),
              buttons: [t("Cancel"), t('Yes')],
              focusConfirm: false,
          }).then(async (result) => {
            if (result){
                setNwhatsapp(true)
                try {
                    const response = await axios.get(`${SERVER_URL}/store/settings/whatsapp/1`,attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                    }
                } catch (error) {
                    console.log(error)
                }
            }else{
                setNwhatsapp(false)

            }
          })

      }
       else{
          setNwhatsapp(false)
          try {
              const response = await axios.get(`${SERVER_URL}/store/settings/whatsapp/2`,attachTokenToHeaders())
              if (response.data.succeed === true) {
                  toast.success(t(response.data.message))
              }
          } catch (error) {
              console.log(error)
          }
      }
    }


    const generateUrl =  (state) => {
        const redirect_uri = "https://4a2d-196-171-58-114.ngrok-free.app/store/slack-integration"
        const userScope = `team:read,chat:write,chat:write.public,chat:write.customize,usergroups:read,channels:read,users:read,channels:join`
        return `https://slack.com/oauth/v2/authorize?scope=${userScope}&state=${state}&client_id=6146719397523.6150007198340&redirect_uri=${redirect_uri}`
    }


    useEffect(() => {
       if (notifications?.slack_notify && !notifications?.slack_channel){
           getChannels()

       }
    }, [notifications]);

    const getChannels = async () =>{
        await axios.get(SERVER_URL+"store/slack/channels/"+auth?.shop.shop_key,attachTokenToHeaders())
            .then((response) =>{
                if (response.data.succeed){
                    setChannel(response.data.channels)
                    setOpen(true)
                }else{
                    toast.error("error")
                }
            })

}

const deleteSlackConfig = async (shop_key) => {
    await swal({
        title : t('Deleting'),
        text : t('SureToDeleteSlackConfig'),
        buttons: [t("Cancel"), t('Yes')],
        dangerMode:true,
        icon : "warning"
    }).then(async (check) => {
        if (check){
            try {
                const response = await axios.delete(`${SERVER_URL}store/slack/delete/`+shop_key,attachTokenToHeaders())
                if (response.data.succeed) {
                    toast.success(t(response.data.message))
                    getNotificationSettings()
                }
            } catch (error) {
                console.log(error)
            }
        }
    });
}



    return (
        <>
            <h5>Notifications</h5>
            <hr/>
            <div className="row"  style={{marginTop : "20px"}}>
                {
                    loading ?
                        <MiddleLoading />
                        :
                        <>
                            {
                                notifications ?
                                    <ul className="list-unstyled mb-0">
                                        <li className="d-flex">
                                            <div className="flex-grow-1">
                                                <label htmlFor="directMessage" className="form-check-label fs-14">{t('BasicNotify')}</label>
                                                <p className="text-muted">{t('BasicNotifyText')}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" disabled={true} role="switch" id="directMessage" defaultChecked={notifications?.basic_notify} />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex mt-2">
                                            <div className="flex-grow-1">
                                                <label className="form-check-label fs-14" htmlFor="emailNotification">
                                                    {t('EmailNotify')}
                                                </label>
                                                <p className="text-muted">{t('EmailNotifyText')}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" disabled={true} type="checkbox" defaultChecked={notifications?.email_notify} role="switch" id="emailNotification"/>
                                                </div>
                                            </div>
                                        </li>

                                        <LevelAccess auth={auth} link={true} level={4}>
                                            <li className="d-flex mt-2">
                                            <div className="flex-grow-1">
                                                <h4 className="form-check-label fs-14" >
                                                    {t('SlackNotify')}
                                                </h4>
                                                <p className="text-muted mt-10">{t('SlackNotifyText')}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                {
                                                  notifications?.slack_notify ?

                                                      <div className="custom__avatar__badge">
                                                          <img src={JSON.parse(notifications?.slack_notify).team.icon.image_34} alt=""/>
                                                          <div className="custom__avatar__badge__element">
                                                              <span>{JSON.parse(notifications?.slack_notify).team.name}</span>
                                                              <span>{ notifications?.slack_channel}</span>
                                                          </div>
                                                          <div className="close" onClick={event => deleteSlackConfig(auth?.shop.shop_key)}>
                                                              <i className="ri-close-fill"></i>
                                                          </div>
                                                      </div>

                                                      :

                                                      <a className="connect_slack" href={generateUrl(auth?.shop.shop_key)}><span><img alt="" height="20" width="20" src={slack} /> {t('ConnectSlack')}</span></a>


                                                }
                                            </div>
                                        </li>
                                        </LevelAccess>

                                        {/*<li className="d-flex mt-2">*/}
                                        {/*    <div className="flex-grow-1">*/}
                                        {/*        <label className="form-check-label fs-14" htmlFor="chatNotification">*/}
                                        {/*            {t('SmsNotify')} <b className="badge badge-warning">{t('Soon')}</b>*/}
                                        {/*        </label>*/}
                                        {/*        <p className="text-muted">{t('SmsNotifyText')} 10 frcs par SMS</p>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="flex-shrink-0">*/}
                                        {/*        <div className="form-check form-switch">*/}
                                        {/*            <input className="form-check-input" type="checkbox" disabled={true} role="switch" defaultChecked={notifications?.sms_notify} id="chatNotification"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}
                                        {/*<li className="d-flex mt-2">*/}
                                        {/*    <div className="flex-grow-1">*/}
                                        {/*        <label className="form-check-label fs-14" htmlFor="purchaesNotification">*/}
                                        {/*            {t('WhatsappNotify')} <b className="badge badge-warning">{t('Soon')}</b>*/}
                                        {/*        </label>*/}
                                        {/*        <p className="text-muted">{t('WhatsappNotifyText')}</p>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="flex-shrink-0">*/}
                                        {/*        <div className="form-check form-switch">*/}
                                        {/*            <input className="form-check-input" type="checkbox" role="switch" disabled={true} onChange={event => Notifywhatsapp(event)} defaultChecked={nwhatsapp} checked={nwhatsapp} id="nwhatsapp"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}



                                    </ul>
                                    :
                                    <h3>Error Loading</h3>
                            }
                        </>
                }


            </div>

            <_ChooseChannel data={channel} open={open} t={t}  setOpen={setOpen} shop_key={auth?.shop.shop_key} created={getNotificationSettings} />
        </>
    );
}

export default  withTranslation() (SettingsDash)
