import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {DeleteCart,Increase,Decrease,ClearCart} from "../../../../store/Subdomain/cart/action";
import {numberWithCommas} from "../../../../helpers/utils";


const  TrialCart = ({t,cart,DeleteCart,Increase,Decrease,ClearCart,auth}) => {

    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (cart.carts?.length > 0){
            calculTotal()
        }else {
            setTotal(0)
        }
    }, [cart]);


    const calculTotal = () => {
        let totales = 0
        cart.carts.map((c) => {
            totales += (c.price * c.qty)
        })
        setTotal(totales)
    }

    const descrease = (k) => {
       Decrease(k)
    }
    const increase = (k) => {
        Increase(k)
    }

    const clearCartContent = () => {
        ClearCart()
        calculTotal()
    }

    const payMyCart = () => {

    }


    return (
        <div >

            <div className="u-s-p-b-60 u-s-p-t-30">

                <div className="section__intro u-s-m-b-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section__text-wrap">
                                    <h2 className="section__heading u-c-secondary">{t('Cart')}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="section__content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 u-s-m-b-30">
                                <div className="table-responsive">
                                    <table className="table-p">
                                        <tbody>

                                        {cart?.carts.length > 0 ?
                                            <>
                                                {
                                                    cart?.carts.map((item,key) =>

                                                        <tr>
                                                            <td>
                                                                <div className="table-p__box">
                                                                    <div className="table-p__img-wrap">
                                                                        <img className="u-img-fluid" src={item.picture} alt=""/>
                                                                    </div>
                                                                    <div className="table-p__info">

                                                                   <span className="table-p__name">
                                                                       <a >{item.name}</a>
                                                                   </span>

                                                                   <span className="table-p__category">
                                                                       <a >{item.category}</a>
                                                                   </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className="table-p__price">{item.price} Frcs</span>

                                                            </td>
                                                            <td>
                                                              <div className="cartIncrement">
                                                                  <span onClick={event => descrease(key)}>-</span>
                                                                  <input type="number" value={item.qty}  onChange={event => ()=>{}} style={{width : "60px",padding : "1px",textAlign : "center"}} />
                                                                  <span onClick={event => increase(key)}>+</span>
                                                              </div>
                                                            </td>
                                                            <td>
                                                                <span className="table-p__price">{numberWithCommas(item.price * item.qty)} Frcs</span>

                                                            </td>
                                                            <td>
                                                                <div className="table-p__del-wrap">
                                                                    <span className="far fa-trash-alt table-p__delete-link cursor-pointer" onClick={event => DeleteCart(key)} ></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                            :
                                            <tr><td rowSpan="5"><p className="text-center">{t('CartEmpty')}</p></td></tr>
                                        }





                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="route-box">
                                    <div className="route-box__g1">
                                        <Link className="route-box__link" to="/products">
                                            <i className="fas fa-long-arrow-alt-left"></i>
                                            <span>{t('Shop')}</span>
                                        </Link>
                                    </div>
                                    <div className="route-box__g2">

                                        <span className="route-box__link cursor-pointer" onClick={event => clearCartContent()}>
                                            <i className="fas fa-trash"></i>
                                            <span>{t('ClearCart')}</span>
                                        </span>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="u-s-p-b-60">
                <div className="section__content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 u-s-m-b-30">
                                <div className="f-cart">
                                    <div className="row">
                                        <div className="offset-lg-8 col-lg-4 col-md-6 u-s-m-b-30">
                                            <div className="f-cart__pad-box">
                                                <div className="u-s-m-b-30">
                                                    <table className="f-cart__table">
                                                        <tbody>
                                                        <tr>
                                                            <td>TOTAL</td>
                                                            <td>{numberWithCommas(total)} Frcs</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div>
                                                    {
                                                        cart?.carts.length > 0?
                                                            <>
                                                                {
                                                                    auth?.isAuthenticated ?
                                                                        <Link className="btn btn-info"   to="/checkout"> {t('ProceedCheckout')}</Link>
                                                                        :
                                                                        <div>
                                                                            <p>{t("AuthBeforeCheckout")}</p>
                                                                            <Link className="btn btn-info"   to="/login"> {t('Login')}</Link>
                                                                        </div>
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

);
}



const mapStateToProps = (state) => ({
    auth: state.subLogin,
    cart : state.cart,
});

export default compose(withRouter, connect(mapStateToProps, { DeleteCart,Increase,Decrease,ClearCart })) (withTranslation()(TrialCart))
