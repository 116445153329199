import React, {useState} from 'react';
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import CustomModal from "../../../../components/Modal/CustomModal";

const _Invitation = ({open,setOpen,created,t,auth,roles}) => {

    const [loading,setLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('InputRequired')),
       role_id: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        email :  '',
         role_id : '',
    }

    const handleSubmit = async (values) => {
        values.shopname = auth.shop.shopName
        values.firstname = auth.me.firstname
        values.lastname = auth.me.lastname
        setLoading(true)
        await axios.post(SERVER_URL+"user/invite",values,attachTokenToHeaders()).then((response)=>{
             setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setOpen(false)
                created()
            }else{
                toast.error(t(response.data.message))
            }
        })


    }



        return (
            <CustomModal open={open} setOpen={setOpen} title={t('InviteCollaborater')} size="md">
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ errors, touched }) =>
                         <Form>
                             <div className="row">
                                 <div>
                                     <label htmlFor="email" className="form-label">{t('Email')} <b className="text-danger">*</b></label>
                                     <Field  className="form-control form-control-lg" id="email" name="email"/>
                                     {errors.email && touched.email ? (
                                         <div className='text-danger'>{errors.email}</div>
                                     ) : null}
                                 </div>

                                 <div className="col-lg-12">
                                     <br/>
                                     <label htmlFor="role_id" className="form-label">{t('Role')} <b className="text-danger">*</b></label>
                                     <Field as="select" className="form-control form-control-lg" id="role_id" name="role_id" >
                                         <option value="">-</option>
                                         {
                                             roles?.length > 0 ?

                                                 <>
                                                     {
                                                         roles?.map((role,key) => <option key={key} value={role.id}>{role.name}</option>)
                                                     }
                                                 </>
                                                 : null
                                         }
                                     </Field>
                                     {errors.role_id && touched.role_id ? (
                                         <div className='text-danger'>{errors.role_id}</div>
                                     ) : null}
                                 </div>
                             </div>

                             <div className="row">
                                 <div className="col-lg-12 pt-3">
                                     <button className="btn btn-success btn-block btn-lg" disabled={loading}>
                                         {
                                             loading ?
                                                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                 :
                                                 <span> {t('Submit')}</span>
                                         }
                                     </button>
                                 </div>
                             </div>

                        </Form>
                     }

                </Formik>
                </CustomModal>
    );
}

export default  withTranslation() (_Invitation)
