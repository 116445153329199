import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {APPNAME, attachTokenToHeaders, Capitalize, numberWithCommas, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import Paginate from "../../../../components/Paginate/Paginate";
import WithdrawMethod from './_withdrawMethod'
import { compose } from 'redux'
import { connect } from 'react-redux'

const AdminPayment = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('Payments')

    const [currentPayment,setCurrentPayment] = useState([]);
    const [payments,setPayments] = useState([])

    const [open, setOpen] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [available,setAvailable] = useState(0)
    const [onWait,setOnWait] = useState(0)
    const [taked,setTaked] = useState(0)
    const [totalAmount,setTotalAmount] = useState(0)

    useEffect(() => {
        getAllPayment()
    },[])

    useEffect(() => {
        setCurrentPayment(payments?.slice(indexOfFirstPost, indexOfLastPost))
    },[payments])



    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);

    const [loading, setLoading] = useState(true);



    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    useEffect(() => {
        setCurrentPayment(payments?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])


    const calculAmountTotal = (data) => {
        let amount_available = 0
        let amount_on_wait = 0
        let amount_taked = 0
        data.forEach(payment => {
            if(payment.statut === 4){
                amount_available += parseInt(payment.amount)
            }
            if(payment.statut !== 4 && payment.statut !== 5){
                amount_on_wait += parseInt(payment.amount)
            }
            if(payment.statut === 5){
                amount_taked += parseInt(payment.amount)
            }
        });
        setAvailable(amount_available)
        setOnWait(amount_on_wait)
        setTaked(amount_taked)
        setTotalAmount(amount_available + amount_on_wait + amount_taked)
    }

    const getAllPayment = async () => {
        await axios.get(SERVER_URL+"admin/payments",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPayments(response.data.payments)
                calculAmountTotal(response.data.payments)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(payments?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentPayment(payments?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = payments.filter(payment => {
                return payment.payment_method.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentPayment(filter)
        }
    }




    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Payments')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Payments')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex mb-3">
                                <div className="flex-grow-1">
                                    <lord-icon src="https://cdn.lordicon.com/fhtaantg.json" trigger="loop"
                                               colors="primary:#405189,secondary:#0ab39c"
                                               style={{ width: "55px", height: "55px" }}></lord-icon>
                                </div>
                            </div>
                            <h3 className="mb-2">
                                <span className="counter-value">{numberWithCommas(available)}</span>
                                <small className="text-muted fs-13">Frcs</small></h3>
                            <h6 className="text-muted mb-0">{t('AvailableBalance')}</h6>
                        </div>
                    </div>

                </div>

                <div className="col-lg-3">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex mb-3">
                                <div className="flex-grow-1">
                                    <lord-icon src="https://cdn.lordicon.com/qhviklyi.json" trigger="loop"
                                               colors="primary:#405189,secondary:#0ab39c"
                                               style={{ width: "55px", height: "55px" }}></lord-icon>
                                </div>

                            </div>
                            <h3 className="mb-2"><span className="counter-value">{numberWithCommas(onWait)}</span>
                                <small className="text-muted fs-13">Frcs</small>
                            </h3>
                            <h6 className="text-muted mb-0">{t('MoneyPending')}</h6>
                        </div>
                    </div>

                </div>

                <div className="col-lg-3">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex mb-3">
                                <div className="flex-grow-1">
                                    <lord-icon src="https://cdn.lordicon.com/yeallgsa.json" trigger="loop"
                                               colors="primary:#405189,secondary:#0ab39c"
                                               style={{ width: "55px", height: "55px" }}></lord-icon>
                                </div>
                            </div>
                            <h3 className="mb-2"><span className="counter-value">{numberWithCommas(taked)}</span>
                                <small className="text-muted fs-13">Frcs</small>
                            </h3>
                            <h6 className="text-muted mb-0">{t('MoneyReceived')}</h6>
                        </div>
                    </div>

                </div>

                <div className="col-lg-3">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex mb-3">
                                <div className="flex-grow-1">
                                    <lord-icon src="https://cdn.lordicon.com/vaeagfzc.json" trigger="loop"
                                               colors="primary:#405189,secondary:#0ab39c"
                                               style={{ width: "55px", height: "55px" }}></lord-icon>
                                </div>
                            </div>
                            <h3 className="mb-2"><span className="counter-value">{numberWithCommas(totalAmount)} </span>
                                <small className="text-muted fs-13">Frcs</small>
                            </h3>
                            <h6 className="text-muted mb-0">{t('TotalAmountReceived')}</h6>
                        </div>
                    </div>

                </div>


            </div>


            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">

                                    <div className="col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light"
                                                   placeholder={t('Search')}
                                                   onChange={event => SearchFilter(event.target.value)} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>

                                    <div className="offset-lg-4 col-lg-4 d-flex justify-content-end">
                                        <button className="btn btn-primary" onClick={event =>  setOpen(true)}>{t('WithdrawMoney')}</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th className="sort">{t('Transaction')} ID</th>
                                        <th className="sort">{t('PaymentMethod')}</th>
                                        <th className="sort">{t('Amount')}</th>
                                        <th className="sort">{t('Object')}</th>
                                        <th className="sort">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="11">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status"
                                                              aria-hidden="true" /> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentPayment?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentPayment?.map((payment, key) =>
                                                                    <tr key={key}>
                                                                        <td>#{payment.transaction}</td>
                                                                        <td>{payment.payment_method}</td>
                                                                        <td>{numberWithCommas(payment.amount)} Francs</td>
                                                                        <td>{t(Capitalize(payment.type.toLowerCase()))}</td>
                                                                        <td>

                                                                            {payment.statut == 1 ?
                                                                                <b className="badge badge-soft-success">{t('Success')}</b>
                                                                                :
                                                                                <b className="badge badge-soft-warning">{t('Pending')}</b>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="11">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={payments?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>

            <WithdrawMethod open={open} t={t} setOpen={setOpen}  amount={available}  paymentMethod={paymentMethod} auth={auth} created={getAllPayment} />


        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(AdminPayment));
