import React, {useRef, useState} from 'react';
import {flatData} from "../../utils/utils";
import {CLIENT} from "../../../helpers/utils";

const AuthorArea = ({block,customStyle,toggleHightLight,t,isEditor}) => {

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const authorAreaRef = useRef()

    const getBlockSize = () => {
        setDimension({top :authorAreaRef?.current?.offsetTop, left : authorAreaRef?.current?.offsetLeft, width : authorAreaRef?.current?.offsetWidth, height : authorAreaRef?.current?.offsetHeight,nodeName : authorAreaRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    return (
        <section className="tp-author-area pt-60 pb-15"
                 ref={authorAreaRef}
                 onMouseEnter={() => isEditor ? getDimension(block,true) : console.log("")}
                 onMouseLeave={() => isEditor ? getDimension(block,false) : console.log("")}
                 style={customStyle}
        >
            <div className="container">
                <div className="tp-author-inner p-relative z-index-1 tp-author-bg-overlay fix"  style={{backgroundColor : "#821F40"}}>

                    <span className="tp-author-shape-1"></span>

                    <div className="tp-author-bg include-bg "  style={{background : `url(${CLIENT}'/assets/img/author/author-bg.jpg')`}}></div>
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="tp-author-wrapper p-relative z-index-1">
                                <div className="tp-author-info-wrapper d-flex align-items-center mb-30">
                                    <div className="tp-author-info-avater mr-10">
                                        <img src={flatData(block.contentEditable,"authorPic")} alt="user"/>
                                    </div>
                                    <div className="tp-author-info">
                                        <h3 className="tp-author-info-title">{flatData(block.contentEditable,"authorName")}</h3>
                                        <span className="tp-author-info-designation">{flatData(block.contentEditable,"authorRole")}</span>
                                    </div>
                                </div>
                                <div className="tp-author-content">
                                    <p>
                                        {flatData(block.contentEditable,"message")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className=" d-flex flex-wrap align-items-center justify-content-lg-end">
                                        <div className="tp-author-brand-item text-center" >
                                            <img src={flatData(block.contentEditable,"partner")} alt=""/>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default AuthorArea;
