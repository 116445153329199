import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ClearCart} from "../../../../store/Subdomain/cart/action";
import axios from "axios";
import {SERVER_URL} from "../../../../helpers/utils";
import {MiddleLoading} from "../../../../components";
import swal from "@sweetalert/with-react";


const  TrialCheckout = ({auth,cart,ClearCart,t}) => {

    const [shop,setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [canContinue, setCanContinue] = useState(false);
    const [method, setMethod] = useState(1);


    const history = useHistory()


    useEffect(() => {
        if (cart.carts?.length > 0){
            calculTotal()
        }
    }, [cart]);


    const [data, setData] = useState({
         shop_id : shop?.id,
         cart_content : JSON.stringify(cart.carts),
         customer_id : auth?.user?.id,
         payment_id : "",
         payment_method : "",
         shipping_status : false,
         shipping_charge : 0,
         total_amount : 0,
         discount_percent : 0,
         tax : 0,
        delivery_fullname :  auth?.user?.firstname+" "+auth?.user?.lastname,
        delivery_address: "",
        delivery_city: "",
        delivery_country : "Togo",
        delivery_latlng : null,
    });

    useEffect(() => {
        getCountries()
    }, []);

    const getCountries = async () => {
        await axios.get(SERVER_URL+"front/countries").then((response)=>{
            if (response.data.succeed){
                setCountries(response.data.countries)
            }
        })
    }

    const calculTotal = () => {
        let totales = 0
        cart.carts.map((c) => {
            totales += (c.price * c.qty)
        })
        setData({...data, total_amount: totales})
    }

    const payOnDelivery = async () => {
        await swal({
            title : t('Validation'),
            text : t('SureToSubmitCheckout'),
            buttons: [t("Cancel"), t('Yes')],
            icon : "success"
        }).then(async (check) => {
            if (check){
                const payload = {
                    ...data,
                    payment_method: "CASHONDELIVERY-payment",
                    payment_id: "100010001"
                }
                handleSubmit(payload)
            }
        });


    }

    const handleSubmit = async (payload) => {
      if (data.payment !== ""){
          setLoading(true);
          setCanContinue(false);
          await axios.post(SERVER_URL+"front/save/order",payload).then((response)=>{
              if (response.data.succeed){
                  ClearCart();
                  history.push("/success");
              }else{
                  setCanContinue(true);
                  setLoading(false);
                  history.push("/error");
                  window.location.href = "/error";
              }
          })
      }
    }

    const succeedPaymentTrial = (detail,method) => {
        const payload = {
            ...data,
            payment_method: method,
            payment_id: detail.id
        }
        handleSubmit(payload)
    }




    return (
        <div className=" ">


            <div className="u-s-p-b-60 u-s-p-t-30">

                {
                    loading ?
                        <MiddleLoading />
                        :
                        <>
                            {
                                canContinue ?
                                 <div>
                                     <h1>Payer</h1>
                                 </div>
                                :

                                    <>
                                        <div className="section__content">
                                            <div className="container">
                                                <div className="checkout-f">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <h3 className="checkout-f__h1">{t('DeliveryInformation')}</h3>
                                                            <div className="checkout-f__delivery">
                                                                <div className="u-s-m-b-30">
                                                                    <hr/>
                                                                    <div className="gl-inline">
                                                                        <div className="u-s-m-b-15">
                                                                            <label className="gl-label" htmlFor="lastname">{t('Lastname')} <b className="text-danger">*</b></label>
                                                                            <input className="front front--primary-style" id="lastname" value={data.lastname} onChange={event => setData({...data,lastname: event.target.value})} type="text"  />
                                                                            {data.lastname == "" ? <small className="text-danger">{t('InputRequired')}</small> : null}
                                                                        </div>
                                                                        <div className="u-s-m-b-15">
                                                                            <label className="gl-label" htmlFor="firstname">{t('Firstname')} <b className="text-danger">*</b></label>
                                                                            <input className="front front--primary-style" type="text" id="firstname" value={data.firstname} onChange={event => setData({...data,firstname: event.target.value})} />
                                                                            {data.firstname == "" ? <small className="text-danger">{t('InputRequired')}</small> : null}
                                                                        </div>
                                                                    </div>


                                                                    <div className="u-s-m-b-15">

                                                                        <label className="gl-label" htmlFor="country">{t('Country')} <b className="text-danger">*</b></label>
                                                                        <select className="form-select" id="country" value={data.delivery_country} onChange={event => setData({...data,delivery_country: event.target.value})} >
                                                                            <option selected value="">{t('ChooseCountry')}</option>
                                                                            {countries?.map((country,key)=> <option key={key} value={country.name}>{country.name}</option>)}
                                                                        </select>

                                                                        {data.delivery_country == ""  ? <small className="text-danger">{t('InputRequired')}</small> : null}
                                                                    </div>


                                                                    <div className="u-s-m-b-15">
                                                                        <label className="gl-label" htmlFor="billing-town-city"> {t('City')} <b className="text-danger">*</b></label>
                                                                        <input className="front front--primary-style" type="text" value={data.delivery_city} onChange={event => setData({...data,delivery_city: event.target.value})} id="billing-town-city" />
                                                                        {data.delivery_city == ""  ? <small className="text-danger">{t('InputRequired')}</small> : null}
                                                                    </div>


                                                                    <div className="u-s-m-b-15">
                                                                        <label htmlFor="billing-street-optional" className="gl-label">{t('Address')}</label>
                                                                        <textarea className="form-control" rows="4" cols="30" value={data.delivery_address} onChange={event => setData({...data,delivery_address: event.target.value})}  placeholder={t('Address')}/>
                                                                        {data.delivery_address == ""  ? <small className="text-danger">{t('InputRequired')}</small> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <h3 className="checkout-f__h1">{t('OrderSummary')}</h3>
                                                             <hr/>

                                                            <div className="o-summary">
                                                                <div className="o-summary__section u-s-m-b-30">
                                                                    <div className="o-summary__item-wrap gl-scroll">
                                                                        {cart?.carts.length > 0 ?
                                                                            <>
                                                                                {
                                                                                    cart?.carts.map((item,key) =>
                                                                                        <div className="o-card">
                                                                                            <div className="o-card__flex">
                                                                                                <div className="o-card__img-wrap">
                                                                                                    <img className="u-img-fluid" src={item.picture} alt=""/>
                                                                                                </div>
                                                                                                <div className="o-card__info-wrap">
                                                                                                    <span className="o-card__name"><a>{item.name}</a></span>
                                                                                                    <span className="o-card__quantity">Qty x {item.qty}</span>
                                                                                                    <span className="o-card__price">{item.price} Frcs </span></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                            :
                                                                            <p className="text-center">{t('CartEmpty')}</p>
                                                                        }



                                                                    </div>
                                                                </div>
                                                                <div className="o-summary__section u-s-m-b-30">
                                                                    <div className="o-summary__box">
                                                                        <h3 className="checkout-f__h1">{t('ShippingBilling')}</h3>
                                                                        <div className="ship-b">

                                                                            <span className="ship-b__text">{t('ShipTo')} : </span>
                                                                            <div className="ship-b__box u-s-m-b-10">
                                                                                <p className="ship-b__p">{data.delivery_address} {data.delivery_city} {data.delivery_country}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="o-summary__section u-s-m-b-30">
                                                                    <div className="o-summary__box">
                                                                        <table className="o-summary__table">
                                                                            <tbody>
                                                                            <tr>
                                                                                <td>GRAND TOTAL</td>
                                                                                <td>{data?.total_amount} Frcs</td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    {
                                                        data.firstname && data.lastname && data.delivery_address && data.delivery_city && data.delivery_country ?
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="o-summary__section u-s-m-b-30">
                                                                        <div className="o-summary__box">
                                                                            <h3 className="checkout-f__h1">PAYMENT INFORMATION</h3>
                                                                            <hr/>
                                                                            <div className="checkout-f__payment">
                                                                                <div className="u-s-m-b-10">


                                                                                    <div className="radio-box">
                                                                                        <input type="radio" id="cash-on-delivery" name="payment" value={1} defaultChecked={true} onChange={event => setMethod(event.target.value)} />
                                                                                        <div className="radio-box__state radio-box__state--primary">
                                                                                            <label className="radio-box__label" htmlFor="cash-on-delivery">{t('CashonDelivery')}</label>
                                                                                        </div>
                                                                                    </div>


                                                                                    <span className="gl-text u-s-m-t-6">{t('PayOnDelivery')}</span>
                                                                                </div>

                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="o-summary__section u-s-m-b-30">
                                                                        <div className="o-summary__box">


                                                                            {method == 1 &&

                                                                                <button className="btn btn-success" onClick={event => payOnDelivery()}>
                                                                                    {t('ValidOrder')}
                                                                                </button>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :

                                                           null

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </>

                            }
                        </>
                }

            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
    cart : state.cart,
});

export default compose(withRouter, connect(mapStateToProps,{ClearCart} )) (withTranslation()(TrialCheckout))
