import {GOOGLE_CLIENT_ID} from "./keys";
import {PUBLIC_GOOGLE_OAUTH_REDIRECT_URL} from "./utils";

const  getGoogleOAuthURL = (domaine) => {

    const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";

    const options = {
        redirect_uri: "https://api.kiiako.io/api/v1/sessions/oauth/google",
        client_id: GOOGLE_CLIENT_ID,
        access_type: "offline",
        response_type: "code",
        prompt: "consent",
        scope: [
            "https://www.googleapis.com/auth/userinfo.profile",
            "https://www.googleapis.com/auth/userinfo.email",
        ].join(" "),
        state : domaine.split('.').shift(),
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs.toString()}`;
}

export default getGoogleOAuthURL;
