import React from 'react';
import { withTranslation } from "react-i18next";
import { Capitalize, CheckPermissions } from "../helpers/utils";
import { compose } from "redux";
import { connect } from "react-redux";
import Authorized from "./Authorized/Authorized";

const Authorization = ({ module, auth, children, single = true, bloc = [], component = false, trial = false, type }) => {
    const { plan, me, permissions } = auth || {};
    const isTrialUser = plan?.pricing_id === 1;
    const hasGestRole = me?.gest_role;

    if (!isTrialUser) {
        if (hasGestRole) {
            const userPermissions = JSON.parse(permissions?.content || '{}');
            const isAuthorized = CheckPermissions(userPermissions, Capitalize(module), single, bloc, type);

            if (isAuthorized) {
                return  children
            }else {
                if (component === true){
                    return <Authorized />
                }else{
                    return null;
                }
            }

        }
        return children;
    } else {
        if (trial && isTrialUser) {
            return children;
        }
        return component ? <Authorized /> : null;
    }
};

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(Authorization);
