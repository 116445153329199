import React from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal} from "../index";

const ProgressModal = ({setOpen,open,title,progress}) => {
    return (
        <CustomModal setOpen={setOpen} open={open} title={title}  centered={true}>
            <div className="progress animated-progress custom-progress progress-label">
                <div className="progress-bar bg-success" role="progressbar" style={{width: `${progress}%`}} aria-valuenow="50"
                     aria-valuemin="0" aria-valuemax="100">
                    <div className="label">{progress}%</div>
                </div>
            </div>
        </CustomModal>
    );
}

export default withTranslation()(ProgressModal);