import React from 'react';
import {withTranslation} from "react-i18next";
import person from "../../assets/authorized.png"
import {Link} from "react-router-dom";
import {LINK_STORE_PACKAGES} from "../../routes/type";
import {compose} from "redux";
import {connect} from "react-redux";

const AppAuthorized = ({t,auth}) => {
    return (
        <div className='row'>
            <div className="offset-lg-1 col-lg-10">
                <div className="card" style={{height : "500px"}}>
                    <div className="card-body p-5">
                        <div className="row p-5">
                            <div className="offset-lg-2 col-lg-5 pt-5">
                                <br/>
                                <br/>
                                <h2>{t('NotAuthorized')}</h2>
                                <br/>
                                <p>{t('AuthorizedTextApp')}</p>

                                <Link to={LINK_STORE_PACKAGES} className="btn btn-success">{t('VisiteMarketPlace')}</Link>
                            </div>

                            <div className="col-lg-3">
                                <img src={person} style={{width : "300px"}} alt=""/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});

export default   compose(connect(mapStateToProps)) (withTranslation()(AppAuthorized));
