import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import _Dashboard from "./_dashboard";
import _Profil from "./_Profil";
import _Orders from "./_orders";


const  TrialDashboard = ({auth,t}) => {

    const [step, setStep] = useState(1);

    return (
        <div  style={{background : "#f4f4f4"}}>
            <div className="u-s-p-b-60 u-s-p-t-60">

                <div className="section__content">
                    <div className="dash">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="offset-lg-1 col-lg-2 col-md-12">

                                    <div className="dash__box dash__box--bg-white dash__box--shadow u-s-m-b-30">
                                        <div className="dash__pad-1">

                                            <span className="dash__text u-s-m-b-16">Hello, {auth?.user.firstname} {auth?.user.lastname}</span>
                                            <ul className="dash__f-list">
                                                <li><span className={`cursor-pointer ${step === 1 ? "dash-active" : null}`} onClick={event => setStep(1)} >{t('Dashboard')}</span></li>
                                                 <hr/>
                                                <li><span className={`cursor-pointer ${step === 2 ? "dash-active" : null}`} onClick={event => setStep(2)}>{t('Orders')}</span></li>
                                                <hr/>
                                                <li><span className={`cursor-pointer ${step === 3 ? "dash-active" : null}`} onClick={event => setStep(3)}>{t('Profile')}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    {step === 1 ?<_Dashboard t={t} auth={auth} /> : null}
                                    {step === 2 ? <_Orders t={t} auth={auth} /> : null}
                                    {step === 3 ? <_Profil t={t} auth={auth} /> : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
    auth: state.subLogin,
});

export default compose(withRouter, connect(mapStateToProps )) (withTranslation()(TrialDashboard))
