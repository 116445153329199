import React, {useRef, useState} from 'react';
import "./freebocks.css"
import {flatData} from "../../utils/utils";
import {Link} from "react-router-dom";

const CardBlock = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const cardblock = useRef()

    const getBlockSize = () => {
        setDimension({top :cardblock?.current?.offsetTop, left : cardblock?.current?.offsetLeft, width : cardblock?.current?.offsetWidth, height : cardblock?.current?.offsetHeight,nodeName : cardblock?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }



    return (
        <section
            className="pt-50 pb-50"
            style={customStyle}
            ref={cardblock}
            onMouseEnter={() => isEditor && getDimension(block, true)}
            onMouseLeave={() => isEditor && getDimension(block, false)}
        >
            <div className="container">
                <div className="row" style={{fontFamily: "Poppins"}}>
                    {
                        flatData(block.contentEditable, 'leftPosition') ?
                            <div className="col-lg-4">
                                <img src={flatData(block.contentEditable, 'img')} className="img-thumbnail" alt=""/>
                            </div>
                            : ""
                    }

                    <div className="col-lg-8">
                        <h2 style={{
                            fontSize: "42px",
                            fontWeight: "bold"
                        }}>{flatData(block.contentEditable, 'title')}</h2>
                        <br/>
                        <p>{flatData(block.contentEditable, 'text')}</p>
                    </div>

                    {
                        !flatData(block.contentEditable, 'leftPosition') ?
                            <div className="col-lg-4">
                                <img src={flatData(block.contentEditable, 'img')} className="img-thumbnail" alt=""/>
                            </div>
                            : ""
                    }
                </div>
            </div>

        </section>
    )
}

export default CardBlock;