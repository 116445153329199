import axios from 'axios';

import {
   CUSTOMERS_SHOP_LOADING,CUSTOMERS_SHOP_FAIL,CUSTOMERS_SHOP_SUCCESS
} from './type';
import {attachTokenToHeaders, SERVER_URL} from "../../helpers/utils";



export const getCustomers = (id) => async (dispatch) => {
    dispatch({
        type: CUSTOMERS_SHOP_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'customers/'+id,attachTokenToHeaders());
            dispatch({
                type: CUSTOMERS_SHOP_SUCCESS,
                payload: { list : response.data.list},
            });


    } catch (err) {
        dispatch({
            type: CUSTOMERS_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};


