import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import Paginate from "../../../../components/Paginate/Paginate";
import {compose} from "redux";
import {connect} from "react-redux";
import {getSubcategories} from "../../../../store/Categories/action";
import swal from "@sweetalert/with-react";
import axios from "axios";
import {toast} from "react-toastify";
import CreateOrUpdate from "./CreateOrUpdate";
import ImageUpdate from "./ImageUpdate";
import Authorization from "../../../../components/Authorization";


const SubCategories = ({t,auth}) => {
    document.title = APPNAME+" :: Sous-catégories"


    const [currentSubcategories, setCurrentSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [categories,setCategories] = useState([]);



    useEffect(() => {
        getSubcategories(auth.shop?.id)
    },[auth])



    useEffect(() => {
        setCurrentSubcategories(subcategories?.slice(indexOfFirstPost, indexOfLastPost))
    },[subcategories])




    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [loading,setLoading] = useState(true)
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])
    const [popOpen,setPopOpen] = useState(false)
    const [subcategoryId,setSubcategoryId] = useState("")

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);




    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;




    const getSubcategories = async (shop_id) => {
        await axios.get(SERVER_URL+"subcategories/"+shop_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setSubcategories(response.data.list)
                setCategories(response.data.categories)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }



    const addSubCategory = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editSubCategory = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(subcategories?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteCategorie'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}subcategorie/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed == true) {
                        toast.success(t(response.data.message))
                        getSubcategories(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }


    useEffect(() => {
        setCurrentSubcategories(subcategories?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])

    const updatePicture = (id) =>{
        setPopOpen(true)
        setSubcategoryId(id)
    }



    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledCategorie') : t('SureToEnabledCategorie'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}subcategorie/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getSubcategories(auth.shop.id)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentSubcategories(subcategories?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = subcategories.filter(sub => {
                return sub.subname.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentSubcategories(filter)
        }
    }


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(subcategories.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ==  currentSubcategories.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }
    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListSubcategorie'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}subcategorie/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getSubcategories(auth.shop.id)
                        setIsCheckAll(false)
                         setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }

    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('SubCategories')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('SubCategories')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <Authorization single={true} trial={true}  module="subcategories" component={true} >
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('SubCategories')}</h5>
                                <Authorization single={true}  module="subcategories"  trial={true}  type="create" >
                                <div className="flex-shrink-0">
                                    <button className="btn btn-primary add-btn" onClick={event => addSubCategory()}>
                                        <i className="ri-add-line align-bottom me-1"></i> {t('Add')}
                                    </button>
                                </div>
                                </Authorization>
                            </div>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">
                                    <div className="col-lg-4">
                                        {
                                            isCheck.length > 0 &&

                                            <>
                                                {
                                                    Gloading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                        <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col" style={{width: "50px"}}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="selectAll"
                                                       id="selectAll"
                                                       onChange={handleSelectAll}
                                                       checked={isCheckAll}
                                                />
                                            </div>
                                        </th>
                                        <th className="sort">{t('Picture')}</th>
                                        <th className="sort">{t('Category')}</th>
                                        <th className="sort">{t('SubCategorie')}</th>
                                        <th className="sort">{t('Description')}</th>
                                        <th className="sort">Status</th>
                                        <th className="sort">{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="7">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentSubcategories?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentSubcategories.map((subcat,key)=>
                                                                        <tr key={key}>
                                                                            <th scope="row">
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input"
                                                                                           type="checkbox"
                                                                                           name={subcat.category}
                                                                                           id={subcat.id}
                                                                                           onChange={handleClick}
                                                                                           checked={isCheck.includes(subcat.id)}
                                                                                    />
                                                                                </div>
                                                                            </th>
                                                                            <td>
                                                                                {
                                                                                    subcat.image
                                                                                        ?
                                                                                        <div className="avatar-group">
                                                                                    <span className="avatar-group-item">
                                                                                    <img src={`${SERVER_IMG_URL}${auth.shop.shop_key}/subcategories/${subcat.image}`} alt="" className="rounded-circle avatar-xxs"/>
                                                                                    </span>
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <i className="cursor-pointer text-primary" style={{textDecoration : "underline"}} onClick={event =>  updatePicture(subcat.id)}><small>{t('Add')}</small></i>
                                                                                        </div>

                                                                                }
                                                                            </td>
                                                                            <td><b>{subcat.category}</b></td>
                                                                            <td>{subcat.subname}</td>
                                                                            <td>{subcat.description ? subcat.description.substring(0,50)+"..." : <i className="text-primary"><small>{t('Empty')}</small></i>}</td>
                                                                            <td>
                                                                                {subcat.statut == 1
                                                                                    ? <span className="badge badge-soft-success text-uppercase">{t('Public')}</span>
                                                                                    :  <span className="badge badge-soft-danger text-uppercase">{t('Private')}</span>
                                                                                }
                                                                            </td>
                                                                            <td className="priority">
                                                                                <ul className="list-inline  mb-0">
                                                                                    <Authorization single={true}  module="subcategories"  trial={true}  type="action" >
                                                                                    <li className="list-inline-item">
                                                                                        {subcat.statut == 1
                                                                                            ?    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(subcat.id,2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                            :

                                                                                            <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(subcat.id,1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                        }
                                                                                    </li>
                                                                                    </Authorization>

                                                                                    <Authorization single={true}  module="subcategories"  trial={true}  type="update" >
                                                                                    <li className="list-inline-item">
                                                                                     <span className="edit-item-btn cursor-pointer" onClick={event => editSubCategory(subcat)}>
                                                                                         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                     </span>
                                                                                    </li>
                                                                                    </Authorization>

                                                                                    <Authorization single={true}  module="subcategories"  trial={true}  type="delete" >
                                                                                    <li className="list-inline-item">
                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => deleteModal(subcat.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                    </li>
                                                                                    </Authorization>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="7">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={subcategories?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>
            </Authorization>

            <CreateOrUpdate open={open} setOpen={setOpen} created={getSubcategories} isEdit={isEdit} updateData={updateData} auth={auth} categories={categories} />
            <ImageUpdate open={popOpen} setOpen={setPopOpen} created={getSubcategories} auth={auth} t={t}  category={subcategoryId}  />

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
    categorie : state.categorie
});
export default   compose(connect(mapStateToProps, { getSubcategories })) (withTranslation()(SubCategories));
