import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../components/SellerLayout";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import axios from "axios";
import { checkCurrentUrlSubdomain, SERVER_URL } from '../../../../helpers/utils'
import { Fade } from 'react-awesome-reveal'

const TermConditions = ({t}) => {

    const [shop, setShop] = useState({})
    const [terms, setTerms] = useState({})
    const [loading, setLoading] = useState(true)


    useEffect(()=>{
        checkSubdomain()

    },[])

    const checkSubdomain = async () => {
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShop(response.data)
                getTermAndCondition(response.data.shop.id)
            }
        })
    }

    const getTermAndCondition = async (shop_id) => {
        await axios.get(SERVER_URL+"seller/terms/"+shop_id).then((response)=>{
            if (response.data.succeed){
                setTerms(response.data.term)
            }
            setLoading(false)
        })
    }

    return(
        <Fade>
            <SellerLayout>
                <section className="sign-in-section p-t-50 p-b-2">
                    <div className="container-fluid ">
                        <h3 className="text-center" style={{fontSize: "40px"}}>{t('GeneralConditions')}</h3>
                        <div className="row justify-content-center" style={{marginTop: "50px", marginBottom: "250px"}}>
                            <div className="col-md-8">
                                {terms?.[localStorage.getItem("I18N_LANGUAGE")]}
                            </div>
                        </div>
                    </div>
                </section>

            </SellerLayout>
        </Fade>
    )
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
});

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(TermConditions))