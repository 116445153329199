import React, {useEffect, useState} from 'react';

import giftBox from "../../../../../assets/admin/images/giftbox.png"
import axios from "axios";
import {ShareSocial} from 'react-share-social'
import {attachTokenToHeaders, CLIENT, format_date, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import LevelAccess from "../../../../../components/LevelAccess";
const SettingsSponsor = ({t,auth}) => {

    const [shortenedLink, setShortenedLink] = useState( `${CLIENT}/store/register/sponsorship/${auth.shop.shop_key}`)
    const [loading, setLoading] = useState(false)
    const [sponsorship, setSponsorship] = useState([])


    useEffect(()=>{
        getSponsorShipList()
    },[auth])

     useEffect(()=>{
        if (localStorage.getItem('sponsorship')){
             setShortenedLink(localStorage.getItem('sponsorship'))
         }  else{
            generateSponsorShipLink()
         }
     },[localStorage.getItem('sponsorship')])

    const style = {
        root: {
            background: 'linear-gradient(45deg, #299cdb 30%, #405189 90%)',
            borderRadius: 3,
            border: 0,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            color: 'white',
            width : "100%"
        },
        copyContainer: {
            border: '1px solid blue',
            background: 'rgb(0,0,0,0.7)'
        },
        title: {
            color: 'aquamarine',
            fontStyle: 'italic'
        }
    };



    const getSponsorShipList = async () => {
        setLoading(true)
        await axios.get(SERVER_URL+"sponsorship/list/"+auth.shop.shop_key,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setSponsorship(response.data.sponsor)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    const generateSponsorShipLink = async () => {
        const link = `${CLIENT}store/register/sponsorship/${auth.shop.shop_key}`

        try {
            const response = await axios(
                `https://api.shrtco.de/v2/shorten?url=${link}`
            );
            setShortenedLink(response.data.result.full_short_link);
            localStorage.setItem('sponsorship',response.data.result.full_short_link)

        } catch (e) {
            console.log(e);
            setLoading(false)

        }
    };


    return (
        <LevelAccess auth={auth} link={false}>
            <h4>{t('SponsorshipSettings')}</h4>
            <hr/>
            <div className="row">
            <div className="card-body">
                <ul className="nav nav-tabs nav-border-top nav-border-top-primary mb-3" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" data-bs-toggle="tab" href="#home1" role="tab" aria-selected="true">
                            {t('InviteNewShop')}
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" href="#profile1" role="tab" aria-selected="false" tabIndex="-1">
                            {t('ListOfReferrals')}
                        </a>
                    </li>
                </ul>


                <div className="tab-content text-muted">
                    <div className="tab-pane active show" id="home1" role="tabpanel">
                        <div className="d-flex">
                            <div className="flex-grow-1 ms-2">
                                <div className="col-lg-12">
                                    <p className="text-muted lh-base">{t('InviteNewShopText')}</p>
                                </div>

                                <div className="col-lg-12">

                                    <ShareSocial
                                        url={shortenedLink}
                                        socialTypes= {['facebook','twitter','whatsapp','linkedin','telegram']}
                                        onSocialButtonClicked={ data => console.log(data)}
                                        style={style}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="profile1" role="tabpanel">
                        <div className="d-flex">
                            <div className="flex-grow-1 ">
                                <div className="row">
                                    <div className="mb-4">
                                        <table className="table align-middle table-nowrap mb-0">
                                            <thead className="table-light text-muted">
                                            <tr>
                                                <th className="sort">{t('Shop')}</th>
                                                <th className="sort">{t('CreatedAt')}</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list form-check-all">

                                            {
                                                loading ?
                                                    <tr>
                                                        <td colSpan="2">
                                                            <p className="text-center">
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    :

                                                    <>
                                                        {
                                                            sponsorship?.length > 0
                                                                ?
                                                                <>
                                                                    {
                                                                        sponsorship.map((list,key)=>
                                                                            <tr key={key}>
                                                                                <td>{list.shopName}</td>
                                                                                <td>{format_date(list.created_at)}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        <p className="text-center">{t('EmptyList')}</p>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </>
                                            }


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </LevelAccess>
    );
}

export default SettingsSponsor;
