import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {loadShopPricing} from "../../../../store/Pricing/action";
import {withTranslation} from "react-i18next";
import line2 from '../../../../assets/front/img/particle/title-line-2.png'
import {numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {LINK_NEWS} from "../../../../routes/type";
import moment from "moment";

const Pricing = ({t,loadShopPricing}) => {

    const [loading, setLoading] = useState(false)
    const [articles, setArticles] = useState([])

    useEffect(()=>{
        getArticles()
    },[])

    const getArticles = async () => {
        await axios.get(SERVER_URL+"articles").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setArticles(response.data.articles)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }



    return (
        <section className="latest-news-section bg-soft-primary p-t-130 p-b-130">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-7 col-sm-10">
                        <div className="latest-news-text-block m-b-50 p-r-50 p-r-lg-0">
                            <div className="common-heading tagline-boxed-two title-line m-b-40">
                                <span className="tagline">Blog & News</span>
                                <h2 className="title">{t('NewsIndexText')} <span> {t('Informed')}   <img src={line2} alt="Image" className="Line"/></span></h2>
                            </div>
                            <p>{t('BlogText')}</p>

                            <Link to={LINK_NEWS} className="template-btn bg-primary-color-2 m-t-35">{t('News')}<i className="far fa-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row justify-content-center latest-news-v1 color-secondary">
                            {
                                articles.slice(0,2).map((item,key) =>
                                    <div className="col-md-6 col-sm-9" key={key}>
                                        <div className="latest-news-box m-t-md-30">
                                            <div className="post-thumbnail">
                                                <img src={SERVER_IMG_URL+"blogs/"+item.image} alt={item.title} />
                                            </div>
                                            <div className="post-content">
                                                <ul className="post-meta">
                                                    <li><a href="#"><i className="far fa-calendar-alt"></i>{moment(item?.created_at).fromNow()}</a></li>
                                                    <li><a href="#"><i className="fas fa-user"></i>{item.author}</a></li>
                                                </ul>
                                                <h4 className="title"><Link to={`/news/${item.slug}`} >{item.title}</Link></h4>
                                                <Link to={`/news/${item.slug}`}  className="read-more-btn">{t('ReadMore')} <i className="fas fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    list_pricing: state.list_pricing
});


export default compose(connect(mapStateToProps, {loadShopPricing}))(withTranslation()(Pricing));
