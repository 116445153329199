import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {
    CustomerAddCart,
    CustomerDeleteCart,
    CustomerClearCart
} from "../../store/Subdomain/CustomerCart/action";
import {withTranslation} from "react-i18next";
import {CLIENT, numberWithCommas} from "../../helpers/utils";
import {Link} from "react-router-dom";
import {SUB_CART, SUB_CHECKOUT} from "../../pages/Sub/Seller/routes/type";

const CartRight = ({customCart,CustomerClearCart,CustomerDeleteCart,open,setOpen,t}) => {

    const [subtotal, setSubtotal] = useState(0)

    useEffect(() => {
        calculCart()
    }, [customCart]);

    const calculCart = () => {
        let total = 0;
        customCart?.carts.map((item) =>  total += (item.price * item.qty))
        setSubtotal(total)
    }

    const emptyCart = () => {
        CustomerClearCart()
    }


    return (
        <>
            <div className={`body-overlay ${open ? "opened" : ""}`} onClick={event => setOpen(false)}></div>
            <div className={`cartmini__area ${open ? "cartmini-opened" : ""}`}>
                <div className="cartmini__wrapper d-flex justify-content-between flex-column">
                    <div className="cartmini__top-wrapper">
                        <div className="cartmini__top p-relative">
                            <div className="cartmini__top-title">
                                <h4>{t('ShoppingCart')}</h4>
                            </div>
                            <div className="cartmini__close">
                                <button type="button" onClick={event => setOpen(false)} className="cartmini__close-btn cartmini-close-btn"><i className="fal fa-times"></i></button>
                            </div>
                        </div>

                        <div className="cartmini__widget">
                            {
                                customCart?.carts.length > 0 ?
                                    <>
                                        {
                                            customCart?.carts.map((item,idx) =>
                                          <div className="cartmini__widget-item" key={idx}>
                                            <div className="cartmini__thumb">
                                                <a>
                                                    <img src={item.picture} alt=""/>
                                                </a>
                                            </div>
                                            <div className="cartmini__content">
                                                <h5 className="cartmini__title">{item.name}</h5>
                                                <div className="cartmini__price-wrapper">
                                                    <span className="cartmini__price">{numberWithCommas(item.price)} Francs</span>
                                                    <span className="cartmini__quantity ml-10">x {item.qty}</span>
                                                </div>
                                            </div>
                                            <span className="cartmini__del cursor-pointer" onClick={event => CustomerDeleteCart(idx)} ><i className="ri-delete-bin-3-fill text-danger"></i></span>
                                    </div>
                                            )


                                        }

                                        </>
                                    :

                                    <div className="cartmini__empty text-center d-none">
                                        <img src={`${CLIENT}/assets/img/product/cartmini/empty-cart.png`} alt=""/>
                                        <p>Your Cart is empty</p>
                                        <a href="shop.html" className="tp-btn">Go to Shop</a>
                                    </div>

                            }
                        </div>


                    </div>
                    {
                        customCart?.carts.length > 0?
                            <div className="cartmini__checkout">
                                <div className="cartmini__checkout-title mb-30">
                                    <h4>Subtotal:</h4>
                                    <span>{numberWithCommas(subtotal)} Francs</span>
                                </div>
                                <div className="cartmini__checkout-btn">
                                    <Link to={SUB_CART} className="tp-btn mb-10 w-100">{t('Cart')}</Link>
                                    <button className="tp-btn tp-btn-border w-100" onClick={event => emptyCart()} >{t('Empty')}</button>
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth : state.subLogin,
    customCart : state.customCart
});
export default   compose(connect(mapStateToProps,{CustomerAddCart,CustomerDeleteCart,CustomerClearCart})) (withTranslation()(CartRight));

