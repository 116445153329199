import React from 'react';
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from 'lodash';
import {toast} from "react-toastify";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../../helpers/utils";

const Profil = ({t,auth,loadSubMe,setStep}) => {

    const countryOptions = [
        { value: 'benin' , label: "Benin"},
        { value: "cote d'ivoire" , label: "Cote d'ivoire"},
        { value: 'mali' , label: "Mali"},
        { value: 'niger' , label: "Niger"},
        { value: 'senegal' , label: "Sénégal"},
        { value: 'togo' , label: "Togo"},
        { value: 'others' , label: t('Other')},
    ]

    const animatedComponents = makeAnimated();

    const initialValuesProfile = {
        lastname: auth?.user.lastname,
        firstname: auth?.user.firstname,
        email: auth?.user.email,
        phone: auth?.user.phone,
        city: auth?.user.city,
        country: auth?.user.country.toLowerCase(),
    }

    const initialValuesPassword = {
        old_password: "",
        password: "",
        conf_password: ""
    }

    const profilvalidationSchema = Yup.object().shape({
        firstname: Yup.string().required(t('InputRequired')),
        lastname: Yup.string().required(t('InputRequired')),
        email: Yup.string().required(t('InputRequired')),
        phone: Yup.string().required(t('InputRequired')),
        country: Yup.string().required(t('InputRequired')),
        city: Yup.string().required(t('InputRequired')),
    });

    const passwordValidationSchema = Yup.object().shape({
        old_password: Yup.string()
            .required(t('PasswordRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('PasswordContaint')
            )
        , password: Yup.string()
            .required(t('PasswordRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('PasswordContaint')
            )
        ,
        conf_password: Yup.string()
            .required(t('ConfPasswordRequired'))
            .oneOf([Yup.ref('password'), null], t('PasswordSame'))
    });

    const handleProfilSubmit = async (values) => {
        if (_.isEqual(initialValuesProfile,values)){
            toast.info(t("NothingChange"))
        }
        else{
            await axios.put(SERVER_URL+"seller/update/profil/"+auth?.user.id,values,attachTokenToHeaders(false,true)).then((response)=>{
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setStep(6)
                    loadSubMe()
                }else{
                    toast.error(t(response.data.message))
                }
            })
        }
    }

    const handlePasswordSubmit = async (values) => {
        if (_.isEqual(initialValuesPassword,values)){
            toast.info(t("NothingChange"))
        }
        else{
            await axios.put(SERVER_URL+"seller/update/password/"+auth?.user.id,values,attachTokenToHeaders(false,true)).then((response)=>{
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setStep(6)
                    loadSubMe()
                }else{
                    toast.error(t(response.data.message))
                }
            })
        }
    }


    return (
        <>
            <div className="profile__info">
                <h3 className="profile__info-title">{t('PersonalDetails')}</h3>
                <div className="profile__info-content">
                    <Formik initialValues={initialValuesProfile} onSubmit={handleProfilSubmit} validationSchema={profilvalidationSchema}>
                        {({ errors, touched,values,setFieldValue }) =>
                            <Form>
                        <div className="row">
                            <div className="col-xxl-6 col-md-6">
                                <div className="profile__input-box">
                                    <div className="profile__input">
                                        <Field  id="lastname" name="lastname"  />
                                        <span>
                                      <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                         <path d="M15.5 17.6C15.5 14.504 12.3626 12 8.5 12C4.63737 12 1.5 14.504 1.5 17.6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                   </span>
                                    </div>
                                    {errors.lastname && touched.lastname ? (
                                        <div className='text-danger'>{errors.lastname}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-xxl-6 col-md-6">
                                <div className="profile__input-box">
                                    <div className="profile__input">
                                        <Field  id="firstname" name="firstname"  />
                                        <span>
                                      <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                         <path d="M15.5 17.6C15.5 14.504 12.3626 12 8.5 12C4.63737 12 1.5 14.504 1.5 17.6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                   </span>
                                    </div>
                                    {errors.firstname && touched.firstname ? (
                                        <div className='text-danger'>{errors.firstname}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-xxl-6 col-md-6">
                                <div className="profile__input-box">
                                    <div className="profile__input">
                                        <Field readOnly={true} disabled={true} style={{background : "#EEE"}}  id="email" name="email"  />
                                        <span>
                                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M13 14.6H5C2.6 14.6 1 13.4 1 10.6V5C1 2.2 2.6 1 5 1H13C15.4 1 17 2.2 17 5V10.6C17 13.4 15.4 14.6 13 14.6Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                         <path d="M13 5.3999L10.496 7.3999C9.672 8.0559 8.32 8.0559 7.496 7.3999L5 5.3999" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                   </span>
                                    </div>

                                    {errors.email && touched.email ? (
                                        <div className='text-danger'>{errors.email}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-xxl-6 col-md-6">
                                <div className="profile__input-box">
                                    <div className="profile__input">
                                        <Field  id="phone" name="phone"  />
                                        <span>
                                      <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M13.9148 5V13C13.9148 16.2 13.1076 17 9.87892 17H5.03587C1.80717 17 1 16.2 1 13V5C1 1.8 1.80717 1 5.03587 1H9.87892C13.1076 1 13.9148 1.8 13.9148 5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                         <path opacity="0.4" d="M9.08026 3.80054H5.85156" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                         <path opacity="0.4" d="M7.45425 14.6795C8.14522 14.6795 8.70537 14.1243 8.70537 13.4395C8.70537 12.7546 8.14522 12.1995 7.45425 12.1995C6.76327 12.1995 6.20312 12.7546 6.20312 13.4395C6.20312 14.1243 6.76327 14.6795 7.45425 14.6795Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                   </span>
                                    </div>
                                    {errors.phone && touched.phone ? (
                                        <div className='text-danger'>{errors.phone}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-xxl-6 col-md-6">
                                <div className="profile__input-box">
                                    <div className="profile__input">
                                        <Field  id="city" name="city"  />
                                        <span>
                                      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M7.99377 10.1461C9.39262 10.1461 10.5266 9.0283 10.5266 7.64946C10.5266 6.27061 9.39262 5.15283 7.99377 5.15283C6.59493 5.15283 5.46094 6.27061 5.46094 7.64946C5.46094 9.0283 6.59493 10.1461 7.99377 10.1461Z" stroke="currentColor" strokeWidth="1.5"/>
                                         <path d="M1.19707 6.1933C2.79633 -0.736432 13.2118 -0.72843 14.803 6.2013C15.7365 10.2663 13.1712 13.7072 10.9225 15.8357C9.29079 17.3881 6.70924 17.3881 5.06939 15.8357C2.8288 13.7072 0.263493 10.2583 1.19707 6.1933Z" stroke="currentColor" strokeWidth="1.5"/>
                                      </svg>
                                   </span>
                                    </div>
                                    {errors.city && touched.city ? (
                                        <div className='text-danger'>{errors.city}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-xxl-6 col-md-6">
                                <div className="profile__input-box">
                                    <div className="">
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            defaultValue={values.country}
                                            isDisabled={false}
                                            onChange={event => setFieldValue('country', event.value)}
                                            id="country"
                                            className="tp_select"
                                            options={countryOptions}
                                        />
                                    </div>
                                    {errors.country && touched.country ? (
                                        <div className='text-danger'>{errors.country}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-xxl-12">
                                <div className="profile__btn">
                                    <button type="submit" className="tp-btn">{t('Update')}</button>
                                </div>
                            </div>
                        </div>
                            </Form>
                        }

                    </Formik>
                </div>
            </div>
            <br/>
            <hr/>
            <br/>
            <h3>{t('UpdatePassword')}</h3>
            <div className="profile__password">
                <Formik initialValues={initialValuesPassword} onSubmit={handlePasswordSubmit} validationSchema={passwordValidationSchema}>
                    {({ errors, touched,values,setFieldValue }) =>
                        <Form>
                           <div className="row">
                        <div className="col-xxl-12 mt-20">
                            <div className="tp-profile-input-box">
                                <div className="tp-contact-input">
                                    <Field name="old_password" id="old_pass" type="password"/>
                                </div>
                                <div className="tp-profile-input-title">
                                    <label htmlFor="old_pass">{t('OldPassword')}</label>
                                </div>
                                {errors.old_password && touched.old_password ? (
                                    <div className='text-danger'>{errors.old_password}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-xxl-6 col-md-6">
                            <div className="tp-profile-input-box">
                                <div className="tp-profile-input">
                                    <Field name="password" id="password" type="password"/>
                                </div>
                                <div className="tp-profile-input-title">
                                    <label htmlFor="password">{t('NewPassword')}</label>
                                </div>
                                {errors.password && touched.password ? (
                                    <div className='text-danger'>{errors.password}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-xxl-6 col-md-6">
                            <div className="tp-profile-input-box">
                                <div className="tp-profile-input">
                                    <Field name="conf_password" id="conf_password" type="password"/>
                                </div>
                                <div className="tp-profile-input-title">
                                    <label htmlFor="conf_password">{t('ConfPassword')}</label>
                                </div>
                                {errors.conf_password && touched.conf_password ? (
                                    <div className='text-danger'>{errors.conf_password}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-xxl-6 col-md-6">
                            <div className="profile__btn">
                                <button type="submit" className="tp-btn">{t('Update')}</button>
                            </div>
                        </div>
                    </div>
                      </Form>
                    }

                </Formik>
            </div>
        </>

    );
};

export default withTranslation()(Profil)
