import React, {useEffect, useState} from 'react';
import {CustomModal} from "../../../components";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../helpers/utils";
import {toast} from "react-toastify";

const CopyPage = ({item,setOpen,open,t}) => {

    const [templates, setTemplates] = useState([])
    const getAllTemplate = async () => {
        await axios.get(SERVER_URL+"admin/templates",attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                setTemplates(response.data.template)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    useEffect(() => {
        getAllTemplate()
    }, []);


    return (
        <CustomModal setOpen={setOpen} open={open} title={t('CopyPageContent')} size="md">
           <div className="row">
               <div className="col-lg-12">
                   <div className="dottedcard">
                       <h5>* {item?.name}</h5>
                       <p className="text-muted">{t('CopyPageTo')}</p>
                   </div>
               </div>

               <div className="col-lg-12">
                   <label htmlFor="">Template</label>
                   <select name="" id="" className="form-select">
                       <option value="">-</option>
                       {
                           templates?.map((template,key) =>
                               <option value={template.hash_key} key={key}>{template.name}</option>
                           )
                       }
                   </select>
               </div>

               <div className="col-lg-12 mt-20">
                   <button className="btn btn-block btn-success">{t('Copy')}</button>
               </div>
           </div>
        </CustomModal>
    )
}

export default withTranslation()(CopyPage)
