import {
    TEMPLATE_SHOP_LOADING,TEMPLATE_SHOP_SUCCESS,TEMPLATE_SHOP_FAIL
} from './type';

const initialState = {
    isLoading : true,
    templates : [],
    error: null,
};


 const TemplateListReducer  =  (state = initialState, { type, payload }) => {
    switch (type) {
        case TEMPLATE_SHOP_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case TEMPLATE_SHOP_SUCCESS:
            return {
                ...state,
                templates: payload.templates,
                isLoading: false,
            };

        case TEMPLATE_SHOP_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error,
            };

        default:
            return state;
    }
}

export default TemplateListReducer