import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../../utils/utils";

const Paragraph = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const horizontal = useRef()

    const getBlockSize = () => {
        setDimension({top :horizontal?.current?.offsetTop, left : horizontal?.current?.offsetLeft, width : horizontal?.current?.offsetWidth, height : horizontal?.current?.offsetHeight,nodeName : horizontal?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);

    return(

        <section className="pt-10 pb-10"
                 style={customStyle}
        >
            <div className="container "
                 ref={horizontal}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
            >
                <hr/>
            </div>
        </section>
    )
}

export default Paragraph;