import React, {Fragment, useEffect, useRef, useState} from 'react';
import * as Yup from "yup";
import axios from "axios";
import {
    Capitalize,
    checkCurrentUrlSubdomain,
    CLIENT,
    numberWithCommas,
    SERVER_URL,
} from '../../../../../helpers/utils'
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";
import {withTranslation} from "react-i18next";
import {SUB_LOGIN} from "../../routes/type";
import {Link} from "react-router-dom";

const _Block = ({auth,setStep,customCart,total,t,subtotal,setTotal,saveOrder,setCheckoutData}) => {

    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const ulpar = window.location.search
    const urlParams = new URLSearchParams(ulpar);
    const [shop, setShop] = useState(null);
    const [shipping, setShipping] = useState("")
    const [shippingStatus, setShippingStatus] = useState(false)
    const [coupon, setCoupon] = useState(null)
    const [couponMinus, setCouponMinus] = useState(0)
    const [paymentmethod, setPaymentMethod] = useState([])
    const [key, setKey] = useState("")


    useEffect(() => {
        checkSubdomain()
    },[subtotal])

    const checkSubdomain = async () => {
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            if (response.data.succeed){
                setShop(response.data.shop)
                loadDatas(response.data.shop)
                getPaymentMethod(response.data.shop)
            }
        })
    }
    const getPaymentMethod = async (shop) => {
        await axios.get(SERVER_URL + "seller/payment/method/" + shop.id).then((response) => {
            if (response.data.succeed) {
                setPaymentMethod(response.data.method)
            }
        })
    }
    const loadDatas = (shop) => {
        if (urlParams.get('coupon')){
            handleCoupon(urlParams.get('coupon'),shop)
        }
        if(urlParams.get('shipping_type')){
            if (urlParams.get('shipping_type') === "receive-on-shop"){
                setShipping(0)
                setShippingStatus(false)
            }else if (["FixedAmount","ByKm","FreeDelivery"].includes(urlParams.get('shipping_type'))) {
                setShipping(JSON.parse(shop?.config).value)
                setShippingStatus(true)
            }
            else{
                setShipping(0)
                setShippingStatus(false)
            }
        }
    }

    const handleCoupon = async (coupon,shop) => {

        const data = {
            coupon : coupon,
            shop_id: shop?.id,
            customer_id: auth?.user.id
        }

        await axios.post(SERVER_URL+"coupon/used",data).then((response)=>{
            if (response.data.succeed){
                setCoupon(response.data.coupon)
                if (subtotal)  ApplyCoupon(response.data.coupon)
            }else{
                toast.error(t(response.data.message))
            }
        })

    }

    const ApplyCoupon =  (data) => {
        if (total){
            if(data.type === 1){
                setCouponMinus(parseInt(data.amount))
                setTotal(total - parseInt(data.amount))
            }else{
                const minus = (total * parseInt(data.amount)) / 100
                setCouponMinus(minus)
                setTotal(total - minus)
            }
        }else{
            ApplyCoupon(data)
        }
    }


    const validationSchema = Yup.object().shape({
        firstname : Yup.string().required(t('InputRequired')),
        lastname : Yup.string().required(t('InputRequired')),
        country : Yup.string().required(t('InputRequired')),
        city : Yup.string().required(t('InputRequired')),
        streetAddress : Yup.string().required(t('InputRequired')),
        phone : Yup.string().required(t('InputRequired')),
        email : Yup.string().required(t('InputRequired')),
    })

    const billingAddress = {
        firstname : auth?.user?.firstname,
        lastname : auth?.user?.lastname,
        country : auth?.user?.country,
        city : auth?.user?.city,
        streetAddress : "",
        phone : auth?.user?.phone,
        email : auth?.user?.email,
        save_address : false,
        default_address : false,
        note : ""
    }

    const handleSubmit = (values, { setSubmitting }) => {
        setLoading(true)
        setSubmitting(true)
        const {firstname, lastname, country, city, streetAddress, phone, email,save_address,note,default_address} = values
        const data = {
            shop_id: shop?.id,
            cart_content: JSON.stringify(customCart.carts),
            customer_id: auth?.user.id,
            shipping_status: shippingStatus,
            shipping_charge: shipping,
            total_amount: total,
            discount_percent: couponMinus,
            delivery_status: 1,
            delivery_fullname: firstname + ' ' + lastname,
            delivery_country: country,
            delivery_city: city,
            delivery_phone: phone,
            delivery_email: email,
            delivery_address: streetAddress,
            save_address: save_address,
            default_address: default_address,
            note: note,
            payment_method : key,
            coupon : coupon ? coupon : false,
        }

        if (shippingStatus){
            setLoading(false)
            if (["FixedAmount","ByKm","FreeDelivery"].includes(JSON.parse(shop?.config).option)){
                setStep(2)
                data.shipping_data = shipping
                setCheckoutData(data)
            }
        }else{
           if (!key){
              toast.error(t('Please Select Payment Method'))
                setLoading(false)
               setSubmitting(false)
           }else{
               if (["CASHONDELIVERY-payment","CHEQUERECEIVERY-payment","DIRECTBANK-payment"].includes(data.payment_method)) saveOrder(data)
               else {
                   setStep(3)
                   setCheckoutData(data)
                   setLoading(false)
                   setSubmitting(false)
               }

           }
        }
    }

    return (
      <>
        {customCart.carts?.length > 0 ? (
          <section
            className="tp-checkout-area pb-120"
            style={{ background: "#EFF1F5" }}
          >

              {
                 !auth?.isAuthenticated
                  &&
                  <div className="container">
                      <div className="row">
                          <div className="col-xl-7 col-lg-7">
                              <div className="tp-checkout-verify">
                                  <div className="tp-checkout-verify-item">
                                      <p className="tp-checkout-verify-reveal">
                                          {t('LogBeforeCheckout')} <Link to={SUB_LOGIN} className="tp-checkout-login-form-reveal-btn"  style={{
                                          color: shop?.color,
                                          fontWeight: "bold"
                                      }}>{t('Login')}</Link>
                                      </p>


                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              }

              <Formik
                  initialValues={billingAddress}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
              >
                  {({errors, touched, isValid}) => (
                      <Form>
                          <div className="container">
                              <div className="row">
                                  <div className="col-lg-7">
                                      <div className="tp-checkout-bill-area">
                                          <h3 className="tp-checkout-bill-title">
                                              {t("BillingDetails")}
                                          </h3>

                                     <div className="tp-checkout-bill-form">
                            <div className="tp-checkout-bill-inner">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="tp-checkout-input">
                                    <label>
                                      {t("Lastname")} <span>*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="lastname"
                                      placeholder={t("Lastname")}
                                    />
                                    {errors.lastname && touched.lastname ? (
                                      <div className="text-danger">
                                        {errors.lastname}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="tp-checkout-input">
                                    <label>
                                      {t("Firstname")} <span>*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="firstname"
                                      placeholder={t("Firstname")}
                                    />
                                    {errors.firstname && touched.firstname ? (
                                      <div className="text-danger">
                                        {errors.firstname}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="tp-checkout-input">
                                    <label>Country / Region </label>
                                    <Field
                                      type="text"
                                      name="country"
                                      placeholder={t("Togo")}
                                    />
                                    {errors.country && touched.country ? (
                                      <div className="text-danger">
                                        {errors.country}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="tp-checkout-input">
                                    <label>{t("Address")}</label>
                                    <Field
                                      type="text"
                                      name="streetAddress"
                                      placeholder="House number and street name"
                                    />
                                    {errors.streetAddress &&
                                    touched.streetAddress ? (
                                      <div className="text-danger">
                                        {errors.streetAddress}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="tp-checkout-input">
                                    <label>{t("City")}</label>
                                    <Field
                                      type="text"
                                      name="city"
                                      placeholder={t("Lomé")}
                                    />
                                    {errors.city && touched.city ? (
                                      <div className="text-danger">
                                        {errors.city}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="tp-checkout-input">
                                    <label>
                                      {t("Phone")} <span>*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="phone"
                                      placeholder="+228 90 00 00 00"
                                    />
                                    {errors.phone && touched.phone ? (
                                      <div className="text-danger">
                                        {errors.phone}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="tp-checkout-input">
                                    <label>
                                      {t("Email")} <span>*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="email"
                                      placeholder=""
                                    />
                                    {errors.email && touched.email ? (
                                      <div className="text-danger">
                                        {errors.email}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                {/*<div className="col-md-12">*/}
                                {/*  <div className="tp-checkout-option-wrapper">*/}
                                {/*    <div className="tp-checkout-option">*/}
                                {/*      <Field*/}
                                {/*        type="checkbox"*/}
                                {/*        id="save_address"*/}
                                {/*        defaultChecked={false}*/}
                                {/*        name="save_address"*/}
                                {/*      />*/}
                                {/*      <label htmlFor="save_address">*/}
                                {/*        {t("SaveAddress")}*/}
                                {/*      </label>*/}
                                {/*    </div>*/}

                                {/*    <div className="tp-checkout-option">*/}
                                {/*      <Field*/}
                                {/*        type="checkbox"*/}
                                {/*        id="default_address"*/}
                                {/*        defaultChecked={false}*/}
                                {/*        name="default_address"*/}
                                {/*      />*/}
                                {/*      <label htmlFor="default_address">*/}
                                {/*        {t("UseThisAsDefaultAddress")}*/}
                                {/*      </label>*/}
                                {/*    </div>*/}
                                {/*  </div>*/}
                                {/*</div>*/}

                                <div className="col-md-12">
                                  <div className="tp-checkout-input">
                                    <label>{t("orderNote")}</label>
                                    <Field
                                      as="textarea"
                                      placeholder={t("orderNoteText")}
                                      name="note"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="tp-checkout-place white-bg">
                          <h3 className="tp-checkout-place-title">
                            {t("YourOrder")}
                          </h3>

                          <div className="tp-order-info-list">
                            <ul
                              style={{ paddingLeft: "0em", marginTop: "20px" }}
                            >
                              <li className="tp-order-info-list-header">
                                <h4>
                                  <b>{t("Product")}</b>
                                </h4>
                                <h4>
                                  <b>Total</b>
                                </h4>
                              </li>

                              {customCart.carts?.map((item, key) => (
                                <li
                                  key={key}
                                  className="tp-order-info-list-desc"
                                >
                                  <p className="text-muted">
                                    <span
                                      style={{
                                        fontWeight: "100",
                                        fontSize: "14px",
                                        color: "#3d3a3a"
                                      }}
                                    >
                                      {item.name}
                                    </span>{" "}
                                    <span
                                      className="bold"
                                      style={{
                                        color: shop?.color,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {" "}
                                      x {item.qty}
                                    </span>
                                  </p>
                                  <span>
                                    {numberWithCommas(item.price * item.qty)}{" "}
                                    Francs
                                  </span>
                                </li>
                              ))}

                              <br />
                              <br />

                              {coupon?.code ? (
                                <li className="tp-order-info-list-subtotal">
                                  <span>
                                    Coupon <br /> <small>{coupon?.code}</small>
                                  </span>
                                  <span style={{ color: shop?.color }}>
                                    {numberWithCommas(couponMinus)} Francs
                                  </span>
                                </li>
                              ) : (
                                ""
                              )}

                              <li className="tp-order-info-list-shipping">
                                <span>{t("Shipping")}</span>
                                <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-end">
                                  {shipping !== 0 && shop?.config ? (
                                    <span>
                                      <input
                                        id="local_pickup"
                                        defaultChecked={true}
                                        type="radio"
                                        name="shipping"
                                      />
                                      <label htmlFor="local_pickup">
                                        {" "}
                                        {
                                          JSON.parse(shop?.config).value
                                        } Frcs{" "}
                                        {t(JSON.parse(shop?.config).option)}
                                      </label>
                                    </span>
                                  ) : (
                                    <span>
                                      <input
                                        id="free_shipping"
                                        type="radio"
                                        defaultChecked={true}
                                        name="shipping"
                                      />
                                      <label htmlFor="free_shipping">
                                        {t("TakeOrderOnShop")}
                                      </label>
                                    </span>
                                  )}
                                </div>
                              </li>

                              <li className="tp-order-info-list-total">
                                <span>{t("Subtotal")}</span>
                                <span>{numberWithCommas(subtotal)} Francs</span>
                              </li>

                              <li className="tp-order-info-list-subtotal">
                                <span>Total</span>
                                <span
                                  style={{
                                    color: shop?.color,
                                    fontWeight: "bold"
                                  }}
                                >
                                  {numberWithCommas(total)} Francs
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="tp-checkout-payment">
                            {paymentmethod
                              .slice(0)
                              .reverse()
                              .map((item, idx) => (
                                <Fragment key={idx}>
                                  {item.param !== "PAYPAL" ? (
                                    <div className="tp-checkout-payment-item">
                                      <input
                                        type="radio"
                                        id={item.param + "-payment"}
                                        defaultChecked={false}
                                        onClick={event =>
                                          setKey(item.param + "-payment")
                                        }
                                        name="payment"
                                      />
                                      <label htmlFor={item.param + "-payment"}>
                                        {t(
                                          Capitalize(item.param.toLowerCase())
                                        )}
                                      </label>
                                      {item.param + "-payment" == key ? (
                                        <div
                                          className="tp-checkout-payment-desc cash-on-delivery"
                                          style={{ display: "block" }}
                                        >
                                          <p>{t(item.param + "-PAYMENT")}</p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    <div className="tp-checkout-payment-item paypal-payment">
                                      <input
                                        type="radio"
                                        id="paypal"
                                        defaultChecked={false}
                                        onClick={event =>
                                          setKey(item.param + "-payment")
                                        }
                                        name="payment"
                                      />
                                      <label htmlFor="paypal">
                                        PayPal{" "}
                                        <img
                                          src={
                                            CLIENT +
                                            "/builder/icon/payment-option.png"
                                          }
                                          alt=""
                                        />{" "}

                                      </label>
                                    </div>
                                  )}
                                </Fragment>
                              ))}
                          </div>
                          <div className="tp-checkout-agree">
                            <div className="tp-checkout-option">
                              <input
                                id="read_all"
                                defaultChecked={false}
                                onClick={event =>
                                    key !== "" ? setDisabled(!event.target.checked) : null
                                }
                                type="checkbox"
                              />
                              <label htmlFor="read_all">
                                {t("AcceptTerms")}
                              </label>
                            </div>
                          </div>
                          {auth?.isAuthenticated ? (
                            <div className="tp-checkout-btn-wrapper">
                              <button
                                type="submit"
                                disabled={loading | disabled}
                                className="tp-checkout-btn w-100"
                                style={{
                                  background: disabled ? "#AAA" : shop?.color
                                }}
                              >
                                {loading ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    />{" "}
                                    {t("Loading")}...
                                  </>
                                ) : (
                                  <span>{t("PlaceOrder")}</span>
                                )}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
        ) : (
          <div className="mt-100 mb-100">
            <div className="u-s-p-y-60">
              <div className="section__content">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 u-s-m-b-30">
                      <div className="empty">
                        <div className="empty__wrap">
                          <span
                            className="empty__big-text"
                            style={{ color: shop?.color }}
                          >
                            {t("NothingInCard")}
                          </span>
                          <span className="empty__text-1">
                            {t("NothingInCardText")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
};

export default withTranslation()(_Block)
