import React, {useCallback, useEffect, useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {APPNAME, attachTokenToHeaders, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {FilePond} from "react-filepond";
import {EditorState, convertToRaw, ContentState, convertFromHTML} from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html'

const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,categories}) => {

    const [loading,setLoading] = useState(false)
    const [files,setFiles] = useState( [])
    const [description,setDescription] = useState(null)
    const [editorState, setEditorState] = useState(null)


    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t('InputRequired')),
        category_id: Yup.string().required(t('InputRequired')),
        author: Yup.string().required(t('InputRequired')),
        short: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        title: isEdit ? updateData.title : '',
        category_id: isEdit ? updateData.category_id : '',
        author: isEdit ? updateData.author : APPNAME,
        short: isEdit ? updateData.short : "",
    }


    useEffect(() => {
      isEdit ? setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(updateData?.content)))) : setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML("<p>Description...</p>"))))
      isEdit ? setFiles(SERVER_IMG_URL+"blogs/thumb/"+updateData?.image) : setFiles([])
    }, [isEdit]);


    const handleSubmit = async (values) => {
        setLoading(true)

        const data = new FormData()
        data.append("folder","blogs")
        data.append("type","admin")
        data.append("title",values.title)
        data.append("category_id",values.category_id)
        data.append("author",values.author)
        data.append("short",values.short)
        data.append("description",description)
        data.append('image',files[0])


        if (isEdit){
            await axios.put(SERVER_URL+"admin/blog/edit/"+updateData.id,data,attachTokenToHeaders(true,false)).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"admin/blog/add",data,attachTokenToHeaders(true,false)).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created()
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    const handleEditorChange = (data) => {
        setEditorState(data);
        let html  = draftToHtml(convertToRaw(editorState.getCurrentContent()))
       setDescription(html)
    };



    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Article')}</> : <>{t('Edit')} {t('Article')}</>}  size="xl">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched,values }) =>
                    <Form>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div>
                                            <label htmlFor="file" className="form-label">{t('Picture')}</label>
                                            <FilePond
                                                files={files}
                                                allowMultiple={true}
                                                maxFiles={1}
                                                name="file"
                                                id="file"
                                                onupdatefiles={(fileItems) => {
                                                    setFiles(fileItems.map((fileItem) => fileItem.file))
                                                }}
                                                labelIdle={t('DragNdrop')}
                                            />

                                        </div>

                                        <div>
                                            <label htmlFor="title" className="form-label">{t('Name')} <b className="text-danger">*</b></label>
                                            <Field  className="form-control form-control-lg" id="title" name="title"/>
                                            {errors.title && touched.title ? (
                                                <div className='text-danger'>{errors.title}</div>
                                            ) : null}
                                        </div>

                                        <div>
                                            <br/>
                                            <label htmlFor="category_id" className="form-label">{t('Categorie')} <b className="text-danger">*</b></label>
                                            <Field as="select" className="form-select form-control-lg" id="title" name="category_id">
                                                <option value="">-</option>
                                                {
                                                    categories?.length > 0 ?
                                                        <>
                                                            {
                                                                categories.map((category,key) => <option key={key} value={category.id}>{category.category}</option>)
                                                            }
                                                        </>
                                                        : ""
                                                }
                                            </Field>
                                            {errors.category_id && touched.category_id ? (
                                                <div className='text-danger'>{errors.category_id}</div>
                                            ) : null}
                                        </div>

                                        <div>
                                            <br/>
                                            <label htmlFor="title" className="form-label">{t('Author')} <b className="text-danger">*</b></label>
                                            <Field  className="form-control form-control-lg" id="author" name="author"/>
                                            {errors.author && touched.author ? (
                                                <div className='text-danger'>{errors.author}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div  className="col-lg-8">
                                       <div className="row">
                                           <div>
                                               <label htmlFor="short"  className="form-label">{t('ShortDescription')} <b className="text-danger">*</b></label>
                                               <Field  as="textarea" maxLength={255}  className="form-control" id="short" name="short"/>
                                              <p className="text-right"> <small><b className={values?.short?.length > 254 ? "badge badge-danger" : "badge badge-info"}>{values?.short?.length} / 255</b></small></p>
                                               {errors.short && touched.short ? (
                                                   <div className='text-danger'>{errors.short}</div>
                                               ) : null}
                                           </div>
                                           <div>
                                               <Editor
                                                   editorState={editorState}
                                                   toolbarClassName="toolbarClassName"
                                                   wrapperClassName="wrapperClassName"
                                                   editorClassName="editorClassName"
                                                   onEditorStateChange={handleEditorChange}
                                               />

                                               {/*{errors.description && <span className="text-danger">{t('InputRequired')}</span>}*/}
                                           </div>
                                       </div>
                                    </div>
                              </div>


                        <div className="row">
                            <div className="col-lg-10 pt-3">
                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                            </div>
                            <div className="col-lg-2 pt-3">
                                <button className="btn btn-success btn-block" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>
        </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)
