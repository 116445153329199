import React from 'react';
import {withTranslation} from "react-i18next";

import './loading.css';

const RegLoading = ({t}) => {
    return (
        <div className="loading_content_center_reg">
            <div className=""><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</div>
        </div>
    );
};

export default withTranslation() (RegLoading)
