export const SUB_INDEX = "/"
export const SUB_CONTACT = "/contact-us"
export const SUB_ABOUT = "/about-us"
export const SUB_SERVICE = "/services"
export const SUB_LOGIN = "/login"
export const SUB_REGISTER = "/register"
export const SUB_VERIFY_PASS = "/forgot-password"
export const SUB_CART = "/cart"
export const SUB_CHECKOUT = "/checkout"
export const SUB_WISHLIST = "/wishlist"
export const SUB_PRODUCTS = "/shop/products"
export const SUB_CATEGORY = "/shop/categories"
export const SUB_TERMS = "/terms-conditions"





export const SUB_CUSTOMER_DASHBOARD = "/user-dashboard"
export const SUB_CUSTOMER_PROFIL = "/user-profil"

