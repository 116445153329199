import React from 'react';
import {withTranslation} from "react-i18next";
import {services} from "../../../../helpers/utils";

const Service = ({t}) => {
    return (
        <section className="cc-service-area p-t-30 p-b-100">
            <div className="container">
                <div className="common-heading text-center m-b-70">
                    <h2 className="title m-b-15">Services</h2>
                    <div className="row justify-content-center text-center m-b-20">
                        <div className="col-xl-6 col-lg-8">
                            <p>{t('OurServicesPro')}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {services.map((service,key) =>
                        <div className="col-xl-4 col-md-6" key={key}>
                            <div className="cc-service-item wow fadeInUp" data-wow-delay="0.2s">
                                <div className="content">
                                    <h4><b>{service[localStorage.getItem('I18N_LANGUAGE')]}</b></h4>
                                    <p>
                                        {service[localStorage.getItem('I18N_LANGUAGE') == "fr" ? "contenu" : "content"]}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
}

export default withTranslation()(Service)
