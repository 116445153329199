import React, {useRef, useState} from 'react';
import {CLIENT} from "../../../helpers/utils";
import {flatData} from "../../utils/utils";
import {Link} from "react-router-dom";

const Banner2 = ({block,t,toggleHightLight,isEditor,customStyle}) => {
    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const banner2Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :banner2Ref?.current?.offsetTop, left : banner2Ref?.current?.offsetLeft, width : banner2Ref?.current?.offsetWidth, height : banner2Ref?.current?.offsetHeight,nodeName : banner2Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    return (
        <section className="tp-banner-area pt-60 pb-15"
                 ref={banner2Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
                 style={customStyle}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-7">
                        <div className="tp-banner-item tp-banner-height p-relative mb-30 z-index-1 fix" >
                            <div className="tp-banner-thumb include-bg transition-3"  style={{background : `url('${flatData(block.contentEditable,'image')}') no-repeat`}} ></div>
                            <div className="tp-banner-content">
                                <span>{flatData(block.contentEditable,'discount')}</span>
                                <h3 className="tp-banner-title">
                                    <Link to={isEditor ? "#" : flatData(block.contentEditable,'link')}>{flatData(block.contentEditable,'product1')}</Link>
                                </h3>
                                <div className="tp-banner-btn">
                                    <Link to={isEditor ? "#" : flatData(block.contentEditable,'link')} className="tp-link-btn">{flatData(block.contentEditable,'linkText')}
                                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9998 6.19656L1 6.19656" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.75674 0.975394L14 6.19613L8.75674 11.4177" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="tp-banner-item tp-banner-item-sm tp-banner-height p-relative mb-30 z-index-1 fix">
                            <div className="tp-banner-thumb include-bg transition-3" style={{background : `url('${flatData(block.contentEditable,'image2')}')`}}></div>
                            <div className="tp-banner-content">
                                <h3 className="tp-banner-title">
                                    <Link to={isEditor ? "#" : flatData(block.contentEditable,'link2')}>{flatData(block.contentEditable,'product2')}</Link>
                                </h3>
                                <p>{flatData(block.contentEditable,'discount2')}</p>
                                <div className="tp-banner-btn">
                                    <Link to={isEditor ? "#" : flatData(block.contentEditable,'link2')} className="tp-link-btn"> {flatData(block.contentEditable,'linkText2')}
                                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9998 6.19656L1 6.19656" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.75674 0.975394L14 6.19613L8.75674 11.4177" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner2;
