import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../../components/SellerLayout";
import {Link} from "react-router-dom";
import {SUB_CUSTOMER_DASHBOARD} from "../../routes/type";
import {withTranslation} from "react-i18next";
import {
    attachTokenToHeaders,
    Capitalize,
    checkCurrentUrlSubdomain,
    numberWithCommas,
    SERVER_URL,
} from '../../../../../helpers/utils'
import axios from "axios";
import SubLoading from "../../../../../components/Loading/SubLoading";
import {toast} from "react-toastify";
import { Fade } from 'react-awesome-reveal'

const OrderDetail = ({t}) => {

    const order_id = window.location.pathname.split('/').pop()

    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+t('Tracked')


    const [products, setProducts] = useState([])
    const [shop, setShop] = useState({});
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        loadOrder(order_id)
    }, []);

    useEffect(()=>{
        checkSubdomain()

    },[])



    const checkSubdomain = async () =>{
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
             setLoading(false)
            if (response.data.succeed){
                setShop(response.data)
            }
        })
    }



    const loadOrder = (order_id) => {

        axios.get(SERVER_URL+'seller/track/detail/'+order_id,attachTokenToHeaders(false,true))
            .then((response) => {
                setProducts(response.data.products)
            })
    }

    const downloadFile = (filename) => {

        const data = {
            shop_key : shop.shop_key,
            filename : filename,
            type : "products"
        }

        axios.post(SERVER_URL+'seller/download/file',data,attachTokenToHeaders(false,true,true))
            .then((response) => {
               if (response){
                   const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                   const link = document.createElement('a');
                   link.href = downloadUrl;
                   link.setAttribute('download', filename)
                   document.body.appendChild(link);
                   link.click();
                   link.remove();
                   window.URL.revokeObjectURL(downloadUrl);
                   toast.success(t('FileDownloaded'))
               }
            })
    }


    return (
        <>
            {
                loading ?
                    <SubLoading /> :
                    <Fade>
                        <SellerLayout>
                            <main>
                <section className="breadcrumb__area include-bg pt-95 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">Detail {t('Order')}</h3>
                                    <div className="breadcrumb__list">
                                        <span><Link to={SUB_CUSTOMER_DASHBOARD}>{t('Orders')}</Link></span>
                                        <span>{t('Detail')}</span>
                                        <span>#{order_id}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tp-order-area pb-160">
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-1 col-lg-10">
                                <table className="table">
                                    <thead style={{background: "#fff"}}>
                                    <tr>
                                        <th style={{width: "70%"}}>{t('Product')}</th>
                                        <th style={{width: "15%"}} className="tp-cart-header-price">{t('Price')}</th>
                                        <th style={{width: "15%"}} className="tp-cart-header-action">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="6">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status"
                                                              aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {
                                                    loading ?
                                                        <tr>
                                                            <td colSpan="3">
                                                                <p className="text-center">
                                                                                   <span
                                                                                       className="spinner-border spinner-border-sm"
                                                                                       role="status"
                                                                                       aria-hidden="true"/> {t('Loading')}...
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        :
                                                        <>
                                                            {
                                                                products.length > 0 ?
                                                                    <>
                                                                        {
                                                                            products.map((item, idx) =>
                                                                                <tr key={idx}>
                                                                                    <td>{item.title}</td>
                                                                                    <td>{numberWithCommas(item.price)} Frcs</td>
                                                                                    <td>

                                                                                        {item.isDownloadable == true ?

                                                                                            <button
                                                                                                className="btn btn-sm btn-primary"
                                                                                                onClick={event => downloadFile(item.downloadable)}
                                                                                                title={t('Download')}><i
                                                                                                className='ri-download-2-line'></i>
                                                                                            </button>
                                                                                            :
                                                                                            ""
                                                                                        }


                                                                                        <Link
                                                                                            className="btn btn-sm btn-success"
                                                                                            to={"/product/detail/"+item.hash_key}
                                                                                        >
                                                                                         <small>Detail</small>
                                                                                        </Link>


                                                                                    </td>

                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </>
                                                                    :

                                                                    <tr>
                                                                        <td colSpan="3">
                                                                            <p className="text-center">{t('EmptyList')}</p>
                                                                        </td>
                                                                    </tr>
                                                            }
                                                        </>
                                                }
                                            </>

                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
                        </SellerLayout>
                    </Fade>
            }
        </>
    );
};

export default withTranslation()(OrderDetail)
