import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import Paginate from "../../../../components/Paginate/Paginate";
import CreateOrUpdate from "./CreateorUpdate";
import Features from "./Features";

const Plugins = ({t}) => {

    document.title = APPNAME+" :: "+t('Plugins')

    const [currentPlugin,setCurrentPlugin] = useState([]);
    const [plugins,setPlugins] = useState([])

    useEffect(() => {
        getAllPlugin()
    },[])

    useEffect(() => {
        setCurrentPlugin(plugins?.slice(indexOfFirstPost, indexOfLastPost))
    },[plugins])



    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [fopen,setFopen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])

    const [loading, setLoading] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentPlugin(plugins?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])


    const getAllPlugin = async () => {
        await axios.get(SERVER_URL+"admin/plugins",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPlugins(response.data.plugins)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(plugins?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const addPlugin = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editPlugin = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }


    const openFeatureList = (item) => {
        setUpdateData(JSON.parse(item))
        setFopen(true)
    }



    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeletePlugin'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}admin/plugin/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        getAllPlugin()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }





    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledPlugin') : t('SureToEnabledPlugin'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/plugin/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllPlugin()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentPlugin(plugins?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = plugins.filter(coup => {
                return coup.event.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentPlugin(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(plugins?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentPlugin.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListPlugin'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}admin/plugin/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getAllPlugin()
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Plugins')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Plugins')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Plugins')}</h5>
                                <div className="flex-shrink-0">
                                    <button className="btn btn-primary add-btn" onClick={event => addPlugin()}>
                                        <i className="ri-add-line align-bottom me-1"></i> {t('Add')}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">
                                    <div className="col-lg-4">
                                        {
                                            isCheck.length > 0 &&

                                            <>
                                                {
                                                    Gloading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                         <div>

                                                         </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col" style={{width: "50px"}}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="selectAll"
                                                       id="selectAll"
                                                       onChange={handleSelectAll}
                                                       checked={isCheckAll}
                                                />
                                            </div>
                                        </th>
                                        <th className="sort">{t('Module')}</th>
                                        <th className="sort">{t('Type')}</th>
                                        <th className="sort">{t('Description')}</th>
                                        <th className="sort">{t('Price')}</th>
                                        <th className="sort">{t('Period')}</th>
                                        <th className="sort">{t('Features')}</th>
                                        <th className="sort">Status</th>
                                        <th className="sort">{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="11">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentPlugin?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentPlugin?.map((plugin,key)=>
                                                                    <tr key={key}>
                                                                        <th scope="row"><div className="form-check">
                                                                            <input className="form-check-input"
                                                                                   type="checkbox"
                                                                                   name={plugin.category}
                                                                                   id={plugin.id}
                                                                                   onChange={handleClick}
                                                                                   checked={isCheck.includes(plugin.id)}
                                                                            />
                                                                        </div>
                                                                        </th>
                                                                        <td>{plugin?.["name"+localStorage.getItem('I18N_LANGUAGE').toUpperCase()]}</td>
                                                                        <td>{plugin?.type == 2 ? <b>{t('Module')}</b> : <b>{t('Extension')}</b>}</td>
                                                                        <td>{plugin?.[localStorage.getItem('I18N_LANGUAGE')].substring(0,100)}...</td>
                                                                        <td>{plugin?.price} Frcs</td>
                                                                        <td>{t(plugin.period).toUpperCase()}</td>
                                                                        <td><b className="underline cursor-pointer" onClick={event => openFeatureList(plugin.features)}>{t('Detail')}</b></td>
                                                                        <td>

                                                                                        {  plugin.statut == 1 ?
                                                                                                <b className="badge badge-soft-success">{t('Available')}</b>
                                                                                                :
                                                                                                <b className="badge badge-soft-warning">{t('Unavailable')}</b>
                                                                                        }

                                                                        </td>

                                                                        <td className="priority">
                                                                            <ul className="list-inline  mb-0">

                                                                                <li className="list-inline-item">
                                                                                    {plugin.statut == 1
                                                                                        ?    <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(plugin.id,2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                        :

                                                                                        <span className="edit-item-btn cursor-pointer" onClick={event => toggleStatus(plugin.id,1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                    }
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                     <span className="edit-item-btn cursor-pointer" onClick={event => editPlugin(plugin)}>
                                                                                         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                     </span>
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                     <span className="remove-item-btn cursor-pointer" onClick={event => deleteModal(plugin.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="11">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={plugins?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>
            <CreateOrUpdate
                 created={getAllPlugin}
                 open={open}
                 setOpen={setOpen}
                 updateData={updateData}
                 isEdit={isEdit}
                />

            <Features
                open={fopen}
                setOpen={setFopen}
                data={updateData}
                />
        </AdminLayout>
    );
}

export default withTranslation()(Plugins);
