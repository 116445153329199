import {
    LOAD_RECAP_FAIL,
    LOAD_RECAP_LOADING, LOAD_RECAP_SUCCESS,
    LOAD_SHOP_FAIL,
    LOAD_SHOP_LOADING, LOAD_SHOP_SUCCESS,
    STORE_SHOP_FAIL,
    STORE_SHOP_LOADING, STORE_SHOP_SUCCESS

} from './type';

const initialState = {
    shop_info: "",
    message : "",
    list : [],
    transaction : null,
    shop_add : false,
    isLoading: false,
    error: null,
};


export default function (state = initialState, { type, payload }) {
    switch (type) {
        case STORE_SHOP_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case STORE_SHOP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                key: payload.key,
                shop_add : true,
                message: payload.message,
            };

        case STORE_SHOP_FAIL:
            return {
                ...state,
                isLoading: false,
                shop_add : false,
                error: payload.error,
            };

        case LOAD_SHOP_LOADING :
            return {
                ...state,
                isLoading: true,
            };

        case LOAD_SHOP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: payload.list,
            };

        case LOAD_SHOP_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error,
            };


        case LOAD_RECAP_LOADING :
            return {
                ...state,
                isLoading: true,
            };

        case LOAD_RECAP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                transaction: payload.transaction,
            };

        case LOAD_RECAP_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error,
            };


        default:
            return state;
    }
}