import React, { useEffect, useRef, useState} from 'react';
import {Autoplay, Navigation} from "swiper/modules";
import {SwiperSlide,Swiper} from "swiper/react";
import {
    CalculateDiscount,
    CLIENT,
    numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL
} from "../../../helpers/utils";
import axios from "axios";
import {MiddleLoading} from "../../../components";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import QuickView from "../../../components/Card/QuickView";
import {secondCalculDicount, slugify} from "../../utils/utils";
import IconShoppingCart from "../../../components/Icons/IconShoppingCart";
import IconEye from "../../../components/Icons/IconEye";
import IconHeart from "../../../components/Icons/IconHeart";
import moment from "moment/moment";
import {compose} from "redux";
import {connect} from "react-redux";
import {CustomerAddCart} from "../../../store/Subdomain/CustomerCart/action";
import {addWishItem} from "../../../store/Subdomain/Wishlist/action";
import {toast} from "sonner"


const NewArrival = ({block,t,toggleHightLight,customStyle,isEditor,shop_data,CustomerAddCart,addWishItem}) => {

    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(null)


    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const newArrivalRef = useRef()

    const getBlockSize = () => {
        setDimension({top :newArrivalRef?.current?.offsetTop, left : newArrivalRef?.current?.offsetLeft, width : newArrivalRef?.current?.offsetWidth, height : newArrivalRef?.current?.offsetHeight,nodeName : newArrivalRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()
        toggleHightLight(data,dimension,status)
    }

    useEffect(() => {
       if (!isEditor) {
           if(shop_data){
               getProductsData(shop_data.id)
           }
       }
    }, [shop_data])


    useEffect(() => {
        if (isEditor){
            setLoading(false)
            setProducts(block.data)
        }
    }, [isEditor])

    const getProductsData = async (id) => {
        await axios.get(SERVER_URL+"/public/products/"+id).then((response)=>{
            setLoading(false)
            if (response.data.succeed){

              setProducts(response.data.products.slice(0,8))

            }
        })
    }

    const quickView = (item) => {
        setItem(item)
        setOpen(true)
    }


    const addWishlist = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
            const data ={
                id:item.id,
                category : item.category,
                picture :SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
                name:item.title,
                discount : item.discount,
                price:item.price,
                hash_key:item.hash_key
            }

            addWishItem(data)

            toast("Notification",{
                description: t('AddToWishlist') +"\n"+ moment(Date.now()).fromNow(),
                icon : <i className="ri-check-double-fill"></i>
            });

        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }

    }


    const addToCart = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
            const data = {
                id:item.id,
                qty : 1,
                category : item.category,
                picture : SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
                name:item.title,
                price: CalculateDiscount(item?.price,item?.discount)
            }
            CustomerAddCart(data)

            toast("Notification",{
                description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
                icon : <i className="ri-check-double-fill"></i>
            })
        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }
    }

    return (
        <section className="tp-product-arrival-area pt-60 pb-15"
                 ref={newArrivalRef}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
                 style={customStyle}
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-xl-5 col-sm-6">
                        <div className="tp-section-title-wrapper mb-40">
                            <h3 className="tp-section-title">
                                {t('NewArrivals')} <svg width="114" height="35" viewBox="0 0 114 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M112 23.275C1.84952 -10.6834 -7.36586 1.48086 7.50443 32.9053" stroke="currentColor" strokeWidth="4" strokeMiterlimit="3.8637" strokeLinecap="round"/>
                                </svg>
                            </h3>
                        </div>
                    </div>
                    <div className="col-xl-7 col-sm-6">
                        <div className="tp-product-arrival-more-wrapper d-flex justify-content-end">
                            <div className="tp-product-arrival-arrow tp-swiper-arrow mb-40 text-end tp-product-arrival-border">
                                <button type="button" className="tp-arrival-slider-button-prev">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 13L1 7L7 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <button type="button" className="tp-arrival-slider-button-next">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 13L7 7L1 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        loading ?
                            <MiddleLoading />
                            :
                            <div className="col-xl-12">
                                <div className="tp-product-arrival-slider fix">
                                    <div className="tp-product-arrival-active swiper-container">
                                        <div className="swiper-wrapper">
                                            <Swiper
                                                grabCursor={true}

                                                spaceBetween={20}
                                                autoplay={{
                                                    delay : 1500,
                                                    disableOnInteraction : true
                                                }}
                                                loop={true}
                                                slidesPerView={products.length > 3 ? 4 : products.length}
                                                modules={[Autoplay, Navigation]}
                                                navigation={{
                                                    nextEl : ".tp-arrival-slider-button-next",
                                                    prevEl : ".tp-arrival-slider-button-prev",
                                                    clickable : true
                                                }}
                                            >
                                                {
                                                    products?.map((item,key) =>
                                                            <SwiperSlide key={key}>
                                                                <div className="tp-product-item p-relative transition-3 mb-25">
                                                                    <div className="tp-product-thumb p-relative fix m-img">
                                                                        <Link to={"/product/detail/"+item.hash_key}>
                                                                            <img src={isEditor ? item.picture : SERVER_IMG_URL+shop_data?.shop_key+"/products/thumb/"+JSON.parse(item.pictures)[0]}  alt="product-electronic"/>
                                                                        </Link>
                                                                        <div className="tp-product-badge">
                                                                            {
                                                                                parseInt(item?.discount) > 0 ? <span className="product-offer">-{item.discount}%</span> : ""
                                                                            }

                                                                            <span className={`product-${item.badge?.toLowerCase()} ml-10`}>{item.badge}</span>


                                                                        </div>
                                                                        <div className="tp-product-action">
                                                                            <div className="tp-product-action-item d-flex flex-column">
                                                                                <button type="button" className="tp-product-action-btn tp-product-add-cart-btn" onClick={event => addToCart(item)}>
                                                                                    <IconShoppingCart />
                                                                                    <span className="tp-product-tooltip">{t("BuyNow")}</span>
                                                                                </button>
                                                                                <button type="button" className="tp-product-action-btn tp-product-quick-view-btn" onClick={event => quickView(item)}>
                                                                                    <IconEye />
                                                                                    <span className="tp-product-tooltip">{t('QuickView')}</span>
                                                                                </button>
                                                                                <button type="button" className="tp-product-action-btn tp-product-add-to-wishlist-btn" onClick={event => addWishlist(item)}>
                                                                                    <IconHeart />
                                                                                    <span className="tp-product-tooltip">{t('AddToWishlist')}</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="tp-product-content">
                                                                        <div className="tp-product-category">
                                                                            <Link to={"/shop/"+slugify(item.category)}>{item.category}</Link>
                                                                        </div>
                                                                        <h3 className="tp-product-title">
                                                                            <Link to={"/product/detail/"+item.hash_key}>
                                                                                {item.title}
                                                                            </Link>
                                                                        </h3>
                                                                        {
                                                                            item.rating ?
                                                                                <div className="tp-product-rating d-flex align-items-center">
                                                                                    <div className="tp-product-rating-icon">
                                                                                        {
                                                                                            [...parseInt(item.rating)].map((item,key) => (
                                                                                                <span key={key}><i className="fa-solid fa-star"></i></span>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                            ''

                                                                        }
                                                                        <small className="text-muted">{item.short_description}</small>
                                                                        {
                                                                            parseInt(item.discount) > 0 ?
                                                                                <div className="tp-product-price-wrapper">
                                                                                    <span className="tp-product-price old-price">{numberWithCommas(item.price)}  Francs CFA</span>
                                                                                    <span className="tp-product-price new-price pl-10">{numberWithCommas(secondCalculDicount(parseInt(item.price),parseInt(item.discount)))} Francs CFA</span>
                                                                                </div>
                                                                                :

                                                                                <div className="tp-product-price-wrapper">
                                                                                    <span className="tp-product-price new-price">{numberWithCommas(item.price)}  Francs CFA</span>
                                                                                </div>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                    )
                                                }

                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <QuickView
                open={open}
                setOpen={setOpen}
                data={item}
                shop_data={shop_data}
            />
        </section>
    )
}

const mapStateToProps = (state) => ({
    customCart : state.customCart
});

export default compose(withRouter, connect(mapStateToProps,{CustomerAddCart,addWishItem})) (withTranslation()(NewArrival))
