import {
    PRICING_FAIL,
    PRICING_LOADING, PRICING_SUCCESS
} from './type';

const initialState = {
    isLoading : true,
    pricing : [],
    error: null,
};


const PricingReducer  = (state = initialState, { type, payload }) => {
    switch (type) {
        case PRICING_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case PRICING_SUCCESS:
            return {
                ...state,
                pricing: payload.pricing,
                isLoading: false,
            };

        case PRICING_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error,
            };

        default:
            return state;
    }
}

export default PricingReducer