import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CustomModal} from "../index";
import {CalculateDiscount, CLIENT, numberWithCommas, SERVER_IMG_URL} from "../../helpers/utils";
import {Alert} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {secondCalculDicount} from "../../themes/utils/utils";
import {toast} from "sonner";
import moment from "moment";
import {CustomerAddCart, CustomerDecrease, CustomerIncrease} from "../../store/Subdomain/CustomerCart/action";
import {compose} from "redux";
import {connect} from "react-redux";
import {ShareSocial} from "react-share-social";

const QuickView = ({setOpen,open,data,t,shop_data,CustomerAddCart}) => {

    const [main, setMain] = useState("");
    const [qty, setQty] = useState(1)

    useEffect(() => {
        if (data?.pictures) setMain(JSON.parse(data?.pictures)[0])
    }, [data]);


    const style = {
        root: {
            width: "100%",
            // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            // borderRadius: 3,
            // border: 0,
            // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            // color: 'white',
            backgroundColor: 'rgba(255, 255, 255, .0)',
            marginTop: "-60px",
            marginBottom: "-50px",
            marginLeft : "-30px"
        },
        copyContainer: {
            display: "none",
            border: '1px solid blue',
            background: 'rgb(0,0,0,0.7)'
        },
        // title: {
        //     color: 'aquamarine',
        //     fontStyle: 'italic'
        // }
    };

    const addToCart = (item) => {
        const data = {
            id:item.id,
            qty : qty,
            category : item.category,
            picture : SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
            name:item.title,
            price: CalculateDiscount(item?.price,item?.discount)
        }
        CustomerAddCart(data)

        toast("Notification",{
            description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
            icon : <i className="ri-check-double-fill"></i>
        })
    }


    return (
       <CustomModal open={open} setOpen={setOpen} title={t('QuickView')} size="xl" centered={true} header={false}>
           <div className="row">
               <div className="tp-product-modal-content d-lg-flex align-items-start">
                   <button type="button" className="tp-product-modal-close-btn" onClick={event => setOpen(false)}><i className="fa-regular fa-xmark"></i></button>
                   <div className="tp-product-details-thumb-wrapper tp-tab d-sm-flex">
                       <nav className='d-sm-none'>
                           {
                               data?.pictures ?
                                   <>
                                   {
                                       JSON.parse(data?.pictures).map((picture,key)=>
                                           <div key={key} className="nav nav-tabs flex-sm-column mb-3 " id="productDetailsNavThumb" role="tablist">
                                               <button className={`nav-link p-2 ${main == picture ? "active" : ""}`} onClick={event => setMain(picture)}  data-bs-toggle="tab" data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4" aria-selected="true">
                                                   <img src={SERVER_IMG_URL+shop_data?.shop_key+"/products/thumb/"+picture} alt=""/>
                                               </button>
                                           </div>
                                       )
                                   }
                                   </>

                                   : ""
                           }
                       </nav>
                       <div className="tab-content m-img" id="productDetailsNavContent">
                           <div className="tab-pane fade active show" style={{width : "407px",height : "470px",padding : "5px",border : "1px solid #ccc"}}   role="tabpanel" aria-labelledby="nav-1-tab" tabIndex="0">
                               <div className="tp-product-details-nav-main-thumb  ">
                                   <img src={SERVER_IMG_URL+shop_data?.shop_key+"/products/"+main}  alt=""/>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className="tp-product-details-wrapper">
                       <div className="tp-product-details-category">
                           <span className="fs-12">{data?.category}</span>
                       </div>
                       <h3 className="tp-product-details-title">{data?.title}</h3>

                       <div className="tp-product-details-inventory d-flex align-items-center mb-10">
                           <div className="tp-product-details-stock mb-10">
                               {data?.stock && parseInt(data?.stock) > 0 ? <span style={{color : "#0989FF",background : "rgba(9, 137, 255, 0.06)"}}>{t('InStock')} </span> : "" }
                           </div>
                           <div className="tp-product-details-rating-wrapper d-flex align-items-center mb-10">
                               <div className="tp-product-details-rating">
                                   {
                                       data?.rating  ?
                                           <>
                                               {
                                                   [...Array(parseInt(data.rating))].map((item,key) => (
                                                       <span key={key}><i className="fa-solid fa-star"></i></span>
                                                   ))
                                               }
                                           </>
                                           :
                                           ""
                                   }
                               </div>
                           </div>
                       </div>
                       <p>
                           {data?.short_description}  <span><Link to={'/product/detail/'+data?.hash_key}>{t('SeeMore')}</Link></span>
                       </p>


                       {
                           data?.discount &&
                           <>
                           {
                               parseInt(data?.discount) > 0 ?
                                   <div className="tp-product-details-price-wrapper mb-20">
                                       <span className="tp-product-details-price old-price">{numberWithCommas(data?.price)}  Francs CFA</span>
                                       <span className="tp-product-details-price new-price bold pl-10">{numberWithCommas(secondCalculDicount(parseInt(data?.price),parseInt(data?.discount)))} Francs CFA</span>
                                   </div>
                                   :
                                   <div className="tp-product-details-price-wrapper mb-20">
                                       <span className="tp-product-details-price new-price">{numberWithCommas(data?.price)}  Francs CFA</span>
                                   </div>
                           }
                           </>
                       }


                       {
                           data?.additional_info ?
                               <>
                                   {
                                       JSON.parse(data.additional_info).slice(0,2).map((item,key) =>

                                           <div key={key} style={{display : "flex",justifyContent : "space-between",marginBottom : "20px"}}>
                                               <b>{item.label} : </b> <span>{item.value}</span>
                                           </div>

                                       )
                                   }
                               </>
                               :

                               ""
                       }

                       <p><b>{t('ShareOn')}</b> : </p>
                       <div className="tp-product-details-social">
                           <ShareSocial
                               url={window.location.origin+"/product/detail/"+data?.hash_key}
                               socialTypes= {['facebook','twitter','whatsapp','linkedin','telegram']}
                               onSocialButtonClicked={ data => console.log(data)}
                               style={style}
                           />
                       </div>



                       <br/>

                       {data?.stock && parseInt(data?.stock) > 0 ?
                       <div className="tp-product-details-action-wrapper">
                           <div className="tp-product-details-action-item-wrapper d-sm-flex align-items-center">
                               <div className="tp-product-details-quantity">
                                   <div className="tp-product-quantity mb-15 mr-15">
                                    <span className="tp-cart-minus" onClick={event => setQty(qty - 1)}>
                                       <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 1H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                       </svg>
                                    </span>
                                       <input className="tp-cart-input" type="text" value={qty}  />
                                    <span className="tp-cart-plus" onClick={event => setQty(qty + 1)}>
                                       <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 6H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                          <path d="M5.5 10.5V1.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                       </svg>
                                    </span>
                                   </div>
                               </div>





                               <div className="tp-product-details-add-to-cart mb-15 w-100">
                                   <button className="tp-product-details-add-to-cart-btn w-100"
                                            style={{background : shop_data.color,color : "white",paddingTop : "10px",paddingBottom : "10px"}}
                                           onClick={event => addToCart(data)}>{t("BuyNow")}</button>
                               </div>

                           </div>
                       </div>
                           :
                           <div style={{marginTop : "10px"}}>
                               <Alert color="danger">
                                   {t('RuptureStock')} {shop_data?.phone}
                               </Alert>
                           </div>
                       }

                   </div>
               </div>
           </div>
       </CustomModal>
    )
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
    customCart : state.customCart
});

export default compose(withRouter, connect(mapStateToProps,{CustomerAddCart})) (withTranslation()(QuickView))

