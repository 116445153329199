import React, {Fragment, useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {attachTokenToHeaders, Capitalize, CLIENT, numberWithCommas, SERVER_URL} from "../../../../../helpers/utils";
import {Link, Redirect} from "react-router-dom";
import {SUB_CART, SUB_INDEX, SUB_LOGIN} from "../../routes/type";
import SellerLayout from "../../components/SellerLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import {MiddleLoading} from "../../../../../components";
import _Map from "./_Map";
import _Payment from "./_Payment";
import _Success from "./_Success";
import _Error from "./_Error";
import _Block from "./_Block";
import SubLoading from "../../../../../components/Loading/SubLoading";


const Checkout = ({t,auth,customCart}) => {
    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+t('Checkout')

    const [loading, setLoading] = useState(true)
    const [global, setGlobal] = useState(false)
    const [successData, setSuccessData] = useState({})
    const [errorData, setErrorData] = useState({})
    const [subtotal, setSubtotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [checkoutData, setCheckoutData] = useState({})

    const [step, setStep] = useState(1)


    useEffect(() => {
        calculCart()
    }, [customCart]);

    useEffect(() => {
        setTimeout(() =>{
            setLoading(false)
        },2000)
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const calculCart = () => {
        let total = 0;
        customCart?.carts.map((item) =>  total += (item.price * item.qty))
          setSubtotal(total)
          setTotal(total)
    }


    const saveOrder = (data) => {
        axios.post(SERVER_URL+"front/save/order", data,attachTokenToHeaders())
            .then((response) =>{
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setTimeout(() =>{
                        changeStep(4)
                        setSuccessData({
                            orderdate :  response.data.created_at,
                            orderNumber : response.data.order_id,
                            payment_method : response.data.payment_method
                        })
                    },1000)
                }
                else{
                    setErrorData(response.data.message)
                    changeStep(5)
                }
            })
    }

    const changeStep = (step) => {
        setGlobal(true)
        setStep(step)
        setTimeout(()=>{
            setGlobal(false)
        },1000)

    }





    return (
        <>
            {
                loading ?
                    <SubLoading /> :
                    <>
                    <SellerLayout>
           {
               global ?
                   <MiddleLoading />
                   :
                   <main>

                       {
                           step !== 4 ?

                               <section className="breadcrumb__area include-bg pt-95 pb-50"
                                        style={{background: "#EFF1F5"}}>
                                   <div className="container">
                                       <div className="row">
                                           <div className="col-xxl-12">
                                               <div className="breadcrumb__content p-relative z-index-1">
                                                   <h3 className="breadcrumb__title">{t('Checkout')}</h3>
                                                   <div className="breadcrumb__list text-muted">
                                                       <span><Link to={SUB_INDEX}>{t('Home')}</Link></span>
                                                       <span><Link to={SUB_CART}>{t('ShoppingCart')}</Link></span>
                                                       <span>{t('Checkout')}</span>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>

                               </section>

                               : ""
                       }





                       {step === 1 ?
                           <_Block
                               auth={auth}
                               customCart={customCart}
                               setStep={changeStep}
                               setTotal={setTotal}
                               total={total}
                               subtotal={subtotal}
                               setCheckoutData={setCheckoutData}
                               saveOrder={saveOrder}
                           /> : ""}

                       {step === 2 ?
                           <_Map
                               saveOrder={saveOrder}
                               checkoutData={checkoutData}
                               setCheckoutData={setCheckoutData}
                               setStep={changeStep}
                           /> : ""}
                       {step === 3 ?
                           <_Payment
                               setCheckoutData={setCheckoutData}
                               checkoutData={checkoutData}
                               setStep={changeStep}
                               saveOrder={saveOrder}
                           /> : ""}
                       {step === 4 ?
                           <_Success
                               successData={successData}
                               t={t}
                           /> : ""}
                       {step === 5 ? <_Error
                           errorData={errorData}
                       /> : ""}


                   </main>
           }
       </SellerLayout>
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
    customCart: state.customCart
});
export default compose(connect(mapStateToProps))(withTranslation()(Checkout));

