import React, { useEffect, useState } from 'react'
import axios from 'axios'

const FacebookCatalog = ({account}) => {

    const [catalogs, setCatalogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [businesses, setBusinesses] = useState([]);

    useEffect(() => {
        if (account) {
            loadBusinesses();
        }
    }, [account]);

    const loadBusinesses = async () => {
        setLoading(true);
        try {
            // Step 1: Get list of businesses the user manages
            const businessResponse = await axios.get(
                `https://graph.facebook.com/v17.0/me/businesses`,
                {
                    headers: {
                        Authorization: `Bearer ${account}`,
                    },
                }
            );
            setBusinesses(businessResponse.data.data); // Assuming 'data' contains businesses
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(`Error fetching businesses: ${error.response?.data?.error?.message}`);
        }
    };

    const loadCatalogs = async (businessId) => {
        setLoading(true);
        try {
            // Step 2: Load catalogs for a specific business ID
            const catalogResponse = await axios.get(
                `https://graph.facebook.com/v17.0/${businessId}/owned_product_catalogs`,
                {
                    headers: {
                        Authorization: `Bearer ${account}`,
                    },
                }
            );
            setCatalogs(catalogResponse.data.data); // Assuming 'data' contains catalogs
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(`Error fetching catalogs: ${error.response?.data?.error?.message}`);
        }
    };


    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        {loading && <p>Loading catalogs...</p>}
                        {error && <p>{error}</p>}
                        {!loading && businesses.length > 0 && (
                            <div>
                                <h3>Select a Business</h3>
                                <ul>
                                    {businesses.map((business) => (
                                        <li key={business.id}>
                                            {business.name} (ID: {business.id}){' '}
                                            <button onClick={() => loadCatalogs(business.id)}>
                                                Load Catalogs
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!loading && catalogs.length > 0 && (
                            <div>
                                <h3>Catalogs</h3>
                                <ul>
                                    {catalogs.map((catalog) => (
                                        <li key={catalog.id}>
                                            {catalog.name} (ID: {catalog.id})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacebookCatalog