import React, {useEffect, useRef, useState} from 'react';
import {CLIENT} from "../../../helpers/utils";
import {flatData} from "../../utils/utils";
import {withTranslation} from "react-i18next";

const Social2 = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {

    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const social2Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :social2Ref?.current?.offsetTop, left : social2Ref?.current?.offsetLeft, width : social2Ref?.current?.offsetWidth, height : social2Ref?.current?.offsetHeight,nodeName : social2Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);


    return (
        <section className="tp-instagram-area pt-65 pb-15"
                 style={customStyle}
                 ref={social2Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
        >
            <div className="container-fluid pl-20 pr-20">
                <div className="row row-cols-lg-5 row-cols-sm-2 row-cols-1 gx-2 gy-2 gy-lg-0">
                    <div className="col">
                        <div className="tp-instagram-banner text-center">
                            <div className="tp-instagram-banner-icon mb-40">
                                <a href="#">
                                    {flatData(items[0],"social") === "Instagram" &&  <i className="fa-brands fa-instagram" style={{fontSize : "75px"}}></i>}
                                    {flatData(items[0],"social") === "X (twitter)" &&  <i className="fa-brands fa-twitter" style={{fontSize : "75px"}}></i>}
                                    {flatData(items[0],"social") === "Facebook" &&  <i className="fa-brands fa-facebook" style={{fontSize : "75px"}}></i>}
                                    {flatData(items[0],"social") === "Dribbble" &&  <i className="fa-brands fa-dribbble" style={{fontSize : "75px"}}></i>}
                                    {flatData(items[0],"social") === "Tiktok" &&  <i className="fa-brands fa-tiktok" style={{fontSize : "75px"}}></i>}
                                </a>
                            </div>
                            <div className="tp-instagram-banner-content">
                                <span>{t('FollowUson')}</span>
                                {
                                    items.length > 0 &&  <a href={flatData(items[0],'social')} target="_blank" >{flatData(items[0],'social')}</a>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        items?.length > 0 ?
                            <>
                                {items?.map((item,key) =>
                                <div className="col" key={key}>
                                    <div className="tp-instagram-item-2 w-img">
                                        <img src={flatData(item,"image")} alt=""/>
                                            <div className="tp-instagram-icon-2">
                                                <a href={flatData(item,"link")} target="_blank" className="popup-image">
                                                    {flatData(item,"social") === "Instagram" &&  <i className="fa-brands fa-instagram"></i>}
                                                    {flatData(item,"social") === "X (twitter)" &&  <i className="fa-brands fa-twitter"></i>}
                                                    {flatData(item,"social") === "Facebook" &&  <i className="fa-brands fa-facebook"></i>}
                                                    {flatData(item,"social") === "Dribbble" &&  <i className="fa-brands fa-dribbble"></i>}
                                                    {flatData(item,"social") === "Tiktok" &&  <i className="fa-brands fa-tiktok"></i>}
                                                </a>
                                            </div>
                                    </div>
                                </div>
                                )}
                            </>

                            : ""

                    }


                </div>
            </div>
        </section>
    )
}

export default withTranslation()(Social2)
