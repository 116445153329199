import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import { APPNAME, attachTokenToHeaders, numberWithCommas, SERVER_URL } from '../../../../helpers/utils'
import axios from "axios";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import Paginate from "../../../../components/Paginate/Paginate";
import CreateOrUpdate from "./CreateorUpdate";

const Demo = ({t}) => {

    document.title = APPNAME+" :: "+t('Commerciaux')

    const [currentCommercial,setCurrentCommercial] = useState([]);
    const [commercial,setCommercial] = useState([])

    useEffect(() => {
        getAllCommercial()
    },[])


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [Gloading,setGloading] = useState(false)
    const [open,setOpen] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [updateData,setUpdateData] = useState([])

    const [loading, setLoading] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentCommercial(commercial?.slice(indexOfFirstPost, indexOfLastPost))
    },[commercial])

    useEffect(() => {
        setCurrentCommercial(commercial?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])


    const getAllCommercial = async () => {
        await axios.get(SERVER_URL+"admin/commercial",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setCommercial(response.data.commercial)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(commercial?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const addCommercial = () => {
        setIsEdit(false)
        setUpdateData([])
        setOpen(true)
    }

    const editCommercial = (item) => {
        setIsEdit(true)
        setUpdateData(item)
        setOpen(true)
    }



    const deleteModal = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteCommercial'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}admin/commercial/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed) {
                        toast.success(t(response.data.message))
                        getAllCommercial()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }





    const toggleStatus = async (id,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledCommercial') : t('SureToEnabledCommercial'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/commercial/toggle/${type}/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllCommercial()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentCommercial(commercial?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = commercial.filter(demo => {
                return demo?.email.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentCommercial(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(commercial?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentCommercial.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };

    const deleteCheked = async () => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteListCommercial'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setGloading(true)
                try {
                    const response = await axios.post(`${SERVER_URL}admin/commercial/multiple/delete`,{ids : JSON.stringify(isCheck)},attachTokenToHeaders())
                    if (response.data.succeed === true) {
                        toast.success(t(response.data.message))
                        getAllCommercial()
                        setIsCheckAll(false)
                        setIsCheck([])
                        setGloading(false)
                    }
                } catch (error) {
                    setGloading(false)
                    console.log(error)
                }
            }
        });

    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Commercial')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Commercial')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1"> {t('List')} {t('Commercial')}</h5>
                                <div className="flex-shrink-0">
                                    <button className="btn btn-primary add-btn" onClick={event => addCommercial()}>
                                        <i className="ri-add-line align-bottom me-1"></i> {t('Add')}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">
                                    <div className="col-lg-4">
                                        {
                                            isCheck.length > 0 &&

                                            <>
                                                {
                                                    Gloading ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        :
                                                        <>
                                                            {
                                                                Gloading ?
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                                    :
                                                                    <button className="btn btn-danger btn-sm mr-1" onClick={event => deleteCheked()}><i className="ri-delete-bin-2-line"></i></button>
                                                            }
                                                        </>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col" style={{ width: '50px' }}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                       name="selectAll"
                                                       id="selectAll"
                                                       onChange={handleSelectAll}
                                                       checked={isCheckAll}
                                                />
                                            </div>
                                        </th>
                                        <th  className="sort">{t('User')}</th>
                                        <th  className="sort">{t('Email')}</th>
                                        <th  className="sort">{t('Phone')}</th>
                                        <th  className="sort">{t('City')}</th>
                                        <th  className="sort">{t('Country')}</th>
                                        <th  className="sort">{t('Code')}</th>
                                        <th  className="sort">{t('Promoted')}</th>
                                        <th  className="sort">{t('AvailableBalance')}</th>
                                        <th  className="sort">Status</th>
                                        <th className="sort">{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="11">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentCommercial?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentCommercial?.map((commercial,key)=>
                                                                    <tr key={key}>
                                                                        <th scope="row">
                                                                            <div className="form-check">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       name={commercial.lastname}
                                                                                       id={commercial.id}
                                                                                       onChange={handleClick}
                                                                                       checked={isCheck.includes(commercial.id)}
                                                                                />
                                                                            </div>
                                                                        </th>
                                                                        <td>{commercial.lastname} {commercial.firstname}</td>
                                                                        <td>{commercial.email}</td>
                                                                        <td>{commercial.phone}</td>
                                                                        <td>{commercial.city}</td>
                                                                        <td>{commercial.country}</td>
                                                                        <td>{commercial.code}</td>
                                                                        <td>{commercial.promoted}</td>
                                                                        <td>{numberWithCommas(commercial.amountAvailable)} Frcs</td>

                                                                        <td>

                                                                            {commercial.statut == 1 ?
                                                                                <b className="badge badge-soft-success">{t('Available')}</b>
                                                                                :
                                                                                <b className="badge badge-soft-warning">{t('Unavailable')}</b>
                                                                            }

                                                                        </td>

                                                                        <td className="priority">
                                                                            <ul className="list-inline  mb-0">

                                                                                <li className="list-inline-item">
                                                                                    {commercial.statut == 1
                                                                                        ? <span
                                                                                            className="edit-item-btn cursor-pointer"
                                                                                            onClick={event => toggleStatus(commercial.id, 2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                        :

                                                                                        <span
                                                                                            className="edit-item-btn cursor-pointer"
                                                                                            onClick={event => toggleStatus(commercial.id, 1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                    }
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                     <span
                                                                                         className="edit-item-btn cursor-pointer"
                                                                                         onClick={event => editCommercial(commercial)}>
                                                                                         <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>
                                                                                     </span>
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                     <span
                                                                                         className="remove-item-btn cursor-pointer"
                                                                                         onClick={event => deleteModal(commercial.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="11">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={commercial?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>

            <CreateOrUpdate
                created={getAllCommercial}
                open={open}
                setOpen={setOpen}
                updateData={updateData}
                isEdit={isEdit}
            />



        </AdminLayout>
    );
}

export default withTranslation()(Demo);
