import React, {createRef, useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import './assets/css/editor.css'
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import TopBar from "./components/TopBar";
import LeftPanel from "./components/LeftPanel";
import Canvas from "./components/Canvas";
import {createFileName, useScreenshot} from "use-react-screenshot";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import Navigator from "./components/Navigator";
import {driver} from 'driver.js';
import 'driver.js/dist/driver.css';

const Editor = ({
t,
auth,
saveTemplate,
data,
loading,
list,
templateName,
shop_data
}) => {

    const getInitialDriverEnabledState = () => {
        const driverEnabled = localStorage.getItem('__driverEnabled__');
        return driverEnabled !== "false" ? "true" : "false";
    };

    const [driverEnabled, setDriverEnabled] = useState(getInitialDriverEnabledState);

    const [device, setDevice] = useState("desktop");
    const [leftSide, setLeftSide] = useState(true);
    const [step, setStep] = useState(1);
    const [preview, setPreview] = useState(false);
    const toShoot = createRef(null);
    const [substep, setSubstep] = useState(1);
    const [image, takeScreenShot] = useScreenshot();
    const [elements, setElements] = useState([]);
    const [content, setContent] = useState(null)
    const [showNavigator, setShowNavigator] = useState(false)

    document.body.style.setProperty("--builder-right-panel-width", "0px");

    useEffect(() => {
        data?.content ? setElements(JSON.parse(data.content)) : setElements([]) ;
    }, [data]);

    const toggleSides = (type) => {
            if (leftSide){
                document.body.style.setProperty("--builder-left-panel-width", "0px");
                setLeftSide(false)
            }else{
                document.body.style.removeProperty("--builder-left-panel-width");
                setLeftSide(true)
            }
    }

    const screenshot = () => {
        takeScreenShot(toShoot.current).then(downloadScreenShot)
        toast.info(t("Screenshot"), {
            position: "bottom-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    }
    const downloadScreenShot = (image, { name = "img", extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

        const save = () => {

                    if (elements.length > 0) {
                        swal({
                            title : t('Save'),
                            text : t('SavePageUpdates'),
                            buttons: [t("Cancel"), t('Yes')],
                            dangerMode:false,
                            successMode : true,
                            icon : "success"
                        }).then(async (check) => {
                            if (check) {
                             saveTemplate(elements)
                            }
                        });
                    }else{
                        swal({
                            title : t('Error'),
                            text : t('CantSaveEmptyPageContent'),
                            dangerMode : true,
                            icon : "error"
                        })
                    }

        }

    const changeDevice = (dd) =>{
         setDevice(dd)
        localStorage.setItem('themepreview',JSON.stringify(elements))
    }

    const ToggleNavigator = () => {
       setShowNavigator(!showNavigator)
    }



    useEffect(() => {
        if (driverEnabled === "true"){
            const driverObj = driver({
                    animate: true,
                    showProgress: true,
                    nextBtnText: t('Next'),
                    prevBtnText: t('Previous'),
                    doneBtnText: t('Terminate'),
                    showButtons: ['next', 'previous'],
                     allowKeyboardControl : true,
                    steps : [
                        {
                            element: '#left-panel',
                            popover: {
                                title: t('LeftPanel'),
                                description: t('LeftPanelDescription'),
                                position: 'right'
                            }
                        },

                        {
                            element: '#block-menu',
                            popover: {
                                title: t('LeftPanelBlocks'),
                                description: t('LeftPanelBlocksDescription'),
                                position: 'right'
                            }
                        },

                        {
                            element: '#block-settings',
                            popover: {
                                title: t('LeftPanelSetting'),
                                description: t('LeftPanelSettingDescription'),
                                position: 'right'
                            }
                        },

                        {
                            element: '#top-panel',
                            popover: {
                                title: t('TopPanel'),
                                description: t('TopPanelDescription'),
                                position: 'right'
                            }
                        },

                        {
                            element: '#control-panel',
                            popover: {
                                title: t('ControlPanel'),
                                description: t('ControlPanelDescription'),
                                position: 'left'
                            }
                        },

                        {
                            element: '#preview-panel',
                            popover: {
                                title: t('PreviewPanel'),
                                description: t('PreviewPanelDescription'),
                                position: 'bottom'
                            }
                        },

                        {
                            element: '#actions',
                            popover: {
                                title: t('ActionsPanel'),
                                description: t('ActionsPanelDescription'),
                                position: 'bottom'
                            }
                        },


                    ],
                onCloseClick: () => {
                    setDriverEnabled("false");
                    localStorage.setItem('__driverEnabled__', "false");
                    driverObj.destroy();
                },

                onDestroyStarted :  () => {
                setDriverEnabled("false");
                localStorage.setItem('__driverEnabled__', "false");
                    driverObj.destroy();
                  },
                onDestroyed : () => {
                    setDriverEnabled("false");
                    localStorage.setItem('__driverEnabled__', "false");
                    driverObj.destroy();
                },
                }
            );

            driverObj.drive();
        }
    }, [driverEnabled]);

    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <div id="kiiako-builder" className={`${preview ? "preview" : ""}`}>
                    <TopBar
                        toggleSides={toggleSides}
                        setDevice={changeDevice}
                        preview={preview}
                        setPreview={setPreview}
                        auth={auth}
                        screenshot={screenshot}
                        saveTemplate={save}
                        setElements={setElements}
                        device={device}
                        toggleNavigator={ToggleNavigator}
                        elements={elements}
                     />

                    <LeftPanel
                        elements={elements}
                        setElements={setElements}
                        pageList={list}
                        templateName={templateName}
                        substep={substep}
                        step={step}
                        setStep={setStep}
                        t={t}
                        setSubstep={setSubstep}
                        content={content}
                    />

                    <Canvas
                        device={device}
                        toShoot={toShoot}
                        setRightStep={setSubstep}
                        setStep={setStep}
                        setContent={setContent}
                        setElements = {setElements}
                        elements={elements}
                        loading={loading}
                        shop_data={shop_data}
                    />

                    {/*<RightPanel*/}
                    {/*    substep={substep}*/}
                    {/*    t={t}*/}
                    {/*    setSubstep={setSubstep}*/}
                    {/*    content={content}*/}
                    {/*    setElements={setElements}*/}
                    {/*    elements={elements}*/}
                    {/*/>*/}
                </div>
            </DndProvider>

            {
                showNavigator ?  <Navigator
                    elements={elements}
                    t={t}
                    setElements={setElements}
                    toggleNavigator={ToggleNavigator}
                />
                    : ""
            }
        </div>
    );
}

export default withTranslation()(Editor);
