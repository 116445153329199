import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {Link} from "react-router-dom";
import {LINK_STORE_APPS} from "../../../../routes/type";
import { Chart } from "react-google-charts";
import axios from "axios";
import {MiddleLoading} from "../../../../components";


const Charts = ({t,auth}) => {

    const [charData, setCharData] = useState([])
    const [visitorChart, setVisitorChart] = useState([])
    const [visitorPage, setVisitorPage] = useState([])
    const [totalVisitorM, setTotalVisitorM] = useState(0)

    const month = ["Jan", "Fev", "Mar", "Apr", "Mai", "Jun","Jul", "Auo", "Sep", "Oct", "Nov", "Dec"]

    const [fCurrentYear, setFCurrentYear] = useState(new Date().getFullYear())

    const [arrayYears, setArrayYears] = useState([])
    const [products, setProducts] = useState([])

    const [loading, setLoading] = useState(true)



    useEffect(() => {
        loadData()
        for (let i = fCurrentYear; i >= fCurrentYear - 3; i--) {
            setArrayYears(prevState => [...prevState,i])
        }
    }, []);


    const loadData = () => {
        setLoading(true)
        getData()
        getVisitorByDevice()
        getVisitorPerPage()
        getProductSelled()
        setTimeout(() =>{
            setLoading(false)
        },1000)
    }

        const getData = (year) => {
            const thisYear = year ? year :   new Date().getFullYear()
          axios.get(SERVER_URL+"charts/"+auth?.shop.id+"/"+thisYear ,attachTokenToHeaders())
              .then((response) =>{

                  const data = month.map(month => [month, 0]);

                  response.data.data.forEach((alls) => {
                      data[alls.month - 1][1] += alls.totalAmount;
                  });

                  const header = [[t('Month'), t('Amount')]];
                  const ffdata = data.length > 0 ? data : [[0, 0]];
                  header.push(...ffdata);

                  setCharData(header)
              })
              .catch((err) => {
                  if (err.response.status === 401){
                      window.location.reload(true)
                  }
              })
        }


        const getVisitorByDevice = () => {
          axios.get(SERVER_URL+"visitor/"+auth?.shop.id ,attachTokenToHeaders())
              .then((response) =>{
                   const data = []
                  let tt = 0
                  response.data.visitors.forEach((all) => {
                      data.push([all.device,all.deviceCount]);
                      tt += all.deviceCount
                  });

                   setTotalVisitorM(tt)

                  const header = [[t('Web'), t('Mobile')]];
                  const ffdata = data.length > 0 ? data : [["Desktop", 0]];
                  header.push(...ffdata);

                  setVisitorChart(header)
              })
              .catch((err) => {
                  if (err.response.status === 401){
                      window.location.reload(true)
                  }
              })
        }

        const getVisitorPerPage = () => {
          axios.get(SERVER_URL+"perpage/"+auth?.shop.id ,attachTokenToHeaders())
              .then((response) =>{
                  setVisitorPage(response.data.visitors)
              })
              .catch((err) => {
                  if (err.response.status === 401){
                      window.location.reload(true)
                  }
              })
        }

        const getProductSelled = () => {
          axios.get(SERVER_URL+"productcharts/"+auth?.shop.id ,attachTokenToHeaders())
              .then((response) =>{
                      const data = []


                  response.data.products.forEach((all) => {
                      data.push([all.title,parseInt(all.orders)]);
                  });

                  const header = [[t('Product'), t('Sellings')]];
                  const ffdata = data.length > 0 ? data : [["", 0]];
                  header.push(...ffdata);

                  setProducts(header)
              })
              .catch((err) => {
                  if (err.response.status === 401){
                      window.location.reload(true)
                  }
              })
        }




     const options = {
        title: "Vente par Année",
         curveType: "function",
         colors: ['#109de3','#e0440e'],
         hAxis: { maxValue: 12, minValue: 0 , scaleType: 'log'},
         vAxis: { minValue: 1  },
        legend: { position: "bottom" },
    };

     const optionsV = {
         pieSliceText: "label",
         is3D: true,
         colors: ['#109de3','#e0440e']
     };

     const productOption = {
         title : t('TrendingProducts'),
         colors: ['#109de3','#e0440e']
     }


    const changeFirstGrapheYear = (value) => {
        setFCurrentYear(value)
        getData(value)
        }


    return (
        <AdminLayout>


            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Charts')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item"><Link to={LINK_STORE_APPS}>{t('Applications')}</Link>
                                </li>
                                <li className="breadcrumb-item active">{t('Charts')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            {/*<pre>*/}
            {/*    {JSON.stringify(visitorPage, null, 2)}*/}
            {/*</pre>*/}


            <div className="row">
                <div className="offset-lg-10 col-lg-2 text-right">
                    <p className="underline text-muted cursor-pointer" onClick={event => loadData()}>{t('ReloadData')}</p>
                </div>
            </div>

            {
                loading ?
                    <MiddleLoading/>
                    :
                    <div className="row">
                        <div className="col-lg-8">

                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{t('Orders')}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex justify-content-end">
                                        <select name="" id="" className="form-select" style={{width: '80px'}}
                                                value={fCurrentYear}
                                                onChange={event => changeFirstGrapheYear(event.target.value)}>
                                            <option value="">-</option>
                                            {
                                                arrayYears.map((year, index) =>
                                                    <option key={index} value={year}>{year}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <br/>
                                    {
                                        charData.length > 2 ?
                                            <Chart
                                                width="100%"
                                                height="400px"
                                                chartType="LineChart"
                                                loader={<MiddleLoading/>}
                                                data={charData}
                                                options={options}
                                            />
                                            :
                                            <div className="row">
                                                <div className="offset-lg-1 col-lg-10 mt-100 mb-100">
                                                    <h3> {t('NoDataAvailableForThisYear')}</h3>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <Chart
                                        chartType="BarChart"
                                        data={products}
                                        options={productOption}
                                        loader={<MiddleLoading/>}
                                        width={"100%"}
                                        height={"330px"}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">

                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{t('VisitesThisMounth')}</h4>
                                </div>
                                <div className="card-body">
                                    <h1 style={{fontSize: "56px", textAlign: "center"}}>{totalVisitorM}</h1>
                                    <p className="text-center">{t('VisiteMount')}</p>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    {
                                        visitorPage.length > 0 ?

                                            <Chart
                                                chartType="PieChart"
                                                data={visitorChart}
                                                options={optionsV}
                                                loader={<MiddleLoading/>}
                                                width={"100%"}
                                                height={"350px"}
                                            />
                                            :
                                            <h3>{t('NoneVisit')}</h3>
                                    }
                                </div>
                            </div>


                            <div className="card">
                                <div className="card-body">
                                    {
                                        visitorPage.length > 0 ?
                                            <div className="table-responsive" style={{height : "200px",overflowY : "scroll"}}>
                                                <table className="table table-striped" >
                                                    <tbody>
                                                    {
                                                        visitorPage.map((all, index) =>
                                                            <tr key={index}>
                                                                <td>{t(all.pageName)}</td>
                                                                <td>{all.page}</td>
                                                                <td>{all.visiteCount}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <h3>{t('NoneVisit')}</h3>
                                    }
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-12">*/}
                        {/*    <div className="card">*/}
                        {/*        <div className="card-header">*/}
                        {/*            <h4 className="card-title mb-0">{t('Orders')}</h4>*/}
                        {/*        </div>*/}
                        {/*        <div className="card-body">*/}
                        {/*            {*/}
                        {/*                countryData.length > 2 ?*/}
                        {/*                    <Chart*/}
                        {/*                        options={optionsCountry}*/}
                        {/*                        chartType="GeoChart"*/}
                        {/*                        width="100%"*/}
                        {/*                        height="400px"*/}
                        {/*                        data={countryData}*/}
                        {/*                    />*/}
                        {/*                    :*/}
                        {/*                    <div className="row">*/}
                        {/*                        <div className="offset-lg-1 col-lg-10 mt-100 mb-100">*/}
                        {/*                            <h3> {t('NoDataAvailableForThisYear')}</h3>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
            }

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(Charts));