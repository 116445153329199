import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './locales/i18n';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from './store'
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {NODE_ENV, PAYPAL_DEV_CLIENT_ID, PAYPAL_PROD_CLIENT_ID} from "./helpers/keys";
import { HelmetProvider } from 'react-helmet-async'
const root = ReactDOM.createRoot(document.getElementById('root'));

const initialOptions = {
    "client-id": NODE_ENV === 'dev' ? PAYPAL_DEV_CLIENT_ID : PAYPAL_PROD_CLIENT_ID,
    currency: "USD",
    intent: "capture",
};


root.render(
    <Provider store={store}>
        <PayPalScriptProvider options={initialOptions}>
            <HelmetProvider>
               <App />
            </HelmetProvider>
        </PayPalScriptProvider>
    </Provider>
);

reportWebVitals();
