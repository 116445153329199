import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {LINK_LANDING, LINK_LOGIN_STORE} from "../../../../routes/type";
import {APPNAME, CustomChangeLanguage, SERVER_URL} from "../../../../helpers/utils";
import bg from "../register.png";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import logo from "../../../../assets/logo.png";
import {Alert} from "reactstrap";

const  CheckEMail = ({t}) => {


    document.title = APPNAME+" :: "+t('ForgetPassword')

    const [loading,setLoading] = useState(false)
    const [send,setSend] = useState(false)

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('EmailRequired'))
    });

    const initialValues = {
        email: ''
    }


    const handleSubmit = async (values) =>{
        setLoading(true)
        await axios.get(SERVER_URL+"reset/password/"+values.email).then((response)=>{
            setLoading(false)
             setSend(true)
        })
    }


    return (
        <section className="sign-in-section p-b-2">
            <div className="container-fluid reg_panel_all">
                <div className="row">
                    <div className="col-lg-4 left__panel">
                        <div className="">
                            <div className="header__left__panel">
                                <Link to={LINK_LANDING}><h3>{APPNAME}</h3></Link>
                                <div className="d-none d-sm-block">
                                    <button
                                        className={`text-white  ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('fr')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>FR
                                    </button>
                                    <button
                                        className={`text-white ${localStorage.getItem('I18N_LANGUAGE') === "en" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('en')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>EN
                                    </button>
                                </div>
                            </div>
                            <div className="left__panel__content">
                                <h1 className="text-white" style={{fontSize: "40px"}}>{t('CustomerEspace')}</h1>
                                <p>{t('CustomerEspaceText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 bg-white">
                        <div className="">
                            <div className="row">
                                <div className="offset-lg-3 col-lg-5" style={{marginTop : "50px"}}>
                                    <div className="sign-in-up-wrapper" style={{marginTop: "225px"}}>
                                        <div className="text-center">
                                            <img src={logo} alt="kiiako" style={{width: "150px"}}/>
                                        </div>

                                        <Formik initialValues={initialValues} onSubmit={handleSubmit}
                                                validationSchema={validationSchema}>
                                            {({errors, touched}) =>
                                                <Form>
                                                    <div className="form-groups">
                                                        <h4 className="form-title">{t('ForgetPassword')}</h4>
                                                        <p className="fs-16">
                                                            {t('ForgetpasswordText')}
                                                        </p>
                                                        <div className="field-group">
                                                            <Field name="email" type="email" className='front'
                                                                   placeholder={t('Email')}/>
                                                            {errors.email && touched.email ? (
                                                                <div className='text-danger'>{errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="">
                                                            <button className="template-btn primary-bg-5 btn-block"
                                                                    type="submit">
                                                                {
                                                                    loading ?
                                                                        <><span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"/>{t('Loading')}...</>
                                                                        :
                                                                        <span>{t('ResetPassword')}</span>
                                                                }
                                                            </button>

                                                        </div>
                                                    </div>
                                                    {
                                                        send ?
                                                            <Alert color={"success"}>
                                                                {t("ResetMailSendSuccess")}
                                                            </Alert>
                                                            : ""
                                                    }
                                                    <div className="form-note">
                                                        <p className="text-muted text-center">{t('HaveAccount')} <Link to={LINK_LOGIN_STORE} className="underline text-primary">{t('LoginShop')}</Link></p>
                                                    </div>
                                                </Form>
                                            }

                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default  (withTranslation()(CheckEMail))
