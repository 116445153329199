import axios from 'axios';

import {
    CATEGORIES_SHOP_LOADING,
    CATEGORIES_SHOP_SUCCESS,
    CATEGORIES_SHOP_FAIL,
    SUBCATEGORIES_SHOP_LOADING,
    SUBCATEGORIES_SHOP_SUCCESS,
    SUBCATEGORIES_SHOP_FAIL,
    PRODUCTS_SHOP_LOADING,
    PRODUCTS_SHOP_SUCCESS,
    PRODUCTS_SHOP_FAIL
} from './type';
import {attachTokenToHeaders, SERVER_URL} from "../../helpers/utils";




export const getCategories = (id) => async (dispatch) => {
    dispatch({
        type: CATEGORIES_SHOP_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'categories/'+id,attachTokenToHeaders());
            dispatch({
                type: CATEGORIES_SHOP_SUCCESS,
                payload: { categories : response.data.list},
            });


    } catch (err) {
        dispatch({
            type: CATEGORIES_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};

export const getSubcategories = (id) => async (dispatch) => {
    dispatch({
        type: SUBCATEGORIES_SHOP_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'subcategories/'+id,attachTokenToHeaders());

            dispatch({
                type: SUBCATEGORIES_SHOP_SUCCESS,
                payload: { subcategories : response.data.list,categories : response.data.categories},
            });


    } catch (err) {
        dispatch({
            type: SUBCATEGORIES_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};
export const getProducts = (id) => async (dispatch) => {
    dispatch({
        type: PRODUCTS_SHOP_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'products/'+id,attachTokenToHeaders());
            dispatch({
                type: PRODUCTS_SHOP_SUCCESS,
                payload: {
                    products : response.data.products,
                    categories : response.data.categories,
                    subcategories : response.data.subcategories,
                    galerie : response.data.galerie,
                },
            });


    } catch (err) {
        dispatch({
            type: PRODUCTS_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};


