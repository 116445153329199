import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Link, Redirect, withRouter} from "react-router-dom";
import {LINK_CONDITIONS} from "../../../../routes/type";
import axios from "axios";
import {toast} from "react-toastify";
import {loginSubUser} from "../../../../store/Subdomain/Oauth/action";
import {compose} from "redux";
import {connect} from "react-redux";


const  TrialLogin = ({t,loginSubUser,auth}) => {

    const [ profile, setProfile ] = useState([]);
    const [ user, setUser ] = useState([]);

    const [shopInfo, setShopInfo] = useState(null);

    useEffect(() => {
        setShopInfo(JSON.parse(localStorage.getItem('__S78456321__')))
    }, []);


    useEffect(() => {
        if (user.length) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }

    }, [user]);



    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('InputRequired')),
        password: Yup.string()
            .required(t('InputRequired')),
    });

    const initialValues = {
        email: '',
        password: '',
        shop_id: '',
    }

    const handleSubmit = async (values) =>{
        values.shop_id = shopInfo?.id
        loginSubUser(values)
    }



    useEffect(() => {
        return () => {
            if (auth.error){
                toast.error(t(auth.error))
                auth.error = false;
            }
        };
    }, [auth.error]);




    if (auth.user) return <Redirect to="/customer/dashboard" exact={true} />

    return (
        <div  style={{background : "#f4f4f4"}} >



            <div className="u-s-p-b-60 u-s-p-t-60">


                <div className="section__content">
                    <div className="container">
                        <div className="row row--center">
                            <div className="col-lg-4 col-md-6 u-s-m-b-30 ">
                                <div className="l-f-o bg-white">
                                    <div className="l-f-o__pad-box">

                                        <h3 className="gl-h2">{t('LogToAccount')}</h3>

                                        <div className="u-s-p-t-30">
                                                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                                    {({ errors, touched }) =>
                                                        <Form>
                                                            <div className="login">
                                                                <div className="form-group">
                                                                    <Field name="email" type="email" className='front'  placeholder={t('Email')} />
                                                                    {errors.email && touched.email ? (
                                                                        <div className='text-danger'>{errors.email}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="form-group">
                                                                    <Field name="password" type="password" className='front' placeholder={t('Password')} />
                                                                    {errors.password && touched.password ? (
                                                                        <div className='text-danger'>{errors.password}</div>
                                                                    ) : null}
                                                                </div>

                                                                <span style={{fontSize : "13px",padding :  "10px 0"}}>{t('MessageBeforeLogin')} <Link to={LINK_CONDITIONS}> {t('Termsndcondition')}</Link></span>

                                                                <button  className="btn btn-info btn-block" type="submit"  disabled={auth.loading} style={{background: "#3377ff"}}>
                                                                    {
                                                                        auth.loading ?
                                                                            <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                                                            :
                                                                            <span>{t('Login')}</span>
                                                                    }
                                                                </button>

                                                                <hr/>
                                                                <br/>

                                                                <div className="links">
                                                                    <Link to="/signup" className="">{t('Signup')}</Link>
                                                                    <Link to="signup" className="">{t('ForgetPassword')}</Link>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    }

                                                </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
});

export default compose(withRouter, connect(mapStateToProps, { loginSubUser })) (withTranslation()(TrialLogin))
