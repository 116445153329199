import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {filterSubcategories, flatData, slugify} from "../../utils/utils";
import axios from "axios";
import {CLIENT, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import {Link, useHistory} from "react-router-dom";
import {
    SUB_ABOUT, SUB_CART,
    SUB_CONTACT,
    SUB_INDEX, SUB_LOGIN,
    SUB_PRODUCTS,
    SUB_SERVICE,
    SUB_WISHLIST
} from "../../../pages/Sub/Seller/routes/type";
import {toast} from "sonner";
import swal from "@sweetalert/with-react";
import {logUserout} from "../../../store/Subdomain/Oauth/action";
import {compose} from "redux";
import {connect} from "react-redux";
import CartRight from "../../../components/Card/CartRight";

const  Header3 = ({block,t,toggleHightLight,isEditor,customStyle,shop_data,logUserout,customCart,customWishlist,auth}) => {
    const [sidemenu, setSidemenu] = useState(false);
    const [display, setDisplay] = useState(false)
    const [mobileToggle, setMobileToggle] = useState(false)
    const [showHeader, setShowHeader] = useState(false)
    const [categories, setCategories] = useState([])
    const [subcategories, setSubcategories] = useState([])
    const [open, setOpen] = useState(false)
    const [pages, setPages] = useState([])
    const history = useHistory()

        const [dimension, setDimension] = useState({
            top :0,
            left : 0,
            width : 0,
            height : 0,
        })

        const header3Ref = useRef()

        const getBlockSize = () => {
            setDimension({top :header3Ref?.current?.offsetTop, left : header3Ref?.current?.offsetLeft, width : header3Ref?.current?.offsetWidth, height : header3Ref?.current?.offsetHeight,nodeName : header3Ref?.current?.nodeName})
        };

        const getDimension = (data,status) => {
            getBlockSize()

            toggleHightLight(data,dimension,status)
        }


    useEffect(() => {
        const handleScroll = _ => {
            if (window.pageYOffset > 100) {
                setShowHeader(true)
            } else {
                setShowHeader(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return _ => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);


    const loadPages = async (key) => {
        await axios.get(SERVER_URL + "pages/list/" + key).then((response) => {
            if (response.data.succeed) {
                const content = response.data.pages?.content
                let pages = JSON.parse(content)
                let names = []
                pages.map((page) => names.push(page.name))
                setPages(names)
            } else {
                toast.error(t(response.data.message))
            }
        })

    }

    useEffect(() => {
        loadPages(shop_data.shop_key)
    }, [shop_data]);



    useEffect(() => {
        if(shop_data){
            getCategories(shop_data.id)
        }
    }, [shop_data]);


    const getCategories = async (id) => {
        await axios.get(SERVER_URL+"/public/categories/"+id).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.categories)
                setSubcategories(response.data.subcategories)
            }
        })
    }

    const logoutModal = () => {
        swal({
            title : t('Logout'),
            text : t('SureToLogout'),
            buttons : [t('Cancel'),t('Yes')],
            dangerMode : true
        }).then((pass) => {
            if (pass){
                logUserout()
            }
        })
    }

    const handleSearch = (e) => {
        e.preventDefault()
        if (!isEditor){
            console.log(e.target[0].value)
            if (e.target[0].value === ""){
                toast.error("Notification",{
                    description: t('InputRequired'),
                    icon : <i className="ri-close-fill"></i>
                })
            }else{
                history.push("/results?search_query=" + slugify(e.target[0].value))
            }
        }
    }



    return (
        <div
            ref={header3Ref}
            onMouseEnter={() => isEditor && getDimension(block,true)}
            onMouseLeave={() => isEditor && getDimension(block,false)}
            style={customStyle}
        >
            <div className={`offcanvas__area offcanvas__style-green ${mobileToggle ? 'offcanvas-opened' : ""} `}>
                <div className="offcanvas__wrapper">
                    <div className="offcanvas__close" >
                        <button className="offcanvas__close-btn offcanvas-close-btn" onClick={event => setMobileToggle(false)}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1L1 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="offcanvas__content">
                        <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
                            <div className="offcanvas__logo logo">
                                <Link to={SUB_INDEX}>
                                    <img src={shop_data? SERVER_IMG_URL+"/"+shop_data.shop_key+"/logo/"+shop_data.logo : CLIENT + '/imgs/logo/logo.png'} alt="logo" style={{width : "120px"}}/>
                                </Link>
                            </div>
                        </div>
                        <div className="offcanvas__category pb-40">
                            <button className="tp-offcanvas-category-toggle" onClick={event => setDisplay(!display)}>
                                <i className="fa-solid fa-bars"></i>
                                Categories
                            </button>
                            <div className="tp-category-mobile-menu">
                                <nav className="tp-category-menu-content" style={{display : display ? "block" : "none"}}>
                                    {
                                        isEditor ?
                                            <ul className="pl-0">
                                                <li className="has-dropdown">
                                                    <a href="#" className="has-mega-menu">

                                                        Electronics
                                                        <button className="dropdown-toggle-btn"><i className="fa-regular fa-angle-right"></i></button></a>

                                                    <ul className="mega-menu tp-submenu">
                                                        <li>
                                                            <a href="#" className="mega-menu-title">Featured</a>
                                                            <ul>
                                                                <li>
                                                                    <a href="#"><img src="assets/img/header/menu/menu-1.jpg" alt=""/></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">New Arrivals</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Best Seller</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Top Rated</a>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                        <li>
                                                            <a href="#" className="mega-menu-title">Computer &amp; Laptops</a>
                                                            <ul>
                                                                <li>
                                                                    <a href="#"><img src="assets/img/header/menu/menu-2.jpg" alt=""/></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Top Brands</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Weekly Best Selling</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Most Viewed</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="mega-menu-title">Accessories</a>
                                                            <ul>
                                                                <li>
                                                                    <a href="#"><img src="assets/img/header/menu/menu-3.jpg" alt=""/></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Headphones</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">TWS Earphone</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">Gaming Headset</a>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                    </ul>
                                                </li>
                                            </ul>
                                            :
                                            <ul className="pl-0">
                                                {
                                                    categories?.map((category,key) =>
                                                        <li className={  filterSubcategories(subcategories,category.id)?.length > 0 ? "has-dropdown" : ""} key={key}>
                                                            <Link to={"/shop/"+category.slug}>
                                                                {category.category}
                                                            </Link>

                                                            {
                                                                filterSubcategories(subcategories,category.id)?.length > 0 ?
                                                                    <>
                                                                        <ul className="tp-submenu">
                                                                            { filterSubcategories(subcategories,category.id).map((sub,index) =>
                                                                                <li key={index}><Link to={"/"+slugify(category.category)+"/"+sub.slug}>{sub.subname}</Link></li>
                                                                            )

                                                                            }
                                                                        </ul>
                                                                    </>
                                                                    :
                                                                    ""
                                                            }
                                                        </li>
                                                    ) }
                                            </ul>
                                    }
                                </nav>
                            </div>
                        </div>
                        <div className="tp-main-menu-mobile fix d-lg-none mb-40">
                            <nav className="tp-main-menu-content">
                                <ul>
                                    { pages.includes("Home") ?  <li><Link to={isEditor ? "#" : SUB_INDEX} style={{color :   window.location.pathname === SUB_INDEX ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_INDEX && "bold" }}>{t('Home')}</Link></li>: ""}
                                    {pages.includes("Shop") ?   <li><Link to={isEditor ? "#" : SUB_PRODUCTS} style={{color :   window.location.pathname === SUB_PRODUCTS ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_PRODUCTS && "bold" }}>{t('Shop')}</Link></li>: ""}
                                    {pages.includes("About") ?   <li><Link to={isEditor ? "#" : SUB_ABOUT} style={{color :   window.location.pathname === SUB_ABOUT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_ABOUT && "bold" }}>{t('About')}</Link></li>: ""}
                                    {pages.includes("Services") ?  <li><Link to={isEditor ? "#" : SUB_SERVICE} style={{color :   window.location.pathname === SUB_SERVICE ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_SERVICE && "bold" }}>{t('Service')}</Link></li>: ""}
                                    {pages.includes("Contact") ?  <li><Link to={isEditor ? "#" : SUB_CONTACT} style={{color :   window.location.pathname === SUB_CONTACT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_CONTACT && "bold" }}>{t('Contact')}</Link></li>: ""}
                                </ul>
                            </nav>
                        </div>


                    </div>
                    <div className="offcanvas__bottom">
                        <div className="offcanvas__footer d-flex align-items-center justify-content-between">
                            <div className="offcanvas__currency-wrapper currency">
                                <span className="offcanvas__currency-selected-currency" id="tp-offcanvas-currency-toggle">{t('Currency')} : XOF</span>
                            </div>
                            <div className="offcanvas__select language">
                                <div className="offcanvas__lang d-flex align-items-center justify-content-md-end">
                                    <div className="offcanvas__lang-img mr-15">
                                        <img src={CLIENT+"/admin/images/flags/french.svg"} alt=""/>
                                    </div>
                                    <div className="offcanvas__lang-wrapper">
                                        <span className="offcanvas__lang-selected-lang tp-lang-toggle" id="tp-offcanvas-lang-toggle">English</span>
                                        <ul className="offcanvas__lang-list tp-lang-list">
                                            <li>Français</li>
                                            <li>English</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tp-bottom-menu-sticky" className="tp-mobile-menu d-lg-none">
                <div className="container">
                    <div className="row row-cols-4">
                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <Link to={isEditor ? "#" :  SUB_PRODUCTS} className="tp-mobile-item-btn">
                                    <i className="flaticon-store"></i>
                                    <span>{t('Shop')}</span>
                                </Link>
                            </div>
                        </div>

                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <Link to={isEditor ? "#" :  SUB_WISHLIST} className="tp-mobile-item-btn">
                                    <i className="flaticon-love"></i>
                                    <span>{t('WishList')}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <Link to={isEditor ? "#" :  SUB_LOGIN} className="tp-mobile-item-btn">
                                    <i className="flaticon-user"></i>
                                    <span>{t('Account')}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div className="tp-mobile-item text-center">
                                <button className="tp-mobile-item-btn tp-offcanvas-open-btn" onClick={event => setMobileToggle(true)}>
                                    <i className="flaticon-menu-1"></i>
                                    <span>Menu</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header>
                <div id="header-sticky" className={`tp-header-area p-relative tp-header-sticky tp-header-height ${showHeader ? "header-sticky header-sticky-2" : ""} `} >
                    <div className="tp-header-5 pl-25 pr-25"  style={{background : flatData(block.contentEditable,'bannerColor')}}>
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-5 col-8">
                                    <div className="tp-header-left-5 d-flex align-items-center">
                                        <div className="tp-header-hamburger-5 mr-15 d-none d-lg-block">
                                            <button className="tp-hamburger-btn-2 tp-hamburger-toggle" onClick={event => setSidemenu(!sidemenu)}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </button>
                                        </div>
                                        <div className="tp-header-hamburger-5 mr-15 d-lg-none">
                                            <button className="tp-hamburger-btn-2 tp-offcanvas-open-btn" onClick={event => setMobileToggle(true)}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </button>
                                        </div>
                                        <div className="logo">
                                            <Link to={SUB_INDEX}>
                                                <img src={shop_data? SERVER_IMG_URL+"/"+shop_data.shop_key+"/logo/"+shop_data.logo : CLIENT + '/imgs/logo/logo.png'} alt="logo" style={{width : "120px"}}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-6 d-none d-xl-block">
                                    <div className="main-menu d-none">
                                        <nav id="mobile-menu">
                                            <ul>
                                                { pages.includes("Home") ?  <li><Link to={isEditor ? "#" : SUB_INDEX} style={{color :   window.location.pathname === SUB_INDEX ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_INDEX && "bold" }}>{t('Home')}</Link></li>: ""}
                                                {pages.includes("Shop") ?   <li><Link to={isEditor ? "#" : SUB_PRODUCTS} style={{color :   window.location.pathname === SUB_PRODUCTS ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_PRODUCTS && "bold" }}>{t('Shop')}</Link></li>: ""}
                                                {pages.includes("About") ?   <li><Link to={isEditor ? "#" : SUB_ABOUT} style={{color :   window.location.pathname === SUB_ABOUT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_ABOUT && "bold" }}>{t('About')}</Link></li>: ""}
                                                {pages.includes("Services") ?  <li><Link to={isEditor ? "#" : SUB_SERVICE} style={{color :   window.location.pathname === SUB_SERVICE ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_SERVICE && "bold" }}>{t('Service')}</Link></li>: ""}
                                                {pages.includes("Contact") ?  <li><Link to={isEditor ? "#" : SUB_CONTACT} style={{color :   window.location.pathname === SUB_CONTACT ? flatData(block.contentEditable,"topbarColor") : "#101010", fontWeight :   window.location.pathname === SUB_CONTACT && "bold" }}>{t('Contact')}</Link></li>: ""}
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="tp-header-search-5">
                                        <form onSubmit={handleSearch} >
                                            <div className="tp-header-search-input-box-5">
                                                <div className="tp-header-search-input-5">
                                                    <input type="text" name="search" placeholder={t('Search')}/>
                                                    <span className="tp-header-search-icon-5">
                                                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M8.11111 15.2222C12.0385 15.2222 15.2222 12.0385 15.2222 8.11111C15.2222 4.18375 12.0385 1 8.11111 1C4.18375 1 1 4.18375 1 8.11111C1 12.0385 4.18375 15.2222 8.11111 15.2222Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                          <path d="M16.9995 17L13.1328 13.1333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                       </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-7 col-4">
                                    <div className="tp-header-right-5 d-flex align-items-center justify-content-end">
                                        <div className="tp-header-login-5 d-none d-md-block">
                                            <Link to={SUB_LOGIN} className="d-flex align-items-center">
                                                <div className="tp-header-login-icon-5">
                                                    <span>
                                                       <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M8.00029 9C10.2506 9 12.0748 7.20914 12.0748 5C12.0748 2.79086 10.2506 1 8.00029 1C5.75 1 3.92578 2.79086 3.92578 5C3.92578 7.20914 5.75 9 8.00029 9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                          <path d="M15 17C15 13.904 11.8626 11.4 8 11.4C4.13737 11.4 1 13.904 1 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                       </svg>
                                                    </span>
                                                </div>
                                                <div className="tp-header-login-content-5">
                                                    <span className="text-white">Hello, {!auth?.isAuthenticated && t('LoginTo')}</span>
                                                    <h5 className="tp-header-login-title text-white">{auth?.isAuthenticated ? auth?.user?.firstname+" "+auth?.user?.lastname  : t('YourAccount')}</h5>

                                                </div>
                                            </Link>
                                        </div>
                                        <div className="tp-header-action-5 d-flex align-items-center ml-20">
                                            <div className="tp-header-action-item-5 d-none d-sm-block">
                                                <Link  className="tp-header-action-btn" to={isEditor ? "#" :  SUB_WISHLIST}>
                                                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.20125 16.0348C11.0291 14.9098 12.7296 13.5858 14.2722 12.0865C15.3567 11.0067 16.1823 9.69033 16.6858 8.23822C17.5919 5.42131 16.5335 2.19649 13.5717 1.24212C12.0151 0.740998 10.315 1.02741 9.00329 2.01177C7.69109 1.02861 5.99161 0.742297 4.43489 1.24212C1.47305 2.19649 0.40709 5.42131 1.31316 8.23822C1.81666 9.69033 2.64228 11.0067 3.72679 12.0865C5.26938 13.5858 6.96983 14.9098 8.79771 16.0348L8.99568 16.1579L9.20125 16.0348Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.85156 4.41306C4.95446 4.69963 4.31705 5.50502 4.2374 6.45262" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    <span className="tp-header-action-badge-5" style={{border: `3px solid ${flatData(block.contentEditable,'bannerColor')}`}}>{customWishlist?.wishlist?.length??0}</span>
                                                </Link>
                                            </div>
                                            <div className="tp-header-action-item-5">
                                                <Link to="#" className="cartmini-open-btn cursor-pointer" onClick={event => setOpen(true)}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.31165 17H12.6964C15.4091 17 17.4901 16.0781 16.899 12.3676L16.2107 7.33907C15.8463 5.48764 14.5912 4.77907 13.49 4.77907H4.48572C3.36828 4.77907 2.18607 5.54097 1.76501 7.33907L1.07673 12.3676C0.574694 15.659 2.59903 17 5.31165 17Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.19048 4.59622C5.19048 2.6101 6.90163 1.00003 9.01244 1.00003V1.00003C10.0289 0.99598 11.0052 1.37307 11.7254 2.04793C12.4457 2.72278 12.8506 3.6398 12.8506 4.59622V4.59622" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.38837 8.34478H6.42885" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M11.5466 8.34478H11.5871" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    <span className="tp-header-action-badge-5" style={{border: `3px solid ${flatData(block.contentEditable,'bannerColor')}`}}>{customCart?.carts.length}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tp-header-side-menu tp-side-menu-5" style={{display: !sidemenu ? "none" : ""}}>
                        <nav  className="tp-category-menu-content" style={{paddingLeft : "-20px"}}>
                            <ul>
                                <li className="has-dropdown">
                                    <a href="#">
                                        <i className="flaticon-menu"></i>
                                       Navigation
                                    </a>
                                    <div className="tp-submenu">
                                        <ul className="home-menu">
                                            {
                                                pages.includes("Home") ?
                                                <li>
                                                    <Link to={SUB_INDEX}>
                                                        <div className="home-menu-content">
                                                            <h5 className="home-menu-title">{t('Home')} </h5>
                                                        </div>
                                                    </Link>
                                                </li>
                                                    : ""
                                            }
                                            {
                                                pages.includes("Shop") ?
                                                    <li>
                                                <Link to={SUB_PRODUCTS}>
                                                    <div className="home-menu-content">
                                                        <h5 className="home-menu-title">{t('Products')}</h5>
                                                    </div>
                                                </Link>
                                            </li>
                                                    : ""
                                            }

                                            {
                                                pages.includes("Services") ?
                                            <li>
                                                <Link to={SUB_SERVICE}>
                                                    <div className="home-menu-content">
                                                        <h5 className="home-menu-title">{t('Services')}</h5>
                                                    </div>
                                                </Link>
                                            </li>

                                            : ""
                                            }

                                            {
                                                pages.includes("About") ?
                                            <li>
                                                <Link to={SUB_ABOUT}>
                                                    <div className="home-menu-content">
                                                        <h5 className="home-menu-title">{t('About')} </h5>
                                                    </div>
                                                </Link>
                                            </li>

                                            : ""
                                            }

                                            {
                                                pages.includes("Contact") ?
                                            <li>
                                                <Link to={SUB_CONTACT}>
                                                <div className="home-menu-content">
                                                    <h5 className="home-menu-title">{t('Contact')}</h5>
                                                </div>
                                            </Link>
                                            </li>

                                            : ""
                                            }
                                        </ul>
                                    </div>


                                </li>

                                {
                                    isEditor ?

                                        <div className="mega-menu tp-submenu">
                                            <div className="home-menu">
                                                <div className="row row-cols-1 row-cols-lg-3 row-cols-xl-3">
                                                    <div className="col">
                                                        <div className="home-menu-item ">
                                                            <a href="index.html">
                                                                <div className="home-menu-thumb p-relative fix">
                                                                    <img src="assets/img/menu/menu-home-1.jpg" alt=""/>
                                                                </div>
                                                                <div className="home-menu-content">
                                                                    <h5 className="home-menu-title">Electronics </h5>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="home-menu-item ">
                                                            <a href="index-2.html">
                                                                <div className="home-menu-thumb p-relative fix">
                                                                    <img src="assets/img/menu/menu-home-2.jpg" alt=""/>
                                                                </div>
                                                                <div className="home-menu-content">
                                                                    <h5 className="home-menu-title">Fashion</h5>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="home-menu-item ">
                                                            <a href="index-3.html">
                                                                <div className="home-menu-thumb p-relative fix">
                                                                    <img src="assets/img/menu/menu-home-3.jpg" alt=""/>
                                                                </div>
                                                                <div className="home-menu-content">
                                                                    <h5 className="home-menu-title">Beauty</h5>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="home-menu-item ">
                                                            <a href="index-4.html">
                                                                <div className="home-menu-thumb p-relative fix">
                                                                    <img src="assets/img/menu/menu-home-4.jpg" alt=""/>
                                                                </div>
                                                                <div className="home-menu-content">
                                                                    <h5 className="home-menu-title">Jewelry </h5>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="home-menu-item ">
                                                            <a href="index-5.html">
                                                                <div className="home-menu-thumb p-relative fix">
                                                                    <img src="assets/img/menu/menu-home-5.jpg" alt=""/>
                                                                </div>
                                                                <div className="home-menu-content">
                                                                    <h5 className="home-menu-title">Grocery</h5>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :

                                        <>
                                            {
                                                categories?.map((category,key) =>
                                                    <li className={  filterSubcategories(subcategories,category.id)?.length > 0 ? "has-dropdown" : ""} key={key}>
                                                        <Link to={"/shop/"+category.slug}>
                                                            <i className="flaticon-store-1"></i>
                                                            {category.category}
                                                        </Link>

                                                        {
                                                            filterSubcategories(subcategories,category.id)?.length > 0 ?
                                                                <>
                                                                    <ul className="tp-submenu">
                                                                        { filterSubcategories(subcategories,category.id).map((sub,index) =>
                                                                            <li key={index}><Link to={"/"+slugify(category.category)+"/"+sub.slug}>{sub.subname}</Link></li>
                                                                        )

                                                                        }
                                                                    </ul>
                                                                </>
                                                                :
                                                                ""
                                                        }
                                                    </li>
                                                ) }
                                        </>

                                }

                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            <CartRight open={open} setOpen={setOpen} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth : state.subLogin,
    customCart : state.customCart,
    customWishlist : state.customWishlist
});
export default   compose(connect(mapStateToProps,{logUserout})) (withTranslation()(Header3));
