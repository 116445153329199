import axios from 'axios';

import {
 TEMPLATE_SHOP_LOADING,TEMPLATE_SHOP_SUCCESS,TEMPLATE_SHOP_FAIL
} from './type';
import {SERVER_URL} from "../../helpers/utils";



export const loadShopTemplates = () => async (dispatch) => {
    dispatch({
        type: TEMPLATE_SHOP_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'shop/templates');
            dispatch({
                type: TEMPLATE_SHOP_SUCCESS,
                payload: { templates : response.data.templates},
            });



    } catch (err) {
        dispatch({
            type: TEMPLATE_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};


