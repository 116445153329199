import React, {Fragment, memo, useCallback} from 'react';
import SectionCard from "./parts/SectionCard";
import ItemTypes from "../../config/config.type";
import {useDrop} from "react-dnd";
import update from "immutability-helper";
import swal from "@sweetalert/with-react";

const PositionList =  ({elements,setElements,t}) =>{

    const findCard = useCallback((id) => {
            const element = elements.filter((c) => `${c.item.id}` === id)[0]
            return {
                element,
                index: elements.indexOf(element),
            }
        },
        [elements],
    )
    const moveCard = useCallback((id, atIndex) => {
            const { element, index } = findCard(id)
            setElements(
                update(elements, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, element],
                    ],
                }),
            )
        },
        [findCard, elements, setElements],
    )
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))


    const DeleteSection = (item_id) => {

        swal({
            title : t('Delete'),
            text : t('SureToDelete'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check)  {
                const ele = elements.filter(e => e.item.id !== item_id)
                setElements(ele)
            }
        });


    }


    return (
            <>
                {
                    elements?.length > 0 ?
                        <div  className="drag-elements-sidepane sidepane" ref={drop}  >
                            <div className="sections-container p-4">
                                {
                                    elements?.map((el,key) =>
                                        <Fragment key={key}>
                                            <SectionCard
                                                el={el}
                                                item_key={key}
                                                elements={elements}
                                                moveCard={moveCard}
                                                findCard={findCard}
                                                DeleteSection={DeleteSection}

                                            />
                                        </Fragment>
                                    )
                                }

                            </div>
                        </div>
                        :
                        <div className="alert alert-dismissible fade show alert-primary m-3 fs-12" role="alert" >
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            <strong>{t('NoSelectedElement')}</strong><br/> {t('SelectElement')}
                        </div>

                }
            </>
    );
}

export default PositionList;
