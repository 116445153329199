import React from 'react';
import placeholder from '../styles/product.png'

const  TrialModalCart = ({item,deleteOnCart,k}) => {
    return (
        <div className="card-mini-product" >
            <div className="mini-product">
                <div className="mini-product__image-wrapper">

                    <a className="mini-product__link" >
                        <img className="u-img-fluid" src={item.picture??placeholder} alt=""/>
                    </a>
                </div>
                <div className="mini-product__info-wrapper">
                 <span className="mini-product__category"><a href="shop-side-version-2.html">{item.category}</a></span>
                    <span className="mini-product__name"><a href="product-detail.html">{item.name}</a></span>
                    <span className="mini-product__quantity">{item.qty} x</span>
                    <span className="mini-product__price">{item.price} Frcs</span></div>
            </div>
            <a className="mini-product__delete-link cursor-pointer far fa-trash-alt" onClick={event => deleteOnCart(k)}></a>
        </div>
    );
}

export default TrialModalCart;