import axios from 'axios';

import {
   LOAD_HOME_LOADING,LOAD_HOME_SUCCESS,LOAD_HOME_FAIL
} from './type';
import {attachTokenToHeaders, SERVER_URL} from "../../helpers/utils";



export const loadHome = () => async (dispatch) => {
    dispatch({ type: LOAD_HOME_LOADING });

    try {
        const options = attachTokenToHeaders();
        const response = await axios.get(SERVER_URL+'index', options);

        dispatch({
            type: LOAD_HOME_SUCCESS,
            payload: {
                categories : response.data.categories
            },
        });
    } catch (err) {
        dispatch({
            type: LOAD_HOME_FAIL,
            payload: { error: err },
        });
    }
};



