import React, {useEffect, useState} from 'react';
import CustomInput from "../../forms/CustomInput";
import DynamicBlockInputs from "../../forms/DynamicBlockInputs";
import {MiddleLoading} from "../../../components";

const RightContent = ({t,content,setElements,elements}) => {

    const [tester, setTester] = useState([]);
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState(null);

    const updateData = (data) => {
        const updateInfo = elements.map((element) => {
            if (element.item.id === content.id) {
                const item = {
                    ...content,
                    contentEditable: content.contentEditable.map((editable) => ({
                        ...editable,
                        data: data,
                    })),
                };
                return { ...element, item };
            }
            return element;
        });
        setElements(updateInfo);
    };

    useEffect(() => {
        setLoading(true)
        if (elements.length === 0) {
            setInput([]);
            setTester([]);
             setTimeout(() =>{
                 setLoading(false)
             },1000)
        } else {
            setInput(content?.contentEditable);
            setTester(content);
            setTimeout(() =>{
                setLoading(false)
            },1000)
        }
    }, [content?.name, elements.length]);

    const handleInput = (data, id) => {
        const newState = input.map((obj) => {
            if (obj.id === id) {
                return { ...obj, value: data };
            }
            return obj;
        });
        setInput(newState);

        const insert = elements.map((obj) => {
            if (obj.item.id === content.id) {
                const item = {
                    ...content,
                    contentEditable: newState,
                };
                return { ...obj, item };
            }
            return obj;
        });

        setElements(insert);
    };


    return (
        <div>
            {
                loading ?
                     <MiddleLoading />
                    :
                    <>
                        {content !== null && tester && input && input.length > 0 ? (
                            <div className="p-3 row">
                                <h4>{content.name}</h4>
                                <small>{content?.tips ? content.tips : ""}</small>
                                {input.map((item, key) => (
                                    <div className="col-lg-12 pb-3" style={{ borderBottom: "0.1em solid #f4f4f4" }} key={key}>
                                        {item.type === "dynamicArray" ? (
                                            <DynamicBlockInputs item={item} t={t} updateData={updateData} />
                                        ) : (
                                            <CustomInput content={item} t={t} handleInput={handleInput} />
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="alert alert-dismissible fade show alert-primary m-3" role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                <strong>{t('NoSelectedElement')}</strong><br /> {t('SelectElement')}
                            </div>
                        )}
                    </>
            }
        </div>
    );
}

export default RightContent;
