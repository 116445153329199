import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {SUB_CHECKOUT, SUB_INDEX, SUB_PRODUCTS} from "../routes/type";
import {Link, useHistory} from "react-router-dom";
import { Capitalize, checkCurrentUrlSubdomain, numberWithCommas, SERVER_URL } from '../../../../helpers/utils'
import SellerLayout from "../components/SellerLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    CustomerAddCart,
    CustomerDecrease,
    CustomerDeleteCart,
    CustomerIncrease
} from "../../../../store/Subdomain/CustomerCart/action";
import axios from "axios";
import {toast} from "react-toastify";
import SubLoading from "../../../../components/Loading/SubLoading";
import { Fade } from 'react-awesome-reveal'

const Cart = ({t,customCart,CustomerIncrease,CustomerDecrease,CustomerDeleteCart,auth}) => {
    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+t('ShoppingCart')
    const [loading,setLoading] = useState(true)
    const [save,setSave] = useState(false)

    const [coupon, setCoupon] = useState("")
    const [shippingType, setShippingType] = useState("")
    const [error__coupon, setError__Coupon] = useState("")
    const [subtotal, setSubtotal] = useState(0)
    const [couponMinus, setCouponMinus] = useState(0)
    const [total, setTotal] = useState(0)
    const [shop, setShop] = useState({});

    const history = useHistory()

    useEffect(() => {
        setTimeout(() =>{
            setLoading(false)
        },1000)
        calculCart()
    }, [customCart]);



    useEffect(()=>{
        checkSubdomain()

    },[])

    const checkSubdomain = async () => {
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            if (response.data.succeed){
                setShop(response.data)
            }
        })
    }



     const saveCart = () => {

         if (shippingType === ''){
             toast.warn(t('YouHaveToChooseShippingType'))
         }
         else{
             setSave(true)
             const query =  {
                coupon: coupon?.code,
                 shipping_type: shippingType,
             }
             if (!coupon) delete query.coupon

             const objString = '?' + new URLSearchParams(query).toString();

             history.push(SUB_CHECKOUT+objString)
         }
     }





    const handleCoupon = async (event) => {
        event.preventDefault()

       if (event.target[0].input === ""){
           setError__Coupon('RequiredInput')
       }else{
          if (!auth.isAuthenticated){
            toast.info(t('YouMustBeAuthenticated'))
          }else{
              setError__Coupon('')
              const input = event.target[0].value

              console.log(event.target[0].value)

              const data = {
                  coupon : input,
                  shop_id: shop?.shop.id,
                  customer_id: auth?.user.id
              }

              await axios.post(SERVER_URL+"coupon/used",data).then((response)=>{
                  if (response.data.succeed){
                      setCoupon(response.data.coupon)
                      event.target[0].value = ""
                      ApplyCoupon(response.data.coupon)
                  }else{
                      setError__Coupon(t(response.data.message))
                  }
                  setLoading(false)
              })
          }

       }
    }


    const ApplyCoupon = async (data) => {
        if(data.type === 1){
            setCouponMinus(parseInt(data.amount))
            setTotal(subtotal - parseInt(data.amount))
        }else{
            const minus = (subtotal * parseInt(data.amount)) / 100
            setCouponMinus(minus)
            setTotal(subtotal - minus)
        }
    }


    const deleteFromCart = (key) =>{
        CustomerDeleteCart(key)
        calculCart()
    }

    const calculCart = () => {
          let total = 0;
          customCart?.carts.map((item) =>  total += (item.price * item.qty))
          setSubtotal(total)
          setTotal(total)
    }

    return (
        <>
            {
                loading ?
                    <SubLoading /> :
                    <Fade>
                        <SellerLayout>
                              <main>
                               <section className="breadcrumb__area include-bg pt-95 pb-50">
                                   <div className="container">
                                       <div className="row">
                                           <div className="col-xxl-12">
                                               <div className="breadcrumb__content p-relative z-index-1">
                                                   <h3 className="breadcrumb__title">{t('ShoppingCart')}</h3>
                                                   <div className="breadcrumb__list">
                                                       <span><Link to={SUB_INDEX}>{t('Home')}</Link></span>
                                                       <span>{t('ShoppingCart')}</span>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </section>

                             <section className="tp-cart-area pb-120">
                                           <div className="container">
                                               <div className="row">
                                                   <div
                                                       className={customCart?.carts.length > 0 ? 'col-xl-9 col-lg-8' : "col-xl-12 col-lg-12"}>
                                                       <div className="tp-cart-list tp-cart-checkout-wrapper mb-25 mr-30">
                                                           <table className="table">
                                                               <thead style={{background: "#fff"}}>
                                                               <tr>
                                                                   <th colSpan="2"
                                                                       className="tp-cart-header-product">{t('Product')}</th>
                                                                   <th className="tp-cart-header-price">{t('Price')}</th>
                                                                   <th className="tp-cart-header-quantity">{t('Quantity')}</th>
                                                                   <th className="tp-cart-header-quantity">{t('Total')}</th>
                                                                   <th className="tp-cart-header-action">Action</th>
                                                               </tr>
                                                               </thead>
                                                               <tbody>
                                                               {
                                                                   loading ?
                                                                       <tr>
                                                                           <td colSpan="6">
                                                                               <p className="text-center">
                                                                                   <span className="spinner-border spinner-border-sm"
                                                                                         role="status"
                                                                                         aria-hidden="true"/> {t('Loading')}...
                                                                               </p>
                                                                           </td>
                                                                       </tr>
                                                                       :
                                                                       <>
                                                                           {
                                                                               customCart?.carts.length > 0 ?
                                                                                   <>
                                                                                       {
                                                                                           customCart?.carts.map((item, idx) =>
                                                                                               <tr key={idx}>

                                                                                                   <td className="tp-cart-img">
                                                                                                       <img src={item.picture} alt=""/>
                                                                                                   </td>

                                                                                                   <td className="tp-cart-title ">
                                                                                                       <div className="ml-10">
                                                                                                           <h4>{item.name}</h4>
                                                                                                           <p className="text-muted">{item.category}</p>
                                                                                                       </div>
                                                                                                   </td>

                                                                                                   <td className="tp-cart-price">
                                                                                                       <span>{numberWithCommas(item.price)} Francs</span>
                                                                                                   </td>

                                                                                                   <td className="tp-cart-quantity">
                                                                                                       <div
                                                                                                           className="tp-product-quantity mt-10 mb-10">
                                                                                               <span className="tp-cart-minus"
                                                                                                     onClick={event => CustomerDecrease(idx)}>
                                                                                                  <svg width="10" height="2"
                                                                                                       viewBox="0 0 10 2" fill="none"
                                                                                                       xmlns="http://www.w3.org/2000/svg">
                                                                                                     <path d="M1 1H9"
                                                                                                           stroke="currentColor"
                                                                                                           strokeWidth="1.5"
                                                                                                           strokeLinecap="round"
                                                                                                           strokeLinejoin="round"/>
                                                                                                  </svg>
                                                                                               </span>

                                                                                                           <input
                                                                                                               className="tp-cart-input"
                                                                                                               type="text"
                                                                                                               onChange={event => console.log('')}
                                                                                                               value={item.qty}/>

                                                                                                           <span
                                                                                                               className="tp-cart-plus"
                                                                                                               onClick={event => {
                                                                                                                   CustomerIncrease(idx);
                                                                                                                   calculCart()
                                                                                                               }}>
                                                                                                      <svg width="10" height="10"
                                                                                                           viewBox="0 0 10 10"
                                                                                                           fill="none"
                                                                                                           xmlns="http://www.w3.org/2000/svg">
                                                                                                         <path d="M5 1V9"
                                                                                                               stroke="currentColor"
                                                                                                               strokeWidth="1.5"
                                                                                                               strokeLinecap="round"
                                                                                                               strokeLinejoin="round"/>
                                                                                                         <path d="M1 5H9"
                                                                                                               stroke="currentColor"
                                                                                                               strokeWidth="1.5"
                                                                                                               strokeLinecap="round"
                                                                                                               strokeLinejoin="round"/>
                                                                                                      </svg>
                                                                                                  </span>
                                                                                                       </div>
                                                                                                   </td>

                                                                                                   <td className="tp-cart-action">
                                                                                                       <span>{numberWithCommas(item.price * item.qty)} Francs </span>
                                                                                                   </td>

                                                                                                   <td className="tp-cart-action">
                                                                                                       <button
                                                                                                           className="tp-cart-action-btn"
                                                                                                           onClick={event => {
                                                                                                               deleteFromCart(idx)
                                                                                                           }}>
                                                                                                           <svg width="10" height="10"
                                                                                                                viewBox="0 0 10 10"
                                                                                                                fill="none"
                                                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                                               <path fillRule="evenodd"
                                                                                                                     clipRule="evenodd"
                                                                                                                     d="M9.53033 1.53033C9.82322 1.23744 9.82322 0.762563 9.53033 0.46967C9.23744 0.176777 8.76256 0.176777 8.46967 0.46967L5 3.93934L1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L3.93934 5L0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L5 6.06066L8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033C9.82322 9.23744 9.82322 8.76256 9.53033 8.46967L6.06066 5L9.53033 1.53033Z"
                                                                                                                     fill="currentColor"/>
                                                                                                           </svg>
                                                                                                           <span>{t('Remove')}</span>
                                                                                                       </button>
                                                                                                   </td>
                                                                                               </tr>
                                                                                           )
                                                                                       }
                                                                                   </>
                                                                                   :

                                                                                   <tr>
                                                                                       <td colSpan="6">
                                                                                           <p className="text-center">{t('EmptyList')}</p>
                                                                                       </td>
                                                                                   </tr>
                                                                           }
                                                                       </>
                                                               }
                                                               </tbody>
                                                           </table>
                                                       </div>

                                                       {
                                                           customCart?.carts.length > 0 ?
                                                               <div className="tp-cart-bottom pr-20">
                                                                   <div className="row tp-cart-checkout-wrapper"
                                                                        style={{marginRight: "10px"}}>
                                                                       <div className="col-xl-6 col-md-8">
                                                                           <div className="tp-cart-coupon">
                                                                               <form action="#" onSubmit={event => handleCoupon(event)}>
                                                                                   <div className="tp-cart-coupon-input-box">
                                                                                       <label>Coupon :</label>
                                                                                       <div
                                                                                           className="tp-cart-coupon-input d-flex align-items-center">
                                                                                           <input type="text" name="coupon"
                                                                                                  required={true}
                                                                                                  placeholder={t('YourCouponCode')}/>
                                                                                           <button type="submit"
                                                                                                   style={{background: shop?.shop?.color}}>{t('Apply')}</button>
                                                                                       </div>
                                                                                       <small
                                                                                           className="text-danger">{error__coupon}</small>
                                                                                   </div>
                                                                               </form>
                                                                           </div>
                                                                       </div>
                                                                       <div className="col-xl-6 col-md-4">
                                                                           <div className="tp-cart-update text-md-end pt-30">
                                                                               <Link to={SUB_PRODUCTS}
                                                                                     className="tp-cart-update-btn">{t('ContinueShopping')}</Link>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               : ""
                                                       }
                                                   </div>

                                                   {
                                                       customCart?.carts.length > 0 ?
                                                           <div className="col-xl-3 col-lg-4 col-md-6">
                                                               <div className="tp-cart-checkout-wrapper">
                                                                   <div
                                                                       className="tp-cart-checkout-top d-flex align-items-center justify-content-between">
                                                                       <span className="tp-cart-checkout-top-title">Subtotal</span>
                                                                       <span
                                                                           className="tp-cart-checkout-top-price">{numberWithCommas(subtotal)} Francs</span>
                                                                   </div>

                                                                   {
                                                                       shop?.shop ?

                                                                           <div className="tp-cart-checkout-shipping">
                                                                               <h4 className="tp-cart-checkout-shipping-title">{t('Shipping')}</h4>

                                                                               <div className="tp-cart-checkout-shipping-option-wrapper">


                                                                                           {
                                                                                               (shop?.shop?.config && JSON.parse(shop?.shop?.config)?.received) || !shop?.shop?.config
                                                                                                   ?
                                                                                                   <div
                                                                                                       className="tp-cart-checkout-shipping-option">
                                                                                                       <input id="ondelivery" type="radio"
                                                                                                              value="receive-on-shop"
                                                                                                              onClick={event => setShippingType(event.target.value)}
                                                                                                              name="shipping"/>
                                                                                                       <label
                                                                                                           htmlFor="ondelivery">{t("TakeOrderOnShop")}</label>
                                                                                                   </div>
                                                                                                   :
                                                                                                   ""

                                                                                           }

                                                                                           {
                                                                                               shop?.shop?.config && JSON.parse(shop?.shop?.config)?.enabled
                                                                                                   ?
                                                                                                   <div
                                                                                                       className="tp-cart-checkout-shipping-option">
                                                                                                       <input id="free_shipping" type="radio"
                                                                                                              value={JSON.parse(shop?.shop?.config).option}
                                                                                                              onClick={event => setShippingType(event.target.value)}
                                                                                                              name="shipping"/>
                                                                                                       <label
                                                                                                           htmlFor="free_shipping">{t("IWantToBeShipped")}</label>
                                                                                                   </div>
                                                                                                   : ""
                                                                                           }

                                                                                       </div>

                                                                           </div>
                                                                           :
                                                                           ""
                                                                       }


                                                                   {
                                                                       coupon &&
                                                                       <>
                                                                           <div className="tp-coupon-status d-flex align-items-center">
                                                                               <h4>Coupon <span className="active">Active</span></h4>
                                                                               <div className="tp-coupon-info-details">
                                                                     <span>
                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                           <path fillRule="evenodd" clipRule="evenodd"
                                                                                 d="M9 1.5C4.99594 1.5 1.75 4.74594 1.75 8.75C1.75 12.7541 4.99594 16 9 16C13.0041 16 16.25 12.7541 16.25 8.75C16.25 4.74594 13.0041 1.5 9 1.5ZM0.25 8.75C0.25 3.91751 4.16751 0 9 0C13.8325 0 17.75 3.91751 17.75 8.75C17.75 13.5825 13.8325 17.5 9 17.5C4.16751 17.5 0.25 13.5825 0.25 8.75ZM9 7.75C9.55229 7.75 10 8.19771 10 8.75V11.95C10 12.5023 9.55229 12.95 9 12.95C8.44771 12.95 8 12.5023 8 11.95V8.75C8 8.19771 8.44771 7.75 9 7.75ZM9 4.5498C8.44771 4.5498 8 4.99752 8 5.5498C8 6.10209 8.44771 6.5498 9 6.5498H9.008C9.56028 6.5498 10.008 6.10209 10.008 5.5498C10.008 4.99752 9.56028 4.5498 9.008 4.5498H9Z"
                                                                                 fill="currentColor"></path>
                                                                        </svg>
                                                                     </span>
                                                                                   <div className="tp-coupon-info-tooltip transition-3">
                                                                                       <p>*This coupon code will apply on <span>Grocery type products</span> and
                                                                                           when you shopping more than <span>$500</span>
                                                                                       </p>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                           <div
                                                                               className="tp-cart-checkout-total tp-coupon-date d-flex align-items-center justify-content-between">
                                                       <span style={{
                                                           display: "flex",
                                                           justifyContent: "space-between",
                                                           flexDirection: "row",
                                                           width: "100%",
                                                           marginTop: "10px"
                                                       }}>
                                                           <small>{coupon?.code}</small>

                                                           <small>- {couponMinus} Francs</small>
                                                       </span>
                                                                           </div>

                                                                       </>
                                                                   }

                                                                   <div
                                                                       className="tp-cart-checkout-total d-flex align-items-center justify-content-between">
                                                                       <span>Total</span>
                                                                       <span>{numberWithCommas(total)} Francs</span>
                                                                   </div>
                                                                   <div className="tp-cart-checkout-proceed"
                                                                        style={{background: shop?.shop?.color}}>
                                                                       <button onClick={event => saveCart()} disabled={save}
                                                                               className="tp-cart-checkout-btn w-100">

                                                                           {
                                                                               save ?
                                                                                   <><span className="spinner-border spinner-border-sm"
                                                                                           role="status"
                                                                                           aria-hidden="true"/> {t('Loading')}...</>
                                                                                   :
                                                                                   <span> {t('ProceedCheckout')}</span>
                                                                           }

                                                                       </button>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           :
                                                           ""
                                                   }

                                               </div>
                                           </div>
                                       </section>
                           </main>
                       </SellerLayout>
                    </Fade>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
    customCart: state.customCart
});
export default compose(connect(mapStateToProps, {
    CustomerAddCart,
    CustomerIncrease,
    CustomerDecrease,
    CustomerDeleteCart
}))(withTranslation()(Cart));
