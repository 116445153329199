import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {FilePond} from "react-filepond";
import {toast} from "react-toastify";
import {MiddleLoading} from "../index";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../helpers/utils";
import CustomMap from "../CustomMap/CustomMap";
import {compose} from "redux";
import {connect} from "react-redux";
import {loadMe} from "../../store/Auth/action";
import Color,{Palette} from "color-thief-react";
import CustomModal from "../Modal/CustomModal";


const CompletedPopover = ({t,auth,loadMe}) => {


    useEffect(() => {
        getLocation()
    }, []);

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showposition);
        } else {
        }
    }

    function showposition(position) {
        setPosition([position.coords.latitude, position.coords.longitude])
    }

    const options = [
        { value: 'monday', label: t('Monday') },
        { value: 'tuesday', label: t('Tuesday') },
        { value: 'wednesday', label: t('Wednesday') },
        { value: 'thursday', label: t('Thursday') },
        { value: 'friday', label: t('Friday') },
        { value: 'saturday', label: t('Saturday') },
        { value: 'sunday', label: t('Sunday') },
    ]

    const countryOptions = [
        { value: 'benin' , label: "Benin"},
        { value: "cote d'ivoire" , label: "Cote d'ivoire"},
        { value: 'mali' , label: "Mali"},
        { value: 'niger' , label: "Niger"},
        { value: 'senegal' , label: "Sénégal"},
        { value: 'togo' , label: "Togo"},
    ]


    const [open, setOpen] = useState(true);
    const [loading,setLoading] = useState(false)
    const [file, setFile] = useState([]);
    const [openDays, setOpenDays] = useState([options[0], options[1],options[2],options[3], options[4]]);
    const [openH, setOpenH] = useState("");
    const [closeH, setCloseH] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState("");
    const [position, setPosition] = useState([]);
    const [color, setColor] = useState("");

    const animatedComponents = makeAnimated();


    const addInformation = async  () =>{
        if (!file.length){
            toast.error(t('LogoRequired'));
        }
        else if(!position.length ){
            toast.error(t('LocationRequired'));
        }
        else if(openH == ""){
            document.getElementById('openH').classList.add('is-invalid');

        }
        else if(closeH == ""){
            document.getElementById('closeH').classList.add('is-invalid');
        }

        else if(country.value == ""){
            document.getElementById('country').classList.add('is-invalid');
        }

        else if(city == ""){
            document.getElementById('city').classList.add('is-invalid');
        }

        else if(address == ""){
            document.getElementById('address').classList.add('is-invalid');
        }
        else{
            setLoading(true)

            try {
                const shop_id = auth?.shop.id
                const formdata = new FormData()
                formdata.append('shop_id',shop_id)
                formdata.append('open_days', JSON.stringify(openDays))
                formdata.append("open_hours",openH)
                formdata.append("close_hours",closeH)
                formdata.append('address',address)
                formdata.append('country',country.value)
                formdata.append('city',city)
                formdata.append('color',color)
                formdata.append('position',JSON.stringify(position))
                formdata.append('type',"logo")
                formdata.append('image',file[0])

                await axios.post(SERVER_URL+"shop/update/logo",formdata,attachTokenToHeaders(true)).then((response)=>{
                    setLoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        setFile([])
                        setOpenDays([])
                        setAddress("")
                        setOpenH("")
                        setCloseH("")
                        setCity("")
                        setCountry([])
                        loadMe()
                        setTimeout(() =>{
                            setOpen(false)
                            setLoading(false)
                        },1000)



                    }else{
                        setLoading(false)
                        toast.error(t(response.data.message))
                    }
                })


            } catch (e) {
                console.log(e)
            }


        }
    }



    return (
      <CustomModal open={open} setOpen={setOpen} title="Finalisation" size="xl" fullscreen={true}>
                        {!auth?.shop?.logo ?

                            <>
                                {
                                    loading ?
                                        <MiddleLoading />
                                        :
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="p-2 mb-2">
                                                    <label htmlFor="">Logo <b className="text-danger">*</b></label>
                                                    <FilePond
                                                        files={file}
                                                        allowMultiple={true}
                                                        maxFiles={1}
                                                        acceptedFileTypes={["image/jpg", "image/jpeg","image/png"]}
                                                        name="file"
                                                        id="file"
                                                        onupdatefiles={(fileItems) => {
                                                            setFile(fileItems.map((fileItem) => fileItem.file))
                                                        }}
                                                        labelIdle={t('AddLogo')}
                                                    />
                                                    <i className="text">{t("AcceptPic")}</i>
                                                    <br/>

                                                    {
                                                        file.length > 0 && !color ? <div>
                                                                <Color src={URL.createObjectURL(file[0])}
                                                                       crossOrigin="anonymous" format="hex">
                                                                    {({data, loading}) => {
                                                                        // if (loading) return <div>Chargement...</div>;
                                                                        setColor(data)
                                                                    }}
                                                                </Color>
                                                            </div>
                                                            : ""
                                                    }
                                                    <br/>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label htmlFor="openH">{t('OpenH')} <b
                                                                    className="text-danger">*</b></label>
                                                                <input type="time" className="form-control" id="openH"
                                                                       style={{height: "50px"}} value={openH}
                                                                       onChange={event => setOpenH(event.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label htmlFor="closeH">{t('CloseH')} <b
                                                                    className="text-danger">*</b></label>
                                                                <input type="time" className="form-control" id="closeH"
                                                                       style={{height: "50px"}} value={closeH}
                                                                       onChange={event => setCloseH(event.target.value)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="openH">{t('OpenDays')} <b
                                                                    className="text-danger">*</b></label>
                                                                <Select
                                                                    closeMenuOnSelect={false}
                                                                    components={animatedComponents}
                                                                    defaultValue={openDays}
                                                                    isDisabled={false}
                                                                    onChange={setOpenDays}
                                                                    className="front"
                                                                    isMulti
                                                                    options={options}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="country">{t('Country')} <b
                                                                    className="text-danger">*</b></label>
                                                                <Select
                                                                    closeMenuOnSelect={true}
                                                                    components={animatedComponents}
                                                                    defaultValue={country}
                                                                    isDisabled={false}
                                                                    onChange={setCountry}
                                                                    id="country"
                                                                    className="front"
                                                                    options={countryOptions}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="city">{t('City')} <b
                                                                    className="text-danger">*</b></label>
                                                                <input type="text" value={city}
                                                                       onChange={event => setCity(event.target.value)}
                                                                       className="form-control"
                                                                       style={{height: "50px"}}/>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="col-lg-12">
                                                        <button className="btn btn-primary btn-block btn-lg"
                                                                onClick={event => addInformation()}>{t('Add')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label htmlFor="address">{t('Address')} <b
                                                            className="text-danger">*</b></label>
                                                        <textarea className="form-control" rows="2" id="address"
                                                                  value={address}
                                                                  onChange={event => setAddress(event.target.value)}
                                                                  cols="30"></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mt-20">
                                                    <div style={{
                                                        width: "100%",
                                                        marginBottom: "10px",
                                                        borderRadius: "9px"
                                                    }}
                                                         className="leaflet-container">
                                                        <CustomMap description={t('MyShopPosition')}
                                                                   getPosition={setPosition} zoom={17} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                }
                            </>

                            : null}


      </CustomModal>

    );
}


const mapStateToProps = (state) => ({
    auth: state.loginShop
});


export default compose(connect(mapStateToProps, { loadMe }))(withTranslation()(CompletedPopover));
