import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";

import {
    APPNAME,
    attachTokenToHeaders,
    DOMAINE, format_date, numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL
} from "../../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {SUB_CUSTOMER_DASHBOARD} from "../../routes/type";

const Invoice = ({t}) => {

    document.title = APPNAME + " :: "+ t('Invoice')
    const [detail,setDetail] = useState(null)
    const [seller,setSeller] = useState(null)
    const [Gloading,setGloading] = useState(true)


    useEffect(()=>{
        getOrderSingle()
    },[])

    const getOrderSingle = async () => {
        setGloading(true)
        await axios.get(SERVER_URL+"order/single/"+window.location.pathname.split('/').pop(),attachTokenToHeaders(false,true)).then((response)=>{
             setGloading(false)
            if (response.data.succeed){
                setDetail(response.data.order[0])
                setSeller(response.data.seller)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    const calculateSubtotal = () => {
        let price = 0
        JSON.parse(detail?.cart_content)?.map((item) => price =  price + (item.price * item.qty))
        return numberWithCommas(price)
    }



    return (
        <div className="bg-grey-color">
            {
                Gloading  ?
                    <div className="col-lg-12">
                        <div className="loading_content_center bg-white">
                            <div className="bg-white"><span className="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true" /> {t('Loading')}...
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div>
                            <div className="row">
                                <div className="col-lg-2 d-flex justify-content-end">
                                    <div className=" d-print-none mt-40 mb-5 ml-10">
                                        <Link to={SUB_CUSTOMER_DASHBOARD}
                                              className=" tp-btn-btn bg-danger text-white cursor-pointer"><i
                                            className="ri-arrow-left-fill align-bottom me-1"></i></Link>
                                    </div>
                                </div>
                                <div className="d-flex offset-lg-8 col-lg-2">
                                    <div className=" d-print-none mt-40 mb-50">
                                        <span onClick={event => window.print()}
                                              className=" tp-btn-btn bg-primary text-white  cursor-pointer"><i className="ri-printer-line align-bottom me-1"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center" id="contentInvoice">
                                <div className="col-xxl-9">
                                    <div className="card" >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card-header border-bottom-dashed p-4">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <img src={`${SERVER_IMG_URL}${seller?.shop_key}/logo/${seller?.logo}`} className="card-logo card-logo-dark" alt={seller?.shopName} height="50"/>
                                                            <div className="mt-sm-5 mt-4">
                                                                <h6 className="text-muted text-uppercase fw-semibold">{t('Address')}</h6>
                                                                <p className="text-muted mb-1" id="address-details">{seller?.address}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                                            <h6><span className="text-muted fw-normal">Email:</span><span id="email"> {seller?.email}</span></h6>
                                                            <h6><span className="text-muted fw-normal">{t('Website')}:</span> <a href={DOMAINE} className="link-primary" target="_blank" id="website">{window.location.origin}</a></h6>
                                                            <h6 className="mb-0"><span className="text-muted fw-normal">Contact No: </span><span id="contact-no"> {seller?.phone}</span></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card-body p-4">
                                                        <div className="row g-3">
                                                            <div className="col-lg-4">
                                                                <p className="text-muted mb-2 text-uppercase fw-semibold">{t('Invoice')} No</p>
                                                                <h5 className="fs-14 mb-0">#<span id="invoice-no">{detail?.order_id}</span></h5>
                                                            </div>

                                                            <div className="col-lg-4" style={{textAlign : "center"}}>
                                                                <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                                                                <h5 className="fs-14 mb-0"><span id="invoice-date">{format_date(detail?.created_at)}</span></h5>
                                                            </div>


                                                            <div className="col-lg-4" style={{textAlign : "right"}}>
                                                                <p className="text-muted mb-2 text-uppercase fw-semibold">Total</p>
                                                                <h5 className="fs-14 mb-0"><span id="total-amount">{numberWithCommas(parseInt(detail?.total_amount) + parseInt(detail?.shipping_charge))}</span> Francs</h5>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card-body p-4 border-top border-top-dashed">
                                                        <div className="row g-3">
                                                            <div className="offset-lg-6 col-lg-6" style={{textAlign : "right"}}>
                                                                <h6 className="text-muted text-uppercase fw-semibold mb-3"> {t('ShippingAddress')}</h6>
                                                                <p className="fw-medium mb-2" id="shipping-name">{detail?.delivery_fullname}</p>
                                                                <p className="text-muted mb-1" id="shipping-address-line-1">{detail?.delivery_address}</p>
                                                                <p className="text-muted mb-1"><span id="shipping-phone-no">{detail?.delivery_city} , {detail?.delivery_country}</span></p>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card-body p-4">
                                                        <div className="table-responsive">
                                                            <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                                                                <thead>
                                                                <tr className="table-active">
                                                                    <th scope="col" style={{width: "50px"}}>#</th>
                                                                    <th scope="col">Details</th>
                                                                    <th scope="col">{t('Price')}</th>
                                                                    <th scope="col">{t('Quantity')}</th>
                                                                    <th scope="col" className="text-end">{t('Amount')}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody id="products-list">
                                                                {
                                                                    JSON.parse(detail?.cart_content)?.map((item,key) =>

                                                                        <tr key={key}>
                                                                            <th scope="row">{key + 1}</th>
                                                                            <td className="text-start">
                                                                                <span className="fw-medium">{item.name}</span>
                                                                            </td>
                                                                            <td>{numberWithCommas(item.price)} Frcs</td>
                                                                            <td>{item.qty}</td>
                                                                            <td className="text-end">{numberWithCommas(item.price * item.qty)} Frcs</td>
                                                                        </tr>

                                                                    )
                                                                }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="border-top border-top-dashed mt-2">
                                                            <table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{width:"250px"}}>
                                                                <tbody>
                                                                <tr>
                                                                    <td>Sub Total</td>
                                                                    <td className="text-end">{calculateSubtotal()} Frcs</td>
                                                                </tr>
                                                                {/*<tr>*/}
                                                                {/*    <td>{t('Tax')} :</td>*/}
                                                                {/*    <td className="text-end">{detail?.tax??0} Frcs</td>*/}
                                                                {/*</tr>*/}
                                                                {
                                                                    parseInt(detail?.discount_percent) ?

                                                                        <tr>
                                                                            <td>{t('Discount')} :</td>
                                                                            <td className="text-end"> - {numberWithCommas(detail?.discount_percent)} Frcs</td>
                                                                        </tr>
                                                                        : null}
                                                                {
                                                                    parseInt(detail?.shipping_status) == 1 ?
                                                                        <tr>
                                                                            <td>{t('ShippingCharge')} :</td>
                                                                            <td className="text-end">{detail?.shipping_charge?numberWithCommas(detail?.shipping_charge):0} Frcs</td>
                                                                        </tr>
                                                                        : null}


                                                                <tr className="border-top border-top-dashed fs-15">
                                                                    <th scope="row">Total :</th>
                                                                    <th className="text-end">{numberWithCommas(parseInt(detail?.total_amount) + parseInt(detail?.shipping_charge))} Frcs</th>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                        <div className="mt-3">
                                                            <h6 className="text-muted text-uppercase fw-semibold mb-3">{t("PaymentDetails")}:</h6>
                                                            <p className="text-muted mb-1"> {t('PaymentMethod')} : <span className="fw-medium" id="payment-method">{t(detail?.payment_method.split('-')[0])}</span></p>
                                                            <p className="text-muted">Total : <span id="card-total-amount">{numberWithCommas(parseInt(detail?.total_amount) + parseInt(detail?.shipping_charge))} Frcs</span></p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                   </div>
            }
        </div>
    );
}

export default withTranslation()(Invoice)
