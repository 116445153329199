import React from 'react';
import {withTranslation} from "react-i18next";

import './loading.css';

const MiddleLoading = ({t}) => {
    return (
                <div className="loading_content_center">
                    <div className=""><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</div>
            </div>
    );
};

export default withTranslation() (MiddleLoading)
