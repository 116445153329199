import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../components/SellerLayout";
import {withTranslation} from "react-i18next"
import {Swiper,SwiperSlide} from "swiper/react";
import { Autoplay } from "swiper/modules";
import {
    CalculateDiscount,
    Capitalize, checkCurrentUrlSubdomain,
    numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL,
} from '../../../../helpers/utils'
import ReactImageMagnify from "react-image-magnify";
import {Link, useParams, withRouter} from "react-router-dom";
import axios from "axios";
import {MiddleLoading, RegLoading} from "../../../../components";
import {secondCalculDicount} from "../../../../themes/utils/utils";
import Reviews from "../components/Reviews/Reviews";
import IconShoppingCart from "../../../../components/Icons/IconShoppingCart";
import IconEye from "../../../../components/Icons/IconEye";
import IconHeart from "../../../../components/Icons/IconHeart";
import QuickView from "../../../../components/Card/QuickView";
import {compose} from "redux";
import {connect} from "react-redux";
import {ShareSocial} from "react-share-social";
import {CustomerAddCart} from "../../../../store/Subdomain/CustomerCart/action";
import {toast} from "sonner";
import moment from "moment";
import {addWishItem} from "../../../../store/Subdomain/Wishlist/action";
import {Alert} from "reactstrap";
import { Fade } from 'react-awesome-reveal'

const ProductDetail = ({t,auth,CustomerAddCart,addWishItem}) => {

    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(1);
    const { slug } = useParams();
    const [products, setProducts] = useState([]);
    const [detail, setDetail] = useState({});
    const [subcategory, setSubcategory] = useState({});
    const [qty, setQty] = useState(1);
    const [reviews, setReviews] = useState([]);
    const [reviewsStats, setReviewStats] = useState([]);
    const shareUrl = window.location.href;
    const [shop, setShop] = useState({});
    const [image, setImage] = useState("");
    const [item, setItem] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        checkSubdomain();
    }, [slug]);

    const checkSubdomain = async () => {
        try {
            const content = checkCurrentUrlSubdomain();
            const response = await axios.post(`${SERVER_URL}check/domain`, content);
            setLoading(false);
            if (response.data.succeed) {
                setShop(response.data);
                getProductDetail(response.data.shop.id);
            }
        } catch (error) {
            console.error('Error checking subdomain:', error);
            setLoading(false);
        }
    };

    const getProductDetail = async (shop_id) => {
       setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL}seller/product/${shop_id}/${slug}`);
            if (response.data.succeed) {
                setProducts(response.data.products);
                setDetail(response.data.detail);
                setSubcategory(response.data.subcategory);
                setReviews(response.data.reviews);
                setReviewStats(response.data.reviewsStats);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching product details:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (detail.pictures) {
            setImage(`${SERVER_IMG_URL}${shop?.shop?.shop_key}/products/${JSON.parse(detail.pictures)[0]}`);
        }
    }, [detail, shop]);

    const stockRest = (stock, order) => (order ? (parseInt(stock) - parseInt(order)) > 10 : true);

    const calculOrderPercent = (stock, order) => {
        const total = parseInt(stock) + parseInt(order);
        return order ? (parseInt(order) * 100) / total : 0;
    };

    const calculOrder = (stock, order) => (order ? parseInt(stock) - parseInt(order) : 0);

    const calculReviews = (reviews) => {
        const totalRate = reviews?.reduce((sum, review) => sum + review.rate, 0);
        const count = totalRate / reviews.length;
        return count || 0;
    };

    const style = {
        root: {
            width: "100%",
            backgroundColor: 'rgba(255, 255, 255, .0)',
            marginTop: "-60px"
        },
        copyContainer: {
            display: "none",
            border: '1px solid blue',
            background: 'rgb(0,0,0,0.7)'
        }
    };

    document.title = `${Capitalize(shop?.shop?.shopName)} :: ${detail?.title}`;

    const addWishlist = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0) {
            const data = {
                id: item.id,
                name: item.title,
                category: item.category,
                picture: `${SERVER_IMG_URL}${shop?.shop.shop_key}/products/${JSON.parse(item.pictures)[0]}`,
                discount: item.discount,
                price: item.price,
                hash_key: item.hash_key
            };
            addWishItem(data);
            toast("Notification", {
                description: `${t('AddToWishlist')}\n${moment(Date.now()).fromNow()}`,
                icon: <i className="ri-check-double-fill"></i>
            });
        } else {
            toast.error("Notification", {
                description: t('RuptureStockSimple'),
                icon: <i className="ri-close-fill"></i>
            });
        }
    };

    const addToCart = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0) {
            const data = {
                id: item.id,
                qty,
                category: item.category,
                picture: `${SERVER_IMG_URL}${shop?.shop.shop_key}/products/${JSON.parse(item.pictures)[0]}`,
                name: item.title,
                price: CalculateDiscount(item.price, item.discount)
            };
            CustomerAddCart(data);
            toast("Notification", {
                description: `${t('AddToCart')}\n${moment(Date.now()).fromNow()}`,
                icon: <i className="ri-check-double-fill"></i>
            });
        } else {
            toast.error("Notification", {
                description: t('RuptureStockSimple'),
                icon: <i className="ri-close-fill"></i>
            });
        }
    };


    return (
        <Fade>
            <SellerLayout>
                {
                    loading ?
                        <MiddleLoading />
                        :
                        <main>
                            <section className="tp-product-details-area pt-80"  style={{background : "#fff"}}>
                                <div className="tp-product-details-top pb-50">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-7 col-lg-6">
                                                <div className="tp-product-details-thumb-wrapper tp-product-details-thumb-style2 tp-tab">
                                                    <div className="tab-content m-img" id="productDetailsNavContent">
                                                        <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab" tabIndex="0">
                                                            <div className="tp-product-details-nav-main-thumb" style={{zIndex : "-2",border : "1px solid #101010"}}>
                                                                {
                                                                    image ?
                                                                        <ReactImageMagnify {...{
                                                                            smallImage: {
                                                                                alt: 'Wristwatch by Ted Baker London',
                                                                                isFluidWidth: true,
                                                                                src: image
                                                                            },
                                                                            largeImage: {
                                                                                src: image,
                                                                                width: 1200,
                                                                                height: 1800
                                                                            },
                                                                        }} />
                                                                        :
                                                                        <RegLoading />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <nav>
                                                        <div className="nav nav-tabs " id="productDetailsNavThumb" role="tablist">
                                                            {
                                                                detail ?
                                                                    <>
                                                                        {
                                                                            JSON.parse(detail?.pictures).map((pic,key) =>
                                                                                <button className={`nav-link ${pic === image.split("/").pop() ? "border__round" : ""}`} key={key}  onClick={event => setImage(SERVER_IMG_URL+shop?.shop?.shop_key+"/products/"+pic)}>
                                                                                    <img src={SERVER_IMG_URL+shop?.shop?.shop_key+"/products/thumb/"+pic} alt=""/>
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </>
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-6">
                                                <div className="tp-product-details-wrapper tp-product-details-wrapper-style2">
                                                    <div className="tp-product-details-category">
                                                        <span>{subcategory ? subcategory?.subname : detail?.category}</span>
                                                    </div>
                                                    <h3 className="tp-product-details-title">{detail?.title}</h3>

                                                    <div className="tp-product-details-inventory d-flex align-items-center mb-10">
                                                        <div className="tp-product-details-stock mb-10">
                                                            {detail?.stock && parseInt(detail?.stock) > 0 ? <span style={{color : "#0989FF",background : "rgba(9, 137, 255, 0.06)"}}>{t('InStock')} </span> : "" }
                                                        </div>
                                                        <div className="tp-product-details-rating-wrapper d-flex align-items-center mb-10">
                                                            {
                                                                reviews?.length > 0 ?

                                                                    <div className="tp-product-details-rating">
                                                                        {
                                                                            calculReviews(reviews) ?
                                                                                <>
                                                                                    {
                                                                                        [...Array(parseInt(calculReviews(reviews)))].map((item, key) => (
                                                                                            <span key={key}><i
                                                                                                className="fa-solid fa-star"></i></span>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                                :
                                                                                ''
                                                                        }
                                                                    </div>

                                                                    : ""
                                                            }
                                                            <div className="tp-product-details-reviews">
                                                                <span>({reviews?.length} {t('Reviews')})</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p>
                                                        {detail?.short_description}
                                                    </p>

                                                    {
                                                        detail?.discount &&
                                                        <>
                                                            {
                                                                parseInt(detail?.discount) > 0 ?
                                                                    <div
                                                                        className="tp-product-details-price-wrapper mb-20">
                                                                        <span className="tp-product-details-price old-price fs-12">{numberWithCommas(detail?.price)}  Francs CFA</span>
                                                                        <span className="tp-product-details-price new-price bold pl-10">{numberWithCommas(secondCalculDicount(parseInt(detail?.price),parseInt(detail?.discount)))} Francs CFA</span>
                                                                    </div>
                                                                    :
                                                                    <div className="tp-product-details-price-wrapper mb-20">
                                                                        <span className="tp-product-details-price new-price">{numberWithCommas(detail?.price)}  Francs CFA</span>
                                                                    </div>
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        detail?.orders ?
                                                            <div className="tp-product-details-views d-flex align-items-center">
                                                                <p><i className="ri-shopping-bag-2-fill"></i><span>{detail?.orders}</span>  {t('OrdersForThisProduct')}</p>
                                                            </div>
                                                            :
                                                            ""
                                                    }



                                                    <br/>

                                                    {
                                                        parseInt(detail?.stock) < 20 && parseInt(detail?.stock) > 0 ?
                                                            <div className="tp-product-details-stock-bar mb-25">
                                                                <p>{t('HurryOnly')} <span>{detail?.stock}</span> {t('UnitInStock')}</p>

                                                                <div className="tp-product-details-stock-bar-line" style={{background : "#D3DAE1"}}>
                                                                    <span className="tp-product-details-stock-bar-line-inner" style={{width : `${calculOrderPercent(detail?.stock,detail?.orders)}%`}}></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            ""

                                                    }



                                                    {detail?.stock && parseInt(detail?.stock) > 0 ?
                                                        <div className="tp-product-details-action-wrapper">
                                                            <h3 className="tp-product-details-action-title">{t('Quantity')}</h3>
                                                            <div className="tp-product-details-action-item-wrapper d-flex flex-wrap align-items-center">
                                                                <div className="tp-product-details-quantity">
                                                                    <div className="tp-product-quantity mb-15 mr-15">
                                                               <span className="tp-cart-minus" onClick={event => setQty(qty - 1)}>
                                                                  <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                     <path d="M1 1H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                  </svg>
                                                               </span>
                                                                        <input className="tp-cart-input" type="text" value={qty} onChange={event => setQty(event.target.value)}/>
                                                                        <span className="tp-cart-plus" onClick={event => setQty(qty + 1)}>
                                                                  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                     <path d="M1 6H10" stroke="currentColor" strokeWidth="1.5"  strokeLinejoin="round"/>
                                                                     <path d="M5.5 10.5V1.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                  </svg>
                                                               </span>
                                                                    </div>
                                                                </div>
                                                                <div className="tp-product-details-add-to-cart mb-15 mr-10" style={{backgroundColor : shop?.shop.color, border : "1px solid "+shop?.shop.color}}>
                                                                    <button className="tp-product-details-add-to-cart-btn w-100" onClick={event => addToCart(detail)} style={{paddingBottom : "12px",paddingTop : "12px"}}>{t('BuyNow')}</button>
                                                                </div>
                                                                <div className="tp-product-details-wishlist mb-15">
                                                                    <button className="tp-product-details-wishlist-btn" onClick={event => addWishlist(detail)}>
                                                                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M1.52624 7.48527C2.69896 11.0641 7.33213 13.9579 8.5634 14.6742C9.79885 13.9505 14.4655 11.0248 15.6006 7.48855C16.3458 5.20273 15.6541 2.30731 12.9055 1.43844C11.5738 1.01918 10.0205 1.27434 8.94817 2.08824C8.724 2.25726 8.41284 2.26054 8.18699 2.09317C7.05107 1.25547 5.56719 1.01015 4.21463 1.43844C1.47019 2.30649 0.780949 5.20191 1.52624 7.48527ZM8.56367 16C8.45995 16 8.35706 15.9754 8.26338 15.9253C8.00157 15.785 1.83433 12.4507 0.331203 7.86098C0.330366 7.86098 0.330366 7.86016 0.330366 7.86016C-0.613163 4.97048 0.437434 1.3391 3.82929 0.266748C5.42192 -0.238659 7.15758 -0.0163125 8.56116 0.852561C9.92125 0.009122 11.728 -0.22389 13.2888 0.266748C16.684 1.34074 17.738 4.9713 16.7953 7.86016C15.3407 12.3973 9.12828 15.7818 8.86479 15.9237C8.77111 15.9746 8.66739 16 8.56367 16Z" fill="currentColor"/>
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.5155 6.78932C13.1918 6.78932 12.9174 6.54564 12.8906 6.22402C12.8354 5.5496 12.3754 4.9802 11.7204 4.77262C11.39 4.6676 11.2094 4.32054 11.3156 3.9981C11.4235 3.67483 11.774 3.49926 12.1052 3.60099C13.2453 3.96282 14.0441 4.95312 14.142 6.12393C14.1696 6.46278 13.9128 6.75979 13.5673 6.78686C13.5498 6.7885 13.5331 6.78932 13.5155 6.78932Z" fill="currentColor"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        :

                                                        <div style={{marginTop : "10px"}}>
                                                            <Alert color="danger">
                                                                {t('RuptureStock')} {shop?.shop?.phone}
                                                            </Alert>
                                                        </div>

                                                    }

                                                    <div className="tp-product-details-query">
                                                        <div className="tp-product-details-query-item d-flex align-items-center">
                                                            <span>Badge:  </span>
                                                            <p>{t(detail?.badge)}</p>
                                                        </div>
                                                        <div className="tp-product-details-query-item d-flex align-items-center">
                                                            <span>{t('Categorie')}:  </span>
                                                            <p>{detail?.category}</p>
                                                        </div>
                                                    </div>
                                                    <p>{t('ShareOn')} : </p>
                                                    <div className="tp-product-details-social">
                                                        <ShareSocial
                                                            url={shareUrl}
                                                            socialTypes= {['facebook','twitter','whatsapp','linkedin','telegram']}
                                                            onSocialButtonClicked={ data => console.log(data)}
                                                            style={style}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tp-product-details-bottom tp-product-details-bottom-style2 pt-30 pb-85 white-bg">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="tp-product-details-tab-nav tp-tab">
                                                    <nav>
                                                        <div className="nav nav-tabs pRelative tp-product-tab justify-content-sm-start justify-content-center" id="nav-tab" role="tablist">
                                                            <button className={`nav-link ${step === 1 ? "active" : ""}`} onClick={event => setStep(1)}>Description</button>
                                                            <button className={`nav-link ${step === 2 ? "active" : ""}`} onClick={event => setStep(2)} >{t('AdditionalInfo')}</button>
                                                            <button className={`nav-link ${step === 3 ? "active" : ""}`} onClick={event => setStep(3)}>{t('Reviews')} ({reviews?.length})</button>
                                                            <span id="productTabMarker" className="tp-product-details-tab-line"></span>
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className={`tab-pane ${step === 1 ? "fade show active" : ""}`} id="nav-description" role="tabpanel" aria-labelledby="nav-description-tab" tabIndex="0">
                                                            <div className="tp-product-details-desc-wrapper-2 pt-40">
                                                                <div dangerouslySetInnerHTML={{__html: detail?.description }} />
                                                            </div>
                                                        </div>
                                                        <div className={`tab-pane ${step === 2 ? "fade show active" : ""}`} id="nav-addInfo" role="tabpanel" aria-labelledby="nav-addInfo-tab" tabIndex="0">

                                                            <div className="tp-table-style-2">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-xl-12">
                                                                        <table  className="mt-30 table table-striped table-bordered">
                                                                            <tbody>

                                                                            {
                                                                                detail?.additional_info ?
                                                                                    <>
                                                                                        {
                                                                                            JSON.parse(detail.additional_info).map((item,key) =>

                                                                                                <tr key={key}>
                                                                                                    <td>{item.label}</td>
                                                                                                    <td>{item.value}</td>
                                                                                                </tr>

                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                    :

                                                                                    <h5>{t('NoAditionnalInfo')}</h5>
                                                                            }

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`tab-pane ${step === 3 ? "fade show active" : ""}`} id="nav-review" role="tabpanel" aria-labelledby="nav-review-tab" tabIndex="0">
                                                            <Reviews reviewsStats={reviewsStats} reviews={reviews} created={getProductDetail} me={auth?.user} product={detail?.id} shop={shop?.shop} calculReviews={calculReviews} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            {
                                products.length > 0 ?
                                    <section className="tpRelated-product pt-95 pb-120">
                                        <div className="container">
                                            <div className="row">
                                                <div className="tp-section-titleWrapper-6 text-center mb-40">
                                                <span className="tp-section-title-pre-6"
                                                      style={{color: shop?.shop.color}}>{t('YouMayBeInterested')}</span>
                                                    <h3 className="tp-section-title-6">{t('RelatedProducts')}</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="tp-product-related-slider">
                                                    <div
                                                        className="tp-product-related-slider-active swiper-container  mb-10">
                                                        <div className="swiper-wrapper">
                                                            <Swiper
                                                                speed={1000}
                                                                modules={[Autoplay]}
                                                                grabCursor={true}
                                                                autoplay={{
                                                                    delay: 3000,
                                                                    disableOnInteraction: false,
                                                                }}
                                                                loop={true}

                                                                spaceBetween={20}
                                                                slidesPerView={products?.length > 3 ? 4 : products.length}

                                                            >
                                                                {
                                                                    products.map((product, key) =>

                                                                        <SwiperSlide key={key}>
                                                                            <div className="swiper-slide m-2">
                                                                                <div
                                                                                    className="tp-product-item-3 tp-product-style-primary mb-50">
                                                                                    <div
                                                                                        className="tp-product-thumb-3 mb-15 fix pRelative z-index-1">
                                                                                        <Link
                                                                                            to={'/product/detail/' + product.hash_key}>
                                                                                            <img
                                                                                                src={SERVER_IMG_URL + shop?.shop?.shop_key + "/products/thumb/" + JSON.parse(product.pictures)[0]}
                                                                                                alt=""/>
                                                                                        </Link>


                                                                                        {
                                                                                            parseInt(product?.discount) > 0 ?
                                                                                                <div
                                                                                                    className="tp-product-badge-3">
                                                                                                <span
                                                                                                    className="product-discount">{product?.discount}%</span>
                                                                                                </div>
                                                                                                : ""
                                                                                        }

                                                                                        <div
                                                                                            className="tp-product-action-3 tp-product-action-4 has-shadow tp-product-action-primaryStyle">
                                                                                            <div
                                                                                                className="tp-product-action-item-3 d-flex flex-column">
                                                                                                <button type="button"
                                                                                                        className="tp-product-action-btn-3 tp-product-add-cart-btn">
                                                                                                    <IconShoppingCart/>
                                                                                                    <span
                                                                                                        className="tp-product-tooltip">{t('BuyNow')}</span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                        className="tp-product-action-btn-3 tp-product-quick-view-btn"
                                                                                                        onClick={event => {
                                                                                                            setItem(product);
                                                                                                            setOpen(true)
                                                                                                        }}
                                                                                                >
                                                                                                    <IconEye/>
                                                                                                    <span
                                                                                                        className="tp-product-tooltip">{t('QuickView')}</span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                        className="tp-product-action-btn-3 tp-product-add-to-wishlist-btn">
                                                                                                    <IconHeart/>
                                                                                                    <span
                                                                                                        className="tp-product-tooltip">{t('AddToWishlist')}</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="tp-product-content-3">
                                                                                        <div className="tp-product-tag-3">
                                                                                            <span>{subcategory?.subname}</span>
                                                                                        </div>
                                                                                        <h3 className="tp-product-title-3">
                                                                                            <Link
                                                                                                to={'/product/detail/' + product.hash_key}>
                                                                                                {product.title}
                                                                                            </Link>
                                                                                        </h3>
                                                                                        {
                                                                                            parseInt(product?.discount) > 0 ?
                                                                                                <div
                                                                                                    className="tp-product-details-price-wrapper mb-20">
                                                                                                <span
                                                                                                    className="tp-product-details-price old-price fs-12">{numberWithCommas(product?.price)} Francs CFA</span>
                                                                                                    <span
                                                                                                        className="tp-product-details-price new-price pl-10 fs-18"
                                                                                                        style={{color: shop?.shop.color}}>{numberWithCommas(secondCalculDicount(parseInt(product?.price), parseInt(product?.discount)))} Francs CFA</span>
                                                                                                </div>
                                                                                                :
                                                                                                <div
                                                                                                    className="tp-product-details-price-wrapper mb-20">
                                                                                                <span
                                                                                                    className="tp-product-details-price new-price fs-18"
                                                                                                    style={{color: shop?.shop.color}}>{numberWithCommas(product?.price)} Francs CFA</span>
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </SwiperSlide>
                                                                    )
                                                                }

                                                            </Swiper>
                                                        </div>
                                                    </div>
                                                    <div className="tpRelated-swiper-scrollbar tp-swiper-scrollbar"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    : ""

                            }


                        </main>
                }
                <QuickView
                    data={item}
                    shop_data={shop?.shop}
                    setOpen={setOpen}
                    open={open}
                />
            </SellerLayout>
        </Fade>
    )
}

const mapStateToProps = (state) => ({
    auth: state.subLogin,
    customCart: state.customCart
});

export default compose(withRouter, connect(mapStateToProps, {
    CustomerAddCart,
    addWishItem
}))(withTranslation()(ProductDetail))
