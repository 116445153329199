import React from 'react';
import {withTranslation} from "react-i18next";

const ThemeToggle = ({t}) => {


    const ToggleConfig = (name,value) => {
        document.querySelector("html").setAttribute(name, value)
        localStorage.setItem(name,value)
    }

    // const ChangeScheme = (value) => {
    //     document.querySelector("html").setAttribute("data-layout-mode", value)
    //     localStorage.setItem("data-layout-mode",value)
    // }




    return (
            <div className="offcanvas offcanvas-end border-0" tabIndex="-1" id="theme-settings-offcanvas">
                <div className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
                    <h5 className="m-0 me-2 text-white">Theme Customizer</h5>

                    <button type="button" className="btn-close btn-close-white ms-auto" id="customizerclose-btn" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <br/>
                <div className="offcanvas-body p-0" >
                    <div className="" style={{height : "100vh",overflowY : "scroll !important",marginBottom : "-20px"}}>
                        <div className="p-4">
                            <h6 className="mb-0 fw-semibold text-uppercase">{t('Layout')}</h6>
                            <p className="text-muted">{t("ChooseYourLayout")}</p>

                            <div className="row gy-3">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout01" name="data-layout" type="radio"
                                               defaultChecked={localStorage.getItem('data-layout') === "vertical"} value="vertical"
                                               onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                               className="form-check-input"/>
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout01">
                                    <span className="d-flex gap-1 h-100">
                                        <span className="flex-shrink-0">
                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                            </span>
                                        </span>
                                        <span className="flex-grow-1">
                                            <span className="d-flex h-100 flex-column">
                                                <span className="bg-light d-block p-1"></span>
                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                            </span>
                                        </span>
                                    </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Vertical</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout02" name="data-layout" type="radio" value="horizontal" className="form-check-input"
                                               defaultChecked={localStorage.getItem('data-layout') === "horizontal"}
                                               onClick={event => ToggleConfig(event.target.name,event.target.value)} />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout02">
                                    <span className="d-flex h-100 flex-column gap-1">
                                        <span className="bg-light d-flex p-1 gap-1 align-items-center">
                                            <span className="d-block p-1 bg-soft-primary rounded me-1"></span>
                                            <span className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"></span>
                                            <span className="d-block p-1 pb-0 px-2 bg-soft-primary"></span>
                                        </span>
                                        <span className="bg-light d-block p-1"></span>
                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Horizontal</h5>
                                </div>
                            </div>

                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">Color Scheme</h6>
                            <p className="text-muted">Choose Light or Dark Scheme.</p>

                            <div className="colorscheme-cardradio">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-mode" id="layout-mode-light"
                                                   value="light" defaultChecked={localStorage.getItem('data-layout-mode') === "light"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value) }/>
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="layout-mode-light">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                    <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check card-radio dark">
                                            <input className="form-check-input" type="radio" name="data-layout-mode" id="layout-mode-dark" value="dark"
                                                   defaultChecked={localStorage.getItem('data-layout-mode') === "dark"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value) }/>
                                                <label className="form-check-label p-0 avatar-md w-100 bg-dark" htmlFor="layout-mode-dark">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-soft-light d-flex h-100 flex-column gap-1 p-1">
                                                    <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-soft-light d-block p-1"></span>
                                                    <span className="bg-soft-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                </div>
                            </div>


                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">Topbar Color</h6>
                            <p className="text-muted">Choose Light or Dark Topbar Color.</p>

                            <div className="row">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar" id="topbar-color-light" value="light"
                                               defaultChecked={localStorage.getItem('data-topbar') === "light"}
                                         onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                        />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="topbar-color-light">
                                    <span className="d-flex gap-1 h-100">
                                        <span className="flex-shrink-0">
                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                            </span>
                                        </span>
                                        <span className="flex-grow-1">
                                            <span className="d-flex h-100 flex-column">
                                                <span className="bg-light d-block p-1"></span>
                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                            </span>
                                        </span>
                                    </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Light</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar" id="topbar-color-dark" value="dark"
                                               defaultChecked={localStorage.getItem('data-topbar') === "dark"}
                                               onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                        />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="topbar-color-dark">
                                    <span className="d-flex gap-1 h-100">
                                        <span className="flex-shrink-0">
                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                            </span>
                                        </span>
                                        <span className="flex-grow-1">
                                            <span className="d-flex h-100 flex-column">
                                                <span className="bg-primary d-block p-1"></span>
                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                            </span>
                                        </span>
                                    </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Dark</h5>
                                </div>
                            </div>

                            <div id="sidebar-size">
                                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">Sidebar Size</h6>
                                <p className="text-muted">Choose a size of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-default" value="lg"
                                                   defaultChecked={localStorage.getItem('data-sidebar-size') === "lg"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-default">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                    <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-compact" value="md"
                                                   defaultChecked={localStorage.getItem('data-sidebar-size') === "md"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-compact">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                    <span className="d-block p-1 bg-soft-primary rounded mb-2"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Compact</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-small" value="sm"
                                                   defaultChecked={localStorage.getItem('data-sidebar-size') === "sm"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-light d-flex h-100 flex-column gap-1">
                                                    <span className="d-block p-1 bg-soft-primary mb-2"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small (Icon View)</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-small-hover" value="sm-hover"
                                                   defaultChecked={localStorage.getItem('data-sidebar-size') === "sm-hover"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small-hover">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-light d-flex h-100 flex-column gap-1">
                                                    <span className="d-block p-1 bg-soft-primary mb-2"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small Hover View</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-view">
                                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">Sidebar View</h6>
                                <p className="text-muted">Choose Default or Detached Sidebar view.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style" id="sidebar-view-default" value="default"
                                                   defaultChecked={localStorage.getItem('data-layout-style') === "default"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-view-default">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                    <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style" id="sidebar-view-detached" value="detached"
                                                   defaultChecked={localStorage.getItem('data-layout-style') === "detached"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-view-detached">
                                        <span className="d-flex h-100 flex-column">
                                            <span className="bg-light d-flex p-1 gap-1 align-items-center px-2">
                                                <span className="d-block p-1 bg-soft-primary rounded me-1"></span>
                                                <span className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"></span>
                                                <span className="d-block p-1 pb-0 px-2 bg-soft-primary"></span>
                                            </span>
                                            <span className="d-flex gap-1 h-100 p-1 px-2">
                                                <span className="flex-shrink-0">
                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="bg-light d-block p-1 mt-auto px-2"></span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Detached</h5>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar-color">
                                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">Sidebar Color</h6>
                                <p className="text-muted">Choose a color of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-light" value="light"
                                                   defaultChecked={localStorage.getItem('data-sidebar') === "light"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-color-light">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                                                    <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-dark" value="dark"
                                                   defaultChecked={localStorage.getItem('data-sidebar') === "dark"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-color-dark">
                                        <span className="d-flex gap-1 h-100">
                                            <span className="flex-shrink-0">
                                                <span className="bg-primary d-flex h-100 flex-column gap-1 p-1">
                                                    <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                </span>
                                            </span>
                                            <span className="flex-grow-1">
                                                <span className="d-flex h-100 flex-column">
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </span>
                                        </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn btn-link avatar-md w-100 p-0 overflow-hidden border collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient" aria-expanded="false" aria-controls="collapseBgGradient">
                                    <span className="d-flex gap-1 h-100">
                                        <span className="flex-shrink-0">
                                            <span className="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                                                <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                            </span>
                                        </span>
                                        <span className="flex-grow-1">
                                            <span className="d-flex h-100 flex-column">
                                                <span className="bg-light d-block p-1"></span>
                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                            </span>
                                        </span>
                                    </span>
                                        </button>
                                        <h5 className="fs-13 text-center mt-2">Gradient</h5>
                                    </div>
                                </div>

                                <div className="collapse" id="collapseBgGradient">
                                    <div className="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">

                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient" value="gradient"
                                                   defaultChecked={localStorage.getItem('data-sidebar') === "gradient"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient">
                                                    <span className="avatar-title rounded-circle bg-vertical-gradient"></span>
                                                </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-2" value="gradient-2"
                                                   defaultChecked={localStorage.getItem('data-sidebar') === "gradient-2"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient-2">
                                                    <span className="avatar-title rounded-circle bg-vertical-gradient-2"></span>
                                                </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-3" value="gradient-3"
                                                   defaultChecked={localStorage.getItem('data-sidebar') === "gradient-3"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient-3">
                                                    <span className="avatar-title rounded-circle bg-vertical-gradient-3"></span>
                                                </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-4" value="gradient-4"
                                                   defaultChecked={localStorage.getItem('data-sidebar') === "gradient-4"}
                                                   onClick={event => ToggleConfig(event.target.name,event.target.value)}
                                            />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient-4">
                                                    <span className="avatar-title rounded-circle bg-vertical-gradient-4"></span>
                                                </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>

                <div className="offcanvas-footer border-top p-3 text-center">
                    <div className="row">
                        <div className="col-6">
                            <button type="button" className="btn btn-light w-100" id="reset-layout">Reset</button>
                        </div>
                    </div>
                </div>

            </div>
    );
}

export default  withTranslation() (ThemeToggle)
