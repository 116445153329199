import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {CalculateDiscount, numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import {MiddleLoading} from "../../../components";
import {Navigation} from "swiper/modules";
import {Swiper,SwiperSlide} from "swiper/react";
import QuickView from "../../../components/Card/QuickView";
import {Link, withRouter} from "react-router-dom";
import {secondCalculDicount} from "../../utils/utils";
import {toast} from "sonner";
import moment from "moment/moment";
import {CustomerAddCart} from "../../../store/Subdomain/CustomerCart/action";
import {compose} from "redux";
import {connect} from "react-redux";

const WeekFeatured = ({block,customStyle,toggleHightLight,t,isEditor,shop_data,CustomerAddCart}) => {

    const PRODUCT_DETAIL = "/product/detail/"
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(null)

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const weekFeaturedRef = useRef()

    const getBlockSize = () => {
        setDimension({top :weekFeaturedRef?.current?.offsetTop, left : weekFeaturedRef?.current?.offsetLeft, width : weekFeaturedRef?.current?.offsetWidth, height : weekFeaturedRef?.current?.offsetHeight,nodeName : weekFeaturedRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }



    useEffect(() => {
        if (isEditor){
            setLoading(false)
            setProducts(block.data)
        }else{
            getProductsData(shop_data.id)
        }
    }, [isEditor])

    const getProductsData = async (id) => {
        await axios.get(SERVER_URL+"/public/products/"+id).then((response)=>{
            setLoading(false)
            if (response.data.succeed){

                setProducts(response.data.products.slice(0,8))

            }
        })
    }

    const quickView = (item) => {
        setItem(item)
        setOpen(true)
    }

    const addToCart = (item) => {
        if (item?.stock && parseInt(item?.stock) > 0){
            const data = {
                id:item.id,
                qty : 1,
                category : item.category,
                picture : SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0],
                name:item.title,
                price: CalculateDiscount(item?.price,item?.discount)
            }
            CustomerAddCart(data)

            toast("Notification",{
                description: t('AddToCart') +"\n"+ moment(Date.now()).fromNow(),
                icon : <i className="ri-check-double-fill"></i>
            })
        }
        else{
            toast.error("Notification",{
                description: t('RuptureStockSimple'),
                icon : <i className="ri-close-fill"></i>
            })
        }
    }

    return (
        <section className="tp-featured-slider-area grey-bg-6 fix pt-65 pb-15"
                 style={customStyle}
                 ref={weekFeaturedRef}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-section-titleWrapper-2 mb-50">
                        <span className="tp-section-title-pre-2">
                          {t('Products')}
                           <svg width="82" height="22" viewBox="0 0 82 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M81 14.5798C0.890564 -8.05914 -5.81154 0.0503902 5.00322 21" stroke="currentColor" stroke-opacity="0.3" strokeWidth="2" stroke-miterlimit="3.8637" strokeLinecap="round"/>
                           </svg>
                        </span>
                            <h3 className="tp-section-title-2">{t('WeekFeatured')}</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-featured-slider">
                            <div className="tp-featured-slider-active swiper-container">
                                {
                                    loading ?
                                        <MiddleLoading />
                                        :

                                        <div className="swiper-wrapper">
                                            <Swiper
                                                grabCursor={true}
                                                centeredSlides={true}
                                                loop={true}
                                                slidesPerView={products.length > 2 ? 3 : 2}
                                                spaceBetween={20}
                                                modules={[Navigation]}
                                                navigation={{
                                                    nextEl : ".tp-featured-slider-button-next",
                                                    prevEl : ".tp-featured-slider-button-prev",
                                                    clickable : true
                                                }}
                                            >
                                                {
                                                    products?.map((item,key) =>
                                                        <SwiperSlide key={key}>
                                                              <div className="tp-featured-item swiper-slide white-bg p-relative z-index-1 row" style={{width: "645.333px"}} >
                                                                    <div className="tp-featured-content col-lg-6">
                                                                        <h3 className="tp-featured-title">
                                                                            <Link to={PRODUCT_DETAIL+item.hash_key}>{item.title}</Link>
                                                                        </h3>
                                                                        {
                                                                            parseInt(item.discount) > 0 ?
                                                                                <div className="tp-product-priceWrapper">
                                                                                    <span className="tp-product-price old-price fs-14">{numberWithCommas(item.price)}  Francs CFA</span>
                                                                                    <span className="tp-product-price new-price pl-10 fs-24">{numberWithCommas(secondCalculDicount(parseInt(item.price),parseInt(item.discount)))} Francs CFA</span>
                                                                                </div>
                                                                                :

                                                                                <div className="tp-product-priceWrapper">
                                                                                    <span className="tp-product-price new-price fs-24">{numberWithCommas(item.price)}  Francs CFA</span>
                                                                                </div>
                                                                        }

                                                                        {
                                                                            item.rating ?
                                                                                <div className="tp-product-rating-icon tp-product-rating-icon-2">
                                                                                        {
                                                                                            [...parseInt(item.rating)].map((item,key) => (
                                                                                                <span key={key}><i className="fa-solid fa-star"></i></span>
                                                                                            ))
                                                                                        }
                                                                                </div>
                                                                                :

                                                                                <small className="text-muted">{item.short_description}</small>
                                                                        }

                                                                        <div className="tp-featured-btn mt-20">
                                                                            <button onClick={event => addToCart(item)} className="tp-btn tp-btn-border tp-btn-border-sm"> {t('BuyNow')}
                                                                                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M16 7.49988L1 7.49988" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                    <path d="M9.9502 1.47554L16.0002 7.49954L9.9502 13.5245" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                  <div className="col-lg-6">
                                                                      <img src={isEditor ? item.picture : SERVER_IMG_URL+shop_data?.shop_key+"/products/"+JSON.parse(item.pictures)[0]} alt=""/>
                                                                  </div>
                                                                </div>
                                                        </SwiperSlide>
                                                    )
                                                }
                                            </Swiper>
                                        </div>
                                }
                            </div>
                            <div className="tp-featured-slider-arrow mt-45">
                                <button className="tp-featured-slider-button-prev">
                                    <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.97974 7.97534L31.9797 7.97534" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8.02954 0.999999L0.999912 7.99942L8.02954 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <button className="tp-featured-slider-button-next">
                                    <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.9795 7.97534L0.979492 7.97534" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M24.9297 0.999999L31.9593 7.99942L24.9297 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuickView
                open={open}
                setOpen={setOpen}
                data={item}
                shop_data={shop_data}
            />
        </section>

    )
}

const mapStateToProps = (state) => ({
    customCart : state.customCart
});
export default compose(withRouter, connect(mapStateToProps,{CustomerAddCart})) (withTranslation()(WeekFeatured))
