import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {
    APPNAME,
    attachTokenToHeaders,
    changeCurrency,
    numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL
} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {FedaPay, PayPal, RegLoading} from "../../../../components";
import PricingModal from "../../../Front/Register/Subscribe/PricingModal";
import {compose} from "redux";
import {connect} from "react-redux";
import TemplateModal from "../../../Front/Register/Subscribe/TemplateModal";
import CustomRadioInput from "../../../../components/CustomInputs/CustomRadioInput";
import {Link, useHistory, useLocation} from 'react-router-dom'
import {LINK_DOCS, LINK_STORE_DASHBOARD} from "../../../../routes/type";
import {loadMe} from "../../../../store/Auth/action";
import {Alert} from "reactstrap";
import buildSelf from "../../../../assets/buildSelf.png";


const Changeplan = ({t,auth}) => {


     // logged plan
    const  slice = useRef(auth?.plan?.pricing_id)

    // change steps
    const [step, setStep] = useState(1);
    const [substep, setSubstep] = useState(1);


    // set data from server
    const [pricing,setPricing] = useState([])
    const [pages, setPages] = useState([]);
    const [templates,setTemplates] = useState([])


    const [loading,setLoading] = useState(true)

    // Open modals
    const [open,setOpen] = useState(false)
    const [openT,setOpenT] = useState(false)
    const [data,setData] = useState(null)

    const [selectedplan, setSelectedPlan] = useState({});
    const [selectedtheme, setSelectedTheme] = useState({});


    const [annual, setAnnual] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [oldPrice, setOldprice] = useState(0);

    const [subTotal, setSubTotal] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);
    const [discount, setDiscount] = useState([]);
    const [refund, setRefund] = useState(0);
    const [month, setmonth] = useState(0);

    const history = useHistory()



    const [options, setOptions] = useState([]);





    useEffect(()=>{
        getPricing()
        getTemplates()
        getPages()
        calculRefund()
        getDiscountConfig()
    },[])

    //Fetch Data from API

    const getPricing = async () => {
        await axios.get(SERVER_URL+'shop/pricing').then((response)=>{
            if (response.data.succeed){
                setPricing(response.data.pricing)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
                setLoading(false)

            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }
    const getPages = async () => {
        await axios.get(SERVER_URL+'shop/pages').then((response)=>{
            if (response.data.succeed){
                setPages(response.data.pageList)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }
    const getTemplates = async () => {
        await axios.get(SERVER_URL+'shop/templates').then((response)=>{
            if (response.data.succeed){
                setTemplates(response.data.templates)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }
    const getDiscountConfig = async () => {
        await axios.get(SERVER_URL+'shop/config/discount',attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                setDiscount(response.data.params.params.split(','))
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }



    const selectTemplate = (template) => {
        setOpenT(true)
        setData(template)
    }



// Refactoring of the default implementation
    const addToArray = (ev,lt) => {
        const verify  = options.filter(e => e.id == lt.id)

        if (verify.length){
            const opts = options.filter(object => {
                return object.page_key !== lt.page_key;
            });

            setOptions(opts)
        }else{
            setOptions(prevState => [...prevState,lt])
        }
    }



    const calculRefund = () => {
        if (auth?.plan?.pricing_id != 1){
            const unite = auth?.plan?.price / 30
            const ref = unite * auth?.planDays
            setRefund(parseInt(ref))
            setOldprice(totalToPay)
        }
    }



    //  Adding to array

    const selectedPlan= (pricing) => {
        setSelectedPlan(pricing)

        setTotalToPay(pricing.price)
        setSubTotal(pricing.price)
        if (auth?.plan?.pricing_id !== 1){
            setStep(4)
        }else{
            setStep(2)
        }


        setData([])
        setOpen(false)
    }

    const ChooseTemplate= (template) => {
        if (template == "empty"){
            setSelectedTheme({
                id : 0,
                picture : "empty",
                price : 0,
                name : "Empty"
            })
        }else{
            setSelectedTheme(template)
        }
        setOpenT(false)
        setData([])
        setStep(4)
    }

    useEffect(() => {
        if (pages.length > 0){
            const defaultChecked = pages.filter((el) => el.selected_default)
            setOptions(defaultChecked)
        }
    }, [pages]);

    const PeriodeSubscription = (month) =>{
        setmonth(month)

        setSubTotal(subTotal * month)

        switch (month) {
            case 1:
                setAnnual(false)
                setTotalDiscount(0)
                setTotalToPay(totalToPay - refund)
                break;
            case 6:
                setAnnual(true)
                const first = ((totalToPay * discount[0])/100) * parseInt(month)
                setTotalDiscount(first)
                const fPrice = (totalToPay * parseInt(month))
                setTotalToPay((fPrice - first)  - refund)
                break;
            case 12:
                setAnnual(true)
                const second = ((totalToPay * discount[1])/100) * parseInt(month)
                setTotalDiscount(second)
                const bigPrice = (totalToPay * parseInt(month))
                setTotalToPay((bigPrice - second) - refund)
                break;
            default :
                setAnnual(false)
                setTotalDiscount(0)
                setTotalToPay(totalToPay  - refund)
        }
        setSubstep(2)

    }


    // Payment Pass and finalisation
    const succeedPassed = async (detail,method) => {

        setLoading(true)

        const data = {
            shop_key : auth?.shop.shop_key,
            shop_id : auth?.shop.id,
            month : month,
            pricing : selectedplan,
            old_plan : auth?.plan.name,
            payment_data : {
                payment_id : detail.id,
                payment_method : method,
                type : "UPGRADE_PLAN",
                totalAmount : totalToPay,
            },
            from_trial : {
                theme : selectedtheme,
                pages : options
            },
            oldsubscriptions : {
                trial : auth?.plan?.pricing_id === 1,
                current_period_start : auth?.plan?.current_period_start,
                current_period_end : auth?.plan?.current_period_end
            }

        }

        await axios.post(SERVER_URL+"upturn/changed",data,attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                toast.success(t(response.data.message))

                setLoading(false)
                setStep(5)


                setTimeout(()=>{
                    loadMe()
                    window.location.reload(true)
                },3000)

            }else{
                toast.error(t(response.data.message))
            }
        })

    }




    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Upturn')}</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Upturn')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            {
                auth?.shop.statut === 2 ?

                    <div className="row">
                        <div className="offset-lg-2 col-xl-8">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{t('PlanActuel')} : {auth?.plan?.name.toUpperCase()}</h4>
                                </div>

                                <div className="card-body form-steps">
                                    <div className="vertical-navs-step">

                                        {
                                           pricing.length > slice.current ?

                                               <div className="row gy-5">
                                                   <div className="col-lg-12">
                                                           <div className="tab-content">
                                                               <div className={`tab-pane fade ${step === 1 ? " show active" : null}`}>
                                                                   <div>
                                                                       <div className="row">

                                                                           {
                                                                               loading
                                                                                   ? <RegLoading/>
                                                                                   : <>
                                                                                       {
                                                                                           pricing.length > 0 ?
                                                                                               <>
                                                                                                   {
                                                                                                       pricing.slice(slice.current).map((price,key)=>

                                                                                                           <div key={key} className="col-lg-6 col-md-6 col-sm-8 mt-20">
                                                                                                               <div className={`pricing-table ${key > 2 ? "m-t-30" : null}`}>
                                                                                                                   <h3>{price.name}</h3>
                                                                                                                   <br/>

                                                                                                                   <div className="plan-cost">
                                                                                                                       <span className="currency">XOF</span>
                                                                                                                       <span className="price">{numberWithCommas(price.price)}</span>
                                                                                                                       <span className="plan-type">/ {t('Month')}</span>
                                                                                                                   </div>

                                                                                                                   <button  className="template-btn primary-bg-5" onClick={event => {setOpen(true);setData(price)}}>{t('GetStart')} <i className="fas fa-arrow-right"></i></button>
                                                                                                               </div>
                                                                                                           </div>
                                                                                                       )
                                                                                                   }
                                                                                               </>
                                                                                               :
                                                                                               <>
                                                                                                   liste vide
                                                                                               </>
                                                                                       }
                                                                                   </>
                                                                           }
                                                                       </div>
                                                                   </div>
                                                               </div>

                                                               <div className={`tab-pane fade ${step === 2 ? " show active" : null}`}>
                                                                   <div>
                                                                       <div className="row g-3">
                                                                           {
                                                                               pages?.length > 0 ?
                                                                                   <>
                                                                                       {
                                                                                           pages.map((lt, key) =>
                                                                                               <div className="col-lg-4" key={key}>
                                                                                                   <CustomRadioInput  onClick={event => addToArray(event,lt)} item={lt} t={t}  options={options}  />
                                                                                               </div>
                                                                                           )
                                                                                       }
                                                                                   </>
                                                                                   :
                                                                                   <div className="text-center">
                                                                                       liste vide
                                                                                   </div>
                                                                           }
                                                                       </div>

                                                                       <div className="d-flex align-items-start gap-3 mt-4">
                                                                           <button type="button" className="btn btn-light btn-label previestab bg-light" onClick={event => setStep(auth?.plan.pricing_id > 1 && step > 1 ? 1 : step - 1)}>
                                                                               <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                                               {t('Previous')}
                                                                           </button>
                                                                           <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" onClick={event => setStep(3)}>
                                                                               <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                                               {t('Next')}
                                                                           </button>
                                                                       </div>
                                                                   </div>
                                                               </div>

                                                               <div className={`tab-pane fade ${step === 3 ? " show active" : null}`}>

                                                                   <div>
                                                                       <div className="row g-3">
                                                                           <div  className="col-md-4">
                                                                               <div className="empty_template" onClick={event => ChooseTemplate('empty')}>
                                                                                   <div className="content">
                                                                                       <img src={buildSelf} alt=""/>
                                                                                       <p>{t('FromZero')}</p>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                           {
                                                                               templates.length > 0
                                                                                   ?
                                                                                   <>
                                                                                       {
                                                                                           templates.map((template,key)=>

                                                                                               <div  key={key} className="col-md-4" onClick={event => selectTemplate(template)} >
                                                                                                   <div className="custom_template" title={t('Choose')}>
                                                                                                       <div className="card-wrapper">
                                                                                                           <div className="card-item">
                                                                                                               <figure className="card-image">
                                                                                                                   <img src={SERVER_IMG_URL+'templates/'+template.picture1} alt="..."/>
                                                                                                               </figure>
                                                                                                               <a className="card-content">
                                                                                                                   <p className="card-title text-center">{template.name}</p>
                                                                                                               </a>
                                                                                                           </div>
                                                                                                       </div>
                                                                                                   </div>
                                                                                               </div>

                                                                                           )
                                                                                       }
                                                                                   </>
                                                                                   :
                                                                                   <div className="text-center">
                                                                                       liste vide
                                                                                   </div>
                                                                           }
                                                                       </div>

                                                                   </div>

                                                                   <div className="d-flex align-items-start gap-3 mt-4">
                                                                       <button type="button" className="btn btn-light btn-label previestab bg-light" onClick={event => setStep(2)}>
                                                                           <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                                           {t('Previous')}
                                                                       </button>
                                                                   </div>
                                                               </div>

                                                               <div className={`tab-pane fade ${step === 4 ? " show active" : null}`}>
                                                                   <div>
                                                                       <h5>{t('Amount')} : {numberWithCommas(totalToPay)} Frcs</h5>
                                                                   </div>

                                                                   <div className="row">
                                                                       {
                                                                           substep == 1 ?
                                                                               <div className="col-lg-12">

                                                                                   {auth?.planDays < 30 ?

                                                                                       <label className="option__upturn" htmlFor="opt_1">
                                                                                           <p className="option--description"> 1 {t('Month')}</p>
                                                                                           <p className="option--cost"></p>
                                                                                           <input type="radio"  defaultChecked={month==1}  id="opt_1" name="upturn" value={month} onChange={event => PeriodeSubscription(1)}  />
                                                                                       </label>

                                                                                       : ""
                                                                                   }

                                                                                   {
                                                                                      auth?.planDays  <  180 ?
                                                                                       <label className="option__upturn" htmlFor="opt_2">
                                                                                           <p className="option--description"> 6 {t('Month')}</p>
                                                                                           <p className="option--cost"> <small>{t('ReductionOf')} <b>{discount[0]}% </b></small></p>
                                                                                           <input type="radio" id="opt_2" defaultChecked={month==6}  name="upturn" value={month} onChange={event => PeriodeSubscription(6)}  />
                                                                                       </label>
                                                                                       : ""
                                                                                   }

                                                                                   {
                                                                                      auth?.planDays < 360 ?

                                                                                       <label className="option__upturn" htmlFor="opt_3">
                                                                                           <p className="option--description"> 1 {t('Annuel')}</p>
                                                                                           <b className="badge badge-success">{t('BestOffer')}</b>
                                                                                           <p className="option--cost"> <small>{t('ReductionOf')} <b>{discount[1]}% </b></small></p>
                                                                                           <input type="radio" id="opt_3" defaultChecked={month==12}  name="upturn" value={month} onChange={event => PeriodeSubscription(12)}  />
                                                                                       </label>
                                                                                       : ""
                                                                                   }
                                                                               </div>
                                                                               : ""
                                                                       }
                                                                       {
                                                                           substep == 2 ?
                                                                             <div className="row">
                                                                                 <div className="col-lg-6">
                                                                                     <FedaPay amount={(totalToPay)} text="Payer avec Mobile Money"  succeedPayment={succeedPassed}  user={auth?.me}/>
                                                                                     <hr/>
                                                                                     <PayPal succeedPayment={succeedPassed} total={changeCurrency((totalToPay)??0,'USD')} />
                                                                                 </div>
                                                                                 <div className="col-lg-6">
                                                                                     <ul className="list-group mb-3">
                                                                                         <li className="list-group-item d-flex justify-content-between lh-sm">
                                                                                             <div>
                                                                                                 <h6 className="my-0">Plan</h6>
                                                                                             </div>
                                                                                             <span className="text-muted">{selectedplan?.name}</span>
                                                                                         </li>

                                                                                         <li className="list-group-item d-flex justify-content-between lh-sm">
                                                                                             <div>
                                                                                                 <h6 className="my-0">{t('TypeSub')}</h6>

                                                                                             </div>
                                                                                             <span className="text-muted">
                                                                                                  {month === 0 ? "Mensuel" : null}
                                                                                                 {month === 6 ? "Semestre" : null}
                                                                                                 {month === 12 ? "Annuel" : null}
                                                                                             </span>

                                                                                         </li>
                                                                                         <li className="list-group-item d-flex justify-content-between lh-sm">
                                                                                             <div>
                                                                                                 <h6 className="my-0">{t('Subtotal')}</h6>
                                                                                             </div>
                                                                                             <span className="text-muted">{numberWithCommas(subTotal)} Frcs</span>
                                                                                         </li>
                                                                                         <li className="list-group-item  lh-sm bg-soft-success">
                                                                                             <small className="text-success">{t('ReductionOf')} <b>{discount[0]}% </b> {t('To')} <b>{discount[1]}%</b> {t('IfSemestrerOrAnnual')}</small>
                                                                                         </li>


                                                                                         { auth?.plan?.pricing_id > 1 ?
                                                                                             <li className="list-group-item d-flex justify-content-between bg-light">
                                                                                                 <div className="text-success">
                                                                                                     <h6 className="my-0">{t('Refund')}</h6>
                                                                                                 </div>
                                                                                                 <span className="text-success">− {numberWithCommas(refund)} Frcs</span>
                                                                                             </li>
                                                                                             : null}

                                                                                         { annual ?
                                                                                             <li className="list-group-item d-flex justify-content-between bg-light">
                                                                                                 <div className="text-success">
                                                                                                     <h6 className="my-0">{t('Discount')}</h6>
                                                                                                 </div>
                                                                                                 <span className="text-success"> - {numberWithCommas(totalDiscount)} Frcs</span>
                                                                                             </li>
                                                                                             : null}

                                                                                         <li className="list-group-item d-flex justify-content-between">
                                                                                             <span>Total (XOF)</span>
                                                                                             <strong>{numberWithCommas(totalToPay)} Frcs</strong>
                                                                                         </li>
                                                                                     </ul>
                                                                                 </div>
                                                                             </div>
                                                                               : ""
                                                                       }



                                                                   </div>

                                                                   {
                                                                       substep == 0 ?
                                                                           <div className="d-flex align-items-start gap-3 mt-4">
                                                                               <button type="button" className="btn btn-light btn-label previestab bg-light" onClick={event => {substep > 1 ? setSubstep(1) : setStep(3)}}>
                                                                                   <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                                                   {t('Previous')}
                                                                               </button>
                                                                           </div>
                                                                           :
                                                                           ""

                                                                   }
                                                               </div>

                                                               <div className={`tab-pane fade ${step === 5 ? " show active" : null}`}>
                                                                   <div className="text-center pt-4 pb-2">

                                                                       <div className="mb-4">
                                                                           <lord-icon src="https://cdn.lordicon.com/lupuorrc.json"
                                                                                      trigger="loop"
                                                                                      colors="primary:#0ab39c,secondary:#405189"
                                                                                      style={{width:"120px",height:"120px"}}></lord-icon>
                                                                       </div>
                                                                       <h5>{t('SuccessSubscription')}</h5>
                                                                       <p className="text-muted">{t('YouWillReceiveEmail')}</p>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                   </div>



                                               </div>

                                               :

                                               <div className="row">
                                                   <Alert color="info" >
                                                       {t('YouCantUpgradeYouHaveTheLast')}
                                                   </Alert>
                                               </div>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    :

                    <div className="alert alert-success" role="alert">
                        <h1 className="alert-heading text-success">{t('WelcomeDash')} {APPNAME}</h1>
                        <hr/>
                        <p>{t('DefaultStartText')}</p>
                        <br/>
                        <p>{t('RefferToDocs')} <br/>
                            <Link to={LINK_DOCS}><b style={{textDecoration : "underline"}}>{t('Docs')}</b></Link></p>
                        <br/>
                    </div>
            }

            <PricingModal
                title={t("Pricing")}
                open={open}
                setOpen={setOpen}
                data={data}
                ChoosePricing={selectedPlan}
            />

            <TemplateModal
                title={t("Template")}
                open={openT}
                setOpen={setOpenT}
                data={data}
                ChoosedTemplate={ChooseTemplate}
            />
        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Changeplan));
