import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {compose} from "redux";
import {connect} from "react-redux";
import {MiddleLoading} from "../../../../components";
import TrialEmpty from "../../../Sub/Trial/template/blocs/TrialEmpty";
import moment from "moment";

const AdminNotification = ({t,auth}) => {

     document.title = APPNAME+" :: Notifications"

    const history = useHistory()


    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);



    useEffect(() => {
        getAllNotifications()
    }, []);

    const getAllNotifications = async () => {
        await axios.get(SERVER_URL+"notifications/"+auth?.me.id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setNotifications(response.data.notifications)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const gotoMessage = async (link,id) => {
        await axios.get(SERVER_URL+"notification/read/"+id,attachTokenToHeaders()).then((response)=>{
            getAllNotifications()
            history.push(link)

        })
    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Notifications')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Notifications')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row" style={{marginTop : "50px"}}>
                <h3 className="offset-lg-2 col-lg-9">Notifications</h3>
                {
                    loading ?
                        <MiddleLoading />
                        :
                        <>
                        {
                            notifications?.length > 0 ?
                                  <>
                                      {
                                          notifications.map((notification,key) =>
                                              <div className="offset-lg-2 col-lg-8 cursor-pointer" key={key} style={{marginBottom : "-20px"}}>
                                                  <div className="card" onClick={event => gotoMessage(notification.link,notification.id)}>
                                                      <div className="card-body">
                                                          <p className="text-muted">
                                                              {notification.message}
                                                          </p>
                                                          <div className="live-preview">
                                                              <div className="d-flex flex-wrap gap-2">
                                                                  {moment(notification?.created_at).fromNow()}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                          )
                                      }
                                  </>
                                :

                                <div className="">
                                    <div className="u-s-p-y-60">
                                        <div className="section__content">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 u-s-m-b-30">
                                                        <div className="empty">
                                                            <div className="empty__wrap">
                                                                <span className="empty__big-text text-primary">{t('Empty')}</span>
                                                                <span className="empty__text-1">{t('EmptyNotificationList')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                        }
                        </>
                }
            </div>

        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default compose(withRouter, connect(mapStateToProps)) (withTranslation()(AdminNotification))
