import React, {useEffect, useState} from 'react';
import axios from "axios";
import {attachTokenToHeaders, format_date, SERVER_URL} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import _CreateTicket from "./_CreateTicket";
import {Link} from "react-router-dom";

const Support = ({t,user_id,shop_id}) => {
    const [currentSupport,setCurrentSupport] = useState([]);
    const [supports,setSupports] = useState([])

    useEffect(() => {
        getAllSupport(user_id)
    },[user_id])

    useEffect(() => {
        setCurrentSupport(supports?.slice(indexOfFirstPost, indexOfLastPost))
    },[supports])



    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [open,setOpen] = useState(false)


    const [loading, setLoading] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    const getAllSupport = async (user_id) => {
        await axios.get(SERVER_URL+"supports/seller/"+user_id+"/"+shop_id,attachTokenToHeaders(false,true)).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setSupports(response.data.tickets)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        }).catch(() => setLoading(false))
    }



    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(supports?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const addSupport = () => {
        setOpen(true)
    }


    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentSupport(supports?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = supports.filter(support => {
                return support.name.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentSupport(filter)
        }
    }



    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(supports?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentSupport.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };



    return (
        <div className="profile__main">
            <div className="profile__main-top pb-30">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                            <input type="text" style={{height : "50px"}} placeholder={t('Search')}  onChange={event => SearchFilter(event.target.value)}/>
                    </div>

                    <div className="offset-md-2 col-md-6">
                        <div className="profile__main-logout text-sm-end">
                            <span className="tp-logout-btn cursor-pointer"
                                  onClick={event => setOpen(true)}>{t('Add')}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div className="profile__ticket table-responsive mt-30">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col" style={{width: "50px"}}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       name="selectAll"
                                       id="selectAll"
                                       onChange={handleSelectAll}
                                       checked={isCheckAll}
                                />
                            </div>
                        </th>
                        <th>{t('Subject')}</th>
                        <th>{t('Message')}</th>
                        <th>{t('CreatedAt')}</th>
                        <th>Status</th>
                        <th scope="col">{t('Detail')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading ?
                            <tr>
                                <td colSpan="6">
                                    <p className="text-center">
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"/> {t('Loading')}...
                                    </p>
                                </td>
                            </tr>

                            :

                            <>
                                {
                                    currentSupport?.length > 0
                                        ?
                                        <>
                                            {
                                                currentSupport?.map((support,key)=>
                                                    <tr key={key}>
                                                        <th scope="row"><div className="form-check">
                                                            <input className="form-check-input"
                                                                   type="checkbox"
                                                                   name={support.category}
                                                                   id={support.id}
                                                                   onChange={handleClick}
                                                                   checked={isCheck.includes(support.id)}
                                                            />
                                                        </div>
                                                        </th>
                                                        <td>{support.title}</td>
                                                        <td>{support.description.substring(0,75)}...</td>
                                                        <td className="create_date">{format_date(support?.created_at)}</td>
                                                        <td className="status">
                                                            {support?.statut == 1 ?  <span className="badge badge-soft-info text-uppercase">{t('New')}</span> : null}
                                                            {support?.statut == 2 ?  <span className="badge badge-soft-success text-uppercase">{t('Open')}</span> : null}
                                                            {support?.statut == 3 ?  <span className="badge badge-soft-warning text-uppercase">{t('Inprogress')}</span> : null}
                                                            {support?.statut == 4 ?  <span className="badge badge-soft-dangertext-uppercase">{t('Closed')}</span> : null}

                                                        </td>

                                                        <td>
                                                            <Link to={`/user/support/${support.hash_key}`}>{t('Open')}</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td colSpan="6">
                                                <p className="text-center">{t('EmptyList')}</p>
                                            </td>
                                        </tr>
                                }
                            </>
                    }

                    </tbody>
                </table>
            </div>

            <_CreateTicket open={open} setOpen={setOpen} created={getAllSupport} shop_id={shop_id} user_id={user_id} />
        </div>
    );
};

export default Support;
