import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {APPNAME, attachTokenToHeaders, Capitalize, SERVER_URL} from "../../../../helpers/utils";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {toast} from "react-toastify";
import { MiddleLoading } from '../../../../components'

const Params = ({t}) => {

    const [params, setParams] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getParams()
    }, []);


    const getParams = async () => {
        await axios.get(SERVER_URL+"admin/params",attachTokenToHeaders()).then((response)=>{
             setLoading(false)
            if (response.data.succeed){
                setParams(response.data.params)
                 setLoading(false)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
            .catch(err => {
                 setLoading(false)
            })
    }


    const validationSchemaDiscount = Yup.object().shape({
        month: Yup.string().required(t('InputRequired')),
        year: Yup.string().required(t('InputRequired')),
    });

    const discountInitialValue = {
        month : params?.length ? params[0].params.split(',')[0] : 0,
        year : params?.length ? params[0].params.split(',')[1] : 0,
    }


    const handleSubmitDiscount = async (values) => {
        values.params = [values.month,values.year].join(',')
        values.id = params[0].id
        try {
            const response = await axios.post(`${SERVER_URL}admin/params/discount`,values,attachTokenToHeaders())
            if (response.data.succeed) {
                 toast.success(t(response.data.message))
            }
        } catch (error) {
            console.log(error)
        }

    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Settings')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item">{t('Config')}</li>
                                <li className="breadcrumb-item active">{t('Settings')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3">
                    <div className="card">
                        <h5 className="card-header border border-dashed border-end-0 border-start-0">{t('DiscountParams')}</h5>

                        <div className="card-body border" style={{height : "250px"}}>
                            <div className="row">

                                {
                                    loading ?
                                        <div>
                                            <MiddleLoading />
                                        </div>
                                        :

                                        <Formik initialValues={discountInitialValue} onSubmit={handleSubmitDiscount} validationSchema={validationSchemaDiscount}>
                                            {({ errors, touched,values }) =>
                                                <Form>
                                                    <div className="row">
                                                        <div  className="col-lg-12">
                                                            <label htmlFor="month" className="form-label">{Capitalize(t('Month'))} <b className="text-danger">*</b></label>
                                                            <Field  className="form-control form-control-lg" id="month" name="month"/>
                                                            {errors.month && touched.month ? (
                                                                <div className='text-danger'>{errors.month}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div  className="col-lg-12">
                                                            <br/>
                                                            <label htmlFor="year" className="form-label">{Capitalize(t('Year'))} <b className="text-danger">*</b></label>
                                                            <Field  className="form-control form-control-lg" id="year" name="year"/>
                                                            {errors.year && touched.year ? (
                                                                <div className='text-danger'>{errors.year}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-8 pt-3">
                                                            <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                                                        </div>
                                                        <div className="col-lg-4 pt-3">
                                                            <button type="submit" className="btn btn-success btn-block" disabled={loading}>
                                                                {
                                                                    loading ?
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                                        :
                                                                        <span> { t('Edit')}</span>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            }

                                        </Formik>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </AdminLayout>
    );
}

export default withTranslation()(Params);
