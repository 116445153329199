import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,auth}) => {

    const [loading,setLoading] = useState(false)
    const [files, setFiles] = useState([])


    const validationSchema = Yup.object().shape({
        category: Yup.string()
            .required(t('InputRequired'))
    });

    const initialValues = {
        category: isEdit ? updateData.category : '',
        description: isEdit ? updateData.description : '',
        image : []
    }


    const handleSubmit = async (values) => {
        setLoading(true)

        const shop_id = auth.shop.id

        const formdata  = new FormData()
        formdata.append('category',values.category)
        formdata.append('description',values.description)
        formdata.append('shop_id',auth.shop.id)
        formdata.append('type',"categories")
        formdata.append('shop_key',auth.shop.shop_key)
        formdata.append('image',files[0])

        

        if (isEdit){
            await axios.post(SERVER_URL+"categorie/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"categorie/add",formdata,attachTokenToHeaders(true)).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    setFiles([])
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    return (
      <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Categor')}</> : <>{t('Edit')} {t('Categor')}</>} size="md">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ errors, touched }) =>
                  <Form>
                 <div className="row">
                     <div>
                         <label htmlFor="category" className="form-label">{t('Categorie')} <b className="text-danger">*</b></label>
                         <Field  className="form-control form-control-lg" id="category" name="category"/>
                         {errors.category && touched.category ? (
                             <div className='text-danger'>{errors.category}</div>
                         ) : null}
                     </div>

                     {
                         !isEdit &&

                         <div>
                             <br/>
                             <label htmlFor="file" className="form-label">{t('Picture')}</label>
                             <FilePond
                                 files={files}
                                 allowMultiple={true}
                                 maxFiles={1}
                                 name="file"
                                 id="file"
                                 onupdatefiles={(fileItems) => {
                                     setFiles(fileItems.map((fileItem) => fileItem.file))
                                 }}
                                 labelIdle={t('DragNdrop')}
                             />

                         </div>
                     }

                     <div>
                         <br/>
                         <label htmlFor="description" className="form-label">{t('Short')} Description</label>
                         <Field as="textarea" className="form-control" rows={2} cols={30} id="description" name="description"/>
                     </div>

                 </div>
                     <div className="row">
                         <div className="col-lg-8 pt-3">
                             <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                         </div>
                         <div className="col-lg-4 pt-3">
                             <button className="btn btn-success btn-block" disabled={loading}>
                                 {
                                     loading ?
                                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                         :
                                         <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                 }
                             </button>
                         </div>
                     </div>
                  </Form>
              }

          </Formik>
      </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)