import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../../utils/utils";
import {isColorLight} from "../../../../helpers/utils";

const CPayPal = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const cpaypal = useRef()

    const getBlockSize = () => {
        setDimension({top :cpaypal?.current?.offsetTop, left : cpaypal?.current?.offsetLeft, width : cpaypal?.current?.offsetWidth, height : cpaypal?.current?.offsetHeight,nodeName : cpaypal?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);


    return (
        <section className=" pt-30 pb-30"
                 style={customStyle}
                 ref={cpaypal}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
        >

            <div className="container">
                <div className="row p-10">
                    <div className="col-lg-2">
                        <a href={"https://www.paypal.com/donate?hosted_button_id="+flatData(block.contentEditable,'paypalId')}
                           target="_blank" className="btn btn-rounded" style={{backgroundColor : flatData(block.contentEditable,'color_btn'), color : isColorLight(flatData(block.contentEditable,'color_btn')) ? "#101010" : "#ffffff"}}>{flatData(block.contentEditable,'text_btn')}</a>
                    </div>
                    <div className="col-lg-10">
                        {flatData(block.contentEditable,'content')}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default CPayPal;