import axios from 'axios';

import {
   PAGE_SHOP_SUCCESS,PAGE_SHOP_FAIL,PAGE_SHOP_LOADING
} from './type';
import {SERVER_URL} from "../../helpers/utils";



export const loadShopPages = () => async (dispatch) => {
    dispatch({
        type: PAGE_SHOP_LOADING,
    });
    try {
        const response = await axios.get(SERVER_URL+'shop/pages');
            dispatch({
                type: PAGE_SHOP_SUCCESS,
                payload: { pageList : response.data.pageList},
            });



    } catch (err) {
        dispatch({
            type: PAGE_SHOP_FAIL,
            payload: { error: err?.response?.data.message || err.message },
        });
    }
};


