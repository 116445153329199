import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import { checkCurrentUrlSubdomain, SERVER_URL } from '../../../../helpers/utils'
import {toast} from "react-toastify";
import CustomRender from "../../../../themes/config/config.block";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Header from "./Header/Header";
import socket from "../../../../helpers/services/BroadCasts";
import notificationSound from "../../../../assets/notifications/message.wav";


const SellerLayout = ({children,auth,t}) => {

    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState([])


    const [shop, setShop] = useState({});

    useEffect(()=>{
        checkSubdomain()

    },[])



    const checkSubdomain = async () =>{
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            if (response.data.succeed){
                setShop(response.data)
                getPageData(response.data)
            }
        })
    }



    const getPageData = async (data) => {
        setLoading(true)
        await axios.get(SERVER_URL+"store/template/page/"+data?.shop.shop_key+"/Home").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                const data = response?.data?.page?.content
                if (data)  setPageData(JSON.parse(data))
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })

    }

    useEffect(() => {
       if (auth.isAuthenticated){
           socket.on("support_message_customer_"+auth?.user.id, data => {
               console.log(data)
               if (!window.location.pathname.includes("support")){
                   if (data.toUser_id == auth?.user.id) {
                       const audio = new Audio(notificationSound)
                       audio.play().catch(function (error) {
                           console.log(error)
                       });
                       toast.info(t('NewMessage'))
                   }
               }

           });

       }
        return () => socket.disconnect();
    }, []);



    return (
        <>
            {
                pageData?.length > 0 ?
                    <>{
                        pageData[0]?.item.type === "header" && pageData[0].item.component !== "header4" ?
                            <CustomRender block={pageData[0].item} isEditor={false} shop_data={shop?.shop}  />
                            :

                            <Header shop_data={shop?.shop} />
                    }

                        {children}

                        {
                            pageData[pageData.length - 1]?.item.component === "footer"  ?
                                <CustomRender block={pageData[pageData.length - 1].item} isEditor={false} shop_data={shop?.shop}  />
                                : ""
                        }</> : ""
            }




        </>

    )
}

const mapStateToProps = (state) => ({
    auth : state.subLogin,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(SellerLayout));
