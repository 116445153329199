import React, {useEffect, useState} from 'react';
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {LINK_STORE_SETTINGS} from "../../../../routes/type";
import {MiddleLoading} from "../../../../components";
import {useHistory} from "react-router-dom";

const SlackIntegration = () => {

    const [loading, setLoading] = useState(true)

    const history = useHistory()

    useEffect(() => {
        const ulpar = window.location.search
        const urlParams = new URLSearchParams(ulpar);

        getToken(urlParams.get("state"),urlParams.get("code"))

    }, []);


    const getToken = async (state,code) => {
        const data = {state : state, code : code}
        try {
            await axios.post(`${SERVER_URL}store/slack/token`,data,attachTokenToHeaders())
                .then((response) => {
                    if (response.data.succeed) {
                        history.push(LINK_STORE_SETTINGS+"?step=notification&slackstatus=success&message="+response.data.message)
                    }else {
                        history.push(LINK_STORE_SETTINGS+"?step=notification&slackstatus=failed&message="+response.data.message)
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            {

                loading ? <MiddleLoading /> : ""

            }

        </div>
    );
};

export default SlackIntegration;
