import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../components/SellerLayout";
import axios from "axios";
import {
    attachTokenToHeaders,
    Capitalize, checkCurrentUrlSubdomain,
    format_date_complet,
    numberWithCommas,
    SERVER_URL,
} from '../../../../helpers/utils'
import {withTranslation} from "react-i18next";
import { Fade } from 'react-awesome-reveal'

const Tracked = ({t}) => {

    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+t('Tracked')


    const [tracking, setTracking] = useState(null)
    const [shop, setShop] = useState({});
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        loadTrack(window.location.pathname.split('/').pop())
    }, []);

    useEffect(()=>{
        checkSubdomain()

    },[])



    const checkSubdomain = async () =>{
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShop(response.data)
            }
        })
    }



    const loadTrack = (order_id) => {

        axios.get(SERVER_URL+'seller/track/'+order_id,attachTokenToHeaders())
            .then((response) => {
              setTracking(response.data.tracking)
        })
    }

    return (
        <Fade>
            <SellerLayout>
                <section className="breadcrumb__area include-bg pt-95 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">{t('TrackYourOrder')}</h3>
                                    <div className="breadcrumb__list">
                                        <span><a href="#">{t('Tracking')}</a></span>
                                        <span>{t('TrackYourOrder')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tp-order-area pb-160">
                    <div className="container">
                        <div className="tp-order-inner">
                            <div className="row gx-0">
                                <div className="col-lg-6" style={{background : shop?.shop?.color}}>
                                    <div className="tp-order-details" >
                                        <div className="tp-order-details-top text-center mb-70">
                                            <div className="tp-order-details-icon">
                                 <span style={{color : "#fff"}}>
                                     {
                                         tracking?.delivery_status == 1  ?

                                             <svg
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="80"
                                                 height="80"
                                                 version="1"
                                                 viewBox="0 0 100 100"
                                             >
                                                 <g fill="#fff">
                                                     <path
                                                         d="M120 500V40h760v920H120V500zm720 0V80H160v840h680V500z"
                                                         transform="matrix(.1 0 0 -.1 0 100)"
                                                     ></path>
                                                     <path
                                                         d="M260 760c0-19 7-20 240-20s240 1 240 20-7 20-240 20-240-1-240-20zM260 480c0-17 7-20 40-20s40 3 40 20-7 20-40 20-40-3-40-20zM400 480c0-19 7-20 170-20s170 1 170 20-7 20-170 20-170-1-170-20zM260 360c0-17 7-20 40-20s40 3 40 20-7 20-40 20-40-3-40-20zM400 360c0-19 7-20 170-20s170 1 170 20-7 20-170 20-170-1-170-20zM260 240c0-17 7-20 40-20s40 3 40 20-7 20-40 20-40-3-40-20zM400 240c0-19 7-20 170-20s170 1 170 20-7 20-170 20-170-1-170-20z"
                                                         transform="matrix(.1 0 0 -.1 0 100)"
                                                     ></path>
                                                 </g>
                                             </svg>
                                             : ""
                                     }

                                     {
                                         tracking?.delivery_status == 2  ?

                                             <svg
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="80"
                                                 height="80"
                                                 version="1"
                                                 viewBox="0 0 100 100"
                                             >
                                                 <path
                                                     fill="#fff"
                                                     d="M52 908c-8-8-12-45-12-100 0-81 2-88 20-88 20 0 20-7 20-308 0-226 3-311 12-320 17-17 799-17 816 0 9 9 12 94 12 320 0 301 0 308 20 308 18 0 20 7 20 88 0 55-4 92-12 100-17 17-879 17-896 0zm348-88v-60H80v120h320v-60zm160-90V580H440v300h120V730zm360 90v-60H600v120h320v-60zM400 630v-90h200v180h280V120H120v600h280v-90z"
                                                     transform="matrix(.1 0 0 -.1 0 100)"
                                                 ></path>
                                             </svg>
                                             : ""
                                     }
                                     {
                                         tracking?.delivery_status == 3  ?

                                             <svg
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="80"
                                                 height="80"
                                                 version="1"
                                                 viewBox="0 0 100 100"
                                             >
                                                 <g fill="#fff">
                                                     <path
                                                         d="M130 800c-20-20-27-50-12-50 4 0 12 10 17 23 10 22 13 22 214 25 142 2 208-1 217-9 11-9 14-50 14-185V430H130v70h65c37 0 65 4 65 10s-38 10-95 10-95-4-95-10c0-5 9-10 20-10 18 0 20-8 22-112l3-113 40-3c32-3 44-10 60-35 39-61 111-61 150 0l20 32h250l20-32c39-61 111-61 150 0 13 20 28 32 43 33 12 0 31 9 42 20 18 18 20 33 20 131v112l-45 83c-25 46-54 88-65 94-10 5-59 10-109 10h-91v30c0 17-9 39-20 50-19 19-33 20-225 20s-206-1-225-20zm660-110c25-14 100-155 100-191v-29H680v179l35 4 35 4-37-1-38-1V465l108-3 107-3v-34c0-29-4-34-27-37-24-3-28-8-28-33s4-30 28-33c23-3 26-6 16-18-20-24-52-16-74 19-11 17-32 36-48 41-38 14-78-2-102-41-13-20-28-32-42-33h-23v410h91c50 0 99-4 109-10zM580 355v-65h-97l-98 1-20 32c-39 61-111 61-150 0-16-25-27-32-52-33-41 0-46 27-5 32 23 3 27 8 27 33s-4 30-27 33c-16 2-28 9-28 18 0 12 36 14 225 14h225v-65zm-400 0c0-20-5-25-25-25s-25 5-25 25 5 25 25 25 25-5 25-25zm710 0c0-20-5-25-25-25s-25 5-25 25 5 25 25 25 25-5 25-25zm-545-20c16-15 25-36 25-55s-9-40-25-55c-49-50-135-15-135 55 0 19 9 40 25 55 15 16 36 25 55 25s40-9 55-25zm440 0c50-49 15-135-55-135-41 0-80 39-80 80s39 80 80 80c19 0 40-9 55-25z"
                                                         transform="matrix(.1 0 0 -.1 0 100)"
                                                     ></path>
                                                     <path
                                                         d="M260 305c-15-18-10-45 13-59 34-22 73 27 47 59-16 19-44 19-60 0zm55-25c0-18-6-26-23-28-13-2-25 3-28 12-10 26 4 48 28 44 17-2 23-10 23-28zM700 305c-26-32 13-81 48-59 22 14 27 41 12 59-16 19-44 19-60 0zm55-25c0-18-6-26-23-28-24-4-38 18-28 44 3 9 15 14 28 12 17-2 23-10 23-28zM70 720c0-5 9-10 20-10s20-7 20-15 5-15 10-15c6 0 10 7 10 15 0 13 21 15 125 15 77 0 125 4 125 10s-58 10-155 10-155-4-155-10zM70 650c0-5 9-10 20-10s20-7 20-15 5-15 10-15c6 0 10 7 10 15 0 12 18 15 105 15 63 0 105 4 105 10s-52 10-135 10-135-4-135-10zM70 580c0-5 9-10 20-10s20-7 20-15 5-15 10-15c6 0 10 7 10 15 0 12 16 15 85 15 50 0 85 4 85 10s-45 10-115 10-115-4-115-10z"
                                                         transform="matrix(.1 0 0 -.1 0 100)"
                                                     ></path>
                                                 </g>
                                             </svg>
                                             : ""
                                     }

                                     {
                                         tracking?.delivery_status == 4  ?

                                             <svg
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="80"
                                                 height="80"
                                                 version="1"
                                                 viewBox="0 0 100 100"
                                             >
                                                 <g fill="#ffff">
                                                     <path
                                                         d="M120 980c-17-17-20-33-20-120 0-93 1-100 20-100 18 0 20 8 22 98l3 97h710l3-97c2-90 4-98 22-98 19 0 20 7 20 100 0 152 34 140-400 140-347 0-361-1-380-20z"
                                                         transform="matrix(.1 0 0 -.1 0 100)"
                                                     ></path>
                                                     <path
                                                         d="M380 810c-74-7-142-28-154-48-4-6-13-62-21-124-9-62-18-123-20-135-7-30-45-32-45-3 0 13-7 20-20 20-16 0-20-7-20-31 0-17-9-42-20-56-18-24-20-41-20-209C60 3 61 0 160 0c70 0 100 21 100 70v30h170c158 0 170-1 180-20 6-11 31-32 57-47 40-23 61-28 139-31 83-4 94-2 113 17 11 11 21 30 21 40 0 11 5 23 11 27 21 13 49 89 49 134s-28 121-49 134c-6 4-11 18-11 30 0 13-9 35-20 49s-20 39-20 56c0 24-4 31-20 31-13 0-20-7-20-20 0-25-37-27-44-2-2 9-10 60-16 112-14 121-25 155-53 167-63 29-244 45-367 33zm287-51c29-6 59-17 67-24 17-17 32-143 18-157-13-13-338-21-432-11l-75 8 2 60c3 81 9 102 35 116 49 26 264 31 385 8zM500 525c118 0 227 3 241 7 26 7 27 6 36-40l8-47-45-11c-25-6-53-15-62-19-13-5-18-2-18 11 0 9-7 26-17 36-14 16-33 18-141 18-156 0-162-4-162-98 0-95 9-102 132-102 54 0 98-2 98-4s-3-11-6-20c-5-14-36-16-235-16H100v78c0 95 11 113 71 121 43 6 44 7 52 53 9 46 10 47 36 40 14-4 123-7 241-7zm120-122c-1-22-9-48-19-60-18-21-28-23-120-23H380v120h240v-37zm250 27c11-8 7-9-15-4-37 8-45 14-19 14 10 0 26-5 34-10zm-10-52c132-67 132-249 0-316-56-28-104-28-160 0-132 67-132 249 0 316 24 12 60 22 80 22s56-10 80-22zM564 184c17-44 19-44-229-44H100v60h229c199 0 230-2 235-16zM218 73c-3-27-5-28-58-28s-55 1-58 28-3 27 58 27 61 0 58-27z"
                                                         transform="matrix(.1 0 0 -.1 0 100)"
                                                     ></path>
                                                     <path
                                                         d="M152 388c-16-16-16-80 0-96s120-16 136 0 16 80 0 96c-7 7-37 12-68 12s-61-5-68-12zm108-48c0-17-7-20-40-20s-40 3-40 20 7 20 40 20 40-3 40-20zM820 235l-54-55-37 36c-32 31-37 33-49 19-11-14-7-22 30-60 24-25 49-45 55-45s41 31 78 68c53 53 65 70 55 80-7 7-15 12-18 12s-30-25-60-55zM52 728c-16-16-16-160 0-176 7-7 28-12 48-12s41 5 48 12c16 16 16 160 0 176s-80 16-96 0zm68-88c0-53-2-60-20-60s-20 7-20 60 2 60 20 60 20-7 20-60zM852 728c-16-16-16-160 0-176 7-7 28-12 48-12 52 0 60 13 60 100 0 46-5 81-12 88-16 16-80 16-96 0zm68-88c0-53-2-60-20-60s-20 7-20 60 2 60 20 60 20-7 20-60z"
                                                         transform="matrix(.1 0 0 -.1 0 100)"
                                                     ></path>
                                                 </g>
                                             </svg>
                                             : ""
                                     }
                                 </span>
                                            </div>
                                            <div className="tp-order-details-content">
                                                {tracking?.delivery_status == 1 ? <h3 className="tp-order-details-title">{t("OrderPlaced")}</h3>: null}
                                                {tracking?.delivery_status == 2 ? <h3 className="tp-order-details-title">{t("Packed")}</h3>: null}
                                                {tracking?.delivery_status == 3 ? <h3 className="tp-order-details-title">{t("OnTheWay")}</h3>: null}
                                                {tracking?.delivery_status == 4 ? <h3 className="tp-order-details-title">{t("Delivered")}</h3> : null}
                                                {tracking?.delivery_status == 5  ? <h3 className="tp-order-details-title">{t("Return")}</h3>  : null}
                                                {tracking?.delivery_status == 6  ? <h3 className="tp-order-details-title">{t("Cancel")}</h3> : null}

                                                <p>{t('OrderNumber')} <b># {tracking?.order_id}</b>   </p>
                                            </div>
                                        </div>


                                        <div className="tp-order-details-item-wrapper">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="tp-order-details-item">
                                                        <h4>{t('OrderDate')}:</h4>
                                                        <p>{format_date_complet(tracking?.created_at)}</p>
                                                    </div>
                                                </div>


                                                <div className="col-sm-6">
                                                    <div className="tp-order-details-item">
                                                        <h4> {t('PaymentMethod')} :</h4>
                                                        <p>{t(Capitalize(tracking?.payment_method.toLowerCase().split("-").shift()))}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="tp-order-info-wrapper">
                                        <h4 className="tp-order-info-title">{t('OrderDetails')}</h4>

                                        <div className="tp-order-info-list">
                                            <ul>


                                                <li className="tp-order-info-list-header">
                                                    <h4>{t('Product')}</h4>
                                                    <h4>Total</h4>
                                                </li>


                                                {
                                                    tracking != null ?

                                                        <>
                                                            {
                                                                JSON.parse(tracking.cart_content).map((item,key) =>

                                                                    <li className="tp-order-info-list-desc" key={key}>
                                                                        <p>{item.name} <span> x {item.qty}</span></p>
                                                                        <span>{numberWithCommas(parseInt(item.price) * parseInt(item.qty))} Francs</span>
                                                                    </li>
                                                                )
                                                            }
                                                        </>

                                                        : ""
                                                }


                                                <br/> <br/>

                                                {tracking?.discount_percent ?
                                                    <li className="tp-order-info-list-desc">
                                                        <p>{t('Discount')}</p>
                                                        <span>{numberWithCommas(parseInt(tracking?.discount_percent))} Francs</span>
                                                    </li>

                                                    : ""
                                                }


                                                <li className="">
                                                    <span>Subtotal</span>
                                                    <span>{numberWithCommas(tracking?.total_amount)} Francs</span>
                                                </li>


                                                <li className="tp-order-info-list-shipping">
                                                    <span>{t('Shipping')}</span>

                                                    <label htmlFor="shipping_info">
                                                        <span>{numberWithCommas(parseInt(tracking?.shipping_charge)) } Francs</span></label>

                                                </li>


                                                <li className="">
                                                    <span>Total</span>
                                                    <span style={{color : shop?.shop?.color,fontWeight : "bold"}}>{numberWithCommas(parseInt(tracking?.total_amount) + parseInt(tracking?.shipping_charge)) } Francs</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </SellerLayout>
        </Fade>
    );
};

export default withTranslation()(Tracked);
