import axios from 'axios';

import {
    LOGIN_LOADING,
    LOGOUT_USER_SUCCESS,
    SUB_ME_FAIL,
    SUB_ME_LOADING, SUB_ME_SUCCESS, LOGIN_SUCCESS, LOGIN_FAIL

} from './type';


import {attachTokenToHeaders, SERVER_URL} from "../../../helpers/utils";


export const loadSubMe = () => async (dispatch) => {
    dispatch({ type: SUB_ME_LOADING });

    try {
        const options = attachTokenToHeaders(false,true);
        const response = await axios.get(SERVER_URL+'subdomain/user/me', options);
        dispatch({
            type: SUB_ME_SUCCESS,
            payload: {
                token :response.data.token,
                user: response.data.user,
            },
        });
    } catch (err) {
        dispatch({
            type: SUB_ME_FAIL,
            payload: { error: err },
        });
    }
};

export const loginSubUser = (formData) => async (dispatch) => {
    dispatch({ type: LOGIN_LOADING });
    try {
        await axios.post(SERVER_URL+'subdomain/login', formData).then((response)=>{
            if (response.data.succeed){
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { token: response.data.token, user: response.data.user},
                });

            }
            else{
                dispatch({
                    type: LOGIN_FAIL,
                    payload: { error : response.data.message },
                });
            }
        })



    } catch (err) {
        dispatch({
            type: LOGIN_FAIL,
            payload: { error: err.message },
        });
    }
};


export const logUserout = () => async (dispatch) => {
    dispatch({ type: LOGOUT_USER_SUCCESS });
};


