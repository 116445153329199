import React from 'react';
import waiting from "../../../assets/waiting.svg"
import {withTranslation} from "react-i18next";

const NotAvailable = ({shopName,t,domain}) => {
 document.title = domain?.charAt(0).toUpperCase() + domain?.slice(1) +" :: "+t('Unavailable')

    return (
        <div className="warning-content">
            <h1 style={{fontSize : "50px",color : "#3377ff",fontWeight : "bold"}}>{shopName} </h1>
            <h3>{t('NotAvailable')}</h3>
            <img src={waiting} alt="" style={{width : "100px",color : "#000000"}}/>
            <br/>
            <br/>
            <br/>
    <p>{t('CanBeAccess')}</p>

</div>
    );
}

export default  withTranslation()(NotAvailable)
