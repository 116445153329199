import React from 'react';
import gear from "../../../assets/gears.svg"
import {withTranslation} from "react-i18next";
import { Capitalize } from '../../../helpers/utils'

const Maintenance = ({shopName,t,domain}) => {
 document.title = shopName +" :: "+t('Building')

    return (
        <div className="warning-content">
            <h1 style={{fontSize : "50px",color : "#3377ff",fontWeight : "bold"}}>{shopName} </h1>
            <h3>{t('UnderBuilding')}</h3>
            <img src={gear} alt=""/>
    <p>{t('InitShop')}</p>
    <p>{t('AvailableSoon')}</p>

</div>
    );
}

export default  withTranslation()(Maintenance)
