import React, {useEffect, useState} from 'react';
import axios from "axios";
import {attachTokenToHeaders, numberWithCommas, SERVER_URL, storage_manage} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import {CustomModal, MiddleLoading} from "../../../../../components";
import {withTranslation} from "react-i18next";
import Paginate from "../../../../../components/Paginate/Paginate";

const Generate = ({open,setOpen,shop_id,type,t,reload,auth}) => {

    const [currentProducts,setCurrentProducts] = useState([]);
    const [products,setProducts] = useState([]);

    const [loading,setLoading] = useState(false);
    const [templates,setTemplates] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [error, setError] = useState(false);
    const [choosed, setChoosed] = useState("");
    const [number, setNumber] = useState(1);
    const [stock, setStock] = useState(false);

    useEffect(() => {
        getTemplates()
        getProducts()
    }, []);

    const getTemplates = async () => {
        await axios.get(SERVER_URL+"label/template/"+shop_id,attachTokenToHeaders())
            .then((response)=>{
                if (response.data.succeed){
                    setTemplates(response.data.template)
                }else{
                    toast.error(t(response.data.message))
                }
            })
    }
    const getProducts = async () => {
        await axios.get(SERVER_URL+"/products/"+shop_id,attachTokenToHeaders())
            .then((response)=>{
               if (response.data.succeed){
                   setProducts(response.data.products)
               }else{
                   toast.error(t(response.data.message))
               }
            })
    }


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentProducts(products?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage,products])


    const handleSubmit = () => {
       if (!choosed){
           setError(true)
       }else {
           if (storage_manage(auth?.sidebar_n_storage?.storage,1000)){
               setLoading(true)
               setError(false)
               const values = {
                   products : isCheck,
                   type : type,
                   template : choosed,
                   shop_id : shop_id,
                   stock : stock,
                   number : number,
               }
               axios.post(SERVER_URL+"label/generate",values,attachTokenToHeaders(false,false,true))
                   .then((response) => {
                       setLoading(false)
                       if(response){
                           const url = window.URL.createObjectURL(new Blob([response.data]));
                           const link = document.createElement('a');
                           link.href = url;
                           link.setAttribute('download', 'labels.zip');
                           document.body.appendChild(link);
                           link.click();
                           document.body.removeChild(link);
                           window.URL.revokeObjectURL(url);

                           setStock(false)
                           setNumber(1)
                           setChoosed("")
                           setIsCheck([])
                           reload()
                           setOpen(false)
                       }
                   })
           }
       }
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(products?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(products.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (isCheck.length ===  currentProducts.length - 1)
        {
            setIsCheckAll(true)
        }else{
            setIsCheckAll(false)
        }

    };


    return (

        <CustomModal open={open} setOpen={setOpen} size="xl" title={type == "qr" ? t('GenerateQrLabel') : t('GenerateBarLabel')} >
            <div className="row">

                <div className=" col-lg-5">
                    <div className="row">
                        <div className="col-lg-5">
                            <label htmlFor="">{t('NbrCopy')}</label>
                            <input type="number" className="form-control" value={number}
                                   onChange={event => setNumber(event.target.value)}/>
                        </div>
                        <div className="col-lg-7">
                            <label htmlFor="number">Template</label>
                            <select name="" id="number" className="form-select"
                                    onChange={event => setChoosed(event.target.value)}>
                                <option value="">-</option>
                                {
                                    templates?.map((temp, key) =>
                                        <option key={key} value={temp.config}>{temp.name}</option>
                                    )
                                }
                            </select>
                            <span className="text-danger">{error ? <span>{t('ChooseTemplate')}</span> : ""}</span>
                        </div>
                    </div>

                </div>
                <div className="col-lg-5">
                    <div className="form-check form-switch mt-40">
                        <input className="form-check-input" defaultChecked={stock} onClick={event => setStock(event.target.checked)} type="checkbox" role="switch" id="stock" />
                        <label className="form-check-label" htmlFor="stock">{t('ChangeStockAccordingToNumber')}</label>
                    </div>
                </div>
                <div className="col-lg-2 text-end">
                    <br/>
                    {
                        isCheck.length > 0 && <button className="btn btn-outline-dark btn-sm mt-10"
                                                      onClick={event => handleSubmit()}>{t('Print')}</button>
                    }
                </div>
            </div>


            <div className="row mt-30">
                {
                    loading ?
                        <div className="mt-50 mb-50 d-flex justify-content-center">
                            <div className=""><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('GenerateLoading')}...
                            </div>
                        </div>
                        :

                        <>
                            <table className="table align-middle table-nowrap mb-20" id="ticketTable">
                                <thead>
                                <tr>
                                    <th scope="col" style={{width: "50px"}}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                   name="selectAll"
                                                   id="selectAll"
                                                   onChange={handleSelectAll}
                                                   checked={isCheckAll}
                                            />
                                        </div>
                                    </th>
                                    <th className="sort" data-sort="tasks_name">{t('Name')}</th>
                                    <th className="sort" data-sort="client_name">{t('Price')}</th>
                                    <th className="sort" data-sort="status">{t('Stock')}</th>
                                </tr>
                                </thead>
                                <tbody className="list form-check-all" id="ticket-list-data">
                                {
                                    currentProducts?.length > 0
                                        ?
                                        <>
                                            {
                                                currentProducts?.map((temp, key) =>
                                                    <tr key={key}>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="form-check-input"
                                                                       type="checkbox"
                                                                       name={temp.title}
                                                                       id={temp.id}
                                                                       onChange={handleClick}
                                                                       checked={isCheck.includes(temp.id)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <td className="id"><span
                                                            className="fw-medium link-primary">{temp?.title}</span></td>
                                                        <td>{numberWithCommas(temp.price)} Francs</td>
                                                        <td>{temp.stock}</td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td colSpan="4">
                                                <p className="text-center">{t('EmptyList')}</p>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={products?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </>
                }

            </div>
        </CustomModal>
    )
}

export default withTranslation()(Generate)
