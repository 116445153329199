import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../../utils/utils";

const Images = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const images = useRef()

    const getBlockSize = () => {
        setDimension({top :images?.current?.offsetTop, left : images?.current?.offsetLeft, width : images?.current?.offsetWidth, height : images?.current?.offsetHeight,nodeName : images?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);

    return(

        <section className="pt-10 pb-10"
                 style={customStyle}
        >
            <div className="container "
                 ref={images}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
                 style={{textAlign : flatData(block.contentEditable,"align")}}
            >
               <img src={flatData(block.contentEditable,'image')}  style={{height :flatData(block.contentEditable,'height')+"px",
                   width : flatData(block.contentEditable,'width')+"px"
               }} alt="" />
            </div>
        </section>
    )
}

export default Images;