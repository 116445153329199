import React, {useEffect, useState} from 'react';
import {CustomModal, MiddleLoading, RegLoading} from "../../../../components";
import {withTranslation} from "react-i18next";
import {attachTokenToHeaders, CLIENT, SERVER_URL} from "../../../../helpers/utils";
import IconShoppingCart from "../../../../components/Icons/IconShoppingCart";
import IconEye from "../../../../components/Icons/IconEye";
import IconHeart from "../../../../components/Icons/IconHeart";
import axios from "axios";
import {toast} from "react-toastify";

const CartChoosed = ({open,setOpen,t,shop_key,loadData}) => {

    const [data, setData] = useState({})
    const [shop, setShop] = useState({})
    const [card, setCard] = useState("")
    const [loading,setLoading] = useState(false)


    useEffect(() => {
        getShopData(shop_key)
    }, [open]);
    const getShopData = async (key) => {
        await axios.get(SERVER_URL+"store/template/page/"+key+"/Shop").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShop(response.data.page)
                setData(JSON.parse(response.data.page.content))
                setCard(JSON.parse(response.data.page.content)?.card)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    const submitConfig = async () => {
        setLoading(true)
        const content = {
            content : JSON.stringify({...data, card : card})
        }
        await axios.put(SERVER_URL+"store/template/page/edit/"+shop.hash_key,content,attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                loadData()
               setTimeout(()=>{
                   setLoading(false)
                   toast.success(t(response.data.message))
                   setOpen(false)
               },2000)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
    }



    return (
        <CustomModal open={open} setOpen={setOpen}  size="xl" fullscreen={true}  title={t("ProductCardConfig")}  >
            <div className="row">
                <p className="mt-3">{t('ChoosedCardForDefault')}</p>
                {
                    loading ?
                        <div>
                            <MiddleLoading />
                            <div className="mb-50"></div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-check form-radio-primary mt-50">
                                            <input className="form-check-input" type="radio"
                                                   name="cardChoosed" id="first" value="grocery"
                                                   defaultChecked={card == "grocery" ? true : false}
                                                   onClick={event => setCard(event.target.value)}
                                            />
                                            <label htmlFor="first">
                                                Grocery
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="tp-product-sm-item-5 d-flex align-items-center">
                                            <div className="tp-product-sm-thumb-5 fix">
                                                <a href="#">
                                                    <img src={CLIENT+"/builder/product/sm/5/product-sm-3.jpg"} alt=""/>
                                                </a>
                                            </div>
                                            <div className="tp-product-sm-content-5">
                                                <div className="tp-product-sm-tag-5">
                                                    <span><a href="#">{t('Cream')}</a></span>
                                                </div>
                                                <h4 className="tp-product-sm-title-5">
                                                    <a href="#">Blueberry Ice Cream</a>
                                                </h4>
                                                <div className="tp-product-sm-rating-5">
                                                    <span><i className="fa-solid fa-star"></i></span>
                                                    <span><i className="fa-solid fa-star"></i></span>
                                                    <span><i className="fa-solid fa-star"></i></span>
                                                    <span><i className="fa-solid fa-star"></i></span>
                                                    <span><i className="fa-solid fa-star"></i></span>
                                                </div>
                                                <div className="tp-product-sm-price-wrapper-5">
                                                    <span className="tp-product-sm-price-5">1200 Francs</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-check form-radio-primary mt-50">
                                            <input className="form-check-input" type="radio"
                                                   name="cardChoosed" id="emeraude"
                                                   value="emeraude"  defaultChecked={card === "emeraude" ? true : false}
                                                   onClick={event => setCard(event.target.value)}
                                            />
                                            <label htmlFor="emeraude">
                                                Emeraude
                                            </label>
                                        </div>
                                    </div>
                                    <label htmlFor="last">
                                        <div className="col-lg-12">
                                            <div className="tp-category-item-4 p-relative z-index-1 fix  text-center" style={{border : "1px solid #ccc",borderRadius : "5px"}}>
                                                <div className="tp-category-thumb-4 include-bg" style={{background : `url('${CLIENT+"/builder/category/4/category-1.jpg"}')`,marginLeft : "-40px"}}></div>

                                                <div className="tp-product-action-3 tp-product-action-4 tp-product-action-blackStyle tp-product-action-brownStyle">
                                                    <div className="tp-product-action-item-3 d-flex flex-column">
                                                        <button type="button" className="tp-product-action-btn-3 tp-product-add-cart-btn">
                                                            <IconShoppingCart  />
                                                            <span className="tp-product-tooltip">{t('BuyNow')}</span>
                                                        </button>
                                                        <button type="button" className="tp-product-action-btn-3 tp-product-quick-view-btn">
                                                            <IconEye />
                                                            <span className="tp-product-tooltip">{t('QuickView')}</span>
                                                        </button>
                                                        <button type="button" className="tp-product-action-btn-3 tp-product-add-to-wishlist-btn">
                                                            <IconHeart />
                                                            <span className="tp-product-tooltip">{t('AddToWishlist')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="tp-category-content-4">
                                                    <h3 className="tp-category-title-4">
                                                        <a href="#">Sigma Bracelet</a>
                                                    </h3>
                                                    <div className="tp-category-price-wrapper-4">
                                                        <span className="tp-category-price-4">4.800 Francs</span>
                                                        <div className="tp-category-add-to-cart">
                                                            <button className="tp-category-add-to-cart-4">
                                                                {t('BuyNow')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-lg-2 mt-20">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-check form-radio-primary mt-30">
                                            <input  className="form-check-input" type="radio"
                                                    name="cardChoosed" id="default" value="default"
                                                    defaultChecked={card == "default" ? true : false}
                                                    onClick={event => setCard(event.target.value)}
                                            />
                                            <label htmlFor="default">
                                                {t('Default')}
                                            </label>
                                        </div>
                                    </div>

                                    <label htmlFor="default">
                                        <div className="col-lg-12">
                                            <div className="tp-product-item transition-3 mb-25">
                                                <div className="tp-product-thumb p-relative fix m-img">
                                                    <a href="#">
                                                        <img src={CLIENT+"/builder/product/product-5.jpg"} alt="product-electronic"/>
                                                    </a>


                                                    <div className="tp-product-badge">
                                                        <span className="product-trending">Tendance</span>
                                                    </div>

                                                    <div className="tp-product-action">
                                                        <div className="tp-product-action-item d-flex flex-column">
                                                            <button type="button" className="tp-product-action-btn tp-product-add-cart-btn">
                                                                <IconShoppingCart />
                                                                <span className="tp-product-tooltip">{t('BuyNow')}</span>
                                                            </button>
                                                            <button type="button" className="tp-product-action-btn tp-product-quick-view-btn">
                                                                <IconEye/>
                                                                <span className="tp-product-tooltip">{t('QuickView')}</span>
                                                            </button>
                                                            <button type="button" className="tp-product-action-btn tp-product-add-to-wishlist-btn">
                                                                <IconHeart />

                                                                <span className="tp-product-tooltip">{t('AddToWishlist')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tp-product-content">
                                                    <div className="tp-product-category">
                                                        <a href="#">Powerbank</a>
                                                    </div>
                                                    <h3 className="tp-product-title">
                                                        <a href="#">
                                                            Mini Portable PD 22.5W Fast Charging Powerbank.
                                                        </a>
                                                    </h3>
                                                    <div className="tp-product-rating d-flex align-items-center">
                                                        <div className="tp-product-rating-icon">
                                                            <span><i className="fa-solid fa-star"></i></span>
                                                            <span><i className="fa-solid fa-star"></i></span>
                                                            <span><i className="fa-solid fa-star"></i></span>
                                                            <span><i className="fa-solid fa-star"></i></span>
                                                            <span><i className="fa-solid fa-star"></i></span>
                                                        </div>
                                                        <div className="tp-product-rating-text">
                                                            <span>(6 {t('Reviews')})</span>
                                                        </div>
                                                    </div>
                                                    <div className="tp-product-price-wrapper">
                                                        <span className="tp-product-price">72.000 Francs</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-check form-radio-primary mt-40">
                                            <input className="form-check-input" type="radio"
                                                   name="cardChoosed" id="defaultWithoutBord"
                                                   value="defaultWithoutBord"
                                                   defaultChecked={card === "defaultWithoutBord" ? true : false}
                                                   onClick={event => setCard(event.target.value)}
                                            />
                                            <label htmlFor="defaultWithoutBord">
                                                {t('defaultWithoutBord')}
                                            </label>
                                        </div>
                                    </div>
                                    <label htmlFor="defaultWithoutBord">
                                        <span className="col-lg-12">
                                             <div className="tp-product-item-2 mb-40">
                                        <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                                            <a href="#">
                                                <img src={CLIENT + "/builder/product/2/prodcut-2.jpg"} alt=""/>
                                            </a>

                                            <div className="tp-product-action-2 tp-product-action-blackStyle">
                                                <div className="tp-product-action-item-2 d-flex flex-column">
                                                    <button type="button"
                                                            className="tp-product-action-btn-2 tp-product-add-cart-btn">
                                                        <IconShoppingCart/>
                                                        <span
                                                            className="tp-product-tooltip tp-product-tooltip-right">{t('BuyNow')}</span>
                                                    </button>
                                                    <button type="button"
                                                            className="tp-product-action-btn-2 tp-product-quick-view-btn">
                                                        <IconEye/>
                                                        <span
                                                            className="tp-product-tooltip tp-product-tooltip-right">{t('QuickView')}</span>
                                                    </button>
                                                    <button type="button"
                                                            className="tp-product-action-btn-2 tp-product-add-to-wishlist-btn">
                                                        <IconHeart/>
                                                        <span
                                                            className="tp-product-tooltip tp-product-tooltip-right">{t('AddToWishlist')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-product-content-2 pt-15">
                                            <div className="tp-product-tag-2">
                                                <a href="#">Backpack, </a>
                                                <a href="#">Wonder</a>
                                            </div>
                                            <h3 className="tp-product-title-2">
                                                <a href="#">Simple Modern School Boys</a>
                                            </h3>
                                            <div className="tp-product-rating-icon tp-product-rating-icon-2">
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                                <span><i className="fa-solid fa-star"></i></span>
                                            </div>
                                            <div className="tp-product-price-wrapper-2">
                                                <span className="tp-product-price-2 new-price">20.000 Francs</span>
                                                <span className="tp-product-price-2 old-price">28.500 Francs</span>
                                            </div>
                                        </div>
                                    </div>
                                        </span>
                                    </label>
                                </div>
                            </div>


                            <div className="offset-lg-5 col-lg-2 mt-50">
                                <button className="btn btn-success btn-block" onClick={event => submitConfig()}>
                                    {
                                        loading ?
                                            <><span className="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"/> {t('Loading')}...</>
                                            :
                                            <span>{t('Submit')}</span>
                                    }

                                </button>
                            </div>
                        </div>
                }


            </div>

        </CustomModal>

    )
}

export default withTranslation()(CartChoosed)
