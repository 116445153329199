import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/front/Layout";
import {withTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {APPNAME, attachTokenToHeaders, format_date_complet, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import {toast} from "react-toastify";
import logo from "../../../assets/logo.png";
import user from "../../../assets/admin/images/avatar-blank.png"
import {compose} from "redux";
import {connect} from "react-redux";
import {LINK_LOGIN_STORE} from "../../../routes/type";
import * as Yup from "yup";
import Answer from "../../../components/Blog/Answer";
import Part3 from "../Home/contents/Part3";
import {ShareSocial} from "react-share-social";
import moment from "moment/moment";
import 'moment/locale/fr'

moment().locale('fr')

const NewsDetail = ({t,auth}) => {
    const [loading,setLoading] = useState(false)
    const [detail, setDetail] = useState(null);
    const [comments, setComments] = useState(null);
    const [reply, setReply] = useState(false);
    const [parent, setParent] = useState(null);

    const urlParams = useParams()



    const style = {
        root: {
            width: "100%",
            // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            // borderRadius: 3,
            // border: 0,
            // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            // color: 'white',
        },
        copyContainer: {
            display: "none",
            border: '1px solid blue',
            background: 'rgb(0,0,0,0.7)'
        },
        // title: {
        //     color: 'aquamarine',
        //     fontStyle: 'italic'
        // }
    };



    useEffect(() => {
        detailSingleArticle()
    },[])



    const detailSingleArticle = async () => {
        await axios.get(SERVER_URL+"article/single/"+urlParams.slug).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setDetail(response.data.article)
                getAllcomments(response.data.article?.id)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch(err => {
            setLoading(false)
        })
    }



    const getAllcomments = async (id) => {
        await axios.get(SERVER_URL+"article/comments/"+id).then((response)=>{
            if (response.data.succeed){
                setComments(response.data.comments)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch(err => {
            setLoading(false)
        })
    }


    const validationSchema = Yup.object().shape({

    });

    const initialValues = {
        comment : ""
    }



    const addNewComment = async (values) => {
       setLoading(true)
        values.user_id = auth?.me.id
        values.parent_id = parent
        values.article_id = detail.id
        await axios.post(SERVER_URL+"comment/add",values,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setLoading(false)
                toast.success(t(response.data.message), {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })

                getAllcomments(detail?.id)
               values.comment = ""
                setParent(null)
                setReply(false)
            }else{
                 toast.error(t(response.data.message))
            }
        }).catch(err => {
            setLoading(false)
        })
    }


    const replyTo = (element) => {
       setParent(element.id)
       setReply(true)
    }

    const CloseReply = () => {
       setParent(null)
       setReply(false)
    }



    return (
        <Layout>
            {
                detail == null ?
                    <section className="shop-area p-t-180 p-b-130">
                        <div className="container">
                            <div className="row">
                                <div className="text-center p-t-200 p-b-200"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</div>
                            </div>
                        </div>
                    </section>
                    :
                    <>
                        <section className="shop-area p-t-180 p-b-10">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="header_custom_pricing">
                                            <h2>{detail?.title}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>


                        <section className="blog-area p-b-50">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <div className="blog-details-content p-r-40 p-r-lg-0">
                                            <div className="post-thumbnail">
                                                <img src={SERVER_IMG_URL+"blogs/"+detail?.image} alt="blog thumbnail one"/>
                                            </div>

                                            <div className="post-content">
                                                <ul className="post-meta">
                                                    <li><a href="#" className="post-meta"><i
                                                        className="far fa-user"></i>{detail?.author}</a></li>
                                                    <li><a href="#" className="post-meta"><i
                                                        className="far fa-calendar-alt"></i>{format_date_complet(detail?.created_at)}</a></li>
                                                    <li><a href="#" className="post-meta"><i className="far fa-comment-dots"></i>{t('Comments')} ({comments?.length})</a></li>
                                                </ul>


                                                <div dangerouslySetInnerHTML={{__html: detail?.content }}  />


                                                <hr/>

                                                <div className="post-footer m-t-10">
                                                    <ShareSocial
                                                        title={t('Share')}
                                                        url={window.location.href}
                                                        socialTypes= {['facebook','twitter','whatsapp','linkedin','telegram','reddit','email']}
                                                        onSocialButtonClicked={ data => console.log(data)}
                                                        style={style}
                                                    />


                                                    <div className="post-author-box">
                                                        <div className="author-thumbnail">
                                                            <img src={logo} alt="Post Author"/>
                                                        </div>
                                                        <div className="author-content">
                                                            <h4 className="name">{detail?.author}</h4>
                                                            <p>
                                                                {t('Redacteur')} {APPNAME}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comments-template">
                                            <h4 className="template-title">{t('Comments')}</h4>


                                            {
                                                auth?.isAuthenticated ?
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-12">
                                                            <div className="comment-form d-flex align-items-center">
                                                                <div className="flex-shrink-0">
                                                                    <div className="avatar avatar-sm rounded-circle">
                                                                        {
                                                                            auth?.me?.role_id == 2 ?
                                                                                <img src={auth?.me?.avatar ? SERVER_IMG_URL+"/"+auth?.me?.shop_key+"/users/"+auth?.me?.avatar : user} className="avatarRounded" alt="comment author one"/>
                                                                                :
                                                                                <img src={user} alt="comment author one"/>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Answer initialValues={initialValues} addNewComment={addNewComment} validationSchema={validationSchema}   />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="alert alert-danger m-t-100" role="alert">
                                                        <br/>
                                                        <p>{t('LoginBefore')}, <Link to={LINK_LOGIN_STORE}><b style={{textDecoration : "underline"}}>{t('Login')}</b></Link></p>
                                                    </div>
                                            }
                                              <br/>
                                              <br/>
                                             <div className="row justify-content-center mb-4">

                                                            <div className="col-lg-12">
                                                                <div className="comments">
                                                                    {
                                                                        comments?.length > 0 ?
                                                                            <>
                                                                                {
                                                                                    comments?.map((comment,key) =>
                                                                                        <div className="comment d-flex mb-4" key={key}>
                                                                                            <div className="flex-shrink-0">
                                                                                                <div className="avatar avatar-sm rounded-circle">
                                                                                                    {
                                                                                                        comment.role_id == 2 ?
                                                                                                            <img src={comment?.avatar ? SERVER_IMG_URL+"/"+comment?.shop_key+"/users/"+comment?.avatar : user} className="avatarRounded" alt="comment author one"/>
                                                                                                            :
                                                                                                            <img src={user} alt="comment author one"/>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="flex-grow-1 ms-2 ms-sm-3">
                                                                                                <div className="comment-meta d-flex align-items-baseline">
                                                                                                    <h6 className="me-2">{comment?.firstname} {comment?.lastname}</h6>
                                                                                                    <span className="text-muted"><small>{moment(comment?.created_at).fromNow()}</small></span>
                                                                                                </div>
                                                                                                <div className="comment-body">
                                                                                                    {comment?.comment}
                                                                                                    {  auth?.isAuthenticated ?
                                                                                                        <div>
                                                                                                            <br/>
                                                                                                            <span>
                                                                                                        <small><i className=" bx bx-message-square-dots"></i> {comment.children.length}</small>
                                                                                                    </span> <span  className="cursor-pointer text-info fs-12" onClick={event => replyTo(comment)}> <i className="bx bx-reply"></i> {t('Reply').toLowerCase()}
                                                                                                    </span> {reply && parent == comment.id ?  <span  className="cursor-pointer text-danger fs-12 p-l-10" onClick={event => CloseReply()}><i className="bx bx-x"></i> {t('Close').toLowerCase()}</span> : ""}
                                                                                                        </div>
                                                                                                        :
                                                                                                        ""
                                                                                                    }
                                                                                                </div>


                                                                                                {
                                                                                                    reply ?
                                                                                                          <>
                                                                                                              {
                                                                                                                  parent == comment.id ?
                                                                                                                     <Answer initialValues={initialValues} addNewComment={addNewComment} validationSchema={validationSchema}  isSub={true} />
                                                                                                                      : ""
                                                                                                              }
                                                                                                          </>
                                                                                                        : ""
                                                                                                }


                                                                                                {comment.children.length  ?
                                                                                                    <div className="comment-replies btn-light p-3 mt-3 rounded" style={{borderLeft : "1px solid #ccc"}}>
                                                                                                        {
                                                                                                            comment.children?.map((child,index) =>
                                                                                                                <div className="reply d-flex mb-4" key={index}>
                                                                                                                    <div
                                                                                                                        className="flex-shrink-0">
                                                                                                                        <div
                                                                                                                            className="avatar avatar-sm rounded-circle">
                                                                                                                            {
                                                                                                                                child.role_id == 2 ?
                                                                                                                                    <img
                                                                                                                                        src={child?.avatar ? SERVER_IMG_URL + "/" + child?.shop_key + "/users/" + child?.avatar : user}
                                                                                                                                        className="avatarRounded"
                                                                                                                                        alt="comment author one"/>
                                                                                                                                    :
                                                                                                                                    <img
                                                                                                                                        src={user}
                                                                                                                                        alt="comment author one"/>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="flex-grow-1 ms-2 ms-sm-3">
                                                                                                                        <div
                                                                                                                            className="reply-meta d-flex align-items-baseline">
                                                                                                                            <h6 className="mb-0 me-2">{child?.firstname} {child?.lastname}</h6>
                                                                                                                            <span className="text-muted"><small>{moment(child?.created_at).fromNow()}</small></span>

                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="reply-body fs-14">
                                                                                                                            {child?.comment}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }

                                                                                                    </div>
                                                                                                    : ""
                                                                                                }


                                                                                            </div>

                                                                                        </div>

                                                                                    )
                                                                                }
                                                                            </>
                                                                            :
                                                                           ""
                                                                    }
                                                                </div>
                                                            </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>




                    </>


            }
            <Part3 />
        </Layout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(NewsDetail));
