import React,{useEffect,useState} from 'react';
import {toast} from "react-toastify";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../../helpers/utils";
import _OptModal from "./_OptModal";
import Cookies from "js-cookie";
const SettingsSecurity = ({t,auth}) => {

    const [loading, setLoading] = useState(false);

    const [secret, setSecret] = useState({
        otpauth_url: "",
        base32: "",
    });
    const [openModal, setOpenModal] = useState(false);




    const generateQrCode = async (user_id, email) => {
        try {
          setLoading(true)
            const data = {id : user_id,email : email}
            const response = await axios.post(SERVER_URL+"store/otp/generate",data,attachTokenToHeaders());

            setLoading(false)

            if (response.data.succeed) {
                setOpenModal(true);
                setSecret({
                    base32: response.data.base32,
                    otpauth_url: response.data.otpauth_url,
                });
            }
        } catch (error) {
            setLoading(false);
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.response.data.message || error.message || error.toString();
            toast.error(resMessage);
        }
    };




    const disableTwoFactorAuth = async () => {
        setLoading(true)
        try {
            const data = {
                id : auth.me?.id,
            }
                await axios.post(SERVER_URL+"store/otp/disabled", data,attachTokenToHeaders()).then((response)=>{
                setLoading(false);
                    Cookies.remove('_ot')
                toast.success(t(response.data.message));

                    setTimeout(()=>{
                        window.location.reload(true)
                    },2000)
            })



        } catch (error) {
            setLoading(false);
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.response.data.message || error.message || error.toString();
            toast.error(resMessage);
        }
    };




    const verifyOtp =  async (token) => {
        try {
           setLoading(true);

           const data = {
               id : auth.me?.id,
               token : token
           }
          await axios.post(SERVER_URL+"store/otp/verify",data,attachTokenToHeaders()).then((pass)=>{
              if (pass){
                  setLoading(false);
                  setOpenModal(false)
                  toast.success("Two-Factor Auth Enabled Successfully");

                  setTimeout(()=>{
                      window.location.reload(true)
                  },2000)
              }
          })

        } catch (error) {
            setLoading(false);
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.response.data.message || error.message || error.toString();
            toast.error(resMessage);
        }
    }


    return (
        <>
            <h5>{t('Security')}</h5>
        <hr/>
            <br/>

            <div className="row">

                <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                    <div className="flex-grow-1">
                        <h6 className="fs-14 mb-1">{t('MobileAppAuthText')}</h6>
                        <p className="text-muted">{t('MobileAppAuthTextDesc')}</p>
                    </div>
                    <div className="flex-shrink-0 ms-sm-3">
                        {
                            auth.me?.otp_enabled

                                ? <button className="btn btn-danger"  onClick={() => disableTwoFactorAuth({ user_id: auth.me?.id})}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>

                                            : <span>{t('Disabled')} 2FA</span>
                                    }
                                </button>

                                : <button className="btn btn-primary"  onClick={() => generateQrCode(auth.me?.id,auth.me?.email)}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>

                                            : <span>{t('Configure')} 2FA</span>
                                    }

                                </button>
                        }
                    </div>
                </div>

            </div>

            <_OptModal open={openModal} setOpen={setOpenModal} secret={secret} verifyOtp={verifyOtp} t={t} />
        </>
    );
}

export default SettingsSecurity;
