import React, {Fragment, useEffect, useRef, useState} from 'react';
import {EffectCards, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {withTranslation} from "react-i18next";
import {flatData} from "../../utils/utils";

const Testimonial = ({block,customStyle,toggleHightLight,t,isEditor}) => {

    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(block.contentEditable[0].data)
    }, [block.contentEditable]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const testimonialRef = useRef()

    const getBlockSize = () => {
        setDimension({top :testimonialRef?.current?.offsetTop, left : testimonialRef?.current?.offsetLeft, width : testimonialRef?.current?.offsetWidth, height : testimonialRef?.current?.offsetHeight,nodeName : testimonialRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    return (
        <section className="tp-testimonial-area grey-bg-7 pt-130 pb-135"
                 ref={testimonialRef}
                 onMouseEnter={() => isEditor && getDimension(block,true) }
                 onMouseLeave={() => isEditor && getDimension(block,false) }
                 style={customStyle}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="tp-testimonial-slider p-relative z-index-1">
                            <div className="tp-testimonial-shape">
                                <span className="tp-testimonial-shape-gradient"></span>
                            </div>
                            <h3 className="tp-testimonial-section-title text-center">{t('Testimonials')}</h3>
                            <div className="row justify-content-center">
                                <div className="col-xl-8 col-lg-8 col-md-10">
                                    <div className="tp-testimonial-slider-active swiper-container">
                                        <div className="swiper-wrapper">
                                            <Swiper
                                                grabCursor={true}
                                                autoplay={{
                                                    delay : 100
                                                }}
                                                loop={true}
                                                slidesPerView={1}
                                                modules={[Pagination,Navigation]}
                                                pagination={{
                                                    el : '.tp-testimonial-slider-dot' , clickable : true
                                                }}
                                                navigation={{
                                                    nextEl : ".tp-testimonial-slider-button-next",
                                                    prevEl : ".tp-testimonial-slider-button-prev",
                                                    clickable : true
                                                }}
                                            >
                                                {
                                                   items?.map((item,key) =>
                                                            <SwiperSlide key={key}>
                                                           <div className="tp-testimonial-item text-center mb-20 swiper-slide">
                                                                <div className="tp-testimonial-rating">
                                                                    {
                                                                        [...Array(flatData(item,"rate"))].map((_,cc)=>
                                                                            <span key={cc}><i className="fa-solid fa-star"></i></span>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className="tp-testimonial-content">
                                                                    <p>“{flatData(item,"message")}”</p>
                                                                </div>
                                                                <div className="tp-testimonial-user-wrapper d-flex align-items-center justify-content-center">
                                                                    <div className="tp-testimonial-user d-flex align-items-center">
                                                                        <div className="tp-testimonial-avater mr-10">
                                                                            <img src={flatData(item,"customerPic")} alt=""/>
                                                                        </div>
                                                                        <div className="tp-testimonial-user-info tp-testimonial-user-translate">
                                                                            <h3 className="tp-testimonial-user-title">{flatData(item,"customerName")}</h3>
                                                                            <span className="tp-testimonial-designation">{flatData(item,"customerRole")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </SwiperSlide>
                                                    )
                                                }
                                            </Swiper>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tp-testimonial-arrow d-none d-md-block">
                                <button className="tp-testimonial-slider-button-prev">
                                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.061 6.99959L16 6.99959" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.08618 1L1.06079 6.9995L7.08618 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <button className="tp-testimonial-slider-button-next">
                                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.939 6.99959L1 6.99959" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9.91382 1L15.9392 6.9995L9.91382 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="tp-testimonial-slider-dot tp-swiper-dot text-center mt-30 tp-swiper-dot-style-darkRed d-md-none"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default withTranslation()(Testimonial)
