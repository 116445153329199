import React, {useEffect, useRef, useState} from 'react';
import {
    attachTokenToHeaders,
    Capitalize,
    checkCurrentUrlSubdomain,
    SERVER_IMG_URL,
    SERVER_URL,
} from '../../../../../helpers/utils'
import {withTranslation} from "react-i18next";
import CustomRender from "../../../../../themes/config/config.block";
import axios from "axios";
import {toast} from "react-toastify";
import SubLoading from "../../../../../components/Loading/SubLoading";
import { Fade } from "react-awesome-reveal";
import { Helmet } from 'react-helmet'

const Home = ({t}) => {
    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+t('Home')

    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState([])


    const [shop, setShop] = useState({});

    useEffect(()=>{
        checkDomainType()
    },[])


    const checkDomainType = async () =>{
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShop(response.data)
                getPageData(response.data)
            }
        })
    }







    const getPageData = async (data) => {
        setLoading(true)
        await axios.get(SERVER_URL+"store/template/page/"+data?.shop.shop_key+"/Home").then((response)=>{
             setLoading(false)
            if (response.data.succeed){

                const data = response?.data?.page?.content
               if (data)  setPageData(JSON.parse(data))
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })

    }


    return (
        <>
            <Helmet>
                <link rel="icon" href={SERVER_IMG_URL + shop?.shop?.shop_key + '/logo/' + shop?.shop?.logo} />
                <meta name="theme-color" content={shop?.shop?.color} />
                <meta property="og:title"
                      content={Capitalize(window.location.host.split('.')[0]) + ' :: ' + t('Home')} />
                <meta property="og:image"
                      content={SERVER_IMG_URL + shop?.shop?.shop_key + '/logo/' + shop?.shop?.logo} />
                <title>{Capitalize(window.location.host.split('.')[0]) + ' :: ' + t('Home')}</title>
                <meta name="description" content={shop?.shop?.description} />
            </Helmet>


            {
                loading ?
                    <SubLoading /> :
                    <Fade>
                        {pageData.map(({ item }, index) => (
                            <div key={index}>
                                <CustomRender block={item} isEditor={false} shop_data={shop?.shop}  />
                            </div>
                        ))}
                    </Fade>
            }
        </>
    )
}

export default withTranslation()(Home)
