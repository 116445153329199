import React,{useEffect,useState} from 'react';
import {SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import Loader from "../../../../components/Loading/Loader";
import Cookies from 'js-cookie'
import {useHistory} from 'react-router-dom'
import {LINK_REGISTER_STORE} from "../../../../routes/type";

const RegisterSponsor = ({match}) => {

    useEffect(() => {
      if (match.params.shop_key != null){
          verifyUrl(match.params.shop_key);
      }
    }, [match]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const history = useHistory()

    const verifyUrl = async (shop_key) => {
        setLoading(true)
        try {
            await axios.get(SERVER_URL+"check/sponsor/"+shop_key)
                .then((response) => {
                    setLoading(false)
                    if (response.data.succeed){
                       Cookies.set('sponsor_key',shop_key)
                        history.push(LINK_REGISTER_STORE)
                    }else{
                        setError(response.data.message)
                    }
                })

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    };

    return  (
        <>
            {loading ? <Loader /> : <div>
                {error ? <h1>{error}</h1>  : null}
            </div>}
        </>
    )


}

export default RegisterSponsor;