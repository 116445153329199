import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import placeholder from '../template/styles/placeholder.jpg'
import axios from "axios";
import {SERVER_IMG_URL,SERVER_URL} from "../../../../helpers/utils";
import TrialEmpty from "../template/blocs/TrialEmpty";
import {Link, Redirect} from "react-router-dom";


const  TrialSubcategories = ({t}) => {


    const [shop,setShop] = useState(JSON.parse(localStorage.getItem('__S78456321__')));
    const [subcategories,setSubcategories] = useState([]);
    const [subexist, setSubexist] = useState(false);
    const [products, setProducts] = useState([]);
    const [galerie, setGalerie] = useState([]);
    const [rate, setRates] = useState([]);


    const slug = useRef(window.location.pathname.split('/').pop());



    useEffect(() => {
        if (shop){
            getSubCategories()
        }
    }, []);

    const getSubCategories = async () => {
        await axios.get(SERVER_URL+"front/categorie/"+slug.current).then((response)=>{
            if (response.data.succeed){
                setSubexist(response.data.subexists)
                if (!response.data.subexists){
                   return <Redirect to={`/products?filter-by-category=${response.data.categoryId}`} />
                }else{
                    setSubcategories(response.data.subcategory)
                }
            }else{
                // toast.error(t(response.data.message))
            }
        })
    }



    return (
        <div className=""  style={{background : "#f4f4f4"}}>
            <div className="u-s-p-y-90">
                <div className="container u-s-p-b-80">
                    <div className="row">
                        <h1 className="text-center">{t('Subcategories')}</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row">

                        {
                            subcategories?.length > 0 ?
                                <>
                                    {
                                        subcategories?.map((sub,key) =>
                                            <div className="col-lg-4" key={key}>
                                                <div className="bp bp--img u-s-m-b-30">

                                                    <div className="bp__wrap" style={{ boxShadow: "0 2px 15px rgba(0, 0, 0, 0.2)"}}>
                                                        <div className="bp__thumbnail">
                                                            <Link to={`/products?filter-by-subcategory=${sub.id}`} className="aspect aspect--bg-grey u-d-block" style={{height : "250px"}} >
                                                                <img className="aspect__img" src={sub.image ? SERVER_IMG_URL+shop?.shop_key+"/subcategories/"+sub.image : placeholder} alt=""/>
                                                            </Link>
                                                        </div>
                                                        <div className="bp__info-wrap">
                                                            <p className="text-center">
                                                                <span className="bp__h1"><a>{sub.subname}</a></span>
                                                            </p>
                                                            <p className="bp__p text-center"><small>{sub.description}</small></p>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    }
                                </>
                                :
                                <TrialEmpty />
                        }

                    </div>

                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TrialSubcategories)
