import React,{useState,useEffect} from 'react';
import {attachTokenToHeaders, formatPhoneNumber, SERVER_URL, sha1_random} from "../../../../../helpers/utils";
import {toast} from "react-toastify";
import {MiddleLoading} from "../../../../../components";
import axios from "axios";
import swal from "@sweetalert/with-react";
import CustomPhoneInput from "../../../../../components/CustomInputs/CustomPhoneInput";

const SettingsDash = ({t,auth}) => {

    const [momo, setMomo] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [number, setNumber] = useState("");
    const [nloading, setNLoading] = useState(false);
    const [connectLoading, setConnectLoading] = useState(false);
    const [method, setMethod] = useState([]);

    const activeSellerNonce = sha1_random(44)


    useEffect(() => {
        getAllPayment()
        getAllMethod()
        return () => {};
    }, []);

     const getAllPayment = async () =>{
        await axios.get(SERVER_URL+"store/settings/payment/"+auth?.shop.id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPaymentMethod(response.data.method)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }
     const getAllMethod= async () =>{
        await axios.get(SERVER_URL+"method/payment/"+auth?.shop?.id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setMethod(response.data.method)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }
     const addMethodPayment = async (method,status) =>{

         const data = {
             method : method,
             status : status
         }

        await axios.post(SERVER_URL+"method/payment/add/"+auth?.shop?.id,data,attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                toast.success(t(response.data.message))
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    // MoMo
    const deleteNumber = async (id) => {
        await swal({
            title : t('Deleting'),
            text : t('SureToDeleteNumber'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                try {
                    const response = await axios.delete(`${SERVER_URL}store/settings/payment/delete/`+id,attachTokenToHeaders())
                    if (response.data.succeed == true) {
                        toast.success(t(response.data.message))
                        getAllPayment()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }
    const addPhoneNumber = async () =>{
        if (number.length == 11){
            setNLoading(true)
            const data = {
                number : number,
                shop_id : auth?.shop.id
            }
            const response = await axios.post(SERVER_URL+"store/settings/payment/number",data,attachTokenToHeaders())
            if (response.data.succeed){
                setNLoading(false)
                setNumber("")
                toast.success(t(response.data.message))
                setOpen(false)
                getAllPayment()
            }else{
                setNLoading(false)
                toast.error(t(response.data.message))
            }
        }else{
            toast.error(t('LengthIncorrect'))
        }
    }

    // PayPal API

    const paypalGenerateLink = async (access_token,once) =>{
        const body = {
            "operations": [
                {
                    "operation": "API_INTEGRATION",
                    "api_integration_preference": {
                        "rest_api_integration": {
                            "integration_method": "PAYPAL",
                            "integration_type": "FIRST_PARTY",
                            "first_party_details": {
                                "features": [
                                    "PAYMENT",
                                    "REFUND"
                                ],
                                "seller_nonce": once
                            }
                        }
                    }
                }
            ],
            "products": [
                "EXPRESS_CHECKOUT"
            ],
            "legal_consents": [
                {
                    "type": "SHARE_DATA_CONSENT",
                    "granted": true
                }
            ]
        }

        return axios({
            method: "post",
            url: `https://api-m.sandbox.paypal.com/v2/customer/partner-referrals`,
            data: body,
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });

    }


    const onboardedCallback = (data) => {
        console.log(data)
    }


    const connect = async () => {

        setConnectLoading(true)

        await axios.post(
            'https://api-m.sandbox.paypal.com/v1/oauth2/token',
            new URLSearchParams({
                'grant_type': 'client_credentials'
            }),
            {
                auth: {
                    username: 'AVXjYf_tHC9qJL-uHzUUJ8-rfDSxlXkAxE6fU7fLobWBfpU6ZD8JzOw0qHLCDU_6JpaF0_ygl4AoC6DZ',
                    password: 'EArdvWQcwCQJpX5-DrF_UtHHZKSYwliRUffHMXFyU_EXnS4GrX4_Zc-4hR7g9-kl-1ffFxJAQlUhmPjK'
                }
            }
        ).then(async (response) =>{
            const access_token = response.data.access_token
            await paypalGenerateLink(access_token,activeSellerNonce).
            then((generate)=>{
                // let newWindow = window.open(generate?.data?.links[1].href, 'PayPal', 'width=640,height=720')
                 const href = generate?.data?.links[1].href+"&displayMode=minibrowser"
                // const link = document.createElement('a');
                // link.href = href;
                // link.setAttribute('data-paypal-button', "true");
                // link.setAttribute('data-paypal-onboard-complete', "onboardedCallback");
                // link.setAttribute('target', "PPFrame");
                // document.body.appendChild(link);
                // link.click();

                const btn = document.getElementById("paypalBtn")
                btn.setAttribute("href",href)
                btn.setAttribute('data-paypal-onboard-complete', `onboardedCallback`);
                btn.click()

            })

        })


    }






    return (
        <>
            <h5>{t('PaymentSettings')}</h5>
            <hr/>

            <div className="row">
                <div className="col-lg-12">
                    <div className="d-flex align-items-center">
                        <div className="mb-0 flex-grow-1">

                            <label>{t('AddPaymentMomo')}</label>
                            <br/>
                            <small className="text-muted">{t('AddPaymentGateway')}</small>

                        </div>
                        <div className="flex-shrink-0">
                                    <button className="btn btn-primary btn-sm add-btn" onClick={event => setOpen(true)}>
                                        {t('Add')}
                                    </button>
                        </div>
                    </div>
                </div>
            </div>


            {
                open ?
                    <div className="row mt-20 mb-20">
                        <div className="col-lg-10">
                            <div className="field-group">
                                <CustomPhoneInput phone={number} name="phone" setPhone={event => setNumber(event)}  setCountry={(ddd) => console.log(ddd)} />
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <button className="btn btn-lg  btn-primary btn-block "  onClick={event => addPhoneNumber()}>
                                {
                                    nloading ?
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                        :
                                        <span>{t('Add')}</span>
                                }
                            </button>
                        </div>
                        <div className="col-lg-1">
                            <button className="btn btn-danger btn-lg" onClick={event => setOpen(false)}>{t('Close')}</button>
                        </div>
                    </div>
                    : ""
            }

            <div className="row mb-5">
                {
                    loading ?
                    <span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</span>
                        :
                        <div className="row mt-3 ml-1">

                            {
                                !open && paymentMethod?.length > 0 ?
                                    <>
                                        {
                                            paymentMethod?.filter(e => e.payment_method === "MoMo").map((item, key) =>
                                                <div className="col-lg-4" key={key}>
                                                    <div><b>Status</b> : <span className="badge badge-success">{t('Active')}</span> <br/>
                                                    <b>{t('Phone')}</b> : {formatPhoneNumber(item.numOrEmail)} <br/>
                                                        <small className="cursor-pointer text-danger" onClick={event => deleteNumber(item.id)}>{t('Delete')}</small>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </>

                                    :

                                    ""


                            }
                        </div>

                }

            </div>

            {/*<hr/>*/}
            {/*<div className="row">*/}
            {/*    <div className="col-lg-12">*/}
            {/*        <div className="d-flex align-items-center">*/}
            {/*            <div className="mb-0 flex-grow-1">*/}

            {/*                <label>{t('AddPaymentPayPal')}</label>*/}
            {/*                <br/>*/}
            {/*                <small className="text-muted">{t('AddPaymentGatewayPayPal')}</small>*/}

            {/*            </div>*/}
            {/*            <div className="flex-shrink-0">*/}
            {/*                <button className="btn btn-primary btn-sm add-btn" onClick={event => connect()} >*/}

            {/*                    {*/}
            {/*                        connectLoading ?*/}
            {/*                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>*/}
            {/*                            :*/}
            {/*                            <span>{t('Configure')}</span>*/}
            {/*                    }*/}

            {/*                </button>*/}

            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}




            <hr/>


            <div className="row mt-0">
                <div className="col-lg-12">
                    <div className="d-flex align-items-center">
                        <div className="mb-0 flex-grow-1">

                            <label>{t('ConfigurePayment')}</label>
                            <br/>
                            <small className="text-muted">{t('ConfigureAcceptedPayment')}</small>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <fieldset className="checkbox-group">
                        <div className="checkbox">
                            <label className="checkbox-wrapper">
                                <input type="checkbox" className="checkbox-input" defaultChecked={method?.filter(e => e.param === "PAYPAL")[0]?.statut} onClick={event => addMethodPayment("PAYPAL",event.target.checked)}/>
                                <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                        <i className="ri-paypal-fill"></i>
                                    </span>
                                    <span className="checkbox-label">PayPal</span>
                                </span>
                            </label>
                        </div>

                        {
                            paymentMethod?.filter(e => e.payment_method === "MoMo").length > 0 ?
                                <div className="checkbox">
                                    <label className="checkbox-wrapper">
                                        <input type="checkbox" className="checkbox-input"   defaultChecked={method?.filter(e => e.param === "MOMO")[0]?.statut} onClick={event => addMethodPayment("MOMO",event.target.checked)}/>
                                        <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                        <i className="ri-phone-fill"></i>
                                    </span>
                                    <span className="checkbox-label">Mobile</span>
                                </span>
                                    </label>
                                </div>
                                :
                                ""
                        }


                    </fieldset>
                </div>
            </div>
            <hr/>
            <div className="row mt-0">
                <div className="col-lg-12">
                    <div className="d-flex align-items-center">
                        <div className="mb-0 flex-grow-1">

                            <label>{t('AnotherConfigurePayment')}</label>
                            <br/>
                            <small className="text-muted">{t('AnotherConfigureAcceptedPayment')}</small>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-20">
                    <div className="col-lg-4">
                        <div className="form-check form-switch form-switch-primary">
                            <input className="form-check-input" type="checkbox" role="switch" id="bank" defaultChecked={method?.filter(e => e.param === "DIRECTBANK")[0]?.statut} onClick={event => addMethodPayment("DIRECTBANK",event.target.checked)} />
                            <label className="form-check-label" htmlFor="bank">{t('DirectBankTransfert')}</label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-check form-switch form-switch-primary">
                            <input className="form-check-input" type="checkbox" role="switch" id="cheque" defaultChecked={method?.filter(e => e.param === "CHEQUERECEIVERY")[0]?.statut} onClick={event => addMethodPayment("CHEQUERECEIVERY",event.target.checked)} />
                                <label className="form-check-label" htmlFor="cheque">{t('ChequePayment')}</label>
                        </div>
                    </div>

                <div className="col-lg-4">
                    <div className="form-check form-switch form-switch-primary">
                        <input className="form-check-input" type="checkbox" role="switch" id="cheque" defaultChecked={method?.filter(e => e.param === "CASHONDELIVERY")[0]?.statut} onClick={event => addMethodPayment("CASHONDELIVERY",event.target.checked)} />
                        <label className="form-check-label" htmlFor="cheque">{t('CashOnReceived')}</label>
                    </div>
                </div>
            </div>


        </>
    );
}

export default SettingsDash;
