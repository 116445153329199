import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {LINK_STORE_APPS} from "../../../../routes/type";
import axios from "axios";
import {attachTokenToHeaders, database_date, deepEqual, hasEmptyValue, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {MiddleLoading} from "../../../../components";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import swal from "@sweetalert/with-react";
import {FACEBOOK_APP_ID} from "../../../../helpers/keys";

const MessengerConf = ({t,auth}) => {

    const [loading, setLoading] = useState(true);
    const [doading, setDoading] = useState(false);
    const [soading, setSoading] = useState(false);
    const [inputs, setInputs] = useState({});

    const history = useHistory()

    useEffect(() => {
        getWidgetConf()
    }, []);

    const getWidgetConf = async () => {
        await axios.get(SERVER_URL+"package/config/messenger",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                console.log(response.data.config)
                if (!hasEmptyValue(response.data.config)){
                    setInputs(JSON.parse(response.data.config))
                }else{
                    setInputs({
                        appId  : "",
                        pageId  : ""
                    })
                }

                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }



    const validationSchema = Yup.object().shape({
        appId: Yup.string().required(t('InputRequired')),
        pageId: Yup.string().required(t('InputRequired')),
    });


    const initialValues = {
        appId  : FACEBOOK_APP_ID,
        pageId  : inputs.pageId,
    }




    const handleSubmit = async (values) => {
        if (!deepEqual(values,inputs)){
            setDoading(true)
            const config = JSON.stringify(values)
            const expression = "messenger"
            const shop_id =  auth?.shop.id

            const data = {
                config : config,
                shop_id : shop_id,
                expression : expression
            }

            await axios.post(SERVER_URL+"package/config",data,attachTokenToHeaders()).then((response)=>{
                setDoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                }else{
                    toast.error(t(response.data.message))
                }
            })
        }else{
            toast.error(t("ValueNotChanged"))
        }

    }

    const Uninstall = async () => {

        const expression = "messenger"
        const shop_id =  auth?.shop.id

        const data = {
            shop_id : shop_id,
            expression : expression
        }
        await swal({
            title : t('Uninstall'),
            text : t('SureToUninstallExt'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setSoading(true)
                await axios.post(SERVER_URL+"package/uninstall",data,attachTokenToHeaders()).then((response)=>{
                    setSoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        window.location.href =  LINK_STORE_APPS
                    }else{
                        toast.error(t(response.data.message))
                    }
                })
            }
        });



    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Messenger</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{auth?.shop?.shopName}</li>
                                <li className="breadcrumb-item"><Link to={LINK_STORE_APPS}>{t('Applications')}</Link></li>
                                <li className="breadcrumb-item active">Messenger</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="offset-lg-2 col-lg-8">
                    <div className="card" id="tasksList">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title mb-0">{t('Configuration')}</h4>
                            <Link to={LINK_STORE_APPS}><i className='ri-close-fill' style={{fontSize: "15px"}}></i></Link>

                        </div>
                        <div className="card-body ">
                            {
                                loading ?
                                    <MiddleLoading/>
                                    :
                                    <div className="row">
                                        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                            {({ errors, touched }) =>
                                                <Form>
                                                    <div className="row mb-10">
                                                        <div className="col-lg-12">
                                                            <h6>{t('HowConfigMessenger')}</h6>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <ul>
                                                                <li className="pt-30">
                                                                    <b>{t('Step')} 1</b>
                                                                    <ol>
                                                                        <li>{t('GetPageIdFromThisLink')} , <a
                                                                            className="text-primary"
                                                                            href="https://www.facebook.com/help/1503421039731588">{t('FindFacebookPageId')}</a>
                                                                        </li>
                                                                    </ol>
                                                                </li>

                                                                <li className="pt-30">
                                                                    <b>{t('Step')} 2</b>

                                                                    <ol className="pt-10">
                                                                        <li>- {t('AcceedToFacebookParam')}</li>
                                                                        <li>- {t('FacebookParamSub')} <b>{t('FacebookNewPage')}</b></li>
                                                                        <li>- {t('FacebookParamSub')}  <b>{t('FacebookAdvanceMessage')}</b></li>
                                                                        <li>- {t('FacebookAddBlankDomain')}</li>
                                                                    </ol>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <br/>
                                                            <label htmlFor="pageId"
                                                                   className="form-label">{t('PageId')} <b
                                                                className="text-danger">*</b></label>
                                                            <Field className="front" id="pageId" name="pageId"/>
                                                            {errors.pageId && touched.pageId ? (
                                                                <div className='text-danger'>{errors.pageId}</div>
                                                            ) : null}
                                                        </div>

                                                    </div>
                                                    <br/>

                                                    <div className="offset-lg-4 col-lg-4">
                                                        <button className="btn btn-success btn-block btn-lg" disabled={doading}>
                                                            {
                                                                doading ?
                                                                    <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                                                    :
                                                                    <span>{t('Submit')}</span>
                                                            }

                                                        </button>
                                                    </div>


                                                </Form>
                                            }

                                        </Formik>


                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="offset-lg-2 col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-9">
                                    <p style={{paddingTop : "10px"}}>
                                        {t('UninstallExt')}
                                    </p>
                                </div>
                                <div className="col-lg-3 text-right">
                                    <button className="btn btn-outline-danger" disabled={soading} onClick={event => Uninstall()}>
                                        {
                                            loading ?
                                                <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Uninstalling')}...</>
                                                :
                                                <span>{t('Uninstall')}</span>
                                        }

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(MessengerConf));