import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {
    APPNAME,
    attachTokenToHeaders,
    changeCurrency,
    numberWithCommas,
    SERVER_URL
} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {FedaPay, PayPal, RegLoading} from "../../../../components";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link, useHistory, useLocation} from 'react-router-dom'
import {LINK_DOCS, LINK_STORE_DASHBOARD} from "../../../../routes/type";
import {loadMe} from "../../../../store/Auth/action";


const Upturn = ({t,auth}) => {

    const [step, setStep] = useState(1);
    const [loading,setLoading] = useState(true)
    const [annual, setAnnual] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);
    const [discount, setDiscount] = useState([]);
    const [refund, setRefund] = useState(0);
    const [month, setMonth] = useState(0);

const history = useHistory()


    useEffect(()=>{
        getDiscountConfig()
    },[])


    const getDiscountConfig = async () => {
        await axios.get(SERVER_URL+'shop/config/discount',attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                setDiscount(response.data.params.params.split(','))
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    const PeriodeSubscription = (month) => {
        const price = auth?.plan.price;

        setMonth(month);

        let calculatedTotalToPay = price;

        switch (month) {
            case 1:
                setAnnual(false);
                setTotalDiscount(0);
                calculatedTotalToPay = price;
                break;
            case 6:
                setAnnual(true);
                const first = ((price * discount[0]) / 100) * parseInt(month);
                setTotalDiscount(first);
                calculatedTotalToPay = (price * parseInt(month)) - first;
                break;
            case 12:
                setAnnual(true);
                const second = ((price * discount[1]) / 100) * parseInt(month);
                setTotalDiscount(second);
                calculatedTotalToPay = (price * parseInt(month)) - second;
                break;
            default:
                setAnnual(false);
                setTotalDiscount(0);
                calculatedTotalToPay = price;
        }

        setTotalToPay(calculatedTotalToPay);
        setStep(2);
    };


    const succeedPassed = async (detail,method) => {

        setLoading(true)

        const data = {
                 shop_key : auth?.shop.shop_key,
                 shop_id : auth?.shop.id,
                 month : month,
                    payment_data : {
                        payment_id : detail.id,
                        payment_method : method,
                        type : "REABONNEMENT",
                        totalAmount : totalToPay,
                    },
                  plan_id : auth?.plan?.pricing_id,
                 current_period_end : auth?.plan?.current_period_end,
                current_period_start : auth?.plan?.current_period_start
              }

        await axios.post(SERVER_URL+"upturn/saved",data,attachTokenToHeaders()).then((response)=>{
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setLoading(false)
                setStep(3)
                setTimeout(()=>{
                    loadMe()
                    window.location.href = LINK_STORE_DASHBOARD
                },2000)

            }else{
                toast.error(t(response.data.message))
            }
        })

    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Reabonnement')}</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Reabonnement')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            {
                auth?.shop.statut === 2 ?
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{t('PlanActuel')} : <b style={{color : "#fff",background : auth?.plan.color, padding : "0px 7px",borderRadius : "6px"}}>{auth?.plan?.name.toUpperCase()}</b> </h4>
                                </div>

                                <div className="card-body form-steps">
                                    <div className="vertical-navs-step">
                                        <div className="row gy-5">
                                            <div className="col-lg-12">
                                                <div className="px-lg-4">
                                                    <div className="tab-content">
                                                        <div className={`tab-pane fade ${step === 1 ? " show active" : null}`}>
                                                            <div className="row">
                                                               <div className="col-lg-12">
                                                                   <label className="option__upturn" htmlFor="opt_1">
                                                                       <p className="option--description"> 1 {t('Month')}</p>
                                                                       <p className="option--cost"></p>
                                                                       <input type="radio"  defaultChecked={month==1}  id="opt_1" name="upturn" value={month} onChange={event => PeriodeSubscription(1)}  />
                                                                   </label>

                                                                   <label className="option__upturn" htmlFor="opt_2">
                                                                       <p className="option--description"> 6 {t('Month')}</p>
                                                                       <p className="option--cost"> <small>{t('ReductionOf')} <b>{discount[0]}% </b></small></p>
                                                                       <input type="radio" id="opt_2" defaultChecked={month==6}  name="upturn" value={month} onChange={event => PeriodeSubscription(6)}  />
                                                                   </label>

                                                                   <label className="option__upturn" htmlFor="opt_3">
                                                                       <p className="option--description"> 1 {t('Annuel')}</p>
                                                                       <b className="badge badge-success">{t('BestOffer')}</b>
                                                                       <p className="option--cost"> <small>{t('ReductionOf')} <b>{discount[1]}% </b></small></p>
                                                                       <input type="radio" id="opt_3" defaultChecked={month==12}  name="upturn" value={month} onChange={event => PeriodeSubscription(12)}  />
                                                                   </label>
                                                               </div>
                                                            </div>
                                                        </div>


                                                        <div className={`tab-pane fade ${step === 2 ? " show active" : null}`}>
                                                            <div className="row">
                                                                <div className="col-lg-8">
                                                                    <ul className="list-group mb-3">

                                                                        <li className="list-group-item d-flex justify-content-between lh-sm">
                                                                            <div>
                                                                                <h6 className="my-0">{t('TypeSub')}</h6>
                                                                                <small className="text-muted">{t('MOrYear')}</small>

                                                                            </div>
                                                                            <div className="text-muted">
                                                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                                                    {month === 1 ? "Mensuel" : null}
                                                                                    {month === 6 ? "Semestre" : null}
                                                                                    {month === 12 ? "Annuel" : null}
                                                                                </div>


                                                                            </div>

                                                                        </li>
                                                                        <li className="list-group-item  lh-sm bg-soft-success">
                                                                            <small className="text-success">{t('ReductionOf')} <b>{discount[0]}% </b> {t('To')} <b>{discount[1]}%</b> {t('IfSemestrerOrAnnual')}</small>
                                                                        </li>


                                                                        { annual ?
                                                                            <li className="list-group-item d-flex justify-content-between bg-light">
                                                                                <div className="text-success">
                                                                                    <h6 className="my-0">{t('Discount')}</h6>
                                                                                </div>
                                                                                <span className="text-success"> - {numberWithCommas(totalDiscount)} Frcs</span>
                                                                            </li>
                                                                            : null}

                                                                        <li className="list-group-item d-flex justify-content-between">
                                                                            <span>Total (XOF)</span>
                                                                            <strong>{numberWithCommas(totalToPay)} Frcs</strong>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    {
                                                                        totalToPay  !== 0 ?
                                                                            <>
                                                                                <FedaPay amount={totalToPay} text="Payer avec Mobile Money"  errorHandler={(err) => console.log(err)} succeedPayment={succeedPassed}  user={auth?.me}/>
                                                                                <hr/>
                                                                                <PayPal succeedPayment={succeedPassed} total={changeCurrency((totalToPay - refund)??0,'USD')} />
                                                                            </>
                                                                            :

                                                                            <RegLoading />
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className={`tab-pane fade ${step === 3 ? " show active" : null}`}>
                                                            <div className="text-center pt-4 pb-2">

                                                                <div className="mb-4">
                                                                    <lord-icon src="https://cdn.lordicon.com/lupuorrc.json"
                                                                               trigger="loop"
                                                                               colors="primary:#0ab39c,secondary:#405189"
                                                                               style={{width:"120px",height:"120px"}}></lord-icon>
                                                                </div>
                                                                <h5>{t('SuccessSubscription')}</h5>
                                                                <p className="text-muted">{t('YouWillReceiveEmail')}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    :

                    <div className="alert alert-success" role="alert">
                        <h1 className="alert-heading text-success">{t('WelcomeDash')} {APPNAME}</h1>
                        <hr/>
                        <p>{t('DefaultStartText')}</p>
                        <br/>
                        <p>{t('RefferToDocs')} <br/>
                            <Link to={LINK_DOCS}><b style={{textDecoration : "underline"}}>{t('Docs')}</b></Link></p>
                        <br/>
                    </div>
            }


        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Upturn));
