import React, {useState} from 'react';
import {withTranslation} from "react-i18next";

const OffcanvasFilter = ({t,setOffcanvas,filterSearch,filterByBadge,filterByCategory,categories}) => {

    const [search, setSearch] = useState("")

    const searchBy = (value) => {
      setSearch(value)
     filterSearch(value)
    }

    const badges = [
        {name : 'Hot'},
        {name : 'Trending'}
    ]

    return (
        <div className="tp-filter-offcanvas-wrapper">
            <div className="tp-filter-offcanvas-close">
                <button type="button" className="tp-filter-offcanvas-close-btn filter-close-btn" onClick={event => setOffcanvas(false)} >
                    <i className="fa-solid fa-xmark"></i>
                    {t('Close')}
                </button>
            </div>
            <div className="tp-shop-sidebar">
                <div className="tp-shop-widget mb-35">
                    <h3 className="tp-shop-widget-title no-border">{t('Search')}</h3>

                    <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-filter">
                            <input type="text" className='form-control' style={{height : "50px"}} value={search} placeholder="Pull..." onChange={event => searchBy(event.target.value)}/>
                        </div>
                    </div>
                </div>

                <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">{t('FilterByBadge')}</h3>

                    <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-checkbox">
                            <ul style={{paddingLeft : "0rem"}} className="filter-items filter-checkbox">
                                {
                                    badges.map((item, key) =>
                                <li className="filter-item radio">
                                    <input id={item.name} type="radio" name="filter"  onClick={event => filterByBadge(item.name)} />
                                    <label htmlFor={item.name}>{item.name}</label>
                                </li>
                                    )
                                }

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">Categories</h3>

                    <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-categories">
                            <ul style={{paddingLeft : "0rem"}}>
                                {
                                    categories?.map((cate,key) =>

                                        <li key={key}><a className="cursor-pointer" onClick={event => filterByCategory(cate.id)} >{cate.subname} <span>{cate.count??0}</span></a></li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default withTranslation()(OffcanvasFilter)
