import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "../../admin.css"
import {CLIENT, loadScript} from "../../helpers/utils";
import { Link, Redirect } from 'react-router-dom'
import {
    LINK_CONDITIONS,
    LINK_LANDING,
    LINK_LOGIN_STORE,
    LINK_STORE_DASHBOARD,
    LINK_STORE_STARTER,
} from '../../routes/type'
import Cookies from "js-cookie";
import TwoFA from "../../components/2FA/TwoFA";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import CompletedPopover from "../../components/Popover/CompletedPopover";
import ThemeToggle from "./ThemeToggle";

const AdminLayout = ({auth,children,t}) => {

    document.querySelector("html").setAttribute("data-layout", localStorage.getItem("data-layout")??"vertical")
    document.querySelector("html").setAttribute("data-layout-style", localStorage.getItem("data-layout-style")??"default")
    document.querySelector("html").setAttribute("data-topbar", localStorage.getItem("data-topbar")??"light")
    document.querySelector("html").setAttribute("data-sidebar", localStorage.getItem("data-sidebar")??"light")
    document.querySelector("html").setAttribute("data-sidebar-size", localStorage.getItem("data-sidebar-size")??"lg")
    document.querySelector("html").setAttribute("data-sidebar-image", localStorage.getItem("data-sidebar-image")??"none")
    document.querySelector("html").setAttribute("data-preloader", localStorage.getItem("data-preloader")??"disable")
    document.querySelector("html").setAttribute("data-layout-mode", localStorage.getItem("data-layout-mode")??"light")


    useEffect(()=>{
        loadScript(CLIENT+"/admin/js/layout.js")
        loadScript(CLIENT+"/admin/libs/bootstrap/js/bootstrap.bundle.min.js")
        loadScript(CLIENT+"/admin/libs/simplebar/simplebar.min.js")
        loadScript(CLIENT+"/admin/libs/node-waves/waves.min.js")
        loadScript(CLIENT+"/admin/libs/feather-icons/feather.min.js")
        loadScript(CLIENT+"/admin/js/pages/plugins/lord-icon-2.1.0.js")
        loadScript(CLIENT+"/admin/libs/swiper/swiper-bundle.min.js")
    },[])



    if (!Cookies.get('auth-token')) return <Redirect to={LINK_LANDING} />

    if (!auth) return <Redirect to={LINK_LOGIN_STORE} />

    if (auth?.planDays <= 0 && auth?.shop.firstTime == 0) return <Redirect to={LINK_STORE_STARTER} />

     if (auth?.me?.role_id == 2 && window.location.pathname.includes("admin")) return <Redirect to={"/error/notfound"} />



    return (
        <>
            <div id="layout-wrapper" className="admin-panel">
                {
                    auth?.me?.statut === 2 ?
                        <div className="container">
                           <div className="row">
                               <div className="offset-lg-3 col-lg-6">
                                   <div className="card mt-200">
                                       <div className="card-body">
                                           <h1 className="text-center"><i className="fa fa-warning text-warning" style={{fontSize : "100px"}}></i></h1>
                                               <h1 className="text-center">Compte désactivé</h1>
                                               <p>{t('DisabledAccount1')}</p>

                                           <p>
                                               <b>{t('DisabledAccount2')}</b>
                                           </p>

                                           <p>
                                               {t('DisabledAccount3')} <Link to={LINK_CONDITIONS} className="text-primary">{t('Termsndcondition')}</Link>.

                                           </p>

                                           <p>
                                               {t('DisabledAccount4')} <a href="mailto:support@kiiako.io">support@kiiako.io</a>.
                                           </p>

                                       </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                        :
                        <>
                            {
                                auth?.me?.otp_enabled && !Cookies.get('_ot') ?

                                    <TwoFA auth={auth} />
                                    :

                                    <>
                                        <Navbar />
                                        <Sidebar />
                                        <div className="vertical-overlay"></div>
                                        <div className="main-content">
                                        <div className="page-content">
                                                <div className="container-fluid">
                                                    {children}
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            auth?.me?.role_id == 2 ?
                                                <>
                                                    {!auth?.shop?.logo ?  <CompletedPopover auth={auth} /> : null}
                                                </>
                                                :
                                                ""
                                        }
                                    </>
                            }
                        </>
                }
            </div>

            {
                auth?.me?.statut != 2 ?
                    <>
                        <div className="customizer-setting d-none d-md-block">
                            <div className="btn-primary btn-rounded shadow-lg btn btn-icon btn-lg p-2"
                                 data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas"
                                 aria-controls="theme-settings-offcanvas">
                                <i className='mdi mdi-spin mdi-cog-outline fs-22'></i>
                            </div>
                        </div>

                        <ThemeToggle />
                    </>
                    :
                    ""
            }

        </>
    );
}


const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(AdminLayout));
