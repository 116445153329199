import React, {useEffect} from 'react';
import {MiddleLoading} from "../../../../../components";
import {loadSubMe} from "../../../../../store/Subdomain/Oauth/action";
import {compose} from "redux";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {SUB_CUSTOMER_DASHBOARD} from "../../routes/type";

const SuccessLogin = ({loadSubMe}) => {
    const history = useHistory()
    useEffect(() => {
        localStorage.setItem('__S78456100__',window.location.pathname.split('/').pop())
        loadSubMe()
        history.push(SUB_CUSTOMER_DASHBOARD)
    }, []);

    return (
        <MiddleLoading />
    );
};

const mapStateToProps = (state) => ({
    auth: state.subLogin,
});

export default compose(withRouter, connect(mapStateToProps, { loadSubMe })) (withTranslation()(SuccessLogin))

