import React, {Fragment} from 'react';
import {withTranslation} from "react-i18next";
import Blocks from "./parts/Blocks";

const Section = ({section,t,setPreview}) => {

    return (
        <li className="header">
            <label className="header" htmlFor={`leftpanel_sectionhead_${section.name}`}>{t(section.name)}<div className="header-arrow"></div>
            </label>
            <input className="header_check" type="checkbox" defaultChecked={false} id={`leftpanel_sectionhead_${section.name}`} />
            <ol>
                {section.blocks.map((block,key) => <Fragment key={key}><Blocks  block={block} setPreview={setPreview} section={section} /></Fragment>)}
            </ol>
        </li>
    );
}

export default withTranslation()(Section);
