import React, {useEffect, useState} from 'react'
import { withTranslation } from 'react-i18next'
import {APPNAME, attachTokenToHeaders, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import Editor from "../../../../themes/Editor";
import axios from "axios";
import {toast} from "react-toastify";

const AdminBuilder = ({t,auth}) => {
    document.title = APPNAME+" :: "+t('Builder')

    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState(null)
    const [list, setList] = useState([])
    const [template, setTemplate] = useState(null)
    const slug = window.location.href.split('/').pop()
    useEffect(() => {
        getPageData()
    }, [slug]);

    const getPageData = async () => {
        setLoading(true)
        const slug = window.location.href.split('/').pop()
        await axios.get(SERVER_URL+"admin/template/page/"+slug,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPageData(response.data.page)
                setList(response.data.list)
                setTemplate(response.data.template)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })

    }


    const saveTemplate = async (data) => {
        const slug = window.location.href.split('/').pop()
        const content = {
            content : JSON.stringify(data)
        }
        await axios.put(SERVER_URL+"admin/template/page/edit/"+slug,content,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })
    }


    return (
        <div>
            <Editor
                auth={auth}
                loading={loading}
                list={list}
                data={pageData}
                saveTemplate={saveTemplate}
                templateName={template?.name}
            />
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(AdminBuilder));
