import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {
    APPNAME,
    attachTokenToHeaders,
    Capitalize,
    format_date,
    numberWithCommas,
    SERVER_URL
} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import Paginate from "../../../../components/Paginate/Paginate";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import { LINK_STORE_WITHDRAW} from "../../../../routes/type";
import Authorization from "../../../../components/Authorization";


const Payment = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('Payments')

    const [currentPayments,setCurrentPayments] = useState([]);
    const [payments,setPayments] = useState([]);
    const [loading,setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);

    useEffect(() => {
        getPayments(auth.shop?.id)
    },[auth])

    useEffect(() => {
        setCurrentPayments(payments?.slice(indexOfFirstPost, indexOfLastPost))
    },[payments,currentPage])

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    const getPayments = async (shop_id) => {
        await axios.get(SERVER_URL+"payments/"+shop_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPayments(response.data.payments)
                setLoading(false)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(payments?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t("Payment")}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="#">{APPNAME}</a></li>
                                <li className="breadcrumb-item active">{t("Payment")}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>



            <Authorization single={true}  trial={true}  module="payments" component={true} >
            <div className="card" id="contactList">
                <div className="card-header">
                    <div className="row align-items-center g-3">
                        <div className="col-md-3">
                            <h5 className="card-title mb-0">{t("Payment")}</h5>
                        </div>

                        {auth?.plan?.pricing_id != 1 ?
                            <div className="col-md-auto ms-auto">
                            <div className="d-flex gap-2">
                                <Link to={LINK_STORE_WITHDRAW} className="btn btn btn-primary">
                                    <i className="ri-money-euro-box-line align-bottom me-1"></i> {t("WithdrawMoney")}
                                </Link>

                            </div>
                        </div> : ""}

                    </div>

                </div>

                <div className="card-body">
                    <div className=" table-card">
                        <table className="table align-middle table-nowrap" id="customerTable">
                            <thead className="table-light text-muted">
                            <tr>
                                <th className="sort" data-sort="name" scope="col" style={{width: "60px"}}></th>
                                <th className="sort" data-sort="date" scope="col">ID</th>
                                <th className="sort" data-sort="type" scope="col">{t('Order')}</th>
                                <th className="sort" data-sort="type" scope="col">{t('PaymentMethod')}</th>
                                <th className="sort" data-sort="type" scope="col">{t('Date')}</th>
                                <th className="sort" data-sort="amount" scope="col">{t('Amount')}</th>
                                <th className="sort" data-sort="status" scope="col">Status</th>
                            </tr>

                            </thead>
                            <tbody className="list form-check-all">
                            {
                                loading ?
                                    <tr>
                                        <td colSpan="7">
                                            <p className="text-center">
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                            </p>
                                        </td>
                                    </tr>

                                    :

                                    <>
                                        {
                                            currentPayments?.length > 0
                                                ?
                                                <>
                                                    {
                                                        currentPayments?.map((payment,key)=>
                                                                     <tr key={key}>
                                                                        <td>
                                                                            <div className="avatar-xs">
                                                                                <div className="">
                                                                                    <i className="ri-arrow-left-up-fill"></i>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="id">
                                                                            <a href="#" className="fw-medium link-primary">#{payment.transaction}</a>
                                                                        </td>
                                                                        <td className="type"><Link to={`/store/order/${payment.order_id}`} className="text-muted cursor-pointer" style={{textDecoration : "underline"}}>{t('Detail')}</Link></td>
                                                                        <td className="type">{t(Capitalize(payment.payment_method.split('-')[0].toLowerCase()))} </td>
                                                                        <td className="date">{format_date(payment.created_at)}</td>
                                                                        <td>
                                                                            <h6 className="text-success mb-1 amount">+{numberWithCommas(payment.amount)} Frcs</h6>
                                                                        </td>
                                                                        <td className="status">
                                                                            <span className="badge badge-soft-success fs-11"><i className="ri-checkbox-circle-line align-bottom"></i> Succes</span>
                                                                        </td>
                                                                    </tr>

                                                        )
                                                    }
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan="7">
                                                        <p className="text-center">{t('EmptyList')}</p>
                                                    </td>
                                                </tr>
                                        }
                                    </>
                            }
                            </tbody>
                        </table>


                    </div>
                    <br/>
                    <Paginate
                        postsPerPage={postsPerPage}
                        totalPosts={payments?.length}
                        paginate={paginate}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        current={currentPage}
                    />
                </div>

            </div>
            </Authorization>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Payment));
