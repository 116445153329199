import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {flatData} from "../../utils/utils";
import {withTranslation} from "react-i18next";
import {CLIENT} from "../../../helpers/utils";

const Testimonial3 = ({block,customStyle,toggleHightLight,t,isEditor}) => {


    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(block.contentEditable[0].data)
    }, [block.contentEditable]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const testimonial3Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :testimonial3Ref?.current?.offsetTop, left : testimonial3Ref?.current?.offsetLeft, width : testimonial3Ref?.current?.offsetWidth, height : testimonial3Ref?.current?.offsetHeight,nodeName : testimonial3Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    return (
        <section className="tp-testimonial-area pt-65 pb-15"
                 ref={testimonial3Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true) }
                 onMouseLeave={() => isEditor && getDimension(block,false) }
                 style={customStyle}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="tp-testimonial-slider-wrapper-5">
                            <div className="row">
                                <div className="col-xl-7 offset-xl-3">
                                    <div className="tp-section-title-wrapper-5 mb-45">
                                 <span className="tp-section-title-pre-5">
                                    Customer Reviews
                                    <svg width="82" height="22" viewBox="0 0 82 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M81 14.5798C0.890564 -8.05914 -5.81154 0.0503902 5.00322 21" stroke="currentColor" stroke-opacity="0.3" stroke-width="2" stroke-miterlimit="3.8637" stroke-linecap="round"></path>
                                    </svg>
                                 </span>
                                        <h3 className="tp-section-title-5">Our Happy Customers</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="tp-testimonial-slider-5 p-relative">
                                <div className="tp-testimonial-slider-active-5 swiper-container pb-15 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                                    <div className="swiper-wrapper"  >
                                        <Swiper
                                            grabCursor={true}

                                            autoplay={{
                                                delay : 1000,
                                                disableOnInteraction : true
                                            }}
                                            loop={true}
                                            slidesPerView={1}
                                            spaceBetween={20}
                                            modules={[Pagination,Navigation,Autoplay]}
                                            pagination={{
                                                el : '.tp-testimoinal-slider-dot-3' , clickable : true
                                            }}
                                            navigation={{
                                                nextEl : ".tp-testimonial-slider-5-button-next",
                                                prevEl : ".tp-testimonial-slider-5-button-prev",
                                                clickable : true
                                            }}
                                        >
                                            {
                                                items?.map((item,key) =>
                                                  <SwiperSlide key={key}>
                                                      <div className="tp-testimonial-item-5 d-md-flex swiper-slide white-bg">
                                                          <div className="tp-testimonial-avater-wrapper-5 p-relative">
                                                              <div className="tp-avater-rounded mr-60">
                                                                  <div className="tp-testimonial-avater-5 ">
                                                                      <img src={flatData(item,"customerPic")} alt=""/>
                                                                  </div>
                                                              </div>
                                                              <span className="quote-icon">
                                                                  <img src={CLIENT+"/builder/testimonial/testimonial-quote-2.png"} alt=""/>
                                                               </span>
                                                          </div>

                                                          <div className="tp-testimonial-content-5">
                                                              <div className="tp-testimonial-rating tp-testimonial-rating-5">
                                                                  {
                                                                      [...Array(flatData(item,"rate"))].map((_,cc)=>
                                                                          <span key={cc}><i className="fa-solid fa-star"></i></span>
                                                                      )
                                                                  }
                                                              </div>

                                                              <p>
                                                                  {flatData(item,"message")}
                                                              </p>

                                                              <div className="tp-testimonial-user-5-info">
                                                                  <h3 className="tp-testimonial-user-5-title">{flatData(item,"customerName")}</h3>
                                                                  <span className="tp-testimonial-user-5-designation">{flatData(item,"customerRole")}</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </SwiperSlide>

                                                )
                                            }
                                        </Swiper>
                                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                <div className="tp-testimonial-arrow-5">
                                    <button type="button" className="tp-testimonial-slider-5-button-prev" tabIndex="0" aria-label="Previous slide" aria-controls="swiper-wrapper-da0168c103d716a53">
                                        <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.10059 7.97559L32.1006 7.97559" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M8.15039 0.999999L1.12076 7.99942L8.15039 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                    <button type="button" className="tp-testimonial-slider-5-button-next" tabIndex="0" aria-label="Next slide" aria-controls="swiper-wrapper-da0168c103d716a53">
                                        <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31.1006 7.97559L1.10059 7.97559" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M25.0508 0.999999L32.0804 7.99942L25.0508 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default withTranslation()(Testimonial3)
