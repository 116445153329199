import React, {useEffect, useRef, useState} from 'react';
import SellerLayout from "../../components/SellerLayout";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../../../../helpers/utils";
import _Chat from "./_Chat";
import {compose} from "redux";
import {connect} from "react-redux";
import socket from "../../../../../helpers/services/BroadCasts";
import notificationSound from "../../../../../assets/notifications/message.wav";
import {toast} from "react-toastify";
import {Alert} from "reactstrap";
import {useHistory} from "react-router-dom";

const SupportConversation = ({t,auth}) => {

    const hash_key = window.location.pathname.split('/').pop();
    const [messages, setMessages] = useState([])
    const [detail, setDetail] = useState({})
    const [mloading,setMloading] = useState(false)
    const [answer, setAnswer] = useState("");
    const dummy = useRef();
    const history = useHistory();

     useEffect(() =>{
      getConversation()
     },[])


    const getConversation = async () => {
         await axios.get(SERVER_URL+"ticket/detail/"+hash_key,attachTokenToHeaders(false,true))
             .then((response) =>{
                 if (response.data.succeed){
                     setMessages(response.data.messages)
                     setDetail(response.data.detail[0])
                 }
             })
    }


    useEffect(() => {
        socket.on("support_message_customer_"+auth?.user.id, data => {
            if (data.toUser_id == auth?.user.id) {
                getConversation()
                const  audio = new Audio(notificationSound)
                audio.play().catch(function (error) {
                    console.log(error)});
                toast.info(t('NewMessage'))
                dummy.current.scrollIntoView({ behavior: 'smooth' });
            }else {
               getConversation()
            }

        });

        return () => socket.disconnect();
    }, []);



    const handleChatmessage = async (e) => {
        e.preventDefault()

        const data = {
            message : answer,
            user_id : auth?.user?.id,
            shop_id : detail?.shop_id,
            ticket_hash : detail?.hash_key,
            toUser_id : detail.customer_id,
            type : "TEXT",
            customer : true,
            support_shop : true,
            channel_key : window.location.pathname.split('/').pop(),
            lng : localStorage.getItem('I18N_LANGUAGE')
        }
        setMloading(true)

        await axios.post(SERVER_URL+"message/add",data,attachTokenToHeaders(false,true))
            .then((response)=>{
            setMloading(false)
            if (response.data.succeed){
                setAnswer("")
                getConversation()

                dummy.current.scrollIntoView({ behavior: 'smooth' });

            }else{
                toast.error(t(response.data.message))
            }
        }).catch((error)=>{
            setMloading(false)
        })



    }




    return (
        <SellerLayout>

            <div className="container mt-100">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10">
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="cursor-pointer" onClick={event => history.goBack()}><i className="ri-arrow-left-line"></i> {t('Back')}</p>
                                <div style={{paddingLeft : "7px"}}>
                                    <h3>Sujet : {detail?.title}</h3>
                                    <p>{detail?.description}</p>
                                    {detail?.statut == 1 ?  <span className="badge badge-soft-info text-uppercase">{t('New')}</span> : null}
                                    {detail?.statut == 2 ?  <span className="badge badge-soft-success text-uppercase">{t('Open')}</span> : null}
                                    {detail?.statut == 3 ?  <span className="badge badge-soft-warning text-uppercase">{t('Inprogress')}</span> : null}
                                    {detail?.statut == 4 ?  <span className="badge badge-soft-dangertext-uppercase">{t('Closed')}</span> : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                messages?.length > 0 ?
            <div className="container mt-40 mb-50">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10">
                        <section className="msger">
                            <header className="msger-header">
                                <div className="msger-header-title">
                                 Chat
                             </div>
                         </header>

                         <main className="msger-chat">

                                         {
                                             messages.map((message,key)=>
                                                 <div key={key}>
                                                     <_Chat auth={auth} data={message}  />
                                                 </div>
                                             )
                                         }

                             <span ref={dummy} style={{paddingTop : "80px"}}></span>


                         </main>

                         <form className="msger-inputarea" onSubmit={handleChatmessage} >
                             <input type="text"   onChange={event => setAnswer(event.target.value)}
                                    value={answer} className="msger-input" placeholder="Enter your message..."/>
                             <button type="submit" className="msger-send-btn">
                                 {
                                     mloading ?
                                         <span
                                             className="spinner-border spinner-border-sm"
                                             role="status"
                                             aria-hidden="true"/>
                                         :
                                         <i className="ri-arrow-right-fill"></i>
                                 }
                             </button>
                         </form>
                     </section>
                 </div>
             </div>
         </div>

                    : <div className={"container mt-60 mb-60"}>
                     <div className="offset-lg-1 col-lg-10">
                         <Alert>
                             {t('WaitingForAnswer')}
                         </Alert>
                     </div>
                    </div>

            }
        </SellerLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.subLogin,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(SupportConversation));
