import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../../utils/utils";

const IFrame = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const iframe = useRef()

    const getBlockSize = () => {
        setDimension({top :iframe?.current?.offsetTop, left : iframe?.current?.offsetLeft, width : iframe?.current?.offsetWidth, height : iframe?.current?.offsetHeight,nodeName : iframe?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);

    return(

        <section className="pt-10 pb-10"
                 style={customStyle}
        >
            <div className="container "
                 ref={iframe}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
            >
                <iframe allowpaymentrequest={true} src={flatData(block.contentEditable,"link")} style={{height :flatData(block.contentEditable,'height')+"px",
                 width : flatData(block.contentEditable,'width')+"px"
                }}
                        height={flatData(block.contentEditable,'height')} width={flatData(block.contentEditable,'width')}
                        frameborder="0"></iframe>
            </div>
        </section>
    )
}

export default IFrame;