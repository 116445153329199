import {
    LOAD_HOME_LOADING,LOAD_HOME_SUCCESS,LOAD_HOME_FAIL
} from './type';

const initialState = {
    isLoading: false,
     error: null,
     categories : [],
    message : null
};


 const  HomeReducer = (state = initialState, { type, payload })  =>{
    switch (type) {
        case LOAD_HOME_LOADING:
            return {
                ...state,
                isLoading: true,
                error: null,
            };

        case LOAD_HOME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                categories: payload.categories,
                message : payload.message,
                error: null,
            };

        case LOAD_HOME_FAIL:
            return {
                ...state,
                error: payload.error,
            };


        default:
            return state;
    }
}

export default HomeReducer