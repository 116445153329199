import React, {useEffect, useRef, useState} from 'react';
import {flatData} from "../../utils/utils";
import {withTranslation} from "react-i18next";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {SwiperSlide,Swiper} from "swiper/react";

const Teams2 = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {
    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const team2 = useRef()

    const getBlockSize = () => {
        setDimension({top :team2?.current?.offsetTop, left : team2?.current?.offsetLeft, width : team2?.current?.offsetWidth, height : team2?.current?.offsetHeight,nodeName : team2?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);
    return (
        <section className="container"
                 style={customStyle}
                 ref={team2}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
        >
            <div className="team2">
                <div className="responsive-container-block outer-container">
                    <div className="responsive-container-block inner-container">
                        <p className="text-blk section-head-text">
                            {t('OurTeam')}
                        </p>
                        <p className="text-blk section-subhead-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna
                        </p>
                        <div className="responsive-container-block">
                            <div className="swiper team-swiper">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        loop={true}
                                        modules={[Autoplay,Pagination,Navigation]}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }
                                        }
                                        slidesPerView={items.length > 3 ? 3 : 2}
                                        pagination={{
                                            el : '.swiper-pagination' , clickable : true
                                        }}
                                    >


                                        {items?.map((item,key) =>
                                            <SwiperSlide key={key}>
                                               <div className="swiper-slide">
                                                    <div className="card">
                                                        <div className="img-wrapper">
                                                            <img src={flatData(item,'image')} alt=""/>
                                                        </div>
                                                        <div className="card-content">
                                                            <p className="text-blk name">
                                                                {flatData(item,'fullname')}
                                                            </p>
                                                            <p className="text-blk position">
                                                                {flatData(item,'title')}
                                                            </p>
                                                            <p className="text-blk testimonial">
                                                                {flatData(item,'desc')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )}


                                    </Swiper>
                                </div>
                                <div className="swiper-pagination container-block"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default withTranslation()(Teams2)
