import React, {useState} from 'react';
import './filepicker.css'
import Media from "../../themes/forms/Media";


const PicturePicker = ({defaultValue = "",handleInput,content}) => {

    const [open, setOpen] = useState(false)

    return (
       <div>
           <div className="filepicker__form__element">
               {/*<input type="file" id="filepicker__input" accept="image/*"/>*/}
               <label htmlFor="filepicker__input" id="filepicker__file__preview" onClick={event => setOpen(true)}>
                   <img src={defaultValue} alt=""/>
                   <div className="filepicker__overlay">
                       <span>{defaultValue.split('/').pop()}</span>
                   </div>
               </label>

           </div>

           <Media open={open} setOpen={setOpen} handleInput={handleInput} content={content} />
       </div>

    )
}

export default PicturePicker;
