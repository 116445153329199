import React from 'react';
import { Capitalize, CheckPermissions } from "../helpers/utils";
import { compose } from "redux";
import { connect } from "react-redux";
import Authorized from "./Authorized/Authorized";

const AdminAuthorization = ({ module, auth, children, single = true, bloc = [], component = false, type }) => {
    const { me, permissions } = auth || {};
    const hasRole = me?.gest_role;
    console.log(hasRole)

    if (hasRole) {
        const userPermissions = JSON.parse(permissions?.content || '{}');
        const isAuthorized = CheckPermissions(userPermissions, module, single, bloc, type);

        if (isAuthorized) {
            return children;
        } else {
            return component ? <Authorized /> : null;
        }
    }else{
        return children
    }

    return component ? <Authorized /> : null;
};

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default compose(
    connect(mapStateToProps)
)(AdminAuthorization);
