import React from 'react';
import {useDrag} from "react-dnd";
import itemTypes from "../../config/config.type";
import {CLIENT, sha1_random} from "../../../helpers/utils";

const Components = ({component,section}) => {


    const [{ isDragging }, drag] = useDrag({
        type: itemTypes.CARD,
        item: {
            id : component?.id+sha1_random(4),
            type:  section?.category,
            component: component?.component,
            name : component?.name,
            contentEditable : component?.contentEditable,
            data : component?.data
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
         <li
             ref={drag}
             data-type={`${section.name.toLowerCase()}/${component.name.toLowerCase()}`}
             data-section={section.name}
            style={{backgroundImage: `url('${component.picture}`, backgroundRepeat: "no-repeat"}}>
            <span>{component.name}</span>
        </li>
    )

}

export default Components;