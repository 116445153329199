import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {flatData} from "../../utils/utils";
import {withTranslation} from "react-i18next";
import {CLIENT} from "../../../helpers/utils";

const Testimonial2 = ({block,customStyle,toggleHightLight,t,isEditor}) => {


    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(block.contentEditable[0].data)
    }, [block.contentEditable]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const testimonial2Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :testimonial2Ref?.current?.offsetTop, left : testimonial2Ref?.current?.offsetLeft, width : testimonial2Ref?.current?.offsetWidth, height : testimonial2Ref?.current?.offsetHeight,nodeName : testimonial2Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    return (
        <section className="tp-testimonial-area pt-115 pb-100"
                 ref={testimonial2Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true) }
                 onMouseLeave={() => isEditor && getDimension(block,false) }
                 style={customStyle}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-section-title-wrapper-3 mb-45 text-center">
                            <span className="tp-section-title-pre-3">{t('CustomersReview')}</span>
                            <h3 className="tp-section-title-3">{t('WhatOurClientsSay')}</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-testimonial-slider-3">
                            <div className="tp-testimoinal-slider-active-3 swiper-container">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        grabCursor={true}
                                       
                                        autoplay={{
                                            delay : 100
                                        }}
                                        slidesPerView={items.length > 1 ? 2 : "auto"}
                                        spaceBetween={20}
                                        modules={[Pagination,Navigation]}
                                        pagination={{
                                            el : '.tp-testimoinal-slider-dot-3' , clickable : true
                                        }}
                                        navigation={{
                                            nextEl : ".tp-testimonial-slider-button-next",
                                            prevEl : ".tp-testimonial-slider-button-prev",
                                            clickable : true
                                        }}
                                    >
                                        {
                                            items?.map((item,key) =>
                                                <Fragment key={key}>
                                                    <SwiperSlide>
                                                       <div className="tp-testimonial-item-3 swiper-slide grey-bg-7 p-relative z-index-1" style={{width : "100%"}}>
                                                            <div className="tp-testimonial-shape-3">
                                                                <img className="tp-testimonial-shape-3-quote" src={CLIENT+"/builder/testimonial/testimonial-quote.png"} alt=""/>
                                                            </div>
                                                            <div className="tp-testimonial-rating tp-testimonial-rating-3">
                                                                {
                                                                    [...Array(flatData(item,"rate"))].map((_,cc)=>
                                                                        <span key={cc}><i className="fa-solid fa-star"></i></span>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="tp-testimonial-content-3">
                                                                <p>{flatData(item,"message")}</p>
                                                            </div>
                                                            <div className="tp-testimonial-user-wrapper-3 d-flex">
                                                                <div className="tp-testimonial-user-3 d-flex align-items-center">
                                                                    <div className="tp-testimonial-avater-3 mr-10">
                                                                        <img src={flatData(item,"customerPic")} alt=""/>
                                                                    </div>
                                                                    <div className="tp-testimonial-user-3-info tp-testimonial-user-translate">
                                                                        <h3 className="tp-testimonial-user-3-title">{flatData(item,"customerName")}  /</h3>
                                                                        <span className="tp-testimonial-3-designation">{flatData(item,"customerRole")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </Fragment>
                                            )
                                        }
                                    </Swiper>

                                </div>
                            </div>
                            <div className="tp-testimoinal-slider-dot-3 tp-swiper-dot-border text-center mt-50"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
)
}

export default withTranslation()(Testimonial2)
