import React, {useRef, useState} from 'react';
import * as Yup from "yup";
import axios from "axios";
import {SERVER_URL} from "../../../helpers/utils";
import {toast} from "react-toastify";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import {flatData} from "../../utils/utils";

const Contact = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const contactRef = useRef()

    const getBlockSize = () => {
        setDimension({top :contactRef?.current?.offsetTop, left : contactRef?.current?.offsetLeft, width : contactRef?.current?.offsetWidth, height : contactRef?.current?.offsetHeight,nodeName : contactRef?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    const  [loading,setLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        fullname: Yup.string()
            .required(t('FullnameRequired')),
        email: Yup.string()
            .required(t('EmailRequired')),
        sujet: Yup.string()
            .required(t('SubjectRequired')),
        message: Yup.string()
            .required(t('MessageRequired')),
    });

    const initialValues = {
        fullname: '',
        email: '',
        sujet: '',
        message: '',
        shop_email : shop_data?.email
    }

    const handleSubmit = async (values,{resetForm}) => {
       if (isEditor){
       }else{
           setLoading(true)
           await axios.post(SERVER_URL+"/subdomain/contact/message",values).then((response)=>{
               setLoading(false)
               if (response.data.succeed){
                   resetForm({values : ""})
                   toast.success(t(response.data.message))
               }else{
                   toast.error(t(response.data.message))
               }
           })
       }
    }


    return (
        <section className="tp-contact-area pt-65 pb-15"
                 ref={contactRef}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
                 style={customStyle}
        >
            <div className="container">
                <div className="tp-contact-inner">
                    <div className="row">
                        <div className="col-xl-9 col-lg-8">
                            <div className="tp-contact-wrapper">
                                <h3 className="tp-contact-title">{t('DoYouHaveQuestions')}</h3>

                                <div className="tp-contact-form">
                                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                        {({ errors, touched }) =>
                                            <Form id="contact-form">
                                                <div className="tp-contact-input-wrapper">
                                                    <div className="tp-contact-input-box">
                                                        <div className="tp-contact-input">
                                                            <Field type="text" id="name" placeholder={t('Fullname')} name="fullname" />
                                                        </div>
                                                        <div className="tp-contact-input-title">
                                                            <label htmlFor="name">{t('Fullname')}</label>
                                                        </div>

                                                        {errors.fullname && touched.fullname ? (
                                                            <small className='text-danger'>{errors.fullname}</small>
                                                        ) : null}
                                                    </div>
                                                    <div className="tp-contact-input-box">
                                                        <div className="tp-contact-input">
                                                            <Field type="email" id="email" placeholder="mail@example.com" name="email" />
                                                        </div>
                                                        <div className="tp-contact-input-title">
                                                            <label htmlFor="email">{t('Email')}</label>
                                                        </div>
                                                        {errors.email && touched.email ? (
                                                            <small className='text-danger'>{errors.email}</small>
                                                        ) : null}
                                                    </div>
                                                    <div className="tp-contact-input-box">
                                                        <div className="tp-contact-input">
                                                            <Field type="text" id="subject" placeholder={t('SubjectPlaceholder')}  name="sujet"/>
                                                        </div>
                                                        <div className="tp-contact-input-title">
                                                            <label htmlFor="subject">{t('Subject')}</label>
                                                        </div>
                                                        {errors.sujet && touched.sujet ? (
                                                            <small className='text-danger'>{errors.sujet}</small>
                                                        ) : null}
                                                    </div>
                                                    <div className="tp-contact-input-box">
                                                        <div className="tp-contact-input">
                                                            <Field as="textarea" id="message" placeholder="Message..." name="message" />
                                                        </div>
                                                        <div className="tp-contact-input-title">
                                                            <label htmlFor="message">Message</label>
                                                        </div>
                                                        {errors.message && touched.message ? (
                                                            <small className='text-danger'>{errors.message}</small>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                {/*<div className="tp-contact-suggetions mb-20">*/}
                                                {/*    <div className="tp-contact-remeber">*/}
                                                {/*        <input id="remeber" type="checkbox"/>*/}
                                                {/*            <label htmlFor="remeber">Save my name, email, and website in this browser for the next time I comment.</label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="tp-contact-btn">
                                                    <button type="submit">
                                                        {
                                                            loading ?
                                                                <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>{t('Loading')}...</>
                                                                :
                                                                <span>{t('Send')} message</span>
                                                        }
                                                    </button>
                                                </div>
                                            </Form>
                                        }

                                    </Formik>
                                    <p className="ajax-response"></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4">
                            <div className="tp-contact-info-wrapper">
                                <div className="tp-contact-info-item">
                                    <div className="tp-contact-info-content">
                                        <p data-info="mail"><a href={flatData(block.contentEditable,"email")}>{flatData(block.contentEditable,"email")}</a></p>
                                        <p data-info="phone"><a href={"tel:"+flatData(block.contentEditable,"email")}>{flatData(block.contentEditable,"phone")}</a></p>
                                    </div>
                                </div>
                                <div className="tp-contact-info-item">
                                    <div className="tp-contact-info-content">
                                        <p>
                                            <a href="#" target="_blank">
                                                {flatData(block.contentEditable,"address")}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="tp-contact-info-item">
                                    <div className="tp-contact-info-content">
                                        <div className="tp-contact-social-wrapper mt-5">
                                            <h4 className="tp-contact-social-title">{t('FindOnMedia')}</h4>

                                            <div className="tp-contact-social-icon">
                                                {flatData(block.contentEditable,'show_facebook') && <a href={flatData(block.contentEditable,'facebook')} target='_blank' style={{marginLeft : "10px"}}><i className="fa-brands fa-facebook-f"></i></a>}
                                                {flatData(block.contentEditable,'show_x') && <a href={flatData(block.contentEditable,'x')} target='_blank' style={{marginLeft : "10px"}}><i className="fa-brands fa-twitter"></i></a>}
                                                {flatData(block.contentEditable,'show_instagram') && <a href={flatData(block.contentEditable,'instagram')} target='_blank' style={{marginLeft : "10px"}}><i className="fa-brands fa-instagram"></i></a>}
                                                {flatData(block.contentEditable,'show_linkedin') && <a href={flatData(block.contentEditable,'linkedin')} target='_blank' style={{marginLeft : "10px"}}><i className="fa-brands fa-linkedin-in"></i></a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withTranslation()(Contact)
