import React, {useEffect, useState} from 'react';
import SellerLayout from "../components/SellerLayout";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    Capitalize,
    checkCurrentUrlSubdomain,
    CLIENT,
    numberWithCommas,
    SERVER_IMG_URL,
    SERVER_URL,
} from '../../../../helpers/utils'
import {withTranslation} from "react-i18next";
import {SUB_CATEGORY, SUB_INDEX} from "../routes/type";
import axios from "axios";
import { Fade } from 'react-awesome-reveal'
import { MiddleLoading } from '../../../../components'
import GroceryCard from '../components/Cards/GroceryCard'
import DefaultCard from '../components/Cards/DefaultCard'
import OldCard from '../components/Cards/OldCard'
import EmeraudeCard from '../components/Cards/EmeraudeCard'
import { secondCalculDicount } from '../../../../themes/utils/utils'
import ShopPaginate from '../../../../components/Paginate/ShopPaginate'
import QuickView from '../../../../components/Card/QuickView'
import { toast } from 'react-toastify'

const Subcategories = ({t}) => {
    const params = useParams()
    document.title = Capitalize(window.location.host.split(".")[0])+" :: "+params?.category

    const [slice, setSlice] = useState(4)
    const [categories, setCategories] = useState({})
    const [subcategories, setSubcategories] = useState([])

    const [shop, setShop] = useState({});

    const history = useHistory()


    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState([])
    const [type, setType] = useState("grid")
    const [currentProducts,setCurrentProducts] = useState([]);
    const [products, setProducts] = useState([])
    const [subcategory, setSubcategory] = useState({})
    const [item, setItem] = useState({})
    const [open, setOpen] = useState(false)
    const [sorting, setSorting] = useState("default")



    const [className, setClassName] = useState("row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1");


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(15);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentProducts(products?.slice(indexOfFirstPost, indexOfLastPost))
    },[products])


    useEffect(() => {
        setCurrentProducts(products?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(products?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(()=>{
        checkSubdomain()

    },[params.category])



    const checkSubdomain = async () => {
        const content = checkCurrentUrlSubdomain()
        await axios.post(SERVER_URL+"check/domain",content).then((response)=>{
            if (response.data.succeed){
                setShop(response.data)
                getSubcategories(response.data)
                getPageData(response.data)

            }
        })
    }


    const getProductBycategory = async (category_id) => {
        await axios.get(SERVER_URL+"seller/products/category/"+category_id).then((response)=>{
            if (response.data.succeed){
                setProducts(response.data.products)
            }
            setLoading(false)
        })
    }



    const getSubcategories = async (shop) => {
        await axios.get(SERVER_URL+"public/subcategories/"+params.category).then((response)=>{
            if (response.data.succeed){
                setCategories(response.data.category)
                setSubcategories(response.data.subcategories)
                getProductBycategory(response.data.category?.id)
            }
        })
    }

    const gotoSub = (slug) => {
      return history.push("/"+params.category+"/"+slug)
    }


    const getPageData = async (data) => {
        setLoading(true)
        await axios.get(SERVER_URL+"store/template/page/"+data?.shop.shop_key+"/Shop").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                const data = response?.data?.page?.content
                if (data)  {
                    setPageData(JSON.parse(data))

                    switch (JSON.parse(data).card){
                        case "grocery":
                            setClassName("row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1");
                            break;
                        case "emeraude" :
                            setClassName("row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1")
                            break;
                        default :
                            setClassName("row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1")

                    }
                }


            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        })

    }


    return (
       <Fade>
           <SellerLayout>
               <section className="breadcrumb__area include-bg pt-80 pb-50">
                   <div className="container">
                       <div className="row">
                           <div className="col-xxl-12">
                               <div className="breadcrumb__content p-relative z-index-1">
                                   <h2  style={{fontSize : "40px"}}>{categories?.category}</h2>
                                   <div className="breadcrumb__list">
                                       <span><Link to={SUB_INDEX}>{t('Home')}</Link></span>
                                       <span><Link to={SUB_CATEGORY}>{t('Categories')}</Link></span>
                                       <span>{categories?.category}</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>
               <section className="tp-category-area pb-120">
                   <div className="container">
                       <div className="">
                           {
                               subcategories?.length > 0 ?
                                   <div className="card__container row">
                                       {
                                           subcategories?.slice(0,slice).map((sub,key) =>
                                               <article className="card__article col-lg-3 mt-20" key={key} onClick={event => gotoSub(sub.slug)}>
                                                   <img src={sub.image ? SERVER_IMG_URL+shop.shop.shop_key+"/subcategories/thumb/"+sub.image : CLIENT+"/builder/default-image.jpg"} alt="image" className="card__img"/>

                                                       <div className="card__data">
                                                           <span className="card__description">{sub.subname}</span>
                                                           <h2 className="card__title">{sub.count??0} {t('Products')}</h2>
                                                           <Link to={"/"+params.category+"/"+sub.slug} className="card__button"
                                                            style={{color : shop?.shop.color}}
                                                           >{t('SeeMore')}</Link>
                                                       </div>
                                               </article>
                                           )
                                       }
                                   </div>
                                   :
                                   <div className="row">
                                       {
                                           products.length === 0 ?
                                               <div className="">
                                                   <div className="u-s-p-y-60">
                                                       <div className="section__content">
                                                           <div className="container">
                                                               <div className="row">
                                                                   <div className="col-lg-12 col-md-12 u-s-m-b-30">
                                                                       <div className="empty">
                                                                           <div className="empty__wrap">
                                                                               <span
                                                                                   className="empty__big-text">{t('Empty')}</span>
                                                                               <span
                                                                                   className="empty__text-1">{t('AllListEmpty')}</span>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>

                                               </div>

                                               :
                                               ""
                                       }
                                   </div>
                           }

                       </div>
                       {
                           subcategories?.length > slice ?
                               <div className="row">
                                   <div className="col-xl-12">
                                       <div className="tp-category-main-result text-center mb-35 mt-35">
                                           <p>{t('Showing')} {subcategories?.length > slice ? slice : 0} {t('Subcategories').toLowerCase()} {t('On').toLowerCase()} {subcategories?.length} </p>
                                           <div className="tp-category-main-result-bar">
                                               <span data-width="40%"></span>
                                           </div>
                                       </div>
                                       {
                                           slice < subcategories.length ?
                                               <div className="tp-category-main-more text-center cursor-pointer">
                            <span onClick={event => setSlice(slice + 3)} className="tp-load-more-btn">
                              {t('LoadMore')}
                            </span>
                                               </div>
                                               : ""
                                       }
                                   </div>
                               </div>

                               : ""
                       }

                   </div>
               </section>


               {
                   products.length > 0 ?
                       <Fade>
                           <main>

                               {
                                   loading ?
                                       <MiddleLoading />
                                       :
                                       <section className="tp-shop-area pb-120 tp-shop-full-width-padding">
                                           <div className="row">
                                               <div className="tp-section-titleWrapper-6 text-center mb-40">
                                                <span className="tp-section-title-pre-6"
                                                      style={{ color: shop?.shop.color }}>{t('CategoryProducts')}</span>
                                                   <h3 className="tp-section-title-6">{t('List')} {t('Products')}</h3>
                                               </div>
                                           </div>
                                           <div  className={pageData.page_layout === 'fullwidth' ? 'container-fluid' : 'container'}>
                                               <div className="row">
                                                   <div className="col-xl-12">
                                                       <div className="tp-shop-main-wrapper">

                                                           <div className="tp-shop-items-wrapper tp-shop-item-primary">
                                                               <div className="tab-content" id="productTabContent">
                                                                   <div
                                                                       className={`tab-pane fade ${type === 'grid' ? 'show active' : ''}`}>
                                                                       <div className={className}>
                                                                           {
                                                                               currentProducts?.length > 0
                                                                                   ?
                                                                                   <>
                                                                                       {
                                                                                           currentProducts?.map((product, key) =>
                                                                                               <div className="col"
                                                                                                    key={key}>
                                                                                                   {pageData?.card === 'grocery' &&
                                                                                                       <GroceryCard
                                                                                                           item={product}
                                                                                                           server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                           color={shop?.shop.color} />}
                                                                                                   {pageData?.card === 'default' || !pageData ?
                                                                                                       <DefaultCard
                                                                                                           item={product}
                                                                                                           server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                           setItem={setItem}
                                                                                                           setOpen={setOpen}
                                                                                                           color={shop?.shop.color} /> : ''}
                                                                                                   {pageData?.card === 'defaultWithoutBord' &&
                                                                                                       <OldCard
                                                                                                           item={product}
                                                                                                           server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                           setItem={setItem}
                                                                                                           setOpen={setOpen}
                                                                                                           color={shop?.shop.color} />}
                                                                                                   {pageData?.card === 'emeraude' &&
                                                                                                       <EmeraudeCard
                                                                                                           item={product}
                                                                                                           server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                           setItem={setItem}
                                                                                                           setOpen={setOpen} />}
                                                                                                   {
                                                                                                       !pageData?.card &&
                                                                                                       <DefaultCard
                                                                                                           item={product}
                                                                                                           server={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/thumb/'}
                                                                                                           setItem={setItem}
                                                                                                           setOpen={setOpen}
                                                                                                           color={shop?.shop.color} />
                                                                                                   }
                                                                                               </div>,
                                                                                           )
                                                                                       }
                                                                                   </>
                                                                                   :

                                                                                   <div className="container">
                                                                                       <div className="row">
                                                                                           <div
                                                                                               className="col-lg-12 col-md-12">
                                                                                               <div className="empty">
                                                                                                   <div
                                                                                                       className="empty__wrap">
                                                                                        <span
                                                                                            className="empty__big-text">{t('Empty')}</span>
                                                                                                       <span
                                                                                                           className="empty__text-1">{t('AllListEmpty')}</span>
                                                                                                   </div>
                                                                                               </div>
                                                                                           </div>
                                                                                       </div>

                                                                                   </div>
                                                                           }

                                                                       </div>
                                                                   </div>
                                                                   <div
                                                                       className={`tab-pane fade ${type === 'list' ? 'show active' : ''}`}>
                                                                       <div
                                                                           className="tp-shop-list-wrapper tp-shop-item-primary mb-70">
                                                                           <div className="row justify-content-center">
                                                                               <div className="col-xl-8">
                                                                                   {
                                                                                       currentProducts?.length > 0
                                                                                           ?
                                                                                           <>
                                                                                               {
                                                                                                   currentProducts?.map((product, key) =>
                                                                                                       <div
                                                                                                           className="tp-product-list-item d-md-flex"
                                                                                                           key={key}>
                                                                                                           <div
                                                                                                               className="tp-product-list-thumb pRelative fix">
                                                                                                               <Link
                                                                                                                   to={'/product/detail/' + product.hash_key}>
                                                                                                                   <img
                                                                                                                       src={SERVER_IMG_URL + shop?.shop?.shop_key + '/products/' + JSON.parse(product.pictures)[0]}
                                                                                                                       style={{
                                                                                                                           width: '350px',
                                                                                                                           height: '310px',
                                                                                                                           border: '1px solid #ccc',
                                                                                                                           borderRight: '0',
                                                                                                                           padding: '10px',
                                                                                                                       }}
                                                                                                                       alt="" />
                                                                                                               </Link>
                                                                                                           </div>
                                                                                                           <div
                                                                                                               className="tp-product-list-content">
                                                                                                               <div
                                                                                                                   className="tp-product-content-2 pt-15">
                                                                                                                   <div
                                                                                                                       className="tp-product-tag-2">
                                                                                                                       <Link
                                                                                                                           to={'/'}>{product.category}</Link>
                                                                                                                   </div>
                                                                                                                   <h3 className="tp-product-title-2">
                                                                                                                       <Link
                                                                                                                           to={''}>{product.title}</Link>
                                                                                                                   </h3>
                                                                                                                   <div
                                                                                                                       className="tp-product-rating-icon tp-product-rating-icon-2">

                                                                                                                       {
                                                                                                                           product.rating ?
                                                                                                                               <>
                                                                                                                                   {
                                                                                                                                       [...Array(parseInt(product.rating))].map((item, key) => (
                                                                                                                                           <span
                                                                                                                                               key={key}><i
                                                                                                                                               className="fa-solid fa-star"></i></span>
                                                                                                                                       ))
                                                                                                                                   }
                                                                                                                               </>
                                                                                                                               :
                                                                                                                               ''
                                                                                                                       }
                                                                                                                   </div>
                                                                                                                   {
                                                                                                                       product?.discount &&
                                                                                                                       <>
                                                                                                                           {
                                                                                                                               parseInt(product?.discount) > 0 ?
                                                                                                                                   <div
                                                                                                                                       className="tp-product-details-price-wrapper mb-20">
                                                                                                                        <span
                                                                                                                            className="tp-product-details-price old-price">{numberWithCommas(product?.price)} Francs CFA</span>
                                                                                                                                       <span
                                                                                                                                           className="tp-product-details-price new-price bold pl-10">{numberWithCommas(secondCalculDicount(parseInt(product?.price), parseInt(product?.discount)))} Francs CFA</span>
                                                                                                                                   </div>
                                                                                                                                   :
                                                                                                                                   <div
                                                                                                                                       className="tp-product-details-price-wrapper mb-20">
                                                                                                                        <span
                                                                                                                            className="tp-product-details-price new-price">{numberWithCommas(product?.price)} Francs CFA</span>
                                                                                                                                   </div>
                                                                                                                           }
                                                                                                                       </>
                                                                                                                   }
                                                                                                                   <p>{product.short_description}</p>
                                                                                                                   <div
                                                                                                                       className="tp-product-list-add-to-cart">
                                                                                                                       <button
                                                                                                                           className="tp-product-list-add-to-cart-btn">{t('BuyNow')}</button>
                                                                                                                   </div>
                                                                                                               </div>
                                                                                                           </div>
                                                                                                       </div>,
                                                                                                   )
                                                                                               }
                                                                                           </>
                                                                                           :

                                                                                           ""
                                                                                   }

                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <ShopPaginate
                                                               postsPerPage={postsPerPage}
                                                               totalPosts={products?.length}
                                                               paginate={paginate}
                                                               previousPage={previousPage}
                                                               nextPage={nextPage}
                                                               current={currentPage}
                                                               color={shop?.shop.color}
                                                           />
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </section>
                               }


                           </main>
                           <QuickView
                               data={item}
                               shop_data={shop?.shop}
                               setOpen={setOpen}
                               open={open}
                           />
                       </Fade>
                       :
                       ""
               }

           </SellerLayout>
       </Fade>

    )
}

export default withTranslation()(Subcategories)
