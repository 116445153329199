import React, {Fragment, useState} from 'react';
import {sectionsConfig} from "../../config/config.section";
import {CustomModal} from "../../../components";

const SectionPanel = ({open,setOpen,addnewBlock}) => {

    const [position, setPosition] = useState(1)



    return (
    <CustomModal open={open} setOpen={setOpen} title="Section Block" size="xl" >
            <div className="row">
                {/*<div className="mt-2 me-3 text-end">*/}
                {/*    <div className="form-check d-inline-block me-1">*/}
                {/*        <input type="radio" id="add-section-insert-mode-after" value={1} defaultChecked={event => event.target.value === position} onClick={event => setPosition(1)} name="add-section-insert-mode" className="form-check-input"/>*/}
                {/*        <label className="form-check-label small" htmlFor="add-section-insert-mode-after">After</label>*/}
                {/*    </div>*/}

                {/*    <div className="form-check d-inline-block">*/}
                {/*        <input type="radio" id="add-section-insert-mode-inside" value={2} defaultChecked={event => event.target.value === position} onClick={event => setPosition(2)} name="add-section-insert-mode" className="form-check-input"/>*/}
                {/*        <label className="form-check-label small" htmlFor="add-section-insert-mode-inside">Inside</label>*/}
                {/*    </div>*/}

                {/*</div>*/}


                <div className="row">
                        <div className="modal__section">
                                {
                                    sectionsConfig.map((section,key) =>
                                        <div key={key} className="row mb-50">
                                            <div className="row"><h3>{section.name}</h3></div>
                                            {section.blocks.map((block,key) =>
                                                <div
                                                    key={key}
                                                    className=" col-lg-4"
                                                >
                                                    <div className="card__to__add">
                                                        <img src={block.picture} alt=""/>
                                                        <div className="add__block__btn" title="Add"
                                                             onClick={event => addnewBlock(block,position)}
                                                        ><i className="ri-add-line"></i></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                        </div>
                </div>
        </div>
    </CustomModal>

    )
}

export default SectionPanel;
