import {
    SUB_INCREASE_QUANTITY,
    SUB_DECREASE_QUANTITY,
    SUB_ADD_CART ,
    SUB_DELETE_CART,
    SUB_CLEAR_CART
} from  './type';
import {encryptData, decryptData} from "../../../helpers/utils";


const cryptCart = (data) => {
   const encrypted = encryptData(data);
   localStorage.setItem('customer__cart', encrypted)
}

const decryptCart = () => {
   const data =   localStorage.getItem('customer__cart')
   return  decryptData(data)

}

const initialState = {
    carts: localStorage.getItem('customer__cart') ? decryptCart().carts : [],
}


const CustomCartReducer =  (state = initialState, { type, payload }) =>{
    switch(type){
        case SUB_ADD_CART:
            if(!state.carts.length){
                let cart = {
                    id:payload.id,
                    qty : payload.qty,
                    picture : payload.picture,
                     name:payload.name,
                    category : payload.category,
                    price:payload.price
                }
                state.carts.push(cart);
                cryptCart({carts : state.carts})
            }
            else{
                let check = false;
                state.carts.map((item,key)=>{
                    if(item.id===payload.id){
                        state.carts[key].qty++;
                        check=true;
                    }
                });
                if(!check){
                    let _cart = {
                        id:payload.id,
                        qty:1,
                        name:payload.name,
                        category : payload.category,
                        picture : payload.picture,
                        price:payload.price
                    }
                    state.carts.push(_cart);
                    cryptCart({
                        carts :  state.carts
                    })
                }
            }
            return{
                ...state,
            }
        case SUB_INCREASE_QUANTITY:
            state.carts[payload].qty++;

            cryptCart({
                carts : state.carts
            })
            return{
                ...state
            }
        case SUB_DECREASE_QUANTITY:
            let qty = state.carts[payload].qty;
            if(qty>1){
                state.carts[payload].qty--;
            }

            cryptCart({
                carts : state.carts
            })

            return{
                ...state,
            }
        case SUB_DELETE_CART:
            let qty_ = state.carts[payload].qty;
            const re = {
                ...state,
                carts:state.carts.filter(item=>{
                    return item.id!=state.carts[payload].id
                })

            }
            cryptCart({
                carts:re.carts
            })
            return re
        case SUB_CLEAR_CART:
            localStorage.removeItem('customer__cart')
            return {
                carts : []
            }
        default:
            return state;
    }
}

export default CustomCartReducer
