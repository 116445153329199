import React, {useState} from 'react';
import CustomModal from "../../../../components/Modal/CustomModal";
import {withTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {attachTokenToHeaders, database_date, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";



const CreateOrUpdate = ({open,setOpen,updateData,isEdit,created,t,auth}) => {

    const [loading,setLoading] = useState(false)

    const countryOptions = [
        { value: 'benin' , label: "Benin"},
        { value: "cote d'ivoire" , label: "Cote d'ivoire"},
        { value: 'mali' , label: "Mali"},
        { value: 'niger' , label: "Niger"},
        { value: 'senegal' , label: "Sénégal"},
        { value: 'togo' , label: "Togo"},
        { value: 'other' , label: t('Other')},
    ]

    const validationSchema = Yup.object().shape({
        country: Yup.string().required(t('InputRequired')),
        city: Yup.string().required(t('InputRequired')),
        firstname: Yup.string().required(t('InputRequired')),
        lastname: Yup.string().required(t('InputRequired')),
        email: Yup.string().required(t('InputRequired')),
        phone: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        firstname: isEdit ? updateData.firstname : '',
        lastname: isEdit ? updateData.lastname : '',
        email: isEdit ? updateData.email : '',
        country: isEdit ? updateData.country : '',
        city: isEdit ? updateData.city : '',
        phone: isEdit ? updateData.phone : ''
    }


    const handleSubmit = async (values) => {
        setLoading(true)
        const shop_id = auth.shop.id

        values.shop_id  = shop_id
        values.sendEmail  = auth.shop.email
        values.shopName  = auth.shop.shopName


        if (isEdit){
            await axios.put(SERVER_URL+"deliverer/edit/"+updateData.id,values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }
        else{
            await axios.post(SERVER_URL+"deliverer/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    setOpen(false)
                    created(shop_id)
                }else{
                    toast.error(t(response.data.message))
                }
            })

        }

    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={!isEdit ? <>{t('Add')} {t('Delivere')}</> : <>{t('Edit')} {t('Delivere')}</>} size="md">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ errors, touched }) =>
                    <Form>
                        <div className="row">

                            <div  className="col-lg-6">
                                <br/>
                                <label htmlFor="country" className="form-label">{t('Country')} <b className="text-danger">*</b></label>
                                <Field as="select" className="form-select" id="country" name="country">
                                    <option value="">-</option>
                                    {
                                        countryOptions.map((country,key) =>   <option key={key} value={country.value}>{country.label}</option>)
                                    }

                                </Field>
                                {errors.country && touched.country ? (
                                    <div className='text-danger'>{errors.country}</div>
                                ) : null}
                            </div>

                            <div  className="col-lg-6">
                                <br/>
                                <label htmlFor="city" className="form-label">{t('City')} <b className="text-danger">*</b></label>
                                <Field className="form-control" id="city" name="city" style={{height : "40px"}}/>
                                {errors.city && touched.city ? (
                                    <div className='text-danger'>{errors.city}</div>
                                ) : null}
                            </div>

                            <div className="col-lg-12">
                                <label htmlFor="lastname" className="form-label">{t('Lastname')} <b className="text-danger">*</b></label>
                                <Field  className="form-control" id="lastname" name="lastname" style={{height : "40px"}} />
                                {errors.lastname && touched.lastname ? (
                                    <div className='text-danger'>{errors.lastname}</div>
                                ) : null}
                            </div>

                            <div  className="col-lg-12">
                                <br/>
                                <label htmlFor="firstname" className="form-label">{t('Firstname')} <b className="text-danger">*</b></label>
                                <Field  className="form-control" id="firstname" name="firstname" style={{height : "40px"}}/>
                                {errors.firstname && touched.firstname ? (
                                    <div className='text-danger'>{errors.firstname}</div>
                                ) : null}
                            </div>
                            <div  className="col-lg-12">
                                <br/>
                                <label htmlFor="email" className="form-label">{t('Email')} <b className="text-danger">*</b></label>
                                <Field type="email" className="form-control" id="email" name="email" style={{height : "40px"}}/>
                                {errors.email && touched.email ? (
                                    <div className='text-danger'>{errors.email}</div>
                                ) : null}
                            </div>

                            <div  className="col-lg-12">
                                <br/>
                                <label htmlFor="phone" className="form-label">{t('Phone')} <b className="text-danger">*</b></label>
                                <Field  className="form-control" id="phone" name="phone" style={{height : "40px"}}/>
                                {errors.phone && touched.phone ? (
                                    <div className='text-danger'>{errors.phone}</div>
                                ) : null}
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-lg-8 pt-3">
                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                            </div>
                            <div className="col-lg-4 pt-3">
                                <button className="btn btn-success btn-block" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                            :
                                            <span> {isEdit ? t('Edit') : t('Submit')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                }

            </Formik>


        </CustomModal>
    );
}

export default  withTranslation() (CreateOrUpdate)
