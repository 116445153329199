import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {APPNAME, attachTokenToHeaders, Capitalize, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import Paginate from "../../../../components/Paginate/Paginate";
import Message from "./Message";

const AdminContact = ({t}) => {

    document.title = APPNAME+" :: "+t('Contacts')

    const [currentContact,setCurrentContact] = useState([]);
    const [contacts,setContacts] = useState([])

    useEffect(() => {
        getAllContact()
    },[])

    useEffect(() => {
        setCurrentContact(contacts?.slice(indexOfFirstPost, indexOfLastPost))
    },[contacts])



    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [detail,setDetail] = useState(null)
    const [open,setOpen] = useState(false)

    const [loading, setLoading] = useState(true);



    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    useEffect(() => {
        setCurrentContact(contacts?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])


    const getAllContact = async () => {
        await axios.get(SERVER_URL+"admin/contacts",attachTokenToHeaders())
            .then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setContacts(response.data.contacts)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch(err => {
                setLoading(false)
            })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(contacts?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentContact(contacts?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = contacts.filter(contact => {
                return contact.fullname.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentContact(filter)
        }
    }

    const detailMessage = async (item) => {

       if (item.statut == 1){
           try {
               const response = await axios.get(`${SERVER_URL}admin/contact/`+item.id,attachTokenToHeaders())
               if (response.data.succeed) {
                   getAllContact()
                   setDetail(item)
                   setOpen(true)
               }
           } catch (error) {
               console.log(error)
           }
       }else{
           setDetail(item)
           setOpen(true)
       }
    }




    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Contacts')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Contacts')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div>
                                <div className="row g-3">

                                    <div className="offset-lg-8 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light" placeholder={t('Search')} onChange={event => SearchFilter(event.target.value)}/>
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card mb-4">
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th className="sort">{t('Fullname')}</th>
                                        <th className="sort">{t('Email')}</th>
                                        <th className="sort">{t('Phone')}</th>
                                        <th className="sort">{t('Subject')}</th>
                                        <th className="sort">Status</th>
                                        <th className="sort"></th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="11">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentContact?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentContact?.map((contact,key)=>
                                                                    <tr key={key} style={contact.statut == 1 ? {background :"#f4f4f4f4"} : {}} >
                                                                        <td>{contact.fullname}</td>
                                                                        <td>{contact.email}</td>
                                                                        <td>{contact.phone}</td>
                                                                        <td>{contact.sujet}</td>

                                                                        <td>

                                                                            {  contact.statut == 1 ?
                                                                                <b className="badge badge-success">{t('Unread')}</b>
                                                                                :
                                                                                <b className="badge badge-dark">{t('Read')}</b>
                                                                            }

                                                                        </td>
                                                                        <td><b className="cursor-pointer" onClick={event => detailMessage(contact)}>Detail</b></td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="11">
                                                                <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={contacts?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                            <Message setOpen={setOpen} open={open} data={detail} />
                        </div>

                    </div>

                </div>

            </div>

        </AdminLayout>
    );
}

export default withTranslation()(AdminContact);
