import React, {useEffect, useState} from 'react';
import CustomRender from "../../themes/config/config.block";

const ThemePreview = () => {

    const [elements, setElements] = useState([])

    useEffect(() => {
     if (localStorage.getItem('themepreview')) setElements(JSON.parse(localStorage.getItem('themepreview')))
    }, [localStorage.getItem('themepreview')]);


    return (
      <div>
          {elements?.map(({item}, index) => (
              <div key={index}
                   className="cursor-pointer">
                  <CustomRender
                      block={item}
                      isEditor={false}
                  />
              </div>
          ))}
      </div>
    );
}

export default ThemePreview;