import React, {useEffect, useState} from 'react';
import {
    APPNAME, AppStoreLinkDeliverer,
    Facebook_link, GooglePlayLinkDeliverer, instagram_link,
    Linkedin_link, TECHNCTRL, TECHNCTRL_LINK,
    X_link,
    Youtube_link
} from "../../helpers/utils";
import {Cookies as CookieConsent} from "../../components";
import  Cookies  from 'js-cookie'
import {withTranslation} from "react-i18next";
import gfr from "../../assets/google-play-badge.png"
import gen from "../../assets/google-play-badge-en.png"
import Aen from "../../assets/app-store-en.png"
import Afr from "../../assets/app-store-fr.png"
import {Link} from "react-router-dom";
import {
    LINK_ABOUT_US,
    LINK_CONDITIONS,
    LINK_CONTACT,
    LINK_DOCS,
    LINK_LEGAL,
    LINK_NEWS,
    LINK_PRICING, LINK_PRIVACY
} from "../../routes/type";
import logo from '../../assets/logo.png'

const HomeFooter = ({t})  => {


    const [consent,setConsent] = useState(true)

    useEffect(()=>{
        if (Cookies.get('CookieConset')) {
            setConsent(true)
        }else{
            setConsent(false)
        }
    },[])



    const AcceptCookie = () => {
        Cookies.set('CookieConset',true)
        setConsent(true)
    }


    const googlePlay = () => {
        const link = document.createElement('a');
        link.href = GooglePlayLinkDeliverer;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const appStore = () => {
        const link = document.createElement('a');
        link.href = AppStoreLinkDeliverer;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <footer className="template-footer" style={{backgroundColor : "#F4F4F4"}}>
            {!consent &&  <CookieConsent setConsent={AcceptCookie} />}

            <div className="container">
                <div className="footer-widgets p-t-80 p-b-30">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-sm-6">
                            <div className="widget text-block-widget">
                               <img src={logo} style={{width : "30%"}}  />
                                <p>{t('AppDescription')}</p>
                                <br/>
                                <p className="">{t('YouCanCheckOurSocial')}</p>

                                <ul className="social-bottom  ">
                                    <li><a href={Facebook_link} target={"_blank"}><i className="fab fa-facebook-f text-primary"></i></a></li>
                                    <li><a href={Youtube_link} target={"_blank"}><i className="fab fa-youtube text-danger"></i></a></li>
                                    <li><a href={instagram_link} target={"_blank"}><i className="fab fa-instagram text-danger"></i></a></li>
                                    <li><a href={X_link} target={"_blank"}><i className="fab fa-x text-dark"></i></a></li>
                                    <li><a href={Linkedin_link} target={"_blank"}><i className="fab fa-linkedin text-primary"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 col-sm-12">
                           <div className="row">
                               <div className="col-lg-8 col-md-6 col-sm-6">
                                   <div className="d-lg-flex justify-content-center">
                                       <div className="widget nav-widget pr-50">
                                           <h5 className="widget-title">{t('UtilLinks')}</h5>
                                           <ul>
                                               {/*<li><Link to={LINK_ABOUT_US} >{t('About')} {APPNAME}</Link></li>*/}
                                               <li><Link to={LINK_PRICING} >{t('Pricing')}</Link></li>
                                               {/*<li><Link to={LINK_DOCS} >{t('Docs')}</Link></li>*/}
                                               <li><Link to={LINK_NEWS} >{t('News')}</Link></li>
                                               <li><Link to={LINK_CONTACT}>{t('Contact')}</Link></li>
                                           </ul>
                                       </div>


                                       <div className="widget nav-widget">
                                           <h5 className="widget-title">{t('TermsConditions')}</h5>
                                           <ul>
                                               <li><Link  to={LINK_LEGAL} >{t('Legal')}</Link></li>
                                               <li><Link  to={LINK_PRIVACY} >{t('PrivacyPolicy')}</Link></li>
                                               <li><Link  to={LINK_CONDITIONS} >{t('GeneralConditions')}</Link></li>
                                           </ul>
                                       </div>
                                   </div>
                               </div>

                               <div className="col-lg-4 col-md-6 col-sm-6">
                                   <div className="widget pl-xl-5">
                                       <h5 className="widget-title">{t('Downloading')}</h5>

                                       <p>{t('OurApps')}</p>
                                       {localStorage.getItem('I18N_LANGUAGE') == "fr"
                                           ? <div className="d-flex justify-content-between">

                                               {
                                                   GooglePlayLinkDeliverer ?
                                                      <img src={gfr} alt="" style={{cursor : "pointer"}}  onClick={event => googlePlay()}  /> : ""}
                                               {
                                                   AppStoreLinkDeliverer ?
                                                       <img src={Afr} alt="" onClick={event => appStore()} style={{
                                                           height: "50px",
                                                           marginTop: "10px",
                                                           cursor: "pointer"
                                                       }}/>
                                                       :
                                                       ""
                                               }
                                                   </div>
                                                   : <div className="d-flex justify-content-between">
                                               {
                                                   GooglePlayLinkDeliverer ?
                                                       <img src={gen} alt="" style={{cursor : "pointer"}}  onClick={event => googlePlay()}  /> : ""}
                                               {
                                                   AppStoreLinkDeliverer ?
                                                       <img src={Aen} onClick={event => appStore()} alt=""/>
                                                       : ""
                                               }
                                           </div>
                                       }

                                   </div>
                               </div>
                           </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container">
                <div className="footer-copyright border-top-primary">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-auto col-12">
                            <div className="copyright-logo text-center text-sm-left">
                                <h4>{APPNAME}</h4>
                            </div>
                        </div>
                        <div className="col-sm-auto col-12">
                            <p className="copyright-text text-center text-sm-right pt-4 pt-sm-0">© {new Date().getFullYear()} {t('Product_of')} <a href={TECHNCTRL_LINK} target="_blank" className="text-primary">{TECHNCTRL}</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default  withTranslation() (HomeFooter)
