import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {withTranslation} from "react-i18next";
import {
    APPNAME,
    attachTokenToHeaders,
    filterAndSum,
    format_date,
    numberWithCommas,
    SERVER_URL
} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import Paginate from "../../../../components/Paginate/Paginate";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import WithdrawMethod from "./_withdrawMethod";
import {LINK_STORE_DASHBOARD, LINK_STORE_PAYMENT} from "../../../../routes/type";
import Authorized from "../../../../components/Authorized/Authorized";

const Withdraw = ({t,auth}) => {

    document.title = APPNAME+" :: "+t('WithdrawMoney')

    const [currentWithdraws,setCurrentWithdraws] = useState([]);
    const [withdraws,setWithdraws] = useState([]);
    const [payments,setPayments] = useState([]);
    const [loading,setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);


    const [totalAmount, setTotalAmount] = useState(0);
    const [onWait, setOnWait] = useState(0);
    const [available, setAvailable] = useState(0);
    const [taked, setTaked] = useState(0);
    const [open, setOpen] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState([]);

    useEffect(() => {
        getWithdraw(auth.shop?.id)
        getPayments(auth.shop?.id)
        getAllPaymentMehod()
    },[auth])

    useEffect(() => {
        setCurrentWithdraws(withdraws?.slice(indexOfFirstPost, indexOfLastPost))
    },[withdraws,currentPage])

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setTotalAmount(filterAndSum(payments,"amount",0))
        setOnWait(filterAndSum(payments,"amount",1))
        setAvailable(filterAndSum(payments,"amount",2))
        setTaked(filterAndSum(payments,"amount",3))
    }, [payments]);

    const getWithdraw = async (shop_id) => {
        await axios.get(SERVER_URL+"withdraws/"+shop_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setWithdraws(response.data.withdraws)
                setLoading(false)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }


    const getPayments = async (shop_id) => {
        await axios.get(SERVER_URL+"payments/"+shop_id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPayments(response.data.payments)
                setLoading(false)
            }else{
                setLoading(false)
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }

    const getAllPaymentMehod = async () =>{
        await axios.get(SERVER_URL+"store/settings/payment/"+auth?.shop.id,attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPaymentMethod(response.data.method)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }


    const reloadAll = () =>{
        getWithdraw(auth.shop?.id)
        getPayments(auth.shop?.id)
        getAllPaymentMehod()
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(payments?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };




    return (
        <AdminLayout>
            {
                auth?.plan?.pricing_id == 1 ?
                    <Authorized/>
                    :
                      <>
                          <div className="row">
                              <div className="col-12">
                                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                      <h4 className="mb-sm-0">{t("Withdraw")}</h4>

                                      <div className="page-title-right">
                                          <ol className="breadcrumb m-0">
                                              <li className="breadcrumb-item"><Link href="#" to={LINK_STORE_DASHBOARD}>{APPNAME}</Link></li>
                                              <li className="breadcrumb-item"><Link href="#" to={LINK_STORE_PAYMENT}>{t("Payment")}</Link></li>
                                              <li className="breadcrumb-item active">{t("Withdraw")}</li>
                                          </ol>
                                      </div>

                                  </div>
                              </div>
                          </div>



                          <div className="row">
                              <div className="col-lg-3">
                                  <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex mb-3">
                                              <div className="flex-grow-1">
                                                  <lord-icon src="https://cdn.lordicon.com/fhtaantg.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"55px",height:"55px"}}></lord-icon>
                                              </div>
                                          </div>
                                          <h3 className="mb-2">
                                              <span className="counter-value">{numberWithCommas(available)}</span>
                                              <small className="text-muted fs-13">Frcs</small></h3>
                                          <h6 className="text-muted mb-0">{t('AvailableBalance')}</h6>
                                      </div>
                                  </div>

                              </div>

                              <div className="col-lg-3">
                                  <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex mb-3">
                                              <div className="flex-grow-1">
                                                  <lord-icon src="https://cdn.lordicon.com/qhviklyi.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"55px",height:"55px"}}></lord-icon>
                                              </div>

                                          </div>
                                          <h3 className="mb-2"><span className="counter-value">{numberWithCommas(onWait)}</span>
                                              <small className="text-muted fs-13">Frcs</small>
                                          </h3>
                                          <h6 className="text-muted mb-0">{t('MoneyPending')}</h6>
                                      </div>
                                  </div>

                              </div>

                              <div className="col-lg-3">
                                  <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex mb-3">
                                              <div className="flex-grow-1">
                                                  <lord-icon src="https://cdn.lordicon.com/yeallgsa.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"55px",height:"55px"}}></lord-icon>
                                              </div>
                                          </div>
                                          <h3 className="mb-2"><span className="counter-value">{numberWithCommas(taked)}</span>
                                              <small className="text-muted fs-13">Frcs</small>
                                          </h3>
                                          <h6 className="text-muted mb-0">{t('MoneyReceived')}</h6>
                                      </div>
                                  </div>

                              </div>

                              <div className="col-lg-3">
                                  <div className="card card-animate">
                                      <div className="card-body">
                                          <div className="d-flex mb-3">
                                              <div className="flex-grow-1">
                                                  <lord-icon src="https://cdn.lordicon.com/vaeagfzc.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"55px",height:"55px"}}></lord-icon>
                                              </div>
                                          </div>
                                          <h3 className="mb-2"><span className="counter-value">{numberWithCommas(totalAmount)} </span> <small className="text-muted fs-13">Frcs</small>
                                          </h3>
                                          <h6 className="text-muted mb-0">{t('TotalAmountReceived')}</h6>
                                      </div>
                                  </div>

                              </div>



                          </div>
                          <div className="card" id="contactList">
                              <div className="card-header">
                                  <div className="row align-items-center g-3">
                                      <div className="col-md-3">
                                          <h5 className="card-title mb-0">Transactions</h5>
                                      </div>

                                      <div className="col-md-auto ms-auto">
                                          <div className="d-flex gap-2">
                                              <button className="btn btn-primary" onClick={event =>  setOpen(true)}>
                                                  <i className="ri-money-euro-box-line align-bottom me-1"></i> {t("Withdraw")}
                                              </button>

                                          </div>
                                      </div>

                                  </div>

                              </div>

                              <div className="card-body">
                                  <div className=" table-card">
                                      <table className="table align-middle table-nowrap" id="customerTable">
                                          <thead className="table-light text-muted">
                                          <tr>
                                              <th>Transaction</th>
                                              <th>ID</th>
                                              <th>{t('Amount')}</th>
                                              <th>Type</th>
                                              <th style={{width: "10%"}}>Date</th>
                                              <th style={{width: "10%"}}>Status</th>
                                          </tr>

                                          </thead>
                                          <tbody className="list form-check-all">
                                          {
                                              loading ?
                                                  <tr>
                                                      <td colSpan="6">
                                                          <p className="text-center">
                                                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                          </p>
                                                      </td>
                                                  </tr>

                                                  :

                                                  <>
                                                      {
                                                          currentWithdraws?.length > 0
                                                              ?
                                                              <>
                                                                  {
                                                                      currentWithdraws?.map((withdraw,key)=>
                                                                          <tr key={key}>
                                                                              <td>
                                                                                  <a className="fw-medium link-primary">#{withdraw.transaction}</a>
                                                                              </td>
                                                                              <td>
                                                                                 {withdraw.withdraw_id}
                                                                              </td>
                                                                              <td>
                                                                                  <h6 className="text-danger mb-1 amount">- {numberWithCommas(withdraw.amount)} Frcs</h6>
                                                                              </td>
                                                                              <td className="type">{withdraw.withdraw_method}</td>
                                                                              <td className="date">{format_date(withdraw.created_at)}</td>
                                                                              <td className="status">
                                                                                    <span
                                                                                        className="badge badge-soft-success fs-11">
                                                                                        <i className="ri-time-line align-bottom"></i> Succes
                                                                                    </span>
                                                                              </td>
                                                                          </tr>
                                                                      )
                                                                  }
                                                              </>
                                                              :
                                                              <tr>
                                                                  <td colSpan="6">
                                                                      <p className="text-center">{t('EmptyList')}</p>
                                                                  </td>
                                                              </tr>
                                                      }
                                                  </>
                                          }

                                          </tbody>
                                      </table>


                                  </div>
                                  <br/>
                                  <Paginate
                                      postsPerPage={postsPerPage}
                                      totalPosts={withdraws?.length}
                                      paginate={paginate}
                                      previousPage={previousPage}
                                      nextPage={nextPage}
                                      current={currentPage}
                                  />
                              </div>

                          </div>
                          <WithdrawMethod open={open} t={t} setOpen={setOpen}  amount={available}  paymentMethod={paymentMethod} auth={auth} created={reloadAll} />
                      </>

            }

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth : state.loginShop,
});
export default   compose(connect(mapStateToProps)) (withTranslation()(Withdraw));
