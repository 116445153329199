import {Capitalize} from "../../helpers/utils";
import PicturePicker from "../../components/PicturePicker/PicturePicker";
import React, {Fragment, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Popover, PopoverBody} from "reactstrap";
import {remixIcons} from "../config/config.section";


const CustomInput = ({content,t,handleInput}) => {

    const [icon, setIcon] = useState([])
    const [open, setOpen] = useState(false)
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(100)


         const sames = [
             'text',
             "number",
             "radio",
         ]

    useEffect(() => {
     setIcon(remixIcons)
    }, []);

    const SearchFilter = (event) =>{
        if (event === ""){
            setIcon(remixIcons)
        }else{
            const  filter = icon.filter(ico => {
                return ico.toLowerCase().includes(event.toLowerCase())
            })
            setIcon(filter)
        }
    }

    const loadPrev = () => {
      setStart(start - 100)
      setEnd(end - 100)
    }

    const loadNext = () => {
      setStart(start + 100)
      setEnd(end + 100)
    }


    if (sames.includes(content.type)){
        return <div className="form-inline row inline mt-3">
            <label className="col-lg-4" htmlFor={content.name.toLowerCase()}>{t(Capitalize(content.name))}</label>
            <input type ={content.type} value={content.value} id={content.name.toLowerCase()}
                   onInput={event => handleInput(event.target.value,content.id)}
                   className="form-control col-lg-8" placeholder={t(Capitalize(content.name))} />
        </div>
    }

    if (content.type === 'checkbox') {

       return  <div className="row form-inline mt-3">
           <label className="col-lg-4 form-check-label" htmlFor="flexCheckChecked">{t(Capitalize(content.name))}</label>
           <div className="form-check form-switch offset-lg-1 col-lg-4">
               <input className="form-check-input " defaultChecked={content.value} type="checkbox" role="switch" id="flexCheckChecked" onChange={event => handleInput(event.target.checked,content.id)} />
           </div>
       </div>
    }

    if (content.type == "title"){
        return <h4 className="text-muted">{content.value}</h4>
    }

    if (content.type == "icon"){
        return <div className="mb-3 form-inline  col-sm-12 inline" data-key="background-color">
            <label className=" form-label col-lg-4" htmlFor={content.name.toLowerCase()}>{t(Capitalize(content.name))}</label>

            <div className="col-lg-8">
                <button className="btn btn-icon btn-light"
                        id={"Popover-"+content.name.toLowerCase()}
                        onClick={event => setOpen(true)}>
                    icon
                </button>

                <Popover placement="bottom" isOpen={open} fade={true} target={'Popover-' +content.name.toLowerCase()} toggle={event => setOpen(!open)}>
                    <PopoverBody>
                        <div className="row">
                            <input type="text" placeholder={t('SearchIcon')+"..."} className="form-control" onInput={event => SearchFilter(event.target.value)}/>
                        </div>
                        <hr/>

                       <div className="row" style={{height : "500px",overflowY : "scroll"}}>
                           {
                               icon.length > 0 ?
                                   <>
                                       {
                                           icon.slice(start,end).map((remixIcon,key) => (
                                               <div className="col-lg-3 col-md-4 col-sm-6 mb-10" key={key}>
                                                   <button  className="btn btn-light btn-icon" onClick={event => {handleInput("ri-"+remixIcon,content.id); setOpen(false)}}>
                                                       <i className={`ri-${remixIcon}`}></i>
                                                   </button>
                                               </div>
                                           ))
                                       }
                                   </>
                                   :

                                 <div className="text-center">
                                     <i className="ri-folder-open-line" style={{fontSize : "150px",fontWeight : "lighter"}}></i> <br/>
                                      <small>{t('NoResultFound')}</small>
                                 </div>
                           }
                       </div>
                        <p style={{display : "flex",justifyContent : "space-between", marginTop : "20px",marginBottom : "-10px"}}>
                            {
                                start > 0 &&  <button className="btn btn-light" onClick={event => loadPrev()}>{t('Previous')}</button>
                            }
                            {
                                end <= remixIcons.length &&  <button className="btn btn-light" onClick={event => loadNext()}>{t('Next')}</button>
                            }
                        </p>
                    </PopoverBody>
                </Popover>

            </div>
        </div>
    }


    if (content.type === "color"){
        return  <div className="mb-3 form-inline  col-sm-12 inline" data-key="background-color">
             <label className=" form-label col-lg-4" htmlFor={content.name.toLowerCase()}>{t(Capitalize(content.name))}</label>
                <div className=" input"><div>
                    <input name="background-color" value={content.value} type="color" pattern="#[a-f0-9]{6}"
                           onChange={event => handleInput(event.target.value,content.id)}
                           className="form-control form-control-color col-lg-8" style={{width : "100%"}}/>
                    </div>
                </div>
            </div>
    }

    if (content.type === "select"){
        return <div className="row" style={{width : "100%",display : "flex",flexDirection : "row",marginTop : "10px"}}>
            <label className="form-label " style={{width : "33%"}} htmlFor={content.name.toLowerCase()}>{t(Capitalize(content.name))}</label>
            <select name="" className="form-select" style={{width : "67%"}} value={content.value} id={content.name.toLowerCase()}
                    onChange={event => handleInput(event.target.value,content.id)}>
                <option value="">-</option>
                {
                    content.option.map((option,key) => <option key={key} value={option}>{option}</option>)
                }
            </select>
        </div>
    }

    if (content.type === "image"){
        return <div className="row p-1">
            <label className="form-label col-lg-5 text-center pt-3" htmlFor={content.name.toLowerCase()}>{t(Capitalize(content.name))}</label>
             <div className="col-lg-6">
                 <PicturePicker defaultValue={content.value} handleInput={handleInput} content={content} />
             </div>
        </div>
    }

    if (content.type === "textarea"){
        return <div className="form-inline row inline mt-3">
            <label className="col-lg-4" htmlFor={content.name.toLowerCase()}>{t(Capitalize(content.name))}</label>
            <div className="col-lg-8">
                <textarea onInput={event => handleInput(event.target.value,content.id)} id={content.name.toLowerCase()} cols="20" rows="3" className="form-control">
                 {content.value}
            </textarea>
            </div>

        </div>
    }
}

export default withTranslation() (CustomInput)
