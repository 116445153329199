import React, {useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import OtpInput from "../OtpInput/OtpInput";
import {withTranslation} from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";
import {attachTokenToHeaders, SERVER_URL} from "../../helpers/utils";
import {toast} from "react-toastify";
import {MiddleLoading} from "../index";
const TwoFa = ({auth,t}) => {

    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    const onCompleteOTP = async (value) =>{
        try {
            setLoading(true);

            const data = {
                id : auth?.me?.id,
                token : value
            }
            await axios.post(SERVER_URL+"store/otp/verify",data,attachTokenToHeaders()).then((pass)=>{
                if (pass){
                    setTimeout(()=>{
                        Cookies.set('_ot',true,{expires : 1})
                        window.location.reload(true)
                    },2000)
                }
                else{
                    toast.success(t("Auth2Failed"));
                }
            })

        } catch (error) {
            setLoading(false);
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.response.data.message || error.message || error.toString();
            toast.error(t(resMessage));
        }



    }

    return (
        <Modal isOpen={open} toggle={() => setOpen(false)} backdrop="static" centered>
            <ModalBody className="form-modal">
                {loading ?
                     <MiddleLoading />
                    :
                    <>
                        <h3 className="text-center">2FA Authentification</h3>

                        <p style={{marginBottom: "20px", marginTop: "20px", textAlign: "center"}}>{t('Verificode')}</p>
                        <br/>
                        <br/>
                        <OtpInput length={6} onInputComplete={onCompleteOTP}/>
                        <br/>
                    </>
                }
            </ModalBody>
        </Modal>
    );
}

export default withTranslation()(TwoFa);