import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {CustomModal, RegLoading} from "../../../../components";
import { withTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import {attachTokenToHeaders, isEmail, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";

const AddByXlsx = ({ t, setOpen, open, oldData ,reload }) => {
    const [size, setSize] = useState("sm");
    const [fullscreen, setFullscreen] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [editedRow, setEditedRow] = useState(null);

    useEffect(() => {
        setData([]);
        setFullscreen(false);
        setSize("sm");
    }, [open]);

    const handleFileUpload = useCallback((event) => {
        const file = event.target.files[0];
        if (!file) return;

        setFullscreen(true);
        setSize("lg");
        setLoading(true);
        setError('');

        const fileType = file.name.split('.').pop().toLowerCase();

        if (fileType === 'csv') {
            handleCSVUpload(file);
        } else if (fileType === 'xlsx') {
            handleXLSXUpload(file);
        } else {
            setError('Unsupported file type');
            setLoading(false);
        }
    }, []);

    const handleXLSXUpload = useCallback((file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const ab = e.target.result;
                const workbook = XLSX.read(ab, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                filterData(jsonData);
            } catch (err) {
                setError('Error reading XLSX file');
            } finally {
                setLoading(false);
            }
        };

        reader.readAsArrayBuffer(file);
    }, []);

    const handleCSVUpload = useCallback((file) => {
        Papa.parse(file, {
            complete: (result) => {
                filterData(result.data);
                setLoading(false);
            },
            header: true,
            error: (err) => {
                setError(err.message);
                setLoading(false);
            },
        });
    }, []);

    const filterData = useCallback((data) => {
        const oldEmails = oldData.map(item => item.email);
        const filteredData = data
            .filter(item => !oldEmails.includes(item["Email de la boutique"].trim()))
            .map(item => ({
                country: item["Pays"],
                email: item["Email de la boutique"],
                phone: item["Numero de téléphone de la boutique"],
                owner: item["Nom et prénom du responsable"],
                valide_email: isEmail(item["Email de la boutique"].trim()),
            }));
        setData(filteredData);
    }, [oldData]);

    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditedRow({ ...data[index] });
    };

    const handleInputChange = (e, field) => {
        setEditedRow(prev => ({
            ...prev,
            [field]: e.target.value,
            valide_email: field === "email" ? isEmail(e.target.value) : prev.valide_email
        }));
    };

    const handleSaveClick = () => {
        const updatedData = data.map((row, index) => index === editIndex ? editedRow : row);
        setData(updatedData);
        setEditIndex(null);
        setEditedRow(null);
    };


    const saveData = async () => {
        setSaveLoading(true)
        await axios.post(SERVER_URL+"admin/demo/account/add/multiple",{data : JSON.stringify(data)},attachTokenToHeaders()).then((response)=>{
            setSaveLoading(false)
            if (response.data.succeed){
                toast.success(t(response.data.message))
                setOpen(false)
               reload()
            }else{
                toast.error(t(response.data.message))
            }
        })
    }


    return (
        <CustomModal open={open} setOpen={setOpen} title={t('AddwithXlsx')} size={size} fullscreen={fullscreen}>
            <div className="row">
                <h4>Fichier excel</h4>
                <input type="file" className="form-control form-control-lg" accept=".csv,.xlsx" onChange={handleFileUpload} />
                {error && <p className="text-danger">{error}</p>}
                {loading &&  <RegLoading />}
                {
                    data.length > 0 && (
                        <div className="col-md-12 mt-30">
                            <h4 className="d-flex justify-content-between">Données importées ({data.length})
                                <button className="btn btn-success btn-sm mr-10" onClick={event => saveData()}>
                                    {
                                        saveLoading ?
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                            </>
                                            :
                                            <span>{t('SaveData')}</span>
                                    }
                                    </button></h4>
                            <div className="data-table-container">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                    <tr>
                                        <th>Pays</th>
                                        <th>Email de la boutique</th>
                                        <th>Numero de téléphone de la boutique</th>
                                        <th>Nom et prénom du responsable</th>
                                        <th>Email valide</th>
                                        <th>Modifier</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.country}</td>
                                            <td>
                                                {editIndex === index ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editedRow.email}
                                                        onChange={(e) => handleInputChange(e, 'email')}
                                                    />
                                                ) : (
                                                    row.email
                                                )}
                                            </td>
                                            <td>
                                                {editIndex === index ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editedRow.phone}
                                                        onChange={(e) => handleInputChange(e, 'phone')}
                                                    />
                                                ) : (
                                                    row.phone
                                                )}
                                            </td>
                                            <td>{row.owner}</td>
                                            <td>
                                                {editIndex === index ? (
                                                    <b className={`badge ${editedRow.valide_email ? 'badge-success' : 'badge-danger'}`}>
                                                        {editedRow.valide_email ? 'Valide' : 'Invalide'}
                                                    </b>
                                                ) : (
                                                    <b className={`badge ${row.valide_email ? 'badge-success' : 'badge-danger'}`}>
                                                        {row.valide_email ? 'Valide' : 'Invalide'}
                                                    </b>
                                                )}
                                            </td>
                                            <td>
                                                {editIndex === index ? (
                                                    <button className="btn btn-sm btn-icon btn-success"
                                                            onClick={handleSaveClick}>
                                                        <i className="ri-save-2-fill"></i>
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-sm btn-light btn-icon"
                                                            onClick={() => handleEditClick(index)}>
                                                        <i className="ri-pencil-line"></i>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }

            </div>
        </CustomModal>
    );
};

AddByXlsx.propTypes = {
    t: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    oldData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withTranslation()(React.memo(AddByXlsx));
