import React, {useEffect} from 'react';
import {Capitalize, format_date_complet} from "../../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    CustomerClearCart
} from "../../../../../store/Subdomain/CustomerCart/action";
import {withTranslation} from "react-i18next";

const _Success = ({t,successData,CustomerClearCart}) => {

    useEffect(() => {
       if (successData) CustomerClearCart()
    }, [successData]);
    return (
        <section className="tp-checkout-area pb-120 pt-100" style={{background : "#EFF1F5"}}>
            <div className="">
                <div className="row gx-0">
                    <div className="offset-lg-2 col-lg-8">
                        <div className="tp-order-details" data-bg-color="#4F3D97" style={{backgroundColor: "#28A745"}}>
                            <div className="tp-order-details-top text-center mb-70">
                                <div className="tp-order-details-icon">
                                 <span>
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="100"
                                      height="100"
                                      fill="#fff"
                                      version="1.1"
                                      viewBox="0 0 50 50"
                                      xmlSpace="preserve"
                                  >
                                      <path d="M40.267 14.628L20.974 33.921l-9.293-9.293a.999.999 0 10-1.414 1.414l10 10a.997.997 0 001.414 0l20-20a.999.999 0 10-1.414-1.414z"></path>
                                    </svg>
                                 </span>
                                </div>
                                <div className="tp-order-details-content">
                                    <h3 className="tp-order-details-title text-white">{t('OrderPlaceSuccessFully')}</h3>
                                    <p>{t('OrderConfirmMail')}</p>
                                </div>
                            </div>
                            <div className="tp-order-details-item-wrapper">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="tp-order-details-item">
                                            <h4>{t('OrderDate')}</h4>
                                            <p>{format_date_complet(successData?.orderdate)}</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="tp-order-details-item">
                                            <h4>{t('OrderNumber')}:</h4>
                                            <p>#{successData?.orderNumber}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="tp-order-details-item">
                                            <h4>{t('PaymentMethod')}:</h4>
                                            <p>{t(Capitalize(successData.payment_method.split('-')[0].toLowerCase()))}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};


const mapStateToProps = (state) => ({
    customCart : state.customCart
});

export default   compose(connect(mapStateToProps,{CustomerClearCart})) (withTranslation()(_Success));

