import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {LINK_STORE_APPS} from "../../../../routes/type";
import axios from "axios";
import {attachTokenToHeaders, database_date, deepEqual, hasEmptyValue, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {MiddleLoading} from "../../../../components";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import swal from "@sweetalert/with-react";

const WhatsappConf = ({t,auth}) => {

    const [loading, setLoading] = useState(true);
    const [doading, setDoading] = useState(false);
    const [soading, setSoading] = useState(false);
    const [inputs, setInputs] = useState({});

    const history = useHistory()

    useEffect(() => {
        getWidgetConf()
    }, []);

    const getWidgetConf = async () => {
        await axios.get(SERVER_URL+"package/config/whatsapp",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                console.log(response.data.config)
                if (!hasEmptyValue(response.data.config)){
                    setInputs(JSON.parse(response.data.config))
                }else{
                    setInputs({
                        companyName  : auth?.shop.shopName,
                        phoneNumber  : auth?.shop.phone,
                        sendButtonText  : t('Send'),
                        inputPlaceHolder  : "",
                        replyTimeText  : t('ReplyOnTime'),
                        message  : t('messageToWhatsapp'),
                    })
                }

                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }



    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required(t('InputRequired')),
        phoneNumber: Yup.string().required(t('InputRequired')),
        inputPlaceHolder : Yup.string().required(t('InputRequired')),
        sendButtonText: Yup.string().required(t('InputRequired')),
        replyTimeText: Yup.string().required(t('InputRequired')),
        message: Yup.string().required(t('InputRequired')),
    });

    const initialValues = {
        companyName  : inputs.companyName,
        phoneNumber  : inputs.phoneNumber,
        sendButtonText  :inputs.sendButtonText,
        inputPlaceHolder  : inputs.inputPlaceHolder,
        replyTimeText  : inputs.replyTimeText,
        message  : inputs.message,
    }




    const handleSubmit = async (values) => {
        if (!deepEqual(values,inputs)){
            setDoading(true)
            const config = JSON.stringify(values)
            const expression = "whatsapp"
            const shop_id =  auth?.shop.id

            const data = {
                config : config,
                shop_id : shop_id,
                expression : expression
            }

            await axios.post(SERVER_URL+"package/config",data,attachTokenToHeaders()).then((response)=>{
                setDoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                }else{
                    toast.error(t(response.data.message))
                }
            })
        }else{
            toast.error(t("ValueNotChanged"))
        }

    }

    const Uninstall = async () => {

            const expression = "whatsapp"
            const shop_id =  auth?.shop.id

        const data = {
            shop_id : shop_id,
            expression : expression
        }
        await swal({
            title : t('Uninstall'),
            text : t('SureToUninstallExt'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode:true,
            icon : "warning"
        }).then(async (check) => {
            if (check){
                setSoading(true)
                await axios.post(SERVER_URL+"package/uninstall",data,attachTokenToHeaders()).then((response)=>{
                    setSoading(false)
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        window.location.href =  LINK_STORE_APPS
                    }else{
                        toast.error(t(response.data.message))
                    }
                })
            }
        });



    }


    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Whatsapp</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{auth?.shop?.shopName}</li>
                                <li className="breadcrumb-item"><Link to={LINK_STORE_APPS}>{t('Applications')}</Link></li>
                                <li className="breadcrumb-item active">Whatsapp</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="offset-lg-2 col-lg-8">
                    <div className="card" id="tasksList">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title mb-0">{t('Configuration')}</h4>
                            <Link to={LINK_STORE_APPS}><i className='ri-close-fill' style={{fontSize: "15px"}}></i></Link>

                        </div>
                        <div className="card-body ">
                            {
                                loading ?
                                    <MiddleLoading/>
                                    :
                                    <div className="row">
                                        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                            {({ errors, touched }) =>
                                                <Form>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <br/>
                                                            <label htmlFor="companyName"
                                                                   className="form-label">{t('ShopName')} <b
                                                                className="text-danger">*</b></label>
                                                            <Field className="front" id="companyName"
                                                                   name="companyName"/>
                                                            {errors.companyName && touched.companyName ? (
                                                                <div className='text-danger'>{errors.companyName}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <br/>
                                                            <label htmlFor="phoneNumber"
                                                                   className="form-label">{t('Phone')} <b
                                                                className="text-danger">*</b></label>
                                                            <Field className="front" id="phoneNumber"
                                                                   name="phoneNumber"/>
                                                            {errors.phoneNumber && touched.phoneNumber ? (
                                                                <div className='text-danger'>{errors.phoneNumber}</div>
                                                            ) : null}
                                                        </div>

                                                        <div className="col-lg-4">
                                                            <br/>
                                                            <label htmlFor="sendButtonText"
                                                                   className="form-label">{t('SendButtonText')} <b
                                                                className="text-danger">*</b></label>
                                                            <Field className="front" id="sendButtonText"
                                                                   name="sendButtonText"/>
                                                            {errors.sendButtonText && touched.sendButtonText ? (
                                                                <div
                                                                    className='text-danger'>{errors.sendButtonText}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <br/>
                                                            <label htmlFor="inputPlaceHolder"
                                                                   className="form-label">{t('InputPlaceHolder')} <b
                                                                className="text-danger">*</b></label>
                                                            <Field className="front" id="inputPlaceHolder"
                                                                   name="inputPlaceHolder"/>
                                                            {errors.inputPlaceHolder && touched.inputPlaceHolder ? (
                                                                <div
                                                                    className='text-danger'>{errors.inputPlaceHolder}</div>
                                                            ) : null}
                                                        </div>


                                                        <div className="col-lg-6">
                                                            <br/>
                                                            <label htmlFor="replyTimeText"
                                                                   className="form-label">{t('ReplyTimeText')} <b
                                                                className="text-danger">*</b></label>
                                                            <Field className="front" id="replyTimeText"
                                                                   name="replyTimeText"/>
                                                            {errors.replyTimeText && touched.replyTimeText ? (
                                                                <div
                                                                    className='text-danger'>{errors.replyTimeText}</div>
                                                            ) : null}
                                                        </div>


                                                        <div className="col-lg-12">
                                                            <br/>
                                                            <label htmlFor="message"
                                                                   className="form-label">{t('Message')} <b
                                                                className="text-danger">*</b></label>
                                                            <Field as="textarea" className="form-control"
                                                                   style={{fontSize: "16px", color: "#6e727d"}}
                                                                   id="message" name="message" rows={4} cols={10}/>
                                                            {errors.message && touched.message ? (
                                                                <div className='text-danger'>{errors.message}</div>
                                                            ) : null}
                                                        </div>

                                                    </div>
                                                    <br/>

                                                    <div className="offset-lg-4 col-lg-4">
                                                        <button className="btn btn-success btn-block btn-lg" disabled={doading}>
                                                            {
                                                                doading ?
                                                                    <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</>
                                                                    :
                                                                    <span>{t('Submit')}</span>
                                                            }

                                                          </button>
                                                    </div>


                                                </Form>
                                            }

                                        </Formik>


                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="offset-lg-2 col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-9">
                                   <p style={{paddingTop : "10px"}}>
                                       {t('UninstallExt')}
                                   </p>
                                </div>
                                <div className="col-lg-3 text-right">
                                    <button className="btn btn-outline-danger" disabled={soading} onClick={event => Uninstall()}>
                                        {
                                            loading ?
                                                <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Uninstalling')}...</>
                                                :
                                                <span>{t('Uninstall')}</span>
                                        }

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(WhatsappConf));