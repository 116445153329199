import React, {useRef, useState} from 'react';
import {flatData} from "../../utils/utils";
import {Link} from "react-router-dom";

const Collection = ({block,customStyle,toggleHightLight,isEditor}) => {

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const collection = useRef()

    const getBlockSize = () => {
        setDimension({top :collection?.current?.offsetTop, left : collection?.current?.offsetLeft, width : collection?.current?.offsetWidth, height : collection?.current?.offsetHeight,nodeName : collection?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }

    
    return (
        <section className="tp-about-area pt-10 pb-10"
                 style={customStyle}
                 ref={collection}
                 onMouseEnter={() => isEditor && getDimension(block, true)}
                 onMouseLeave={() => isEditor && getDimension(block, false)}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6">
                        <div className="tp-about-thumb-wrapper p-relative mr-35">
                            <div className="tp-about-thumb m-img">
                                <img src={flatData(block.contentEditable,'img1')} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="tp-about-wrapper pl-80 pt-75 pr-60">
                            <div className="tp-section-title-wrapper-4 mb-50">
                                <span className="tp-section-title-pre-4">{flatData(block.contentEditable,'smallTitle')}</span>
                                <h3 className="tp-section-title-4 fz-50">{flatData(block.contentEditable,'title')}</h3>
                            </div>
                            <div className="tp-about-content pl-120">
                                <p style={{fontFamily : "poppins"}}>{flatData(block.contentEditable,'text')}</p>

                                <div className="tp-about-btn">
                                    <Link to={flatData(block.contentEditable,"btn_link")} className="tp-btn">
                                        {flatData(block.contentEditable,'text_btn')}  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 7.49976L1 7.49976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
                )
}

export default Collection;