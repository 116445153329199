import React, {useState} from 'react';
import pluginIcon from "../../../../assets/install.jpg";
import {Capitalize, numberWithCommas} from "../../../../helpers/utils";
import InstalledPlugin from "./InstalledPlugin";

const AppDetail = ({reload,setOpen,plugin,auth,t}) => {

    const [install, setInstall] = useState(false)

    return (
        <div className="row">
            <div className="offset-lg-1 col-lg-10">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            {/*<pre>*/}
                            {/*    {JSON.stringify(plugin,null,2)}*/}
                            {/*</pre>*/}
                            <div className="col-lg-12 text-right">
                                <i className="ri-close-fill fs-20 cursor-pointer" onClick={event => setOpen(false)}></i>
                            </div>
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-4 d-flex">
                                        <img src={pluginIcon} alt="extension"
                                             style={{width: "50px", height: "50px", marginRight: "10px"}}/>
                                        <div>
                                            <h3>{plugin?.["name" + localStorage.getItem('I18N_LANGUAGE').toUpperCase()]} </h3>
                                           <span className="fs-10">
                                               Version : <span className="text-primary"> {plugin.version}</span> , {!plugin.free ?
                                               <span className="pl-10"> {t('Price')} : <small className="fs-10 text-primary">{numberWithCommas(plugin.price)} Frcs</small></span> : ""}
                                           </span>
                                        </div>
                                    </div>

                                    <div className="offset-lg-6 col-lg-2 text-right">
                                        <br/>
                                        <button className="btn btn-rounded btn-success" onClick={event => setInstall(true)}>Installer</button>

                                    </div>

                                </div>
                                <div className="d-flex mt-10">
                                    <b className="badge badge-primary">{Capitalize(t(plugin.expression))}</b>
                                    {plugin?.type == 1 ? <b className="badge badge-info ml-10">Extension</b> : <b className="badge badge-primary ml-10">Module</b>}
                                    <b className="badge badge-primary ml-10">{Capitalize(t("Annual"))}</b>
                                    {plugin?.price > 0 ? <b className="badge badge-danger ml-10">{t('Premium')}</b> : <b className="badge badge-success ml-10">{t('Free')}</b>}


                                </div>
                            </div>
                        </div>
                        <div className="row mt-45 pl-25">
                            <h3>Description</h3>
                            <br/>
                            <br/>
                            <div className="pl-40">
                                <p style={{width: "60%"}}>
                                    {plugin?.[localStorage.getItem('I18N_LANGUAGE')]}
                                </p>

                                <br/>
                                <ul>
                                    {
                                        JSON.parse(plugin.features).map((feature, idx) =>
                                            <div className="pl-30" key={idx}>
                                                <li key={idx}>★ {feature?.[localStorage.getItem('I18N_LANGUAGE')]}</li>
                                                <br/>
                                            </div>
                                        )

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <InstalledPlugin
             open={install}
             setOpen={setInstall}
             plugin={plugin}
             auth={auth}
             closed={setOpen}
            />
        </div>
    );
}

export default AppDetail;