import React, {useEffect, useRef, useState} from 'react';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {SwiperSlide,Swiper} from "swiper/react";
import {flatData} from "../../utils/utils";

const Partners = ({block,customStyle,toggleHightLight,t,isEditor,shop_data}) => {
    const [items, setItems] = useState([]);

    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const partner = useRef()

    const getBlockSize = () => {
        setDimension({top :partner?.current?.offsetTop, left : partner?.current?.offsetLeft, width : partner?.current?.offsetWidth, height : partner?.current?.offsetHeight,nodeName : partner?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    useEffect(() => {
        setItems(block.contentEditable[0]?.data)
    }, [block.contentEditable]);

    return(
        <section className="tp-brand-area pt-120"
                 style={customStyle}
                 ref={partner}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-brand-slider p-relative">
                            <div className="tp-brand-slider-active swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                                <div className="swiper-wrapper" id="swiper-wrapper-b11054f103c97313a2" aria-live="polite">
                                    <Swiper
                                        modules={[Autoplay,Pagination,Navigation]}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }
                                        }
                                        loop={true}
                                        slidesPerView={items.length > 5 ? 5 : 1}
                                        navigation={{
                                            nextEl : ".tp-brand-slider-button-prev",
                                            prevEl : ".tp-brand-slider-button-next",
                                            clickable : true
                                        }}
                                    >


                                        {items?.map((item,key) =>
                                            <SwiperSlide key={key}>
                                                <div
                                                    className="tp-brand-item swiper-slide text-center swiper-slide-next">
                                                    <a target="_blank" href={isEditor ? "#" : flatData(item,"link")}>
                                                        <img src={flatData(item,"image")} alt=""/>
                                                    </a>
                                                </div>
                                            </SwiperSlide>
                                        )}


                                    </Swiper>
                                    <span className="swiper-notification" aria-live="assertive"
                                          aria-atomic="true"></span></div>
                                <div className="tp-brand-slider-arrow">
                                    <button className="tp-brand-slider-button-prev" tabIndex="0" aria-label="Previous slide" aria-controls="swiper-wrapper-b11054f103c97313a2">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1L1 7L7 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                                <button className="tp-brand-slider-button-next" tabIndex="0" aria-label="Next slide" aria-controls="swiper-wrapper-b11054f103c97313a2">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Partners;
