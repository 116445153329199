import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/front/Layout";
import {withTranslation} from "react-i18next";
import PublicPaginate from "../../../components/Paginate/PublicPaginate";
import axios from "axios";
import {APPNAME, format_date_complet, SERVER_IMG_URL, SERVER_URL} from "../../../helpers/utils";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import 'moment/locale/fr'

moment().locale('fr')

const  News = ({t}) => {

    document.title = APPNAME+" :: Blog"

    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('Blog'))
    document.querySelector("meta[name='description']").setAttribute("content", "Les articles de notre blog")



    useEffect(() => {
        getArticles()
    }, []);


    const [loading,setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(4);

    const [currentArticles,setCurrentArticles] = useState([]);
    const [articles,setArticles] = useState([]);
    const [categories,setCategories] = useState([]);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    useEffect(() => {
        setCurrentArticles(articles?.slice(indexOfFirstPost, indexOfLastPost))
    },[articles])

    useEffect(() => {
        setCurrentArticles(articles?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(articles?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const getArticles = async () => {
        await axios.get(SERVER_URL+"articles").then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setArticles(response.data.articles)
                setCategories(response.data.categories)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }



    const filterByCategory = (category) =>{
        console.log(category)
           if (category == 0){
               setCurrentArticles(articles?.slice(indexOfFirstPost, indexOfLastPost))
           }else{
               const  filter = articles.filter(article => parseInt(article.category_id) == category)
               setCurrentArticles(filter)
           }
    }




    return (
        <Layout>
            {
                loading ?
                    <section className="shop-area p-t-180 p-b-130">
                        <div className="container">
                            <div className="row">
                                <div className="text-center p-t-200 p-b-200"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...</div>
                            </div>
                        </div>
                    </section>
                    :
                    <>
                        <section className="shop-area p-t-180 p-b-80">
                            <div className="container">
                                <div className="row">
                                    <div className="offset-lg-2 col-lg-8">
                                        <div className="header_custom_pricing">
                                            <h1>{t('Blog')}</h1>
                                            <p>
                                                {t('BlogText')}
                                            </p>
                                            <br/>
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </section>
                        {
                            currentArticles?.length > 0 || categories?.length > 0 ?
                                <>
                        <section className="blog-area p-b-130">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        {
                                            currentArticles?.length > 0
                                            ?
                                                <>
                                                    <div className="blog-post-items p-r-40 p-r-lg-0">

                                                        {currentArticles?.map((item,k)=>
                                                            <div className="single-blog-post thumbnail-cover" key={k}>
                                                                <div className="post-thumbnail">
                                                                    <img src={SERVER_IMG_URL+"blogs/"+item.image} alt={item.title} />
                                                                </div>

                                                                <div className="post-content">
                                                                    <ul className="post-meta">
                                                                        <li><a href="#" className="post-meta"><i className="far fa-user"></i>{item.author}</a></li>
                                                                        <li><a href="#" className="post-meta"><i className="far fa-calendar-alt"></i>{moment(item?.created_at).fromNow()}</a></li>
                                                                    </ul>
                                                                    <h4 className="post-title">
                                                                        <Link to={`/news/${item.slug}`} >{item.title}</Link>
                                                                    </h4>
                                                                    <p className="text-white">{item.short}</p>
                                                                    <Link to={`/news/${item.slug}`}  className="post-read-more">{t('ReadMore')} <i className="fas fa-arrow-right"></i></Link>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                    <PublicPaginate
                                                        postsPerPage={postsPerPage}
                                                        totalPosts={articles?.length}
                                                        paginate={paginate}
                                                        previousPage={previousPage}
                                                        nextPage={nextPage}
                                                        current={currentPage}
                                                    />
                                                </>

                                                :

                                                <section className="blog-area p-t-10 p-b-200 text-center">
                                                    <h2>{t('EmptyList')}</h2>
                                                </section>
                                        }



                                    </div>
                                    <div className="col-lg-4">
                                        <div className="blog-sidebar m-t-md-80">
                                            <div className="widget category-widget">
                                                <h4 className="widget-title">{t('Category')}</h4>
                                                <ul className="category-link">
                                                    <li className="cursor-pointer" onClick={event => filterByCategory(0)}><small className="fa fa-plus"></small> {t('All')}</li>
                                                    {categories?.map((category,k)=> <li key={k} className="cursor-pointer" onClick={event => filterByCategory(category.id)}><small className="fa fa-plus"></small> <span>{category.category}</span></li>)}
                                                </ul>
                                            </div>
                                            <div className="widget latest-post-widget">
                                                <h4 className="widget-title">{t('LatestArticle')}</h4>
                                                <div className="popular-posts-wrapper">
                                                    {
                                                        currentArticles.slice(currentArticles.length -1).map((latest,key) =>
                                                    <div className="popular-posts-item" key={key}>
                                                        <div className="popular-posts-thumbnail">
                                                            <Link to={`/news/${latest.slug}`} >
                                                                <img src={SERVER_IMG_URL+"blogs/"+latest.image} alt={latest.title} />
                                                            </Link>
                                                        </div>
                                                                <div className="popular-posts-item-content" key={key}>
                                                                    <h5 className="popular-posts-title">
                                                                        <Link to={`/news/${latest.slug}`} >{latest.title}</Link>
                                                                    </h5>
                                                                    <a href="#" className="posts-date"><i className="far fa-calendar-alt"></i> {format_date_complet(latest.created_at)}</a>
                                                                </div>


                                                    </div>

                                                        )
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                                </>
                                :
                               <section className="blog-area p-t-10 p-b-200 text-center">
                                   <h2>Liste vide</h2>
                               </section>
                        }
                    </>


            }
        </Layout>
    );
}

export default withTranslation() (News)
