import React, {Fragment, useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CLIENT} from "../../../helpers/utils";
import {flatData} from "../../utils/utils";
import {Link} from "react-router-dom";
import {AnimatePresence,motion} from "framer-motion";
import {Swiper,SwiperSlide} from "swiper/react";
import {Autoplay, EffectCoverflow, Navigation, Pagination} from "swiper/modules";



const ArrowRight = () => {
    return (
        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 6.99976L1 6.99976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.9502 0.975414L16.0002 6.99941L9.9502 13.0244" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const SliderItem = ({item}) => {

    const styles = {background: flatData(item,"bgColor")}

    return (
        <div className={`tp-slider-item tp-slider-height d-flex align-items-center swiper-slide`}
             style={styles}
        >
            <div className="tp-slider-shape" style={{zIndex : "999999999999999"}}>
                <img className="tp-slider-shape-1" src={`${CLIENT}/builder/slider/shape/slider-shape-1.png`} alt="slider-shape"/>
                <img className="tp-slider-shape-2" src={`${CLIENT}/builder/slider/shape/slider-shape-2.png`} alt="slider-shape"/>
                <img className="tp-slider-shape-3" src={`${CLIENT}/builder/slider/shape/slider-shape-3.png`} alt="slider-shape"/>
                <img className="tp-slider-shape-4" src={`${CLIENT}/builder/slider/shape/slider-shape-4.png`} alt="slider-shape"/>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-6 col-md-6">
                        <div className="tp-slider-content p-relative z-index-1">
                            <span>{flatData(item,"smallText")}</span>
                            <h3 className="tp-slider-title text-white">{flatData(item,"bigText")}</h3>
                            <p>{flatData(item,"subText")}</p>

                            {
                                flatData(item,"showLink") ?
                                    <div className="tp-slider-btn">
                                        <Link to={flatData(item,"link")} className="tp-btn tp-btn-2 tp-btn-white">
                                            {flatData(item,"linkText")} <ArrowRight />
                                        </Link>
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6">
                        <div className="tp-slider-thumb text-end">
                            <img src={flatData(item,'image')} alt="slider-img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Hero1 = ({block,customStyle,toggleHightLight,t,isEditor}) => {

    const [items, setItems] = useState([]);


    useEffect(() => {
       setItems(block.contentEditable[0].data)
    }, [block.contentEditable]);


    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const hero1Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :hero1Ref?.current?.offsetTop, left : hero1Ref?.current?.offsetLeft, width : hero1Ref?.current?.offsetWidth, height : hero1Ref?.current?.offsetHeight,nodeName : hero1Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }




    return (
        <section className="tp-slider-area p-relative z-index-1"
                 style={customStyle}
                 ref={hero1Ref}
                 onMouseEnter={() => isEditor && getDimension(block,true)}
                 onMouseLeave={() => isEditor && getDimension(block,false)}

        >
            <div className="tp-slider-active tp-slider-variation swiper-container">
                <div className="swiper-wrapper" >
                   <Swiper
                  speed={1000}
                    grabCursor={true}
                      autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }
                      }
                     loop={true}
                    slidesPerView={items.length > 1 ? 1 : "auto"}
                    modules={[Autoplay,Pagination,Navigation]}
                    pagination={{
                        el : '.swiper-pagination-bullets' , clickable : true
                    }}
                    navigation={{
                        nextEl : ".tp-slider-button-next",
                        prevEl : ".tp-slider-button-prev",
                        clickable : true
                    }}
                   >
                       {
                           items?.length > 0 ?
                               <>
                                   {items?.map((item,key) =>
                                         <SwiperSlide key={key}>
                                             <SliderItem  item={item}  />
                                         </SwiperSlide>
                                   )}
                               </>
                               : ""
                       }
                   </Swiper>
                </div>
                <div className="tp-slider-arrow tp-swiper-arrow d-none d-lg-block">
                    <button type="button" className="tp-slider-button-prev">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <button type="button" className="tp-slider-button-next">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>

               <div className="tp-slider-dot tp-swiper-dot swiper-pagination-clickable swiper-pagination-bullets"></div>

            </div>
        </section>

    )
}

export default withTranslation()(Hero1)
