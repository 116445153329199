import React from 'react';
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {CLI} from "../../../../helpers/utils";

function NotFoundTrialPage({t,domaine}) {

    const history = useHistory()
    return (
        <div className="warning-content">
            <h1 style={{fontSize : "60px",color : "#3377ff",fontWeight : "bold"}}>404</h1>
            <h3>{t('404_title')}</h3>
            <p>{t('404_description')}</p>
            <a className="btn btn-success" href={"http://"+domaine.current+'.'+CLI}>{t('Home')}</a>
        </div>
    );
}

export default withTranslation()(NotFoundTrialPage)