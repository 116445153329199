import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {LINK_LANDING, LINK_LOGIN_STORE} from "../../../routes/type";
import {APPNAME, attachTokenToHeaders, CustomChangeLanguage, SERVER_URL} from "../../../helpers/utils";
import bg from "../Register/register.png";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {MiddleLoading} from "../../../components";
import {toast} from "react-toastify";
import logo from "../../../assets/logo.png";

const Signup = ({t,auth}) => {

    document.title = APPNAME+ " :: "+t('Register')
    const param = useParams();
    const [loading, setLoading] = useState(false);
    const [error,setError] = useState("")
    const [email,setEmail] = useState("")
    const [Gloading,setGLoading] = useState(true)

    const history = useHistory()

    useEffect(() => {
        verifyUrl();
    }, []);

    const verifyUrl = async () => {
        setGLoading(true)
        try {
            await axios.get(SERVER_URL+"user/check/"+param.token)
                .then((response) => {
                    setGLoading(false)
                    if (response.data.succeed){
                        setEmail(response.data.email)
                    }else{
                        setError("WrongLink")
                    }
                })

        } catch (error) {
            setGLoading(false)
            console.log(error);
        }
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('EmailRequired')),
        firstname: Yup.string()
            .required(t('PasswordRequired')),
        lastname: Yup.string()
            .required(t('PasswordRequired')),
        password: Yup.string()
            .required(t('PasswordRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('PasswordContaint')
            )
        ,
        confpassword: Yup.string()
            .required(t('ConfPasswordRequired'))
            .oneOf([Yup.ref('password'), null], t('PasswordSame'))
        ,
    });

    const initialValues = {
        email: email,
        firstname : '',
        lastname : '',
        password: '',
        confpassword : ''
    }


    const handleSubmit = async (values) => {
        try {
            await axios.post(SERVER_URL+"user/add",values,attachTokenToHeaders(true)).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    history.push(LINK_LOGIN_STORE)
                }else{
                    toast.error(t(response.data.message))
                }
            })
        }
        catch (e) {
            toast.error(e.message)
        }
    }



    return (
        <section className="sign-in-section p-b-2">
            <div className="container-fluid reg_panel_all">
                <div className="row">
                    <div className="col-lg-4 left__panel">
                        <div className="">
                            <div className="header__left__panel">
                                <Link to={LINK_LANDING}><h3>{APPNAME}</h3></Link>
                                <div className="d-none d-sm-block">
                                    <button
                                        className={`text-white  ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('fr')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>FR
                                    </button>
                                    <button
                                        className={`text-white ${localStorage.getItem('I18N_LANGUAGE') === "en" && "selected_lng_white"}`}
                                        onClick={event => CustomChangeLanguage('en')}
                                        style={{border: "none", background: "none", marginRight: "10px"}}>EN
                                    </button>
                                </div>
                            </div>
                            <div className="left__panel__content">
                                <h1 className="text-white" style={{fontSize: "40px"}}>{t('CustomerEspace')}</h1>
                                <p>{t('CustomerEspaceText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 bg-white">
                        <div className="">
                            <div className="row">
                                <div className="offset-lg-3 col-lg-6">
                                    <div className="login_panel">
                                        <div className="sign-in-up-wrapper mt-120 ">
                                            {
                                                Gloading ?

                                                    <MiddleLoading />
                                                    :


                                                    <>
                                                        {
                                                            error
                                                                ?
                                                               <div className="login_panel text-center">
                                                                      <i className="ri-error-warning-fill text-danger" style={{fontSize : "200px"}}></i>
                                                                   <h4 className="text-danger">{t('WrongLink')}</h4>
                                                                   <p>{t('WrongLinkText')}</p>
                                                               </div>
                                                                :
                                                                  <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                                                    {({ errors, touched }) =>
                                                                        <Form>
                                                                            <div className="text-center">
                                                                                <img src={logo} alt="kiiako" style={{width: "150px"}}/>
                                                                            </div>

                                                                            <div className="form-groups">
                                                                                <h4 className="form-title text-center">{t('CreateAccount')}</h4>
                                                                                <div className="field-group">
                                                                                    <Field name="email" type="email" className='front'
                                                                                           readOnly={true} placeholder={t('Email')}/>
                                                                                    {errors.email && touched.email ? (
                                                                                        <div className='text-danger'>{errors.email}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div className="field-group">
                                                                                    <Field name="lastname" type="text" className='front'
                                                                                           placeholder={t('Lastname')}/>
                                                                                    {errors.lastname && touched.lastname ? (
                                                                                        <div className='text-danger'>{errors.lastname}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div className="field-group">
                                                                                    <Field name="firstname" type="text" className='front'
                                                                                           placeholder={t('Firstname')}/>
                                                                                    {errors.firstname && touched.firstname ? (
                                                                                        <div
                                                                                            className='text-danger'>{errors.firstname}</div>
                                                                                    ) : null}
                                                                                </div>


                                                                                <div className="field-group">
                                                                                    <Field name="password" type="password" className='front'
                                                                                           placeholder={t('Password')}/>
                                                                                    {errors.password && touched.password ? (
                                                                                        <div className='text-danger'>{errors.password}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div className="field-group">
                                                                                    <Field type="password" name="confpassword"
                                                                                           className='front'
                                                                                           placeholder={t('ConfPassword')}/>
                                                                                    {errors.confpassword && touched.confpassword ? (
                                                                                        <div
                                                                                            className='text-danger'>{errors.confpassword}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div className="">
                                                                                    <button className="template-btn primary-bg-5 btn-block"
                                                                                            type="submit" disabled={loading}>
                                                                                        {
                                                                                            loading ?
                                                                                                <><span
                                                                                                    className="spinner-border spinner-border-sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"/> {t('Loading')}...</>
                                                                                                :
                                                                                                <span>{t('Submit')}</span>
                                                                                        }
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                        </Form>
                                                                    }
                                                                  </Formik>
                                                        }
                                                    </>


                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default withTranslation()(Signup)
