import React, {Fragment, useEffect, useRef, useState} from 'react';
import {withTranslation} from "react-i18next";
import {CLIENT} from "../../../helpers/utils";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {Swiper,SwiperSlide} from "swiper/react";
import {flatData} from "../../utils/utils";
import {Link} from "react-router-dom";




const ArrowRight = () => {
    return (
        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 6.99976L1 6.99976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.9502 0.975414L16.0002 6.99941L9.9502 13.0244" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const SliderItem = ({item}) => {

    const styles = {background: flatData(item,"bgColor")}

    return (
        <div className="tp-slider-item-2 tp-slider-height-2 p-relative swiper-slide d-flex align-items-end"
             style={styles}
        >
            <div className="tp-slider-2-shape">
                <img className="tp-slider-2-shape-1" src={`${CLIENT}/builder/slider/2/shape/shape-1.png`} alt=""/>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="tp-slider-content-2">
                            <span>{flatData(item,"smallText")}</span>
                            <h3 className="tp-slider-title-2">{flatData(item,"bigText")}</h3>
                            {
                                flatData(item,"showLink") ?
                            <div className="tp-slider-btn-2">
                                <Link  className="tp-btn tp-btn-border" to={flatData(item,"link")}>{flatData(item,"linkText")}</Link>
                            </div>
                                    : ""
                            }
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="tp-slider-thumb-2-wrapper p-relative">
                            <div className="tp-slider-thumb-2-shape" style={{zIndex : "9999999999"}}>
                                <img className="tp-slider-thumb-2-shape-1" src={`${CLIENT}/builder/slider/2/shape/shape-2.png`} alt=""/>
                                <img className="tp-slider-thumb-2-shape-2" src={`${CLIENT}/builder/slider/2/shape/shape-3.png`} alt=""/>
                            </div>
                            <div className="tp-slider-thumb-2 text-end">
                                <span className="tp-slider-thumb-2-gradient"></span>
                                <img src={flatData(item,"image")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Hero2 = ({t,block,toggleHightLight,customStyle,isEditor}) => {


    const [items, setItems] = useState([]);


    useEffect(() => {
        setItems(block.contentEditable[0].data)
    }, [block.contentEditable]);



    const [dimension, setDimension] = useState({
        top :0,
        left : 0,
        width : 0,
        height : 0,
    })

    const hero2Ref = useRef()

    const getBlockSize = () => {
        setDimension({top :hero2Ref?.current?.offsetTop, left : hero2Ref?.current?.offsetLeft, width : hero2Ref?.current?.offsetWidth, height : hero2Ref?.current?.offsetHeight,nodeName : hero2Ref?.current?.nodeName})
    };

    const getDimension = (data,status) => {
        getBlockSize()

        toggleHightLight(data,dimension,status)
    }


    return (
        <section className="tp-slider-area p-relative z-index-1"
                 ref={hero2Ref}
                 onMouseEnter={() => isEditor ? getDimension(block,true) : console.log("")}
                 onMouseLeave={() => isEditor ? getDimension(block,false) : console.log("")}
                 style={customStyle}
        >
            <div className="tp-slider-active-2 swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        modules={[Autoplay,Pagination,Navigation]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }
                        }
                        loop={true}
                        slidesPerView={items.length > 1 ? 1 : "auto"}
                        pagination={{
                            el : '.swiper-pagination-bullets' , clickable : true
                        }}
                        navigation={{
                            nextEl : ".tp-slider-button-next",
                            prevEl : ".tp-slider-button-prev",
                            clickable : true
                        }}
                    >


                                    {items?.map((item,key) =>
                                            <SwiperSlide key={key}>
                                                <SliderItem  item={item}  />
                                            </SwiperSlide>
                                    )}


                    </Swiper>
                </div>
                <div className="tp-swiper-dot tp-slider-2-dot swiper-pagination-clickable swiper-pagination-bullets"></div>
            </div>
        </section>
    )
}

export default withTranslation()(Hero2)
