import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {APPNAME, attachTokenToHeaders, SERVER_URL, storage_manage} from "../../../../helpers/utils";
import {getSubcategories} from "../../../../store/Categories/action";
import {LINK_STORE_CATEGORIES, LINK_STORE_SUBCATEGORIES} from "../../../../routes/type";
import {Link, useHistory} from "react-router-dom";


import {EditorState, convertToRaw, ContentState, convertFromHTML} from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import axios from "axios";
import {toast} from "react-toastify";

import tips from "../../../../assets/tips.svg"
import Cookies from "js-cookie";
import ProgressModal from "../../../../components/Loading/ProgressModal";
import Authorization from "../../../../components/Authorization";
import DynamicFieldInput from "../../../../components/CustomInputs/DynamicFieldInput";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)


const ProductCreate = ({t,auth,list : {isLoading,categories,subcategories},getSubcategories}) => {
    document.title = APPNAME+" :: " + t('Add') +" "+ t('Product')

    useEffect(()=>{
        getSubcategories(auth.shop?.id)
    },[auth])

    const token = Cookies.get('auth-token')

    const history = useHistory()

    const [files,setFiles] = useState([])
    const [downloadableFile, setDownloadableFile] = useState([]);
    const [progress, setProgress] = useState(0);
    const [open, setOpen] = useState(false);

    const [product,setProduct] = useState({
          title : "",
          description : "",
          statut : 1,
          category : "",
          badge : "",
          isSubcategory : false,
          isDownloadable : false,
          subcategory : "",
          short_description : "",
          stock : "",
          discount : 0,
          price : "",
          shop_id : auth.shop?.id
    })

    const [errors,setErrors] = useState({
        title : false,
        files : false,
        description : false,
        badge : false,
        statut : false,
        category : false,
        download : false,
        subcategory : false,
        short_description : false,
        stock : false,
        discount : false,
        price : false
    })

    const [subcates,setSubcates] = useState([])
    const [variants,setVariant] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML("<p>Description...</p>"))))
    const [loading,setLoading] = useState(false)
    const [loadingS,setLoadingS] = useState(false)

    const handleEditorChange = (data) => {
        setEditorState(data);
         let html  = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        setProduct({
            ...product,
             description: html
        })
    };

    const badges = [
        {name : 'Hot'},
        {name : 'Trending'}
    ]


    const onChangeValue = e => {
        setProduct({
            ...product,
             [e.target.name] : e.target.value
        })

        setErrors({
            ...errors,
            [e.target.name] : false
        })
    }
    const filterSubCategory = (e) =>{

        setProduct({
            ...product,
             category: e.target.value
        })

        const filter = subcategories?.filter(cat => cat.category_id == e.target.value)
        setSubcates(filter)
    }




    const submitProduct =  async (stay) =>{

        var error = false

       if (product.title == "") {
          setErrors({...errors,title: true})
           error = true
       }

       else if (product.description == "") {
            setErrors({...errors,description: true})
            error = true
        }

       else if (product.short_description == "") {
            setErrors({...errors,short_description: true})
            error = true
        }

       else if (product.category == "") {
            setErrors({...errors,category: true})
            error = true
        }

       else if (product.isSubcategory) {
            if (product.subcategory == ""){
                setErrors({...errors,subcategory: true})
                error = true
            }
        }

       else if (product.isDownloadable) {
            if (downloadableFile.length == 0){
                setErrors({...errors,download: true})
                error = true
            }
        }

       else if (product.badge == "") {
            setErrors({...errors,badge: true})
            error = true
        }

       else if (product.price == "") {
            setErrors({...errors,price: true})
            error = true
        }

       else if (product.stock == "") {
            setErrors({...errors,stock: true})
            error = true
        }

       else if (product.statut == "") {
            setErrors({...errors,statut: true})
            error = true
        }


       else  if (files.length == 0){
            setErrors({...errors, files : true})
            error = true
        }




        if (!error) {
            setErrors({
                title : false,
                files : false,
                description : false,
                badge : false,
                statut : false,
                category : false,
                subcategory : false,
                short_description : false,
                stock : false,
                discount : false,
                price : false
            })

           let storage = 0

            for(let i =0; i < files.length; i++) {
               storage += files[i].size
            }

           storage += downloadableFile[0]?.size


           if (storage !== 0){
               if (storage_manage(auth?.sidebar_n_storage?.storage,storage)){

                   if (stay){
                       setLoadingS(true)
                   }else{
                       setLoading(true)
                   }

                   const config = {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded',
                           'Accept' : "application/json",
                           'auth-token' : token
                       },
                       onUploadProgress: (progressEvent) => {
                           const progress = (progressEvent.loaded / progressEvent.total) * 100;
                           setProgress(parseInt(progress));
                       },
                       onDownloadProgress: (progressEvent) => {
                           const progress = (progressEvent.loaded / progressEvent.total) * 100;
                           setProgress(parseInt(progress));
                       },
                   };



                   const formData = new FormData()
                   formData.append('title',product.title)
                   formData.append('description',product.description)
                   formData.append('short_description',product.short_description)
                   formData.append('category',product.category)
                   formData.append('isSubcategory',product.isSubcategory)
                   formData.append('isDownloadable',product.isDownloadable)
                   formData.append('subcategory',product.subcategory)
                   formData.append('badge',product.badge)
                   formData.append('type',"products")
                   formData.append('price',product.price)
                   formData.append('stock',product.stock)
                   formData.append('statut',product.statut)
                   formData.append('variants',JSON.stringify(variants))
                   formData.append('shop_id',auth.shop.id)
                   for(let i =0; i < files.length; i++) {
                       formData.append("galerie",files[i]);
                   }
                   formData.append("file",downloadableFile[0]);

                   if(product.isDownloadable){
                       setOpen(true)
                   }

                   await axios.post(SERVER_URL+"product/add",formData,config).then((response)=>{
                       setLoading(false)
                       setLoadingS(false)

                       if (response.data.succeed){
                           toast.success(t(response.data.message))
                           setProduct({
                               title : "",
                               description : "",
                               statut : "",
                               category : "",
                               isSubcategory : false,
                               isDownloadable : false,
                               subcategory : "",
                               short_description : "",
                               stock : "",
                               badge: "",
                               discount : 0,
                               price : ""
                           })
                           setVariant([])
                           setFiles([])
                           setDownloadableFile([])
                           setEditorState(EditorState.createEmpty())
                           setOpen(false)
                           setTimeout(()=>{
                               if (!stay) history.goBack()
                               else window.scrollTo(0, 0);
                           },1000)
                       }
                       else{
                           toast.error(t(response.data.message))
                       }
                   }).catch((error) => {
                       setLoading(false)
                       setLoadingS(false)
                       setOpen(false)
                       toast.error(t('TypeFileError'))
                   })

               } else{
                   toast.error(t("NoAvailableStorage"))
               }
           }
       }
    }

    const cancelForm = () => {

    }




    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Add')} {t('Product')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item">{t('Product')}</li>
                                <li className="breadcrumb-item active">{t('Add')}</li>

                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <Authorization single={true}  module="products" trial={true} component={true} >
            <div id="createproduct-form">
                <div className="row">
                    <div className="offset-lg-1 col-lg-7">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label text-muted" htmlFor="product-title-input">{t('Name')}  <b className="text-danger">*</b></label>
                                     <input type="text" className="form-control" id="product-title-input" name="title" value={product.title} onChange={event => onChangeValue(event)} placeholder="" required/>
                                    {errors.title && <span className="text-danger">{t('InputRequired')}</span>}
                                </div>

                                <div className="mb-5">
                                    {
                                        isLoading ?
                                            <p className="text-center">
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                            </p>
                                            :

                                            <>
                                                <p className="text-muted mb-2">
                                                    <Link to={LINK_STORE_CATEGORIES}  className="float-end text-decoration-underline cursor-pointer text-primary" style={{fontSize : "12px"}}>{t('AddNew')}</Link>
                                                    <span>{t('ChooseCategory')} <b className="text-danger">*</b></span>
                                                </p>
                                                <select className="form-select" id="choices-category-input" name="category" onChange={event => filterSubCategory(event)} >
                                                    <option value="">-</option>
                                                    {
                                                        categories?.map((cate,key)=>
                                                            <option key={key} value={cate.id}>{cate.category}</option>
                                                        )
                                                    }

                                                </select>

                                                {errors.category && <span className="text-danger">{t('InputRequired')}</span>}

                                                {
                                                    product.category !== "" &&
                                                    <>
                                                        <hr/>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={event => setProduct({
                                                                ...product,
                                                                isSubcategory: event.target.checked
                                                            })} />
                                                            <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked">{t('EnableSubCategory')}</label>
                                                        </div>
                                                    </>
                                                }


                                                {
                                                    product.isSubcategory &&

                                                    <>
                                                        <hr/>
                                                        <p className="text-muted mb-2">
                                                            <Link to={LINK_STORE_SUBCATEGORIES}  className="float-end text-decoration-underline cursor-pointer text-primary" style={{fontSize : "12px"}}>{t('AddNew')}</Link>
                                                            <span>{t('ChooseSubcategory')}</span>
                                                        </p>
                                                        <select className="form-select" id="choices-category-input" name="subcategory" onChange={event => onChangeValue(event)} >
                                                            <option value="">-</option>
                                                            {
                                                                subcates?.map((subcat,key)=>
                                                                    <option key={key} value={subcat.id}>{subcat.subname}</option>
                                                                )
                                                            }

                                                        </select>

                                                        {errors.subcategory && <span className="text-danger">{t('InputRequired')}</span>}
                                                    </>
                                                }

                                            </>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0">{t('Description')} <b className="text-danger">*</b></h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <small>{t('ProductDesc1')}</small>
                                        <br/>
                                        <br/>
                                        <small>{t('ProductDesc2')}</small>
                                        <br/>
                                        <hr/>
                                        <h6>{t('Short')} Description <b className="text-danger">*</b></h6>
                                        <textarea className="form-control" maxLength={100} placeholder="" rows="3" name="short_description" value={product.short_description} onChange={event => onChangeValue(event)}></textarea>
                                        {errors.short_description && <span className="text-danger">{t('InputRequired')}</span>}
                                    </div>
                                    <div className="col-lg-8">
                                        <div>
                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={handleEditorChange}
                                            />

                                            {errors.description && <span className="text-danger">{t('InputRequired')}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card">
                            <div className="card-header">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexCheckChecked" onChange={event => setProduct({
                                        ...product,
                                        isDownloadable: event.target.checked
                                    })} />
                                    <label className="form-check-label text-muted" htmlFor="flexCheckChecked">{t('DownloadableFile')}</label>
                                </div>
                            </div>

                            {
                                product.isDownloadable ?
                                <div className="card-body">
                                    <div className="">
                                        <h5 className="fs-14 mb-1">{t('MaxDependOnYourPlan')}</h5>
                                        <span className="text-muted mb-5">{t('FileExample')}</span>
                                        <br/>
                                        <FilePond
                                            files={downloadableFile}
                                            allowMultiple={true}
                                            maxFiles={1}
                                            name="download"
                                            id="download"
                                            onupdatefiles={(fileItems) => {
                                                setDownloadableFile(fileItems.map((fileItem) => fileItem.file))
                                            }}
                                            labelIdle={t('DragNdrop')}
                                        />
                                        {errors.download && <span className="text-danger">{t('InputRequired')}</span>}
                                        <br/>
                                    </div>
                                </div> : null
                            }
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0">{t('ProductGallery')} <b className="text-danger">*</b></h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <small>{t('AddGalleryImageText1')}</small>
                                        <br/>
                                        <br/>
                                        <small>{t('AddGalleryImageText2')}</small>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="text-center adding_page">
                                            <FilePond
                                                files={files}
                                                allowMultiple={true}
                                                acceptedFileTypes={["image/jpg", "image/jpeg","image/png"]}
                                                maxFiles={5}
                                                name="file"
                                                id="file"
                                                onupdatefiles={(fileItems) => {
                                                    setFiles(fileItems.map((fileItem) => fileItem.file))
                                                }}
                                                labelIdle={t('DragNdrop')}
                                            />

                                            {errors.files && <span className="text-danger">{t('InputRequired')}</span>}
                                        </div>
                                      <p className='text-right'> <small className="text-muted">{t('PicList')}  <span className='text-danger'>(Max :  5)</span>.</small></p>
                                   </div>
                                </div>


                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-bs-toggle="tab"
                                           href="#addproduct-general-info" role="tab">
                                            {t('GeneralInportant')}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="card-body">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="addproduct-general-info" role="tabpanel">
                                        <p>{t('discountTextOptional')}</p>
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="stocks-input"> {t('Stocks')} <b className="text-danger">*</b></label>
                                                    <input type="text" className="form-control" id="stocks-input" name="stock" value={product.stock} onChange={event => onChangeValue(event)} placeholder={t("Stocks")} required/>
                                                 </div>
                                                {errors.stock && <span className="text-danger">{t('InputRequired')}</span>}
                                            </div>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="product-price-input">{t('Price')} <b className="text-danger">*</b><small>(F CFA)</small></label>
                                                    <div className="input-group has-validation mb-3">
                                                        <input type="text" className="form-control" placeholder={t("Price")} name="price" value={product.price} onChange={event => onChangeValue(event)}  required/>
                                                    </div>
                                                    {errors.price && <span className="text-danger">{t('InputRequired')}</span>}

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="product-discount-input"> {t('Discount')} <small>(%)</small></label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder={t("Discount")}  name="discount" value={product.discount} onChange={event => onChangeValue(event)} />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label className="">{t("Badge")} <b className="text-danger">*</b></label>
                                                <div className="flex-grow-1">
                                                    <select name="" id="" className="form-select"
                                                            value={product.badge}
                                                            onChange={event => setProduct({...product,badge : event.target.value})}
                                                    >
                                                        <option value="">-</option>
                                                        {
                                                            badges.map((badge, index) => (
                                                                <option key={index} value={badge.name}>{t(badge.name)}</option>
                                                            ))
                                                        }
                                                    </select>

                                                    {errors.badge && <span className="text-danger">{t('InputRequired')}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>{t('Visibility')} <b className="text-danger">*</b></label>
                                                <div>
                                                    <select className="form-select" id="choices-publish-visibility-input" name="statut" onChange={event => onChangeValue(event)}  value={product.statut}>
                                                        <option value="">-</option>
                                                        <option value="1">Public</option>
                                                        <option value="2">{t('Private')}</option>
                                                    </select>
                                                    {errors.statut && <span className="text-danger">{t('InputRequired')}</span>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="card">
                            <div className="card-header"><h5>{t('AdditionalInfo')}</h5></div>
                            <div className="card-body">
                                <div className="row">
                                    <DynamicFieldInput setVariant={setVariant} />
                                </div>

                            </div>

                        </div>



                        <div className="text-end mb-3">
                            <button type="button" onClick={event => cancelForm()} className="btn btn-dark " >
                               {t('Cancel')}
                            </button>

                            <button type="button" onClick={event => submitProduct(true)} className="btn btn-success ml-5" disabled={loadingS}>
                                {
                                    loadingS ?
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                        </>
                                        :
                                        <>{t('SubmitAndAdd')}</>
                                }

                            </button>
                            <button type="button" onClick={event => submitProduct()} className="btn btn-success ml-5" disabled={loading}>
                                {
                                    loading ?
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                        </>
                                        :
                                        <>{t('Submit')}</>
                                }

                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <div className={product.title && product.category ? "prod__success" : ""}>{t('ProductInfo')}</div>
                            <br/>
                            <div className={product.description && product.short_description ? "prod__success" : ""}>{t('Description')}</div>
                            <br/>
                            <div className={files.length > 0 ? "prod__success" : ""}>{t('ProductGallery')}</div>
                            <br/>
                            <div className={product.price && product.stock ? "prod__success" : ""}>{t('Price')} & {t('Stock')}</div>
                            <br/>
                            <div className={product.badge && product.statut ? "prod__success" : ""}>{t('Variants')}</div>
                        </div>
                    </div>
                        <div className="card">
                            <div className="card-body">
                                <img className="img-responsive" src={tips} />
                                <br/>
                                <small>{t('AddGalleryImageText1')}</small>
                                <br/>
                                <br/>
                                <small>{t('AddGalleryImageText2')}</small>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
            </Authorization>
            <ProgressModal
             progress={progress.toFixed(1)}
             title={t('Uploading')}
             setOpen={setOpen}
             open={open}
            />
        </AdminLayout>
    );
}


const mapStateToProps = (state) => ({
    auth : state.loginShop,
    list : state.categorie
});

export default   compose(connect(mapStateToProps, { getSubcategories })) (withTranslation()(ProductCreate));
