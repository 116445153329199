import React, {useEffect, useState} from 'react';
import avatar from "../../../../assets/admin/images/avatar-blank.png"
import bg from "../../../../assets/admin/images/profile-bg.jpg"
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {attachTokenToHeaders, format_date, numberWithCommas, SERVER_URL} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import {Link, useParams} from "react-router-dom";
import AdminLayout from "../../../../layouts/admin/AdminLayout";

const  CustomerDetail = ({t,auth,match}) => {

const params = useParams()

    const [loading,setLoading] = useState(true)
    const [detail, setDetail] = useState(null);
    const [orders, setOrders] = useState([]);


    useEffect(()=>{
        getCustomerInfo()
    },[match])




    const getCustomerInfo = async () => {
        await axios.get(SERVER_URL+"customer/detail/"+window.location.pathname.split("/")[window.location.pathname.split("/").length - 1],attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setDetail(response.data.detail)
                setOrders(response.data.orders)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        }).catch((err) => {
            if (err.response.status === 401){
                window.location.reload(true)
            }
        })
    }



    return (
        <AdminLayout>

            {
                loading ?
                    <div className="card">
                        <div className="card-body">
                            <p className="text-center p-t-150 p-b-150">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                            </p>
                        </div>
                    </div>

                    :

                    <>
                        <div className="profile-foreground position-relative mx-n4 mt-n4">
                            <div className="profile-wid-bg">
                                <img src={bg} alt="" className="profile-wid-img"/>
                            </div>
                        </div>
                        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                            <div className="row g-4">
                                <div className="col-auto">
                                    <div className="avatar-lg">
                                        <img src={avatar} alt="user-img"
                                             className="img-thumbnail rounded-circle"/>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="p-2">
                                        <h3 className="text-white mb-1">{detail.firstname} {detail.lastname}</h3>
                                        <div className="hstack text-white-50 gap-1">
                                            <div className="me-2"><i
                                                className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i> {detail?.city}, {detail?.country}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <div className="tab-content pt-4 text-muted">
                                        <div className="tab-pane active" id="overview-tab" role="tabpanel">
                                            <div className="row">
                                                <div className="col-xxl-3">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title mb-3">Info</h5>
                                                            <div className="table-responsive">
                                                                <table className="table table-borderless mb-0">
                                                                    <tbody>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">{t('Fullname')} :</th>
                                                                        <td className="text-muted">{detail.firstname} {detail.lastname}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">{t('Phone')} :</th>
                                                                        <td className="text-muted">{detail.phone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">E-mail :</th>
                                                                        <td className="text-muted">{detail.email}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">{t('Location')} :</th>
                                                                        <td className="text-muted">{detail?.city}, {detail?.country}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">{t('Joined')}</th>
                                                                        <td className="text-muted">{format_date(detail?.created_at)}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h1 className="text-center" style={{fontSize : "80px"}}>{orders?.length}</h1>
                                                            <p className='text-center'>{t('Orders')}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-xxl-9">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title mb-3">{t('OrdersHistoric')}</h5>
                                                            <hr/>


                                                            <table className="table table-striped">
                                                                <thead>
                                                                  <tr>
                                                                      <th></th>
                                                                      <th></th>
                                                                      <th></th>
                                                                      <th></th>
                                                                      <th></th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    orders?.length > 0
                                                                        ?
                                                                        <>
                                                                            {
                                                                                orders.map((item,key) =>

                                                                                    <tr key={key} >
                                                                                        <td >
                                                                                            {item?.delivery_status == 1 ? <lord-icon src="https://cdn.lordicon.com/slkvcfos.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"40px",height:"40px"}}></lord-icon> : null}
                                                                                            {item?.delivery_status == 2 ? <lord-icon src="https://cdn.lordicon.com/vpgfeeei.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"40px",height:"40px"}}></lord-icon> : null}
                                                                                            {item?.delivery_status == 3 ? <lord-icon src="https://cdn.lordicon.com/uetqnvvg.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"40px",height:"40px"}}></lord-icon> : null}
                                                                                            {item?.delivery_status == 4 ?  <lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"40px",height:"40px"}}></lord-icon> : null}
                                                                                            {item?.delivery_status == 5 ? <lord-icon src="https://cdn.lordicon.com/iiueiwdd.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"40px",height:"40px"}}></lord-icon> : null}
                                                                                            {item?.delivery_status == 6 ?   <i className="fa fa-window-close fa-5x text-danger"></i> : null}

                                                                                        </td>
                                                                                        <td >

                                                                                            {
                                                                                                JSON.parse(item?.cart_content)?.map((it, key) =>

                                                                                                    <p key={key}>
                                                                                                        <b className="fs-13 m-r-2 text-primary">{it.name}</b>
                                                                                                        <span
                                                                                                            style={{marginLeft: "10px"}}> U : {numberWithCommas(it.price)} Frcs</span>
                                                                                                        <span
                                                                                                            style={{marginLeft: "10px"}}>(Qty : {it.qty})</span>

                                                                                                    </p>
                                                                                                )
                                                                                            }
                                                                                        </td>
                                                                                        <td >
                                                                                            {numberWithCommas(item.total_amount)} Frcs
                                                                                        </td>
                                                                                        <td >
                                                                                            <Link className=" text-decoration-underline bold" to={`/store/order/${item.order_id}`}>Detail</Link>
                                                                                        </td>
                                                                                        <td>
                                                                                            <p className="text-muted p-t-20">{format_date(item.created_at)}</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </>

                                                                        :
                                                                        <tr className="text-center">
                                                                            <td colSpan="4">{t('EmptyList')}</td>
                                                                        </tr>

                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});
export default compose(connect(mapStateToProps))(withTranslation()(CustomerDetail));
