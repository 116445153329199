import React, { useEffect, useState } from 'react';
import Layout from "../../../layouts/front/Layout";
import { withTranslation } from "react-i18next";
import { APPNAME, bytesToSize, numberWithCommas, SERVER_URL } from "../../../helpers/utils";
import { Link } from "react-router-dom";
import { LINK_REGISTER_STORE } from "../../../routes/type";
import axios from "axios";
import { toast } from "react-toastify";
import MiddleLoading from "../../../components/Loading/MiddleLoading";

const Tarification = ({ t }) => {

    document.title = APPNAME + " :: " + t('Pricing')

    document.querySelector("meta[name='title']").setAttribute("content", APPNAME + " :: " + t('Pricing'))
    document.querySelector("meta[name='description']").setAttribute("content", "Commencer à créer votre boutique à partir de nos forfaits disponibles.")

    const [loading, setLoading] = useState(true);
    const [pricing, setPricing] = useState([]);
    const [plan, setPlan] = useState([]);
    const [storages, setStorages] = useState([]);
    const [isAnnual, setIsAnnual] = useState(false);
    const [discount, setDiscount] = useState(null);

    useEffect(() => {
        getPricing();
    }, []);

    const getPricing = async () => {
        await axios.get(SERVER_URL + 'shop/pricing').then((response) => {
            setLoading(false)
            if (response.data.succeed) {
                setPricing(response.data.pricing)
                setPlan(response.data.plan)
                setStorages(response.data.storage)
                setDiscount(response.data.discount)
                setLoading(false)
            } else {
                toast.error(t(response.data.message))
                setLoading(false)
            }
        })
    }

    const switchPricing = () => {
        setIsAnnual(!isAnnual);
    }

    const calculateFinalPrice = (price) => {
        // Ensure price.monthlyPrice is a number
        const monthlyPrice = Number(price.price) || 0;
        let finalPrice = isAnnual ? monthlyPrice * 12 : monthlyPrice;

        if (isAnnual && discount) {
            const discountParams = discount?.params.split(',');
            const discountValue = parseFloat(discountParams[1]) || 0;
            const discountPrice = (finalPrice * discountValue) / 100;
            finalPrice  = finalPrice - discountPrice
        }


        return finalPrice.toFixed(0);
    }

    return (
        <Layout>
            {
                loading ?
                    <MiddleLoading />
                    :
                    <section className="shop-area p-t-180 p-b-130">
                        <div className="container">
                            <div className="row">
                                <div className="offset-lg-2 col-lg-8">
                                    <div className="header_custom_pricing">
                                        <h1>{t('Pricing')}</h1>
                                        <p>{t('StartBuildingYourShop')}</p>
                                        <br />
                                        <Link className="template-btn primary-bg-5 btn-lg m-b-100" to={LINK_REGISTER_STORE}>{t('RegisterShop')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Pricing cards */}
                        <div className="container-fluid pricing_group">
                            <div className="w-table">
                                <div className="w-table-cell">
                                    <div className="w-container">
                                        {
                                            pricing?.length > 0 &&
                                            <>
                                                {
                                                    pricing.map((price, key) =>
                                                        <div className="w-card color-blue" key={key}>
                                                            <div className="card-header">
                                                                <div className="w-title" style={{ color: price.color }}>
                                                                    {price.name}
                                                                </div>
                                                                <div className="w-price">
                                                                    {numberWithCommas(Number(price.price).toFixed(0))} <sub>Frcs</sub>
                                                                </div>
                                                                <div className="container-button">
                                                                    <Link className="w-button" to={LINK_REGISTER_STORE}>{t('GetStart')}</Link>
                                                                </div>
                                                            </div>
                                                            <div className="card-content">
                                                                <p style={{ fontSize: "12px" }}>
                                                                    {price.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Pricing cards End */}

                        {
                            plan?.length > 0 &&
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="offset-lg-1 col-lg-10">
                                        <div className="main">
                                            <div className="text-center ml-200 pl-80 pb-30">
                                                <div className="pricing-switcher">
                                                    <div className="fieldset">
                                                        <input type="radio" name="group-a" value="monthly" id="monthly-1" checked={!isAnnual} onChange={switchPricing} />
                                                        <label htmlFor="monthly-1">{t('Monthly')}</label>
                                                        <input type="radio" name="group-a" value="annualy" id="annualy-1" checked={isAnnual} onChange={switchPricing} />
                                                        <label htmlFor="annualy-1">{t('Annuel')}</label>
                                                        <span className="switch"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="price-table">
                                                <tbody>
                                                <tr>
                                                    <td className="price-blank" style={{ backgroundColor: '#fff', border: "0 none !important" }}></td>
                                                    <td className="price-blank"></td>
                                                    <td className="price-blank"></td>
                                                    <td className="price-table-popular">{t('MostPopular')}</td>
                                                    <td className="price-blank"></td>
                                                    <td className="price-blank"></td>
                                                </tr>
                                                <tr className="price-table-head">
                                                    <td style={{ backgroundColor: '#fff', border: "0 none !important" }}></td>
                                                    {
                                                        pricing?.length > 0 &&
                                                        <>
                                                            {
                                                                pricing?.map((price, key) =>
                                                                    <td key={key}>{price.name}</td>
                                                                )
                                                            }
                                                        </>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ backgroundColor: '#fff' }}></td>
                                                    {
                                                        pricing?.length > 0 &&
                                                        <>
                                                            {
                                                                pricing?.map((price, key) =>
                                                                    <td className="price" key={key}>
                                                                        <br/> {key > 0 ? numberWithCommas(calculateFinalPrice(price)) : price.price}
                                                                        <small><small>Frcs</small></small>
                                                                        <br/>
                                                                        <Link
                                                                            to={LINK_REGISTER_STORE}>{t('GetStart')}</Link>
                                                                    </td>
                                                                )
                                                            }
                                                        </>

                                                    }

                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('Domaine')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{plan[index]?.domaine_name &&
                                                                <i className="fa fa-check text-success"></i>}</td>
                                                        )
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('ThemeCustomizable')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{plan[index]?.theme_customizable ?
                                                                <i className="fa fa-check text-success"></i> :
                                                                <i className="fa fa-times text-danger"></i>}</td>
                                                        )
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('MultiLangue')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{plan[index]?.multi_langue ?
                                                                <i className="fa fa-check text-success"></i> :
                                                                <i className="fa fa-times text-danger"></i>}</td>
                                                        )
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('FolderSize')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{bytesToSize(storages[parseInt(plan[index].folder_size) - 1]?.storage)}</td>
                                                        )
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('Sponsorship')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{plan[index]?.sponsor_shipping ?
                                                                <i className="fa fa-check text-success"></i> :
                                                                <i className="fa fa-times text-danger"></i>}</td>
                                                        )
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('AccountNumber')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{plan[index]?.account_number}</td>
                                                        )
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('Ads')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{plan[index]?.mobile_access ?
                                                                <i className="fa fa-check text-success"></i> :
                                                                <i className="fa fa-times text-danger"></i>}</td>
                                                        )
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="price-table-help">{t('Support')}</td>
                                                    {
                                                        Array.apply(null, Array(plan?.length)).map((_, index) =>
                                                            <td key={index}>{plan[index]?.support_tech ?
                                                                <i className="fa fa-check text-success"></i> :
                                                                <i className="fa fa-times text-danger"></i>}</td>
                                                        )
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </section>
            }
        </Layout>
    )
}

export default withTranslation()(Tarification)
