import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {
    LINK_STORE_CATEGORIES,
    LINK_STORE_COUPON,
    LINK_STORE_CUSTOMERS,
    LINK_STORE_DASHBOARD,
    LINK_STORE_ORDERS,
    LINK_STORE_PAGES,
    LINK_STORE_PAYMENT,
    LINK_STORE_USERS,
    LINK_STORE_PRODUCTS,
    LINK_STORE_SETTINGS,
    LINK_STORE_SUBCATEGORIES,
    LINK_STORE_SUPPORT,
    LINK_STORE_PERMISSIONS,
    LINK_STORE_ASSISTANCE,
    LINK_STORE_PACKAGES,
    LINK_STORE_APPS
} from "../../routes/type";
import {withTranslation} from "react-i18next";
import {ActiveMenu, attachTokenToHeaders, SERVER_URL} from "../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import Authorization from "../../components/Authorization";
import LevelAccess from "../../components/LevelAccess";

const ShopkeeperSidebar = ({t,auth}) => {

    useEffect(() => {

        const links = document.querySelectorAll(   `#navbar-nav  a`)


        for (let i =0;i < links?.length;i++) {
            if (links[i].getAttribute('href') === window.location.pathname) {

                if (links[i].parentElement.parentElement.parentElement.getAttribute('id')){
                    const doc = document.getElementById(links[i].parentElement.parentElement.parentElement.getAttribute('id'))
                    const parent = document.getElementById(links[i].parentElement.parentElement.parentElement.getAttribute('id')+'-parent')
                    parent.classList.remove("collapsed")
                    document.getElementById(links[i].parentElement.parentElement.parentElement.getAttribute('id')+'-parent').setAttribute("aria-expanded", "true");
                    doc.classList.add("show")
                }

            }
        }


    }, [window.location]);

    const toggleCollapsed = (id) => {
        const doc = document.getElementById(id)
        const parent = document.getElementById(id+'-parent')

            if(parent.classList.contains("collapsed")){
                parent.classList.remove("collapsed")
                document.getElementById(id+'-parent').setAttribute("aria-expanded", "true");
                doc.classList.add("show")
            }else{
                parent.classList.add("collapsed")
                document.getElementById(id+'-parent').setAttribute("aria-expanded", "false");
                doc.classList.remove("show")
            }

    }


    return (
        <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title"><span data-key="t-menu">Menu</span></li>
            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_DASHBOARD)}`} to={LINK_STORE_DASHBOARD} >
                    <i className="ri-dashboard-2-line"></i> <span >{t('Dashboard')}</span>
                </Link>
            </li>

            <Authorization single={true}  module="pages">
            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_PAGES)}`} to={LINK_STORE_PAGES} >
                    <i className="ri-apps-2-line"></i> <span>Pages</span>
                </Link>
            </li>
            </Authorization>


            <Authorization single={true}  module="plugins">
                <LevelAccess auth={auth} level={2} link={true}>
                    <li className="nav-item">
                        <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_PACKAGES)}`}
                              to={LINK_STORE_PACKAGES}>
                            <i className=" bx bx-chip"></i> <span>Marketplace</span>
                        </Link>
                    </li>
                </LevelAccess>
            </Authorization>


            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-pages">{t('Shop')}</span></li>


            <Authorization single={true} module="orders">
                <li className="nav-item">
                    <Link to={LINK_STORE_ORDERS} className={`nav-link menu-link ${ActiveMenu(LINK_STORE_ORDERS)}`} >
                    <i className="ri-shopping-bag-2-fill"></i> <span>{t('Orders')}</span>
                </Link>

            </li>
            </Authorization>


            <Authorization single={true}  module="customers">
            <li className="nav-item">
                <Link  to={LINK_STORE_CUSTOMERS} className={`nav-link menu-link ${ActiveMenu(LINK_STORE_CUSTOMERS)}`} >
                    <i className="ri-account-circle-line"></i> <span>{t('Customers')}</span>
                </Link>
            </li>
            </Authorization>

            <Authorization single={true}  module="payments">
            <li className="nav-item">
                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_PAYMENT)}`} to={LINK_STORE_PAYMENT}>
                    <i className="ri-pages-line"></i> <span>{t('Payments')}</span>
                </Link>
            </li>
            </Authorization>


            <Authorization single={false}  module="categories" bloc={['categories','subcategories','products','coupon']} >
            <li className="nav-item">
                <a className="nav-link menu-link collapsed" id="shop-parent" onClick={event => toggleCollapsed('shop')}
                   data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="shop">
                    <i className="ri-stack-line" ></i> <span data-key="t-apps">{t('Store')}</span>
                </a>
                <div className="menu-dropdown collapse" id="shop">
                    <ul className="nav nav-sm flex-column">
                        <Authorization single={true}  module="categories">
                        <li className="nav-item">
                            <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_CATEGORIES)}`} to={LINK_STORE_CATEGORIES} >
                                <span data-key="t-base-ui">{t("Category")}</span>
                            </Link>
                        </li>
                        </Authorization>

                        <Authorization single={true}  module="subcategories">
                        <li className="nav-item">
                            <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_SUBCATEGORIES)}`} to={LINK_STORE_SUBCATEGORIES} >
                                <span>{t("SubCategories")}</span>
                            </Link>
                        </li>
                        </Authorization>

                        <Authorization single={true}  module="products">
                        <li className="nav-item">
                            <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_PRODUCTS)}`} to={LINK_STORE_PRODUCTS} >
                                <span>{t("Products")}</span>
                            </Link>
                        </li>
                        </Authorization>

                        <Authorization single={true}  module="coupon">
                        <li className="nav-item">
                            <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_COUPON)}`} to={LINK_STORE_COUPON} >
                                <span>{t("Coupon")}</span>
                            </Link>
                        </li>
                        </Authorization>
                    </ul>
                </div>
            </li>
            </Authorization>




            <LevelAccess auth={auth} link={true} level={2}>
            {
                 auth?.plugins?.length > 0 &&  <Authorization single={true}  module="applications"><li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-components">{t('Modules')}</span></li></Authorization>
            }

            {
                auth?.plugins?.length > 0 &&
                <Authorization single={true}  module="applications">
                    <li className="nav-item">
                        <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_APPS)}`} to={LINK_STORE_APPS}>
                            <i className="bx bx-package"></i> <span>{t('Applications')}</span>
                        </Link>
                    </li>
                </Authorization>
            }

            </LevelAccess>

            <Authorization single={true}  module="supportToCustomer">
            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-components">{t('Settings')}</span></li>
            </Authorization>

            <Authorization single={false}  module="supportToCustomer" bloc={['supportToCustomer','supportToAdmin']} >
            <li className="nav-item">
                <a className="nav-link menu-link collapsed" id="support-parent" onClick={event => toggleCollapsed('support')}
                   data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="selling">
                    <i className="ri-customer-service-2-line" ></i> <span data-key="t-apps">{t('Support')}</span>
                </a>
                <div className="menu-dropdown collapse" id="support">
                    <ul className="nav nav-sm flex-column">
                        <Authorization single={true}  module="supportToCustomer" component={false}>
                        <li className="nav-item">
                            <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_SUPPORT)}`} to={LINK_STORE_SUPPORT} >
                                <span>{t('ToCustomers')}</span>
                            </Link>
                        </li>
                        </Authorization>

                        <Authorization single={true}  module="supportToAdmin">
                            <li className="nav-item">
                                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_ASSISTANCE)}`} to={LINK_STORE_ASSISTANCE}  >
                                    <span>{t('ToAdmin')}</span>
                                </Link>

                            </li>
                        </Authorization>
                    </ul>
                </div>
            </li>

            </Authorization>

            <Authorization single={false}  module="users" bloc={['permissions','users']} >
            <li className="nav-item">
                <a className="nav-link menu-link collapsed" id="users-parent" onClick={event => toggleCollapsed('users')}
                   data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="users">
                    <i className="ri-user-add-fill" ></i> <span data-key="t-apps">{t('Users')}</span>
                </a>
                <div className="menu-dropdown collapse" id="users">
                    <ul className="nav nav-sm flex-column">
                        <Authorization single={true}  module="permissions">
                        <li className="nav-item">
                            <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_PERMISSIONS)}`} to={LINK_STORE_PERMISSIONS} >
                                <span>{t('Permissions')}</span>
                            </Link>
                        </li>
                        </Authorization>

                        <Authorization single={true}  module="users">
                            <li className="nav-item">
                                <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_USERS)}`} to={LINK_STORE_USERS} >
                                    <span>{t('Collaborater')}s</span>
                                </Link>
                            </li>
                        </Authorization>
                    </ul>
                </div>
            </li>
            </Authorization>



                <Authorization single={true}  module="settings">
                    <li className="nav-item">
                        <Link className={`nav-link menu-link ${ActiveMenu(LINK_STORE_SETTINGS)}`} to={LINK_STORE_SETTINGS} >
                            <i className=" ri-settings-2-line"></i> <span>{t('Setting')}</span>
                        </Link>
                    </li>

                </Authorization>

        </ul>

    );
}

export default  withTranslation()(ShopkeeperSidebar)
